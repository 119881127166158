import { Inject, Injectable } from '@angular/core';
import { Casino, CasinoTest, CurrencyData, Tenant } from 'src/app/common/components/viewmodels/Casino';
import * as moment from 'moment-timezone';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { baseService } from '../base/base.service';
import { ToastrService } from 'ngx-toastr';
import { TextViewModel } from 'src/app/common/components/viewmodels/TextViewModel';
import { Router } from '@angular/router';
import { InstanceTypeEnum } from 'src/app/common/components/viewmodels/Enums';

@Injectable({
  providedIn: 'root',
})
export class CasinoService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getTimeZone(): Observable<ApiResult<TextViewModel>> {
    return this.http.get<ApiResult<TextViewModel>>(`${this.baseUrl}Config/GetTimeZone`).pipe(
      map((x) => {
        var result = this.checkResult<TextViewModel>(x);
        return result;
      }),
      catchError((error) => this.handleError<TextViewModel>(error))
    );
  }

  public createOrEditTenant(tenant: Tenant): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Config/CreateOrEditTenant`, tenant).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public editCasinoConfig(casino: Casino): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Config/editCasinoConfig`, casino).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public GetTenantById(id: number): Observable<ApiResult<Tenant>> {
    return this.http.get<ApiResult<Tenant>>(`${this.baseUrl}Config/GetTenantById?id=${id}`).pipe(
      map((x) => this.checkResult<Tenant>(x)),
      catchError((error) => this.handleError<Tenant>(error))
    );
  }

  public DeleteTenant(id: number): Observable<ApiResult<string>> {
    return this.http.delete<ApiResult<string>>(`${this.baseUrl}Config/DeleteTenantById?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public GetTenantListDataTable(
    dataTablesParameters: DataTableParameters
  ): Observable<ApiResult<DataTableResult<Tenant>>> {
    return this.http
      .post<ApiResult<DataTableResult<Tenant>>>(`${this.baseUrl}Config/GetTenantListDataTable`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Tenant>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Tenant>>(error))
      );
  }

  public getData(): Observable<ApiResult<CurrencyData>> {
    return this.http.get<ApiResult<CurrencyData>>(`${this.baseUrl}Config/GetCasinoConfigurationCurrencyData`).pipe(
      map((x) => {
        var result = this.checkResult<CurrencyData>(x);
        return result;
      }),
      catchError((error) => this.handleError<CurrencyData>(error))
    );
  }

  public getCasinoConfigData(): Observable<ApiResult<Casino>> {
    return this.http.get<ApiResult<Casino>>(`${this.baseUrl}Config/GetCasinoConfiguration`).pipe(
      map((x) => {
        var result = this.checkResult<Casino>(x);
        return result;
      }),
      catchError((error) => this.handleError<Casino>(error))
    );
  }

  public getTenantCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Config/GetTenantCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        var data: IdTextModel[] = [];
        for (let index = 0; index < result.data.length; index++) {
          data.push(new IdTextModel(result.data[index].id, result.data[index].text));
        }

        result.data = data;
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public setCasinoHours(casinoTest: CasinoTest): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Config/SetCasinoHours`, casinoTest).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public setMadridTimeZone(): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Config/SetMadridTimeZone`, null).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public cleanRoles(): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Config/CleanRoles`, null).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getLastSyncInfo(currenttenant): Observable<ApiResult<string>> {
    return this.http
      .get<ApiResult<string>>(`${this.baseUrl}Config/GetLastSyncInfo?currenttenant=${currenttenant}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<string>(x, true);
          return result;
        }),
        catchError((error) => this.handleError<string>(error, true))
      );
  }

  public getInstanceType(): Observable<ApiResult<InstanceTypeEnum>> {
    return this.http.get<ApiResult<InstanceTypeEnum>>(`${this.baseUrl}Config/getInstanceType`).pipe(
      map((x) => {
        var result = this.checkResult<InstanceTypeEnum>(x, true);
        return result;
      }),
      catchError((error) => this.handleError<InstanceTypeEnum>(error, true))
    );
  }

  public getFacilityName(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Config/GetFacilityName`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x, true);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getAppVersion(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Config/GetAppVersion`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x, true);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public isComponentAuthorized(component: string, mode?: string): Observable<ApiResult<boolean>> {
    if (!mode) mode = 'Page';

    return this.http
      .get<ApiResult<boolean>>(`${this.baseUrl}Config/IsComponentAuthorized?component=${component}&mode=${mode}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<boolean>(x);
          return result;
        }),
        catchError((error) => this.handleError<boolean>(error))
      );
  }

  public editCasinoConfigurationParameters(casino: Casino): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Config/editCasinoConfigurationParameters`, casino).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
}
