import { Component, Input, OnInit, ViewChild } from '@angular/core';

import * as moment from 'moment-timezone';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { textChangeRangeIsUnchanged } from 'typescript';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { NotificationService } from '../../shared/notification/notification.service';
import { FirmwareUpdateProcessViewModel } from '../../viewmodels/EGM';

@Component({
  selector: 'app-state-firmware',
  templateUrl: './state-firmware.component.html',
  styleUrls: ['./state-firmware.component.css'],
})
export class StateFirmwareComponent implements OnInit {
  moment = moment;

  step1Text: string = 'Actualización encolada';
  step2Text: string = 'Comando Actualización enviado al slot';
  step3Text: string = 'Aviso de actualización recibido';
  step4Text: string = 'Firmware enviado al slot';
  step5OkText: string = 'Finalizado';
  step5ErrorTextPostedError = 'Error al recibir el firmware en el slot';
  step5ErrorTextNotPost = 'No fue posible enviar el firmware al slot';
  step5ErrorTextUpdateNok = 'Error al intentar actualizar el firmware';
  step5ErrorTextCancel = 'Actualización cancelada';

  // step5Ok: boolean = false;
  // step5Error: boolean = false;
  // currentProgress: string = "0%";

  @Input() model: FirmwareUpdateProcessViewModel = null;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string = '';

  constructor(public egmService: EgmServiceService, private notificationService: NotificationService) {}

  ngAfterViewInit(): void {
    if (this.model.id != null) {
      if (this.model.isUpdating === false) {
        $('#toolBtn' + this.model.id)
          .removeClass('fas fa-minus')
          .addClass('fas fa-plus');
      }
    }
  }
  ngOnInit(): void {}

  getStepDate(date?: moment.Moment): string {
    if (date != null) {
      return ' - ' + moment(date).format('DD/MM/yyyy, HH:mm:ss');
    }
    return '';
  }

  getFinlizadedText() {
    if (this.model.isFinalizedOk) {
      return this.step5OkText;
    }

    if (this.model.isFinalizedError) {
      if (this.model.formattedUpdatePostedError) {
        return this.step5ErrorTextPostedError;
      }
      if (this.model.formattedUpdateNotPost) {
        return this.step5ErrorTextNotPost;
      }
      if (this.model.formattedUpdateFinishNotOk) {
        return this.step5ErrorTextUpdateNok;
      }
      if (this.model.formattedUpdateUpdateCancel) {
        return this.step5ErrorTextCancel;
      }
      return '';
    }
  }

  getFinlizadedDate(): string {
    if (this.model.isFinalizedOk) {
      return ' - ' + moment(this.model.formattedUpdateFinishNotOk).format('DD/MM/yyyy, HH:mm:ss');
    }

    if (this.model.formattedUpdatePostedError) {
      return ' - ' + moment(this.model.formattedUpdatePostedError).format('DD/MM/yyyy, HH:mm:ss');
    }

    if (this.model.formattedUpdateNotPost) {
      return ' - ' + moment(this.model.formattedUpdateNotPost).format('DD/MM/yyyy, HH:mm:ss');
    }

    if (this.model.formattedUpdateFinishNotOk) {
      return ' - ' + moment(this.model.formattedUpdateFinishNotOk).format('DD/MM/yyyy, HH:mm:ss');
    }

    if (this.model.formattedUpdateUpdateCancel) {
      return ' - ' + moment(this.model.formattedUpdateUpdateCancel).format('DD/MM/yyyy, HH:mm:ss');
    }

    return '';
  }

  // public isFinilizedOk() {

  //   if (this.model == null) {
  //     return false;
  //   }
  //   if (this.model.formattedUpdateFinishOk != null) {
  //     return true;
  //   }
  // }

  // public isFinilizedError() {

  //   if (this.model == null) {
  //     return false;
  //   }

  //   if (
  //     this.model.formattedUpdatePostedError != null || this.model.formattedUpdateNotPost != null
  //     || this.model.formattedUpdateFinishNotOk != null
  //     || this.model.formattedUpdateUpdateCancel != null) {
  //     return true;
  //   }
  //   return false;
  // }

  confirmYesModal() {
    if (this.model.isUpdating) {
      this.performCancelUpdate();
    }
  }

  performCancelUpdate() {
    this.egmService.cancelUpdateFirmware(this.model.id).subscribe((result) => {
      if (result.error == null) {
        window.location.reload();
      }
    });
  }

  cancelUpdate() {
    this.confirmModal.openModal();
    this.body = `¿Está seguro que desea cancelar la actualización de firmware  de la máquina  ?`;
  }
}
