<div [class.disabled]="controlFrom.disabled || controlTo.disabled" [hidden]="!isLoaded">
  <label class="form-control-sm mb-0">{{ label }}</label>
  <input
    (datesUpdated)="datesUpdated($event)"
    type="text"
    ngxDaterangepickerMd
    [(ngModel)]="selected"
    placeholder="Elige una fecha"
    class="form-control positioning max-height-36"
    (ngModelChange)="ngModelChange($event)"
    [locale]="locale"
    [timePicker24Hour]="true"
    [alwaysShowCalendars]="true"
    [singleDatePicker]="singleDate"
    [ranges]="ranges"
    [maxDate]="maxDate"
    applyButtonClasses="btn btn-info"
    linkedCalendars="true"
    [timePicker]="showTime"
    [showClearButton]="true" />
</div>
<!--  -->
<!-- [timePicker]="showTime" -->
