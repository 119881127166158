import { Model } from './Model';

export class GameType {
  id: string;
  name: string;
  model: Model;
  public constructor(init?: Partial<GameType>) {
    Object.assign(this, init);
  }
}
