import { CurrencyPipe } from '@angular/common';
import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Ticket } from 'src/app/common/components/viewmodels/Ticket';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-unpaid-tickets',
  templateUrl: './unpaid-tickets.component.html',
  styleUrls: ['./unpaid-tickets.component.css'],
})
export class UnpaidTicketsComponent implements OnInit {
  totalTickets: number = 0;
  total: number = 0;
  firstDraw: boolean = true;
  currencySym: string;
  currencyCode: string;

  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Ticket>;

  items: Array<BreadcrumbItem> = [];

  title: string = 'Reportes de Tickets Impagos';

  date: moment.Moment = moment(new Date());

  public data: commonTableData<Ticket>;

  searchMade: boolean;

  public static getName() {
    return 'UnpaidTicketsComponent';
  }

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public TicketService: TicketService,
    public CasinoService: CasinoService,
    private activatedRoute: ActivatedRoute,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    const that = this;

    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));

    let service = this.TicketService;

    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Ticket>>> {
      return service.getTicketsReportsWithTotals(param);
    };
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.data.columns = [
      {
        data: 'uid',
        title: 'EGM',
        render: function (data: number, type, row) {
          return data == 0 ? 'Totales:' : data;
        },
      },
      { data: 'cashMachine.cuid', title: 'CUID' },
      { data: 'quantity', title: 'Cantidad de Tickets' },
      {
        data: 'formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
    ];

    this.data.rowCallback = function (row: Node, data: Ticket | Object, index: number): void {
      if (data['uid'] == 0) {
        $(row).addClass('bg-dark');
      }

      that.totalTickets += data['quantity'];
      that.total += data['formattedAmount'];
    };
  }
}
