<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="filterParamString !== null"> - {{ filterParamString }} </span>
              </h3>
              <div class="card-tools">
                <button type="button" class="btn btn-tool mr-2" (click)="onSubmit()">
                  <i class="fas fa-redo"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-3">
                  <app-common-daterangepicker
                    [controlFrom]="formSearch.get('from')"
                    [controlTo]="formSearch.get('to')"
                    [label]="'Rango'"
                    [empty]="true">
                  </app-common-daterangepicker>
                </div>

                <div class="col-12 col-sm-3">
                  <egm-combo
                    [multiselect]="true"
                    [control]="formSearch.get('egmUid')"
                    [includeDeleted]="true"></egm-combo>
                </div>

                <div class="d-flex align-items-center ml-4 col-12 col-sm-3" style="padding-left: 18px">
                  <div class="col-12">
                    <div class="form-check form-check-inline">
                      <input
                        (change)="changePendingButton($event)"
                        class="form-check-input"
                        id="toCollect"
                        type="radio"
                        value="toCollect"
                        name="toCollect"
                        formControlName="toCollect" />
                      <label class="form-check-label" for="toCollect">Pendiente Recaudar</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        (change)="changePendingButton($event)"
                        class="form-check-input"
                        id="collected"
                        type="radio"
                        value="collected"
                        name="toCollect"
                        formControlName="toCollect" />
                      <label class="form-check-label" for="collected">Recaudado</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              [title]="title"
              [excel]="true"
              (onSearch)="onSearch($event)"></app-common-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="this.table?.footerData?.totalFormattedTheoricToTake !== null" style="margin-top: 20px">
      <div class="col-12 col-sm-2">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span class="info-box-text text-center text-muted">Total efectivo a Retirar:</span>
            <span class="info-box-number text-center text-muted mb-0">
              {{
                currencyPipe.transform(this.table.footerData.totalFormattedTheoricToTake, currencyCode, currencySym)
              }}</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="col-12 col-sm-2">
        <div class="info-box bg-light">
          <div class="info-box-content">
            <span class="info-box-text text-center text-muted">Total Recaudación:</span>
            <span class="info-box-number text-center text-muted mb-0">
              {{
                currencyPipe.transform(
                  this.table.footerData.totalFormattedTheoricPendingToReConcil,
                  currencyCode,
                  currencySym
                )
              }}</span
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
