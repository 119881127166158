import { Component, Input } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType, Chart } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { ViewChild } from '@angular/core';
import { data } from 'jquery';
import {
  HourlyCoinInChartViewModel,
  HourlyProfitChartViewModel,
  LineChartViewModel,
} from '../../viewmodels/LineChartViewModel';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { CasinoService } from 'src/app/services/casino/casino.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent {
  @Input() data: LineChartViewModel;
  @Input() hourlyProfit: boolean = false;
  @Input() hourlyCoinIn: boolean = false;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  myChart: Chart;
  lineChartData: ChartDataSets[];
  dataStatus: boolean = false;

  public lineChartLabels: Label[] = [];
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  currencyCode: string;
  currencySym: string;

  constructor(public CasinoService: CasinoService) {}

  dynamicColors = function () {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  };

  async setBarData(data: any) {
    if (this.hourlyProfit) {
      await this.getHourlyProfitData(data);
    }
    if (this.hourlyCoinIn) {
      await this.getHourlyCoinInData(data);
    }
  }
  public async getHourlyProfitData(data: HourlyProfitChartViewModel) {
    if (data != null) {
      if (data.hour.length == 0) {
        this.dataStatus = false;
      } else {
        this.dataStatus = true;
      }
      var dataArray = [];

      data.hour.forEach((item) => {
        var aux = {
          data: item.profit,
          label: item.label,
          fill: false,
          borderColor: this.dynamicColors(),
          borderWidth: 1,
        };
        dataArray.push(aux);
      });

      var labelArray: Label[] = [];
      data.labels.forEach((label) => {
        labelArray.push(label);
      });

      this.lineChartLabels = labelArray;
      this.lineChartData = dataArray;
    }
  }
  public async getHourlyCoinInData(data: HourlyCoinInChartViewModel) {
    if (data != null) {
      if (data.hour.length == 0) {
        this.dataStatus = false;
      } else {
        this.dataStatus = true;
      }
      var dataArray = [];

      data.hour.forEach((item) => {
        var aux = {
          data: item.value,
          label: item.label,
          fill: false,
          borderColor: this.dynamicColors(),
          borderWidth: 1,
        };
        dataArray.push(aux);
      });

      var labelArray: Label[] = [];
      data.labels.forEach((label) => {
        labelArray.push(label);
      });

      this.lineChartLabels = labelArray;
      this.lineChartData = dataArray;
    }
  }
  public async reload(data: any) {
    await this.setBarData(data);
    if (this.myChart != null) {
      if (this.myChart.ctx != null) {
        this.myChart.data.labels = [];
        this.myChart.data.datasets = [];
        this.myChart.update();

        this.lineChartLabels.forEach((label) => {
          this.myChart.data.labels.push(label);
        });
        this.lineChartData.forEach((data) => {
          this.myChart.data.datasets.push(data);
        });
        this.myChart.update();
        this.lineChartData.forEach((dataset) => {
          if (dataset.data.length == 0) {
            this.dataStatus = false;
          } else {
            this.dataStatus = true;
          }
        });
        if (this.dataStatus == false) {
          this.myChart.destroy();
        }
      } else {
        if (this.dataStatus == true) {
          this.initChart();
        }
      }
    } else {
      this.initChart();
    }
  }

  // public getProfitData(data: LineChartViewModel) {
  //   var dataArray = [];
  //   data.egmsProfit.forEach(egm => {

  //     var aux = { data: egm.profit, label: egm.label, fill: false, borderColor: this.dynamicColors() }
  //     dataArray.push(aux);
  //   });
  //   var labelArray: Label[] = [];
  //   data.labels.forEach(label => {
  //     labelArray.push(label);
  //   });
  //   this.lineChartLabels = labelArray;
  //   this.lineChartData = dataArray;
  // }

  initChart() {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;

      const canvas = <HTMLCanvasElement>document.getElementById('myChart');
      //if (canvas == null) return;

      const ctx = canvas.getContext('2d');

      this.myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.lineChartLabels,
          datasets: this.lineChartData,
        },
        options: {
          responsive: true,
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return label;
              },
            },
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.currencyCode + ' ' + this.currencySym,
                  fontSize: 14,
                },
                // gridLines: {
                //   display: false
                // },
                ticks: {
                  beginAtZero: true,
                  callback: function (value: string, index, values) {
                    if (parseInt(value) >= 1000) {
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    } else {
                      return value;
                    }
                  },
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    });
  }
}
