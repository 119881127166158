<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="filterParamString !== null"> - {{ filterParamString }} </span>
              </h3>
              <div class="card-tools">
                <button type="button" class="btn btn-tool mr-2" (click)="onSubmit()">
                  <i class="fas fa-redo"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-3">
                  <app-common-daterangepicker
                    [controlFrom]="formSearch.get('from')"
                    [controlTo]="formSearch.get('to')"
                    [label]="'Rango'"
                    [empty]="true">
                  </app-common-daterangepicker>
                </div>

                <div class="col-12 col-sm-3">
                  <egm-combo [multiselect]="true" [control]="formSearch.get('egmUid')"></egm-combo>
                </div>
                <div class="col-12 col-sm-3">
                  <cash-register-combo [multiselect]="true" [control]="formSearch.get('cashRegisters')">
                  </cash-register-combo>
                </div>

                <div class="col-12 col-sm-3">
                  <app-moviment-type-combo
                    [multiselect]="true"
                    [control]="formSearch.get('movementType')"
                    [allowClear]="true"></app-moviment-type-combo>
                </div>
                <div class="col-12 col-sm-3">
                  <app-turn-combo
                    [multiselect]="true"
                    [allowClear]="true"
                    [onlyOpen]="false"
                    [control]="formSearch.get('turnId')"></app-turn-combo>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              [title]="title"
              [excel]="true"
              (onSearch)="onSearch($event)"></app-common-table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.table?.decimalData !== undefined" style="margin-top: 20px">
      <div class="row">
        <div class="col-12 col-sm-2">
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span class="info-box-text text-center text-muted">Total Importe:</span>
              <span class="info-box-number text-center text-muted mb-0">
                {{ currencyPipe.transform(this.table?.decimalData, currencyCode, currencySym) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
