import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private genericMaxLenght: number = 40;
  private cashMaxLenght: number = 10;
  private limitCashMaxLenght: number = 8;
  private maxInteger: number = 99999999;
  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string) {}

  getMaxLenghtAllowed(): number {
    return this.genericMaxLenght;
  }
  getMaxCashLimitedAllowed(): number {
    return this.limitCashMaxLenght;
  }

  getMaxLenghtCashAllowed(): number {
    return this.cashMaxLenght;
  }

  getMaxLenghtIntAllowed(): number {
    return this.maxInteger;
  }
}
