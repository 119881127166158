import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { DataConsts } from 'src/app/common/components/viewmodels/Casino';
import { CollectionViewModel } from 'src/app/common/components/viewmodels/SessioModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-session-collection',
  templateUrl: './session-collection.component.html',
  styleUrls: ['./session-collection.component.css'],
})
export class SessionCollectionComponent implements OnInit {
  public static getName() {
    return 'SessionCollectionComponent';
  }

  InputType = InputTypeEnum;
  currencyCode: string;
  currencySym: string;
  formEdit: FormGroup;
  items: Array<BreadcrumbItem> = [];
  title: string = 'Recaudación EGM';
  headerTitle: string = '';
  loadData: boolean = false;
  egmId: number;
  data: CollectionViewModel;
  body: string;
  isLoading: boolean = true;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;

  constructor(
    private router: Router,

    public sessionService: SessionService,
    public notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    private spinner: NgxSpinnerService
  ) {
    // this.spinner.show();
  }

  getLabelCashResult() {
    if (this.formEdit.get('formattedTheoricToTake').value >= 0) return 'Efectivo a Retirar';

    return 'Efectivo a Reponer';
  }
  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramEgmId = this.activatedRoute.snapshot.params.id;

      this.egmId = Number(paramEgmId);
      if (isNaN(this.egmId)) {
        this.GoToBack();
      }

      this.casinoService.getData().subscribe((result) => {
        this.currencyCode = result.data.currencyCode;
        this.currencySym = result.data.currencySymbol;
      });

      this.loadCollection();

      this.initForm();
      this.items.push(new BreadcrumbItem('/session-egm-action', 'Recaudación'));
      this.items.push(new BreadcrumbItem('', this.title));
    }
  }

  loadCollection() {
    this.sessionService.GetCollection(this.egmId).subscribe((result) => {
      if (result.error != null) {
        this.router.navigate(['/session-egm-action']);
      } else {
        this.formEdit.patchValue(result.data);
        this.loadData = true;
        this.data = result.data;

        this.headerTitle = this.data.title;
        this.isLoading = false;
        //this.spinner.hide();
      }
    });
  }

  GoToBack() {
    this.router.navigate(['/session-egm-action']);
  }

  public changeTotalToTake() {
    var real = this.formEdit.get('formattedSettlementReal').value;

    var deposito = this.formEdit.get('formattedDepositCurrent').value;

    if (!isNaN(real) && !isNaN(deposito)) {
      var total = real - deposito;
      this.formEdit.get('formattedTheoricToTake').setValue(total);
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      formattedSettlementReal: new FormControl(null, [
        Validators.required,

        Validators.max(9999999999.99),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      formattedDepositCurrent: new FormControl(null, [
        Validators.required,
        Validators.max(9999999999.99),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      formattedTheoricToTake: new FormControl(null, [
        Validators.required,
        Validators.max(9999999999.99),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      observations: new FormControl(null),
    });
  }
  async onSubmit(myForm: any) {}

  performCollection() {
    //this.spinner.show();
    this.isLoading = true;
    this.data.settlementReal = Number(this.formEdit.get('formattedSettlementReal').value) * DataConsts.precisionFactor;
    this.data.depositCurrent = Number(this.formEdit.get('formattedDepositCurrent').value) * DataConsts.precisionFactor;
    this.data.theoricToTake = Number(this.formEdit.get('formattedTheoricToTake').value) * DataConsts.precisionFactor;
    this.data.observations = this.formEdit.get('observations').value;

    this.sessionService.PerfomCollection(this.data).subscribe((result) => {
      if (result.error != null) {
        this.loadCollection();
        this.notificationService.showError(result.error.generalMessage, 'Error');
      } else {
        //this.loadCollection();
        this.notificationService.showSuccess('La recaudación se ha generado con éxito', 'Atención');
        this.GoToBack();
      }
      this.isLoading = false;
      //  this.spinner.hide();
    });
  }

  goToPerfomModal() {
    this.body = `¿Está seguro que desea realizar la recaudación?`;
    this.confirmModal.openModalStatic();
  }

  onConfirm() {
    this.performCollection();
  }
}
