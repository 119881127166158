import { AuthorizationOperationType } from './Enums';

export class AuthorizationMock {
  id: number;
  type: AuthorizationOperationType;
  code: string;
  userName: string;
  creationDate: moment.Moment;
  formattedAmount: number;
}
