import { EGMMock } from './EGMMock';
import { PromoTicketState, TicketFamily } from './Enums';
import { SessionOldReport } from './SessionOldReport';

export class PromotionalTicketMock {
  id: number;
  validatorCode: string;
  ticketNumber: number;
  amount: number;
  formattedAmount: number;
  creationDate: moment.Moment;
  expirationDate: moment.Moment;
  familyId: TicketFamily;
  state: PromoTicketState;
  egm?: EGMMock;
  session?: SessionOldReport;
  supervisorUserId: string;
  supervisorName: string;
  egmId: number;

  public constructor(init?: Partial<PromotionalTicketMock>) {
    Object.assign(this, init);
  }
}
