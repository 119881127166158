import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';

import { interval } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  anio: number;
  appVersion: Observable<string>;
  lastSyncData: string;

  autoRefreshFunc: any = null;
  constructor(private casinoService: CasinoService, private tenantService: TenantService) {}

  ngOnInit(): void {
    this.anio = new Date().getFullYear();
    this.appVersion = this.casinoService.getAppVersion().pipe(map((u) => u && u.data));

    this.fillSyncData();
  }

  ngOnDestroy() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);
  }

  autoRefresh() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    const timeIntevalSeconds = 5 * 1000; // 5 segundos

    this.autoRefreshFunc = setTimeout(async () => {
      this.fillSyncData();
    }, timeIntevalSeconds); //
  }

  fillSyncData() {
    this.casinoService.getLastSyncInfo(this.tenantService.getCurrentTenantId()).subscribe((x) => {
      if (x.error == null) {
        this.lastSyncData = x.data;
      } else {
        this.lastSyncData = 'Error sync data';
      }
    });

    this.autoRefresh();
  }
}
