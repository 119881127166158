import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InputTypeEnum } from '../common-input/common-input.component';
import { NotificationService } from '../notification/notification.service';

@Component({
  selector: 'app-authorization-modal',
  templateUrl: './authorization-modal.component.html',
  styleUrls: ['./authorization-modal.component.css'],
})
export class AuthorizationModalComponent {
  @Input() yes: string = 'Guardar';
  @Input() no: string = 'Cancelar';
  @Input() title: string = 'Atención';
  @Input() body: string = '¿Está seguro que desea continuar?';
  @Input() label: string = 'confirmModal';
  @Input() okOnly: boolean = false;

  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  @ViewChild('confirmModal') confirmModal: ElementRef;

  code: FormControl;
  InputType = InputTypeEnum;

  constructor(public modalService: NgbModal, private notificationService: NotificationService) {}

  openModal() {
    this.initCodeControl();
    this.modalService.open(this.confirmModal, { ariaLabelledBy: 'confirmModal' });
  }

  confirm() {
    if (this.code.valid) {
      this.modalService.dismissAll();
      this.onConfirm.emit();
    } else {
      this.notificationService.showError('Por favor complete el código correctamente.', 'Error');
    }
  }

  cancel() {
    this.modalService.dismissAll();
    this.onCancel.emit();
  }

  openModalStatic() {
    this.initCodeControl();
    this.modalService.open(this.confirmModal, { ariaLabelledBy: 'confirmModal', backdrop: 'static', keyboard: false });
  }

  initCodeControl() {
    this.code = new FormControl(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
    ]);
  }
}
