<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid" id="print-section">
    <h1 class="title mt-2">
      {{ title }}
    </h1>
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange !== null"> - {{ dateRange }} </span>
              </h3>
              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body" id="demo">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [showTime]="true"
                    [controlFrom]="this.formSearch.get('from')"
                    [controlTo]="this.formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm">
                  <room-combo
                    [control]="this.formSearch.get('rooms')"
                    [multiselect]="true"
                    (valueChanged)="updateEgms()">
                  </room-combo>
                </div>
                <div class="col-12 col-sm">
                  <egm-combo [multiselect]="true" [control]="formSearch.get('egmUid')"> </egm-combo>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table #table [model]="data" [title]="this.title" [excel]="true"></app-common-table>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" *ngIf="this.footer.showFooter">
      <div class="row">
        <div class="col-12 d-flex">
          <span class="footer"> Total Coin Drop: {{ this.footer.difCoinDropTotal }}</span>
          <span class="footer"> Total Bill In: {{ this.footer.difBillInTotal }}</span>
          <span class="footer"> Total Ticket In: {{ this.footer.difTicketInTotal }}</span>
          <span class="footer"> Total AFT In: {{ this.footer.aftIn }}</span>
          <span class="footer"> Total Total Drop: {{ this.footer.difTotalDropTotal }}</span>

          <span class="" style="margin-right: 5px">Diferencia: </span>
          <span
            class="resalt"
            [class.text-success]="this.footer.difTotal > 0"
            [class.text-danger]="this.footer.difTotal < 0">
            {{ currencyPipe.transform(this.footer.difTotal, currencyCode, currencySym) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
