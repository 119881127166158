import { IdTextModel } from '../shared/IdTextModel';

export class FullUserViewModel {
  id: string;
  userName: string;
  name: string;
  lastName: string;
  roles: Array<IdTextModel>;
  password: string;

  dni: string;
  email: string;
  phone: string;
  canEdit: boolean;

  public constructor(init?: Partial<FullUserViewModel>) {
    Object.assign(this, init);
  }
}

export class UsersViewModel {
  id: string;

  userName: string;
  Rol: string;
  fullName: string;

  isRemoved: boolean;
  canRemove: boolean;
}
