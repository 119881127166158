<div *ngIf="!collapsed" class="card card-primary">
  <div class="card-header">
    <h3 class="card-title">
      {{ ticket.validatorCode }} - {{ currencyPipe.transform(ticket.formattedAmount, currencyCode, currencySym) }}
    </h3>

    <div class="card-tools">
      <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
    </div>
  </div>
  <div class="card-body">
    <div class="form-row">
      <div class="col-12 col-md-3 col-lg-4">
        <!-- <app-info-display [label]="'Monto'" [model]="ticket.formattedAmount"></app-info-display> -->

        <app-info-display
          [label]="'Monto'"
          [model]="currencyPipe.transform(ticket.formattedAmount, currencyCode, currencySym)"></app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display [label]="'Estado'" [model]="stateEnum[ticket.state].replace(this.regex, ' ')">
        </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display [label]="'Número de ticket'" [model]="ticket.ticketNumber"> </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4" *ngIf="ticket.creationEgm !== null">
        <app-info-display [label]="'UID'" [model]="ticket.creationEgm.uid"></app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4" *ngIf="ticket.creationTurn !== null">
        <app-info-display [label]="'CUID'" [model]="ticket.creationTurn.session.cashRegister.cuid"> </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display
          [label]="'Fecha de emisión'"
          [model]="moment(ticket.creationDate).format('DD/MM/yyyy, HH:mm:ss')">
        </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display
          [label]="'Fecha de expiración'"
          [model]="moment(ticket.expirationDate).format('DD/MM/yyyy, HH:mm:ss')">
        </app-info-display>
      </div>
    </div>
  </div>
</div>

<div *ngIf="collapsed" class="card card-primary collapsed-card">
  <div class="card-header">
    <h3 class="card-title">
      {{ ticket.validatorCode }} - {{ currencyPipe.transform(ticket.formattedAmount, currencyCode, currencySym) }}
    </h3>

    <div class="card-tools">
      <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i></button>
    </div>
  </div>
  <div class="card-body">
    <div class="form-row">
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display
          [label]="'Monto'"
          [model]="this.currencyPipe.transform(ticket.formattedAmount, this.currencyCode, this.currencySym)">
        </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display [label]="'Estado'" [model]="stateEnum[ticket.state].replace(this.regex, ' ')">
        </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display [label]="'Número de ticket'" [model]="ticket.ticketNumber"> </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4" *ngIf="ticket.creationEgm !== null">
        <app-info-display [label]="'UID'" [model]="ticket.creationEgm.uid"></app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4" *ngIf="ticket.creationTurn !== null">
        <app-info-display [label]="'CUID'" [model]="ticket.creationTurn.session.cashRegister.cuid"> </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display
          [label]="'Fecha de emisión'"
          [model]="moment(ticket.creationDate).format('DD/MM/yyyy, HH:mm:ss')">
        </app-info-display>
      </div>
      <div class="col-12 col-md-3 col-lg-4">
        <app-info-display
          [label]="'Fecha de expiración'"
          [model]="moment(ticket.expirationDate).format('DD/MM/yyyy, HH:mm:ss')">
        </app-info-display>
      </div>
    </div>
  </div>
</div>
