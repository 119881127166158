import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult } from 'src/app/common/components/viewmodels/apiResult';
import { Dashboard } from 'src/app/common/components/viewmodels/Dashboard';
import { DashboardItem } from 'src/app/common/components/viewmodels/DashboardItem';
import { EventsClasses, StateCashMachine, StateEgm } from 'src/app/common/components/viewmodels/Enums';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import * as L from 'leaflet';
import 'leaflet-path-drag';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map, take } from 'rxjs/operators';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { EgmStatus } from 'src/app/common/components/viewmodels/EgmParam';

// Elegir tamaño de maquinas
// Averiguar que es el setDashboard

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isEdit: boolean = false;
  @ViewChild('egmModal') egmModal: any;
  @ViewChild('cashMachineModal') cashMachineModal: any;
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent;
  @ViewChild('confirmModalBg') confirmModalBg: ConfirmModalComponent;
  @ViewChild('configMapModal') configMapModal: ConfirmModalComponent;

  @ViewChild(EgmComboComponent, { static: false }) egmCombo: EgmComboComponent;

  formEgm: FormGroup;
  formCashMachine: FormGroup;
  InputType = InputTypeEnum;
  eventsClasses = EventsClasses;
  stateEgm = StateEgm;
  itemToDelete: DashboardItem = null;
  confirmModalBody: string = '¿Está seguro que desea eliminar la EGM?';
  roomForm: FormGroup;
  markerSizeForm: FormGroup;
  dashboard: Dashboard;
  regex: RegExp = new RegExp('_', 'g');
  currencyCode: string;
  currencySym: string;
  roomId: number;
  markerSizeList: Array<IdTextModel>;
  processing: boolean = false;
  username: string;

  private mapInitialized: boolean = false;
  private backgroundImg: any;
  private map: any;

  public static getName() {
    return 'DashboardComponent';
  }
  autoRefreshFunc: any = null;

  constructor(
    @Inject('BASE_URL') public baseUrl: string,
    public notif: NotificationService,
    public DashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private egmService: EgmServiceService,
    private CasinoService: CasinoService,
    private CurrencyPipe: CurrencyPipe,
    private authorizeService: AuthorizeService,
    private tenantService: TenantService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.markerSizeList = [];
    this.markerSizeList.push(new IdTextModel(10, '10'));
    this.markerSizeList.push(new IdTextModel(25, '25'));
    this.markerSizeList.push(new IdTextModel(50, '50'));
    this.markerSizeList.push(new IdTextModel(75, '75'));
    this.markerSizeList.push(new IdTextModel(100, '100'));
    this.markerSizeList.push(new IdTextModel(150, '150'));
    this.markerSizeList.push(new IdTextModel(200, '200'));

    this.authorizeService
      .getUser()
      .pipe(map((u) => u && u.name))
      .subscribe((u) => (this.username = u));
  }

  ngOnDestroy() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);
  }

  async ngAfterViewInit(): Promise<void> {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    if (this.isEdit && this.activatedRoute.snapshot.params.id != null) {
      await this.getDashboard();
    }
  }

  async roomLoadFinish() {
    if (!this.isEdit) {
      var currentRoom = localStorage.getItem(
        'currentRoom.' + 'tenant.' + this.tenantService.getCurrentTenantId() + this.username
      );
      if (currentRoom) {
        var currentRoomId = parseInt(currentRoom);
        this.roomId = currentRoomId;
        this.roomForm.get('room').setValue(this.roomId);
        await this.getDashboard(true);
      }
    }
  }

  updateRoom(val: any) {
    localStorage.setItem('currentRoom.' + 'tenant.' + this.tenantService.getCurrentTenantId() + this.username, val);
  }

  async getDashboard(refresh?: boolean) {
    this.itemToDelete = null;
    if (this.isEdit && this.activatedRoute.snapshot.params.id != null) {
      this.roomId = parseInt(this.activatedRoute.snapshot.params.id);
    } else {
      this.roomId = parseInt(this.roomForm.get('room').value);
    }

    var result = await this.DashboardService.getDashboard(this.roomId).toPromise();

    if (result.data != null) {
      if (result.data.id == -1) {
        localStorage.removeItem('currentRoom.' + 'tenant.' + this.tenantService.getCurrentTenantId() + this.username);
        return;
      }

      if (!this.mapInitialized) {
        var bounds = [
          [0, 0],
          [result.data.backgroundHeight, result.data.backgroundWidth],
        ]; // Set the image resolution as the boundary

        if (this.map) {
          this.map.off();
          this.map.remove();
        }
        var zoom = 1;
        var center = undefined;

        var memory = localStorage.getItem('dashboardmap.' + this.username + '.' + this.roomId);

        if (memory) {
          var memoryParse = JSON.parse(memory);
          if (memoryParse.zoom != null) zoom = memoryParse.zoom;

          if (memoryParse.center != null) center = memoryParse.center;
        }

        this.map = L.map('map', {
          crs: L.CRS.Simple,
          maxBounds: bounds,
          zoom: zoom,
          minZoom: result.data.minZoom,
          maxZoom: result.data.maxZoom,
          attributionControl: false,
          zoomSnap: result.data.deltaZoom,
          zoomDelta: result.data.deltaZoom,
          inertia: false,
          maxBoundsViscosity: 1.0,
          wheelPxPerZoomLevel: 120,
          center: center,
        });

        if (this.backgroundImg) this.map.removeLayer(this.backgroundImg);

        this.backgroundImg = L.imageOverlay(
          `${this.baseUrl}Dashboards/GetDashboardBackground?id=${
            this.roomId
          }&tenant=${this.tenantService.getCurrentTenantId()}&${new Date().getTime().toString(16)}`,
          bounds
        );
        this.backgroundImg.addTo(this.map); // Set the background image

        if (!center) this.map.fitBounds(bounds); // Set an area
        this.map.setMaxBounds(bounds);

        var that = this;
        this.map.on('moveend', () => {
          that.updateUserDashboard(that);
        });
        this.map.on('zoomend', () => {
          that.updateUserDashboard(that);
        });

        this.mapInitialized = true;
      }

      this.dashboard = result.data;
      this.dashboard.lastUpdate = moment(this.dashboard.lastUpdate);
      this.markerSizeForm.get('markerSize').setValue(this.dashboard.markersSize.toString());
      this.markerSizeForm.get('minZoom').setValue(this.dashboard.minZoom.toString());
      this.markerSizeForm.get('maxZoom').setValue(this.dashboard.maxZoom.toString());
      this.markerSizeForm.get('deltaZoom').setValue(this.dashboard.deltaZoom.toString());

      this.cleanOldMarkers();

      this.dashboard.dashboardItems.forEach((element) => {
        this.addMarker(element, this.dashboard.markersSize);
      });
    } else return;

    if (refresh) {
      this.autoRefresh();
    }
  }

  private addMarker(element: DashboardItem, markersSize: number) {
    var that = this;

    var statusColorClass = '';
    var iconName = '';
    var marker = null;
    var bufferColor = '';

    if (element.egmId != null) {
      if (element.event) {
        statusColorClass = this.eventsClasses[element.event.eventHost.stateEgm];
        iconName = element.egm?.uid.toString();
        bufferColor = statusColorClass;
      }

      this.map.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          if (element.egm?.id == layer.options.obj.egm?.id) {
            marker = layer;
          }
        }
      });

      if (element.eventsBuffer.length > 0) {
        bufferColor = this.eventsClasses[element.eventsBuffer[0].stateEgm];
      }
    } else {
      statusColorClass = this.eventsClasses[element.stateCashMachine];
      iconName = element.cashMachine?.muid.toString();

      this.map.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          if (element.cashMachine?.id == layer.options.obj.cashMachine?.id) {
            marker = layer;
          }
        }
      });

      //aca no hay buffer
      bufferColor = statusColorClass;
    }

    if (marker == null) {
      marker = L.polygon(
        [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        { draggable: this.isEdit, riseOnHover: true, className: 'str-' + statusColorClass }
      );
      marker.addTo(this.map);
      marker.bindTooltip(iconName, { permanent: true, direction: 'center', className: 'bg-' + bufferColor });

      marker.on('click', (e) => {
        that.itemToDelete = e.target.options.obj;
        that.itemToDelete.isSelected = true;
      });

      if (!this.isEdit) {
        marker.bindPopup('', { closeButton: false, autoClose: true, keepInView: true });
        marker.on('mouseover', function (e) {
          this.openPopup();
        });
        marker.on('mouseout', function (e) {
          this.closePopup();
        });
      } else {
        marker.on('dragstart', function (e) {
          e.target.closeTooltip();
          that.disableInterface();
        });
        marker.on('dragend', function (e) {
          // Actualizar servidor con la nueva posicion
          e.target.openTooltip();
          var latLng = e.target.getLatLngs();
          e.target.options.obj.position.x = parseInt(latLng[0][0].lat + markersSize);
          e.target.options.obj.position.y = parseInt(latLng[0][0].lng + markersSize);
          that.DashboardService.saveDashboard(that.dashboard).subscribe(async (result) => {
            that.enableInterface();
            await that.getDashboard();
          });
        });
      }
    }

    marker.options.obj = element;

    marker.setLatLngs(
      new L.rectangle([
        [element.position.x - markersSize, element.position.y - markersSize],
        [element.position.x + markersSize, element.position.y + markersSize],
      ]).getLatLngs()
    );

    marker.options.className = 'str-' + statusColorClass;
    marker._path.classList.forEach(function (item, index) {
      if (item.startsWith('str-')) marker._path.classList.remove(item);
    });
    marker._path.classList.add(marker.options.className);

    var toolTip = marker.getTooltip();
    marker.unbindTooltip();
    toolTip.options.className = 'bg-' + bufferColor;
    toolTip.setTooltipContent(iconName);
    marker.bindTooltip(toolTip.getContent(), toolTip.options);
    marker.openTooltip();

    if (!this.isEdit) {
      marker._popup.options.className = 'custom-popup bg-' + bufferColor;
      if (marker._popup?._container?.className)
        marker._popup._container.className = 'leaflet-popup leaflet-zoom-animated custom-popup bg-' + bufferColor;

      var text = this.getPopUpEGMText(bufferColor, element);
      marker.setPopupContent(text);
    }
  }

  private getPopUpEGMText(statusColorClass: string, element: DashboardItem): string {
    var text = "<span class='" + statusColorClass + "'>";
    if (element.egmId != null) {
      text +=
        element.egm?.uid.toString() +
        ' - ' +
        element.egm?.nickName +
        ', ' +
        element.egm?.gameType?.name +
        '<br>' +
        '<b>Últ. evento: ' +
        element.event?.eventHost.binaryDec.toString(16).toUpperCase() +
        ' - ' +
        element.event?.eventHost.spaDescription +
        '</b><br>' +
        'Grupo: ' +
        (element.egm?.creditValue ?? ' ') +
        //this.CurrencyPipe.transform(element.egm?.creditValue, this.currencyCode, this.currencySym) +
        ' CA: ';
      text += this.CurrencyPipe.transform(element.currentCreditMoney, this.currencyCode, this.currencySym);
      // text += ' R: ';
      // if (element.result != null) {
      //   text += this.CurrencyPipe.transform(element.result, this.currencyCode, this.currencySym);
      // }

      if (element.eventsBuffer && element.eventsBuffer.length > 0) {
        text += '<br><br>Eventos persistentes: <br>';

        for (var i = 0; i < element.eventsBuffer.length; i++) {
          text +=
            moment(element.eventsBuffer[i].eventDate).format('DD/MM HH:mm:ss') +
            ' - ' +
            element.eventsBuffer[i].binaryDec.toString(16).toUpperCase() +
            ' - ' +
            element.eventsBuffer[i].description +
            ' <br>';
        }
      }
    } else {
      text +=
        element.cashMachine?.muid.toString() +
        ' - ' +
        element.cashMachine?.name +
        ' - ' +
        StateCashMachine[element.stateCashMachine].replace(new RegExp('_', 'g'), ' ') +
        '<br>';
    }

    text += '</span>';
    return text;
  }

  private cleanOldMarkers() {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        var found = false;
        for (var i = 0; i < this.dashboard.dashboardItems.length; i++) {
          if (this.dashboard.dashboardItems[i].egmId != null) {
            if (this.dashboard.dashboardItems[i].egm?.id == layer.options.obj.egm?.id) {
              found = true;
              break;
            }
          } else {
            if (this.dashboard.dashboardItems[i].cashMachine?.id == layer.options.obj.cashMachine?.id) {
              found = true;
              break;
            }
          }

          if (!found) this.map.removeLayer(layer);
        }
      }
    });
  }

  autoRefresh() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    const timeIntevalSeconds = 5; //60 segundos * 5 = 5 minutos

    this.autoRefreshFunc = setTimeout(async () => {
      await this.getDashboard(true);
    }, timeIntevalSeconds * 1000);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private initForm() {
    this.roomForm = new FormGroup({
      room: new FormControl(null),
    });
    this.formEgm = new FormGroup({
      egm: new FormControl(null, Validators.required),
    });
    this.formCashMachine = new FormGroup({
      cashMachine: new FormControl(null, Validators.required),
    });
    this.markerSizeForm = new FormGroup({
      markerSize: new FormControl(null),
      minZoom: new FormControl(null, [
        Validators.required,
        Validators.min(-100),
        Validators.max(100),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      maxZoom: new FormControl(null, [
        Validators.required,
        Validators.min(-100),
        Validators.max(100),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      deltaZoom: new FormControl(null, [
        Validators.required,
        Validators.min(-100),
        Validators.max(100),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
    });
  }

  openEgmModal() {
    this.modalService.open(this.egmModal, { ariaLabelledBy: 'egmModal' });
  }

  openEgmModalCashMachine() {
    this.modalService.open(this.cashMachineModal, { ariaLabelledBy: 'egmModal' });
  }

  openConfigModal() {
    this.modalService.open(this.configMapModal, { ariaLabelledBy: 'configMapModal' });
  }

  openConfirmModal() {
    if (this.itemToDelete.egm != null) {
      this.confirmModalBody = '¿Está seguro que desea eliminar la EGM ' + this.itemToDelete.egm.uid.toString() + '?';
    } else {
      this.confirmModalBody =
        '¿Está seguro que desea eliminar la MUID ' + this.itemToDelete.cashMachine.muid.toString() + '?';
    }

    this.confirmModal.openModal();
  }

  async deleteItem() {
    this.disableInterface();

    if (this.itemToDelete.egm != null) {
      this.dashboard.dashboardItems.splice(
        this.dashboard.dashboardItems.findIndex((element) => element.egm?.uid == this.itemToDelete.egm.uid),
        1
      );
    } else {
      this.dashboard.dashboardItems.splice(
        this.dashboard.dashboardItems.findIndex(
          (element) => element.cashMachine?.muid == this.itemToDelete.cashMachine.muid
        ),
        1
      );
    }

    this.DashboardService.deleteDashboardItem(this.itemToDelete.id).subscribe(async (result) => {
      this.notif.showSuccess('Operación realizada con éxito', 'Operacion realizada');
      await this.getDashboard();
      this.enableInterface();
    });
  }

  async addItemCashMachine(form: NgForm) {
    var id = parseInt(this.formCashMachine.get('cashMachine').value);
    var canAdd = true;
    this.dashboard.dashboardItems.forEach((element) => {
      if (element.cashMachineId == id) {
        this.notif.showError('No se puede agregar Máquina de Cambio que ya está agregada', 'Error!');
        canAdd = false;
      }
    });
    if (canAdd) {
      var result = await this.egmService.getCashMachine(id).toPromise();
      form.resetForm();
      var item: DashboardItem = new DashboardItem();
      item.cashMachine = result.data;
      item.cashMachineId = id;
      this.dashboard.dashboardItems.push(item);
      await this.DashboardService.saveDashboard(this.dashboard).toPromise();
      await this.getDashboard();
    }

    this.dismissModal();
  }

  async addItemEgm(form: NgForm) {
    var id = parseInt(this.formEgm.get('egm').value);
    var canAdd = true;
    this.dashboard.dashboardItems.forEach((element) => {
      if (element.egmId == id) {
        this.notif.showError('No se puede agregar una EGM que ya está agregada', 'Error!');
        canAdd = false;
      }
    });
    if (canAdd) {
      var result = await this.egmService.getEGM2(id).toPromise();
      form.resetForm();
      var item: DashboardItem = new DashboardItem();
      item.egm = result.data;
      item.egmId = item.egm.id;
      this.dashboard.dashboardItems.push(item);
      await this.DashboardService.saveDashboard(this.dashboard).toPromise();
      await this.getDashboard();
    }
    this.dismissModal();
  }

  async selectRoom() {
    if (this.roomForm.get('room').value != null) {
      this.mapInitialized = false;
      await this.getDashboard(true);
      this.updateRoom(Number(this.roomForm.get('room').value));
    }
    this.dismissModal();
  }

  async removeBackground() {
    this.disableInterface();
    await this.DashboardService.updateBackground(this.roomId, null).toPromise();
    this.mapInitialized = false;
    await this.getDashboard();
    this.enableInterface();
  }

  async addBackground(files: FileList) {
    this.disableInterface();
    if (files.item(0).type.toLowerCase() != 'image/png' && files.item(0).type.toLowerCase() != 'image/jpeg') {
      alert('Solo puede subirse imagenes.');
    } else {
      await this.DashboardService.updateBackground(this.roomId, files.item(0)).toPromise();
      this.mapInitialized = false;
      await this.getDashboard();
    }
    this.enableInterface();
  }

  async saveMapConfig() {
    this.disableInterface();
    var reload = false;

    if (this.markerSizeForm.get('markerSize').value != null) {
      this.dashboard.markersSize = Number(this.markerSizeForm.get('markerSize').value);
      reload = true;
    }

    if (this.markerSizeForm.get('minZoom').value != null) {
      this.dashboard.minZoom = Number(this.markerSizeForm.get('minZoom').value);
      this.mapInitialized = false;
      reload = true;
    }

    if (this.markerSizeForm.get('maxZoom').value != null) {
      this.dashboard.maxZoom = Number(this.markerSizeForm.get('maxZoom').value);
      this.mapInitialized = false;
      reload = true;
    }

    if (this.markerSizeForm.get('deltaZoom').value != null) {
      this.dashboard.deltaZoom = Number(this.markerSizeForm.get('deltaZoom').value);
      this.mapInitialized = false;
      reload = true;
    }

    if (reload) {
      this.dismissModal();
      await this.DashboardService.saveDashboard(this.dashboard).toPromise();
      await this.getDashboard();
    }

    this.enableInterface();
  }

  disableInterface() {
    this.processing = true;
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.dragging.disable();
      }
    });
  }

  enableInterface() {
    this.processing = false;
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.dragging.enable();
      }
    });
  }

  updateUserDashboard(that: DashboardComponent) {
    if (that.mapInitialized) {
      var save = {
        zoom: that.map.getZoom(),
        center: that.map.getCenter(),
      };
      localStorage.setItem('dashboardmap.' + that.username + '.' + that.roomId, JSON.stringify(save));
    }
  }
}
