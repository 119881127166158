import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { InsertDailyBalanceViewModel } from 'src/app/common/components/viewmodels/InsertDailyBalanceViewModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent implements OnInit {
  formEdit: FormGroup;
  formEdit2: FormGroup;
  title: string = 'Servicios';
  items: Array<BreadcrumbItem> = [];
  InputType = InputTypeEnum;
  // maxDate: moment.Moment;
  parsedLabel: string = 'Mensaje Parseado';

  public static getName() {
    return 'ServicesComponent';
  }
  constructor(
    private DailyBalanceService: DailyBalanceService,
    private EgmService: EgmServiceService,
    private notificationService: NotificationService,
    private CasinoService: CasinoService,
    private TicketService: TicketService,
    private printerService: PrinterService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.initForm();

    // this.CasinoService.getData().subscribe((result) => {
    //   this.maxDate = moment(result.data.dayStart);
    // });
  }

  onSubmit() {
    if (this.formEdit.valid) {
      var insertBalance: InsertDailyBalanceViewModel = new InsertDailyBalanceViewModel();
      insertBalance.date = moment(this.formEdit.get('date').value);
      insertBalance.egmId = Number(this.formEdit.get('egmId').value);
      this.DailyBalanceService.insertDailyBalance(insertBalance).subscribe();
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      date: new FormControl(null, [Validators.required]),
      egmId: new FormControl(null, [Validators.required]),
    });
    this.initForm2();
  }
  initForm2() {
    this.formEdit2 = new FormGroup({
      message: new FormControl(null),
      parsedMessage: new FormControl(null),
    });
  }
  calculateProfit() {
    this.DailyBalanceService.calculateProfit().subscribe((result) => {
      if (result != null) {
      }
    });
  }

  disabledAllEgms() {
    this.EgmService.disabledAllEgm().subscribe((result) => {
      if (result != null) {
      }
    });
  }

  checkEgmStatus() {
    this.DailyBalanceService.checkEgmStatus().subscribe((result) => {
      if (result != null) {
      }
    });
  }
  checkCashMachineStatus() {
    this.DailyBalanceService.checkCashMachineStatus().subscribe((result) => {
      if (result != null) {
      }
    });
  }

  checkVto() {
    this.TicketService.checkVto().subscribe((result) => {
      if (result != null) {
      }
    });
  }

  updateCounters() {
    this.DailyBalanceService.updateCounters().subscribe((result) => {
      if (result != null) {
      }
    });
  }

  async onParse() {
    this.formEdit2.get('parsedMessage').reset();

    // Veo si estoy imprimiendo:
    const menssageToPrint = this.formEdit2.get('message').value;

    if (menssageToPrint != null && menssageToPrint.toLowerCase().startsWith('print:')) {
      const print = menssageToPrint.substring(6);

      if (await this.printerService.isPrinterReady()) {
        console.log('print: ' + print);
        await this.printerService.print(print, 0);
      } else {
        this.notificationService.showError('Impresora no lista. ', 'Error');
      }
    } else {
      this.DailyBalanceService.parseMessage(this.formEdit2.get('message').value).subscribe((result) => {
        if (result.error != null) {
          this.formEdit2.get('parsedMessage').setValue(result.data);
          this.notificationService.showError('No se pudo parsear el mensaje. ' + result.error.generalMessage, 'Error');
        } else if (result.data != null) {
          this.formEdit2.get('parsedMessage').setValue(result.data);
          this.notificationService.showSuccess('Mensaje parseado', 'Éxito');
        } else {
          this.notificationService.showError('No se pudo parsear el mensaje. ' + result.error.generalMessage, 'Error');
        }
      });
    }
  }
}
