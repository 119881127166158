import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmButtonsComponent } from 'src/app/common/components/shared/confirm-buttons/confirm-buttons.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import {
  CashMachine,
  CashMachineConnectionViewModel,
  CashMachineCurrencyMapping,
} from 'src/app/common/components/viewmodels/cashMachine';
import { LocationService } from 'src/app/services/location/location.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { brandCashMachineEnum, getFriendlyName } from 'src/app/common/components/viewmodels/Enums';

@Component({
  selector: 'app-edit-cash-machine',
  templateUrl: './edit-cash-machine.component.html',
  styleUrls: ['./edit-cash-machine.component.css'],
})
export class EditCashMachineComponent implements OnInit {
  @ViewChild(ConfirmButtonsComponent, { static: false }) confirmButton: ConfirmButtonsComponent;

  locationList: Array<IdTextModel> = [];
  brandList: Array<IdTextModel> = [];

  formEdit: FormGroup;
  title: string = 'Nueva Máquina de Cambio / ATM';

  InputType = InputTypeEnum;
  showIp = false;
  showDb = false;
  items: Array<BreadcrumbItem> = [];

  cashMachine: CashMachine = new CashMachine();
  isEdit: boolean;
  showForm: boolean;
  parameter: tableStatus = null;
  isTestConnection = false;
  canEdit: boolean;

  public static getName() {
    return 'EditCashMachineComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private cashService: EgmServiceService,
    private LocationService: LocationService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.items.push(new BreadcrumbItem('/machines/cash-machine', 'Máquina de Cambio'));
    this.items.push(new BreadcrumbItem('/machines/cash-machine', 'Máquina de Cambio'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();
    await this.getLocations();
    this.getProtocols();

    this.parameter = this.paramService.getAndDeleteParameters();
    if (this.activatedRoute.snapshot.params.id != null) {
      this.cashService.getCashMachine(parseInt(this.activatedRoute.snapshot.params.id)).subscribe((result) => {
        this.formEdit.patchValue(result.data);

        const enumValue: brandCashMachineEnum = result.data.brand as brandCashMachineEnum;

        this.formEdit.get('brand').setValue(enumValue.toString());

        //la primera vez se selecciona el combo:
        this.setBrandCombo(enumValue.toString(), true);

        this.fillMapiing(result.data.cashMachineCurrencyMapping);

        this.canEdit = result.data.canEdit;
        if (result.data.eliminationDate != null) {
          this.formEdit.controls['eliminationDate'].setValue(moment(result.data.eliminationDate).format('YYYY-MM-DD'));
        }

        if (!this.canEdit) {
        } else {
          this.formEdit.controls['mac'].enable();
        }
      });

      this.title = 'Editar Máquina de Cambio / ATM';
      this.isEdit = true;
      this.formEdit.controls['muid'].disable();
      this.showForm = true;
    } else {
      //nueva cash machine:
      //  this.formEdit.controls['systemHostKey'].disable();
      //  this.formEdit.controls['eliminationDate'].disable();
      // this.formEdit.controls['ip'].value('')
      this.showForm = true;

      //sugiero valores de mapping x defecto:
      let cashMachineCurrencyMapping: Array<CashMachineCurrencyMapping> = [];

      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(1, '-500-', 50000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(2, '-200-', 20000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(3, '-100-', 10000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(4, '-50-', 5000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(4, 'JD50', 5000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(5, '-20-', 2000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(5, 'JD20', 2000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(6, '-10-', 1000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(6, 'JD10', 1000));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(7, '-5-', 500));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(8, '-2.0-', 200));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(9, '-1.0-', 100));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(10, '-.50-', 50));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(11, '-.20-', 20));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(12, '-.10-', 10));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(13, '-.05-', 5));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(14, '-.02-', 2));
      cashMachineCurrencyMapping.push(new CashMachineCurrencyMapping(15, '-.01-', 1));

      this.fillMapiing(cashMachineCurrencyMapping);
    }
  }
  getProtocols() {
    this.brandList.push(
      new IdTextModel(
        brandCashMachineEnum.MC_Gewete_GAPI_MC_Stock,
        getFriendlyName(brandCashMachineEnum.MC_Gewete_GAPI_MC_Stock)
      )
    );
    this.brandList.push(
      new IdTextModel(
        brandCashMachineEnum.MC_Gistra_GBG_HOST_Stock,
        getFriendlyName(brandCashMachineEnum.MC_Gistra_GBG_HOST_Stock)
      )
    );

    this.brandList.push(
      new IdTextModel(
        brandCashMachineEnum.MC_Gewete_GBG_GAPI_Host_Stock,
        getFriendlyName(brandCashMachineEnum.MC_Gewete_GBG_GAPI_Host_Stock)
      )
    );

    this.brandList.push(
      new IdTextModel(
        brandCashMachineEnum.ATM_DISPENSADOR_DE_CREDITOS_GBG_Sin_Stock,
        getFriendlyName(brandCashMachineEnum.ATM_DISPENSADOR_DE_CREDITOS_GBG_Sin_Stock)
      )
    );

    this.formEdit.get('brand').setValue(brandCashMachineEnum.MC_Gewete_GAPI_MC_Stock.toString());
    this.setBrandCombo(brandCashMachineEnum.MC_Gewete_GAPI_MC_Stock.toString());
  }

  fillMapiing(cashMachineCurrencyMapping: Array<CashMachineCurrencyMapping>) {
    cashMachineCurrencyMapping.forEach((element) => {
      const mappings = this.formBuilder.group({
        currencyTypeId: [element.currencyTypeId, Validators.required],
        trayIdentifier: [element.trayIdentifier, Validators.required],
        denomination: [element.denomination, [Validators.pattern('[0-9]*'), Validators.required]],
      });
      this.cashMachineCurrencyMapping.push(mappings);
    });
  }

  deleteMapping(index: number) {
    this.cashMachineCurrencyMapping.removeAt(index);
  }

  addmapping() {
    const mappings = this.formBuilder.group({
      currencyTypeId: [null, Validators.required],
      trayIdentifier: ['', Validators.required],
      denomination: [null, [Validators.pattern('[0-9]*'), Validators.required]],
    });
    //al inicio:
    // this.cashMachineCurrencyMapping.insert(0, mappings);
    //al final:
    this.cashMachineCurrencyMapping.push(mappings);
  }

  async getLocations() {
    this.LocationService.getLocationsCombo().subscribe((result) => {
      this.locationList = result.data;
    });
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('machines/cash-machine');
  }

  onSubmit() {
    if (this.formEdit.valid) {
      this.cashMachine = new CashMachine(this.formEdit.getRawValue());
      //this.cashMachine.id = Number(this.cashMachine.id);

      this.paramService.saveParameters(this.parameter);
      this.cashService.createOrEditCashMachine(this.cashMachine).subscribe((result) => {
        if (result.error == null) this.router.navigate(['/machines/cash-machine']);
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  onTestConnection() {
    if (
      !this.formEdit.get('ip').value ||
      !this.formEdit.get('user').value ||
      !this.formEdit.get('psw').value ||
      !this.formEdit.get('db').value ||
      !this.formEdit.get('port').value ||
      isNaN(Number(this.formEdit.get('port').value))
    ) {
      // Muestra un alert informando que los campos son obligatorios
      this.notificationService.showError(
        'Los campos Ip, User, Password, Base de Datos y Puerto (número) son obligatorios',
        'Error'
      );
    } else {
      this.isTestConnection = true;

      this.cashMachine = new CashMachine(this.formEdit.getRawValue());

      var cashMachineConnectionViewModel = new CashMachineConnectionViewModel();
      cashMachineConnectionViewModel.ip = this.cashMachine.ip;
      cashMachineConnectionViewModel.user = this.cashMachine.user;
      cashMachineConnectionViewModel.psw = this.cashMachine.psw;
      cashMachineConnectionViewModel.Db = this.cashMachine.db;
      cashMachineConnectionViewModel.port = this.cashMachine.port;

      this.cashService.testConnection(cashMachineConnectionViewModel).subscribe((result) => {
        this.isTestConnection = false;

        if (result.error == null && result.data == '') {
          this.notificationService.showSuccess('Conexión exitosa.', 'Éxito');
        } else {
          this.notificationService.showError(result.data, 'Error');
        }
      });
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(0),
      muid: new FormControl(null, [
        Validators.pattern('[0-9]*'),
        Validators.max(65535),
        Validators.min(1),
        Validators.required,
      ]),
      machineHostSerialNumber: new FormControl({ value: null, disabled: true }),
      mac: new FormControl(null, [Validators.required, Validators.maxLength(17)]),
      name: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      systemHostKey: new FormControl({ value: null, disabled: true }),
      description: new FormControl(null, Validators.maxLength(255)),
      timeOut: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.max(90000),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      heartBeat: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.max(90000),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      // 'cashinLimit': new FormControl(null,[Validators.required, Validators.pattern('[0-9]*'), Validators.max(99999999), Validators.maxLength(this.ValidationService.getMaxCashLimitedAllowed())]),
      cashinLimit: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*'), Validators.max(99999999)]),
      roomId: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      locationId: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      eliminationDate: new FormControl({ value: null, disabled: true }),
      brand: new FormControl([], [Validators.required]),
      // ip: new FormControl(null, [Validators.maxLength(15)]),
      ip: new FormControl({ value: 'null' }, [Validators.required, Validators.maxLength(15)]),
      db: new FormControl({ value: 'a' }, [Validators.required, Validators.maxLength(30)]),
      user: new FormControl({ value: null }, [Validators.required, Validators.maxLength(30)]),
      psw: new FormControl({ value: null }, [Validators.required, Validators.maxLength(30)]),
      port: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.max(9999999),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),

      cashMachineCurrencyMapping: this.formBuilder.array([]),
    });
  }

  get cashMachineCurrencyMapping() {
    return this.formEdit.controls['cashMachineCurrencyMapping'] as FormArray;
  }
  changeBrand($event: any) {
    this.setBrandCombo($event);
  }

  setBrandCombo(brandType, firstTime: boolean = false) {
    if (
      brandType == brandCashMachineEnum.MC_Gistra_GBG_HOST_Stock ||
      brandType == brandCashMachineEnum.MC_Gewete_GBG_GAPI_Host_Stock
    ) {
      this.showIp = true;
      //la primera vez no limpia el valor
      if (!firstTime) this.formEdit.get('ip').setValue(null);

      this.formEdit.get('ip').setValidators(Validators.required);

      if (brandType == brandCashMachineEnum.MC_Gistra_GBG_HOST_Stock) {
        this.formEdit.get('ip').setValidators(Validators.required);
        this.HideCleanDataDb();
      }

      if (brandType == brandCashMachineEnum.MC_Gewete_GBG_GAPI_Host_Stock) {
        this.showDb = true;
        this.formEdit.get('db').setValidators(Validators.required);
        this.formEdit.get('user').setValidators(Validators.required);
        this.formEdit.get('psw').setValidators(Validators.required);
        this.formEdit
          .get('port')
          .setValidators([
            Validators.required,
            Validators.pattern('[0-9]*'),
            Validators.max(9999999),
            Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
          ]);
      }
    } else {
      this.showIp = false;

      this.formEdit.get('ip').setValue(null);
      this.formEdit.get('ip').setValidators(null);

      this.HideCleanDataDb();
    }

    // Llama a updateValueAndValidity para todos los controles después de ajustar los validadores
    this.formEdit.get('ip').updateValueAndValidity();
    this.formEdit.get('db').updateValueAndValidity();
    this.formEdit.get('user').updateValueAndValidity();
    this.formEdit.get('port').updateValueAndValidity();
    this.formEdit.get('psw').updateValueAndValidity();
  }

  HideCleanDataDb() {
    this.showDb = false;

    this.formEdit.get('db').setValue(null);
    this.formEdit.get('db').clearValidators();

    this.formEdit.get('user').setValue(null);
    this.formEdit.get('user').setValidators(null);

    this.formEdit.get('port').setValue(null);
    this.formEdit.get('port').setValidators(null);

    this.formEdit.get('psw').setValue(null);
    this.formEdit.get('psw').setValidators(null);
  }
}
