<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <form
          *ngIf="formEdit !== null"
          role="form"
          [formGroup]="formEdit"
          (ngSubmit)="onSubmit(formObject)"
          #formObject="ngForm"
          #myForm="ngForm">
          <div class="card">
            <!-- <div class="card-header">
              <h3 class="card-title">
                <span> Editar Con</span>
              </h3>
            </div> -->
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('dataphoneAmount')"
                    [label]="'Pago Datáfono'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('billAmount')"
                    [label]="'Pago Factura'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('betAmount')"
                    [label]="'Pago Apuesta'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('expensesAmount')"
                    [label]="'Pago Gastos Varios'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowDataphone')"
                    [label]="'Autorizar Pago Datáfono'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowBill')"
                    [label]="'Autorizar Pago Factura'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowBet')"
                    [label]="'Autorizar Pago Apuesta'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowExpenses')"
                    [label]="'Autorizar Pago Gastos Varios'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('externalJackpotAmount')"
                    [label]="'Pago Jackpot Externo'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('manualCodeAmount')"
                    [label]="'Pago Manual Sin Alerta'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('manualTicketAmount')"
                    [label]="'Pago Manual Ticket No Reportado'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('impactCodeAmount')"
                    [label]="'Pago Incidencia'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowExternalJackpot')"
                    [label]="'Autorizar Pago Jackpot Externo'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowManualCode')"
                    [label]="'Autorizar Pago Manual Sin Alerta'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowManualTicket')"
                    [label]="'Autorizar Pago Manual Ticket No Reportado'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowImpactCode')"
                    [label]="'Autorizar Pago Incidencia'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('impactTicketAmount')"
                    [label]="'Pago Incidencia Con Ticket'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3"></div>
                <div class="form-group col-12 col-sm-6 col-xl-3"></div>
                <div class="form-group col-12 col-sm-6 col-xl-3"></div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="formEdit.get('allowImpactTicket')"
                    [label]="'Autorizar Pago Incidencia Con Ticket'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="btn btn-primary">Guardar</button>
              <button type="button" class="btn btn-secondary ml-2" (click)="goToErrorPage()">Volver</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal yes="Continuar" (onConfirm)="editConfiguration()" [body]="body"> </app-confirm-modal>
