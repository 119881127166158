<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="form-group row">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCreateButton()">
          <i class="fas fa-plus"></i> Agregar Spider Plus
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              (onEditButton)="onEditButton($event)"
              (onDeleteButton)="onDeleteButton($event)"
              (onSearch)="onSearch($event)"></app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-modal yes="Continuar" (onConfirm)="confirmDelete()" [body]="this.body"> </app-confirm-modal>
