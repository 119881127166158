import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { commonTableData, tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Spider } from 'src/app/common/components/viewmodels/Spider';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SpiderService } from 'src/app/services/spider/spider.service';

@Component({
  selector: 'app-spider-report',
  templateUrl: './spider-report.component.html',
  styleUrls: ['./spider-report.component.css'],
})
export class SpiderReportComponent implements OnInit {
  public data: commonTableData<Spider>;
  items: Array<BreadcrumbItem> = [];
  body: string;
  spider: Spider = new Spider();
  parameter: tableStatus = null;
  title: string = 'Spiders Plus';

  public static getName() {
    return 'SpiderReportComponent';
  }

  constructor(
    public spiderService: SpiderService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
    let service = this.spiderService;
    this.data = new commonTableData();
    let paramSpider = this.paramService.getAndDeleteParameters();

    if (paramSpider != null) {
      this.data.searchText = paramSpider.searchString;
      this.data.pageLength = paramSpider.pageLength;
      this.data.currentPage = paramSpider.currentPage;
    }
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.data.showAll = true;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Spider>>> {
      return service.getAllSpiders(param);
    };

    this.data.columns = [
      { data: 'code', title: 'Código' },

      { data: 'model', title: 'Modelo' },
      {
        data: 'ip',
        title: 'Dirección IP',
      },

      {
        data: 'mac',
        title: 'Dirección MAC',
      },
      {
        data: 'uidEgm',
        title: 'EGM Uid',
        render: function (data, type, row: Spider) {
          return data != null ? data : `-`;
        },
      },
      {
        data: 'checkSum',
        title: 'CheckSum',
      },
      {
        data: 'firmware',
        title: 'Firmware',
      },
      {
        data: 'manufacturingDate',
        title: 'Fecha de alta',
        render: function (data: moment.Moment, type, row) {
          // return moment(data).format('DD/MM/yyyy, HH:mm:ss');
          return moment(data).format('DD/MM/yyyy');
        },
      },
      {
        data: 'eliminationDate',
        title: 'Fecha Baja',
        render: function (data, type, row: Spider) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : `-`;
        },
      },
      {
        data: 'comments',
        title: 'Observaciones',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
    ];
  }
}
