import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Room } from 'src/app/common/components/viewmodels/Room';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
declare var $: any;

@Component({
  selector: 'app-list-rooms',
  templateUrl: './list-rooms.component.html',
  styleUrls: ['./list-rooms.component.css'],
})
export class ListRoomsComponent implements OnInit {
  @Input() isABMReport: boolean = true;
  public data: commonTableData<Room>;
  items: Array<BreadcrumbItem> = [];

  searchString: string;

  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Room>;

  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  room: Room = new Room();
  parameter: tableStatus = null;
  title: string = 'Salas (Áreas)';
  public static getName() {
    return 'ListRoomsComponent';
  }
  constructor(
    public RoomService: RoomServiceService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.data = new commonTableData();
    let paramRoom = this.paramService.getAndDeleteParameters();
    if (paramRoom != null) {
      this.data.searchText = paramRoom.searchString;
      this.data.pageLength = paramRoom.pageLength;
      this.data.currentPage = paramRoom.currentPage;
    }
    this.data.showEditButton = this.isABMReport;
    this.data.showDeleteButton = this.isABMReport;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;

    if (this.isABMReport) {
      let button: commonTableCustomButton = new commonTableCustomButton();
      button.icon = 'fa-map';
      button.columntitle = 'Editar plano';
      button.tooltip = 'Editar plano';
      button.order = 3;

      this.data.commonTableCustomButton.push(button);
    }
    this.setGridData(true);
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['establishment/rooms/edit-room', row.id]);
  }

  public onDeleteButton(row: Room): void {
    this.room = row;
    this.body = '¿Está seguro que desea eliminar la sala ' + this.room.name.toString() + '?';
    this.confirmModal.openModal();
  }

  public onCustomButton(data): void {
    this.router.navigate(['/dashboard/edit', data.row.id]);
  }

  confirmDelete() {
    this.RoomService.deleteRoom(this.room.id).subscribe((result) => {
      this.notificationService.showSuccess(`Se borro la sala ${result.data.name}`, 'Atención!');
      this.updateTable();
    });
  }

  setGridData(param1: boolean): void {
    let service = this.RoomService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Room>>> {
      return service.getRooms(param);
    };

    this.data.columns = [{ data: 'name', title: 'Nombre' }];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['establishment/rooms/create-room']);
  }
}
