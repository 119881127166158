import { TicketFamilyGroup } from './Enums';

export class TicketConfig {
  id: number;
  location: string;
  address1: string;
  address2: string;
  defaultExpiration: number;
  ticketPrint: boolean;
  maximumTicketSale: number;
  familyGroupId: TicketFamilyGroup;
  template: string;

  public constructor(init?: Partial<TicketConfig>) {
    Object.assign(this, init);
  }
}

export class PrintConfigViewModel {
  voucherRecipitTemplate: string;
}
