import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { param } from 'jquery';
import moment from 'moment';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { StateEgm } from 'src/app/common/components/viewmodels/Enums';
import {
  ButtonActionMap,
  EgmActionsViewModel,
  EgmStockTypeEnum,
} from 'src/app/common/components/viewmodels/SessioModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';

import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-session-egm-action',
  templateUrl: './session-egm-action.component.html',
  styleUrls: ['./session-egm-action.component.css'],
})
export class SessionEgmActionComponent implements OnInit, OnDestroy {
  public static getName() {
    return 'SessionEgmActionComponent';
  }
  public data: commonTableData<EgmActionsViewModel>;

  autoRefreshFunc: any = null;
  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<EgmActionsViewModel>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  egm: EgmActionsViewModel = new EgmActionsViewModel();
  parameter: tableStatus = null;
  showUpdateFir: boolean;
  loadedForm: boolean;
  canCollect: boolean;
  canRetirar: boolean;
  canReponer: boolean;
  canReserve: boolean;
  canReserveOn: boolean;
  canReserveOff: boolean;
  buttonActionMaps: ButtonActionMap[] = [];
  loaded: boolean = false;

  @ViewChild('confirmModalDisabledEgm', { static: false }) confirmModalDisabledEgm: ConfirmModalComponent;
  @ViewChild('confirmModalStartupEgm', { static: false }) confirmModalStartupEgm: ConfirmModalComponent;

  title: string = 'Operaciones EGMs';
  constructor(
    public sessionService: SessionService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private casinoService: CasinoService,
    public egmService: EgmServiceService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    // ngOnInit() {

    this.canReserveOn = true;
    this.canReserveOff = true;

    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.showUpdateFir = (
      await this.casinoService.isComponentAuthorized('EditFirmwareComponent', 'Page').toPromise()
    ).data;

    this.data = new commonTableData();
    let paramEGM = this.paramService.getAndDeleteParameters();

    if (paramEGM != null) {
      this.data.searchText = paramEGM.searchString;
      this.data.pageLength = paramEGM.pageLength;
      this.data.currentPage = paramEGM.currentPage;
    }
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    let button: commonTableCustomButton = new commonTableCustomButton();

    this.canCollect = (
      await this.casinoService.isComponentAuthorized('SessionCollectionComponent', 'Page').toPromise()
    ).data;
    this.canRetirar = (
      await this.casinoService.isComponentAuthorized('SessionStockegmOutComponent', 'Page').toPromise()
    ).data;
    this.canReponer = (
      await this.casinoService.isComponentAuthorized('SessionStockegmInComponent', 'Page').toPromise()
    ).data;

    this.canReserve = (
      await this.casinoService.isComponentAuthorized('SessionCanReserveComponent', 'Confirm').toPromise()
    ).data;

    // Agrega la acción para el botón de Recaudar
    if (this.canCollect) {
      let button = new commonTableCustomButton();
      button.icon = 'fa-solid fa-money-bill-wave';
      button.columntitle = 'Recaudar';
      button.tooltip = 'Recaudar';
      this.data.commonTableCustomButton.push(button);

      this.buttonActionMaps.push({
        index: this.data.commonTableCustomButton.length - 1, // Asegura que el índice es correcto
        actionId: 'canCollect', // Identificador único para esta acción
        action: (rowId: string) => {
          this.paramService.saveParameters(this.parameter);
          this.router.navigate(['session-collection', rowId]);
        },
      });
    }

    if (this.canRetirar) {
      let button = new commonTableCustomButton();
      button.icon = 'fas fa-hand-holding-usd';
      button.columntitle = 'Retirada';
      button.tooltip = 'Retirada';
      this.data.commonTableCustomButton.push(button);

      this.buttonActionMaps.push({
        index: this.data.commonTableCustomButton.length - 1,
        actionId: 'canRetirar',
        action: (rowId: string) => {
          this.paramService.saveParameters(this.parameter);
          this.router.navigate(['session-stock-egm-out', rowId], {
            queryParams: { m: Number(EgmStockTypeEnum.Retirar) },
          });
        },
      });
    }

    if (this.canReponer) {
      let button = new commonTableCustomButton();
      button.icon = 'fas fa-donate';
      button.columntitle = 'Reposición';
      button.tooltip = 'Reposición';
      this.data.commonTableCustomButton.push(button);

      this.buttonActionMaps.push({
        index: this.data.commonTableCustomButton.length - 1,
        actionId: 'canReponer',
        action: (rowId: string) => {
          this.paramService.saveParameters(this.parameter);
          this.router.navigate(['session-stock-egm-in', rowId], {
            queryParams: { m: Number(EgmStockTypeEnum.Reponer) },
          });
        },
      });
    }

    if (this.canReserve) {
      let button = new commonTableCustomButton();
      button.icon = 'fas fa-toggle-off';
      button.columntitle = 'Reservar Sí';
      button.tooltip = 'Reservar Sí';
      this.data.commonTableCustomButton.push(button);

      this.buttonActionMaps.push({
        index: this.data.commonTableCustomButton.length - 1,
        actionId: 'canReserveSi', // Corrección aquí
        action: (rowId: string, emgId: number) => {
          this.onDisabledEgmButton(emgId);
        },
      });
    }

    if (this.canReserve) {
      let button = new commonTableCustomButton();
      button.icon = 'fas fa-toggle-on';
      button.columntitle = 'Reservar No';
      button.tooltip = 'Reservar No';
      this.data.commonTableCustomButton.push(button);

      this.buttonActionMaps.push({
        index: this.data.commonTableCustomButton.length - 1,
        actionId: 'canReserveNo', // Corrección aquí
        action: (rowId: string, egmId: number) => {
          this.onStartupEgmButton(egmId);
        },
      });
    }

    await this.setGridData(true);

    this.loadedForm = true;
    this.autoRefresh();
  }

  ngOnDestroy() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);
  }
  async setGridData(param1: boolean): Promise<void> {
    let service = this.sessionService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<EgmActionsViewModel>>> {
      return service.GetEgmToAction(param);
    };

    let that = this;

    this.data.columns = [
      { data: 'uid', title: 'UID' },
      { data: 'nickName', title: 'EGM Nombre' },
      {
        data: 'statusEgm',
        title: 'Últ. Estado',
        render: function (data, type, row) {
          return data != null ? StateEgm[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },

      {
        data: 'lastCollection',
        title: 'Últ. Recaudación',
        render: function (data, type, row) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
        },
      },
    ];

    this.data.rowCallback = function (row: Node, data: EgmActionsViewModel | Object, index: number): void {
      if (data['wasClollectedRecently']) {
        that.resalt(row);
      }

      if (!data['canReserveNo']) {
        let canReserveNoButton = that.buttonActionMaps.find((map) => map.actionId === 'canReserveNo');
        const indexButton = canReserveNoButton.index;
        $($('td a', row)[indexButton]).addClass('hideVisivilityButton');
      }
      if (!data['canReserveYes']) {
        let canReserveNoButton = that.buttonActionMaps.find((map) => map.actionId === 'canReserveSi');
        const indexButton = canReserveNoButton.index;
        $($('td a', row)[indexButton]).addClass('hideVisivilityButton');
      }
    };
  }

  resalt(row: Node) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // $('tr', row).addClass('resalt'); //Success
      $(row).addClass('resaltRow');
      // $($('td', row)[3]).css('font-weight', 'bold');
    });
  }

  updateTable() {
    this.loadedForm = false;
    this.setGridData(true);
    this.table.reload();

    setTimeout(() => {
      this.cdr.detectChanges();
      this.loadedForm = true;
    }, 200);
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  public onCustomButton(data): void {
    let indexButton = parseInt(data.indexButton);
    let actionMap = this.buttonActionMaps.find((map) => map.index === indexButton);

    if (actionMap) {
      actionMap.action(data.row.id, data.row.id);
    } else {
      console.log('Acción no encontrada para el índice de botón:', indexButton);
    }
  }

  autoRefresh() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    const timeIntevalSeconds = 25;

    this.autoRefreshFunc = setTimeout(async () => {
      if (this.parameter != null) {
        this.data.searchText = this.parameter.searchString;
        this.data.pageLength = this.parameter.pageLength;
        this.data.currentPage = this.parameter.currentPage;
      }

      this.updateTable();
      this.autoRefresh();
    }, timeIntevalSeconds * 1000);
  }

  currentEgmId: number = 0;

  onDisabledEgmButton(egmUid: number) {
    this.currentEgmId = egmUid;
    this.body = '¿Está seguro que desea reservar la máquina?';
    this.confirmModalDisabledEgm.openModal();
  }

  onStartupEgmButton(egmUid: number) {
    this.currentEgmId = egmUid;
    this.body = '¿Está seguro que desea liberar la EGM reservarda?';
    this.confirmModalStartupEgm.openModal();
  }

  disabledEgm() {
    this.egmService.getEGM2(this.currentEgmId).subscribe((result) => {
      this.egmService.disabledEgm(result.data).subscribe((result) => {});
    });
  }

  startupEgm() {
    //this.egmService.startUpEGM().subscribe((result) => {});
    this.egmService.getEGM2(this.currentEgmId).subscribe((result) => {
      this.egmService.startUpEGM(result.data).subscribe((result) => {});
    });
  }
}
