import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { SessionManagmentViewModel, SettlmentTypeEnum } from 'src/app/common/components/viewmodels/SessioModel';

@Component({
  selector: 'app-session-settlement',
  templateUrl: './session-settlement.component.html',
  styleUrls: ['./session-settlement.component.css'],
})
export class SessionSettlementComponent implements OnInit {
  public static getName() {
    return 'SessionSettlementComponent';
  }

  items: Array<BreadcrumbItem> = [];
  tittle: string = 'Arqueo de Caja';
  cashRegisterId: number;
  specifTurnId: number = 0;
  loadPage: boolean = false;
  settlmentType: SettlmentTypeEnum = SettlmentTypeEnum.ReadOnly;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    let mode: string = this.activatedRoute.snapshot.queryParamMap.get('m');
    let specifTurnIdParam: string | null = this.activatedRoute.snapshot.queryParamMap.get('turnId');

    //sino viene parametro es solo lectura:

    if (mode == null && mode == '0') {
      this.settlmentType = SettlmentTypeEnum.ReadOnly;
    } else if (mode == '1') {
      this.settlmentType = SettlmentTypeEnum.OpenTurn;
    } else if (mode == '2') {
      this.settlmentType = SettlmentTypeEnum.EndTurn;
    } else if (mode == '3') {
      this.settlmentType = SettlmentTypeEnum.ReadOnlySpecifTurn;
      if (specifTurnIdParam === null || isNaN(Number(specifTurnIdParam))) {
        this.router.navigate(['/session-management']);
      } else {
        this.specifTurnId = Number(specifTurnIdParam);
      }
    }

    if (this.settlmentType == SettlmentTypeEnum.ReadOnly) {
      this.tittle = 'Consulta - Arqueo de Caja';
    } else if (this.settlmentType == SettlmentTypeEnum.OpenTurn) {
      this.tittle = 'Iniciar Turno - Arqueo de Caja';
    } else if (this.settlmentType == SettlmentTypeEnum.EndTurn) {
      this.tittle = 'Cerrar Turno - Arqueo de Caja';
    } else if (this.settlmentType == SettlmentTypeEnum.ReadOnlySpecifTurn) {
      this.tittle = 'Consulta - Turno cerrado';
    }

    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', 'Arqueo de Caja'));

    if (this.settlmentType != SettlmentTypeEnum.ReadOnlySpecifTurn) {
      if (this.activatedRoute.snapshot.params.id == null || this.activatedRoute.snapshot.params.id == 0) {
        this.router.navigate(['/session-management']);
      }
      this.cashRegisterId = this.activatedRoute.snapshot.params.id;
    }
    this.loadPage = true;
  }

  //notifico cunado tengo datos de la session por si quiero mostrar info de la session en el titulo
  sessionChanged(session: SessionManagmentViewModel) {}
}
