import { EGMMock } from './EGMMock';

export class CountersBase {
  egm: EGMMock;
  egmId: number;
  coinIn?: number;
  coinOut?: number;
  jackpot?: number;
  handpayCC?: number;
  cancelledCredits?: number;
  mppp?: number;
  mpebp?: number;
  appp?: number;
  apebp: number;
  TicketCashIn?: number;
  TicketCashInQ?: number;
  TicketCashOut?: number;
  TicketCashOutQ?: number;
  TicketRPIn?: number;
  TicketRPromInQ?: number;
  ticketNoRestPromIn?: number;
  ticketNoRestPromInQ?: number;
  ticketPromOut?: number;
  TicketRestPromOutQ?: number;
  billIn?: number;
  billOut?: number;
  trueCoinIn?: number;
  trueCoinOut?: number;
  coinDrop?: number;
  totalDrop?: number;
  eftIn?: number;
  watIn?: number;
  watOut?: number;
  gamesPlayed?: number;
  gamesWon?: number;
  gamesLost?: number;
  doorQ?: number;
  powerReset?: number;
  billM1Q?: number;
  billM2Q?: number;
  billM3Q?: number;
  billM4Q?: number;
  billM5Q?: number;
  billM6Q?: number;
  billM7Q?: number;
  billM8Q?: number;
  billTotalQ?: number;
  billToDrop?: number;
  billToRecycle?: number;

  gamesSincePowerUp?: number;
  gamesSinceDoorClosed?: number;
  currentHopperLevel?: number;
  totalHandPaidCredits?: number;
  reserved1?: number;
  reserved2?: number;
  reserved3?: number;
  reserved4?: number;
  reserved5?: number;
  reserved6?: number;
  reserved7?: number;
  reserved8?: number;
  reserved9?: number;
  reserved10?: number;
  reserved11?: number;
  reserved12?: number;
  reserved13?: number;
  reserved14?: number;
  reserved15?: number;
  reserved16?: number;
}
