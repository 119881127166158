<common-header [title]="title" [items]="items"></common-header>

<form #formObject2="ngForm">
  <div class="content">
    <div class="container-fluid">
      <div class="" *ngIf="loadData">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                <span> {{ headerTitle }}</span>
              </h3>
              <div class="card-tools">
                <a
                  *ngIf="this.session.nextTurnId > 0"
                  href="javascript:void(0)"
                  (click)="goToNexCashRegister()"
                  style="margin-right: 30px; text-decoration: underline"
                  >Ir a {{ this.session.nextCashRegisterName }}</a
                >
                <button
                  #myCollapse
                  type="button"
                  style="margin-right: 10px; text-decoration: underline"
                  data-card-widget="collapse">
                  <i id="toolBtn" class="fas fa-minus"></i>
                </button>
              </div>
            </div>

            <div class="card-body">
              <div class="row" style="margin-left: -11.5px">
                <a class="btn btn-app custombutton" (click)="GoToSettlement()">
                  <i class="fas fa-check-square"></i> Arqueo de Caja
                </a>

                <a class="btn btn-app custombutton" (click)="GoToPayDatafono()">
                  <i class="fas fa-credit-card"></i> Pago Datáfono
                </a>
                <a class="btn btn-app custombutton" (click)="GoToPayInvoice()">
                  <i class="fas fa-receipt"></i> Pago Factura
                </a>
                <a class="btn btn-app custombutton" (click)="GoToPayBets()">
                  <i class="fas fa-receipt"></i> Pago Apuestas
                </a>
                <a class="btn btn-app custombutton" (click)="GoToExpensesMiscellaneous()">
                  <i class="fas fa-receipt"></i> Pago Gastos Varios
                </a>
                <a class="btn btn-app custombutton" (click)="GoToIncreaseFunds()" *ngIf="showcashflowButtons">
                  <i class="fas fa-level-up-alt"></i> Aumento de Fondo
                </a>
                <a class="btn btn-app custombutton" (click)="GoToReduceFunds()" *ngIf="showcashflowButtons"
                  ><i class="fas fa-level-down-alt"></i> Disminución de Fondo</a
                >
                <a class="btn btn-app custombutton" (click)="GoToIncreaseFundsCashier()"
                  ><i class="fas fa-dollar-sign"></i> Reposición Efectivo</a
                >
                <a class="btn btn-app custombutton" (click)="GoToReduceFundsCashier()"
                  ><i class="fas fa-hand-holding-usd"></i> Retirada Efectivo</a
                >
                <a class="btn btn-app custombutton" (click)="GoToConciliation()" *ngIf="showconciliate"
                  ><i class="fas fa-handshake"></i> Conciliaciones</a
                >
                <a class="btn btn-app custombutton" (click)="GoToHandypayPayment()"
                  ><i class="fas fa-bell"></i> Alerta Pago Manual</a
                >
                <!-- <a class="btn btn-app custombutton" (click)="GoToManualPayment()"
                  ><i class="fas fa-print"></i> Pago Manual</a
                > -->
                <a class="btn btn-app custombutton" (click)="GoToPayJackPot()">
                  <i class="fas fa-hands"></i> Pago Jackpot Externo
                </a>

                <a class="btn btn-app custombutton" (click)="GoToManualIncidencePayment()"
                  ><i class="fas fa-edit"></i> Pago Incidencia</a
                >
                <a class="btn btn-app custombutton" (click)="GoToReplacementTicket()"
                  ><i class="fas fa-copy"></i> Reemplazo de ticket</a
                >
                <a *ngIf="isCashSalon" class="btn btn-app custombutton" (click)="GoToGeneratTito()"
                  ><i class="fas fa-dice"></i> Emitir TITO</a
                >
                <a *ngIf="isCashSalon" class="btn btn-app custombutton" (click)="GoToPayTito()"
                  ><i class="fas fa-money-bill-wave-alt"></i>Abonar TITO</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-session-details
        [turnSelectedChange]="eventsChangeTurn.asObservable()"
        [session]="session"></app-session-details>
    </div>
  </div>
</form>
