import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { FullUserViewModel } from 'src/app/common/components/viewmodels/User';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { NgForm } from '@angular/forms';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';

declare var $: any;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Nuevo Usuario';

  items: Array<BreadcrumbItem> = [];

  InputType = InputTypeEnum;

  isEdit: boolean;

  rolesList: Array<IdTextModel> = [];

  user: FullUserViewModel = new FullUserViewModel();

  parameter: tableStatus = null;
  labelPassword: string = '*Password';
  labelConfirmPassword: string = '*Confirmar Password';
  canEdit: boolean = false;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  public static getName() {
    return 'EditUserComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private UserService: UsersService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('/users/list', 'Seguridad'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();
    this.parameter = this.paramService.getAndDeleteParameters();

    this.UserService.getAllRolesCombo().subscribe((result) => {
      this.rolesList = result.data;

      if (this.activatedRoute.snapshot.params.id != null) {
        this.UserService.GetUserById(this.activatedRoute.snapshot.params.id).subscribe((result) => {
          if (result.error == null) {
            this.formEdit.patchValue(result.data);
            this.canEdit = result.data.canEdit;

            this.formEdit.get('password').setValue(null);
            this.formEdit.get('confirmpassword').setValue(null);

            // this.formEdit.get('password').clearValidators();
            // this.formEdit.get('password').setErrors(null);

            // this.formEdit.get('confirmpassword').clearValidators();
            // this.formEdit.get('confirmpassword').setErrors(null);

            this.labelPassword = 'Password';
            this.labelConfirmPassword = 'Confirmar Password';
          } else {
            this.notificationService.showError('Error al editar el usuario.', 'Error');
          }
        });

        this.title = 'Editar Usuario';
        this.isEdit = true;
        this.formEdit.get('userName').disable();
      } else {
        this.title = 'Nuevo Usuario';
        this.isEdit = false;
        this.labelPassword = '*Password';
        this.labelConfirmPassword = '*Confirmar Password';
        this.formEdit.get('userName').enable();
      }

      this.formEdit.controls['password'].valueChanges.subscribe((newValue) => {
        if (this.isEdit) {
          if (newValue != null && newValue != '') {
            //  let oldValue = this.formEdit.get('password').value;

            //si era vacio lo agrego
            //   if(oldValue ==null || oldValue == ''){
            this.formEdit
              .get('password')
              .setValidators([
                Validators.required,
                Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
                Validators.minLength(6),
              ]);
            this.formEdit.get('password').setValue(newValue, { emitEvent: false });
            // }
          } else {
            this.formEdit.get('password').clearValidators();
            this.formEdit.get('password').setErrors(null);
          }

          this.formEdit.updateValueAndValidity();
          this.formEdit.get('password').markAsTouched();
        }
      });
    });
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('users/list');
  }

  onSubmit() {
    this.formEdit.markAllAsTouched();
    if (this.formEdit.valid) {
      this.user = new FullUserViewModel(this.formEdit.value);
      //  this.user.id = Number(this.user.id);
      this.paramService.saveParameters(this.parameter);
      this.UserService.createOrEdit(this.user).subscribe((result) => {
        if (result.error == null) {
          this.router.navigate(['/users/list']);
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup(
      {
        id: new FormControl(),
        userName: new FormControl({ value: null, disabled: true }, [
          Validators.required,
          Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
        ]),
        name: new FormControl(null, [
          Validators.required,
          Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
        ]),
        lastName: new FormControl(null, [
          Validators.required,
          Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
        ]),
        password: new FormControl(null, [
          Validators.required,
          Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
          Validators.minLength(6),
        ]),
        confirmpassword: new FormControl(null, [Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())]),
        roles: new FormControl(null, [
          Validators.required,
          Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
        ]),
        email: new FormControl(null, [Validators.maxLength(40)]),
        dni: new FormControl(null, [Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())]),
        phone: new FormControl(null, [Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())]),
      },

      {
        validators: (control) => {
          //if ( control.value.password !== '' && control.value.password !== control.value.confirmpassword) {
          if (control.value.password !== control.value.confirmpassword) {
            control.get('confirmpassword').setErrors({ notSame: true });
          } else {
            //    control.get("confirmpassword").setErrors({ notSame: false });
          }
          return null;
        },
      }
    );
  }
}
