import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { TicketHistoryComponent } from '../ticket-history/ticket-history.component';
import { TicketConfigService } from 'src/app/services/ticket-config/ticket-config.service';

@Component({
  selector: 'app-printer-config',
  templateUrl: './printer-config.component.html',
  styleUrls: ['./printer-config.component.css'],
})
export class PrinterConfigComponent implements OnInit {
  formEdit: FormGroup;
  items: Array<BreadcrumbItem> = [];
  baudsList: Array<IdTextModel> = [];
  dataBitsList: Array<IdTextModel> = [];
  stopBitsList: Array<IdTextModel> = [];
  parityList: Array<IdTextModel> = [];
  flowControlList: Array<IdTextModel> = [];
  isFakeList: Array<IdTextModel> = [];

  autoRefreshFunc: any = null;

  public static getName() {
    return 'PrinterConfigComponent';
  }

  constructor(public printerService: PrinterService, private ticketConfigService: TicketConfigService) {
    this.baudsList.push(new IdTextModel(9600, '9600'));
    this.baudsList.push(new IdTextModel(14400, '14400'));
    this.baudsList.push(new IdTextModel(19220, '19220'));
    this.baudsList.push(new IdTextModel(28800, '28800'));
    this.baudsList.push(new IdTextModel(38400, '38400'));
    this.baudsList.push(new IdTextModel(57600, '57600'));
    this.baudsList.push(new IdTextModel(115200, '115200'));

    this.dataBitsList.push(new IdTextModel(7, '7'));
    this.dataBitsList.push(new IdTextModel(8, '8'));

    this.stopBitsList.push(new IdTextModel(1, '1'));
    this.stopBitsList.push(new IdTextModel(2, '2'));

    this.parityList.push(new IdTextModel(0, 'None'));
    this.parityList.push(new IdTextModel(1, 'Odd'));
    this.parityList.push(new IdTextModel(2, 'Even'));

    this.flowControlList.push(new IdTextModel(0, 'Hardware'));
    this.flowControlList.push(new IdTextModel(1, 'Xon/Xoff'));

    this.isFakeList.push(new IdTextModel(0, 'No'));
    this.isFakeList.push(new IdTextModel(1, 'Si'));
    // options.xany;
  }

  async testPrinter() {
    if (await this.printerService.isPrinterReady()) {
      var ticketQuantity = 1;

      var error = false;

      for (let index = 1; index <= ticketQuantity && !error; index++) {
        if (await this.printerService.isPrinterReady()) {
          var result = await this.ticketConfigService.GenerateTestTicket().toPromise();
          if (result?.data?.tickets.length > 0) {
            await this.printerService.print(result?.data?.tickets[0], index - 1);
          } else {
            error = true;
          }
        } else {
          error = true;
        }
      }
    }
  }

  async ngOnInit() {
    this.items.push(new BreadcrumbItem('', 'Configuración Salón'));
    this.items.push(new BreadcrumbItem('', 'Conexión Impresora Tickets'));

    this.formEdit = new FormGroup({
      baudRate: new FormControl(null),
      dataBits: new FormControl(null),
      parity: new FormControl(null),
      stopBits: new FormControl(null),
      flowControl: new FormControl(null),
      isFake: new FormControl(null),
    });
    if (this.printerService.isBrowserSupported) {
      this.formEdit.get('baudRate').setValue(this.printerService.printerOptions.baudRate?.toString());
      this.formEdit.get('dataBits').setValue(this.printerService.printerOptions.dataBits?.toString());

      if (this.printerService.printerOptions.parity == 'none') this.formEdit.get('parity').setValue('0');
      if (this.printerService.printerOptions.parity == 'odd') this.formEdit.get('parity').setValue('1');
      if (this.printerService.printerOptions.parity == 'even') this.formEdit.get('parity').setValue('2');

      this.formEdit.get('stopBits').setValue(this.printerService.printerOptions.stopBits?.toString());

      if (this.printerService.printerOptions.xonXoff) this.formEdit.get('flowControl').setValue('1');
      else this.formEdit.get('flowControl').setValue('0');
    }

    if (this.printerService.printerOptions.isFake) this.formEdit.get('isFake').setValue('1');
    else this.formEdit.get('isFake').setValue('0');
  }

  async updateSettings() {
    this.printerService.printerOptions.baudRate = Number(this.formEdit.get('baudRate').value);
    this.printerService.printerOptions.dataBits = Number(this.formEdit.get('dataBits').value);

    if (this.formEdit.get('parity').value == '0') this.printerService.printerOptions.parity = 'none';
    if (this.formEdit.get('parity').value == '1') this.printerService.printerOptions.parity = 'odd';
    if (this.formEdit.get('parity').value == '2') this.printerService.printerOptions.parity = 'even';

    this.printerService.printerOptions.stopBits = Number(this.formEdit.get('stopBits').value);
    this.printerService.printerOptions.xonXoff = this.formEdit.get('flowControl').value == '1';

    this.printerService.printerOptions.isFake = this.formEdit.get('isFake').value == '1';

    this.printerService.saveToStorage();
  }
}
