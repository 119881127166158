import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { commonTableData } from 'src/app/common/components/shared/common-table/common-table.component';
import { ApiResult } from 'src/app/common/components/viewmodels/apiResult';
import { Casino, CasinoTest } from 'src/app/common/components/viewmodels/Casino';
import { Room } from 'src/app/common/components/viewmodels/Room';
import { CasinoService } from 'src/app/services/casino/casino.service';
import * as moment from 'moment-timezone';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-casino-data',
  templateUrl: './casino-data.component.html',
  styleUrls: ['./casino-data.component.css'],
})
export class CasinoDataComponent implements OnInit {
  public data: commonTableData<Room>;
  casinoData: Casino = new Casino();
  items: Array<BreadcrumbItem> = [];
  title: string = 'Acerca de';
  moment: any = moment;
  showEdit: Observable<boolean>;

  public static getName() {
    return 'CasinoDataComponent';
  }
  constructor(public casinoService: CasinoService, private router: Router) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.casinoService.getCasinoConfigData().subscribe((result) => {
      this.casinoData = result.data;
    });

    this.showEdit = this.casinoService
      .isComponentAuthorized('CasinoDataEditComponent', 'Page')
      .pipe(map((u) => u && u.data));
  }

  getValue(value?: any) {
    return value != null ? value : 'No notifica';
  }

  editParameters() {
    this.router.navigate(['establishment/about/edit-parameters']);
  }
}
