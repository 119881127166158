import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'common-breadcrumb',
  templateUrl: './common-breadcrumb.component.html',
  styleUrls: ['./common-breadcrumb.component.css'],
})
export class CommonBreadcrumbComponent implements AfterViewInit {
  @Input() items: Array<BreadcrumbItem> = [];

  arraySize: number = 0;
  last: BreadcrumbItem;

  constructor() {}

  ngAfterViewInit(): void {
    this.arraySize = this.items.length;
    this.last = this.items.pop();
  }
}

export class BreadcrumbItem {
  route: string;
  text: string;

  constructor(route: string, text: string) {
    this.route = route;
    this.text = text;
  }
}
