import { PercentPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { EgmProfitParameters } from 'src/app/common/components/business/egm-profit/egm-profit.component';
import { GametypesComboComponent } from 'src/app/common/components/business/gametypes-combo/gametypes-combo.component';
import { ManufacturersComboComponent } from 'src/app/common/components/business/manufacturers-combo/manufacturers-combo.component';
import { ModelsComboComponent } from 'src/app/common/components/business/models-combo/models-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CalculatedCountersViewModel } from 'src/app/common/components/viewmodels/CalculatedCountersViewModel';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { CountersStatus } from 'src/app/common/components/viewmodels/Enums';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { CountersService } from 'src/app/services/counters/counters.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-ticket-counters-report',
  templateUrl: './ticket-counters-report.component.html',
  styleUrls: ['./ticket-counters-report.component.css'],
})
export class TicketCountersReportComponent implements OnInit {
  @ViewChild(ModelsComboComponent) modelsCombo: ModelsComboComponent;
  @ViewChild(GametypesComboComponent) gametypesCombo: GametypesComboComponent;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(ManufacturersComboComponent) manufacturersCombo: ManufacturersComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CalculatedCountersViewModel>;

  viewCombo: Array<IdTextModel> = [];
  locationCombo: Array<IdTextModel> = [];

  title: string = 'Contadores Tickets';

  items: Array<BreadcrumbItem> = [];

  public data: commonTableData<CalculatedCountersViewModel>;

  formSearch: FormGroup;
  paramStatus: boolean;
  dateRange: string;

  public static getName() {
    return 'TicketCountersReportComponent';
  }
  constructor(
    private router: Router,
    private CountersService: CountersService,
    private paramService: ParametersService<EgmProfitParameters>,
    private percentPipe: PercentPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.initForm();

    let param = this.paramService.getAndDeleteParameters();

    this.initGrid();

    if (param != null) {
      this.formSearch.get('manufacturers').setValue([param.idManufacturer]);
      this.formSearch.get('models').setValue([param.idModel]);
      this.formSearch.get('gameTypes').setValue([param.idGameType]);
      this.formSearch.get('egmUid').setValue([param.idEgm]);

      this.formSearch.get('from').setValue(param.from);
      this.formSearch.get('to').setValue(param.to);
      // this.onSubmit();
      this.paramStatus = true;
    }
  }
  ngAfterViewInit(): void {
    if (this.paramStatus) {
      this.onSubmit();
    }
  }
  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    const that = this;

    this.data.columns = [
      {
        data: 'counters.countersStatus',
        title: 'Estado',
        render: function (data: number, type, row) {
          return CountersStatus[data];
        },
      },
      {
        data: 'counters.countersDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'counters.period', title: 'Período' },
      { data: 'counters.egm.uid', title: 'EGM' },
      { data: 'counters.egm.manufacturer.name', title: 'Fabricante' },
      { data: 'counters.egm.model.name', title: 'Modelo' },
      { data: 'counters.egm.egmConfig.formattedDenomination', title: 'Denominación' },
      { data: 'counters.ticketCashIn', title: 'TCK_IN Cashable' },
      { data: 'counters.ticketCashInQ', title: 'TCK_IN Cashable Q' },
      { data: 'counters.ticketRPIn', title: 'TCK_IN Restr' },
      { data: 'counters.ticketRPromInQ', title: 'TCK_IN Restr Q' },
      { data: 'counters.ticketNoRestPromIn', title: 'TCK_IN No Restr' },
      { data: 'counters.ticketNoRestPromInQ', title: 'TCK_IN No Restr Q' },
      { data: 'counters.totalTicketIn', title: 'Total TCK_IN' },
      { data: 'counters.ticketCashOut', title: 'TCK_OUT Cashable' },
      { data: 'counters.ticketCashOutQ', title: 'TCK_OUT Cashable Q' },
      { data: 'counters.ticketRestPromOut', title: 'TCK_OUT Restr' },
      { data: 'counters.ticketRestPromOutQ', title: 'TCK_OUT Restr Q' },
      { data: 'counters.totalTicketOut', title: 'Total TCK_OUT' },
    ];
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.CountersService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CalculatedCountersViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getCounterssWithMultiFilters(DataTableFilters);
    };
  }

  updateTable(filters: MultiselectFilters) {
    this.setGridData(filters);
    this.filterString();
    this.table.reload();
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl([]),
      manufacturers: new FormControl([]),
      models: new FormControl([]),
      gameTypes: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      if (!this.formSearchNull) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }
    return filters;
  }

  onSubmit() {
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();

    this.updateTable(this.getFilters());
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    if (this.formSearch.get('manufacturers').value.length > 0) {
      aux += ' Fabricante: ' + this.manufacturerFilters + ' - ';
    }
    if (this.formSearch.get('models').value.length > 0) {
      aux += ' Modelo: ' + this.modelFilters + ' - ';
    }
    if (this.formSearch.get('gameTypes').value.length > 0) {
      aux += ' Juegos: ' + this.gamesFilters + ' - ';
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters;
    }
    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  get manufacturerFilters(): string {
    let combo = this.manufacturersCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('manufacturers').value.length; index++) {
      const element = this.formSearch.get('manufacturers').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('manufacturers').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get modelFilters(): string {
    let combo = this.modelsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('models').value.length; index++) {
      const element = this.formSearch.get('models').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('models').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get gamesFilters(): string {
    let combo = this.gametypesCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('gameTypes').value.length; index++) {
      const element = this.formSearch.get('gameTypes').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('gameTypes').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  getModels() {
    // this.formSearch.get('models').setValue([]);
    this.modelsCombo.fillComboWithManufacturersId(this.formSearch.get('manufacturers').value);
    this.getGameTypes();
  }

  getGameTypes() {
    // this.formSearch.get('gameTypes').setValue([]);
    this.gametypesCombo.fillComboWithModelsId(this.formSearch.get('models').value);
    this.updateEgms();
  }

  updateEgms() {
    // this.formSearch.get('egmUid').setValue([]);
    if (this.formSearchNull) {
      this.egmsCombo.fillCombo();
    } else {
      var filters: MultiselectFilters = new MultiselectFilters();
      filters.manufacturers = this.formSearch.get('manufacturers').value;
      filters.models = this.formSearch.get('models').value;
      filters.gametypes = this.formSearch.get('gameTypes').value;
      filters.egms = this.formSearch.get('egmUid').value;
      this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
    }
  }

  get formSearchNull(): boolean {
    return (
      this.formSearch.get('gameTypes').value.length == 0 &&
      this.formSearch.get('models').value.length == 0 &&
      this.formSearch.get('manufacturers').value.length == 0
    );
  }
}
