import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { TurnComboComponent } from 'src/app/common/business/turn-combo/turn-combo.component';
import { CashRegisterComboComponent } from 'src/app/common/components/business/cash-register-combo/cash-register-combo.component';

import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { TurnDetailViewModel } from 'src/app/common/components/viewmodels/SessioModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-turn-details',
  templateUrl: './turn-details.component.html',
  styleUrls: ['./turn-details.component.css'],
})
export class TurnDetailsComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];
  title: string = 'Reporte Cierre de Turno';

  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<TurnDetailViewModel>;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(CashRegisterComboComponent) cashCombo: CashRegisterComboComponent;
  @ViewChild(TurnComboComponent) turnCombo: TurnComboComponent;
  currencyCode: string;
  currencySym: string;
  public data: commonTableData<TurnDetailViewModel>;
  formSearch: FormGroup;
  dateRange: string;
  filterParamString: string;

  public static getName() {
    return 'TurnDetailsComponent';
  }
  constructor(
    public sessionService: SessionService,
    public notificationService: NotificationService,

    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Gestión'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.initForm();
    this.initGrid();
  }

  getTicketIndex(): number {
    //8 es el indice de la columna profit sin contar los custom buttons.
    return 5;
  }

  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;

    const that = this;
    this.data.columns = [
      { data: 'turnId', title: 'Detalle' },
      { data: 'cashRegisterName', title: 'Caja' },
      { data: 'closeUserName', title: 'Usuario Cierre' },

      {
        data: 'closeDate',
        title: 'Fecha cierre',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'settlement.total',
        title: 'Total Real',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'settlement.currentTotalTheoretical',
        title: 'Total Teórico',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },

      {
        data: 'settlement.difftotal',
        title: 'Diferencia',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },

      { data: 'settlement.billsDesc', title: 'Billetes' },

      { data: 'settlement.coinsDesc', title: 'Monedas' },
    ];

    // //agarro todas las columnas y genero su indice para ponerlas en el excel. Si esta no esta definido ponee todas.
    // this.data.excelColumns = this.data.columns
    //   .map((column, i) => {
    //     return (column.visible === undefined || column.visible === false) && column.data ? i : null;
    //   })
    //   .filter((index) => index !== null);

    this.data.rowCallback = function (row: Node, data: TurnDetailViewModel | Object, index: number): void {
      // that.addAlert(row, data['userCreatedTicket']);

      // if (data['validatorCode'] != null) {
      $($('td', row)[0]).html(
        `<a target="_blank" href='${window.location.origin}/egms/session-settlement?m=3&turnId=${data['turnId']}' style='text-decoration: underline;' data-toggle='tooltip' data-placement='right' title='' data-original-title='Ir a detalle'>Ver</a>`
      );

      //   }
    };
  }

  addAlert(row: Node, userCreatedTicket: boolean) {
    // this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   if (userCreatedTicket == true) {
    //     $($('td', row)[0]).html('<i class="fas fa-exclamation-triangle"></i>');
    //     $($('td', row)[0]).css('color', 'rgb(225 175 0)'); //Danger
    //     $($('td', row)[0]).css('font-weight', 'bold');
    //   } else {
    //     $($('td', row)[0]).html('');
    //   }
    // });
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
      // egmUid: new FormControl([]),
      cashRegisters: new FormControl([]),

      // turnId: new FormControl([]),

      // movementType: new FormControl([]),
    });
  }

  onSubmit() {
    this.updateTable();
  }

  updateTable() {
    this.dateRange = null;
    if (this.formSearch.get('from').value != null && this.formSearch.get('to').value != null) {
      this.dateRange =
        moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
        ' - ' +
        moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();
    }
    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  filterString() {
    let aux: string = '';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    // if (this.formSearch.get('egmUid').value.length > 0) {
    //   aux += ' Egms: ' + this.egmFilters + ' - ';
    // }
    if (this.formSearch.get('cashRegisters').value.length > 0) {
      aux += ' Caja: ' + this.cashRegisterFilters + ' - ';
    }
    // if (this.formSearch.get('turnId').value.length > 0) {
    //   aux += ' Turno: ' + this.turnFilters + ' - ';
    // }

    if (this.table != undefined) {
      this.table.setFilterString(' Filtros: - ' + aux);
    }

    this.filterParamString = aux;
  }

  // get egmFilters(): string {
  //   let combo = this.egmsCombo.getCombo();
  //   let aux: string = '';
  //   for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
  //     const element = this.formSearch.get('egmUid').value[index];
  //     let name = combo.find((e) => e.id == element).text;
  //     aux += name;
  //     if (index < this.formSearch.get('egmUid').value.length - 1) {
  //       aux += ',';
  //     }
  //   }
  //   return aux;
  // }

  // get turnFilters(): string {
  //   let combo = this.turnCombo.getCombo();
  //   let aux: string = '';
  //   for (let index = 0; index < this.formSearch.get('turnId').value.length; index++) {
  //     const element = this.formSearch.get('turnId').value[index];
  //     let name = combo.find((e) => e.id == element).text;
  //     aux += name;
  //     if (index < this.formSearch.get('turnId').value.length - 1) {
  //       aux += ',';
  //     }
  //   }
  //   return aux;
  // }

  get cashRegisterFilters(): string {
    let combo = this.cashCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('cashRegisters').value.length; index++) {
      const element = this.formSearch.get('cashRegisters').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('cashRegisters').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  getFilters(): MultiselectFilters {
    var filters = new MultiselectFilters();

    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;

    //filters.toConciliate = this.formSearch.get('toConciliate').value == 'toconciliate' ? true : false;

    // if (this.formSearch.get('egmUid').value.length > 0) {
    //   filters.egms = this.formSearch.get('egmUid').value;
    // } else {
    //   if (this.formSearch.get('rooms').value.length > 0) {
    //     filters.egms = this.egmsCombo.getEgmComboAsIdArray();
    //   }
    //}
    if (this.formSearch.get('cashRegisters').value.length > 0) {
      filters.cashRegisters = this.formSearch.get('cashRegisters').value;
    }

    // if (this.formSearch.get('movementType').value.length > 0) {
    //   filters.movementTypes = this.formSearch.get('movementType').value;
    // }
    // if (this.formSearch.get('turnId').value.length > 0) {
    //   filters.Turns = this.formSearch.get('turnId').value;
    // }

    return filters;
  }

  setGridData(filters: MultiselectFilters) {
    let service = this.sessionService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<TurnDetailViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.GetTurnDetail(DataTableFilters);
    };
  }

  onSearch(status: tableStatus) {}
}
