import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-real-time-profit',
  templateUrl: './real-time-profit.component.html',
  styleUrls: ['./real-time-profit.component.css'],
})
export class RealTimeProfitComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];
  title: string = 'Reporte Ganancia en Curso';

  public static getName() {
    return 'RealTimeProfitComponent';
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }
}
