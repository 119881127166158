import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/pos/pos.service';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { NotificationService } from '../../../shared/notification/notification.service';

import { CashRegisterTypeEnum, SessionManagmentViewModel } from '../../../viewmodels/SessioModel';
import { CashRegisterComboComponent } from '../../cash-register-combo/cash-register-combo.component';

@Component({
  selector: 'app-session-management-control',
  templateUrl: './session-management-control.component.html',
  styleUrls: ['./session-management-control.component.css'],
})
export class SessionManagementControlComponent implements OnInit {
  formEdit: FormGroup;
  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(CashRegisterComboComponent) cashCombo: CashRegisterComboComponent;
  title: string = '';
  body: string;
  callbackConfirm: () => void;
  cashRegisterType?: CashRegisterTypeEnum = CashRegisterTypeEnum.MaquinaDeCambio;

  constructor(
    public sessionService: SessionService,
    public router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.updatePageState();
  }

  loadFinish() {
    var memory = localStorage.getItem('bcm.currentCash');

    let combo = this.cashCombo.getCombo();

    //si la opcion seleccionada esta en el combo de cajas:
    if (combo.find((e) => e.id == memory) != null) {
      if (memory != null) {
        this.formEdit.get('cashRegister').setValue(Number(memory));
        this.changeCashRigester(Number(memory));
      }
    }
  }
  updatePageState() {
    this.title = this.sessionService.getPageTitle(this.session);
  }

  changeCashRigester($event) {
    this.sessionService.GetSessionByCashRegisterId($event).subscribe((result) => {
      this.session = result.data;

      localStorage.setItem('bcm.currentCash', $event);

      //this.ChangeSessionState();
      this.updatePageState();
    });
  }

  private initForm() {
    this.formEdit = new FormGroup({
      cashRegister: new FormControl(null, Validators.required),
    });
  }

  //muestra el control de arqueo, y desde ahi se inica el turno
  public onTurnStartModal() {
    this.router.navigate(['/session-settlement', this.session.cashRegisterId], { queryParams: { m: '1' } });
  }

  public onTurnEndModal() {
    this.router.navigate(['/session-settlement', this.session.cashRegisterId], { queryParams: { m: '2' } });
  }

  public onGoToCashFlow() {
    this.router.navigate(['/session-cashflow', this.session.idTurn]);
  }

  public onSessionStartModal() {
    this.body = `¿Está seguro que desea iniciar la sesión?`;
    this.callbackConfirm = () => {
      //hago la pregunta:

      this.sessionService.StartSession(this.session.cashRegisterId).subscribe((result) => {
        if (result.error == null) {
          this.session = result.data;
          this.updatePageState();
        } else {
          this.notificationService.showError(result.error.generalMessage, 'Error');
        }
      });
    };
    this.confirmModal.openModalStatic();
  }

  public onSessionEndButtonModal() {
    this.body = `¿Está seguro que desea finalizar la sesión?`;

    this.callbackConfirm = () => {
      //hago la pregunta:

      this.sessionService.EndSession(this.session.cashRegisterId).subscribe((result) => {
        this.session = result.data;

        // this.ChangeSessionState();
        this.updatePageState();
      });
    };
    this.confirmModal.openModalStatic();
  }

  onConfirm() {
    this.callbackConfirm();
  }
}
