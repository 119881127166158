<div *ngIf="multiselect">
  <common-input-multiselect
    class="form-control-sm"
    (valueChanged)="valueChanged.emit($event)"
    [label]="label"
    [list]="combo"
    [formControl]="control">
  </common-input-multiselect>
</div>
<div *ngIf="!multiselect">
  <common-input-select
    class="form-control-sm"
    (valueChanged)="valueChanged.emit($event)"
    [label]="label"
    [list]="combo"
    [formControl]="control">
  </common-input-select>
</div>
