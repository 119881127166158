import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Route, Router } from '@angular/router';
declare var $: any;
import * as AdminLte from 'admin-lte';
import { debug } from 'console';
import { InstanceTypeEnum, TicketFamilyGroup } from 'src/app/common/components/viewmodels/Enums';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SessionOldReportService } from 'src/app/services/session-report/session-report.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { TicketConfigService } from 'src/app/services/ticket-config/ticket-config.service';

@Component({
  selector: 'app-main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.css'],
})
export class MainSidebarComponent implements OnInit {
  @ViewChildren('menuGroup') menus: QueryList<ElementRef>;
  @ViewChildren('menuItem') items: QueryList<ElementRef>;

  showCashable: boolean = false;
  showPromotional: boolean = false;
  showCashRegisterMenu: boolean = false;
  loading: boolean = true;
  tentantTitle: string = '';

  constructor(
    public router: Router,
    private SessionService: SessionOldReportService,
    private TicketConfigService: TicketConfigService,
    private casinoService: CasinoService,
    private tenantService: TenantService,
    private parame: TenantService,
    private paramService: ParametersService<any>
  ) {}

  ngOnInit(): void {
    $('[data-widget="treeview"]').Treeview('init');

    this.fillTenantData();
    this.updateMenu();
    // this.getStartedSession();
  }

  clearInternalStates() {
    this.paramService.cleanParameters();
  }

  fillTenantData() {
    this.casinoService.getInstanceType().subscribe((x) => {
      if (x.data == InstanceTypeEnum.Client) {
        this.tentantTitle = 'TCM System Salón';
      } else if (x.data == InstanceTypeEnum.Server) {
        this.tentantTitle = 'TCM System Multi Salón';
      } else if (x.data == InstanceTypeEnum.StandAlone) {
        this.tentantTitle = 'TCM System Salón Alone';
        //this.tentantTitle = "TCM System Multi Salón";
      }
    });
  }

  ngAfterViewInit(): void {
    this.processMenu().then();
  }

  async processMenu() {
    for (const item of this.items) {
      var route = item.nativeElement?.attributes?.routerLink?.value;
      if (route) {
        var componentName = this.tenantService.findComponentNameByRoute(route);
        if (componentName) {
          var isAuth = await this.casinoService.isComponentAuthorized(componentName).toPromise();
          if (!isAuth.data) item.nativeElement.parentElement.remove();
        } else {
          alert('Ruta no definida: ' + route);
        }
      }
    }

    // for (const item of this.items) {
    //   var route = item.nativeElement?.attributes?.routerLink?.value;
    //   if (route) {
    //     var componentName = this.findRoute(route, this.router.config);
    //     if (componentName) {
    //       this.casinoService.isComponentAuthorized(componentName).subscribe((isAuth) => {
    //         if (!isAuth.data)
    //         item.nativeElement.parentElement.remove();
    //       })
    //     }
    //     else {
    //       alert("Ruta no definida: " + route);
    //     }
    //   }
    // }

    for (const menu of this.menus) {
      if (!menu.nativeElement?.nextElementSibling?.childElementCount) menu.nativeElement.remove();
    }

    this.loading = false;
    window.dispatchEvent(new Event('resize'));
  }

  public updateMenu() {
    //   this.TicketConfigService.getTicketConfigs().subscribe((result) => {
    //     if (result?.data) {
    //       var cash = result.data.find((i) => i.familyGroupId === TicketFamilyGroup.Cash);
    //       if (cash) {
    //         var restpromocional = result.data.find((i) => i.familyGroupId === TicketFamilyGroup.Restricted_promotional);
    //         this.showCashable = cash.ticketPrint;
    //         this.showPromotional = restpromocional.ticketPrint;
    //         return;
    //       }
    //     }
    //     this.showCashable = false;
    //     this.showPromotional = false;
    //     this.showCashRegisterMenu = false;
    //   });
  }
}
