import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { FullUserViewModel } from 'src/app/common/components/viewmodels/User';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { NgForm } from '@angular/forms';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { Casino, Tenant } from 'src/app/common/components/viewmodels/Casino';
import { CasinoService } from 'src/app/services/casino/casino.service';

@Component({
  selector: 'app-edit-casinodata',
  templateUrl: './edit-casinodata.component.html',
  styleUrls: ['./edit-casinodata.component.css'],
})
export class EditCasinodataComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Sincronismo';

  InputType = InputTypeEnum;

  isEdit: boolean;
  items: Array<BreadcrumbItem> = [];
  casino: Casino = new Casino();

  parameter: tableStatus = null;

  public static getName() {
    return 'EditCasinodataComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private casinoService: CasinoService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.casinoService.getCasinoConfigData().subscribe((result) => {
      if (result.error == null) {
        this.formEdit.patchValue(result.data);
      } else {
        this.notificationService.showError('Error al editar el configuración.', 'Error');
      }
    });

    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  onSubmit() {
    this.formEdit.markAllAsTouched();

    if (this.formEdit.valid) {
      this.casino = new Casino(this.formEdit.value);

      this.casino.id = 0;
      this.casinoService.editCasinoConfig(this.casino).subscribe((result) => {
        if (result.error != null) {
          this.notificationService.showError('No se pudo grabar la configuración.', 'Error');
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      facilityName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      // apikey: new FormControl(null, [
      //   Validators.required,
      //   Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      // ]),
      url: new FormControl(null, [Validators.required, Validators.maxLength(500)]),
      spanSyncSeconds: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
    });
  }
}
