import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SessionService } from 'src/app/services/pos/pos.service';
import { CommonInputSelectComponent } from '../../components/shared/common-input-select/common-input-select.component';
import { IdTextModel } from '../../components/shared/IdTextModel';

@Component({
  selector: 'app-currency-type-combo',
  templateUrl: './currency-type-combo.component.html',
  styleUrls: ['./currency-type-combo.component.css'],
})
export class CurrencyTypeComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  @Input() onlyOpen: boolean = null;
  @Input() allowClear: boolean = true;
  @Input() formSubmitted: boolean;

  combo: Array<IdTextModel> = [];
  label: string = 'Moneda';
  @Output() valueChanged = new EventEmitter();

  @ViewChild(CommonInputSelectComponent) selectComponent: CommonInputSelectComponent;

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    this.fillCombo();
  }

  public fillCombo() {
    this.sessionService.GetCurrencyTypeCombo().subscribe((result) => {
      this.combo = result.data;
    });
  }

  public getEgmComboAsIdArray(): number[] {
    var arr: number[] = [];
    this.combo.forEach((element) => {
      arr.push(parseInt(element.id));
    });
    return arr;
  }

  public getCombo() {
    return this.combo;
  }
}
