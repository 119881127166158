<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-8">
        <form role="form" [formGroup]="formEdit" (ngSubmit)="onSubmit()" #formObject="ngForm">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col">
                  <app-common-input
                    [control]="formEdit.get('name')"
                    [label]="'Nombre'"
                    [type]="InputType.text"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col">
                  <manufacturers-combo [control]="formEdit.get('manufacturer')"></manufacturers-combo>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <app-confirm-buttons (onCancel)="cancel()"></app-confirm-buttons>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
