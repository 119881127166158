import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Location } from 'src/app/common/components/viewmodels/Location';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getLocationsCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Locations/GetLocationsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }
  public getLocations(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<Location>>> {
    return this.http
      .post<ApiResult<DataTableResult<Location>>>(`${this.baseUrl}Locations/GetLocations`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Location>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Location>>(error))
      );
  }

  public getLocation(id: number): Observable<ApiResult<Location>> {
    return this.http.get<ApiResult<Location>>(`${this.baseUrl}Locations/GetLocation?id=${id}`).pipe(
      map((x) => this.checkResult<Location>(x)),
      catchError((error) => this.handleError<Location>(error))
    );
  }

  public deleteLocation(id: number): Observable<ApiResult<Location>> {
    return this.http.delete<ApiResult<Location>>(`${this.baseUrl}Locations/DeleteLocation?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<Location>(x);
        return result;
      }),
      catchError((error) => this.handleError<Location>(error))
    );
  }

  public createOrEdit(location: Location): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Locations/CreateOrEdit`, location).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
}
