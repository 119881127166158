<common-header title="{{ tittle }}" [items]="items"></common-header>

<div *ngIf="loadPage">
  <app-session-settlement-control
    [cashRegisterId]="[cashRegisterId]"
    [settlmentType]="settlmentType"
    [specifTurnId]="[specifTurnId]"
    (sessionLoaded)="sessionChanged($event)">
  </app-session-settlement-control>
</div>
