import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Model } from 'src/app/common/components/viewmodels/Model';
import { ModelService } from 'src/app/services/model/model.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-list-models',
  templateUrl: './list-models.component.html',
  styleUrls: ['./list-models.component.css'],
})
export class ListModelsComponent implements OnInit {
  public data: commonTableData<Model>;

  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Model>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  model: Model = new Model();

  parameter: tableStatus = null;

  public static getName() {
    return 'ListModelsComponent';
  }
  constructor(
    public ModelService: ModelService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/models/edit-model', row.id]);
  }

  public onDeleteButton(row): void {
    this.model = row;
    this.body = '¿Está seguro que desea eliminar el modelo ' + this.model.name + '?';
    this.confirmModal.openModal();
  }

  confirmDelete() {
    this.ModelService.deleteModel(this.model.id).subscribe((result) => {
      this.updateTable();
    });
  }

  setGridData(param1: boolean): void {
    let service = this.ModelService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Model>>> {
      return service.getModels(param);
    };

    this.data.columns = [
      { data: 'name', title: 'Modelos' },
      { data: 'manufacturer.name', title: 'Fabricante' },
    ];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/models/create-model']);
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Máquinas'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Modelos'));
    this.data = new commonTableData();
    let paramModel = this.paramService.getAndDeleteParameters();

    if (paramModel != null) {
      this.data.searchText = paramModel.searchString;
      this.data.pageLength = paramModel.pageLength;
      this.data.currentPage = paramModel.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }
}
