import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';

@Injectable({
  providedIn: 'root',
})
export class ParametersService<T = any> {
  parameter: T = null;
  screen?: string = null;

  constructor(private router: Router) {}

  public saveParameters(parameter: T, secreen: string = null) {
    this.parameter = parameter;
    this.screen = secreen;
  }

  public getAndDeleteParameters(screen: string = null): T {
    if (this.parameter != null && this.screen == screen) {
      //      return this.parameter;
      const returnObject = { ...this.parameter };
      this.cleanParameters();
      return returnObject;
    }
  }

  public cleanParameters() {
    this.parameter = null;
    this.screen = null;
  }

  public saveParametersInUrl(status: tableStatus) {
    let navigationExtras: NavigationExtras;

    navigationExtras = {
      queryParams: {
        search: status.searchString.trim(),
        page: status.currentPage,
        len: status.pageLength,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
      skipLocationChange: false,
    };

    this.router.navigate([], navigationExtras);
  }
}
