import { DailyBalance } from './DailyBalance';

export class MoneyDropsViewModel {
  dailyBalance: DailyBalance;
  dif?: number;
}

export class MoneyDropsFooterViewModel {
  showFooter: boolean;
  difCoinDropTotal: number;
  difBillInTotal: number;
  difTicketInTotal: number;
  difTotalDropTotal: number;
  aftIn: number;
  difTotal: number;
}
