<div class="row" *ngIf="this.showSettlement">
  <div class="col">
    <form role="form" [formGroup]="formEdit" #formObject="ngForm">
      <div class="card">
        <div class="card-header" *ngIf="title !== ''">
          <h3 class="card-title">
            <span> {{ title }}</span>
          </h3>
          <div class="card-tools">
            <!-- <button #myCollapse type="button" class="btn btn-tool" data-card-widget="collapse">
              <i id="toolBtn" class="fas fa-minus"></i>
            </button> -->
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-5" formArrayName="bills">
              <h3 class="text-dark">Billettes</h3>

              <!-- <ng-container *ngFor="let billsFrom of bills.controls; let i = index"> -->
              <ng-container *ngFor="let billsFrom of formEdit.get('bills')['controls']; let i = index">
                <div class="row" style="margin-top: 1rem" [formGroup]="billsFrom">
                  <div class="col-12">
                    <div class="input-group">
                      <div class="input-group-prepend min-widthebillval">
                        <span class="input-group-text min-widthebillval justify-content-end"
                          >{{ billsFrom.get('val').value }} x</span
                        >
                      </div>

                      <input
                        value="{{ billsFrom.get('count').value }}"
                        formControlName="count"
                        class="form-control max-widthebillcant text-right"
                        type="text"
                        (keyup)="onBillChange($event, i)" />

                      <div class="input-group-append min-widthebilltotal">
                        <span class="input-group-text min-widthebilltotal justify-content-end"
                          >= {{ currencyPipe.transform(billsFrom.get('total').value, currencyCode, currencySym) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="text-danger font-size-14"
                      *ngIf="
                        (billsFrom.get('count').touched || billsFrom.get('count').dirty || formObject.submitted) &&
                        billsFrom.get('count').invalid
                      ">
                      <div *ngIf="billsFrom.get('count').errors.required">Campo obligatorio.</div>
                      <div *ngIf="billsFrom.get('count').errors.pattern">Número no válido.</div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="col-1"></div>
            <div class="col-5">
              <h3 class="text-dark">Monedas</h3>
              <ng-container *ngFor="let coinsFrom of formEdit.get('coins')['controls']; let i = index">
                <div class="row" style="margin-top: 1rem" [formGroup]="coinsFrom">
                  <div class="col-12">
                    <div class="input-group">
                      <div class="input-group-prepend min-widthebillval">
                        <span class="input-group-text min-widthebillval justify-content-end"
                          >{{ coinsFrom.get('val').value }} x</span
                        >
                      </div>
                      <input
                        value="{{ coinsFrom.get('count').value }}"
                        formControlName="count"
                        class="form-control max-widthebillcant text-right"
                        type="text"
                        (keyup)="onCoinChange($event, i)" />
                      <div class="input-group-append min-widthebilltotal">
                        <span class="input-group-text min-widthebilltotal justify-content-end"
                          >= {{ currencyPipe.transform(coinsFrom.get('total').value, currencyCode, currencySym) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="text-danger font-size-14"
                      *ngIf="
                        (coinsFrom.get('count').touched || coinsFrom.get('count').dirty || formObject.submitted) &&
                        coinsFrom.get('count').invalid
                      ">
                      <div *ngIf="coinsFrom.get('count').errors.required">Campo obligatorio.</div>
                      <div *ngIf="coinsFrom.get('count').errors.pattern">Número no válido.</div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="row" style="margin-top: 1rem">
            <div class="col-1"></div>
            <div class="col-5">
              <div>
                <div class="col-5">
                  <h3 class="text-dark">Total Billetes</h3>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-money-bill-alt"></i></span>
                    </div>
                    <input
                      style="font-weight: bold"
                      [disabled]="true"
                      type="text"
                      class="form-control text-left"
                      value="{{
                        currencyPipe.transform(this.settlementModel.totalsBills, currencyCode, currencySym)
                      }}" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1"></div>
            <div class="col-5">
              <div>
                <div class="col-5">
                  <h3 class="text-dark">Total Monedas</h3>

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-money-bill-alt"></i></span>
                    </div>
                    <input
                      style="font-weight: bold"
                      [disabled]="true"
                      type="text"
                      class="form-control text-left"
                      value="{{
                        currencyPipe.transform(this.settlementModel.totalsCoins, currencyCode, currencySym)
                      }}" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 2rem">
            <div class="col-md-3"></div>

            <div class="col-md-2 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-success"><i class="fas fa-file-invoice-dollar"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">TOTAL </span>
                  <span class="info-box-number"
                    >{{ currencyPipe.transform(this.settlementModel.total, currencyCode, currencySym) }}
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-success"><i class="fas fa-file-invoice"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">TOTAL TEÓRICO</span>
                  <span class="info-box-number"
                    >{{
                      currencyPipe.transform(this.settlementModel.currentTotalTheoretical, currencyCode, currencySym)
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-sm-6 col-12">
              <div class="info-box">
                <span class="info-box-icon bg-danger"><i class="fas fa-exclamation-triangle"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">DIFERENCIA</span>
                  <span class="info-box-number"
                    >{{ currencyPipe.transform(this.settlementModel.difftotal, currencyCode, currencySym) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <app-common-input
                [control]="formEdit.get('observations')"
                [label]="'Observaciones'"
                [type]="InputType.textarea">
              </app-common-input>
            </div>
          </div>
          <span class="summaryfooter">
            *Actualización Máquina: <span style="font-weight: bold"> {{ dataDate }}</span>
          </span>
        </div>

        <div class="card-footer">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary" *ngIf="!readonly" (click)="onConfirmTurnSettlementModal()">
              Confirmar
            </button>
            <button type="button" class="btn btn-secondary ml-2" *ngIf="!readonly" (click)="onCancelModal()">
              Cancelar
            </button>
            <button type="button" class="btn btn-secondary ml-2" *ngIf="readonly" (click)="goToBack()">Volver</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-confirm-modal yes="Continuar" (onConfirm)="goToConfirm()" [body]="body"> </app-confirm-modal>
</div>
