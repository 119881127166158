import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { param } from 'jquery';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Tenant } from 'src/app/common/components/viewmodels/Casino';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { StateEgm } from 'src/app/common/components/viewmodels/Enums';
import { UsersViewModel } from 'src/app/common/components/viewmodels/User';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-list-tenant',
  templateUrl: './list-tenant.component.html',
  styleUrls: ['./list-tenant.component.css'],
})
export class ListTenantComponent implements OnInit {
  public data: commonTableData<Tenant>;

  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<UsersViewModel>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  tenant: Tenant = new Tenant();
  parameter: tableStatus = null;

  public static getName() {
    return 'ListTenantComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private casinoService: CasinoService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Establecimiento'));
    this.items.push(new BreadcrumbItem('', 'Tenant'));

    this.data = new commonTableData();
    let paramEGM = this.paramService.getAndDeleteParameters();

    if (paramEGM != null) {
      this.data.searchText = paramEGM.searchString;
      this.data.pageLength = paramEGM.pageLength;
      this.data.currentPage = paramEGM.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;

    this.setGridData(true);
  }

  setGridData(param1: boolean): void {
    let service = this.casinoService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Tenant>>> {
      return service.GetTenantListDataTable(param);
    };

    this.data.columns = [
      // { data: 'tenantId', title: 'Id' },
      { data: 'description', title: 'Rol' },
      // {
      //   data: 'lastUpdateDate', title: 'Últ. Sync',
      //   "render": function (data, type, row) {
      //     return moment(data).format('DD/MM/yyyy, HH:mm:ss');
      //   }
      // },
      { data: 'info', title: 'estado sync' },
      { data: 'tenantVersion', title: 'Versión' },
      // { data: 'lastSyncId', title: 'últ. Id Sync' },

      // {
      //   data: 'IsDeleted', title: 'Estado',
      //   "render": function (data, type, row) {
      //     return (data) ? "Baja" : `Activo`;
      //   }
      // },
    ];
  }

  // id: number;
  // tenantId: number;
  // description:string;
  // apikey:string;
  // lastUpdateDate: moment.Moment;
  // lastSyncId:number;
  // serverTablesVersion:moment.Moment;

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['establishment/tenant', row.id]);
  }

  public onDeleteButton(row): void {
    this.tenant = row;

    this.body = '¿Está seguro que desea dar de baja el tenant ' + this.tenant.description + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['/establishment/tenant/create']);
    // this.router.navigate(['/establishment/cash-registers']);
  }

  confirmDelete() {
    this.casinoService.DeleteTenant(this.tenant.id).subscribe((result) => {
      //this.notificationService.showSuccess(`Se borro la EGM ${this.egm.uid}`, "Atención!");
      this.updateTable();
    });
  }
}
