import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { GametypesComboComponent } from 'src/app/common/components/business/gametypes-combo/gametypes-combo.component';
import { ManufacturersComboComponent } from 'src/app/common/components/business/manufacturers-combo/manufacturers-combo.component';
import { ModelsComboComponent } from 'src/app/common/components/business/models-combo/models-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import {
  CommonTableComponent,
  commonTableData,
  commonTableCustomButton,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters, DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { Events } from 'src/app/common/components/viewmodels/Events';
import { EventsHost } from 'src/app/common/components/viewmodels/EventsHost';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { GametypesService } from 'src/app/services/gametypes/gametypes.service';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';
import { ModelService } from 'src/app/services/model/model.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { SystemEventService } from 'src/app/services/system-event/system-event.service';

@Component({
  selector: 'app-system-events',
  templateUrl: './system-events.component.html',
  styleUrls: ['./system-events.component.css'],
})
export class SystemEventsComponent implements OnInit {
  InputType = InputTypeEnum;

  @ViewChild(CommonTableComponent) commonDataTable: CommonTableComponent<EventsHost>;
  @ViewChild(ModelsComboComponent) modelsCombo: ModelsComboComponent;
  @ViewChild(GametypesComboComponent) gametypesCombo: GametypesComboComponent;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(ManufacturersComboComponent) manufacturersCombo: ManufacturersComboComponent;

  eventsCombo: Array<IdTextModel> = [];

  items: Array<BreadcrumbItem> = [];

  public data: commonTableData<Events>;

  formSearch: FormGroup;
  dateRange: string;

  public static getName() {
    return 'SystemEventsComponent';
  }

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    public SystemEventsService: SystemEventService,
    public ManufacturersService: ManufacturerService,
    public ModelService: ModelService,
    public gameTypesService: GametypesService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Eventos'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Sistema'));

    this.initForm();

    this.fillCombos();
    this.initGrid();
  }

  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    this.data.columns = [
      {
        data: 'hostDate',
        title: 'Fecha de Recepción Host',
        render: function (data: string, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'egm.uid', title: 'EGM' },
      { data: 'egm.manufacturer.name', title: 'Fabricante' },
      { data: 'egm.model.name', title: 'Modelo' },

      {
        data: 'egm.gameType.name',
        title: 'Tipo Juego',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      // { data: 'egm.spider.model', title: 'Codigo Spider' },

      // {
      //   data: 'egm.spider', title: 'Código Spider',
      //   "render": function (data: any, type, row) {
      //     return data != null ? data.model : `-`;
      //   }
      // },

      {
        data: 'eventHost.binaryDec',
        title: 'Codigo Evento',
        render: function (data: number, type, row) {
          return data.toString(16).toUpperCase();
        },
      },
      { data: 'eventHost.spaDescription', title: 'Descripción' },
      {
        data: 'eventDate',
        title: 'Fecha del Evento',
        render: function (data: string, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      // {
      //   data: 'egm.online', title: 'Habilitada',
      //   "render": function (data: boolean, type, row) {
      //     return data ? `Sí` : `No`;
      //   }
      // },
    ];
  }

  setGridData(filters: MinimalFilters): void {
    let service = this.SystemEventsService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Events>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getSystemEvents(DataTableFilters);
    };

    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }

  updateTable() {
    this.setGridData(this.getFilters());
    this.filterString();
    this.commonDataTable.reload();
  }

  initForm() {
    this.formSearch = new FormGroup({
      from: new FormControl(),
      to: new FormControl(),
      manufacturers: new FormControl(),
      models: new FormControl(),
      gameTypes: new FormControl(),
      egmOnline: new FormControl(false),
      priority: new FormControl(false),
      events: new FormControl([]),
      egmUid: new FormControl([]),
    });
  }

  onSubmit() {
    console.log(this.formSearch);
    this.updateTable();
  }

  getModels() {
    this.formSearch.get('models').setValue(null);
    this.modelsCombo.fillComboWithManufacturerId(this.formSearch.get('manufacturers').value);
    this.getGameTypes();
  }

  getGameTypes() {
    this.formSearch.get('gameTypes').setValue(null);
    this.gametypesCombo.fillComboWithModelId(this.formSearch.get('models').value);
    this.updateEgms();
  }

  updateEgms() {
    this.formSearch.get('egmUid').setValue([]);
    if (this.formSearchNull) {
      this.egmsCombo.fillCombo();
    } else {
      var filters: MinimalFilters = new MinimalFilters();
      filters.manufacturer = this.formSearch.get('manufacturers').value;
      filters.model = this.formSearch.get('models').value;
      filters.gametype = this.formSearch.get('gameTypes').value;
      this.egmsCombo.fillEgmsWithMinimalFilters(filters);
    }
  }

  getFilters(): MinimalFilters {
    var filters = new MinimalFilters();
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;
    filters.events = this.formSearch.get('events').value;
    filters.priorityOnly = this.formSearch.get('priority').value;
    filters.egmOnline = this.formSearch.get('egmOnline').value;
    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      if (!this.formSearchNull) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }
    return filters;
  }

  get formSearchNull(): boolean {
    return (
      this.formSearch.get('manufacturers').value == null &&
      this.formSearch.get('models').value == null &&
      this.formSearch.get('gameTypes').value == null
    );
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    if (this.formSearch.get('manufacturers').value) {
      aux += ' Fabricante: ' + this.manufacturerFilters + ' - ';
    }
    if (this.formSearch.get('models').value) {
      aux += ' Modelo: ' + this.modelFilters + ' - ';
    }
    if (this.formSearch.get('gameTypes')) {
      aux += ' Juegos: ' + this.gamesFilters + ' - ';
    }
    if (this.formSearch.get('egmUid').value) {
      aux += ' Egms: ' + this.egmFilters;
    }
    if (this.formSearch.get('events').value) {
      aux += ' Events: ' + this.eventsFilters;
    }
    // aux += "-";
    if (this.commonDataTable != undefined) this.commonDataTable.setFilterString(aux);
  }

  get manufacturerFilters(): string {
    let combo = this.manufacturersCombo.getCombo();
    const element = this.formSearch.get('manufacturers').value;
    let name = combo.find((e) => e.id == element)?.text || '';

    return name;
  }

  get modelFilters(): string {
    let combo = this.modelsCombo.getCombo();
    const element = this.formSearch.get('models').value;
    let name = combo.find((e) => e.id == element)?.text || '';

    return name;
  }
  get gamesFilters(): string {
    let combo = this.gametypesCombo.getCombo();
    const element = this.formSearch.get('gameTypes').value;
    let name = combo.find((e) => e.id == element)?.text || '';

    return name;
  }
  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    const element = this.formSearch.get('egmUid').value;
    let name = combo.find((e) => e.id == element)?.text || '';

    return name;
  }

  get eventsFilters(): string {
    let combo = this.eventsCombo;
    const element = this.formSearch.get('events').value;
    let name = combo.find((e) => e.id == element)?.text || '';

    return name;
  }

  private fillCombos() {
    this.SystemEventsService.getSystemEventsCombo().subscribe((result) => {
      this.eventsCombo = result.data;
    });
  }
}
