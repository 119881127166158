import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label, Color } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { CoinInChartViewModel } from '../../viewmodels/BarChartViewModel';
import { R3TargetBinder } from '@angular/compiler';
import { bufferToggle, min, window } from 'rxjs/operators';
import * as Chart from 'chart.js';
import { DailyProfitChartViewModel, HourlyProfitChartViewModel } from '../../viewmodels/LineChartViewModel';
import { textChangeRangeIsUnchanged } from 'typescript';
import { CasinoService } from 'src/app/services/casino/casino.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
})
export class BarChartComponent implements OnInit {
  @Input() data: any;
  @Input() coinIn: boolean = false;
  @Input() dailyProfit: boolean = false;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  // window: any;
  myChart: Chart;
  barChartData: ChartDataSets[];
  public barChartLabels: Label[] = [];
  dataStatus: boolean = false;
  currencyCode: string;
  currencySym: string;

  public static getName() {
    return 'BarChartComponent';
  }
  constructor(public CasinoService: CasinoService) {}

  ngOnInit(): void {}

  async setBarData(data: any) {
    if (this.coinIn) {
      await this.getCoinInData(data);
    }
    if (this.dailyProfit) {
      await this.getProfitData(data);
    }
  }

  public async getCoinInData(data: CoinInChartViewModel) {
    if (data != null) {
      if (data.coinIn.value.length == 0) {
        this.dataStatus = false;
      } else {
        this.dataStatus = true;
      }
      var dataArray = [];

      var aux = {
        data: data.coinIn.value,
        label: data.coinIn.label,
        fill: false,
        barPercentage: 0.15,
        backgroundColor: 'rgb(240,40,105)',
        borderWidth: 1,
      };
      dataArray.push(aux);

      var labelArray: Label[] = [];
      data.labels.forEach((label) => {
        labelArray.push(label);
      });

      this.barChartLabels = labelArray;
      this.barChartData = dataArray;
    }
  }
  public async getProfitData(data: DailyProfitChartViewModel) {
    if (data != null) {
      if (data.day.profit.length == 0) {
        this.dataStatus = false;
      } else {
        this.dataStatus = true;
      }
      var dataArray = [];

      var aux = {
        data: data.day.profit,
        label: data.day.label,
        fill: false,
        barPercentage: 0.15,
        backgroundColor: 'rgb(240,40,105)',
        borderWidth: 1,
      };
      dataArray.push(aux);

      var labelArray: Label[] = [];
      data.labels.forEach((label) => {
        labelArray.push(label);
      });

      this.barChartLabels = labelArray;
      this.barChartData = dataArray;
    }
  }
  public async reload(data: any) {
    await this.setBarData(data);
    if (this.myChart != null) {
      if (this.myChart.ctx != null) {
        this.myChart.data.labels = [];
        this.myChart.data.datasets = [];
        this.myChart.update();

        this.barChartLabels.forEach((label) => {
          this.myChart.data.labels.push(label);
        });
        this.barChartData.forEach((data) => {
          this.myChart.data.datasets.push(data);
        });
        this.myChart.update();
        this.barChartData.forEach((dataset) => {
          if (dataset.data.length == 0) {
            this.dataStatus = false;
          } else {
            this.dataStatus = true;
          }
        });
        if (this.dataStatus == false) {
          this.myChart.destroy();
        }
      } else {
        if (this.dataStatus == true) {
          this.initChart();
        }
      }
    } else {
      this.initChart();
    }
  }

  initChart() {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;

      const canvas = <HTMLCanvasElement>document.getElementById('myChart');
      const ctx = canvas.getContext('2d');
      this.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.barChartLabels,
          datasets: this.barChartData,
        },
        options: {
          responsive: true,
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return label;
              },
            },
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.currencyCode + ' ' + this.currencySym,
                  fontSize: 14,
                },
                // gridLines: {
                //   display: false
                // },
                ticks: {
                  beginAtZero: true,
                  callback: function (value: string, index, values) {
                    if (parseInt(value) >= 1000) {
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    } else {
                      return value;
                    }
                  },
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    });
  }
}
