import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { EgmProfitParameters } from 'src/app/common/components/business/egm-profit/egm-profit.component';
import { RoomComboComponent } from 'src/app/common/components/business/room-combo/room-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import {
  MoneyDropsFooterViewModel,
  MoneyDropsViewModel,
} from 'src/app/common/components/viewmodels/MoneyDropsViewModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { CountersService } from 'src/app/services/counters/counters.service';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
declare var $: any;

@Component({
  selector: 'app-money-drops',
  templateUrl: './money-drops.component.html',
  styleUrls: ['./money-drops.component.css'],
})
export class MoneyDropsComponent implements OnInit {
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(RoomComboComponent) roomComboComp: RoomComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<MoneyDropsViewModel>;

  title: string = 'Conciliación Drop';
  public data: commonTableData<MoneyDropsViewModel>;
  manufacturersCombo: Array<IdTextModel> = [];
  roomCombo: Array<IdTextModel> = [];
  hideTitle: boolean = true;

  formSearch: FormGroup;
  public footer: MoneyDropsFooterViewModel = new MoneyDropsFooterViewModel();
  items: Array<BreadcrumbItem> = [];
  currencyCode: string;
  currencySym: string;
  totalCoinDrop: number = 0;
  totalBillIn: number = 0;
  totalTicketIn: number = 0;
  totalAFTIn: number = 0;
  totalDrop: number = 0;
  totalDif: number = 0;
  dateRange: string;

  public static getName() {
    return 'MoneyDropsComponent';
  }
  constructor(
    private router: Router,
    private CountersService: CountersService,
    private DailyBalanceService: DailyBalanceService,
    private paramService: ParametersService<EgmProfitParameters>,
    public currencyPipe: CurrencyPipe,
    public casinoService: CasinoService
  ) {}

  ngOnInit(): void {
    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Conciliación Drop'));
    this.initForm();
    this.initGrid();
  }
  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    const that = this;

    this.data.columns = [
      {
        data: 'dailyBalance.formattedStartDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'dailyBalance.egm.uid', title: 'EGM' },
      {
        data: 'dailyBalance.difCoinDrop',
        title: 'Coin Drop',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'dailyBalance.difBillIn',
        title: 'Bill In',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'dailyBalance.difTicketIn',
        title: 'Ticket In',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'dailyBalance.difAFTIn',
        title: 'AFT In',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'dailyBalance.difTotalDrop',
        title: 'Total Drop',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'dif',
        title: 'Diferencia',
        render: function (data, type, row) {
          //return data != null ? data : "-";
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'dailyBalance.closed',
        title: 'Estado Día',
        render: function (data: boolean, type, row) {
          return data == true ? 'Cerrado' : 'Abierto';
        },
      },
    ];

    this.data.rowCallback = function (row: Node, data: MoneyDropsViewModel | Object, index: number): void {
      if (data['dif'] < 0) {
        that.redFont(row);
      } else {
        if (data['dif'] > 0) {
          that.greenFont(row);
        }
      }
    };
  }

  getProfitIndex(): number {
    //7 es el indice de la columna diff
    return 7 + this.data.commonTableCustomButton.length;
  }

  redFont(row: Node) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[this.getProfitIndex()]).css('color', '#dc3545'); //Danger
      $($('td', row)[this.getProfitIndex()]).css('font-weight', 'bold');
    });
  }

  greenFont(row: Node) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[this.getProfitIndex()]).css('color', '#28a745'); //Success
      $($('td', row)[this.getProfitIndex()]).css('font-weight', 'bold');
    });
  }

  onSearch(status: tableStatus) {
    //  this.parameter.status = status;
    // this.totalProfit = this.table.decimalData;
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }

    if (this.formSearch.get('rooms').value.length > 0) {
      aux += ' Salas: ' + this.roomFilters + ' - ';
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters;
    }
    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get roomFilters(): string {
    let combo = this.roomComboComp.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('rooms').value.length; index++) {
      const element = this.formSearch.get('rooms').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('rooms').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  updateTable(filters: MultiselectFilters) {
    this.setGridData(filters);
    this.filterString();
    this.table.reload();

    this.DailyBalanceService.getMoneyDropsFooterReport(filters).subscribe((result) => {
      this.footer = result.data;
    });
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.DailyBalanceService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<MoneyDropsViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getMoneyDropsReport(DataTableFilters);
    };

    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy').toString() + ' - ' + moment(filters.to).format('DD/MM/yyyy').toString();
  }

  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();

    var endDate = moment(this.formSearch.get('to').value).endOf('day');
    filters.to = endDate;

    filters.from = this.formSearch.get('from').value;
    if (this.formSearch.get('rooms').value.length > 0) {
      filters.rooms = this.formSearch.get('rooms').value;
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      if (this.formSearch.get('rooms').value.lenght != 0) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }
    return filters;
  }

  onSubmit() {
    this.updateTable(this.getFilters());
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl([]),
      rooms: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  updateEgms() {
    if (this.formSearch.get('rooms').value.lenght == 0) {
      this.egmsCombo.fillCombo();
    } else {
      var filters: MultiselectFilters = new MultiselectFilters();
      filters.rooms = this.formSearch.get('rooms').value;
      this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
    }
  }

  print() {
    $('#demo').CardWidget('expand');
    this.hideTitle = false;
    setTimeout(() => {
      this.hideTitle = true;
    }, 50);
  }
}
