import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Cabinet } from 'src/app/common/components/viewmodels/Cabinet';
import { CabinetService } from 'src/app/services/cabinet/cabinet.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-list-cabinets',
  templateUrl: './list-cabinets.component.html',
  styleUrls: ['./list-cabinets.component.css'],
})
export class ListCabinetsComponent implements OnInit {
  public data: commonTableData<Cabinet>;

  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Cabinet>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  cabinet: Cabinet = new Cabinet();

  parameter: tableStatus = null;

  public static getName() {
    return 'ListCabinetsComponent';
  }
  constructor(
    public CabinetService: CabinetService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/cabinets/edit-cabinet', row.id]);
  }

  public onDeleteButton(row): void {
    this.cabinet = row;
    this.body = '¿Está seguro que desea eliminar el gabinete ' + this.cabinet.name + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/cabinets/create-cabinet']);
  }

  confirmDelete() {
    this.CabinetService.deleteCabinet(this.cabinet.id).subscribe((result) => {
      this.updateTable();
    });
  }

  setGridData(param1: boolean): void {
    let service = this.CabinetService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Cabinet>>> {
      return service.getCabinets(param);
    };
    this.data.columns = [{ data: 'name', title: 'Gabinete' }];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Máquinas'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Gabinetes'));

    this.data = new commonTableData();

    let paramCabinet = this.paramService.getAndDeleteParameters();

    if (paramCabinet != null) {
      this.data.searchText = paramCabinet.searchString;
      this.data.pageLength = paramCabinet.pageLength;
      this.data.currentPage = paramCabinet.currentPage;
    }

    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }
}
