<common-header [title]="titlePage" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <form role="form" [formGroup]="formEdit" #formObject="ngForm">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('coinIn')"
                    [label]="'Coin_IN'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('coinOut')"
                    [label]="'Coin_OUT'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('jackpot')"
                    [label]="'Total Jackpot'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('totalDrop')"
                    [label]="'Total Drop'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('cancelledCredits')"
                    [label]="'Cancelled Credits'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('handpayCC')"
                    [label]="'Handpay CC'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billIn')"
                    [label]="'Bill_IN'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billOut')"
                    [label]="'Bill_OUT'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('totalTicketIn')"
                    [label]="'Total TCK_IN'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('totalTicketOut')"
                    [label]="'Total TCK_OUT'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('coinDrop')"
                    [label]="'Coin Drop'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('trueCoinIn')"
                    [label]="'True Coin In'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('trueCoinOut')"
                    [label]="'True Coin Out'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('eftIn')"
                    [label]="'EFT In'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('aftIn')"
                    [label]="'AFT In'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('aftOut')"
                    [label]="'AFT Out'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('gamesPlayed')"
                    [label]="'J. Jugados'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('gamesWon')"
                    [label]="'J. Ganados'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('gamesLost')"
                    [label]="'J. Perdidos'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM1Q')"
                    [label]="'Bill M1 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM2Q')"
                    [label]="'Bill M2 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM3Q')"
                    [label]="'Bill M3 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM4Q')"
                    [label]="'Bill M4 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM5Q')"
                    [label]="'Bill M5 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM6Q')"
                    [label]="'Bill M6 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM7Q')"
                    [label]="'Bill M7 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billM8Q')"
                    [label]="'Bill M8 Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billToDrop')"
                    [label]="'Bill To Drop'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('billToRecycle')"
                    [label]="'Bill To Recycle'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketCashIn')"
                    [label]="'TCK_IN Cashable'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketCashInQ')"
                    [label]="'TCK_IN Cashable Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketRPIn')"
                    [label]="'TCK_IN Restr'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketRPromInQ')"
                    [label]="'TCK_IN Restr Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketNoRestPromIn')"
                    [label]="'TCK_IN No Restr'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketNoRestPromInQ')"
                    [label]="'TCK_IN No Restr Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketCashOut')"
                    [label]="'TCK_OUT Cashable'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketCashOutQ')"
                    [label]="'TCK_OUT Cashable Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketRestPromOut')"
                    [label]="'TCK_OUT Restr'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('ticketRestPromOutQ')"
                    [label]="'TCK_OUT Restr Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('mptp')"
                    [label]="'MPTP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('mppp')"
                    [label]="'MPPP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('mpebp')"
                    [label]="'MPEBP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('aptp')"
                    [label]="'APTP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('appp')"
                    [label]="'APPP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('apebp')"
                    [label]="'APEBP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('doorQ')"
                    [label]="'Door Q'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('powerReset')"
                    [label]="'Power Reset'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('gamesSincePowerUp')"
                    [label]="'Games Since Power Up'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('gamesSinceDoorClosed')"
                    [label]="'Games Since Door Closed'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <!-- <div class="form-group col-12 col-xl-3">
                                    <app-common-input [control]="this.formEdit.get('currentHopperLevel')"
                                    [label]="'el 3'" [formSubmitted]="formObject.submitted"
                                    [type]="InputType.numeric">
                                </app-common-input>

                            </div> -->

                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('currentHopperLevel')"
                    [label]="'Current Hopper Level'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('totalHandPaidCredits')"
                    [label]="'Total Hand Paid Credits'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved1')"
                    [label]="'Reserved 1'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved2')"
                    [label]="'Reserved 2'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved3')"
                    [label]="'Reserved 3'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved4')"
                    [label]="'Reserved 4'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved5')"
                    [label]="'Reserved 5'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved6')"
                    [label]="'Reserved 6'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved7')"
                    [label]="'Reserved 7'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved8')"
                    [label]="'Reserved 8'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved9')"
                    [label]="'Reserved 9'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved10')"
                    [label]="'Reserved 10'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved11')"
                    [label]="'Reserved 11'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved12')"
                    [label]="'Reserved 12'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved13')"
                    [label]="'Reserved 13'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved14')"
                    [label]="'Reserved 14'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved15')"
                    [label]="'Reserved 15'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-xl-3">
                  <app-common-input
                    [control]="this.formEdit.get('reserved16')"
                    [label]="'Reserved 16'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
              </div>
            </div>

            <div class="card-footer text-right">
              <app-confirm-buttons (onConfirm)="onSubmit()" (onCancel)="cancel()"> </app-confirm-buttons>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
