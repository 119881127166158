export enum ValidationStyles {
  Enhanced = 1,
  System,
}
export enum TicketFamily {
  Cash = 1,
  Restricted_Promotional,
  No_Restricted_Promotional,
  Handpay,
  Jackpot,
  Degit,
}

// 1	Cash	True
// 2	Restricted Promotional	True
// 3	Non Restricted Promotional	True
// 4	Handpay	True
// 5	Jackpot	True
// 6	Debit	False

export enum TicketFamilyGroup {
  Cash = 1,
  Restricted_promotional,
  Non_restricted_promotional,
  Handpay,
  Jackpot,
  Debit,
}
export enum TickectTypeEnum {
  Restricted = 1,
  Cashable,
}

export enum MeterModelFlagEnum {
  NotSpecified = 0, // Boot de la Spider +
  WonCreditWhenWon, // Por pedido del HOST
  WonCreditWhenPlayedOrPaid, // Cumplio tiempo de Time Out (contadores)
  Reserved, // Como resultado de un evento
}

export enum TicketType {
  IN = 1,
  OUT,
}
export enum CashableType {
  UID = 1,
  CUID,
  UID_CUID_MCUID,
  MCUID,
}
export enum TicketState {
  Operable = 1,
  Canjeado,
  Anulado,
  Pendiente,
}
export enum PromoTicketState {
  Pendiente = 1,
  Habilitado,
  Anulado,
}
export enum PreInserted {
  No = 1,
  Si,
  Si_validado,
  Si_error,
  Sin_Cambios,
}
export enum CancellationReasons {
  Expiracion = 1,
  Anulacion,
  Error_de_fecha_Egm,
  No_impreso,
  Ticket_pending,
  Duplicado,
  HandPay_reemplazado,
  Error_en_familia_de_ticket,
  Error_en_Codigo,
  Canje,
  Alerta_Handpay_Ya_Usada,
}

export const cancellationReasonMap: { [key in CancellationReasons]: string } = {
  [CancellationReasons.Expiracion]: 'Expiración',
  [CancellationReasons.Anulacion]: 'Anulación',
  [CancellationReasons.Error_de_fecha_Egm]: 'Error de fecha Egm',
  [CancellationReasons.No_impreso]: 'No impreso',
  [CancellationReasons.Ticket_pending]: 'Ticket pending',
  [CancellationReasons.Duplicado]: 'Duplicado',
  [CancellationReasons.HandPay_reemplazado]: 'HandPay reemplazado',
  [CancellationReasons.Error_en_familia_de_ticket]: 'Error en familia de ticket',
  [CancellationReasons.Error_en_Codigo]: 'Error en Código',
  [CancellationReasons.Canje]: 'Canje',
  [CancellationReasons.Alerta_Handpay_Ya_Usada]: 'Alerta Handpay Ya Usada',
};

export enum GenerationReasons {
  Emitido_por_Caja = 1,
  Emitido_por_Egm,
  Pago_Manual_Sin_Codigo,
  Pago_Incidente_Sin_Codigo,
  Reemplazo_otro_ticket,
  Canje,
  Pago_Incidencia_Con_Codigo,
  Alerta_Ticket_Out_No_Generado,
}

export enum StateEgm {
  Ocupado = 1,
  Alarma,
  Desconectado,
  Fuera_de_servicio,
  Asistente,
  Libre,
  Atencion,
}

// Gewete = 1, //GAPI
// Gistra = 2, //GBG

export enum brandCashMachineEnum {
  MC_Gewete_GAPI_MC_Stock = 1, //GAPI  Host interroga stock

  MC_Gistra_GBG_HOST_Stock = 2, //GBG Host pregunta x http

  MC_Gewete_GBG_GAPI_Host_Stock = 3, //MC guarda stock que lee desde DB

  ATM_DISPENSADOR_DE_CREDITOS_GBG_Sin_Stock = 4, //GBG Finocio  no maneja stock
}

export const friendlyNames = {
  [brandCashMachineEnum.MC_Gewete_GAPI_MC_Stock]: 'MC Gewete - GAPI MC Stock',
  [brandCashMachineEnum.MC_Gistra_GBG_HOST_Stock]: 'MC Gistra - GBG HOST Stock',
  [brandCashMachineEnum.MC_Gewete_GBG_GAPI_Host_Stock]: 'MC Gewete - GBG GAPI Host Stock',
  [brandCashMachineEnum.ATM_DISPENSADOR_DE_CREDITOS_GBG_Sin_Stock]: 'ATM Dispensador de Créditos - GBG Sin Stock',
};

export function getFriendlyName(value: brandCashMachineEnum): string {
  return friendlyNames[value] || 'Unknown Value';
}

export enum StateCashMachine {
  Ocupado = 1,
  Desconectado = 3,
}

// public enum StateEgm
// {
//     Occupied = 1,
//     Alarm,
//     Disconnected,
//     OOS,
//     Assistance,
//     Free,
//     Warining,
// }

export enum EventsClasses {
  success = 1, //Occupied
  danger, //Alarm
  navy, //Disconnected
  secondary, //OOS
  warning, // Assistance
  info, //Free
  orange, //warning
}

// public enum StateEgm
// {
//     Occupied = 1,
//     Alarm,
//     Disconnected,
//     OOS,
//     Assistance,
//     Free,
//     Warining,
// }

export enum EventType {
  P = 1,
  Q,
}

export enum ProfitCalculationMotive {
  Manual = 1,
  Automatico,
}

export enum CountersStatus {
  Habilitado = 1,
  Deshabilitado,
}
export enum MessageSendMotive {
  Periodico,
  Post_evento,
}
export enum EGMSpiderStatus {
  Enabled = 1,
  Host_deshabilitado,
  EGM_deshabilitada,
}
export enum EGMHostStatus {
  Enabled = 1,
  Disabled,
}
export enum MessageStatus {
  Procesado = 1,
  Procesando,
  Error,
}
export enum CountersType {
  C = 1,
  V,
  Q,
}
export enum ProfitEvent {
  Rollover = 1,
  Sin_contadores,
}
export enum HandpayState {
  Pendiente_Auto_Acreditacion = 1, //PendingAutoAcreditacion = 1,
  Pendiente_Ticket_Out = 2, //PendingTicketOut
  Pendiente_Sin_Ticket_Out = 3, //PendingNoTicketOut
  Handpay_Recibo = 4, //ToPaidRecipit
  Handpay_Generado = 5, //ToPaidNoRecipit
  Pagado = 6, //Paid
  Cancelado = 7, //Cancelle d
}

export enum TicketIssuedBy {
  Cajero = 1,
  EGM,
  //Supervisor,
}

export enum TicketIssedMachine {
  EGM = 1,
  Caja = 2,
}

export enum OperationType {
  Alta = 9,
  Baja = 1,
  Modificacion = 2,
  Cierre = 3,
  Apertura = 4,
  Habilitado = 5,
  Deshabilitado = 6,
  Login = 7,
  Logout = 8,
  Nuevo_Periodo = 10,
}

export enum InstanceTypeEnum {
  StandAlone = 1,
  Client = 2,
  Server = 3,
}

export enum CashRegisterTypeEnum {
  Salon = 1,
  MaquinaDeCambio = 2,
  Apuestas = 3,
}

export enum AuthorizationOperationType {
  Pago_Datafono = 1,
  Pago_Factura,
  Pago_Apuestas,
  Pago_Gastos_Varios,
  Pago_Jackpot_Externo,
  Pago_Manual_Sin_Alerta,
  Pago_Manual_Ticket_No_Reportado,
  Pago_Incidencia,
  Pago_Incidencia_Con_Ticket,
}
