import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { userInfo } from 'os';
import { Observable, Subject } from 'rxjs';
import { ApiResult, DataTableParameters, DataTableResult } from '../../viewmodels/apiResult';
const moment = require('moment');

declare var $: any;

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.css'],
})
export class CommonTableComponent<T, U = any> implements OnInit {
  @Input()
  model: commonTableData<T>;

  buttons: any[] = [];

  table: any = {};

  @Input() excel: boolean = false;
  @Input() show: boolean = true;
  @Input() print: boolean = false;
  @Input() title: string = 'Exportación a excel';
  // @Input() excelColumns: number[] = [];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public filterString: string = '';

  dtTrigger: Subject<any> = new Subject();

  public commontableform: FormGroup;
  dtOptions: DataTables.Settings = {};
  data: T[];
  footerData: U = null;
  decimalData: number;
  intData: number;
  listIntData: number[];

  @Output() rowsNumber: EventEmitter<number> = new EventEmitter<number>();
  @Output() footerLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onEditButton = new EventEmitter();
  @Output() onDeleteButton = new EventEmitter();
  @Output() onCustomButton = new EventEmitter();
  @Output() onSearch = new EventEmitter();

  constructor(private _fb: FormBuilder, private elRef: ElementRef) {}

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.on('page.dt length.dt', () => {
        const info = dtInstance.page.info();
        this.onPageChange({
          pageIndex: info.page,
          pageSize: info.length,
        });
      });
    });
  }

  onPageChange(event: any): void {
    console.log('Número de página actual:', event.pageIndex + 1);
    console.log('Número de items por página:', event.pageSize);
    // Lógica adicional aquí
  }

  ngAfterViewChecked() {
    this.tooltip();
  }

  public setFooterText(footerText: string) {
    if (this.excel) {
      this.buttons[0].messageBottom = footerText;
      //this.setOptions();
    }
  }

  //seteo el filtro en el excel si hay.  lo tengo que hacer necesariamente en el submit poruqe varian segun lo que seleccione
  public setFilterString(filterString: string) {
    if (this.excel) {
      this.filterString = filterString;

      (this.buttons[0].messageTop = this.filterString),
        (this.buttons[0].title = this.title + ' ' + moment().local().format('DD-MM-yyyy')),
        this.setOptions();
    }
  }

  reload(statusTable?: tableStatus): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('[data-toggle="tooltip"]').tooltip('dispose');

      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();

      // this.tooltip();
      // dtInstance.search("busqueda");
      // dtInstance.page.info().length = 25;

      // this.dtOptions = {
      //   searching: this.model.searching,
      // }

      //this.setOptions();
    });
  }

  public editButtonClick(this: any, element: any): void {
    var rowId = element.dataset['row'];
    let row: T = this.data[rowId];
    this.onEditButton.emit(row);
  }

  public deleteButtonClick(this: any, element: any): void {
    var rowId = element.dataset['row'];
    let row: T = this.data[rowId];

    this.onDeleteButton.emit(row);
  }

  public customButtonClick(this: any, element: any): void {
    var rowId = element.dataset['row'];
    let row: T = this.data[rowId];

    let indexButton = element.dataset['custombutton'];

    this.onCustomButton.emit({ row: row, indexButton: indexButton, rowId: rowId });
  }

  public setSearchString(searchString: string) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchString);
    });
  }

  public drawTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  setButtons() {
    this.buttons = [];

    //agrego la columna codigo validador a al indice que no debo convertir:

    //a todos los validator code, si los transforma el excel luego tengo una logica para q no lo haga
    this.model.columns.forEach((column, index) => {
      if (column.data === 'validatorCode' && !this.model.indexNotConvertInExcel.includes(index)) {
        this.model.indexNotConvertInExcel.push(index);
      }
    });

    //de afuera le puedo decir que columnas van al excel y cuales no. sino se lo digo las calcula solo
    //en base al vissible. Si hay una columna custom no estan en el array de columns y quedan mal los indices
    if (this.model.excelColumns.length === 0) {
      //
      const newColumns: DataTables.ColumnSettings[] = this.model.commonTableCustomButton.map(() => {
        return { data: null };
      });

      //voy a usar un nuevo columns, agregandole los botones custom para no perder los indices:
      const combinedColumns: DataTables.ColumnSettings[] = [...newColumns, ...this.model.columns];

      this.model.excelColumns = combinedColumns
        .map((column, i) => {
          const val = (column.visible === undefined || column.visible === true) && column.data ? i : null;

          return val;
        })
        .filter((index) => index !== null);
    }
    var that = this;
    if (this.excel) {
      this.buttons.push({
        charSet: 'utf-8',
        extend: 'excelHtml5',
        text: 'Exportar a Excel',
        title: this.title + ' ' + moment().local().format('DD-MM-yyyy'),
        exportOptions: {
          columns: this.model.excelColumns,
        },

        customize: function (xlsx) {
          that.model.indexNotConvertInExcel.forEach((index) => {
            //transformo el index a letra mayuscula.  0 es A
            const columnLetter = String.fromCharCode(65 + index);

            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            var formuladasCalculadas = $(`row c[r^="${columnLetter}"]:gt(0)`, sheet).filter(function () {
              return $(this).find('v').length > 0;
            });

            formuladasCalculadas.attr('t', 'inlineStr'); //add :gt(0) to skip column title
            formuladasCalculadas.append('<is><t xml:space="preserve"></t></is>');
            formuladasCalculadas.find('is > t', sheet).each(function () {
              //solo le pone formula a los q empiezan con 77
              if ($(this).parent().siblings('v').length > 0) {
                $(this).text($(this).parent().siblings('v').text()); //paste text to new node
                $(this).parent().siblings('v').remove(); //remove useless v node
              }
            });
          });
        },
      });
    }

    if (this.print) {
      this.buttons.push({
        extend: 'print',
        text: 'Imprimir',
        title: 'Tabla',
        titleAttr: 'Imprimir',
      });
    }
  }
  ngOnInit(): void {
    this.setButtons();

    for (let i = 0; i < this.model.commonTableCustomButton.length; i++) {
      if (this.model.commonTableCustomButton[i].index == null) this.model.commonTableCustomButton[i].index = i;
    }

    this.commontableform = new FormGroup({});

    // const that = this;

    if (this.model.commonTableCustomButton != null && this.model.commonTableCustomButton.length > 0) {
      let index = 0;
      this.model.commonTableCustomButton.forEach((button) => {
        let order = button.order;
        if (button.order == null) {
          order = this.model.columns.length;
        }

        this.model.columns.splice(order, 0, {
          render: function (data, type, row, meta) {
            let htmlButtons = `<a class="btn custombutton" data-customButton="${button.index}" data-row="idrow"
          data-toggle="tooltip" data-placement="right" title="${button.tooltip}"><i class="fas ${button.icon}"></i>${button.text}</a>`;
            return htmlButtons.replace(/idrow/gi, meta.row.toString());
          },
          title: button.columntitle,
          data: `custombutton-${button.index}`,
        });
      });
    }

    if (this.model.showGridButtons) {
      let htmlButtons: string = '';
      if (this.model.showEditButton) {
        htmlButtons += `<a class="btn editButton" data-row='idrow'  data-toggle="tooltip" data-placement="right" title="Editar"><i class="fas fa-edit"></i></a>`;
      }

      if (this.model.showDeleteButton) {
        htmlButtons += `<a class="btn deleteButton" data-row="idrow" canremovedtag data-toggle="tooltip" data-placement="right" title="Eliminar"><i class="fas fa-trash-alt"></i></a>`;
      }

      if (this.model.showEditButton) {
        this.model.columns.push({
          render: function (data, type, row, meta) {
            var newButton = htmlButtons.replace(/idrow/gi, meta.row.toString());

            if (row.canRemove == null || row.canRemove) {
              newButton = newButton.replace(/canremovedtag/gi, '');
            } else {
              newButton = newButton.replace(/canremovedtag/gi, 'style=visibility:hidden');
            }

            return newButton;
          },
          data: 'EditDeleteButton',
        });
      }
    }
    this.setOptions();
  }

  setOptions() {
    if (this.model.paging) {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: this.model.pageLength ? this.model.pageLength : 10,
        displayStart: this.model.currentPage ? this.model.currentPage * this.model.pageLength : 0,
        serverSide: this.model.serverSide,
        processing: this.model.processing,
        ordering: this.model.ordering,
        paging: this.model.paging,
        searching: this.model.searching,
        scrollX: true,
        select: true,
        columnDefs: [{ className: 'dt-center', targets: '_all' }],
        // infoCallback: (oSettings, iStart, iEnd, iMax, iTotal, sPre) => {
        //   //this.recordCount = iTotal;
        //   return sPre;
        // },
        lengthMenu: this.model.showAll
          ? [
              [10, 25, 50, 100, -1],
              ['10', '25', '50', '100', 'Todos'],
            ]
          : [
              [10, 25, 50, 100],
              ['10', '25', '50', '100'],
            ],
        search: {
          search: this.model.searchText,
        },
        dom:
          "<'row'<'col-12'f><'col-sm-6 d-flex align-items-center'l><'col-sm-6 d-flex justify-content-end my-1'B>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",

        // buttons: [
        //   'copy', 'csv', 'excel', 'print'
        // ],

        buttons: this.buttons,
        language: {
          processing: 'Procesando...',
          search: 'Buscar:',
          lengthMenu: 'Mostrar _MENU_ registros',
          info: 'Mostrando desde _START_ al _END_  de _TOTAL_  registros',
          infoEmpty: 'Mostrando ningún elemento.',
          infoFiltered: '(filtrado _MAX_ registros total)',
          infoPostFix: '',
          loadingRecords: 'Cargando registros...',
          zeroRecords: 'No se encontraron registros',
          emptyTable: 'No hay datos para mostrar',
          paginate: {
            first: 'Primero',
            previous: 'Anterior',
            next: 'Siguiente',
            last: 'Último',
          },
          aria: {
            sortAscending: ': Activar para ordenar la tabla en orden ascendente',
            sortDescending: ': Activar para ordenar la tabla en orden descendente',
          },
        },
        ajax: (dataTablesParameters: DataTableParameters, callback) => {
          if (
            this.model.getData == null ||
            (dataTablesParameters.draw == 1 && !this.model.loadFirstTime && dataTablesParameters.search.value == '')
          ) {
            this.data = [];
            this.model.loadFirstTime = true;
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          } else {
            this.model.getData(dataTablesParameters).subscribe((resp) => {
              let thatf = this;

              //el footer puede venir aca, o directamente en la consulta principal. tengo deprecar esta forma:
              if (this.model.getDataFooter != null) {
                this.model.getDataFooter(dataTablesParameters).subscribe((footer) => {
                  thatf.footerData = footer.data;
                  this.footerLoaded.emit();
                });
              }

              this.data = resp.data.dataResults;
              if (resp.data.footer != null) this.footerData = resp.data.footer;

              this.decimalData = resp.data.decimalData;
              this.intData = resp.data.intData;
              this.listIntData = resp.data.listIntData;
              // this.dtTrigger.next();
              this.rowsNumber.emit(resp.data.recordsTotal);

              const that = this;
              setTimeout(function () {
                //Esto solo debe buscar un boton, aca busca muchos
                const buttonEdit = that.elRef?.nativeElement.querySelectorAll('.editButton');

                buttonEdit?.forEach((element) => {
                  element.addEventListener('click', that.editButtonClick.bind(that, element));
                });

                const buttonsDelete = that.elRef?.nativeElement.querySelectorAll('.deleteButton');

                buttonsDelete?.forEach((element) => {
                  element.addEventListener('click', that.deleteButtonClick.bind(that, element));
                });

                const customButtons = that.elRef?.nativeElement.querySelectorAll('.custombutton');
                customButtons?.forEach((element) => {
                  element.addEventListener('click', that.customButtonClick.bind(that, element));
                });

                ///veo   de ocultar los botones custom segun condiciones:
              }, 1000);
              callback({
                recordsTotal: resp.data.recordsTotal,
                recordsFiltered: resp.data.recordsFiltered,
                data: resp.data.dataResults,
              });
            });
          }
        },

        columns: this.model.columns,
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          if (this.model.rowCallback != null) {
            this.model.rowCallback(row, data, index);
          }

          if (this.model.allowSelectRow || this.model.allowMultiSelectRow) this.selectRow(this, row);
        },
        drawCallback: (data: any) => {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            let statusTable = new tableStatus();
            statusTable.searchString = dtInstance.search();
            statusTable.currentPage = dtInstance.page.info().page;
            statusTable.pageLength = dtInstance.page.info().length;
            this.onSearch.emit(statusTable);
          });

          // Inicializar tooltips después de cada redibujado
          $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover',
          });

          // Eliminar y reasignar el manejador de eventos para 'mouseleave' y 'click'
          $('[data-toggle="tooltip"]')
            .off('mouseleave click')
            .on('mouseleave click', function () {
              $(this).tooltip('dispose');
            });
          // this.cleanTooltip();
        },
      };
    } else {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: this.model.pageLength ? this.model.pageLength : 10,
        displayStart: this.model.currentPage ? this.model.currentPage * this.model.pageLength : 0,
        serverSide: this.model.serverSide,
        processing: this.model.processing,
        ordering: this.model.ordering,
        paging: this.model.paging,
        searching: this.model.searching,
        scrollX: true,
        select: true,
        search: {
          search: this.model.searchText,
        },
        // dom:
        //   "<'row'<'col-sm-12'tr>>" +
        //   "<'row'<'col-sm-5'i><'col-sm-7'p>>",

        columnDefs: [{ className: 'dt-center', targets: '_all' }],

        dom:
          "<'row'<'col-12'f><'col-sm-6 d-flex align-items-center'l><'col-sm-6 d-flex justify-content-end my-1'B>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",

        buttons: this.buttons,
        language: {
          processing: 'Procesando...',
          search: 'Buscar:',
          lengthMenu: 'Mostrar _MENU_ registros',
          info: 'Mostrando _END_ registros',
          infoEmpty: 'Mostrando ningún elemento.',
          infoFiltered: '(filtrado _MAX_ registros total)',
          infoPostFix: '',
          loadingRecords: 'Cargando registros...',
          zeroRecords: 'No se encontraron registros',
          emptyTable: 'No hay datos para mostrar',
          paginate: {
            first: 'Primero',
            previous: 'Anterior',
            next: 'Siguiente',
            last: 'Último',
          },
          aria: {
            sortAscending: ': Activar para ordenar la tabla en orden ascendente',
            sortDescending: ': Activar para ordenar la tabla en orden descendente',
          },
        },
        ajax: (dataTablesParameters: DataTableParameters, callback) => {
          if (dataTablesParameters.draw == 1 && !this.model.loadFirstTime && dataTablesParameters.search.value == '') {
            this.data = [];
            this.model.loadFirstTime = true;
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          } else {
            this.model.getData(dataTablesParameters).subscribe((resp) => {
              let thatf = this;
              if (this.model.getDataFooter != null) {
                this.model.getDataFooter(dataTablesParameters).subscribe((footer) => {
                  thatf.footerData = footer.data;
                });
              }

              this.data = resp.data.dataResults;
              this.decimalData = resp.data.decimalData;
              // this.dtTrigger.next();
              this.rowsNumber.emit(resp.data.recordsTotal);

              const that = this;

              setTimeout(function () {
                //Esto solo debe buscar un boton, aca busca muchos
                const buttonEdit = that.elRef.nativeElement.querySelectorAll('.editButton');

                buttonEdit.forEach((element) => {
                  element.addEventListener('click', that.editButtonClick.bind(that, element));
                });

                const buttonsDelete = that.elRef.nativeElement.querySelectorAll('.deleteButton');

                buttonsDelete.forEach((element) => {
                  element.addEventListener('click', that.deleteButtonClick.bind(that, element));
                });

                const customButtons = that.elRef.nativeElement.querySelectorAll('.custombutton');
                customButtons.forEach((element) => {
                  element.addEventListener('click', that.customButtonClick.bind(that, element));
                });
              }, 1000);
              callback({
                recordsTotal: resp.data.recordsTotal,
                recordsFiltered: resp.data.recordsFiltered,
                data: resp.data.dataResults,
              });
            });
          }
        },

        columns: this.model.columns,
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          if (this.model.rowCallback != null) {
            this.model.rowCallback(row, data, index);
          }

          if (this.model.allowSelectRow || this.model.allowMultiSelectRow) this.selectRow(this, row);
        },
        drawCallback: (data: any) => {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            let statusTable = new tableStatus();
            statusTable.searchString = dtInstance.search();
            statusTable.currentPage = dtInstance.page.info().page;
            statusTable.pageLength = dtInstance.page.info().length;
            this.onSearch.emit(statusTable);
          });

          //this.cleanTooltip();
          // Inicializar tooltips después de cada redibujado
          $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover',
          });

          // Eliminar y reasignar el manejador de eventos para 'mouseleave' y 'click'
          $('[data-toggle="tooltip"]')
            .off('mouseleave click')
            .on('mouseleave click', function () {
              $(this).tooltip('dispose');
            });
        },
      };
    }
  }

  tooltip() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
    $('[data-toggle="tooltip"]').on('mouseleave', function () {
      $(this).tooltip('dispose');
    });
    $('[data-toggle="tooltip"]').on('click', function () {
      $(this).tooltip('dispose');
    });
    // Asegúrate de limpiar el tooltip cuando el mouse deja el elemento o después de hacer clic.
    $('[data-toggle="tooltip"]').on('mouseleave click', function () {
      $(this).tooltip('dispose');
    });
  }

  selectRow(self, row: Node): void {
    // Unbind first in order to avoid any duplicate handler
    // (see https://github.com/l-lin/angular-datatables/issues/87)
    $('td', row).unbind('click');
    $('td', row).bind('click', () => {
      self.clickHandler(row);
    });
  }

  cleanTooltip() {
    // Inicializar tooltips después de cada redibujado
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });

    // Eliminar y reasignar el manejador de eventos para 'mouseleave' y 'click'
    $('[data-toggle="tooltip"]')
      .off('mouseleave click')
      .on('mouseleave click', function () {
        $(this).tooltip('dispose');
      });
  }

  clickHandler(info: Node): void {
    if ($(info).hasClass('selected')) {
      $(info).removeClass('selected');
    } else {
      if (!this.model.allowMultiSelectRow) {
        $('.selected').removeClass('selected'); //Esta linea podria sacarse si se quisiera un multiselect
      }
      $(info).addClass('selected');
    }
  }
}

export class commonTableData<T, U = any> {
  rowCallback: (row: Node, data: any[] | Object, index: number) => void;
  getData: (dataParam: any) => Observable<ApiResult<DataTableResult<T>>>;

  getDataFooter: (dataParam: any) => Observable<ApiResult<U>>;

  indexNotConvertInExcel: number[] = [];
  excelColumns: number[] = [];
  columns: DataTables.ColumnSettings[];
  showEditButton: boolean = false;
  showDeleteButton: boolean = false;
  showCreateButton: boolean = false;
  allowSelectRow: boolean = false;
  allowMultiSelectRow: boolean = false;
  ordering: boolean = false;
  paging: boolean = false;
  searching: boolean = false;
  serverSide: boolean = true;
  processing: boolean = true;
  showAll: boolean = false;
  commonTableCustomButton: commonTableCustomButton[] = [];
  loadFirstTime: boolean = true;
  searchText: string = '';

  currentPage: number;
  pageLength: number;

  public showGridButtons(): boolean {
    return this.showDeleteButton || this.showEditButton;
  }
}

export class commonTableCustomButton {
  icon: string = 'fa-user';
  tooltip: string = '';
  text: string = '';
  columntitle: string = '';
  order?: number;
  index?: number;
}

export class tableStatus {
  searchString: string = '';
  currentPage: number = 0;
  pageLength: number = 10;
}
