<common-header [title]="title" [items]="items"></common-header>
<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange !== null"> - {{ dateRange }} </span>
              </h3>
              <div class="card-tools">
                <button type="button" class="btn btn-tool mr-2" (click)="onSubmit()">
                  <i class="fas fa-redo"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [controlFrom]="this.formSearch.get('from')"
                    [controlTo]="this.formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm-4">
                  <egm-combo [multiselect]="true" [control]="formSearch.get('egmUid')"></egm-combo>
                </div>
                <div class="col-12 col-sm-4">
                  <common-input-multiselect
                    class="form-control-sm"
                    [label]="'Grupo EGMs'"
                    [list]="this.creditValueCombo"
                    formControlName="creditValue">
                  </common-input-multiselect>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button (click)="onSubmit()" data-card-widget="collapse" class="btn btn-info">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row" *ngIf="this.searchMade">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              [excel]="true"
              title="Ganancia por Grupo EGMs"
              (onSearch)="onSearch($event)">
            </app-common-table>
          </div>

          <div class="card-footer">
            <div class="row">
              <div class="col-2 d-flex">
                <span class="resalt">Ganancia total: </span
                ><span class="resalt" [class.text-success]="totalProfit > 0" [class.text-danger]="totalProfit < 0">
                  {{ currencyPipe.transform(totalProfit, currencyCode, currencySym) }}
                </span>
              </div>

              <div class="col-2 d-flex">
                <span class="resalt">Cantidad de máquinas:</span
                ><span class="resalt text-success">{{ machineQuantity }}</span>
              </div>
              <div class="col-8 d-flex"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
