import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-handpay-voucher',
  templateUrl: './handpay-voucher.component.html',
  styleUrls: ['./handpay-voucher.component.css'],
})
export class HandpayVoucherComponent {
  items: Array<BreadcrumbItem> = [];
  idTurn: number = 0;
  loadpage: boolean = false;
  title: string = 'Alerta Pago Manual';
  public static getName() {
    return 'HandpayVoucherComponent';
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', this.title));

    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.idTurn = Number(paramTurnsId);
      this.loadpage = true;
      if (isNaN(this.idTurn)) {
        this.goToErrorPage();
      }
    } else {
      this.goToErrorPage();
    }
  }
  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }
}
