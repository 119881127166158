import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, HostListener, AfterViewChecked, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { UsersService } from 'src/app/services/users/users.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  tenantList: Array<IdTextModel> = [];
  isFullScreen: boolean = false;
  userName: Observable<string>;
  elem: any;
  logoutUrl: string;
  formSearch: FormGroup;
  titleTenant: string = 'Salón';
  tenantUrl: string;
  facilityName: Observable<string>;
  @Output() TenantLoaded: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject('BASE_URL') public baseUrl: string,
    private UsersService: UsersService,
    private authorizeService: AuthorizeService,
    private casinoService: CasinoService,
    private tenantService: TenantService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.logoutUrl = this.baseUrl + 'Identity/Account/Logout';
  }

  ngOnInit(): void {
    $('[data-widget="pushmenu"]').PushMenu({ autoCollapseSize: 768 });
    this.elem = document.documentElement;
    this.userName = this.authorizeService.getUser().pipe(map((u) => u && u.nombre));
    this.facilityName = this.casinoService.getFacilityName().pipe(map((u) => u && u.data));

    this.formSearch = new FormGroup({
      tenantId: new FormControl(null),
    });

    const tenantIdStorage = localStorage.getItem('tenantId');

    this.casinoService.getTenantCombo().subscribe((x) => {
      this.tenantList = x.data;

      if (this.tenantList.length > 0) {
        //   //me fijo en la url, sino pongo el primero:
        this.tenantUrl = this.route.snapshot.queryParamMap.get('t');

        if (this.tenantUrl != null) {
          this.formSearch.get('tenantId').setValue(this.tenantUrl);
          this.tenantService.setCurrentTenantId(this.formSearch.get('tenantId').value);
          //localStorage.setItem('tenantId', this.tenantUrl);
        }
        //me fijo en el localstorage, sino pongo el primero:
        else if (tenantIdStorage != null) {
          this.formSearch.get('tenantId').setValue(tenantIdStorage);
          this.tenantService.setCurrentTenantId(this.formSearch.get('tenantId').value);
        } else {
          //si en la url no hay nada pongo el primero
          this.formSearch.get('tenantId').setValue(this.tenantList[0].id);
          this.tenantService.setCurrentTenantId(this.formSearch.get('tenantId').value);
        }

        localStorage.setItem('tenantId', this.formSearch.get('tenantId').value);
        this.TenantLoaded.emit();
      }
    });
  }

  toggleFullscreen() {
    !this.isFullScreen ? this.openFullscreen() : this.closeFullscreen();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    this.isFullScreen = true;
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
    this.isFullScreen = false;
  }

  changeTenant() {
    // this.addTenantToUrl(this.formSearch.get('tenantId').value);

    //la agrego al local storage:

    var tenantId = this.formSearch.get('tenantId').value;
    localStorage.setItem('tenantId', tenantId);
    this.addTenantToUrl(tenantId);
  }

  addTenantToUrl(tenantId: string) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {
          t: tenantId,
        },
        queryParamsHandling: 'merge',
        // preserve the existing query params in the route
        // skipLocationChange: false
        // do not trigger navigation
      })
      .then(() => {
        window.location.reload();
      });

    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: {
    //     t: tenantId
    //   },
    // queryParamsHandling: 'merge',
    //   // preserve the existing query params in the route
    //  // skipLocationChange: false
    //   // do not trigger navigation
    // });
  }

  appendAQueryParam(tenantId: string) {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { t: tenantId },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });

    this.router.navigateByUrl(urlTree);

    // window.location.reload();
  }

  getTeantVal(): string {
    return this.formSearch.get('tenantId').value;
  }
}
