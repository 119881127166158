<div class="col-12">
  <app-common-input
    #myInput
    [control]="control"
    label="Código Validador"
    maxLenght="18"
    [formSubmitted]="formSubmitted"
    type="number"
    (keyUpEvent)="onKeyPress($event)"
    (inputFull)="onFullInput()">
  </app-common-input>
  <!-- <label class="text-info font-size-14" style="font-size: 14px">*Último Buscado: {{lastSerch}}</label> -->
</div>
