import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModelService } from 'src/app/services/model/model.service';
import { GuidTextModel, IdTextModel } from '../../shared/IdTextModel';

@Component({
  selector: 'models-combo',
  templateUrl: './models-combo.component.html',
  styleUrls: ['./models-combo.component.css'],
})
export class ModelsComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  combo: Array<GuidTextModel> = [];
  label: string = 'Modelo';
  @Output() valueChanged = new EventEmitter();

  constructor(private ModelService: ModelService) {}

  ngOnInit(): void {
    this.fillCombo();
    if (this.multiselect) this.label = 'Modelos';
  }

  public async fillCombo() {
    var result = await this.ModelService.getModelsCombo().toPromise();
    this.combo = result.data;
  }

  public async fillComboWithManufacturerId(id: number) {
    var result = await this.ModelService.getModelsCombo2(id).toPromise();
    this.combo = result.data;
  }

  public async fillComboWithManufacturersId(ids: Array<string>) {
    var result = await this.ModelService.getModelsComboByManufacturersId(ids).toPromise();
    this.combo = result.data;
  }

  public getCombo() {
    return this.combo;
  }

  public getModelComboAsIdArray(): string[] {
    var arr: string[] = [];
    this.combo.forEach((element) => {
      arr.push(element.id);
    });
    return arr;
  }
}
