import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import * as moment from 'moment-timezone';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { MinimalFilters, MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { CountersCurrent } from 'src/app/common/components/viewmodels/CountersMock';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { CountersService } from 'src/app/services/counters/counters.service';

@Component({
  selector: 'app-detailed-counters-instant',
  templateUrl: './detailed-counters-instant.component.html',
  styleUrls: ['./detailed-counters-instant.component.css'],
})
export class DetailedCountersinstantComponent implements OnInit, AfterViewInit {
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CountersCurrent>;

  egmCombo: Array<IdTextModel> = [];
  excelColumns: number[] = [];
  items: Array<BreadcrumbItem> = [];
  dateRange: string;
  date: moment.Moment = moment(new Date());
  public data: commonTableData<CountersCurrent>;
  uid: string = null;
  title: string = 'Contadores Instantáneos';
  formSearch: FormGroup;
  parameter: EditCountersParameters = new EditCountersParameters();
  counterObject: CountersCurrent = new CountersCurrent();
  messageStatus: string;

  public static getName() {
    return 'DetailedCountersinstantComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    private activatedRoute: ActivatedRoute,
    private counterService: CountersService,
    private paramService: ParametersService<EditCountersParameters>
  ) {}

  ngAfterViewInit(): void {
    let paramEditCounters = this.paramService.getAndDeleteParameters(DetailedCountersinstantComponent.getName());

    if (paramEditCounters != null) {
      this.formSearch.get('egmUid').setValue(paramEditCounters.uid);
    }
  }
  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Contadores Instántaneos'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();
    this.initGrid();
  }
  initGrid() {
    this.data = new commonTableData();

    let paramEditCounters = this.paramService.getAndDeleteParameters(DetailedCountersinstantComponent.getName());

    this.data.loadFirstTime = false;

    //    console.log(paramEditCounters);
    if (paramEditCounters != null) {
      this.data.searchText = paramEditCounters.status.searchString;
      this.data.pageLength = paramEditCounters.status.pageLength;
      this.data.currentPage = paramEditCounters.status.currentPage;
      this.formSearch.get('egmUid').setValue(paramEditCounters.uid);

      var filters = new MinimalFilters();
      filters.egm = this.formSearch.get('egmUid').value;

      this.data.loadFirstTime = true;
    }

    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;

    this.data.columns = [
      {
        data: 'eventDate',
        title: 'Fecha Evento',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'LastAddedToCounters',
        title: 'últ. actualización',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'egm.uid', title: 'EGM' },

      { data: 'egm.manufacturer.name', title: 'Fabricante' },
      { data: 'egm.model.name', title: 'Modelo' },
      { data: 'egm.egmConfig.formattedDenomination', title: 'Denominación' },
      { data: 'coinIn', title: 'Coin_IN' },
      { data: 'coinOut', title: 'Coin_OUT' },
      { data: 'jackpot', title: 'Total Jackpot' },
      { data: 'totalDrop', title: 'Total Drop' },
      { data: 'cancelledCredits', title: 'Cancelled Credits' },
      { data: 'handpayCC', title: 'Handpay CC' },
      { data: 'billIn', title: 'Bill_IN' },
      { data: 'billOut', title: 'Bill_OUT' },
      { data: 'totalTicketIn', title: 'Total TCK_IN' },
      { data: 'totalTicketOut', title: 'Total TCK_OUT' },
      { data: 'coinDrop', title: 'Coin Drop' },
      { data: 'trueCoinIn', title: 'True Coin In' },
      { data: 'trueCoinOut', title: 'True Coin Out' },
      { data: 'eftIn', title: 'EFT In' },
      { data: 'aftIn', title: 'AFT In' },
      { data: 'aftOut', title: 'AFT Out' },
      { data: 'gamesPlayed', title: 'J. Jugados' },
      { data: 'gamesWon', title: 'J. Ganados' },
      { data: 'gamesLost', title: 'J. Perdidos' },
      { data: 'billM1Q', title: 'Bill M1 Q' },
      { data: 'billM2Q', title: 'Bill M2 Q' },
      { data: 'billM3Q', title: 'Bill M3 Q' },
      { data: 'billM4Q', title: 'Bill M4 Q' },
      { data: 'billM5Q', title: 'Bill M5 Q' },
      { data: 'billM6Q', title: 'Bill M6 Q' },
      { data: 'billM7Q', title: 'Bill M7 Q' },
      { data: 'billM8Q', title: 'Bill M8 Q' },
      { data: 'billToDrop', title: 'Bill To Drop' },
      { data: 'billToRecycle', title: 'Bill To Recycle' },
      { data: 'ticketCashIn', title: 'TCK_IN Cashable' },
      { data: 'ticketCashInQ', title: 'TCK_IN Cashable Q' },
      { data: 'ticketRPIn', title: 'TCK_IN Restr' },
      { data: 'ticketRPromInQ', title: 'TCK_IN Restr Q' },
      { data: 'ticketNoRestPromIn', title: 'TCK_IN No Restr' },
      { data: 'ticketNoRestPromInQ', title: 'TCK_IN No Restr Q' },
      { data: 'ticketCashOut', title: 'TCK_OUT Cashable' },
      { data: 'ticketCashOutQ', title: 'TCK_OUT Cashable Q' },
      { data: 'ticketRestPromOut', title: 'TCK_OUT Restr' },
      { data: 'ticketRestPromOutQ', title: 'TCK_OUT Restr Q' },
      { data: 'mptp', title: 'MPTP' },
      { data: 'mppp', title: 'MPPP' },
      { data: 'mpebp', title: 'MPEBP' },
      { data: 'aptp', title: 'APTP' },
      { data: 'appp', title: 'APPP' },
      { data: 'apebp', title: 'APEBP' },
      { data: 'doorQ', title: 'Door Q' },
      { data: 'powerReset', title: 'Power Reset' },

      { data: 'gamesSincePowerUp', title: 'Games Since PowerUp' },
      { data: 'gamesSinceDoorClosed', title: 'Games Since Door Closed' },
      { data: 'currentHopperLevel', title: 'Current Hopper Level' },
      { data: 'totalHandPaidCredits', title: 'Total HandPaid Credits' },
      { data: 'reserved1', title: 'Reserved1' },
      { data: 'reserved2', title: 'Reserved2' },
      { data: 'reserved3', title: 'Reserved3' },
      { data: 'reserved4', title: 'Reserved4' },
      { data: 'reserved5', title: 'Reserved5' },
      { data: 'reserved6', title: 'Reserved6' },
      { data: 'reserved7', title: 'Reserved7' },
      { data: 'reserved8', title: 'Reserved8' },
      { data: 'reserved9', title: 'Reserved9' },
      { data: 'reserved10', title: 'Reserved10' },
      { data: 'reserved11', title: 'Reserved11' },
      { data: 'reserved12', title: 'Reserved12' },
      { data: 'reserved13', title: 'Reserved13' },
      { data: 'reserved14', title: 'Reserved14' },
      { data: 'reserved15', title: 'Reserved15' },
      { data: 'reserved16', title: 'Reserved16' },
    ];

    let service = this.counterService;
    if (paramEditCounters != null) {
      this.setGridData(filters);
    } else {
      this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CountersCurrent>>> {
        var DataTableFilters = new DatatableMinimalFilters();
        DataTableFilters.filters = filters;
        DataTableFilters.dataTableParameters = param;
        return service.getEmptyDataTableCountersCurrent(DataTableFilters);
      };
    }
  }

  onSearch(status: tableStatus) {
    this.parameter.status = status;
  }

  updateTable() {
    this.setGridData(this.getFilters());
    this.table.reload();
  }

  setGridData(filters: MinimalFilters): void {
    let service = this.counterService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CountersCurrent>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.GetInstantCounters(DataTableFilters);
    };
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      egmUid: new FormControl(null, Validators.required),
      from: new FormControl(),
    });
  }

  onSubmit() {
    if (this.formSearch.valid) {
      this.updateTable();
    }
  }

  getFilters() {
    var filters: MinimalFilters = new MinimalFilters();
    filters.egm = this.formSearch.get('egmUid').value;
    return filters;
  }

  get formSearchNull(): boolean {
    return this.formSearch.get('egmUid').value == null;
  }
}

export class EditCountersParameters {
  status: tableStatus;
  uid: number;
}
