import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmButtonsComponent } from 'src/app/common/components/shared/confirm-buttons/confirm-buttons.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { Spider } from 'src/app/common/components/viewmodels/Spider';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { DaterangepickerService } from 'src/app/services/daterangepicker/daterangepicker.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SpiderService } from 'src/app/services/spider/spider.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-spider',
  templateUrl: './edit-spider.component.html',
  styleUrls: ['./edit-spider.component.css'],
})
export class EditSpiderComponent implements OnInit {
  @ViewChild(ConfirmButtonsComponent, { static: false }) confirmButton: ConfirmButtonsComponent;

  formEdit: FormGroup;
  title: string = 'Nueva spider+';

  InputType = InputTypeEnum;

  items: Array<BreadcrumbItem> = [];
  showSave: Observable<boolean>;
  spider: Spider = new Spider();
  isEdit: boolean;
  showextended: Observable<boolean>;
  parameter: tableStatus = null;
  macChanged: boolean = false;
  canEdit: boolean;

  public static getName() {
    return 'EditSpiderComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private SpiderService: SpiderService,
    private casinoService: CasinoService,
    private daterangepickerService: DaterangepickerService
  ) {}

  ngOnInit(): void {
    this.showextended = this.casinoService
      .isComponentAuthorized('EditSpiderComponent', 'ShowExtended')
      .pipe(map((u) => u && u.data));

    this.initForm();
    this.parameter = this.paramService.getAndDeleteParameters();
    if (this.activatedRoute.snapshot.params.id != null) {
      this.SpiderService.getSpider(parseInt(this.activatedRoute.snapshot.params.id)).subscribe((result) => {
        this.formEdit.patchValue(result.data.spider);
        this.formEdit.controls['manufacturingDate'].setValue(
          moment(result.data.spider.manufacturingDate).format('YYYY-MM-DD')
        );
        this.canEdit = result.data.canEdit;
        if (!this.canEdit) {
          //this.confirmButton.disableYes();
          this.notificationService.showWarning(
            'No es posible editar la MAC ya que la spider+ está asociada a una EGM',
            'Atención!'
          );
          this.formEdit.controls['mac'].disable();
        } else {
          this.formEdit.controls['mac'].enable();
        }
      });
      this.formEdit.controls['mac'].enable();
      this.title = 'Editar Spider';
      this.isEdit = true;
      this.formEdit.controls['ipSuggested'].disable();
    } else {
      //nueva spider:
      this.daterangepickerService.getData().subscribe((result) => {
        this.formEdit.get('manufacturingDate').setValue(dayjs(result.data.now).format('YYYY-MM-DD'));
      });
    }

    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('/machines/spiders', 'Spiders Plus'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('machines/spiders');
  }

  onSubmit() {
    if (this.formEdit.valid) {
      this.spider = new Spider(this.formEdit.value);
      this.spider.ip = this.formEdit.get('ip').value;
      this.spider.id = Number(this.spider.id);

      this.paramService.saveParameters(this.parameter);
      this.SpiderService.createOrEdit(this.spider).subscribe((result) => {
        if (result.error == null) {
          this.router.navigate(['/machines/spiders']);
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(0),
      code: new FormControl(null, Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())),
      model: new FormControl(null, Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())),
      ipSuggested: new FormControl({ value: null }, [Validators.required, Validators.maxLength(15)]),
      ip: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.maxLength(15)]),
      checkSum: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.maxLength(15)]),
      extendedVersion: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.maxLength(15),
      ]),
      firmware: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      mac: new FormControl(null, [Validators.required, Validators.maxLength(17)]),
      comments: new FormControl(null, Validators.maxLength(255)),
      manufacturingDate: new FormControl(null, Validators.required),
    });
  }
}
