import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { IdTextModel } from '../../shared/IdTextModel';

@Component({
  selector: 'room-combo',
  templateUrl: './room-combo.component.html',
  styleUrls: ['./room-combo.component.css'],
})
export class RoomComboComponent implements OnInit {
  @Input() selectFirst: boolean = false;
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  public combo: Array<IdTextModel> = [];
  label: string = 'Sala';
  @Input() hideLabel: boolean = false;

  @Output() valueChanged = new EventEmitter();
  @Output() loadFinished = new EventEmitter();

  constructor(private RoomService: RoomServiceService) {}

  ngOnInit(): void {
    if (this.hideLabel) {
      this.label = '';
    }
    this.fillCombo();
    if (this.multiselect) this.label = 'Salas';
  }

  private fillCombo() {
    this.RoomService.getRoomsCombo().subscribe((result) => {
      this.combo = result.data;
      if (this.selectFirst) {
        this.control.setValue(this.combo[0]);
      }
      this.loadFinished.emit();
    });
  }

  public getCombo() {
    return this.combo;
  }
}
