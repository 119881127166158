<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange !== null"> - {{ dateRange }} </span>
              </h3>
              <div class="card-tools">
                <!-- <div class="bootstrap-switch-container" style="width: 126px; margin-left: -42px;">
                            <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 42px;">ON</span>
                            <span class="bootstrap-switch-label" style="width: 42px;">&nbsp;</span>
                            <span class="bootstrap-switch-handle-off bootstrap-switch-danger" style="width: 42px;">OFF</span>
                            
                            <input type="checkbox" name="my-checkbox" checked="" data-bootstrap-switch="" data-off-color="danger" data-on-color="success">
                        
                        </div> -->

                <button
                  [class.activeButton]="refreshPage"
                  type="button"
                  class="btn btn-tool mr-2"
                  (click)="onRefresh()">
                  <i class="fas fa-redo"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-3">
                  <app-common-daterangepicker
                    [controlFrom]="formSearch.get('from')"
                    [controlTo]="formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm-3">
                  <room-combo [control]="formSearch.get('rooms')" [multiselect]="true" (valueChanged)="updateEgms()">
                  </room-combo>
                </div>
                <div class="col-12 col-sm-3">
                  <egm-combo
                    [multiselect]="true"
                    [control]="formSearch.get('egmUid')"
                    [includeDeleted]="true"></egm-combo>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <span style="color: red" *ngIf="this.refreshPage">búsqueda automática activada</span>

                <button
                  [disabled]="this.refreshPage"
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info ml-2">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              [title]="title"
              [excel]="true"
              (onSearch)="onSearch($event)"
              (footerLoaded)="footerLoaded()">
            </app-common-table>

            <div *ngIf="this.table?.footerData !== null" style="margin-top: 20px">
              <div class="row">
                <div class="col-12 col-sm-2">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span class="info-box-text text-center text-muted">Total Jugado:</span>
                      <span class="info-box-number text-center text-muted mb-0">
                        {{
                          currencyPipe.transform(this.table?.footerData?.formattedPlayed, currencyCode, currencySym)
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-2">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span class="info-box-text text-center text-muted">Total Premiado:</span>
                      <span class="info-box-number text-center text-muted mb-0">{{
                        currencyPipe.transform(this.table.footerData.formattedWon, currencyCode, currencySym)
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-2">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span class="info-box-text text-center text-muted">Total Beneficio:</span>
                      <span class="info-box-number text-center text-muted mb-0">{{
                        currencyPipe.transform(this.table.footerData.formattedBenefit, currencyCode, currencySym)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-2">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span class="info-box-text text-center text-muted">Total Jugado Fichas:</span>
                      <span class="info-box-number text-center text-muted mb-0">{{
                        this.table.footerData.formattedPlayedChips
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-2">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span class="info-box-text text-center text-muted">Total Premiado Fichas:</span>
                      <span class="info-box-number text-center text-muted mb-0">{{
                        this.table.footerData.formattedWonChips
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-2">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span class="info-box-text text-center text-muted">Total Beneficio Fichas:</span>
                      <span class="info-box-number text-center text-muted mb-0">{{
                        this.table.footerData.formattedBenefitChips
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-2">
                  <div class="info-box bg-light">
                    <div class="info-box-content">
                      <span class="info-box-text text-center text-muted">Total Pago Manual</span>
                      <span class="info-box-number text-center text-muted mb-0">
                        {{ currencyPipe.transform(this.table.footerData.pagoManualMoney, currencyCode, currencySym) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
