import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { event } from 'jquery';
import { CommonInputComponent } from '../../shared/common-input/common-input.component';

@Component({
  selector: 'validation-code',
  templateUrl: './validation-code.component.html',
  styleUrls: ['./validation-code.component.css'],
})
export class ValidationCodeComponent implements OnInit, AfterViewChecked {
  @Input() control: FormControl;
  @Input() formSubmitted: boolean;
  @Input() cleanAfterCompleted: boolean;
  @Input() focus: boolean = false;
  @ViewChild(CommonInputComponent, { static: false }) commonInput: CommonInputComponent;
  lastSerch: string;
  @Output() inputFull = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    if (this.focus) {
      this.focusInput();
    }
  }

  public focusInput() {
    this.commonInput.focusField();
  }

  onKeyPress(event: any) {
    const pattern = /[0-9]/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }
  onFullInput() {
    this.inputFull.emit(this.control.value);

    if (this.cleanAfterCompleted) {
      this.lastSerch = this.control.value;

      this.control.setValue('');
    }
  }
}
