import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Tenant } from 'src/app/common/components/viewmodels/Casino';
import { FullUserViewModel, UsersViewModel } from 'src/app/common/components/viewmodels/User';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  // public getUsers(dataTablesParameters: any): Observable<ApiResult<DataTableResult<User>>> {

  //   var listUser: User[] = [];
  //   for (let i = 0; i < 1; i++) {

  //     var objRet0: User = new User();
  //     objRet0.id = 1;
  //     objRet0.name = 'Jorge Benitez';

  //     listUser.push(objRet0);

  //     var objRet: User = new User();
  //     objRet.id = 2;
  //     objRet.name = 'Carlos Gimenez';

  //     listUser.push(objRet);

  //     var objRet2: User = new User();
  //     objRet2.id = 3;
  //     objRet2.name = 'Juan Perez';

  //     listUser.push(objRet2);

  //     var objRet3: User = new User();
  //     objRet3.id = 4;
  //     objRet3.name = 'Luis Rodriguez';

  //     listUser.push(objRet3);

  //   }

  //   var ret = new ApiResult<DataTableResult<User>>()
  //   ret.data.dataResults = listUser;
  //   ret.data.recordsTotal = 8;
  //   ret.data.recordsFiltered = 8;

  //   return new Observable((o) => {
  //     o.next(ret)
  //   });
  // }

  // public getTicketsPerUser(dataTablesParameters: any): Observable<ApiResult<DataTableResult<User>>> {

  //   var listUser: User[] = [];
  //   for (let i = 0; i < 1; i++) {

  //     var objRet0: User = new User();
  //     objRet0.id = 1;
  //     objRet0.uid = 'C1000';
  //     objRet0.name = 'Jorge Benitez';
  //     objRet0.totalQuantity = 150;
  //     objRet0.totalAmount = 2050
  //     objRet0.cashableQuantity = 2;
  //     objRet0.cashableAmount = 150;
  //     objRet0.promotionalQuantity = 1
  //     objRet0.promotionalAmount = 10;
  //     objRet0.handpayQuantity = 1;
  //     objRet0.handpayAmount = 4000;
  //     objRet0.jackpotQuantity = 0;
  //     objRet0.jackpotAmount = 0;
  //     objRet0.uidTotal = objRet0.totalAmount - objRet0.cashableAmount - objRet0.promotionalAmount - objRet0.handpayAmount

  //     listUser.push(objRet0);

  //     var objRet: User = new User();
  //     objRet.id = 2;
  //     objRet.uid = 'C1001';
  //     objRet.name = 'Carlos Gimenez';
  //     objRet.totalQuantity = 45
  //     objRet.totalAmount = 2300
  //     objRet.cashableQuantity = 1;
  //     objRet.cashableAmount = 100;
  //     objRet.promotionalQuantity = 1
  //     objRet.promotionalAmount = 10;
  //     objRet.handpayQuantity = 1;
  //     objRet.handpayAmount = 4000;
  //     objRet.jackpotQuantity = 0;
  //     objRet.jackpotAmount = 0;
  //     objRet.uidTotal = objRet.totalAmount - objRet.cashableAmount - objRet.promotionalAmount - objRet.handpayAmount

  //     listUser.push(objRet);

  //     var objRet2: User = new User();
  //     objRet2.id = 3;
  //     objRet2.uid = 'C1002';
  //     objRet2.name = 'Juan Perez';
  //     objRet2.totalQuantity = 51
  //     objRet2.totalAmount = 2800
  //     objRet2.cashableQuantity = 2;
  //     objRet2.cashableAmount = 150;
  //     objRet2.promotionalQuantity = 1
  //     objRet2.promotionalAmount = 10;
  //     objRet2.handpayQuantity = 1;
  //     objRet2.handpayAmount = 4000;
  //     objRet2.jackpotQuantity = 0;
  //     objRet2.jackpotAmount = 0;
  //     objRet2.uidTotal = objRet2.totalAmount - objRet2.cashableAmount - objRet2.promotionalAmount - objRet2.handpayAmount

  //     listUser.push(objRet2);

  //   }

  //   var ret = new ApiResult<DataTableResult<User>>()
  //   ret.data.dataResults = listUser;
  //   ret.data.recordsTotal = 8;
  //   ret.data.recordsFiltered = 8;

  //   return new Observable((o) => {
  //     o.next(ret)
  //   });
  // }

  public GetUsersListDataTable(
    dataTablesParameters: DataTableParameters
  ): Observable<ApiResult<DataTableResult<UsersViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<UsersViewModel>>>(
        `${this.baseUrl}Users/GetUsersListDataTable`,
        dataTablesParameters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<UsersViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<UsersViewModel>>(error))
      );
  }

  public DeleteUser(id: string): Observable<ApiResult<string>> {
    return this.http.delete<ApiResult<string>>(`${this.baseUrl}Users/DeleteUserById?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getUsersCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Users/GetUsersCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getAllRolesCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Users/GetAllRolesCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public GetUserById(id: string): Observable<ApiResult<FullUserViewModel>> {
    return this.http.get<ApiResult<FullUserViewModel>>(`${this.baseUrl}Users/GetUserById?id=${id}`).pipe(
      map((x) => this.checkResult<FullUserViewModel>(x)),
      catchError((error) => this.handleError<FullUserViewModel>(error))
    );
  }

  public createOrEdit(user: FullUserViewModel): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Users/CreateOrEdit`, user).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
}
