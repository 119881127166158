import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { CommonInputSelectComponent } from '../../components/shared/common-input-select/common-input-select.component';
import { IdTextModel } from '../../components/shared/IdTextModel';
import { MinimalFilters, MultiselectFilters } from '../../components/viewmodels/Filters';

@Component({
  selector: 'egm-combo',
  templateUrl: './egm-combo.component.html',
  styleUrls: ['./egm-combo.component.css'],
})
export class EgmComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  @Input() roomId: number = null;
  @Input() isGetCashMachine: boolean = false;
  @Input() includeDeleted: boolean = false;
  @Input() isManualPay: boolean = false;

  @Input() allowClear: boolean = false;

  combo: Array<IdTextModel> = [];
  allElements: Array<IdTextModel> = [];
  label: string = 'EGM';
  @Output() valueChanged = new EventEmitter();

  @ViewChild(CommonInputSelectComponent) selectComponent: CommonInputSelectComponent;

  constructor(private EgmService: EgmServiceService) {}

  ngOnInit(): void {
    if (this.isGetCashMachine && this.roomId != null) {
      this.fillComboWithFreeCashMachine(this.roomId);
    } else if (this.isManualPay) {
      this.getEGMsComboManualPay();
    } else {
      this.roomId != null ? this.fillComboWithFreeEgms(this.roomId) : this.fillCombo();
    }
  }

  public getEGMsComboManualPay() {
    this.EgmService.getEGMsComboManualPay().subscribe((result) => {
      this.combo = result.data;
    });
  }

  public fillCombo() {
    this.EgmService.getEGMsCombo(this.includeDeleted).subscribe((result) => {
      this.combo = result.data;
    });
  }

  public fillComboWithFreeEgms(roomId: number) {
    this.EgmService.getFreeEGMsCombo(roomId).subscribe((result) => {
      this.combo = result.data;
    });
  }
  public fillComboWithFreeCashMachine(roomId: number) {
    this.EgmService.getFreeCashMachineCombo(roomId).subscribe((result) => {
      this.combo = result.data;
    });
  }

  public fillEgmsWithMultiselectFilters(ids: MultiselectFilters) {
    ids.includeDeleted = this.includeDeleted;
    this.EgmService.fillEgmsWithMultiselectFilters(ids).subscribe((result) => {
      this.combo = result.data;
    });
  }

  public fillEgmsWithMinimalFilters(ids: MinimalFilters) {
    ids.includeDeleted = this.includeDeleted;
    this.EgmService.fillEgmsWithMinimalFilters(ids).subscribe((result) => {
      this.combo = result.data;
    });
  }

  public getEgmComboAsIdArray(): number[] {
    var arr: number[] = [];
    this.combo.forEach((element) => {
      arr.push(parseInt(element.id));
    });
    return arr;
  }

  public getCombo() {
    return this.combo;
  }
}
