import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GuidTextModel, IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { GameType } from 'src/app/common/components/viewmodels/GameType';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class GametypesService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getGameTypesCombo(): Observable<ApiResult<GuidTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}GameTypes/GetGameTypesCombo`).pipe(
      map((x) => {
        var result = this.checkResult<GuidTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<GuidTextModel[]>(error))
    );
  }

  public getGameTypesComboByModelId(idModel: string): Observable<ApiResult<GuidTextModel[]>> {
    return this.http
      .get<ApiResult<GuidTextModel[]>>(`${this.baseUrl}GameTypes/GetGameTypesComboByModelId?idModel=${idModel}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<GuidTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<GuidTextModel[]>(error))
      );
  }

  public getGameTypesComboByModelsId(idModels: Array<string>): Observable<ApiResult<GuidTextModel[]>> {
    return this.http.post<ApiResult<GuidTextModel[]>>(`${this.baseUrl}GameTypes/GetGameTypesComboByIds`, idModels).pipe(
      map((x) => {
        var result = this.checkResult<GuidTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<GuidTextModel[]>(error))
    );
  }

  public getGameTypes(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<GameType>>> {
    return this.http
      .post<ApiResult<DataTableResult<GameType>>>(`${this.baseUrl}GameTypes/GetGameTypes`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<GameType>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<GameType>>(error))
      );
  }

  public getGameType(id: string): Observable<ApiResult<GameType>> {
    return this.http.get<ApiResult<GameType>>(`${this.baseUrl}GameTypes/GetGameType?id=${id}`).pipe(
      map((x) => this.checkResult<GameType>(x)),
      catchError((error) => this.handleError<GameType>(error))
    );
  }
  public createOrEdit(gametype: GameType): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}GameTypes/CreateOrEdit`, gametype).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public deleteGameType(id: string): Observable<ApiResult<GameType>> {
    return this.http.delete<ApiResult<GameType>>(`${this.baseUrl}GameTypes/DeleteGameType?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<GameType>(x);
        return result;
      }),
      catchError((error) => this.handleError<GameType>(error))
    );
  }
}
