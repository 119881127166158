import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TicketState } from '../../viewmodels/Enums';
import { TicketMock } from '../../viewmodels/TicketMock';

@Component({
  selector: 'ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class TicketComponent implements OnInit {
  @Input() ticket: TicketMock;
  @Input() collapsed: boolean = false;

  constructor(public CasinoService: CasinoService, public currencyPipe: CurrencyPipe) {}
  moment = moment;
  stateEnum = TicketState;
  currencySym: string;
  currencyCode: string;
  regex: RegExp = new RegExp('_', 'g');

  ngOnInit(): void {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
  }
}
