import { Component, OnInit } from '@angular/core';
import { MocksService } from 'src/app/services/mocks/mocks.service';

@Component({
  selector: 'app-mock-buttons',
  templateUrl: './mock-buttons.component.html',
  styleUrls: ['./mock-buttons.component.css'],
})
export class MockButtonsComponent implements OnInit {
  public static getName() {
    return 'MockButtonsComponent';
  }
  constructor(private MocksService: MocksService) {}

  ngOnInit(): void {}

  deleteData() {
    this.MocksService.deleteData().subscribe((result) => {
      // this.notificationService.showSuccess(`Se borro el fabricante ${result.data.id}`, "Atención!");
    });
  }

  createData() {
    this.MocksService.createData().subscribe((result) => {
      // this.notificationService.showSuccess(`Se borro el fabricante ${result.data.id}`, "Atención!");
    });
  }
}
