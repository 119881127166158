import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from '../../../shared/common-table/common-table.component';
import { NotificationService } from '../../../shared/notification/notification.service';
import { ApiResult, DataTableResult } from '../../../viewmodels/apiResult';
import { DatatableMinimalFilters, DatatableMultiFilters } from '../../../viewmodels/DatatableFilters';
import { EGMMock } from '../../../viewmodels/EGMMock';
import { MultiselectFilters } from '../../../viewmodels/Filters';
import { CashFlowViewModel, SessionManagmentViewModel } from '../../../viewmodels/SessioModel';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { TicketConfigService } from 'src/app/services/ticket-config/ticket-config.service';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.css'],
})
export class SessionDetailsComponent implements OnInit {
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CashFlowViewModel>;
  @Input() session: SessionManagmentViewModel = null;
  title: string = 'Movimientos';
  public data: commonTableData<CashFlowViewModel>;
  currencyCode: string;
  currencySym: string;
  private eventsSubscription: Subscription;
  showResults: boolean = false;
  loaddata: boolean = false;
  currentTurn: number = 0;

  @Input() turnSelectedChange: Observable<number>;
  constructor(
    public sessionService: SessionService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    public printerService: PrinterService,
    private ticketConfigService: TicketConfigService
  ) {}

  ngOnInit(): void {
    this.eventsSubscription = this.turnSelectedChange.subscribe((session) => this.RefreshTurn(session));
    this.configDataTable();

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
  }

  RefreshTurn(idturn: number) {
    this.currentTurn = idturn;
    this.updateTable();
  }

  //si cambia el objeto sessión, lo guardo en el componete y refresco si debo mosotrar o no el componete
  refreshSession(session: SessionManagmentViewModel) {
    this.session = session;
  }

  setGridData(filters: MultiselectFilters) {
    let service = this.sessionService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CashFlowViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;

      DataTableFilters.dataTableParameters = param;
      return service.GetCashFlow(DataTableFilters);
    };
  }

  updateTable() {
    this.setGridData(this.getFilters());
    this.table.reload();
  }

  getFilters(): MultiselectFilters {
    var filters = new MultiselectFilters();
    filters.currentTurn = this.currentTurn;
    return filters;
  }

  configDataTable() {
    let service = this.sessionService;

    this.data = new commonTableData();
    let paramEGM = this.paramService.getAndDeleteParameters();

    if (paramEGM != null) {
      this.data.searchText = paramEGM.searchString;
      this.data.pageLength = paramEGM.pageLength;
      this.data.currentPage = paramEGM.currentPage;
    }
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.showAll = true;
    // this.data.loadFirstTime = false;

    const that = this;

    this.data.columns = [
      { data: 'userCreatedTicket', title: 'Atención' },
      {
        data: 'date',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },

      { data: 'description', title: 'Descripción' },

      { data: 'isPositive', title: 'Dirección' },
      {
        data: 'formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },

      { data: 'validatorCode', title: 'Ticket' },
      { data: 'cashRegisterSoruce', title: 'Caja Origen' },
      { data: 'cashRegisterTarget', title: 'Caja Destino' },
      { data: 'egmSource', title: 'EGM Origen' },
      { data: 'egmTarget', title: 'EGM Destino' },
      { data: 'egmNickName', title: 'EGM Nombre' },
      { data: 'observations', title: 'Observaciones' },
    ];
    this.data.rowCallback = function (row: Node, data: CashFlowViewModel | Object, index: number): void {
      if (data['isPositive'] == true) {
        that.convertToUp(row);
      } else {
        that.convertToDown(row);
      }
      that.addAlert(row, data['userCreatedTicket']);

      if (data['validatorCode'] != null) {
        $($('td', row)[5]).html(
          `<a target="_blank" href='${window.location.origin}/egms/tito/ticket-history/${data['validatorCode']}' style='text-decoration: underline;' data-toggle='tooltip' data-placement='right' title='' data-original-title='Ir a historial'>${data['validatorCode']}</a>`
        );

        //`<a target="_blank" href='https://${window.location.host}/egms/tito/ticket-history/${data['ticket'].validatorCode}' style='text-decoration: underline;' data-toggle='tooltip' data-placement='right' title='' data-original-title='Ir a historial'>${data['ticket'].validatorCode}</a>`

        //   $($('td', row)[this.getProfitIndex()]).css('color', '#dc3545'); //Danger

        // if (data['userCreatedTicket'] == true) {
        //   //   $($('td a', row)[5]).css('color', '#dc3545'); //Danger
        //   $($('td a', row)[5]).attr('style', 'color: #dc3545 !important'); //Danger
        //   $($('td a', row)[5]).css('font-weight', 'bold');
        // }
      }
    };
  }

  async testPrinter() {
    if (await this.printerService.isPrinterReady()) {
      var ticketQuantity = 1;

      var error = false;

      for (let index = 1; index <= ticketQuantity && !error; index++) {
        if (await this.printerService.isPrinterReady()) {
          var result = await this.ticketConfigService.GenerateTestTicket().toPromise();
          if (result?.data?.tickets.length > 0) {
            await this.printerService.print(result?.data?.tickets[0], index - 1);
          } else {
            error = true;
          }
        } else {
          error = true;
        }
      }
    }
  }

  addAlert(row: Node, userCreatedTicket: boolean) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (userCreatedTicket == true) {
        $($('td', row)[0]).html('<i class="fas fa-exclamation-triangle"></i>');
        //$($('td', row)[0]).css('color', 'rgb(225 225 64)'); //Danger
        $($('td', row)[0]).css('color', 'rgb(225 175 0)'); //Danger
        $($('td', row)[0]).css('font-weight', 'bold');
      } else {
        $($('td', row)[0]).html('');
      }
    });
  }

  arrowColumn: number = 3;

  convertToUp(row: Node) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[this.arrowColumn]).html('<i class="fas fa-arrow-up"></i>');
      $($('td', row)[this.arrowColumn]).css('color', '#008000'); //Danger
      $($('td', row)[this.arrowColumn]).css('font-weight', 'bold');
    });
  }
  convertToDown(row: Node) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[this.arrowColumn]).html('<i class="fas fa-arrow-down"></i>');
      $($('td', row)[this.arrowColumn]).css('color', '#dc3545'); //Danger
      $($('td', row)[this.arrowColumn]).css('font-weight', 'bold');
    });
  }

  onSearch(status: tableStatus) {}
}
