<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="card" [ngClass]="{ 'collapsed-card': this.model.isUpdating === false }">
          <div class="card-header">
            <div class="row">
              <div class="col-md-7" *ngIf="this.model.isUpdating">
                <h3 class="card-title">
                  Actualización en curso del archivo - {{ model.fileName }} -
                  {{ model.currentProgress }}
                </h3>
              </div>

              <div class="col-md-11" *ngIf="!this.model.isUpdating">
                <h3 class="card-title">
                  {{ getFinlizadedText() }} {{ getFinlizadedDate() }} -
                  {{ model.fileName }}
                </h3>
              </div>

              <div class="col-md-4" *ngIf="this.model.isUpdating">
                <div class="progress">
                  <div
                    class="progress-bar bg-warning"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [style.width]="model.currentProgress">
                    <span class="sr-only">{{ model.currentProgress }} Complete (warning)</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1 card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i id="toolBtn{{ model.id }}" class="fas fa-minus"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link" style="color: #0366d6">
                  {{ step1Text }} <span class="datestyle"> {{ getStepDate(model.formattedUpdateQueued) }}</span>
                  <span class="float-right">
                    <span *ngIf="model.formattedUpdateQueued == null && !model.isFinalizedError" class="pending"
                      >pending...</span
                    >
                    <i
                      *ngIf="model.formattedUpdateQueued == null && model.isFinalizedError"
                      class="fas fa-window-minimize"
                      style="padding-right: 30px"></i>
                    <i *ngIf="model.formattedUpdateQueued != null" class="fas fa-check" style="padding-right: 30px"></i>
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="color: #0366d6">
                  {{ step2Text }} <span class="datestyle"> {{ getStepDate(model.formattedUpdateUnqueued) }}</span>
                  <span class="float-right">
                    <span *ngIf="model.formattedUpdateUnqueued == null && !model.isFinalizedError" class="pending"
                      >pending...</span
                    >
                    <i
                      *ngIf="model.formattedUpdateUnqueued == null && model.isFinalizedError"
                      class="fas fa-window-minimize"
                      style="padding-right: 30px"></i>
                    <i
                      *ngIf="model.formattedUpdateUnqueued != null"
                      class="fas fa-check"
                      style="padding-right: 30px"></i>
                  </span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" style="color: #0366d6">
                  {{ step3Text }}<span class="datestyle"> {{ getStepDate(model.formattedUpdateRecivied) }}</span>
                  <span class="float-right">
                    <span *ngIf="model.formattedUpdateRecivied == null && !model.isFinalizedError" class="pending"
                      >pending...</span
                    >
                    <i
                      *ngIf="model.formattedUpdateRecivied == null && model.isFinalizedError"
                      class="fas fa-window-minimize"
                      style="padding-right: 30px"></i>
                    <i
                      *ngIf="model.formattedUpdateRecivied != null"
                      class="fas fa-check"
                      style="padding-right: 30px"></i>
                  </span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" style="color: #0366d6">
                  {{ step4Text }} <span class="datestyle"> {{ getStepDate(model.formattedUpdatePostedOk) }}</span>
                  <span class="float-right">
                    <span *ngIf="model.formattedUpdatePostedOk == null && !model.isFinalizedError" class="pending"
                      >pending...</span
                    >
                    <i
                      *ngIf="model.formattedUpdatePostedOk == null && model.isFinalizedError"
                      class="fas fa-window-minimize"
                      style="padding-right: 30px"></i>
                    <i
                      *ngIf="model.formattedUpdatePostedOk != null"
                      class="fas fa-check"
                      style="padding-right: 30px"></i>
                  </span>
                </a>
              </li>

              <li class="nav-item" *ngIf="this.model.isUpdating">
                <a class="nav-link" style="color: #0366d6">
                  {{ step5OkText }}
                  <span class="float-right"><span class="pending">pending...</span></span>
                </a>
              </li>
              <li class="nav-item" *ngIf="!this.model.isUpdating">
                <a class="nav-link" style="color: #0366d6">
                  {{ getFinlizadedText() }} <span class="datestyle"> {{ getFinlizadedDate() }}</span>
                  <span class="float-right"
                    ><i *ngIf="model.isFinalizedOk" class="fas fa-check" style="padding-right: 30px"></i>
                    <i *ngIf="model.isFinalizedError" class="fas fa-times" style="padding-right: 30px"></i
                  ></span>
                </a>
              </li>
            </ul>
          </div>

          <div class="card-footer">
            <!-- <div class="d-flex justify-content-end"> -->
            <div class="row">
              <div class="col-md-8">
                {{ model.postError }}
              </div>
              <div class="col-md-4">
                <div class="d-flex justify-content-end">
                  <button
                    type="submit"
                    [disabled]="!this.model.canCancel"
                    class="btn btn-primary"
                    (click)="cancelUpdate()">
                    Cancelar actualización
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->

  <app-confirm-modal [label]="'statusModal'" [yes]="'Continuar'" (onConfirm)="confirmYesModal()" [body]="body">
  </app-confirm-modal>
</div>
