import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { OldHomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';

import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { MainSidebarComponent } from './main/main-sidebar/main-sidebar.component';
import { ControlSidebarComponent } from './main/control-sidebar/control-sidebar.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing';
import { EditEgmComponent } from './pages/edit-egm/edit-egm.component';
import { SharedModule } from './common/components/shared/shared.module';
import { ListEgmsComponent } from './pages/list-egms/list-egms.component';
import { CasinoDataComponent } from './pages/casino-data/casino-data.component';
import { EditRoomComponent } from './pages/edit-room/edit-room.component';
import { EditSpiderComponent } from './pages/edit-spider/edit-spider.component';
import { ListSpidersComponent } from './pages/list-spiders/list-spiders.component';
import { EditModelComponent } from './pages/edit-model/edit-model.component';
import { ListManufacturersComponent } from './pages/list-manufacturers/list-manufacturers.component';
import { ListModelsComponent } from './pages/list-models/list-models.component';
import { ListRoomsComponent } from './pages/list-rooms/list-rooms.component';
import { EditManufacturerComponent } from './pages/edit-manufacturer/edit-manufacturer.component';
import { EditCabinetComponent } from './pages/edit-cabinet/edit-cabinet.component';
import { ListCabinetsComponent } from './pages/list-cabinets/list-cabinets.component';
import { CashableTicketComponent } from './pages/cashable-ticket/cashable-ticket.component';
import { PromotionalTicketComponent } from './pages/promotional-ticket/promotional-ticket.component';
import { ListEgmParametersComponent } from './pages/list-egm-parameters/list-egm-parameters.component';
import { EditEgmParametersComponent } from './pages/edit-egm-parameters/edit-egm-parameters.component';
import { CountersInfoComponent } from './pages/counters-info/counters-info.component';
import { DetailedCountersInfoComponent } from './pages/detailed-counters-info/detailed-counters-info.component';
import { TicketConfigComponent } from './pages/ticket-config/ticket-config.component';
import { EditCountersComponent } from './pages/edit-counters/edit-counters.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SystemEventsComponent } from './pages/system-events/system-events.component';
import { ListGametypesComponent } from './pages/list-gametypes/list-gametypes.component';
import { EditGametypeComponent } from './pages/edit-gametype/edit-gametype.component';
import { TicketInfoComponent } from './pages/ticket-info/ticket-info.component';
import { TicketHistoryComponent } from './pages/ticket-history/ticket-history.component';
import { TicketSupervisorComponent } from './pages/ticket-supervisor/ticket-supervisor.component';
import { CountersPeriodComponent } from './pages/counters-period/counters-period.component';
import { DetailedCountersReportComponent } from './pages/detailed-counters-report/detailed-counters-report.component';
import { ListCashRegistersComponent } from './pages/list-cash-registers/list-cash-registers.component';
import { EditCashRegistersComponent } from './pages/edit-cash-registers/edit-cash-registers.component';
import { DailyAnalysisComponent } from './pages/daily-analysis/daily-analysis.component';
import { EnableTicketComponent } from './pages/enable-ticket/enable-ticket.component';
import { CancelTicketComponent } from './pages/cancel-ticket/cancel-ticket.component';
import { TicketComponent } from './common/components/business/ticket/ticket.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CashoutComponent } from './pages/cashout/cashout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { EgmProfitComponent } from './common/components/business/egm-profit/egm-profit.component';
import { ReportCurrentProfitComponent } from './pages/report-current-profit/report-current-profit.component';
import { PeriodProfitComponent } from './pages/period-profit/period-profit.component';
import { AuthorizedTicketsComponent } from './pages/authorized-tickets/authorized-tickets.component';
import { GeneralTicketsComponent } from './pages/general-tickets/general-tickets.component';
import { UnpaidTicketsComponent } from './pages/unpaid-tickets/unpaid-tickets.component';
import { DetailedUnpaidTicketsComponent } from './pages/detailed-unpaid-tickets/detailed-unpaid-tickets.component';
import { CashedTicketsComponent } from './pages/cashed-tickets/cashed-tickets.component';
import { ExpiredTicketsComponent } from './pages/expired-tickets/expired-tickets.component';

import { TicketsAverageComponent } from './pages/tickets-average/tickets-average.component';
import { PublicAccountingPowerComponent } from './pages/public-accounting-power/public-accounting-power.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ValidationCodeComponent } from './common/components/business/validation-code/validation-code.component';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { VisualizeDashboardComponent } from './pages/visualize-dashboard/visualize-dashboard.component';
import { EditDashboardComponent } from './pages/edit-dashboard/edit-dashboard.component';
import { ListLocationsComponent } from './pages/list-locations/list-locations.component';
import { EditLocationComponent } from './pages/edit-location/edit-location.component';
import { EgmReportComponent } from './pages/egm-report/egm-report.component';

import { SpiderReportComponent } from './pages/spider-report/spider-report.component';
import { CombinationProfitComponent } from './pages/combination-profit/combination-profit.component';
import { GlossaryComponent } from './pages/glossary/glossary.component';
import { TicketGenerationComponent } from './pages/ticket-generation/ticket-generation.component';
import { MockButtonsComponent } from './pages/mock-buttons/mock-buttons.component';
import { EgmComboComponent } from './common/business/egm-combo/egm-combo.component';
import { NgxMaskModule } from 'ngx-mask';

import { RoomComboComponent } from './common/components/business/room-combo/room-combo.component';
import { ManufacturersComboComponent } from './common/components/business/manufacturers-combo/manufacturers-combo.component';
import { ModelsComboComponent } from './common/components/business/models-combo/models-combo.component';
import { GametypesComboComponent } from './common/components/business/gametypes-combo/gametypes-combo.component';
import { ServicesComponent } from './pages/services/services.component';
import { BillcodeComboComponent } from './common/components/business/billcode-combo/billcode-combo.component';
import { CashRegisterComboComponent } from './common/components/business/cash-register-combo/cash-register-combo.component';
import { UsersComboComponent } from './common/components/business/users-combo/users-combo.component';
import { TicketFamilyGroupsComboComponent } from './common/components/business/ticket-family-groups-combo/ticket-family-groups-combo.component';
import { ReceiptModalComponent } from './common/components/business/receipt-modal/receipt-modal.component';
import { RealTimeProfitComponent } from './pages/real-time-profit/real-time-profit.component';
import { SessionsComboComponent } from './common/components/business/sessions-combo/sessions-combo.component';
import { GenerationReasonComboComponent } from './common/components/business/generation-reason-combo/generation-reason-combo.component';
import { HandpayVoucherComponent } from './pages/handpay-voucher/handpay-voucher.component';
import { MoneyDropsComponent } from './pages/money-drops/money-drops.component';
import { BillsQuantityComponent } from './pages/bills-quantity/bills-quantity.component';
import { SupervisorSessionsComponent } from './pages/supervisor-sessions/supervisor-sessions.component';
import { GeneralEditEgmComponent } from './common/components/business/general-edit-egm/general-edit-egm.component';
import { CurrentProfitGraphicComponent } from './pages/current-profit-graphic/current-profit-graphic.component';
import { LineChartComponent } from './common/components/shared/line-chart/line-chart.component';
import { ChartsModule } from 'ng2-charts';
import { BarChartComponent } from './common/components/shared/bar-chart/bar-chart.component';
import { CoinInReportComponent } from './pages/coin-in-report/coin-in-report.component';
import { CoinInAverageReportComponent } from './pages/coin-in-average-report/coin-in-average-report.component';
import { ProfitAverageReportComponent } from './pages/profit-average-report/profit-average-report.component';
import { DailyProfitReportComponent } from './pages/daily-profit-report/daily-profit-report.component';
import { HourlyProfitComponent } from './pages/hourly-profit/hourly-profit.component';
import { HourlyCoinInComponent } from './pages/hourly-coin-in/hourly-coin-in.component';
import { AccessLogComponent } from './pages/access-log/access-log.component';
import { UsersLogComponent } from './pages/users-log/users-log.component';
import { ClosedDaysLogComponent } from './pages/closed-days-log/closed-days-log.component';
import { CountersEditLogComponent } from './pages/counters-edit-log/counters-edit-log.component';
import { ParametersEditLogComponent } from './pages/parameters-edit-log/parameters-edit-log.component';
import { TicketCountersReportComponent } from './pages/ticket-counters-report/ticket-counters-report.component';
import { NgxPrintModule } from 'ngx-print';
import { ListUserComponent } from './pages/list-user/list-user.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';
import { PrinterConfigComponent } from './pages/printer-config/printer-config.component';
import { TicketPrinterStatusComponent } from './common/components/business/ticket-printer-status/ticket-printer-status.component';
import { VoucherModalComponent } from './common/components/business/voucher-modal/voucher-modal.component';
import { TenantInterceptor } from './services/tenant/tenant.interceptor';

import { ListTenantComponent } from './pages/list-tenant/list-tenant.component';
import { EditTenantComponent } from './pages/edit-tenant/edit-tenant.component';
import { EditCasinodataComponent } from './pages/edit-casinodata/edit-casinodata.component';
import { LocationsReportComponent } from './pages/locations-report/locations-report.component';
import { CashRegisterReportComponent } from './pages/cashRegister-report/cashRegister-report.component';
import { RoomsReportComponent } from './pages/rooms-report/rooms-report.component';
import { ListCashMachineComponent } from './pages/list-cash-machine/list-cash-machine.component';
import { EditCashMachineComponent } from './pages/edit-cash-machine/edit-cash-machine.component';
import { HandpayVoucherListComponent } from './common/components/handpay-voucher-list/handpay-voucher-list.component';
import { HandpayVoucherReportComponent } from './pages/handpay-voucher-report/handpay-voucher-report.component';
import { AcceptedTicketsComponent } from './pages/accepted-tickets/accepted-tickets.component';
import { IssuedTicketsComponent } from './pages/issued-tickets/issued-tickets.component';
import { CMComboComponent } from './common/business/cm-combo/cm-combo.component';
import { AccountingReportComponent } from './pages/accounting-report/accounting-report.component';
import { EditFirmwareComponent } from './pages/edit-firmware/edit-firmware.component';
import { StateFirmwareComponent } from './common/components/business/state-firmware/state-firmware.component';
import { CommonDaterangepickerMaterialComponent } from './common/components/shared/common-daterangepicker-material/common-daterangepicker-material.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SessionManagementControlComponent } from './common/components/business/pos/session-management-control/session-management-control.component';
import { SessionSettlementControlComponent } from './common/components/business/pos/session-settlement-control/session-settlement-control.component';
import { SessionDetailsComponent } from './common/components/business/pos/session-details/session-details.component';
import { SessionCashFlowComponent } from './pages/session-cash-flow/session-cash-flow.component';
import { SessionManagementComponent } from './pages/session-management/session-management.component';
import { SessionCashoutComponent } from './common/components/business/pos/session-cashout/session-cashout.component';
import { SessionSettlementComponent } from './pages/session-settlement/session-settlement.component';
import { SessionMovementControlComponent } from './common/components/business/pos/session-movement-control/session-movement-control.component';
import { SessionMovementComponent } from './pages/session-movement/session-movement.component';
import { SessionEgmActionComponent } from './pages/session-egm-action/session-egm-action.component';
import { SessionCollectionComponent } from './pages/session-collection/session-collection.component';
import { SessionReconciliationComponent } from './pages/session-reconciliation/session-reconciliation.component';
import { TurnComboComponent } from './common/business/turn-combo/turn-combo.component';
import { MovimentTypeComboComponent } from './common/business/moviment-type-combo/moviment-type-combo.component';
import { SessionMovementsReportComponent } from './pages/session-movements-report/session-movements-report.component';
import { CurrencyTypeComboComponent } from './common/business/currency-type-combo/currency-type-combo.component';
import { SessionCollectionReportComponent } from './pages/session-collection-report/session-collection-report.component';
import { SessionMovementCashflowComponent } from './pages/session-movement-cashflow/session-movement-cashflow.component';
import { SessionCashCountReportComponent } from './pages/session-cash-count-report/session-cash-count-report.component';
import { SessionStockegmOutComponent } from './pages/session-stockegm-in-out/session-stockegm-out.component';
import { SessionStockegmInComponent } from './pages/session-stockegm-in/session-stockegm-in.component';
import { DetailedCountersinstantComponent } from './pages/detailed-counters-instant/detailed-counters-instant.component';
import { EditCasinoDataParametersComponent } from './pages/edit-casino-parameters/edit-casino-parameters.component';
import { ListAuthorizationsComponent } from './pages/list-authorizations/list-authorizations.component';
import { TurnDetailsComponent } from './pages/turn-details/turn-details.component';

@NgModule({
  declarations: [
    AppComponent,
    OldHomeComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    MainSidebarComponent,
    ControlSidebarComponent,
    HomeComponent,
    PageNotFoundComponent,
    EditEgmComponent,
    ListEgmsComponent,
    CasinoDataComponent,
    EditRoomComponent,
    ListRoomsComponent,
    EditModelComponent,
    ListModelsComponent,
    ListManufacturersComponent,
    EditManufacturerComponent,
    EditSpiderComponent,
    ListSpidersComponent,
    ListCashMachineComponent,
    EditCashMachineComponent,
    EditCabinetComponent,
    ListCabinetsComponent,
    CashableTicketComponent,
    PromotionalTicketComponent,
    ListEgmParametersComponent,
    EditEgmParametersComponent,
    CountersInfoComponent,
    DetailedCountersInfoComponent,
    DetailedCountersinstantComponent,
    TicketConfigComponent,
    EditCountersComponent,
    SystemEventsComponent,
    ListGametypesComponent,
    EditGametypeComponent,
    TicketInfoComponent,
    TicketHistoryComponent,
    TicketSupervisorComponent,
    CountersPeriodComponent,
    DetailedCountersReportComponent,
    ListCashRegistersComponent,
    EditCashRegistersComponent,
    DailyAnalysisComponent,
    EnableTicketComponent,
    CancelTicketComponent,
    CashoutComponent,
    TicketComponent,

    DashboardComponent,
    EgmProfitComponent,
    ReportCurrentProfitComponent,
    PeriodProfitComponent,
    AuthorizedTicketsComponent,
    GeneralTicketsComponent,
    UnpaidTicketsComponent,
    DetailedUnpaidTicketsComponent,
    CashedTicketsComponent,
    ExpiredTicketsComponent,

    TicketsAverageComponent,
    PublicAccountingPowerComponent,
    ValidationCodeComponent,

    VisualizeDashboardComponent,
    EditDashboardComponent,
    ListLocationsComponent,
    EditLocationComponent,
    EgmReportComponent,
    LocationsReportComponent,
    CashRegisterReportComponent,
    RoomsReportComponent,
    SpiderReportComponent,
    CombinationProfitComponent,
    GlossaryComponent,
    TicketGenerationComponent,
    MockButtonsComponent,
    EgmComboComponent,
    CMComboComponent,
    RoomComboComponent,
    ManufacturersComboComponent,
    ModelsComboComponent,
    GametypesComboComponent,
    ServicesComponent,
    BillcodeComboComponent,
    CashRegisterComboComponent,
    UsersComboComponent,
    TicketFamilyGroupsComboComponent,
    ReceiptModalComponent,
    RealTimeProfitComponent,
    SessionsComboComponent,
    GenerationReasonComboComponent,
    HandpayVoucherComponent,
    HandpayVoucherReportComponent,
    MoneyDropsComponent,
    BillsQuantityComponent,
    SupervisorSessionsComponent,
    GeneralEditEgmComponent,
    CurrentProfitGraphicComponent,
    LineChartComponent,
    BarChartComponent,
    CoinInReportComponent,
    CoinInAverageReportComponent,
    ProfitAverageReportComponent,
    DailyProfitReportComponent,
    HourlyProfitComponent,
    HourlyCoinInComponent,
    AccessLogComponent,
    UsersLogComponent,
    ClosedDaysLogComponent,
    CountersEditLogComponent,
    ParametersEditLogComponent,
    TicketCountersReportComponent,
    ListUserComponent,
    EditUserComponent,
    PrinterConfigComponent,
    TicketPrinterStatusComponent,
    VoucherModalComponent,
    ListTenantComponent,
    EditTenantComponent,
    EditCasinodataComponent,
    HandpayVoucherListComponent,
    AcceptedTicketsComponent,
    IssuedTicketsComponent,
    AccountingReportComponent,
    EditFirmwareComponent,
    StateFirmwareComponent,
    CommonDaterangepickerMaterialComponent,
    SessionManagementComponent,
    SessionManagementControlComponent,
    SessionSettlementControlComponent,
    SessionDetailsComponent,
    SessionCashFlowComponent,
    SessionManagementComponent,
    SessionCashoutComponent,
    SessionSettlementComponent,
    SessionMovementControlComponent,
    SessionMovementComponent,
    SessionEgmActionComponent,
    SessionCollectionComponent,
    SessionStockegmOutComponent,
    SessionStockegmInComponent,
    SessionReconciliationComponent,
    TurnComboComponent,
    MovimentTypeComboComponent,
    SessionMovementsReportComponent,
    CurrencyTypeComboComponent,
    SessionCollectionReportComponent,
    SessionMovementCashflowComponent,
    SessionCashCountReportComponent,

    EditCasinoDataParametersComponent,
    ListAuthorizationsComponent,
    TurnDetailsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ApiAuthorizationModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    BrowserAnimationsModule,
    LoadingBarRouterModule,
    NgxSpinnerModule,
    DragDropModule,
    ChartsModule,
    NgxPrintModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    CurrencyPipe,
    PercentPipe,
  ],
  bootstrap: [AppComponent],
})
@Injectable()
export class AppModule {}
