import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GametypesService } from 'src/app/services/gametypes/gametypes.service';
import { GuidTextModel, IdTextModel } from '../../shared/IdTextModel';

@Component({
  selector: 'gametypes-combo',
  templateUrl: './gametypes-combo.component.html',
  styleUrls: ['./gametypes-combo.component.css'],
})
export class GametypesComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  combo: Array<GuidTextModel> = [];
  label: string = 'Juego';
  @Output() valueChanged = new EventEmitter();

  constructor(private GametypesService: GametypesService) {}

  ngOnInit(): void {
    this.fillCombo();
    if (this.multiselect) this.label = 'Juegos';
  }

  public fillCombo() {
    this.GametypesService.getGameTypesCombo().subscribe((result) => {
      this.combo = result.data;
    });
  }

  public async fillComboWithModelId(id: string) {
    var result = await this.GametypesService.getGameTypesComboByModelId(id).toPromise();
    this.combo = result.data;
  }

  public async fillComboWithModelsId(ids: Array<string>) {
    // this.GametypesService.getGameTypesComboByModelsId(ids).subscribe((result) => {
    //   this.combo = result.data;
    // })
    var result = await this.GametypesService.getGameTypesComboByModelsId(ids).toPromise();
    this.combo = result.data;
  }

  public getCombo() {
    return this.combo;
  }

  public getGameComboAsIdArray(): string[] {
    var arr: string[] = [];
    this.combo.forEach((element) => {
      arr.push(element.id);
    });
    return arr;
  }
}
