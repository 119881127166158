import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IdTextModel } from '../IdTextModel';

// https://medium.com/@lukaonik/how-to-write-custom-form-controls-in-angular-1285ed00bba0
// https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html
// https://medium.com/@bohndez.dev/custom-form-control-con-control-value-accessor-en-angular-5-6-o-7-f8f4030f105d

@Component({
  selector: 'common-input-multiselect',
  templateUrl: './common-input-multiselect.component.html',
  styleUrls: ['./common-input-multiselect.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonInputMultiselectComponent),
      multi: true,
    },
  ],
})
export class CommonInputMultiselectComponent implements OnInit, ControlValueAccessor {
  _list: Array<IdTextModel> = [];
  get list(): Array<IdTextModel> {
    return this._list;
  }
  @Input() set list(value: Array<IdTextModel>) {
    this._list = value;
    if (this.mySelect) {
      this.mySelect.items = value;
    }
  }

  @Input() label: string;
  @Input() allowClear: boolean = false;
  @Input() formSubmitted: boolean = false;

  @Output() valueChanged = new EventEmitter();

  @ViewChild('mySelect', { static: false }) mySelect: NgSelectComponent;
  @ViewChild('mySelect', { static: false, read: NgControl }) mySelectControl: NgControl;

  public disabled: boolean;
  value?: string[];
  ngControl: NgControl;
  placeHolder: string = '';
  constructor(public injector: Injector, public cd: ChangeDetectorRef) {}

  onChange = (_: any) => {};
  onTouched = (_: any) => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.mySelect.setDisabledState(isDisabled);
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);

    if (this.label == '' || this.label == null) {
      this.placeHolder = 'Seleccione';
    } else {
      this.placeHolder = 'Seleccione ' + this.label.toLowerCase();
    }
  }

  ShowLabelUp() {
    if (this.label == '' || this.label == null) {
      return false;
    }
    return true;
  }

  ngDoCheck() {
    if (this.ngControl.touched) {
      if (!this.mySelectControl?.touched) this.mySelectControl?.control.markAllAsTouched();
    } else {
      if (this.mySelectControl?.touched) {
        this.mySelectControl?.control.markAsUntouched();
      }
    }
  }

  ngAfterViewInit() {
    if (this.mySelect) {
      this.mySelect.items = this._list;
    }

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      if (this.mySelectControl) {
        this.mySelectControl.control.setValidators(this.ngControl.control.validator);
      }
    }
  }

  public updateOptions(options: Array<IdTextModel>) {
    this.list = options;
    this.cd.detectChanges();
  }

  public updateOptionsWithMap(optionsMap: Map<number, string>) {
    var temp: Array<IdTextModel> = [];
    for (let [key, value] of optionsMap) {
      temp.push(new IdTextModel(key, value));
    }

    this._list = temp;
    this.cd.detectChanges();
  }

  emitChange(selection: IdTextModel[]) {
    this.value = [];

    selection.forEach((x) => this.value.push(x.id));
    this.onChange(this.value);
    this.valueChanged.emit(this.value);
  }
}
