<ng-template #receiptModal let-modal class="modal" *ngIf="isCashout">
  <div class="modal-header">
    <!-- <div>
            <div style="font-size: 40px;font-style: bold;text-align: center;">Voucher</div>
            <div style="font-size: 20px;text-align: center;">{{receipt.casino.companyName}} -
                {{receipt.casino.facilityName}} - {{receipt.casino.cuit}}</div>
            <div style="font-size: 14px;font-style: italic;text-align: center;">{{receipt.casino.address}} </div>
    
        </div>
        <hr> -->

    <h3 class="modal-title">{{ receipt.casino.companyName }}</h3>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <div class="col-4">Identificador fiscal:</div>
      <div class="col">
        {{ receipt.casino.cuit }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Divisa:</div>
      <div class="col">
        {{ currencyCode }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Caja Nº:</div>
      <div class="col">
        {{ receipt.cuid }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Usuario:</div>
      <div class="col">
        {{ receipt.user }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Fecha y hora:</div>
      <div class="col">
        {{ receipt.date }}
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col">
        <h5>Detalle de tickets:</h5>
      </div>
    </div>
    <div class="row px-3">
      <table class="table table-hover table-responsive w-100">
        <thead class="text-center">
          <tr>
            <th class="col-8">Código</th>
            <th class="col">Importe</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ticket of receipt.tickets" class="text-center">
            <td>{{ ticket.validatorCode }}</td>
            <td>{{ currencyPipe.transform(ticket.formattedAmount, currencyCode, currencySym) }}</td>
          </tr>
          <tr class="text-bold bg-warning">
            <td>Total a pagar:</td>
            <td>{{ currencyPipe.transform(total, currencyCode, currencySym) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <app-confirm-buttons (onCancel)="cancel()" yes="Continuar" (onConfirm)="confirm()"> </app-confirm-buttons>
    </div>
  </div>
</ng-template>

<ng-template #receiptModal let-modal class="modal" *ngIf="!isCashout">
  <div class="modal-header">
    <h3 class="modal-title">{{ receipt.casino.companyName }}</h3>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <div class="col-4">Identificador fiscal:</div>
      <div class="col">
        {{ receipt.casino.cuit }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Divisa:</div>
      <div class="col">
        {{ currencyCode }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Caja Nº:</div>
      <div class="col">
        {{ receipt.cuid }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Usuario:</div>
      <div class="col">
        {{ ticket.userIdName }}
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">Fecha y hora:</div>
      <div class="col">
        {{ receipt.date }}
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col">
        <h5>Detalle de ticket de reemplazo:</h5>
      </div>
    </div>
    <div class="row px-3">
      <table class="table table-hover table-responsive w-100">
        <thead class="text-center">
          <tr>
            <th class="col-8">Código</th>
            <th class="col">Importe</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ticket of receipt.tickets" class="text-center">
            <td>{{ ticket.validatorCode }}</td>
            <td>{{ currencyPipe.transform(ticket.formattedAmount, currencyCode, currencySym) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-primary ml-2" (click)="confirm()">Confirmar</button>
    </div>
  </div>
</ng-template>
