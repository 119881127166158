<div class="row d-flex flex-grow-1">
  <div class="col-12 d-flex flex-content-stretch h-100">
    <div
      class="card dashboard-height w-100"
      [class.bg-dark]="!isEdit"
      [class.bg-white]="isEdit"
      style="margin-bottom: 0.1rem">
      <div class="map-frame">
        <div id="map"></div>
      </div>
      <form role="form" [formGroup]="roomForm">
        <div class="card-footer d-flex pb-0 pt-0" *ngIf="!isEdit">
          <div
            class="card-text w-100 d-flex align-items-center"
            [class.justify-content-between]="dashboard !== null"
            [class.justify-content-end]="dashboard === null">
            <div class="d-flex" *ngIf="dashboard !== null">
              <span class="mr-3">
                <h2 class="card-title font-weight-bold">
                  {{ dashboard?.room?.name }} {{ dashboard?.lastUpdate.format('hh:mm:ss') }}
                </h2>
              </span>
              <span class="mr-2">
                <i [class]="'fas fa-square mr-1 text-' + eventsClasses[stateEgm.Libre]"></i>
                {{ stateEgm[stateEgm.Libre] }} ({{ dashboard?.freeEgms }})
              </span>
              <span class="mr-2">
                <i [class]="'fas fa-square mr-1 text-' + eventsClasses[stateEgm.Ocupado]"></i>
                {{ stateEgm[stateEgm.Ocupado] }} ({{ dashboard?.occupiedEgms }})
              </span>
              <span class="mr-2">
                <i [class]="'fas fa-square mr-1 text-' + eventsClasses[stateEgm.Alarma]"></i>
                {{ stateEgm[stateEgm.Alarma] }} ({{ dashboard?.alarmEgms }})
              </span>
              <span class="mr-2">
                <i [class]="'fas fa-square mr-1 text-' + eventsClasses[stateEgm.Asistente]"></i>
                {{ stateEgm[stateEgm.Asistente] }} ({{ dashboard?.paymentEgms }})
              </span>
              <span class="mr-2">
                <i [class]="'fas fa-square mr-1 text-' + eventsClasses[stateEgm.Fuera_de_servicio]"></i>
                {{ stateEgm[stateEgm.Fuera_de_servicio].replace(regex, ' ') }} ({{ dashboard?.oosEgms }})
              </span>
              <span class="mr-2">
                <i [class]="'fas fa-square mr-1 text-' + eventsClasses[stateEgm.Desconectado]"></i>
                {{ stateEgm[stateEgm.Desconectado] }} ({{ dashboard?.disconnectedEgms }})
              </span>
              <span class="mr-2"> Total: {{ dashboard?.totalEgms }} </span>
            </div>
            <div class="d-flex">
              <span class="justify-content-end p-2">
                <room-combo
                  class="dashboard room-combo"
                  [control]="roomForm.get('room')"
                  (valueChanged)="selectRoom()"
                  [selectFirst]="true"
                  [hideLabel]="true"
                  (loadFinished)="roomLoadFinish()">
                </room-combo>
              </span>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer d-flex align-items-center" style="height: 132px" *ngIf="isEdit">
        <div class="d-flex justify-content-start">
          <span class=""> <b>Sala:</b> {{ dashboard?.roomName }} </span>
        </div>
        <div class="d-flex justify-content-end w-100">
          <button
            type="button"
            class="btn btn-primary btn-sm mr-3"
            [disabled]="processing"
            (click)="openConfigModal()"
            [class.disabled]="processing"
            *ngIf="isEdit">
            <i class="fas fa-cog"></i> Configurar Fondo
          </button>

          <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="fileInput.click()"
            [disabled]="processing"
            [class.disabled]="processing"
            *ngIf="!dashboard?.hasBackground">
            <i class="fas fa-plus"></i> Agregar Fondo
            <input
              type="file"
              class="custom-file-input"
              name="fileName"
              accept="image/png, image/jpeg"
              #fileInput
              (change)="addBackground($event.target.files)"
              style="display: none" />
          </button>

          <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="this.confirmModalBg.openModal()"
            [disabled]="processing"
            *ngIf="dashboard?.hasBackground">
            <i class="fas fa-minus"></i> Quitar Fondo
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm ml-5"
            [disabled]="
              !itemToDelete?.isSelected ||
              dashboard?.dashboardItems === null ||
              dashboard?.dashboardItems?.length === 0 ||
              processing
            "
            (click)="openConfirmModal()">
            <i class="fas fa-minus"></i> Eliminar elemento
          </button>
          <button type="button" class="btn btn-primary btn-sm ml-2" (click)="openEgmModal()" [disabled]="processing">
            <i class="fas fa-plus"></i> Agregar EGM
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm ml-2"
            (click)="openEgmModalCashMachine()"
            [disabled]="processing">
            <i class="fas fa-plus"></i> Agregar Máquina de Cambio / ATM
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #egmModal let-modal class="modal">
  <div class="modal-header">
    <h3 class="modal-title">Agregar EGM</h3>
  </div>
  <form role="form" [formGroup]="formEgm" (ngSubmit)="addItemEgm(formObj)" #formObj="ngForm">
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <egm-combo [control]="formEgm.get('egm')" [roomId]="roomId"></egm-combo>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end">
        <button type="submit" [disabled]="this.formEgm.get('egm').value === null" class="btn btn-primary">
          Continuar
        </button>
        <button type="button" class="btn btn-secondary ml-2" (click)="dismissModal()">Cancelar</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #cashMachineModal let-modal class="modal">
  <div class="modal-header">
    <h3 class="modal-title">Agregar CashMachine</h3>
  </div>
  <form role="form" [formGroup]="formCashMachine" (ngSubmit)="addItemCashMachine(formObj)" #formObj="ngForm">
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <egm-combo
            [control]="formCashMachine.get('cashMachine')"
            [roomId]="roomId"
            [isGetCashMachine]="true"></egm-combo>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end">
        <button
          type="submit"
          [disabled]="this.formCashMachine.get('cashMachine').value === null"
          class="btn btn-primary">
          Continuar
        </button>
        <button type="button" class="btn btn-secondary ml-2" (click)="dismissModal()">Cancelar</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #configMapModal let-modal class="modal">
  <div class="modal-header">
    <h3 class="modal-title">Configurar fondo</h3>
  </div>
  <form role="form" [formGroup]="markerSizeForm" (ngSubmit)="saveMapConfig(formObj)" #formObj="ngForm">
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <common-input-select
            class="form-control-sm"
            [class.dashboard]="dashboard"
            [disabled]="processing"
            [class.disabled]="processing"
            [label]="'Tamaño Egms'"
            [list]="markerSizeList"
            [formControl]="markerSizeForm.get('markerSize')"
            [setValue]="true">
          </common-input-select>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <app-common-input
            [control]="markerSizeForm.get('minZoom')"
            [label]="'Zoom Mínimo'"
            [type]="InputType.numeric"
            [class.disabled]="processing">
          </app-common-input>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <app-common-input
            [control]="markerSizeForm.get('maxZoom')"
            [label]="'Zoom Máximo'"
            [type]="InputType.numeric"
            [class.disabled]="processing">
          </app-common-input>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <app-common-input
            [control]="markerSizeForm.get('deltaZoom')"
            [label]="'Granularidad Zoom'"
            [type]="InputType.numeric"
            [class.disabled]="processing">
          </app-common-input>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end">
        <button type="submit" [disabled]="this.markerSizeForm.invalid" class="btn btn-primary">Salvar</button>
        <button type="button" class="btn btn-secondary ml-2" (click)="dismissModal()">Cancelar</button>
      </div>
    </div>
  </form>
</ng-template>

<app-confirm-modal #confirmModal (onConfirm)="deleteItem()" [body]="confirmModalBody"></app-confirm-modal>
<app-confirm-modal #confirmModalBg (onConfirm)="removeBackground()" [body]="'¿Desea quitar la imagen de fondo?'">
</app-confirm-modal>
