import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-buttons',
  templateUrl: './confirm-buttons.component.html',
})
export class ConfirmButtonsComponent implements OnInit {
  @Input() yes: string = 'Guardar';
  @Input() no: string = 'Cancelar';

  yesButton: boolean = false;
  noButton: boolean = false;

  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public disableYes() {
    this.yesButton = !this.yesButton;
  }
  public disableNo() {
    this.noButton = !this.noButton;
  }
}
