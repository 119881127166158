import { Moment } from 'moment';

export class Spider {
  id: number;
  code: string;
  model: string;

  checkSum: string;
  observations: string;
  extendedVersion: string;
  ipSuggested: string;
  ip: string; //required
  firmware: string; //required
  mac: string; //required
  comments: string; //Max 255
  manufacturingDate: moment.Moment;
  roomId: number;
  eliminationDate?: moment.Moment;
  public constructor(init?: Partial<Spider>) {
    Object.assign(this, init);
  }
}
