import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonInputComponent,
  InputTypeEnum,
} from 'src/app/common/components/shared/common-input/common-input.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { CashableType, TicketFamily, TicketFamilyGroup, TicketState } from 'src/app/common/components/viewmodels/Enums';
import { SessionManagmentViewModel } from 'src/app/common/components/viewmodels/SessioModel';

import { TicketsSaleViewModel } from 'src/app/common/components/viewmodels/TicketsSaleViewModel';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { SessionOldReportService } from 'src/app/services/session-report/session-report.service';
import { TicketConfigService } from 'src/app/services/ticket-config/ticket-config.service';
import { TicketValuesService } from 'src/app/services/ticket-values/ticket-values.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-cashable-ticket',
  templateUrl: './cashable-ticket.component.html',
  styleUrls: ['./cashable-ticket.component.css'],
})
export class CashableTicketComponent implements OnInit {
  @ViewChild('ticketValue', { static: false }) TicketValue: CommonInputComponent;

  formEdit: FormGroup;
  maximumTicketValue: number;
  InputType = InputTypeEnum;
  ticket: TicketsSaleViewModel;
  items: Array<BreadcrumbItem> = [];
  hideCashable: boolean = false;
  sessionStatus: boolean = false;
  currentCashRegister: CashRegister = new CashRegister();
  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  idTurn: number = 0;
  loadpage: boolean = false;
  headerTitle: string = '';

  title: string = 'Emitir TITO';

  public static getName() {
    return 'CashableTicketComponent';
  }
  constructor(
    private router: Router,
    public ValuesService: TicketValuesService,
    public NotificationService: NotificationService,
    public sessionService: SessionService,
    private ticketService: TicketService,
    private TicketConfigService: TicketConfigService,
    public PrinterService: PrinterService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', this.title));

    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.idTurn = Number(paramTurnsId);

      if (isNaN(this.idTurn)) {
        this.goToErrorPage();
      }

      this.sessionService.GetSessionByTurnId(this.idTurn).subscribe((result) => {
        this.session = result.data;

        if (this.session?.idTurn === null) {
          this.router.navigate(['/session-management']);
        }
        this.headerTitle = this.sessionService.getPageTitle(this.session);
        this.setForm();
      });
    } else {
      this.goToErrorPage();
    }
  }

  setForm() {
    this.initForm();
    this.formEdit.get('ticketValue').setValue('');
    this.formEdit.get('quantity').setValue(1);

    this.TicketConfigService.getTicketConfigs().subscribe((result) => {
      var cashticket = result.data.find((i) => i.familyGroupId === TicketFamilyGroup.Cash);
      this.hideCashable = cashticket.ticketPrint;
      this.maximumTicketValue = cashticket.maximumTicketSale;
      // this.formEdit.get('ticketValue').setValue(this.maximumTicketValue);
      this.TicketValue.focusField();
      this.formEdit.controls['ticketValue'].setValidators([
        Validators.required,
        Validators.max(this.maximumTicketValue),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]);
      this.formEdit.controls['ticketValue'].updateValueAndValidity();

      this.formEdit.controls['quantity'].setValidators([Validators.pattern('[0-9]*'), Validators.required]);
      this.formEdit.controls['quantity'].updateValueAndValidity();
    });
    this.loadpage = true;
  }

  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }

  GoToCashFlow() {
    this.router.navigate(['/session-cashflow', this.session.idTurn]);
  }

  async onSubmit(myForm: any) {
    if (this.formEdit.valid) {
      //   if (this.formEdit.get('ticketValue').value > this.maximumTicketValue) {
      //     this.NotificationService.showError('Valor de ticket mayor al máximo permitido', 'Operación rechazada');
      //   } else {
      if (this.formEdit.get('ticketValue').value <= 0) {
        this.NotificationService.showError('Valor de ticket menor o igual a cero', 'Operación rechazada');
      } else {
        if (await this.PrinterService.isPrinterReady()) {
          this.ticket = new TicketsSaleViewModel(this.formEdit.value);
          //La generación de ticket ahora recibe el turno.
          this.ticket.turnId = this.idTurn;
          this.ticket.formattedAmount = Number(this.formEdit.get('ticketValue').value);
          var ticketQuantity = Number(this.formEdit.get('quantity').value);
          this.ticket.quantity = 1;
          this.ticket.familyId = TicketFamily.Cash;
          this.hideCashable = false;
          var error = false;

          for (let index = 1; index <= ticketQuantity && !error; index++) {
            if (await this.PrinterService.isPrinterReady()) {
              var result = await this.ticketService.generateCashTicket(this.ticket).toPromise();
              if (result?.data?.tickets) {
                await this.PrinterService.print(result?.data?.tickets[0], index - 1);
              }
            } else {
              error = true;
            }
          }

          this.hideCashable = true;

          if (!error) {
            myForm.resetForm();
            this.formEdit.get('quantity').setValue(1);
            this.TicketValue.focusField();
          }
        }
      }
    } else {
      this.NotificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(0),
      ticketValue: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      quantity: new FormControl(null, [Validators.pattern('[0-9]*'), Validators.required]),
    });
  }
}
