import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { RoomComboComponent } from 'src/app/common/components/business/room-combo/room-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { CommonDaterangepickerMaterialComponent } from 'src/app/common/components/shared/common-daterangepicker-material/common-daterangepicker-material.component';
import { CommonDaterangepickerComponent } from 'src/app/common/components/shared/common-daterangepicker/common-daterangepicker.component';
import {
  CommonTableComponent,
  commonTableData,
  commonTableCustomButton,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import {
  DailyAccountingReport,
  DailyAccountingReportFooterViewModel,
  DailyBalance,
} from 'src/app/common/components/viewmodels/DailyBalance';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { ProfitEvent } from 'src/app/common/components/viewmodels/Enums';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

declare var $: any;

@Component({
  selector: 'app-accounting-report',
  templateUrl: './accounting-report.component.html',
  styleUrls: ['./accounting-report.component.css'],
})
export class AccountingReportComponent implements OnInit {
  public static getName() {
    return 'AccountingReportComponent';
  }

  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<
    DailyBalance,
    DailyAccountingReportFooterViewModel
  >;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(RoomComboComponent) roomCombo: RoomComboComponent;
  @ViewChild(CommonDaterangepickerMaterialComponent)
  commonDaterangepickerComponent: CommonDaterangepickerMaterialComponent;

  footerIsLoaded: boolean = false;
  refreshPage: boolean = false;
  searchLive: boolean = false;
  title: string = 'Contable';

  items: Array<BreadcrumbItem> = [];

  regex: RegExp = new RegExp('_', 'g');

  totalProfit: number = 0;
  machineQuantity: number = 0;
  periodAction: string;
  body: string;
  currencyCode: string;
  currencySym: string;

  date: moment.Moment = moment(new Date());
  public data: commonTableData<DailyAccountingReport, DailyAccountingReportFooterViewModel>;
  formSearch: FormGroup;
  parameter: EgmProfitParameters = new EgmProfitParameters();
  dateRange: string;
  autoRefreshFunc: any = null;

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public DailyBalanceService: DailyBalanceService,
    private activatedRoute: ActivatedRoute,
    private paramService: ParametersService<EgmProfitParameters>,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe
  ) {}

  footerLoaded() {
    this.footerIsLoaded = true;
  }

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.initForm();
    this.initGrid();

    let paramEgmProfit = this.paramService.getAndDeleteParameters();

    if (paramEgmProfit != null) {
      this.data.searchText = paramEgmProfit.status.searchString;
      this.data.pageLength = paramEgmProfit.status.pageLength;
      this.data.currentPage = paramEgmProfit.status.currentPage;
      this.formSearch.get('egmUid').setValue(paramEgmProfit.uids);
      this.formSearch.get('from').setValue(paramEgmProfit.from);
      this.formSearch.get('to').setValue(paramEgmProfit.to);
      this.formSearch.get('rooms').setValue(paramEgmProfit.rooms);
      this.formSearch.get('dayStatus').setValue(paramEgmProfit.dayStatus);
    }
  }

  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;

    const that = this;

    (this.data.columns = [
      { data: 'egm.uid', title: 'UID' },
      { data: 'egm.nickName', title: 'EGM Nombre' },
      {
        data: 'formattedStartDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
        },
      },
      {
        data: 'formattedEndDate',
        title: 'Fecha Fin',
        render: function (data, type, row) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
        },
      },
      {
        data: 'formattedPlayed',
        title: 'Jugado',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedWon',
        title: 'Premiado',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedBenefit',
        title: 'Beneficio',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedRptPercent',
        title: 'RTP',
        render: function (data: number, type, row) {
          return that.percentPipe.transform(data, '2.2');
        },
      },

      // { data: 'playedChips', title: 'Jugado Fichas' },
      // { data: 'wonChips', title: 'Premiado Fichas' },
      // { data: 'benefitChips', title: 'Beneficio Fichas' },

      { data: 'formattedPlayedChips', title: 'Jugado Fichas' },
      { data: 'formattedWonChips', title: 'Premiado Fichas' },
      { data: 'formattedBenefitChips', title: 'Beneficio Fichas' },

      {
        data: 'formattedRptPercentChips',
        title: 'RTP',
        render: function (data: number, type, row) {
          return that.percentPipe.transform(data, '2.2');
        },
      },

      {
        data: 'pagoManualMoney',
        title: 'Pagos Manuales',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
    ]),
      (this.data.rowCallback = function (row: Node, data: EGM | Object, index: number): void {
        if (data['formattedBenefit'] < 0) {
          //that.redFont(row, that.getProfitIndex(), that);
          that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            $($('td', row)[that.getProfitIndex()]).css('color', '#dc3545'); //Danger
            $($('td', row)[that.getProfitIndex()]).css('font-weight', 'bold');
          });
        } else {
          if (data['formattedBenefit'] > 0) {
            //    that.greenFont(row, that.getProfitIndex(), that);
            that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              $($('td', row)[that.getProfitIndex()]).css('color', '#28a745'); //Success
              $($('td', row)[that.getProfitIndex()]).css('font-weight', 'bold');
            });
          } else {
          }
        }

        // if (data['formattedBenefitChips'] < 0) {
        //   //that.redFont(row, that.getProfitChipsIndex(), that);
        //   that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //     $($('td', row)[that.getProfitChipsIndex()]).css('color', '#dc3545'); //Danger
        //     $($('td', row)[that.getProfitChipsIndex()]).css('font-weight', 'bold');
        //   });
        // } else {
        //   if (data['formattedBenefitChips'] > 0) {
        //     //that.greenFont(row, that.getProfitChipsIndex(), that);
        //     that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //       $($('td', row)[that.getProfitChipsIndex()]).css('color', '#28a745'); //Success
        //       $($('td', row)[that.getProfitChipsIndex()]).css('font-weight', 'bold');
        //     });
        //   } else {
        //     console.log('es cerooo');
        //   }
        // }
      });
  }

  getProfitIndex(): number {
    //8 es el indice de la columna profit sin contar los custom buttons.
    return 6 + this.data.commonTableCustomButton.length;
  }

  getProfitChipsIndex(): number {
    //8 es el indice de la columna profit sin contar los custom buttons.
    return 10 + this.data.commonTableCustomButton.length;
  }

  redFont(row: Node, index: number, that: any) {
    that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[index]).css('color', '#dc3545'); //Danger
      $($('td', row)[index]).css('font-weight', 'bold');
    });
  }

  greenFont(row: Node, index: number, that: any) {
    that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[index]).css('color', '#28a745'); //Success
      $($('td', row)[index]).css('font-weight', 'bold');
    });
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
      egmUid: new FormControl([]),
      rooms: new FormControl([]),
      //   'dayStatus': new FormControl('opened')
    });
  }

  //presiono boton de la interfaz
  async onRefresh() {
    this.refreshPage = !this.refreshPage;

    await this.refreshData();
  }

  ngOnDestroy() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);
  }

  async autoRefresh() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    const timeIntevalSeconds = 30 * 1000; // 30 segundos

    this.autoRefreshFunc = setTimeout(async () => {
      await this.refreshData();
    }, timeIntevalSeconds); //
  }

  async refreshData() {
    if (this.refreshPage) {
      await this.commonDaterangepickerComponent.refreshData();

      // if (this.parameter != null) {
      //   this.data.searchText = this.parameter.status.searchString;
      //   this.data.pageLength = this.parameter.status.pageLength;
      //   this.data.currentPage = this.parameter.status.currentPage;
      // }

      this.performSearch();
      this.autoRefresh();
    }
  }

  onSubmit() {
    //guardo si estoy buscando en el to hasta ahora, para que el refresh actualice la hora:

    var now = moment();
    var to = this.formSearch.get('to').value;

    if (to >= now) {
      this.searchLive = true;
    }

    this.performSearch();
  }

  performSearch() {
    this.parameter.from = this.formSearch.get('from').value;
    this.parameter.to = this.formSearch.get('to').value;
    if (this.formSearch.get('rooms').value != null) {
      this.formSearch.get('rooms').value.forEach((element: number) => {
        this.parameter.rooms.push(element);
      });
    }
    if (this.formSearch.get('egmUid').value != null) {
      this.formSearch.get('egmUid').value.forEach((element: number) => {
        this.parameter.uids.push(element);
      });
    }
    // this.parameter.dayStatus = this.formSearch.get('dayStatus').value;
    this.updateTable();
  }

  updateTable() {
    this.totalProfit = 0;
    this.machineQuantity = 0;
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();

    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    if (this.formSearch.get('rooms').value.length > 0) {
      aux += ' Salas: ' + this.roomFilters + ' - ';
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters + ' - ';
    }
    //aux += this.formSearch.get('dayStatus').value == 'opened' ? "Día abierto" : "Día cerrado";

    if (this.table != undefined) {
      this.table.setFilterString(aux);
    }
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get roomFilters(): string {
    let combo = this.roomCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('rooms').value.length; index++) {
      const element = this.formSearch.get('rooms').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('rooms').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  getFilters(): MultiselectFilters {
    var filters = new MultiselectFilters();

    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;

    // if (this.isDailyClosing) {
    //   filters.closedPeriod = this.formSearch.get('dayStatus').value == 'closed' ? true : false;
    // }
    // if (this.isPeriodReport) {
    //   filters.closedPeriod = true;
    // }
    // if (this.isReportCurrentProfit) {
    //   filters.closedPeriod = false;
    // }

    if (this.formSearch.get('egmUid').value != null) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      if (this.formSearch.get('rooms').value != null) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }

    return filters;
  }

  updateEgms() {
    this.formSearch.get('egmUid').setValue([]);
    if (this.formSearch.get('rooms').value == null) {
      this.egmsCombo.fillCombo();
    } else {
      var filters: MultiselectFilters = new MultiselectFilters();
      filters.rooms = this.formSearch.get('rooms').value;
      filters.egms = this.formSearch.get('egmUid').value;
      this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
    }
  }

  setGridData(filters: MultiselectFilters) {
    let service = this.DailyBalanceService;

    // this.data.getDataFooter = function (param: any): Observable<ApiResult<DailyAccountingReportFooterViewModel>> {
    //   var DataTableFilters = new DatatableMultiFilters();
    //   DataTableFilters.filters = filters;
    //   DataTableFilters.dataTableParameters = param;
    //   return service.getDailyAccountingReportFooter(DataTableFilters)
    // };

    this.data.getData = function (
      param: any
    ): Observable<ApiResult<DataTableResult<DailyAccountingReport, DailyAccountingReportFooterViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getDailyAccountReport(DataTableFilters);
    };
  }

  // let filters  = this.getFilters();

  // this.DailyBalanceService.getDailyAccountingReportFooter(this.getFilters()).subscribe((result) => {
  //   this.footer = result.data;
  // });

  //public onCustomButton(data: any): void {
  //   this.parameter.idEgm = data.row.egmId;
  //   this.parameter.idModel = data.row.egm.modelId;
  //   this.parameter.idManufacturer = data.row.egm.manufacturerId;
  //   this.parameter.idGameType = data.row.egm.gameTypeId;
  //   //me voy al otro formulario con los datos de la fecha seleccionada
  //   this.parameter.from = data.row.startDate;
  //   this.parameter.to = data.row.endDate;
  //   this.paramService.saveParameters(this.parameter);
  //   this.router.navigate(['/reports/current-counters']);
  // }

  // calculateProfit(row: Node) {
  //   this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     var amount = ((Math.random() - 0.5) * 2) * 1000;
  //     $($('td', row)[this.getProfitIndex()]).text(this.currencyPipe.transform(amount, this.currencyCode, this.currencySym));
  //     if (amount > 0) {
  //       this.greenFont(row);
  //     } else {
  //       this.redFont(row);
  //     }

  //   });
  // }

  // getProfitIndex(): number {
  //   //8 es el indice de la columna profit sin contar los custom buttons.
  //   return 10 + this.data.commonTableCustomButton.length;
  // }

  // redFont(row: Node) {
  //   this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     $($('td', row)[this.getProfitIndex()]).css("color", "#dc3545") //Danger
  //     $($('td', row)[this.getProfitIndex()]).css("font-weight", "bold");
  //   });
  // }

  // greenFont(row: Node) {
  //   this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     $($('td', row)[this.getProfitIndex()]).css("color", "#28a745") //Success
  //     $($('td', row)[this.getProfitIndex()]).css("font-weight", "bold");
  //   });
  // }

  onSearch(status: tableStatus) {
    this.parameter.status = status;
    this.totalProfit = this.table.decimalData;
    this.machineQuantity = this.table.intData;
  }
}

export class EgmProfitParameters {
  from: string;
  to: string;
  uids: number[];

  cuids: number[];
  rooms: number[];
  dayStatus: string;
  toConciliate: string;
  status: tableStatus;
  idEgm: number;
  idModel: number;
  idManufacturer: number;
  idGameType: number;
  idUser: number;
  creationDate: number;
  turnIds: number[];
  movementTypes: number[];
  movementsId: number[];

  constructor() {
    this.status = new tableStatus();
    this.uids = [];
    this.cuids = [];
    this.rooms = [];
    this.turnIds = [];
    this.movementTypes = [];
    this.movementsId = [];
  }
}
