import moment, { Moment } from 'moment-timezone';
import { EGM } from './EGM';
import { StateEgm } from './Enums';

export class SessionManagmentViewModel {
  sessionStartDate?: moment.Moment;
  sessionStartUser?: string;
  turnStartDate?: moment.Moment;
  turnStartUser?: string;

  cashRegisterId?: number;
  cashRegisterName?: string;
  cashRegisterCuid?: string;

  cashinLimit?: number;

  isSessionStarted: boolean = false;
  isTurnStarted: boolean = false;
  canStartSession: boolean = false;
  canEndSession: boolean = false;
  canStartTurn: boolean = false;
  canEndTurn: boolean = false;

  idSession?: number;
  idTurn?: number;

  startingTurn: boolean = false;
  endingTurn: boolean = false;
  cashRegisterType: CashRegisterTypeEnum;
  nextTurnId: number;
  nextCashRegisterName: string;
}

export class SettlementViewModel {
  cashRegisterId: number;
  idTurn?: number;
  bills: MoneySettlementViewModel[] = [];
  coins: MoneySettlementViewModel[] = [];
  totalsBills: number;
  totalsCoins: number;
  total: number;
  currentTotalTheoretical: number;
  difftotal: number;
  dataDate?: Moment;
  observations: string;
  errorReadingStock: number;

  public constructor(init?: Partial<CollectionViewModel>) {
    Object.assign(this, init);
  }
}

export class TurnDetailViewModel {
  turnId: number;
  cashRegisterName: string;
  closeUserName: string;
  openDate: Date;
  closeDate: Date | null;
  // totalReal: number;
  // currentTotalTheoretical: number;
  // dif: number;
  // bills: MoneySettlementViewModel[];
  // coins: MoneySettlementViewModel[];
  // billsDesc: string;
  // coinsDesc: string;

  settlement: SettlementViewModel;
}

export class MoneySettlementViewModel {
  count: string;
  val: string;
  total: number;
  currencyVal: number;
  currencyTypeId: number;
}

export class CashFlowViewModel {
  id: number;
  date: Moment;
  validatorCode: string;
  egmSource: string;
  egmTarget: string;
  cashRegisterSoruce: string;
  cashRegisterTarget: string;

  egmNickName: string;
  description: string;
  isPositive: boolean;

  formattedAmount: number;

  userIdName: string;
  isConciliate: boolean;
  turnDescription: string;

  collectionId?: number;
  reconciliationId?: number;
  observations?: string;
  userCreatedTicket: boolean;
}

export class CashFlowViewModelFooter {
  formattedAmount: number;
  amount: number;
}

export enum SettlmentTypeEnum {
  ReadOnly = 0,
  OpenTurn = 1,
  EndTurn = 2,
  ReadOnlySpecifTurn = 3,
}

export enum CashRegisterTypeEnum {
  Salon = 1,
  MaquinaDeCambio = 2,
  Apuestas = 3,
}

export class SessionMovementViewModel {
  movementType: ButtonMovementTypeEnum;
  formattedAmount: number;
  observation: string;
  authorizationCode: string;
  authorizationId: number;
  turnId: number;
}

export enum ButtonMovementTypeEnum {
  PayDatafono = 0,
  PayInvoice = 1,
  PayJackPot = 2,
  //aumento fondo
  IncreaseFunds = 3,
  //disminucion fondo
  ReduceFunds = 4,
  //aumento fondo caja
  IncreaseFundsCashier = 5,
  //disminucion fondo
  ReduceFundsCashier = 6,
  //Apuestas
  PayBets = 7,
  //Gastos varios
  ExpensesMiscellaneous = 8,
}

export class EgmActionsViewModel {
  id: number;
  uid: number;
  statusEgm: StateEgm;
  lastCollection?: moment.Moment;
  nickName: string;
  canCollect: boolean;
  canRestock: boolean;
  canTakeOut: boolean;
  WasClollectedRecently: boolean;
  canReserveYes: boolean;
  canReserveNo: boolean;
}

export class SessionStokEgmViewModel {
  egmStockType: EgmStockTypeEnum;
  formattedAmount: number;
  egmId: number;
  turnId: number;
  observation: string;
  printTito: boolean;
}

export enum EgmStockTypeEnum {
  Retirar = 0,
  Reponer = 1,
}

export class CollectionViewModel {
  title: string;
  egmId: number;
  egm: EGM;
  collectionDate?: moment.Moment;
  egmUid: number;

  collectionCasinoDesc: string;
  //contadores

  playedCounter: number;
  playedMoney: number;
  formattedPlayedMoney: number;
  wonCounter: number;
  wonMoney: number;
  formattedWonMoney: number;

  formattedTicketOut: number;
  ticketOut: number;
  formattedTicketIn: number;
  ticketIn: number;

  //Movimientos
  movementsSummary: CollectionMovementsViewModel[] = [];

  formattedDepositInitialBefore: number;
  depositInitialBefore: number;
  formattedBenefit: number;
  benefit: number;
  formattedTotalTickets: number;
  totalTickets: number;
  formattedTotalMoviments: number;
  totalMoviments: number;

  formattedSettlementTehoric: number;
  settlementTehoric: number;

  formattedSettlementReal: number;
  settlementReal: number;

  formattedDepositCurrent: number;
  depositCurrent: number;

  formattedTheoricToTake: number;
  theoricToTake: number;

  formattedTheoricPendingToReConcil: number;
  pendingToReConcil: number;
  observations: string;

  formattedReposicionMaquinaAmount: number;
  formattedRetiradaMaquinaAmount: number;
  formattedPagoManualAmount: number;
  formattedPagoManualSinCodigoAmount: number;

  formattedTicketPendingAmount: number;
  ticketPendingAmount: number;
  ticketPendingCount: number;

  formattedAverageBenefitDay: number;
  daysLastCollection: number;

  public constructor(init?: Partial<CollectionViewModel>) {
    Object.assign(this, init);
  }
}

export class CollectionFooterViewModel {
  totalFormattedTheoricToTake: number;
  totalFormattedTheoricPendingToReConcil: number;

  theoricToTake: number;
  theoricPendingToReConcil: number;
}

export class CollectionMovementsViewModel {
  description: string;
  formattedAmount: number;
  amount: number;
}

export class CollectionEgmViewModel {
  idMovement: number;
  egmId: number;
  formattedSettlementReal: number;
  formattedTheoricToTake: number;
  observations: string;
}

export class CloseCollectionState {
  canCloseCollection: boolean;
  egmToCollect: string;
  cashRegisterToConciliate: string;
}

export class ButtonActionMap {
  index: number;
  actionId: string; // Agregamos un identificador de acción
  action: (rowId: string, egmUid: number) => void;

  constructor(index: number, actionId: string, action: (rowId: string) => void) {
    this.index = index;
    this.actionId = actionId;
    this.action = action;
  }
}
