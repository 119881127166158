import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-report-current-profit',
  templateUrl: './report-current-profit.component.html',
  styleUrls: ['./report-current-profit.component.css'],
})
export class ReportCurrentProfitComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];

  title: string = 'Ganancia Día Abierto';

  public static getName() {
    return 'ReportCurrentProfitComponent';
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }
}
