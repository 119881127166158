import { PercentPipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { TicketHistoryComponent } from 'src/app/pages/ticket-history/ticket-history.component';
import { CabinetService } from 'src/app/services/cabinet/cabinet.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { LocationService } from 'src/app/services/location/location.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SpiderService } from 'src/app/services/spider/spider.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { InputTypeEnum } from '../../shared/common-input/common-input.component';
import { tableStatus } from '../../shared/common-table/common-table.component';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { GuidTextModel, IdTextModel } from '../../shared/IdTextModel';
import { NotificationService } from '../../shared/notification/notification.service';
import { EGMMock } from '../../viewmodels/EGMMock';
import { MeterModelFlagEnum, ValidationStyles } from '../../viewmodels/Enums';
import { GametypesComboComponent } from '../gametypes-combo/gametypes-combo.component';
import { ManufacturersComboComponent } from '../manufacturers-combo/manufacturers-combo.component';
import { ModelsComboComponent } from '../models-combo/models-combo.component';
import { TicketConfigService } from 'src/app/services/ticket-config/ticket-config.service';
import { SystemParameters } from '../../viewmodels/SystemParameters';
import { CountersService } from 'src/app/services/counters/counters.service';

@Component({
  selector: 'app-general-edit-egm',
  templateUrl: './general-edit-egm.component.html',
  styleUrls: ['./general-edit-egm.component.css'],
})
export class GeneralEditEgmComponent implements OnInit {
  @Input() isEditEgm: boolean = false; // Editar/Crear EGM

  @Input() isEditParameters: boolean = false; // Editar parametros de EGM
  @Input() isParametersReport: boolean = false; // Reporte parametros EGM
  @Input() isEdit: boolean = false; // Es editar EGM
  @Input() isCreateEgm: boolean = false; // Es editar EGM
  @Input() idClone: string; // Si esta clonando EGM
  @Input() idReportEgm: number; // Si esta pidiendo reporte de EGM

  @ViewChild('confirmModalDisabledEgm', { static: false }) confirmModalDisabledEgm: ConfirmModalComponent;
  @ViewChild('confirmModalStartupEgm', { static: false }) confirmModalStartupEgm: ConfirmModalComponent;

  @ViewChild('confirmModalLogSpiderOn', { static: false }) confirmModalLogSpiderOn: ConfirmModalComponent;
  @ViewChild('confirmModalLogSpiderOff', { static: false }) confirmModalLogSpiderOff: ConfirmModalComponent;

  @ViewChild(GametypesComboComponent) gametypesCombo: GametypesComboComponent;
  @ViewChild(ModelsComboComponent) modelsCombo: ModelsComboComponent;
  @ViewChild(ManufacturersComboComponent) manufacturerCombo: ManufacturersComboComponent;

  cabinetList: Array<GuidTextModel> = [];
  micIdList: Array<GuidTextModel> = [];
  spiderList: Array<IdTextModel> = [];
  locationList: Array<IdTextModel> = [];

  agrupadorJugadoFichasList: Array<IdTextModel> = [];
  agrupadorJugadoList: Array<IdTextModel> = [];
  agrupadorPagoManualList: Array<IdTextModel> = [];
  agrupadorPremiadoFichasList: Array<IdTextModel> = [];
  agrupadorPremiadoList: Array<IdTextModel> = [];

  egmObject: EGMMock = new EGMMock();
  egm: EGMMock = new EGMMock();
  InputType = InputTypeEnum;
  firstTime: boolean = true;

  formEditParameters: FormGroup;
  formEditEgm: FormGroup;
  systemParameters: SystemParameters;

  parameter: tableStatus = null;
  constructor(
    private router: Router,
    private TicketConfigService: TicketConfigService,
    private ValidationService: ValidationService,
    private LocationService: LocationService,
    private activatedRoute: ActivatedRoute,
    private cabinetService: CabinetService,
    private SpiderService: SpiderService,
    private percentPipe: PercentPipe,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    public egmService: EgmServiceService,
    public counterService: CountersService
  ) {}

  async ngOnInit(): Promise<void> {
    this.parameter = this.paramService.getAndDeleteParameters();
    var resultparameters = await this.TicketConfigService.getParameters().toPromise();
    this.systemParameters = resultparameters.data;

    if (this.isEditParameters || this.isParametersReport) {
      this.initFormParameters();
      if (this.idReportEgm != null) {
        var result = await this.egmService.getEGM2(this.idReportEgm).toPromise();
        this.egmObject = result.data;
      }

      if (this.activatedRoute.snapshot.params.id != null) {
        await this.getEgmParam();
        this.isEdit = true;
      }

      this.formEditParameters.get('egmId').setValue(this.egmObject.id);

      //Egm parametros
      this.formEditParameters.get('acceptsCoins').setValue(this.egmObject.egmParam.acceptsCoins);
      this.formEditParameters.get('acceptsHopper').setValue(this.egmObject.egmParam.acceptsCoins);
      this.formEditParameters.get('aftIn').setValue(this.egmObject.egmParam.aftIn);
      this.formEditParameters.get('aftOut').setValue(this.egmObject.egmParam.aftOut);
      this.formEditParameters.get('ticketPromoRestrictedIn').setValue(this.egmObject.egmParam.ticketPromoRestrictedIn);
      this.formEditParameters
        .get('ticketPromoRestrictedOut')
        .setValue(this.egmObject.egmParam.ticketPromoRestrictedOut);
      this.formEditParameters
        .get('ticketPromoNonRestrictedIn')
        .setValue(this.egmObject.egmParam.ticketPromoNonRestrictedIn);
      this.formEditParameters.get('handpayOut').setValue(this.egmObject.egmParam.handpayOut);
      this.formEditParameters.get('ticketCashIn').setValue(this.egmObject.egmParam.ticketCashIn);
      this.formEditParameters.get('ticketCashOut').setValue(this.egmObject.egmParam.ticketCashOut);
      this.formEditParameters.get('foreignRestrictedOut').setValue(this.egmObject.egmParam.foreignRestrictedOut);
      this.formEditParameters.get('validateHandpay').setValue(this.egmObject.egmParam.validateHandpay);

      //Egm billeteros
      this.formEditParameters.get('m1').setValue(this.egmObject.egmParam.m1);
      this.formEditParameters.get('m2').setValue(this.egmObject.egmParam.m2);
      this.formEditParameters.get('m3').setValue(this.egmObject.egmParam.m3);
      this.formEditParameters.get('m4').setValue(this.egmObject.egmParam.m4);
      this.formEditParameters.get('m5').setValue(this.egmObject.egmParam.m5);
      this.formEditParameters.get('m6').setValue(this.egmObject.egmParam.m6);
      this.formEditParameters.get('m7').setValue(this.egmObject.egmParam.m7);
      this.formEditParameters.get('m8').setValue(this.egmObject.egmParam.m8);

      //Egm config
      this.formEditParameters.get('printerCashoutDevice').setValue(this.egmObject.egmConfig.printerCashoutDevice);
      this.formEditParameters
        .get('printerHandpayReceiptDevice')
        .setValue(this.egmObject.egmConfig.printerHandpayReceiptDevice);
      this.formEditParameters.get('printerRestrictedTicket').setValue(this.egmObject.egmConfig.printerRestrictedTicket);
      this.formEditParameters.get('validateHandpays').setValue(this.egmObject.egmConfig.validateHandpays);
      this.formEditParameters.get('ticketForeignRestricted').setValue(this.egmObject.egmConfig.ticketForeignRestricted);
      this.formEditParameters.get('ticketRedemption').setValue(this.egmObject.egmConfig.ticketRedemption);
      this.formEditParameters.get('legacyBonus').setValue(this.egmObject.egmConfig.legacyBonus);
      this.formEditParameters.get('jackpot').setValue(this.egmObject.egmConfig.jackpot);
      this.formEditParameters.get('aft').setValue(this.egmObject.egmConfig.aft);
      this.formEditParameters.get('aftBonusAwards').setValue(this.egmObject.egmConfig.aftBonusAwards);
      this.formEditParameters.get('tournament').setValue(this.egmObject.egmConfig.tournament);
      this.formEditParameters.get('extendedCounters').setValue(this.egmObject.egmConfig.extendedCounters);
      this.formEditParameters
        .get('validationStyle')
        .setValue(ValidationStyles[this.egmObject.egmConfig.validationStyle]);
      this.formEditParameters
        .get('meterModelFlagEnum')
        .setValue(MeterModelFlagEnum[this.egmObject.egmConfig.meterModelFlagEnum]);
      this.formEditParameters.get('ticketToDropCancel').setValue(this.egmObject.egmConfig.ticketToDropCancel);
      this.formEditParameters.get('multiExtensions').setValue(this.egmObject.egmConfig.multiExtensions);
      this.formEditParameters.get('maxPooling').setValue(this.egmObject.egmConfig.maxPooling);
      this.formEditParameters.get('denomination').setValue(this.egmObject.egmConfig.formattedDenomination);
      this.formEditParameters
        .get('paymentPercentage')
        .setValue(this.percentPipe.transform(this.egmObject.egmConfig.formattedPaymentPercentageProb, '2.2'));
      // this.formEditParameters.get('paymentPercentage').setValue(this.egmObject.egmConfig.formattedPaymentPercentage);
      this.formEditParameters.get('protocolError').setValue(this.egmObject.egmConfig.protocolError);
      this.formEditParameters.get('online').setValue(this.egmObject.egmConfig.online ? 'Si' : 'No');
      this.formEditParameters.get('sasVersion').setValue(this.egmObject.egmConfig.sasVersion);
      this.formEditParameters.get('formattedSasVersion').setValue(this.egmObject.egmConfig.formattedSasVersion);
      this.formEditParameters.get('currency').setValue(this.egmObject.egmConfig.currency);
      this.formEditParameters.get('serial').setValue(this.egmObject.egmConfig.serial);

      // this.title = this.title.concat(' de la EGM ', this.egmObject.uid.toString())
    }
    if (this.isEditEgm || this.isParametersReport) {
      this.initFormEdit();
      if (this.idReportEgm != null) {
        var result = await this.egmService.getEGM2(this.idReportEgm).toPromise();
        this.egm = result.data;
        this.formEditEgm.patchValue(result.data);
        await this.getManufacturers(true);
        this.formEditEgm.get('spiderId').setValue(this.egm.spiderId);
        this.formEditEgm.get('online').setValue(result.data.egmConfig.online ? 'Si' : 'No');
        this.formEditEgm
          .get('paymentPercentage')
          .setValue(this.percentPipe.transform(result.data.egmConfig.formattedPaymentPercentageProb, '2.2'));
        // this.formEditEgm.get('paymentPercentage').setValue(result.data.egmConfig.formattedPaymentPercentage, "2.2");
      }
      if (this.activatedRoute.snapshot.params.id != null) {
        var result = await this.egmService.getEGM2(parseInt(this.activatedRoute.snapshot.params.id)).toPromise();
        this.egm = result.data;
        await this.getManufacturers(true);
        this.formEditEgm.patchValue(result.data);
        this.formEditEgm.get('modelId').setValue(this.egm.modelId);
        this.formEditEgm.get('gameTypeId').setValue(this.egm.gameTypeId);
        this.formEditEgm.get('online').setValue(result.data.egmConfig.online ? 'Si' : 'No');
        this.formEditEgm
          .get('paymentPercentage')
          .setValue(this.percentPipe.transform(result.data.egmConfig.formattedPaymentPercentageProb, '2.2'));
        //this.formEditEgm.get('paymentPercentage').setValue(result.data.egmConfig.formattedPaymentPercentage, "2.2");
      } else {
        if (this.idClone != null) {
          var result = await this.egmService.getEGM2(parseInt(this.idClone)).toPromise();
          this.egm = result.data;
          this.formEditEgm.patchValue(result.data);
          await this.getManufacturers(true);
          this.formEditEgm.get('id').setValue(0);
          this.formEditEgm.get('uid').setValue(null);
          this.formEditEgm.get('spiderId').setValue(null);
          this.formEditEgm.get('online').setValue(result.data.egmConfig.online ? 'Si' : 'No');
          this.formEditEgm
            .get('paymentPercentage')
            .setValue(this.percentPipe.transform(result.data.egmConfig.formattedPaymentPercentageProb, '2.2'));
          //  this.formEditEgm.get('paymentPercentage').setValue(result.data.egmConfig.formattedPaymentPercentage, "2.2");
        }
      }

      if (!this.isCreateEgm) {
        this.formEditEgm.get('uid').clearValidators();
        this.formEditEgm.get('uid').disable();
      }
      this.fillCombos();
    }
    if (this.isParametersReport) {
      this.formEditParameters.get('egmId').disable();
      //Egm parametros
      this.formEditParameters.get('acceptsCoins').disable();
      this.formEditParameters.get('acceptsHopper').disable();
      this.formEditParameters.get('aftIn').disable();
      this.formEditParameters.get('aftOut').disable();
      this.formEditParameters.get('ticketPromoRestrictedIn').disable();
      this.formEditParameters.get('ticketPromoRestrictedOut').disable();
      this.formEditParameters.get('ticketPromoNonRestrictedIn').disable();
      this.formEditParameters.get('handpayOut').disable();
      this.formEditParameters.get('ticketCashIn').disable();
      this.formEditParameters.get('ticketCashOut').disable();
      this.formEditParameters.get('foreignRestrictedOut').disable();
      this.formEditParameters.get('validateHandpay').disable();
      //Egm billeteros
      this.formEditParameters.get('m1').disable();
      this.formEditParameters.get('m2').disable();
      this.formEditParameters.get('m3').disable();
      this.formEditParameters.get('m4').disable();
      this.formEditParameters.get('m5').disable();
      this.formEditParameters.get('m6').disable();
      this.formEditParameters.get('m7').disable();
      this.formEditParameters.get('m8').disable();

      //Egm config
      this.formEditParameters.get('printerCashoutDevice').disable();
      this.formEditParameters.get('printerHandpayReceiptDevice').disable();
      this.formEditParameters.get('printerRestrictedTicket').disable();
      this.formEditParameters.get('validateHandpays').disable();
      this.formEditParameters.get('ticketForeignRestricted').disable();
      this.formEditParameters.get('ticketRedemption').disable();
      this.formEditParameters.get('legacyBonus').disable();
      this.formEditParameters.get('jackpot').disable();
      this.formEditParameters.get('aft').disable();
      this.formEditParameters.get('aftBonusAwards').disable();
      this.formEditParameters.get('tournament').disable();
      this.formEditParameters.get('extendedCounters').disable();
      this.formEditParameters.get('validationStyle').disable();
      this.formEditParameters.get('meterModelFlagEnum').disable();
      this.formEditParameters.get('ticketToDropCancel').disable();
      this.formEditParameters.get('multiExtensions').disable();
      this.formEditParameters.get('maxPooling').disable();
      this.formEditParameters.get('denomination').disable();
      this.formEditParameters.get('paymentPercentage').disable();
      this.formEditParameters.get('protocolError').disable();
      this.formEditParameters.get('online').disable();
      this.formEditParameters.get('sasVersion').disable();
      this.formEditParameters.get('formattedSasVersion').disable();

      this.formEditParameters.get('currency').disable();
      this.formEditParameters.get('serial').disable();

      this.formEditEgm.get('id').disable();
      this.formEditEgm.get('uid').disable();
      this.formEditEgm.get('manufacturerId').disable();
      this.formEditEgm.get('micId').disable();
      this.formEditEgm.get('agrupadorJugadoFichasCounterIds').disable();
      this.formEditEgm.get('agrupadorJugadoCounterIds').disable();
      this.formEditEgm.get('agrupadorPagoManualCounterIds').disable();
      this.formEditEgm.get('agrupadorPremiadoFichasCounterIds').disable();
      this.formEditEgm.get('agrupadorPremiadoCounterIds').disable();
      this.formEditEgm.get('spiderId').disable();
      this.formEditEgm.get('cabinetId').disable();
      this.formEditEgm.get('modelId').disable();
      this.formEditEgm.get('locationId').disable();
      this.formEditEgm.get('ticketExpiration').disable();
      this.formEditEgm.get('gameTypeId').disable();
      this.formEditEgm.get('paymentPercentage').disable();
      this.formEditEgm.get('roomId').disable();
      this.formEditEgm.get('currency').disable();
      this.formEditEgm.get('creditValue').disable();
      this.formEditEgm.get('formattedDepositInitial').disable();
      this.formEditEgm.get('ticketLimit').disable();
      this.formEditEgm.get('allowTicketInFromCashRegister').disable();
      this.formEditEgm.get('allowTicketInFromCashMachine').disable();

      this.formEditEgm.get('denomination').disable();
      this.formEditEgm.get('status').disable();
      this.formEditEgm.get('softwareVersion').disable();
      this.formEditEgm.get('observations').disable();
      this.formEditEgm.get('nickName').disable();
      this.formEditEgm.get('alias').disable();
      this.formEditEgm.get('sasVersion').disable();
      this.formEditEgm.get('formattedSasVersion').disable();
      this.formEditEgm.get('mac').disable();
      this.formEditEgm.get('online').disable();
      this.formEditEgm.get('date').disable();
    }

    //si estoy creando egm y no estoy clonando cargo el default en ticket
    if (this.isCreateEgm && this.idClone == null) {
      this.egmService.getTicketCashConfig().subscribe((result) => {
        this.formEditEgm.get('ticketExpiration').setValue(result.data.defaultExpiration);
      });

      //cargo x defualt los primeros valores de los combos de agrupadores:
      // this.formEditEgm.get('agrupadorJugadoFichas').setValue(1);
      // this.formEditEgm.get('agrupadorJugado').setValue(1);
      // this.formEditEgm.get('agrupadorPagoManual').setValue(1);
      // this.formEditEgm.get('agrupadorPremiadoFichas').setValue(1);
      // this.formEditEgm.get('agrupadorPremiado').setValue(1);
    }

    //seteo los parametros de regionalización:
    if (this.isEditEgm || this.isCreateEgm) {
      if (!this.systemParameters.allowTicketFromCashMachine) {
        this.formEditEgm.get('allowTicketInFromCashMachine').disable();
        this.formEditEgm.get('allowTicketInFromCashMachine').setValue(false);
      }
      if (!this.systemParameters.allowTicketFromCashRegister) {
        this.formEditEgm.get('allowTicketInFromCashRegister').disable();
        this.formEditEgm.get('allowTicketInFromCashRegister').setValue(false);
      }

      //a la expiracion de ticket le pongo los validarores q tenia mas el de parametros:
      this.formEditEgm.controls['ticketExpiration'].setValidators([
        Validators.required,
        Validators.max(this.systemParameters.expirateTicketCashInHandPay),
        Validators.pattern('[0-9]*'),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]);
      this.formEditEgm.controls['ticketLimit'].setValidators([
        Validators.required,
        Validators.max(this.systemParameters.maxTicketIn),
        Validators.pattern('[0-9]*'),
      ]);
    }

    if (this.isCreateEgm) {
      this.formEditEgm.get('ticketLimit').setValue(this.systemParameters.maxTicketIn);
    }

    if (this.isEditParameters) {
      if (!this.systemParameters.allowTicketCashIn) {
        this.formEditParameters.get('ticketCashIn').disable();
        this.formEditParameters.get('ticketCashIn').setValue(false);
      }
      if (!this.systemParameters.allowAftIn) {
        this.formEditParameters.get('aftIn').disable();
        this.formEditParameters.get('aftIn').setValue(false);
      }
      if (!this.systemParameters.allowAftOut) {
        this.formEditParameters.get('aftOut').disable();
        this.formEditParameters.get('aftOut').setValue(false);
      }

      this.formEditParameters.get('ticketPromoRestrictedIn').disable();
      this.formEditParameters.get('ticketPromoRestrictedOut').disable();
      this.formEditParameters.get('ticketPromoNonRestrictedIn').disable();
      this.formEditParameters.get('foreignRestrictedOut').disable();
    }
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('/machines/egms');
  }
  onSubmit() {
    if (this.isEditParameters) {
      if (this.formEditParameters.valid) {
        this.egmObject.egmParam.m1 = this.formEditParameters.get('m1').value;
        this.egmObject.egmParam.m2 = this.formEditParameters.get('m2').value;
        this.egmObject.egmParam.m3 = this.formEditParameters.get('m3').value;
        this.egmObject.egmParam.m4 = this.formEditParameters.get('m4').value;
        this.egmObject.egmParam.m5 = this.formEditParameters.get('m5').value;
        this.egmObject.egmParam.m6 = this.formEditParameters.get('m6').value;
        this.egmObject.egmParam.m7 = this.formEditParameters.get('m7').value;
        this.egmObject.egmParam.m8 = this.formEditParameters.get('m8').value;
        this.egmObject.egmParam.ticketPromoRestrictedIn = this.formEditParameters.get('ticketPromoRestrictedIn').value;
        this.egmObject.egmParam.ticketPromoRestrictedOut =
          this.formEditParameters.get('ticketPromoRestrictedOut').value;
        this.egmObject.egmParam.ticketPromoNonRestrictedIn =
          this.formEditParameters.get('ticketPromoNonRestrictedIn').value;
        this.egmObject.egmParam.handpayOut = this.formEditParameters.get('handpayOut').value;
        this.egmObject.egmParam.foreignRestrictedOut = this.formEditParameters.get('foreignRestrictedOut').value;
        this.egmObject.egmParam.ticketCashIn = this.formEditParameters.get('ticketCashIn').value;
        this.egmObject.egmParam.ticketCashOut = this.formEditParameters.get('ticketCashOut').value;
        this.egmObject.egmParam.validateHandpay = this.formEditParameters.get('validateHandpay').value;

        this.egmObject.egmParam.aftIn = this.formEditParameters.get('aftIn').value;
        this.egmObject.egmParam.aftOut = this.formEditParameters.get('aftOut').value;
        this.egmObject.egmParam.acceptsCoins = this.formEditParameters.get('acceptsCoins').value;
        this.egmObject.egmParam.acceptsHopper = this.formEditParameters.get('acceptsHopper').value;

        this.egmService.editEgmParams(this.egmObject).subscribe();
        this.paramService.saveParameters(this.parameter);
        this.router.navigate(['/machines/egms']);
      } else {
        this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
      }
    }
    if (this.isEditEgm) {
      if (this.formEditEgm.valid) {
        var egm = new EGMMock(this.formEditEgm.value);
        egm.id = this.formEditEgm.get('id').value;
        if (this.isEdit) {
          egm.uid = this.egm.uid;
        } else {
          egm.uid = Number(this.formEditEgm.get('uid').value);
        }
        egm.date = this.formEditEgm.get('date').value;
        // egm.status = this.formEditEgm.get('status').value;

        if (this.idClone != null) {
          egm.egmConfig = this.egm.egmConfig;
          egm.egmParam = this.egm.egmParam;
        }
        this.paramService.saveParameters(this.parameter);
        this.egmService.createOrEdit(egm).subscribe((result) => {
          if (result.error == null) this.router.navigate(['/machines/egms']);
        });
      } else {
        this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
      }
    }
  }
  async reportReload(id: number) {
    if (id != null) {
      var result = await this.egmService.getEGM2(id).toPromise();
      this.egmObject = result.data;
      this.egm = result.data;
      this.formEditEgm.patchValue(result.data);
      await this.getManufacturers(false);
      this.formEditEgm.get('online').setValue(this.egmObject.egmConfig.online ? 'Si' : 'No');
      this.formEditEgm
        .get('paymentPercentage')
        .setValue(this.percentPipe.transform(this.egmObject.egmConfig.formattedPaymentPercentageProb, '2.2'));
      //this.formEditEgm.get('paymentPercentage').setValue(result.data.egmConfig.formattedPaymentPercentage, "2.2");

      this.formEditParameters.get('egmId').setValue(this.egmObject.id);
      //Egm parametros
      this.formEditParameters.get('acceptsCoins').setValue(this.egmObject.egmParam.acceptsCoins);
      this.formEditParameters.get('acceptsHopper').setValue(this.egmObject.egmParam.acceptsCoins);
      this.formEditParameters.get('aftIn').setValue(this.egmObject.egmParam.aftIn);
      this.formEditParameters.get('aftOut').setValue(this.egmObject.egmParam.aftOut);
      this.formEditParameters.get('ticketPromoRestrictedIn').setValue(this.egmObject.egmParam.ticketPromoRestrictedIn);
      this.formEditParameters
        .get('ticketPromoRestrictedOut')
        .setValue(this.egmObject.egmParam.ticketPromoRestrictedOut);
      this.formEditParameters
        .get('ticketPromoNonRestrictedIn')
        .setValue(this.egmObject.egmParam.ticketPromoNonRestrictedIn);
      this.formEditParameters.get('handpayOut').setValue(this.egmObject.egmParam.handpayOut);
      this.formEditParameters.get('ticketCashIn').setValue(this.egmObject.egmParam.ticketCashIn);
      this.formEditParameters.get('ticketCashOut').setValue(this.egmObject.egmParam.ticketCashOut);
      this.formEditParameters.get('foreignRestrictedOut').setValue(this.egmObject.egmParam.foreignRestrictedOut);
      this.formEditParameters.get('validateHandpay').setValue(this.egmObject.egmParam.validateHandpay);

      //Egm billeteros
      this.formEditParameters.get('m1').setValue(this.egmObject.egmParam.m1);
      this.formEditParameters.get('m2').setValue(this.egmObject.egmParam.m2);
      this.formEditParameters.get('m3').setValue(this.egmObject.egmParam.m3);
      this.formEditParameters.get('m4').setValue(this.egmObject.egmParam.m4);
      this.formEditParameters.get('m5').setValue(this.egmObject.egmParam.m5);
      this.formEditParameters.get('m6').setValue(this.egmObject.egmParam.m6);
      this.formEditParameters.get('m7').setValue(this.egmObject.egmParam.m7);
      this.formEditParameters.get('m8').setValue(this.egmObject.egmParam.m8);

      //Egm config
      this.formEditParameters.get('printerCashoutDevice').setValue(this.egmObject.egmConfig.printerCashoutDevice);
      this.formEditParameters
        .get('printerHandpayReceiptDevice')
        .setValue(this.egmObject.egmConfig.printerHandpayReceiptDevice);
      this.formEditParameters.get('printerRestrictedTicket').setValue(this.egmObject.egmConfig.printerRestrictedTicket);
      this.formEditParameters.get('validateHandpays').setValue(this.egmObject.egmConfig.validateHandpays);
      this.formEditParameters.get('ticketForeignRestricted').setValue(this.egmObject.egmConfig.ticketForeignRestricted);
      this.formEditParameters.get('ticketRedemption').setValue(this.egmObject.egmConfig.ticketRedemption);
      this.formEditParameters.get('legacyBonus').setValue(this.egmObject.egmConfig.legacyBonus);
      this.formEditParameters.get('jackpot').setValue(this.egmObject.egmConfig.jackpot);
      this.formEditParameters.get('aft').setValue(this.egmObject.egmConfig.aft);
      this.formEditParameters.get('aftBonusAwards').setValue(this.egmObject.egmConfig.aftBonusAwards);
      this.formEditParameters.get('tournament').setValue(this.egmObject.egmConfig.tournament);
      this.formEditParameters.get('extendedCounters').setValue(this.egmObject.egmConfig.extendedCounters);
      this.formEditParameters
        .get('validationStyle')
        .setValue(ValidationStyles[this.egmObject.egmConfig.validationStyle]);
      this.formEditParameters.get('meterModelFlagEnum').setValue(this.egmObject.egmConfig.meterModelFlagEnum);
      this.formEditParameters.get('ticketToDropCancel').setValue(this.egmObject.egmConfig.ticketToDropCancel);
      this.formEditParameters.get('multiExtensions').setValue(this.egmObject.egmConfig.multiExtensions);
      this.formEditParameters.get('maxPooling').setValue(this.egmObject.egmConfig.maxPooling);
      this.formEditParameters.get('denomination').setValue(this.egmObject.egmConfig.formattedDenomination);
      this.formEditParameters
        .get('paymentPercentage')
        .setValue(this.percentPipe.transform(this.egmObject.egmConfig.formattedPaymentPercentageProb, '2.2'));
      // this.formEditEgm.get('paymentPercentage').setValue(result.data.egmConfig.formattedPaymentPercentage, "2.2");
      this.formEditParameters.get('protocolError').setValue(this.egmObject.egmConfig.protocolError);
      this.formEditParameters.get('online').setValue(this.egmObject.egmConfig.online ? 'Si' : 'No');
      this.formEditParameters.get('sasVersion').setValue(this.egmObject.egmConfig.sasVersion);
      this.formEditParameters.get('formattedSasVersion').setValue(this.egmObject.egmConfig.formattedSasVersion);
      this.formEditParameters.get('currency').setValue(this.egmObject.egmConfig.currency);
      this.formEditParameters.get('serial').setValue(this.egmObject.egmConfig.serial);

      this.fillCombos();
    }
  }

  body: string;

  onDisabledEgmButton() {
    this.body = '¿Está seguro que desea deshabilitar la egm?';
    this.confirmModalDisabledEgm.openModal();
  }

  disabledEgm() {
    this.egmService.disabledEgm(this.egmObject).subscribe((result) => {});
  }

  onStartupEgmButton() {
    this.body = '¿Está seguro que desea enviar el comando startup?';
    this.confirmModalStartupEgm.openModal();
  }

  onLogSpiderOn() {
    this.body = '¿Está seguro que desea enviar el comando log_Spider_On?';
    this.confirmModalLogSpiderOn.openModal();
  }

  onLogSpiderOff() {
    this.body = '¿Está seguro que desea enviar el comando log_Spider_Off?';
    this.confirmModalLogSpiderOff.openModal();
  }

  startupEgm() {
    this.egmService.startUpEGM(this.egmObject).subscribe((result) => {});
  }

  logSpiderOn() {
    this.egmService.logSpiderOn(this.egmObject).subscribe((result) => {});
  }
  logSpiderOff() {
    this.egmService.logSpiderOff(this.egmObject).subscribe((result) => {});
  }

  async getEgmParam() {
    var result = await this.egmService.getEGM2(parseInt(this.activatedRoute.snapshot.params.id)).toPromise();
    this.egmObject = result.data;
  }

  fillCombos() {
    this.getCabinets();
    this.getLocations();
    this.getSpiders();
    this.getMicId();

    this.GetCounters();
  }
  getMicId() {
    this.egmService.getMicsCombo().subscribe((result) => {
      this.micIdList = result.data;
    });
  }

  GetCounters() {
    this.counterService.getCountersCombo().subscribe((result) => {
      this.agrupadorJugadoList = result.data;
      this.agrupadorPremiadoList = result.data;
      this.agrupadorJugadoFichasList = result.data;
      this.agrupadorPremiadoFichasList = result.data;
      this.agrupadorPagoManualList = result.data;
    });
  }

  getLocations() {
    this.LocationService.getLocationsCombo().subscribe((result) => {
      this.locationList = result.data;
    });
  }

  getCabinets() {
    this.cabinetService.getCabinetsCombo().subscribe((result) => {
      this.cabinetList = result.data;
    });
  }

  async changeManufacturer() {
    this.formEditEgm.get('modelId').setValue(null);
    this.formEditEgm.get('gameTypeId').setValue(null);
    await this.getModel(false);
  }

  async changeModel() {
    this.formEditEgm.get('gameTypeId').setValue(null);
    await this.getGames(false);
  }

  async getManufacturers(initForm: boolean) {
    await this.manufacturerCombo.fillCombo();
    if (this.isEditEgm && initForm) {
      this.formEditEgm.get('manufacturerId').setValue(this.egm.manufacturerId);
    }
    await this.getModel(initForm);
  }

  async getModel(initForm: boolean) {
    await this.modelsCombo.fillComboWithManufacturerId(this.formEditEgm.get('manufacturerId').value);
    if (this.isEditEgm && initForm) {
      this.formEditEgm.get('modelId').setValue(this.egm.modelId);
    }
    await this.getGames(initForm);
  }

  async getGames(initForm: boolean) {
    await this.gametypesCombo.fillComboWithModelId(this.formEditEgm.get('modelId').value);
    if (this.isEditEgm && !initForm) {
      this.formEditEgm.get('gameTypeId').setValue(this.egm.gameTypeId);
    }
  }

  getSpiders() {
    if (this.idReportEgm != null) {
      this.SpiderService.getUnusedSpidersCombo(this.idReportEgm).subscribe((result) => {
        this.spiderList = result.data;
      });
    }
    if (this.activatedRoute.snapshot.params.id != null) {
      this.SpiderService.getUnusedSpidersCombo(parseInt(this.activatedRoute.snapshot.params.id)).subscribe((result) => {
        this.spiderList = result.data;
      });
    } else {
      this.SpiderService.getUnusedSpidersCombo(null).subscribe((result) => {
        this.spiderList = result.data;
      });
    }
  }

  initFormParameters() {
    this.formEditParameters = new FormGroup({
      egmId: new FormControl(null),
      acceptsCoins: new FormControl(false),
      acceptsHopper: new FormControl(false),

      ticketCashIn: new FormControl(false),
      ticketCashOut: new FormControl(false),
      ticketPromoRestrictedIn: new FormControl(false),
      ticketPromoRestrictedOut: new FormControl(false),
      ticketPromoNonRestrictedIn: new FormControl(false),
      handpayOut: new FormControl(false),
      foreignRestrictedOut: new FormControl(false),

      aftIn: new FormControl(false),
      aftOut: new FormControl(false),
      validateHandpay: new FormControl(false),

      printerCashoutDevice: new FormControl({ value: false, disabled: true }),
      printerRestrictedTicket: new FormControl({ value: false, disabled: true }),
      printerHandpayReceiptDevice: new FormControl({ value: false, disabled: true }),
      validateHandpays: new FormControl({ value: false, disabled: true }),
      ticketForeignRestricted: new FormControl({ value: false, disabled: true }),
      ticketRedemption: new FormControl({ value: false, disabled: true }),
      legacyBonus: new FormControl({ value: false, disabled: true }),
      jackpot: new FormControl({ value: false, disabled: true }),
      aft: new FormControl({ value: false, disabled: true }),
      aftBonusAwards: new FormControl({ value: false, disabled: true }),
      tournament: new FormControl({ value: false, disabled: true }),
      extendedCounters: new FormControl({ value: false, disabled: true }),
      validationStyle: new FormControl({ value: false, disabled: true }),
      meterModelFlagEnum: new FormControl({ value: false, disabled: true }),
      ticketToDropCancel: new FormControl({ value: false, disabled: true }),
      multiExtensions: new FormControl({ value: false, disabled: true }),
      maxPooling: new FormControl({ value: false, disabled: true }),
      denomination: new FormControl({ value: false, disabled: true }),
      paymentPercentage: new FormControl({ value: false, disabled: true }),
      protocolError: new FormControl({ value: false, disabled: true }),
      online: new FormControl({ value: false, disabled: true }),
      sasVersion: new FormControl({ value: false, disabled: true }),
      formattedSasVersion: new FormControl({ value: false, disabled: true }),
      currency: new FormControl({ value: false, disabled: true }),
      serial: new FormControl({ value: false, disabled: true }),

      m1: new FormControl(null, Validators.pattern('[0-9]*')),
      m2: new FormControl(null, Validators.pattern('[0-9]*')),
      m3: new FormControl(null, Validators.pattern('[0-9]*')),
      m4: new FormControl(null, Validators.pattern('[0-9]*')),
      m5: new FormControl(null, Validators.pattern('[0-9]*')),
      m6: new FormControl(null, Validators.pattern('[0-9]*')),
      m7: new FormControl(null, Validators.pattern('[0-9]*')),
      m8: new FormControl(null, Validators.pattern('[0-9]*')),
    });
  }

  initFormEdit() {
    this.formEditEgm = new FormGroup({
      id: new FormControl(0),
      manufacturerId: new FormControl(null, [Validators.required]),
      micId: new FormControl(null, [Validators.required]),
      agrupadorJugadoFichasCounterIds: new FormControl([]),
      agrupadorJugadoCounterIds: new FormControl([]),
      agrupadorPagoManualCounterIds: new FormControl([]),
      agrupadorPremiadoFichasCounterIds: new FormControl([]),
      agrupadorPremiadoCounterIds: new FormControl([]),

      spiderId: new FormControl(null, Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())),
      cabinetId: new FormControl(null),
      modelId: new FormControl(null, [Validators.required]),
      locationId: new FormControl(null, [
        Validators.pattern('[0-9]*'),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      ticketExpiration: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      gameTypeId: new FormControl(null, [Validators.required]),
      roomId: new FormControl(null, [Validators.required]),
      paymentPercentage: new FormControl({ value: null, disabled: true }),
      uid: new FormControl(null, [
        Validators.pattern('[0-9]*'),
        Validators.max(65535),
        Validators.min(1),
        Validators.required,
      ]),
      currency: new FormControl({ value: '$ (ARS)', disabled: true }),

      //es Grupo EGMs. Valor crédito se llamaba antes y no se renombra
      creditValue: new FormControl(null, [
        Validators.required,
        Validators.max(this.ValidationService.getMaxLenghtIntAllowed()),
      ]),

      formattedDepositInitial: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),

      ticketLimit: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.max(this.ValidationService.getMaxLenghtIntAllowed()),
      ]),
      allowTicketInFromCashRegister: new FormControl(false),
      allowTicketInFromCashMachine: new FormControl(false),
      denomination: new FormControl({ value: 0.01, disabled: true }),
      status: new FormControl(false),
      softwareVersion: new FormControl(null, [Validators.required]),
      nickName: new FormControl(null, [Validators.required]),
      alias: new FormControl(null),
      sasVersion: new FormControl({ value: null, disabled: true }),
      formattedSasVersion: new FormControl({ value: null, disabled: true }),
      mac: new FormControl({ value: null, disabled: true }),
      online: new FormControl({ value: 'No', disabled: true }),
      date: new FormControl(moment()),
      observations: new FormControl(),
    });
  }
}
