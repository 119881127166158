import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters, DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { PrintableTickets } from 'src/app/common/components/viewmodels/PrintableTickets';
import {
  CashFlowViewModel,
  SessionManagmentViewModel,
  ButtonMovementTypeEnum,
  SessionMovementViewModel,
  SettlementViewModel,
  EgmActionsViewModel,
  SessionStokEgmViewModel,
  CollectionViewModel,
  CollectionFooterViewModel,
  CashFlowViewModelFooter,
  CloseCollectionState,
  TurnDetailViewModel,
} from 'src/app/common/components/viewmodels/SessioModel';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends baseService {
  private currentMovement?: ButtonMovementTypeEnum;

  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public GetEgmToAction(
    dataTablesParameters: DataTableParameters
  ): Observable<ApiResult<DataTableResult<EgmActionsViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<EgmActionsViewModel>>>(
        `${this.baseUrl}Sessions/GetEgmToAction`,
        dataTablesParameters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<EgmActionsViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<EgmActionsViewModel>>(error))
      );
  }

  public GetCashFlow(filters: DatatableMultiFilters): Observable<ApiResult<DataTableResult<CashFlowViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<CashFlowViewModel>>>(`${this.baseUrl}Sessions/GetCashFlow?filters`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CashFlowViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CashFlowViewModel>>(error))
      );
  }

  public GetReconciliateItems(
    filters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<CashFlowViewModel, CashFlowViewModelFooter>>> {
    return this.http
      .post<ApiResult<DataTableResult<CashFlowViewModel, CashFlowViewModelFooter>>>(
        `${this.baseUrl}Sessions/GetReconciliateItems`,
        filters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CashFlowViewModel, CashFlowViewModelFooter>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CashFlowViewModel, CashFlowViewModelFooter>>(error))
      );
  }
  public GetSessionMovementsItems(
    filters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<CashFlowViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<CashFlowViewModel>>>(`${this.baseUrl}Sessions/GetSessionMovementsItems`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CashFlowViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CashFlowViewModel>>(error))
      );
  }

  public GetTurnDetail(filters: DatatableMultiFilters): Observable<ApiResult<DataTableResult<TurnDetailViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<TurnDetailViewModel>>>(`${this.baseUrl}Sessions/GetTurnDetail`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TurnDetailViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TurnDetailViewModel>>(error))
      );
  }

  public GetCollectionReport(
    filters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<CollectionViewModel, CollectionFooterViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<CollectionViewModel>>>(`${this.baseUrl}Sessions/GetCollectionReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CollectionViewModel, CollectionFooterViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CollectionViewModel, CollectionFooterViewModel>>(error))
      );
  }
  public GetCashCountReport(
    filters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<CollectionViewModel, CollectionFooterViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<CollectionViewModel>>>(`${this.baseUrl}Sessions/GetCashCountReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CollectionViewModel, CollectionFooterViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CollectionViewModel, CollectionFooterViewModel>>(error))
      );
  }

  public GetSessionByCashRegisterId(cashRegisterId: number): Observable<ApiResult<SessionManagmentViewModel>> {
    return this.http
      .get<ApiResult<SessionManagmentViewModel>>(
        `${this.baseUrl}Sessions/GetSessionByCashRegisterId?cashRegisterId=${cashRegisterId}`
      )
      .pipe(
        map((x) => this.checkResult<SessionManagmentViewModel>(x)),
        catchError((error) => this.handleError<SessionManagmentViewModel>(error))
      );
  }
  public GetSessionByTurnId(cashRegisterId: number): Observable<ApiResult<SessionManagmentViewModel>> {
    return this.http
      .get<ApiResult<SessionManagmentViewModel>>(`${this.baseUrl}Sessions/GetSessionByTurnId?turnId=${cashRegisterId}`)
      .pipe(
        map((x) => this.checkResult<SessionManagmentViewModel>(x)),
        catchError((error) => this.handleError<SessionManagmentViewModel>(error))
      );
  }
  public GetCollection(egmId: number): Observable<ApiResult<CollectionViewModel>> {
    var postDataCollect = {
      egmId: egmId,
    };

    return this.http
      .post<ApiResult<CollectionViewModel>>(`${this.baseUrl}Sessions/GetCollection`, postDataCollect)
      .pipe(
        map((x) => {
          var result = this.checkResult<CollectionViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<CollectionViewModel>(error))
      );
  }
  public PerfomCollection(data: CollectionViewModel): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Sessions/PerfomCollection`, data).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getTurnCombo(onlyOpen: boolean): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Sessions/GetTurnsCombo?onlyOpen=${onlyOpen}`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public GetCurrencyTypeCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Sessions/GetCurrencyTypeCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public GetMovimentTypeCombo(onlyReconciliation: boolean): Observable<ApiResult<IdTextModel[]>> {
    return this.http
      .get<ApiResult<IdTextModel[]>>(
        `${this.baseUrl}Sessions/GetMovimentTypeCombo?onlyReconciliation=${onlyReconciliation}`
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<IdTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<IdTextModel[]>(error))
      );
  }

  public GetSettlementCloseByTurnId(turnId: number): Observable<ApiResult<SettlementViewModel>> {
    return this.http
      .get<ApiResult<SettlementViewModel>>(`${this.baseUrl}Sessions/getSettlementCloseByTurnId?turnId=${turnId}`)
      .pipe(
        map((x) => this.checkResult<SettlementViewModel>(x)),
        catchError((error) => this.handleError<SettlementViewModel>(error))
      );
  }
  public GetSessionSettlement(cashRegisterId: number): Observable<ApiResult<SettlementViewModel>> {
    return this.http
      .get<ApiResult<SettlementViewModel>>(
        `${this.baseUrl}Sessions/getSessionSettlement?cashRegisterId=${cashRegisterId}`
      )
      .pipe(
        map((x) => this.checkResult<SettlementViewModel>(x)),
        catchError((error) => this.handleError<SettlementViewModel>(error))
      );
  }

  public StartSession(cashRegisterId: number): Observable<ApiResult<SessionManagmentViewModel>> {
    var postData = {
      cashRegisterId: cashRegisterId,
    };

    return this.http.post<ApiResult<SessionManagmentViewModel>>(`${this.baseUrl}Sessions/StartSession`, postData).pipe(
      map((x) => {
        var result = this.checkResult<SessionManagmentViewModel>(x);
        return result;
      }),
      catchError((error) => this.handleError<SessionManagmentViewModel>(error))
    );
  }

  public EndSession(cashRegisterId: number): Observable<ApiResult<SessionManagmentViewModel>> {
    var postData = {
      cashRegisterId: cashRegisterId,
    };

    return this.http.post<ApiResult<SessionManagmentViewModel>>(`${this.baseUrl}Sessions/EndSession`, postData).pipe(
      map((x) => {
        var result = this.checkResult<SessionManagmentViewModel>(x);
        return result;
      }),
      catchError((error) => this.handleError<SessionManagmentViewModel>(error))
    );
  }

  public ReconciliateItem(movementId: number, turnId: number): Observable<ApiResult<string>> {
    var postData = {
      movementId: movementId,
      turnId: turnId,
    };

    return this.http.post<ApiResult<string>>(`${this.baseUrl}Sessions/ReconciliateItem`, postData).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public ReconciliateItemSearch(
    listSearch: number[],
    currentTurnId: number
  ): Observable<ApiResult<DataTableResult<string>>> {
    var reconciliatePostData = {
      listSearch: listSearch,
      currentTurnId: currentTurnId,
    };
    return this.http
      .post<ApiResult<DataTableResult<string>>>(`${this.baseUrl}Sessions/ReconciliateItemSearch`, reconciliatePostData)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<string>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<string>>(error))
      );
  }

  public CloseCollection(): Observable<ApiResult<DataTableResult<string>>> {
    return this.http.post<ApiResult<DataTableResult<string>>>(`${this.baseUrl}Sessions/CloseCollection`, {}).pipe(
      map((x) => {
        var result = this.checkResult<DataTableResult<string>>(x);
        return result;
      }),
      catchError((error) => this.handleError<DataTableResult<string>>(error))
    );
  }

  public getCloseCollectionState(): Observable<ApiResult<CloseCollectionState>> {
    return this.http.get<ApiResult<CloseCollectionState>>(`${this.baseUrl}Sessions/getCloseCollectionState`).pipe(
      map((x) => this.checkResult<CloseCollectionState>(x)),
      catchError((error) => this.handleError<CloseCollectionState>(error))
    );
  }

  public EndTurn(settlementModel: SettlementViewModel): Observable<ApiResult<SessionManagmentViewModel>> {
    return this.http
      .post<ApiResult<SessionManagmentViewModel>>(`${this.baseUrl}Sessions/EndTurn`, settlementModel)
      .pipe(
        map((x) => {
          var result = this.checkResult<SessionManagmentViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<SessionManagmentViewModel>(error))
      );
  }
  public StartTurn(settlementModel: SettlementViewModel): Observable<ApiResult<SessionManagmentViewModel>> {
    return this.http
      .post<ApiResult<SessionManagmentViewModel>>(`${this.baseUrl}Sessions/StartTurn`, settlementModel)
      .pipe(
        map((x) => {
          var result = this.checkResult<SessionManagmentViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<SessionManagmentViewModel>(error))
      );
  }

  public SessionMovementGenerateTicket(ticket: SessionMovementViewModel): Observable<ApiResult<PrintableTickets>> {
    return this.http
      .post<ApiResult<PrintableTickets>>(`${this.baseUrl}Sessions/SessionMovementGenerateTicket`, ticket)
      .pipe(
        map((x) => this.checkResult<PrintableTickets>(x)),
        catchError((error) => this.handleError<PrintableTickets>(error))
      );
  }

  public StockEgmRetirar(model: SessionStokEgmViewModel): Observable<ApiResult<PrintableTickets>> {
    return this.http.post<ApiResult<PrintableTickets>>(`${this.baseUrl}Sessions/StockEgmRetirar`, model).pipe(
      map((x) => this.checkResult<PrintableTickets>(x)),
      catchError((error) => this.handleError<PrintableTickets>(error))
    );
  }

  public StockEgmReponer(model: SessionStokEgmViewModel): Observable<ApiResult<PrintableTickets>> {
    return this.http.post<ApiResult<PrintableTickets>>(`${this.baseUrl}Sessions/StockEgmReponer`, model).pipe(
      map((x) => this.checkResult<PrintableTickets>(x)),
      catchError((error) => this.handleError<PrintableTickets>(error))
    );
  }

  public SessionMovement(model: SessionMovementViewModel): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Sessions/SessionMovement`, model).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public SessionMovemenCashflow(model: SessionMovementViewModel): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Sessions/SessionMovementCashflow`, model).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  getPageTitle(session: SessionManagmentViewModel): string {
    let message = `Sesión no iniciada`;

    if (session.isTurnStarted) {
      message = `Caja:${session.cashRegisterCuid}-${session.cashRegisterName} -  Sesión Iniciada el ${moment(
        session.sessionStartDate
      ).format('DD/MM/yyyy, HH:mm')} por ${session.sessionStartUser} - Turno Iniciado el ${moment(
        session.turnStartDate
      ).format('DD/MM/yyyy, HH:mm')}
      por ${session.turnStartUser} `;
    } else if (session.isSessionStarted) {
      message = `${session.cashRegisterName} - Sesión Iniciada el ${moment(session.sessionStartDate).format(
        'DD/MM/yyyy, HH:mm'
      )} por ${session.sessionStartUser}`;
    }
    return message;
  }

  public get CurrentMovement(): ButtonMovementTypeEnum {
    return this.currentMovement;
  }
  public set CurrentMovement(value: ButtonMovementTypeEnum) {
    this.currentMovement = value;
  }
  public CleanCurrentMovement() {
    this.currentMovement = null;
  }
}
