import { Cabinet } from './Cabinet';
import { EgmConfig } from './EgmConfig';
import { EgmParam, EgmStatus } from './EgmParam';
import { EGMHostStatus, StateEgm, ValidationStyles } from './Enums';
import { GameType } from './GameType';
import { Location } from './Location';
import { Manufacturer } from './Manufacturer';
import { Model } from './Model';
import { Room } from './Room';
import { Spider } from './Spider';

export class EGMMock {
  id: number;
  uid: number;
  uidFormatted: string;
  cabinet: Cabinet;
  cabinetId: number;
  model: Model;
  modelId: string;
  manufacturer: Manufacturer;
  manufacturerId: number;
  micId: number;

  agrupadorJugadoFichasCounterIds: number[];
  agrupadorJugadoCounterIds: number[];
  agrupadorPagoManualCouterIds: number[];
  agrupadorPremiadoFichasCounterIds: number[];
  agrupadorPremiadoCounterIds: number[];

  room: Room;
  roomId: number;
  location?: Location;
  locationId: number;
  gameType: GameType;
  gameTypeId: number;
  spider?: Spider;
  spiderId: number;

  smib: number;
  softwareVersion: string;
  status: boolean;
  date: moment.Moment;
  ticketExpiration: number;

  depositInitial?: number;
  formattedDepositInitial?: number;

  creditValue: string;

  ticketLimit: number;
  allowTicketInFromCashMachine: boolean;
  allowTicketInFromCashRegister: boolean;
  eliminationDate?: moment.Moment;
  egmConfig: EgmConfig;
  egmParam: EgmParam;

  statusEgm: StateEgm;
  lastEvent: string;
  nickName: string;
  observations: string;
  alias: string;

  public constructor(init?: Partial<EGMMock>) {
    Object.assign(this, init);
  }
}
