import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { ConfirmButtonsComponent } from 'src/app/common/components/shared/confirm-buttons/confirm-buttons.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { CountersMock } from 'src/app/common/components/viewmodels/CountersMock';
import { CountersService } from 'src/app/services/counters/counters.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { EditCountersParameters } from '../detailed-counters-info/detailed-counters-info.component';

@Component({
  selector: 'app-edit-counters',
  templateUrl: './edit-counters.component.html',
  styleUrls: ['./edit-counters.component.css'],
})
export class EditCountersComponent implements OnInit {
  formEdit: FormGroup;
  titlePage: string = 'Modificación Contadores';
  title: string = 'Modificación Contadores';
  egmId: number;
  uid: number;
  CanEdit: boolean = true;
  @ViewChild(ConfirmButtonsComponent, { static: false }) confirmButton: ConfirmButtonsComponent;

  counters: CountersMock = new CountersMock();

  InputType = InputTypeEnum;

  items: Array<BreadcrumbItem> = [];
  parameter: EditCountersParameters = null;
  countersId: number;

  public static getName() {
    return 'EditCountersComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<EditCountersParameters>,
    public CounterService: CountersService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Contadores'));
    this.items.push(new BreadcrumbItem('/audit/edit', this.title));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();

    this.parameter = this.paramService.getAndDeleteParameters();

    if (this.activatedRoute.snapshot.params.id != null) {
      this.countersId = parseInt(this.activatedRoute.snapshot.params.id);
      this.CounterService.getEditCounters(parseInt(this.activatedRoute.snapshot.params.id)).subscribe((result) => {
        this.formEdit.patchValue(result.data.counters);
        this.CanEdit = result.data.canEdit;
        this.counters = result.data.counters;
        if (!this.CanEdit) {
          this.confirmButton.disableYes();
          this.notificationService.showWarning(
            'No es posible editar. El periodo está cerrado o ya forma parte de una recaudación',
            'Atención!'
          );
        }
      });

      this.title = 'Modificación Contadores';
    }
    if (this.activatedRoute.snapshot.params.uid != null) {
      this.uid = parseInt(this.activatedRoute.snapshot.params.uid);
    }
    if (this.parameter != null) {
      if (this.parameter.uid != null) {
        this.egmId = this.parameter.uid;
        this.title = 'Modificación Contadores Máquina ' + this.uid.toString();
      }
    }
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['/audit/edit']);
  }

  onSubmit() {
    if (this.formEdit.valid) {
      this.counters = new CountersMock(this.formEdit.value);
      this.counters.egmId = this.egmId;

      this.paramService.saveParameters(this.parameter);
      this.CounterService.editCounters(this.counters).subscribe((result) => {
        if (result.error == null) {
          this.notificationService.showSuccess('Contadores editados con éxito', 'Operación realizada');
          this.router.navigate(['/audit/edit']);
        } else {
          this.notificationService.showError('Error al editar los contadores.', 'Error');
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(),
      coinIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      coinOut: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      jackpot: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      totalDrop: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      cancelledCredits: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      handpayCC: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billOut: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketCashIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketCashInQ: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketCashOut: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketCashOutQ: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      coinDrop: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      trueCoinIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      trueCoinOut: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      eftIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      aftIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      aftOut: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      gamesPlayed: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      gamesWon: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      gamesLost: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM1Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM2Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM3Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM4Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM5Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM6Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM7Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billM8Q: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billToDrop: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      billToRecycle: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketRPIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      // 'ticketPromInQ': new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketRPromInQ: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketNoRestPromIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketNoRestPromInQ: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketRestPromOut: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      ticketRestPromOutQ: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      mppp: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      mpebp: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      appp: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      apebp: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      doorQ: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      powerReset: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),

      totalTicketIn: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      totalTicketOut: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      mptp: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      aptp: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),

      gamesSincePowerUp: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      gamesSinceDoorClosed: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      currentHopperLevel: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      totalHandPaidCredits: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved1: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved2: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved3: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved4: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved5: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved6: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved7: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved8: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved9: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved10: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved11: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved12: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved13: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved14: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved15: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
      reserved16: new FormControl(0, [Validators.pattern('[0-9]*'), Validators.maxLength(10)]),
    });
  }
}
