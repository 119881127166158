import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.css'],
})
export class CashoutComponent implements OnInit {
  title: string = 'Abonar Ticket';
  idTurn: number = 0;
  items: Array<BreadcrumbItem> = [];
  loadPage: boolean = false;

  public static getName() {
    return 'CashoutComponent';
  }
  constructor(
    private router: Router,

    public sessionService: SessionService,
    public notificationService: NotificationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', this.title));

    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.idTurn = Number(paramTurnsId);
      if (isNaN(this.idTurn)) {
        this.goToErrorPage();
      }
    }
  }

  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }
}
