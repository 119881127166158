import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';

@Injectable({
  providedIn: 'root',
})
export class TicketValuesService {
  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string) {}

  public getValues(): Array<IdTextModel> {
    let array: Array<IdTextModel> = new Array<IdTextModel>();

    array.push(new IdTextModel(50, '50'));
    array.push(new IdTextModel(100, '100'));
    array.push(new IdTextModel(200, '200'));
    array.push(new IdTextModel(1000, '1000'));

    return array;
  }
}
