import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DaterangepickerService } from 'src/app/services/daterangepicker/daterangepicker.service';
import { DatePickerConfig } from '../../viewmodels/DatePickerConfig';
import * as moment from 'moment-timezone';
//import * as dayjs from 'dayjs';
import dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-common-daterangepicker',
  templateUrl: './common-daterangepicker-material.component.html',
  styleUrls: ['./common-daterangepicker-material.component.css'],
})
export class CommonDaterangepickerMaterialComponent implements OnInit {
  @Input() controlFrom: FormControl;
  @Input() controlTo: FormControl;
  @Input() label: string;
  @Input() showTime: boolean = true;
  @Input() singleDate: boolean = false;
  @Input() empty: boolean = false;

  timezone: string = '';

  constructor(private daterangepickerService: DaterangepickerService, public casinoService: CasinoService) {
    dayjs.extend(timezone);
    dayjs.extend(utc);

    // https://fetrarij.github.io/ngx-daterangepicker-material/simple
  }

  ngModelChange($event: any) {
    if ($event.startDate == null || $event.endDate == null) {
      return;
    }

    let startIso = $event.startDate;
    let endIso = $event.endDate;

    //la primera vez no viene un dayjs
    if (dayjs.isDayjs($event.startDate)) {
      startIso = $event.startDate.toISOString().slice(0, -1);
      endIso = $event.endDate.toISOString().slice(0, -1);
    }

    if (typeof $event.startDate == 'object') {
      startIso = $event.startDate.toISOString().slice(0, -1);
      endIso = $event.endDate.toISOString().slice(0, -1);
    }

    let start = dayjs(startIso).tz(this.timezone, true);
    let end = dayjs(endIso).tz(this.timezone, true);

    if (!this.showTime) {
      let newEnd = end.toDate();
      // newEnd.setHours(0);
      newEnd.setHours(0);
      this.controlTo.setValue(newEnd.toISOString());
    } else {
      this.controlTo.setValue(end.toISOString());
    }
    this.controlFrom.setValue(start.toISOString());
  }

  isLoaded: boolean = false;
  ranges: any = {};
  locale: any;
  maxDate: any;

  selected: any = {};
  datePickerConfig: DatePickerConfig;

  public async refreshData() {
    var result = await this.daterangepickerService.getData().toPromise();
    await this.fillData(result.data);

    /**luego de cargar el combo, le tengo q dar tiempo al metodo ngModelChange  */
    await timer(1000).pipe(take(1)).toPromise();
  }

  async ngOnInit(): Promise<void> {
    this.casinoService.getTimeZone().subscribe((result) => {
      if (result?.data?.val) {
        this.timezone = result.data.val;

        this.daterangepickerService.getData().subscribe(async (result) => {
          await this.fillData(result.data, this.empty);
        });
      }
    });
  }

  public datesUpdated(event: any): void {
    if (event.startDate == null || event.endDate == null) {
      // this.selected = null;
      this.controlFrom.setValue(null);
      this.controlTo.setValue(null);
    }
  }

  async fillData(result: DatePickerConfig, emptyResults: boolean = false) {
    this.datePickerConfig = result;

    if (!emptyResults) {
      this.selected = {
        startDate: this.datePickerConfig.lastCut,
        endDate: this.datePickerConfig.now,
      };

      this.controlFrom.setValue(this.datePickerConfig.lastCut);
      this.controlTo.setValue(this.datePickerConfig.now);
    } else {
      this.selected = null;
    }

    //no lo dejo x afuera setear el showtime, ya que hay un bug, que cuando cambio el dia, deberia posicionarme en la hora
    //de corte y lo estoy posicionando en la hora del dia actual

    let displayFormat = this.showTime
      ? this.datePickerConfig.locale.momentFormat
      : this.datePickerConfig.locale.momentFormat.split(' ')[0];

    let format = 'YYYY-MM-DDTHH:mm:ss';
    this.ranges = {
      Hoy: [dayjs(this.datePickerConfig.lastCut), dayjs(this.datePickerConfig.now)],
      Ayer: [dayjs(this.datePickerConfig.yesterday), dayjs(this.datePickerConfig.lastCut)],
      'Últimos 7 días': [dayjs(this.datePickerConfig.lastWeek), dayjs(this.datePickerConfig.lastCut)],
      'Últimos 30 días': [dayjs(this.datePickerConfig.lastMonth), dayjs(this.datePickerConfig.lastCut)],
    };

    this.maxDate = this.datePickerConfig.maxDate;
    this.locale = {
      format: format,
      displayFormat: displayFormat,
      weekLabel: 'S',
      separator: ' - ', // default is ' - '
      cancelLabel: 'Cancelar', // detault is 'Cancel'
      applyLabel: 'Aplicar', // detault is 'Apply'
      clearLabel: 'Limpiar', // detault is 'Clear'
      customRangeLabel: 'Rangos',
      monthNames: this.datePickerConfig.locale.monthNames,
      daysOfWeek: this.datePickerConfig.locale.daysOfWeek,
      firstDay: 0, // first day is monday
    };

    //     maxDate: this.DatePickerConfig.maxDate.format(format),

    //   var format = this.showTime
    //     ? this.DatePickerConfig.locale.momentFormat
    //     : this.DatePickerConfig.locale.momentFormat.split(' ')[0];

    //   // DateTime Format https://www.c-sharpcorner.com/blogs/date-and-time-format-in-c-sharp-programming1
    //   // Moment Format https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
    //   this.options = {
    //     autoApply: true,
    //     maxDate: this.DatePickerConfig.maxDate.format(format),
    //     startDate: moment(this.controlFrom.value),
    //     endDate: moment(this.controlTo.value),
    //     locale: {
    //       format: format,
    //       monthsNames: this.DatePickerConfig.locale.monthsNames,
    //       daysOfWeek: this.DatePickerConfig.locale.daysOfWeek,
    //     },
    //     singleDatePicker: this.singleDate,
    //     alwaysShowCalendars: true,
    //     timePicker: this.showTime,
    //     timePicker24Hour: this.DatePickerConfig.locale.is24Hour,
    //     ranges: {
    //       Hoy: [this.DatePickerConfig.lastCut, this.DatePickerConfig.now],
    //       Ayer: [this.DatePickerConfig.yesterday, this.DatePickerConfig.lastCut],
    //       'Últimos 7 días': [this.DatePickerConfig.lastWeek, this.DatePickerConfig.lastCut],
    //       'Últimos 30 días': [this.DatePickerConfig.lastMonth, this.DatePickerConfig.lastCut],
    //     },
    //     showCustomRangeLabel: false,
    //   };

    this.isLoaded = true;
  }
}
