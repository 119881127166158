import { Component, Input, OnInit } from '@angular/core';
import { PrinterService } from 'src/app/services/printer/printer.service';

@Component({
  selector: 'app-ticket-printer-status',
  templateUrl: './ticket-printer-status.component.html',
  styleUrls: ['./ticket-printer-status.component.css'],
})
export class TicketPrinterStatusComponent implements OnInit {
  autoRefreshFunc: any = null;
  @Input() showFirmware: boolean = false;

  public static getName() {
    return 'TicketPrinterStatusComponent';
  }
  constructor(public PrinterService: PrinterService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.PrinterService.isBrowserSupported) this.autoRefresh();
  }

  ngOnDestroy() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);
  }

  autoRefresh() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    this.autoRefreshFunc = setTimeout(async () => {
      if (!this.PrinterService.getPrintingInternalProcessing()) await this.PrinterService.findPort(false, false);

      this.autoRefresh();
    }, 5000);
  }

  async connectCOM() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    await this.PrinterService.findPort(true, true);
    this.autoRefresh();
  }
}
