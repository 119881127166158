<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange != null"> - {{ dateRange }} </span>
              </h3>
              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [controlFrom]="this.formSearch.get('from')"
                    [showTime]="true"
                    [controlTo]="this.formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header" style="border-bottom: none">
            <h3 class="card-title">Cantidad promedio de tickets impresos fecha seleccionada</h3>
          </div>
          <div class="card-body" style="padding-left: 1.5rem; padding-right: 1.5rem; padding-top: 0rem">
            <app-common-table
              #tableSummarized
              [model]="dataSummarized"
              [excel]="true"
              title="Promedio de tickets impresos por fecha"></app-common-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Cantidad promedio de tickets impresos por día</h3>
          </div>
          <div class="card-body">
            <app-common-table
              #tableDaily
              [model]="dataDaily"
              [excel]="true"
              title="Promedio de tickets impresos por día"></app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
