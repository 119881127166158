import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { Room } from 'src/app/common/components/viewmodels/Room';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.css'],
})
export class EditRoomComponent implements OnInit {
  roomForm: FormGroup;
  title: string = 'Nueva sala';
  inputType = InputTypeEnum;
  isEdit: boolean;
  room: Room;
  items: Array<BreadcrumbItem> = [];
  parameter: tableStatus = null;
  moment: any = moment;

  public static getName() {
    return 'EditRoomComponent';
  }
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private RoomService: RoomServiceService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.parameter = this.paramService.getAndDeleteParameters();

    if (this.activatedRoute.snapshot.params.id != null) {
      this.RoomService.getRoomById(parseInt(this.activatedRoute.snapshot.params.id)).subscribe((result) => {
        this.roomForm.patchValue(result.data);
        this.roomForm.controls['roomStart'].setValue(moment(result.data.roomStart).format('YYYY-MM-DD'));
        //  this.formEdit.controls['manufacturingDate'].setValue(moment(result.data.spider.manufacturingDate).format('YYYY-MM-DD'));
      });
      this.title = 'Editar Sala (Áreas)';
      this.isEdit = true;
    }

    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('/establishment/rooms', 'Salas (Áreas)'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  onSubmit() {
    if (this.roomForm.valid) {
      this.room = new Room(this.roomForm.value);
      this.paramService.saveParameters(this.parameter);
      this.RoomService.createOrEdit(this.room).subscribe((result) => {
        this.router.navigate(['/establishment/rooms']);
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.roomForm = new FormGroup({
      id: new FormControl(0),
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      roomStart: new FormControl(null, [Validators.required]),
    });
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('establishment/rooms');
  }
}
