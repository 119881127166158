import { CashMachine } from './cashMachine';

export class CashRegister {
  id: number;
  cuid: number;
  name: string;
  secondaryName: string;
  observations: string;
  cashRegisterTypeId: number;
  cashRegisterType: CashRegisterType;
  cashMachineId?: number;
  cashMachine: CashMachine;
  friendlyCashReigsterType: string;

  public constructor(init?: Partial<CashRegister>) {
    Object.assign(this, init);
  }
}

export class CashRegisterType {
  public id: number;
  public description: string;
}
