import { EGMMock } from './EGMMock';
import { CashableType, TicketState } from './Enums';
import { Family } from './Family';
import { GenerationReason } from './GenerationReason';
import { SessionOldReport } from './SessionOldReport';
import { Ticket } from './Ticket';

import { Turn } from './TicketTracker';

export class TicketMock {
  id: number;
  validatorCode: string;
  ticketNumber: number;
  amount: number;
  formattedAmount: number;

  creationDate: moment.Moment;
  expirationDate: moment.Moment;
  family: Family;
  familyId: number;
  generationReasonId: number;
  generationReason: GenerationReason;
  cashableBy: CashableType;
  state: TicketState;
  creationEgm?: EGMMock;

  creationSupervisorUserId: string;
  userIdName: string;
  creationEgmId: number;
  observations: string;
  ticketReplacement: TicketReplacement[];
  ticketReplacementCodeValidator: string;
  creationTurn?: Turn;
  creationTurnId?: number;

  //lo uso para enviar el monto con la precisión y asi usar el modelo de ticket en la vista
  amountWithPrecisionView: number;

  authorizationCode: string;
  authorizationId: number;
  public constructor(init?: Partial<TicketMock>) {
    Object.assign(this, init);
  }
}

export class TicketReplacement {
  ticket: TicketMock;
  ticketId: number;
  replacementTicket: TicketMock;
  replacementTicketId: number;
}
