<div id="print-section">
  <div class="content" *ngIf="isEditEgm || isParametersReport">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="card">
            <form
              role="form"
              *ngIf="formEditEgm"
              [formGroup]="formEditEgm"
              (ngSubmit)="onSubmit()"
              #formObject2="ngForm">
              <div class="card-header">
                <h3 class="card-title">Datos editables</h3>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    (click)="print()"
                    data-card-widget="expand"
                    [useExistingCss]="true"
                    [printDelay]="5000"
                    [printStyle]="{ h1: { display: 'block !important' }, '.card-body': { display: 'block' } }"
                    [printTitle]="title"
                    printSectionId="print-section"
                    ngxPrint>
                    <i class="fas fa-print"></i>
                  </button>
                </div>
              </div>

              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('uid')"
                      [label]="'UID'"
                      [formSubmitted]="formObject2.submitted"
                      [type]="InputType.numeric">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('nickName')"
                      [label]="'EGM Nombre'"
                      [formSubmitted]="formObject2.submitted"
                      [type]="InputType.string">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('alias')"
                      [label]="'Alias'"
                      [formSubmitted]="formObject2.submitted"
                      [type]="InputType.string">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <common-input-select
                      label="Spider Plus"
                      [list]="spiderList"
                      formControlName="spiderId"
                      class="form-control-sm"
                      [formSubmitted]="formObject2.submitted"
                      [allowClear]="true">
                    </common-input-select>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <common-input-select
                      [label]="'MIC (Modelo de Interrogación de Contadores)'"
                      [list]="micIdList"
                      class="form-control-sm"
                      formControlName="micId"
                      [formSubmitted]="formObject2.submitted"></common-input-select>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <manufacturers-combo
                      [control]="formEditEgm.get('manufacturerId')"
                      (valueChanged)="changeManufacturer()">
                    </manufacturers-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <models-combo [control]="formEditEgm.get('modelId')" (valueChanged)="changeModel()"> </models-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <gametypes-combo [control]="formEditEgm.get('gameTypeId')"> </gametypes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <room-combo [control]="formEditEgm.get('roomId')"> </room-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <common-input-select
                      [label]="'Ubicación'"
                      [list]="locationList"
                      formControlName="locationId"
                      class="form-control-sm"
                      [formSubmitted]="formObject2.submitted">
                    </common-input-select>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('ticketExpiration')"
                      [label]="'Expiración de ticket (Para Cash y HP)'"
                      [formSubmitted]="formObject2.submitted"
                      [type]="InputType.numeric">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <common-input-select
                      [label]="'Gabinete'"
                      [list]="cabinetList"
                      class="form-control-sm"
                      formControlName="cabinetId"
                      [formSubmitted]="formObject2.submitted"></common-input-select>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('softwareVersion')"
                      [label]="'Versión de Software EGM'"
                      [formSubmitted]="formObject2.submitted"
                      [type]="InputType.text">
                    </app-common-input>
                  </div>

                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('creditValue')"
                      [label]="'Grupo EGMs'"
                      [formSubmitted]="formObject2.submitted"
                      [type]="InputType.numeric">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('formattedDepositInitial')"
                      [label]="'Deposito Inicial'"
                      [formSubmitted]="formObject2.submitted"
                      [type]="InputType.numeric">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('ticketLimit')"
                      [label]="'Importe Máximo de Ticket In (sin céntimos)'"
                      [type]="InputType.numeric">
                    </app-common-input>
                  </div>
                  <div class="form-group col-sm-6 col-xl-4"></div>
                  <div class="form-group col-sm-6 col-xl-4"></div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('allowTicketInFromCashRegister')"
                      [label]="'Permite Ticket In proveniente de Caja'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>

                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditEgm.get('allowTicketInFromCashMachine')"
                      [label]="'Permite Ticket In proveniente de Máquina de Cambio'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12">
                    <app-common-input
                      [control]="formEditEgm.get('observations')"
                      [label]="'Observaciones'"
                      [type]="InputType.textarea">
                    </app-common-input>
                  </div>
                </div>
                <hr />
                <div class="form-group row">
                  <div class="col">
                    <h3 class="card-title">Agrupadores Patrón Contable</h3>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12 col-sm-6 col-xl-6">
                    <common-input-multiselect
                      [label]="'Jugado'"
                      [list]="agrupadorJugadoList"
                      class="form-control-sm"
                      formControlName="agrupadorJugadoCounterIds"
                      [formSubmitted]="formObject2.submitted"></common-input-multiselect>
                  </div>

                  <div class="form-group col-12 col-sm-6 col-xl-6">
                    <common-input-multiselect
                      [label]="'Premiado'"
                      [list]="agrupadorPremiadoList"
                      class="form-control-sm"
                      formControlName="agrupadorPremiadoCounterIds"
                      [formSubmitted]="formObject2.submitted"></common-input-multiselect>
                  </div>

                  <div class="form-group col-12 col-sm-6 col-xl-6">
                    <common-input-multiselect
                      [label]="'Jugado Fichas'"
                      [list]="agrupadorJugadoFichasList"
                      class="form-control-sm"
                      formControlName="agrupadorJugadoFichasCounterIds"
                      [formSubmitted]="formObject2.submitted"></common-input-multiselect>
                  </div>

                  <div class="form-group col-12 col-sm-6 col-xl-6">
                    <common-input-multiselect
                      [label]="'Premiado Fichas'"
                      [list]="agrupadorPremiadoFichasList"
                      class="form-control-sm"
                      formControlName="agrupadorPremiadoFichasCounterIds"
                      [formSubmitted]="formObject2.submitted"></common-input-multiselect>
                  </div>

                  <div class="form-group col-12 col-sm-6 col-xl-6">
                    <common-input-multiselect
                      [label]="'Alerta Manual'"
                      [list]="agrupadorPagoManualList"
                      class="form-control-sm"
                      formControlName="agrupadorPagoManualCounterIds"
                      [formSubmitted]="formObject2.submitted"></common-input-multiselect>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right" *ngIf="!isParametersReport">
                <app-confirm-buttons (onCancel)="cancel()"></app-confirm-buttons>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content" *ngIf="(isEditParameters || isParametersReport) && formEditParameters">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <form
            role="form"
            class="w-100"
            style="display: contents"
            [formGroup]="formEditParameters"
            (ngSubmit)="onSubmit()"
            #formObject="ngForm">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Configuración del host</h3>
                <div class="card-tools" *ngIf="!isEditEgm && !isParametersReport">
                  <button
                    type="button"
                    class="btn btn-tool"
                    (click)="print()"
                    data-card-widget="expand"
                    [useExistingCss]="true"
                    [printDelay]="5000"
                    [printStyle]="{ h1: { display: 'block !important' }, '.card-body': { display: 'block' } }"
                    [printTitle]="title"
                    printSectionId="print-section"
                    ngxPrint>
                    <i class="fas fa-print"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <!-- <div class="form-group row">
                                <div class="col">
                                </div>
                            </div> -->
                <div class="form-row">
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketCashIn')"
                      [label]="'Ticket cash in'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketCashOut')"
                      [label]="'Ticket cash out'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketPromoRestrictedIn')"
                      [label]="'Ticket promotional restricted in'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketPromoRestrictedOut')"
                      [label]="'Ticket promotional restricted out'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketPromoNonRestrictedIn')"
                      [label]="'Ticket promotional non restricted in'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('handpayOut')"
                      [label]="'Ticket Handpay Out'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('foreignRestrictedOut')"
                      [label]="'Ticket foreign restricted out'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('validateHandpay')"
                      [label]="'Validar vouchers y recibos handpay'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('aftIn')"
                      [label]="'AFT in'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('aftOut')"
                      [label]="'AFT out'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('acceptsCoins')"
                      [label]="'Acepta monedas'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('acceptsHopper')"
                      [label]="'Acepta hopper'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                </div>
                <hr />
                <div class="form-group row">
                  <div class="col">
                    <h3 class="card-title">Características Habilitadas en la EGM</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('denomination')"
                      [label]="'Denominación del Sistema'"
                      [formSubmitted]="formObject.submitted"
                      [type]="InputType.text">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('paymentPercentage')"
                      [label]="'% de pago teórico'"
                      [type]="InputType.text">
                    </app-common-input>
                  </div>
                  <!-- <div class="form-group col-12 col-sm-6 col-xl-4">
                                    <app-common-input [control]="formEditParameters.get('currency')" [label]="'Moneda'"
                                        [type]="InputType.text">
                                    </app-common-input>
                                </div> -->
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('formattedSasVersion')"
                      [label]="'Versión SAS'"
                      [formSubmitted]="formObject.submitted"
                      [type]="InputType.text">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('serial')"
                      [label]="'Número de serie'"
                      [formSubmitted]="formObject.submitted"
                      [type]="InputType.text">
                    </app-common-input>
                  </div>
                  <!-- <div class="form-group col-12 col-sm-6 col-xl-4">
                                    <app-common-input [control]="formEditParameters.get('protocolError')"
                                        [label]="'Error de Protocolo'" [formSubmitted]="formObject.submitted"
                                        [type]="InputType.text">
                                    </app-common-input>
                                </div>
                                <div class="form-group col-12 col-sm-6 col-xl-4">
                                    <app-common-input [control]="formEditParameters.get('online')" [label]="'Online'"
                                        [formSubmitted]="formObject.submitted" [type]="InputType.text">
                                    </app-common-input>
                                </div> -->
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('printerCashoutDevice')"
                      [label]="'Printer as Cashout Device'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('printerRestrictedTicket')"
                      [label]="'Printer Restricted Ticket'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('printerHandpayReceiptDevice')"
                      [label]="'Printer as Handpay (HP) receipts device'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('validateHandpays')"
                      [label]="'Validate handpays and handpays receipts'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketForeignRestricted')"
                      [label]="'Tickets foreign restricted amounts'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketRedemption')"
                      [label]="'Ticket Redemption'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('legacyBonus')"
                      [label]="'Legacy bonus awards'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('jackpot')"
                      [label]="'Jackpot'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('aft')"
                      [label]="'AFT'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('aftBonusAwards')"
                      [label]="'AFT Bonus Awards'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('tournament')"
                      [label]="'Torneo'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>

                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('extendedCounters')"
                      [label]="'Contadores extendidos'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <!-- <app-common-input [control]="formEditParameters.get('meterModelFlag')"
                                        [label]="'Meter model flag'" [type]="InputType.checkbox">
                                    </app-common-input> -->
                    <div class="form-group col-12 col-sm-6 disabled">
                      Meter model flag: {{ formEditParameters.get('meterModelFlagEnum').value }}
                    </div>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('ticketToDropCancel')"
                      [label]="'Ticket to Total drop and total cancel credit'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('multiExtensions')"
                      [label]="'Multi denom. extensions'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-4">
                    <app-common-input
                      [control]="formEditParameters.get('maxPooling')"
                      [label]="'Maximun pooling rate'"
                      [type]="InputType.checkbox">
                    </app-common-input>
                  </div>
                  <div class="form-group col-12 col-sm-6 disabled">
                    Estilo de validación: {{ formEditParameters.get('validationStyle').value }}
                  </div>
                </div>
                <hr />
                <div class="form-group row">
                  <div class="col">
                    <h3 class="card-title">Configuración de billetero</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m1')" label="M1"> </billcodes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m2')" label="M2"> </billcodes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m3')" label="M3"> </billcodes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m4')" label="M4"> </billcodes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m5')" label="M5"> </billcodes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m6')" label="M6"> </billcodes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m7')" label="M7"> </billcodes-combo>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-xl-3">
                    <billcodes-combo [control]="formEditParameters.get('m8')" label="M8"> </billcodes-combo>
                  </div>
                </div>
                <hr />
                <div *ngIf="!isParametersReport">
                  <div class="form-group row">
                    <div class="col">
                      <h3 class="card-title">Comandos</h3>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12 col-sm-6 col-xl-3">
                      <button type="button" class="btn btn-danger" (click)="onDisabledEgmButton()">
                        Fuera de Servicio (ShutDown)
                      </button>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-xl-3">
                      <button type="button" class="btn btn-success" (click)="onStartupEgmButton()">
                        En Servicio (StartUp)
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12 col-sm-6 col-xl-3">
                      <button type="button" class="btn btn-danger" (click)="onLogSpiderOff()">Log de spider Off</button>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-xl-3">
                      <button type="button" class="btn btn-success" (click)="onLogSpiderOn()">Log de spider On</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer" *ngIf="!isParametersReport">
                <app-confirm-buttons (onCancel)="cancel()"></app-confirm-buttons>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-modal yes="Continuar" #confirmModalDisabledEgm (onConfirm)="disabledEgm()" [body]="this.body">
</app-confirm-modal>
<app-confirm-modal yes="Continuar" #confirmModalStartupEgm (onConfirm)="startupEgm()" [body]="this.body">
  <app-confirm-modal yes="Continuar" #confirmModalLogSpiderOn (onConfirm)="logSpiderOn()" [body]="this.body">
  </app-confirm-modal>
  <app-confirm-modal yes="Continuar" #confirmModalLogSpiderOff (onConfirm)="logSpiderOff()" [body]="this.body">
  </app-confirm-modal>
</app-confirm-modal>
