import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-handpay-voucher-report',
  templateUrl: './handpay-voucher-report.component.html',
  styleUrls: ['./handpay-voucher-report.component.css'],
})
export class HandpayVoucherReportComponent {
  items: Array<BreadcrumbItem> = [];
  title: string = 'Pagos Manuales';
  public static getName() {
    return 'HandpayVoucherReportComponent';
  }

  constructor(private router: Router) {}

  async ngOnInit(): Promise<void> {
    this.items.push(new BreadcrumbItem(this.router.url, 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }
}
