import * as moment from 'moment-timezone';
import { EgmStatus } from './EgmParam';
import { Room } from './Room';

export class CashMachine {
  id: number;
  muid: string;
  machineHostSerialNumber: string;
  mac: string;
  systemHostKey: string;
  description: string;

  timeOut: string;
  heartBeat: string;
  cashinLimit: string;
  name: string;
  eliminationDate: moment.Moment;
  room: Room;
  roomId: number;
  location?: Location;
  locationId: number;
  macFormatted: string;
  canEdit: boolean;
  ip: string;
  statusCashMachine: number;
  brand: number;

  user: string;
  db: string;
  psw: string;
  port: number;

  cashMachineCurrencyMapping: CashMachineCurrencyMapping[] = [];

  public constructor(init?: Partial<CashMachine>) {
    Object.assign(this, init);
  }
}

export class CashMachineConnectionViewModel {
  ip: string;
  user: string;
  psw: string;
  Db: string;
  port: number;
}

export class CashMachineCurrencyMapping {
  currencyTypeId: number;
  trayIdentifier: string;
  denomination: number;

  public constructor(currencyTypeId: number, trayIdentifier: string, denomination: number) {
    this.currencyTypeId = currencyTypeId;
    this.trayIdentifier = trayIdentifier;
    this.denomination = denomination;
  }
}
