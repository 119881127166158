import { DataTableParameters } from './apiResult';
import { MinimalFilters, MultiselectFilters } from './Filters';

export class DatatableMultiFilters {
  dataTableParameters: DataTableParameters;
  filters: MultiselectFilters;

  constructor() {
    this.dataTableParameters = new DataTableParameters();
    this.filters = new MultiselectFilters();
  }
}

export class DatatableMinimalFilters {
  dataTableParameters: DataTableParameters;
  filters: MinimalFilters;

  constructor() {
    this.dataTableParameters = new DataTableParameters();
    this.filters = new MinimalFilters();
  }
}
