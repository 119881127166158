import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forEach } from 'jszip';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { Receipt } from '../../viewmodels/Receipt';
import { TicketMock } from '../../viewmodels/TicketMock';

@Component({
  selector: 'app-receipt-modal',
  templateUrl: './receipt-modal.component.html',
  styleUrls: ['./receipt-modal.component.css'],
})
export class ReceiptModalComponent implements OnInit {
  @Input() receipt: Receipt = new Receipt();
  @Input() isCashout: boolean = false;

  @ViewChild('receiptModal') receiptModal: ElementRef;

  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  currencySym: string;
  currencyCode: string;
  ticket: TicketMock = new TicketMock();
  total: number = 0;

  constructor(public modalService: NgbModal, private CasinoService: CasinoService, public currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.CasinoService.getCasinoConfigData().subscribe((result) => {
      this.receipt.casino = result.data;
    });
  }

  openModal() {
    this.modalService.open(this.receiptModal, { ariaLabelledBy: 'receiptModal' });
  }

  confirm() {
    this.modalService.dismissAll();
    this.onConfirm.emit();
  }

  cancel() {
    this.modalService.dismissAll();
    this.total = 0;
    this.onCancel.emit();
  }

  openModalStatic() {
    this.modalService.open(this.receiptModal, { ariaLabelledBy: 'receiptModal', backdrop: 'static', keyboard: false });
    this.ticket = this.receipt.tickets[0];

    this.total = 0;
    this.receipt.tickets.forEach((element) => {
      this.total += element.formattedAmount;
    });
  }
}
