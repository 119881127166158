import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonTableComponent } from './common-table/common-table.component';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmButtonsComponent } from './confirm-buttons/confirm-buttons.component';
import { InfoDisplayComponent } from './info-display/info-display.component';
import { CommonInputComponent } from './common-input/common-input.component';
import { CommonSelectComponent } from './common-select/common-select.component';
import { CommonDaterangepickerComponent } from './common-daterangepicker/common-daterangepicker.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CommonInputSelectComponent } from './common-input-select/common-input-select.component';
import { CommonInputMultiselectComponent } from './common-input-multiselect/common-input-multiselect.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonBreadcrumbComponent } from './common-breadcrumb/common-breadcrumb.component';
import { RouterModule } from '@angular/router';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { NgxMaskModule } from 'ngx-mask';
import { AuthorizationModalComponent } from './authorization-modal/authorization-modal.component';

@NgModule({
  declarations: [
    CommonTableComponent,
    ConfirmButtonsComponent,
    InfoDisplayComponent,
    CommonInputComponent,
    CommonSelectComponent,
    CommonDaterangepickerComponent,
    ConfirmModalComponent,
    CommonInputSelectComponent,
    CommonInputMultiselectComponent,
    CommonBreadcrumbComponent,
    CommonHeaderComponent,
    AuthorizationModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    // NgSelect2Module,
    Daterangepicker,
    NgSelectModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonTableComponent,
    DataTablesModule,
    ConfirmButtonsComponent,
    InfoDisplayComponent,
    CommonInputComponent,
    CommonSelectComponent,
    CommonDaterangepickerComponent,
    ConfirmModalComponent,
    CommonInputSelectComponent,
    CommonInputMultiselectComponent,
    CommonBreadcrumbComponent,
    CommonHeaderComponent,
    AuthorizationModalComponent,
  ],
})
export class SharedModule {}
