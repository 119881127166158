import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { Counters, EGM } from 'src/app/common/components/viewmodels/EGM';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import * as moment from 'moment-timezone';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { MinimalFilters, MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { CountersStatus } from 'src/app/common/components/viewmodels/Enums';
import { CountersMock } from 'src/app/common/components/viewmodels/CountersMock';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { CountersService } from 'src/app/services/counters/counters.service';
import { CalculatedCountersViewModel } from 'src/app/common/components/viewmodels/CalculatedCountersViewModel';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

@Component({
  selector: 'app-detailed-counters-info',
  templateUrl: './detailed-counters-info.component.html',
  styleUrls: ['./detailed-counters-info.component.css'],
})
export class DetailedCountersInfoComponent implements OnInit, AfterViewInit {
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CountersMock>;

  egmCombo: Array<IdTextModel> = [];
  excelColumns: number[] = [];
  items: Array<BreadcrumbItem> = [];
  dateRange: string;
  date: moment.Moment = moment(new Date());
  public data: commonTableData<CountersMock>;
  uid: string = null;
  showTable: boolean = false;
  title: string = 'Modificación de Contadores';
  formSearch: FormGroup;
  parameter: EditCountersParameters = new EditCountersParameters();
  counterObject: CountersMock = new CountersMock();
  messageStatus: string;

  public static getName() {
    return 'DetailedCountersInfoComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    private activatedRoute: ActivatedRoute,
    private counterService: CountersService,
    private cdr: ChangeDetectorRef,
    private paramService: ParametersService<EditCountersParameters>
  ) {}

  ngAfterViewInit(): void {
    // let paramEditCounters = this.paramService.getAndDeleteParameters();
    // if (paramEditCounters != null) {
    //   this.formSearch.get('egmUid').setValue(paramEditCounters.uid);
    //   this.formSearch.get('from').setValue(paramEditCounters.from);
    //   this.formSearch.get('to').setValue(paramEditCounters.to);
    // }
  }
  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Contadores'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();
    this.initGrid();
  }
  initGrid() {
    this.data = new commonTableData();

    this.data.loadFirstTime = false;

    let paramEditCounters = this.paramService.getAndDeleteParameters();

    var filters = new MinimalFilters();

    if (paramEditCounters != null) {
      this.data.searchText = paramEditCounters.status.searchString;
      this.data.pageLength = paramEditCounters.status.pageLength;
      this.data.currentPage = paramEditCounters.status.currentPage;
      this.formSearch.get('egmUid').setValue(paramEditCounters.uid);
      this.formSearch.get('from').setValue(paramEditCounters.from);
      this.formSearch.get('to').setValue(paramEditCounters.to);

      filters.egm = this.formSearch.get('egmUid').value;
      filters.from = this.formSearch.get('from').value;
      filters.to = this.formSearch.get('to').value;

      this.data.loadFirstTime = true;
    }

    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;

    let button: commonTableCustomButton = new commonTableCustomButton();
    button.icon = 'fa-edit';
    button.columntitle = 'Editar';

    button.order = 0;
    this.data.commonTableCustomButton.push(button);
    button = new commonTableCustomButton();
    button.icon = 'fa-power-off';
    button.columntitle = 'Habilitar';
    button.order = 0;
    this.data.commonTableCustomButton.push(button);

    this.data.columns = [
      {
        data: 'countersStatus',
        title: 'Estado',
        render: function (data, type, row) {
          return CountersStatus[data];
        },
      },
      {
        data: 'countersDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'egm.uid', title: 'EGM' },
      { data: 'period', title: 'Periodo' },

      {
        data: 'isInCollection',
        title: 'Recaudación',
        render: function (data, type, row) {
          return data == true ? 'Sí' : 'No';
        },
      },

      { data: 'egm.manufacturer.name', title: 'Fabricante' },
      { data: 'egm.model.name', title: 'Modelo' },
      { data: 'egm.egmConfig.formattedDenomination', title: 'Denominación' },
      { data: 'coinIn', title: 'Coin_IN' },
      { data: 'coinOut', title: 'Coin_OUT' },
      { data: 'jackpot', title: 'Total Jackpot' },
      { data: 'totalDrop', title: 'Total Drop' },
      { data: 'cancelledCredits', title: 'Cancelled Credits' },
      { data: 'handpayCC', title: 'Handpay CC' },
      { data: 'billIn', title: 'Bill_IN' },
      { data: 'billOut', title: 'Bill_OUT' },
      { data: 'totalTicketIn', title: 'Total TCK_IN' },
      { data: 'totalTicketOut', title: 'Total TCK_OUT' },
      { data: 'coinDrop', title: 'Coin Drop' },
      { data: 'trueCoinIn', title: 'True Coin In' },
      { data: 'trueCoinOut', title: 'True Coin Out' },
      { data: 'eftIn', title: 'EFT In' },
      { data: 'aftIn', title: 'AFT In' },
      { data: 'aftOut', title: 'AFT Out' },
      { data: 'gamesPlayed', title: 'J. Jugados' },
      { data: 'gamesWon', title: 'J. Ganados' },
      { data: 'gamesLost', title: 'J. Perdidos' },
      { data: 'billM1Q', title: 'Bill M1 Q' },
      { data: 'billM2Q', title: 'Bill M2 Q' },
      { data: 'billM3Q', title: 'Bill M3 Q' },
      { data: 'billM4Q', title: 'Bill M4 Q' },
      { data: 'billM5Q', title: 'Bill M5 Q' },
      { data: 'billM6Q', title: 'Bill M6 Q' },
      { data: 'billM7Q', title: 'Bill M7 Q' },
      { data: 'billM8Q', title: 'Bill M8 Q' },
      { data: 'billToDrop', title: 'Bill To Drop' },
      { data: 'billToRecycle', title: 'Bill To Recycle' },
      { data: 'ticketCashIn', title: 'TCK_IN Cashable' },
      { data: 'ticketCashInQ', title: 'TCK_IN Cashable Q' },
      { data: 'ticketRPIn', title: 'TCK_IN Restr' },
      { data: 'ticketRPromInQ', title: 'TCK_IN Restr Q' },
      { data: 'ticketNoRestPromIn', title: 'TCK_IN No Restr' },
      { data: 'ticketNoRestPromInQ', title: 'TCK_IN No Restr Q' },
      { data: 'ticketCashOut', title: 'TCK_OUT Cashable' },
      { data: 'ticketCashOutQ', title: 'TCK_OUT Cashable Q' },
      { data: 'ticketRestPromOut', title: 'TCK_OUT Restr' },
      { data: 'ticketRestPromOutQ', title: 'TCK_OUT Restr Q' },
      { data: 'mptp', title: 'MPTP' },
      { data: 'mppp', title: 'MPPP' },
      { data: 'mpebp', title: 'MPEBP' },
      { data: 'aptp', title: 'APTP' },
      { data: 'appp', title: 'APPP' },
      { data: 'apebp', title: 'APEBP' },
      { data: 'doorQ', title: 'Door Q' },
      { data: 'powerReset', title: 'Power Reset' },

      { data: 'gamesSincePowerUp', title: 'Games Since PowerUp' },
      { data: 'gamesSinceDoorClosed', title: 'Games Since Door Closed' },
      { data: 'currentHopperLevel', title: 'Current Hopper Level' },
      { data: 'totalHandPaidCredits', title: 'Total HandPaid Credits' },
      { data: 'reserved1', title: 'Reserved1' },
      { data: 'reserved2', title: 'Reserved2' },
      { data: 'reserved3', title: 'Reserved3' },
      { data: 'reserved4', title: 'Reserved4' },
      { data: 'reserved5', title: 'Reserved5' },
      { data: 'reserved6', title: 'Reserved6' },
      { data: 'reserved7', title: 'Reserved7' },
      { data: 'reserved8', title: 'Reserved8' },
      { data: 'reserved9', title: 'Reserved9' },
      { data: 'reserved10', title: 'Reserved10' },
      { data: 'reserved11', title: 'Reserved11' },
      { data: 'reserved12', title: 'Reserved12' },
      { data: 'reserved13', title: 'Reserved13' },
      { data: 'reserved14', title: 'Reserved14' },
      { data: 'reserved15', title: 'Reserved15' },
      { data: 'reserved16', title: 'Reserved16' },
    ];

    for (let i = 2; i < this.data.columns.length + 2; i++) {
      this.excelColumns.push(i);
    }

    let service = this.counterService;

    if (paramEditCounters != null) {
      this.setGridData(filters);
    } else {
      this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CountersMock>>> {
        var DataTableFilters = new DatatableMinimalFilters();
        DataTableFilters.filters = filters;
        DataTableFilters.dataTableParameters = param;
        return service.getEmptyDataTable(DataTableFilters);
      };
    }
    //this.cdr.detectChanges();
  }

  onSearch(status: tableStatus) {
    this.parameter.status = status;
  }

  updateTable() {
    this.setGridData(this.getFilters());
    this.table.reload();
  }

  setGridData(filters: MinimalFilters): void {
    let service = this.counterService;
    this.showTable = true;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CountersMock>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;

      return service.getCountersWithMinimalFilters(DataTableFilters);
    };
    const that = this;
    this.data.rowCallback = function (row: Node, data: EGM | Object, index: number): void {
      if (data['countersStatus'] == CountersStatus.Deshabilitado) {
        that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          $($('td', row)[1]).addClass('disabled');
        });
      }
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      egmUid: new FormControl(null, Validators.required),
      from: new FormControl(),
    });
  }

  onSubmit() {
    if (this.formSearch.valid) {
      this.showTable = true;
      this.cdr.detectChanges();
      this.updateTable();
    }
  }

  getFilters() {
    var filters: MinimalFilters = new MinimalFilters();
    filters.egm = this.formSearch.get('egmUid').value;
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;
    return filters;
  }
  toggleStatus() {
    this.counterService.setCounterStatus(this.counterObject).subscribe((result) => {
      if (result.data != null) {
        // this.loadParameters();
        // this.updateTable();
        this.initGrid();
        this.updateTable();
      }
    });
    // this.notificationService.showSuccess('Se cambió el estado del contador', 'Operación exitosa');
  }
  public onCustomButton(data): void {
    this.parameter.uid = data.row.egm.id;
    this.parameter.from = this.formSearch.get('from').value;
    this.parameter.to = this.formSearch.get('to').value;
    this.paramService.saveParameters(this.parameter);

    switch (parseInt(data.indexButton)) {
      case 1:
        this.counterObject = data.row;
        this.uid = this.counterObject.egm.uid.toString();
        if (this.counterObject.countersStatus == CountersStatus.Habilitado) {
          this.messageStatus = 'deshabilitar';
        } else {
          this.messageStatus = 'habilitar';
        }
        this.confirmModal.openModal();
        break;
      case 0:
        this.router.navigate(['/audit/edit/edit-counters', Number(data.row.id), Number(data.row.egm.uid)]);
        break;
      default:
        console.log('Algo falló con los botones');
        break;
    }
  }

  get formSearchNull(): boolean {
    return this.formSearch.get('egmUid').value == null;
  }
}

export class EditCountersParameters {
  status: tableStatus;
  uid: number;
  from: string;
  to: string;
}
