import { PercentPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { ValidationStyles } from 'src/app/common/components/viewmodels/Enums';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-edit-egm-parameters',
  templateUrl: './edit-egm-parameters.component.html',
  styleUrls: ['./edit-egm-parameters.component.css'],
})
export class EditEgmParametersComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Editar Parámetros Avanzados';
  titlePage: string = 'Editar Parámetros Avanzados';
  egmObject: EGMMock = new EGMMock();

  InputType = InputTypeEnum;

  items: Array<BreadcrumbItem> = [];
  isEdit: boolean;

  parameter: tableStatus = null;

  public static getName() {
    return 'EditEgmParametersComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private percentPipe: PercentPipe,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    public EgmService: EgmServiceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('/machines/egms', 'EGMs'));
    this.items.push(new BreadcrumbItem('', this.titlePage));

    await this.getEgmParam();
    this.titlePage = this.titlePage.concat(' - ', this.egmObject.uid.toString());
  }

  async getEgmParam() {
    var result = await this.EgmService.getEGM2(parseInt(this.activatedRoute.snapshot.params.id)).toPromise();

    this.egmObject = result.data;
  }
}
