import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersService } from 'src/app/services/users/users.service';
import { IdTextModel } from '../../shared/IdTextModel';

@Component({
  selector: 'users-combo',
  templateUrl: './users-combo.component.html',
  styleUrls: ['./users-combo.component.css'],
})
export class UsersComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() allowClear: boolean = false;
  @Input() multiselect: boolean = false;
  @Input() label: string = 'Usuario';
  combo: Array<IdTextModel> = [];
  @Output() valueChanged = new EventEmitter();

  constructor(private UsersService: UsersService) {}

  ngOnInit(): void {
    this.fillCombo();
    if (this.multiselect && this.label === 'Usuario') this.label = 'Usuarios';
  }

  private fillCombo() {
    this.UsersService.getUsersCombo().subscribe((result) => {
      this.combo = result.data;
    });
  }

  public getCombo() {
    return this.combo;
  }
}
