import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-visualize-dashboard',
  templateUrl: './visualize-dashboard.component.html',
  styleUrls: ['./visualize-dashboard.component.css'],
})
export class VisualizeDashboardComponent {
  // items: Array<BreadcrumbItem> = [];
  // title: string = "Visualización";

  public static getName() {
    return 'VisualizeDashboardComponent';
  }
  constructor(private router: Router) {}
}
