<div class="row" *ngIf="loadData">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">
          <span> {{ headerTitle }}</span>
        </h3>
        <!-- <div class="card-tools">
          <button #myCollapse type="button" class="btn btn-tool" data-card-widget="collapse">
            <i id="toolBtn" class="fas fa-minus"></i>
          </button>
        </div> -->
      </div>
      <form
        role="form"
        [formGroup]="formEdit"
        (ngSubmit)="onSubmit(formObject)"
        #formObject="ngForm"
        autocomplete="off">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12 col-md-2">
              <app-common-input
                [control]="this.formEdit.get('amount')"
                [label]="importeLabel"
                [formSubmitted]="formObject.submitted"
                [type]="InputType.numeric"
                #ticketValue>
              </app-common-input>
            </div>

            <div class="form-group col-12">
              <app-common-input
                [control]="formEdit.get('observations')"
                [formSubmitted]="formObject.submitted"
                [label]="'Observaciones'"
                [type]="InputType.textarea">
              </app-common-input>
            </div>
          </div>
        </div>
        <!-- /.card-body -->

        <div class="card-footer text-right">
          <button type="submit" class="btn btn-primary" [disabled]="submiting">Aceptar</button>

          <button type="button" class="btn btn-secondary ml-2" (click)="GoToCashFlow()">Volver</button>
        </div>
      </form>
    </div>
    <!-- /.card -->
  </div>
</div>
<app-confirm-modal yes="Continuar" (onConfirm)="ConfirmAction()" [body]="body"> </app-confirm-modal>

<app-authorization-modal
  yes="Continuar"
  (onConfirm)="ConfirmAction()"
  (onCancel)="submiting = false"
  [body]="authorizationBody">
</app-authorization-modal>
