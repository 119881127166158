import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-edit-dashboard',
  templateUrl: './edit-dashboard.component.html',
  styleUrls: ['./edit-dashboard.component.css'],
})
export class EditDashboardComponent implements OnInit {
  // items: Array<BreadcrumbItem> = [];
  // title: string = "Edición";

  public static getName() {
    return 'EditDashboardComponent';
  }
  constructor(private router: Router) {}

  ngOnInit(): void {
    // this.items.push(new BreadcrumbItem(this.router.url, 'Dashboard'));
    // this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }
}
