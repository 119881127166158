import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Location } from 'src/app/common/components/viewmodels/Location';
import { LocationService } from 'src/app/services/location/location.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-list-locations',
  templateUrl: './list-locations.component.html',
  styleUrls: ['./list-locations.component.css'],
})
export class ListLocationsComponent implements OnInit {
  @Input() isABMReport: boolean = true;

  public data: commonTableData<Location>;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Location>;
  items: Array<BreadcrumbItem> = [];
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  location: Location = new Location();
  title: string = 'Ubicaciones';
  parameter: tableStatus = null;

  public static getName() {
    return 'ListLocationsComponent';
  }
  constructor(
    public LocationService: LocationService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['establishment/locations/edit-location', row.id]);
  }

  public onDeleteButton(row): void {
    this.location = row;
    this.body = '¿Está seguro que desea eliminar la ubicación ' + this.location.name.toString() + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['establishment/locations/create-location']);
  }

  confirmDelete() {
    this.LocationService.deleteLocation(this.location.id).subscribe((result) => {
      this.updateTable();
    });
  }

  setGridData(param1: boolean): void {
    let service = this.LocationService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Location>>> {
      return service.getLocations(param);
    };
    this.data.columns = [{ data: 'name', title: 'Ubicación' }];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
    this.data = new commonTableData();
    let paramLocation = this.paramService.getAndDeleteParameters();
    if (paramLocation != null) {
      this.data.searchText = paramLocation.searchString;
      this.data.pageLength = paramLocation.pageLength;
      this.data.currentPage = paramLocation.currentPage;
    }
    this.data.showEditButton = this.isABMReport;
    this.data.showDeleteButton = this.isABMReport;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }
}
