<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              (onSearch)="onSearch($event)"
              [excel]="true"
              [title]="this.title"></app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
