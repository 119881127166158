<div class="row">
  <div class="col">
    <div class="col-12">
      <form role="form" [formGroup]="formEdit" #formObject="ngForm">
        <div class="card" id="idCard">
          <div class="card-header">
            <h3 class="card-title">
              <span> {{ title }}</span>
            </h3>
            <div class="card-tools">
              <button #myCollapse type="button" class="btn btn-tool" data-card-widget="collapse">
                <i id="toolBtn" class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-2">
                <cash-register-combo
                  (loadFinish)="loadFinish()"
                  [control]="formEdit.get('cashRegister')"
                  (valueChanged)="changeCashRigester($event)">
                </cash-register-combo>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="mx-2 btn"
                (click)="onGoToCashFlow()"
                [class.btn-primary]="session.canEndTurn"
                [class.btn-secondary]="!session.canEndTurn"
                [disabled]="!session.canEndTurn">
                Operaciones de caja
              </button>
              <button
                type="button"
                class="btn"
                id="btnStart"
                (click)="onSessionStartModal()"
                [class.btn-primary]="session.canStartSession"
                [class.btn-secondary]="!session.canStartSession"
                [disabled]="!session.canStartSession">
                Iniciar Sesión
              </button>
              <button
                type="button"
                class="btn mx-2"
                [class.btn-primary]="session.canEndSession"
                [class.btn-secondary]="!session.canEndSession"
                (click)="onSessionEndButtonModal()"
                id="btnEnd"
                [disabled]="!session.canEndSession">
                Finalizar Sesión
              </button>

              <button
                type="button"
                class="btn"
                id="btnStart"
                (click)="onTurnStartModal()"
                [class.btn-primary]="session.canStartTurn"
                [class.btn-secondary]="!session.canStartTurn"
                [disabled]="!session.canStartTurn">
                Iniciar Turno
              </button>
              <button
                type="button"
                class="btn mx-2"
                [class.btn-primary]="session.canEndTurn"
                [class.btn-secondary]="!session.canEndTurn"
                (click)="onTurnEndModal()"
                id="btnEnd"
                [disabled]="!session.canEndTurn">
                Finalizar Turno
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-confirm-modal yes="Continuar" (onConfirm)="onConfirm()" [body]="body"> </app-confirm-modal>
