import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import {
  commonTableData,
  commonTableCustomButton,
  tableStatus,
  CommonTableComponent,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CashMachine } from 'src/app/common/components/viewmodels/cashMachine';
import { getFriendlyName, StateCashMachine, StateEgm } from 'src/app/common/components/viewmodels/Enums';
import { Spider } from 'src/app/common/components/viewmodels/Spider';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SpiderService } from 'src/app/services/spider/spider.service';

@Component({
  selector: 'app-list-cash-machine',
  templateUrl: './list-cash-machine.component.html',
  styleUrls: ['./list-cash-machine.component.css'],
})
export class ListCashMachineComponent implements OnInit {
  public data: commonTableData<CashMachine>;
  title: string = 'Máquina de Cambio / ATM';
  items: Array<BreadcrumbItem> = [];

  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CashMachine>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  cashMachine: CashMachine = new CashMachine();
  parameter: tableStatus = null;
  canDelete: boolean;

  public static getName() {
    return 'ListCashMachineComponent';
  }
  constructor(
    public egmService: EgmServiceService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.data = new commonTableData();
    let paramSpider = this.paramService.getAndDeleteParameters();

    if (paramSpider != null) {
      this.data.searchText = paramSpider.searchString;
      this.data.pageLength = paramSpider.pageLength;
      this.data.currentPage = paramSpider.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }
  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/cash-machine/edit', row.id]);
  }

  public async onDeleteButton(row): Promise<void> {
    this.cashMachine = row;
    this.body = '¿Está seguro que desea eliminar la Máquina de Cambio ' + this.cashMachine.muid + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/cash-machine/create-cash-machine']);
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  confirmDelete() {
    this.egmService.deleteCashMachine(this.cashMachine.id).subscribe((result) => {
      this.updateTable();
    });
  }

  setGridData(param1: boolean): void {
    let service = this.egmService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CashMachine>>> {
      return service.getAllCashMachine(param);
    };

    this.data.columns = [
      { data: 'muid', title: 'MCUID' },
      { data: 'name', title: 'Nombre' },
      {
        data: 'brand',
        title: 'Tipo de Dispositivo',
        render: function (data, type, row) {
          return data != null ? getFriendlyName(data) : '-';
        },
      },

      {
        data: 'macFormatted',
        title: 'Dirección MAC',
      },
      {
        data: 'machineHostSerialNumber',
        title: 'Serial Number',
      },
      {
        data: 'statusCashMachine',
        title: 'Estado',
        render: function (data, type, row) {
          return data != null ? StateCashMachine[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'room.name',
        title: 'Sala',
        render: function (data, type, row) {
          return data != null ? data : `-`;
        },
      },
      {
        data: 'eliminationDate',
        title: 'Fecha de eliminación',
        render: function (data: moment.Moment, type, row) {
          // return moment(data).format('DD/MM/yyyy, HH:mm:ss');
          return data != null ? moment(data).format('DD/MM/yyyy') : `-`;
        },
      },
    ];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }
}
