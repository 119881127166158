import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { CashRegisterComboComponent } from 'src/app/common/components/business/cash-register-combo/cash-register-combo.component';
import { RoomComboComponent } from 'src/app/common/components/business/room-combo/room-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DailyBalance } from 'src/app/common/components/viewmodels/DailyBalance';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import {
  CashFlowViewModel,
  CashFlowViewModelFooter,
  SessionManagmentViewModel,
} from 'src/app/common/components/viewmodels/SessioModel';
import { CasinoService } from 'src/app/services/casino/casino.service';

import { SessionService } from 'src/app/services/pos/pos.service';
import { EgmProfitParameters } from '../accounting-report/accounting-report.component';

@Component({
  selector: 'app-session-reconciliation',
  templateUrl: './session-reconciliation.component.html',
  styleUrls: ['./session-reconciliation.component.css'],
})
export class SessionReconciliationComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];
  turnId: number = 0;
  title: string = 'Conciliación';

  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;

  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(CashRegisterComboComponent) cashCombo: CashRegisterComboComponent;
  @ViewChild(RoomComboComponent) roomCombo: RoomComboComponent;

  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<
    CashFlowViewModel,
    CashFlowViewModelFooter
  >;

  regex: RegExp = new RegExp('_', 'g');
  callbackConfirm: () => void;
  totalMovements: number = 0;
  machineQuantity: number = 0;
  periodAction: string;
  body: string;
  currencyCode: string;
  currencySym: string;
  headerTitle: string;
  isConciliated: boolean = false;
  date: moment.Moment = moment(new Date());
  public data: commonTableData<CashFlowViewModel>;
  formSearch: FormGroup;
  parameter: EgmProfitParameters = new EgmProfitParameters();
  dateRange: string;
  filterParamString: string;
  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  loadData: boolean = false;
  canReconciliate: boolean = true;
  canCloseCollection: boolean = false;
  egmToCollect: string = '';
  cashRegisterToConciliate: string = '';

  listSearch: number[];

  public static getName() {
    return 'SessionReconciliationComponent';
  }
  constructor(
    private router: Router,

    public sessionService: SessionService,
    public notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,

    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', this.title));

    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.turnId = Number(paramTurnsId);
      if (isNaN(this.turnId)) {
        this.goToErrorPage();
      } else {
        this.sessionService.GetSessionByTurnId(this.turnId).subscribe((result) => {
          this.session = result.data;
          this.loadData = true;
          if (this.session === null) {
            this.router.navigate(['/session-management']);
          }
          this.headerTitle = this.sessionService.getPageTitle(this.session);
        });
      }
    }

    this.initStateCloseCollection();

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    //estado del boton conciliar:
    this.sessionService.getCloseCollectionState().subscribe((result) => {
      this.canCloseCollection = result.data.canCloseCollection;
      this.cashRegisterToConciliate = result.data.cashRegisterToConciliate;
      this.egmToCollect = result.data.egmToCollect;
    });

    this.initForm();
    this.initGrid();
  }

  initStateCloseCollection() {
    //estado del boton conciliar:
    this.sessionService.getCloseCollectionState().subscribe((result) => {
      this.canCloseCollection = result.data.canCloseCollection;
      this.cashRegisterToConciliate = result.data.cashRegisterToConciliate;
      this.egmToCollect = result.data.egmToCollect;
    });
  }

  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;

    let button: commonTableCustomButton = new commonTableCustomButton();

    button = new commonTableCustomButton();
    button.icon = 'fas fa-check-double';
    button.columntitle = 'Conciliar';
    button.order = 0;
    this.data.commonTableCustomButton.push(button);

    const that = this;
    this.data.columns = [
      { data: 'description', title: 'Descripción' },
      {
        data: 'formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'date',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },

      // { data: 'isConciliate', title: 'Es conciliado' },

      // { data: 'isPositive', title: '' },

      { data: 'validatorCode', title: 'Ticket' },
      { data: 'cashRegisterSoruce', title: 'Caja Origen' },
      { data: 'cashRegisterTarget', title: 'Caja Destino' },
      { data: 'egmSource', title: 'EGM Origen' },
      { data: 'egmTarget', title: 'EGM Destino' },
      { data: 'egmNickName', title: 'EGM Nombre' },
      { data: 'userIdName', title: 'Usuario' },
      { data: 'turnDescription', title: 'Turno' },
      { data: 'observations', title: 'Observaciones' },
    ];
    this.data.rowCallback = function (row: Node, data: CashFlowViewModel, index: number): void {
      if (data['isConciliate'] == true) {
        that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          $($('td a', row)[0]).addClass('hideButton'); //Danger
        });
      } else {
      }

      if (data.validatorCode == '' || data.validatorCode == null) return;
      $($('td', row)[4]).html(
        `<a target="_blank" href='${window.location.origin}/egms/tito/ticket-history/${data.validatorCode}' style='text-decoration: underline;' data-toggle='tooltip' data-placement='right' title='' data-original-title='Ir a historial'>${data.validatorCode}</a>`
      );
    };
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
      egmUid: new FormControl([]),
      cashRegisters: new FormControl([]),
      //  rooms: new FormControl([]),
      turnId: new FormControl([]),
      //combo tipos de movimientos
      movementType: new FormControl([]),
      toConciliate: new FormControl('toconciliate'),
    });
  }

  changeConliateButton($event) {}

  SetCanReconciliate() {
    if (this.formSearch.get('toConciliate').value == 'toconciliate' && this.totalMovements > 0) {
      this.canReconciliate = true;
    } else {
      this.canReconciliate = false;
    }
  }

  onSubmit() {
    this.parameter.from = this.formSearch.get('from').value;
    this.parameter.to = this.formSearch.get('to').value;
    // if (this.formSearch.get('rooms').value != null) {
    //   this.formSearch.get('rooms').value.forEach((element: number) => {
    //     this.parameter.rooms.push(element);
    //   });
    // }
    if (this.formSearch.get('egmUid').value != null) {
      this.formSearch.get('egmUid').value.forEach((element: number) => {
        this.parameter.uids.push(element);
      });
    }
    if (this.formSearch.get('cashRegisters').value != null) {
      this.formSearch.get('cashRegisters').value.forEach((element: number) => {
        this.parameter.cuids.push(element);
      });
    }
    if (this.formSearch.get('turnId').value != null) {
      this.formSearch.get('turnId').value.forEach((element: number) => {
        this.parameter.turnIds.push(element);
      });
    }
    if (this.formSearch.get('movementType').value != null) {
      this.formSearch.get('movementType').value.forEach((element: number) => {
        this.parameter.movementTypes.push(element);
      });
    }

    this.parameter.toConciliate = this.formSearch.get('toConciliate').value;
    //this.setPeriodAction();
    this.updateTable();
  }

  updateTable() {
    this.totalMovements = 0;
    this.machineQuantity = 0;

    this.dateRange = null;
    if (this.formSearch.get('from').value != null && this.formSearch.get('to').value != null) {
      this.dateRange =
        moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
        ' - ' +
        moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();
    }
    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  filterString() {
    let aux: string = '';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    // if (this.formSearch.get('rooms').value.length > 0) {
    //   aux += ' Salas: ' + this.roomFilters + ' - ';
    // }
    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters + ' - ';
    }
    if (this.formSearch.get('cashRegisters').value.length > 0) {
      aux += ' Caja: ' + this.cashRegisterFilters + ' - ';
    }
    aux += this.formSearch.get('toConciliate').value == 'toconciliate' ? 'Pendiente Conciliar' : 'Conciliado';

    if (this.table != undefined) {
      this.table.setFilterString(' Filtros: - ' + aux);
    }

    this.filterParamString = aux;
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }
  get cashRegisterFilters(): string {
    let combo = this.cashCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('cashRegisters').value.length; index++) {
      const element = this.formSearch.get('cashRegisters').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('cashRegisters').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get roomFilters(): string {
    let combo = this.roomCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('rooms').value.length; index++) {
      const element = this.formSearch.get('rooms').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('rooms').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  getFilters(): MultiselectFilters {
    var filters = new MultiselectFilters();
    filters.currentTurn = this.turnId;
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;

    filters.toConciliate = this.formSearch.get('toConciliate').value == 'toconciliate' ? true : false;

    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
      // } else {
      //   if (this.formSearch.get('rooms').value.length > 0) {
      //     filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      //   }
    }
    if (this.formSearch.get('cashRegisters').value.length > 0) {
      filters.cashRegisters = this.formSearch.get('cashRegisters').value;
    }

    if (this.formSearch.get('movementType').value.length > 0) {
      filters.movementTypes = this.formSearch.get('movementType').value;
    }
    if (this.formSearch.get('turnId').value.length > 0) {
      filters.Turns = this.formSearch.get('turnId').value;
    }

    return filters;
  }

  updateEgms() {
    this.formSearch.get('egmUid').setValue([]);
    // if (this.formSearch.get('rooms').value == null) {
    //   this.egmsCombo.fillCombo();
    // }
    // else
    // {
    var filters: MultiselectFilters = new MultiselectFilters();
    //  filters.rooms = this.formSearch.get('rooms').value;
    filters.egms = this.formSearch.get('egmUid').value;
    filters.cashRegisters = this.formSearch.get('cashRegisters').value;
    this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
    //}
  }

  setGridData(filters: MultiselectFilters) {
    let service = this.sessionService;

    this.data.getData = function (
      param: any
    ): Observable<ApiResult<DataTableResult<CashFlowViewModel, CashFlowViewModelFooter>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.GetReconciliateItems(DataTableFilters);
    };
  }

  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }
  goToBack() {
    if (this.turnId != 0) {
      this.router.navigate(['/session-cashflow', this.turnId]);
    }
  }

  movementId: number;
  public onCustomButton(data: any): void {
    this.movementId = data.row.id;

    this.body = '¿Está seguro que desea conciliar el movimiento seleccionado?';

    this.callbackConfirm = () => {
      //hago la pregunta:

      this.sessionService.ReconciliateItem(this.movementId, this.turnId).subscribe((result) => {
        if (result.error == null) {
          this.updateTable();
          this.notificationService.showSuccess('Operación realizada con éxito', 'Operacion realizada');
        } else {
          this.notificationService.showError(result.error.generalMessage, 'Error');
        }
      });
    };
    this.confirmModal.openModalStatic();
  }

  closeCollectionModal() {
    var alert = '';
    if (this.cashRegisterToConciliate != '') {
      alert = `Hay movimientos de la caja ${this.cashRegisterToConciliate} para conciliar. <br>`;
    }
    if (this.egmToCollect != '') {
      alert += `Las egms ${this.egmToCollect} no fueron recaudadas. <br>`;
    }

    this.body = `${alert}¿Está seguro que desea finalizar la recaudación del Salón?`;

    this.callbackConfirm = () => {
      this.canCloseCollection = false;
      this.sessionService.CloseCollection().subscribe((result) => {
        if (result.error == null) {
        } else {
          this.canCloseCollection = true;
        }
      });
    };
    this.confirmModal.openModalStatic();
  }

  conciliateAllModal() {
    this.body = `¿Está seguro que desea conciliar los ${this.totalMovements} movimientos buscados?`;

    this.callbackConfirm = () => {
      this.sessionService.ReconciliateItemSearch(this.listSearch, this.turnId).subscribe((result) => {
        if (result.error == null) {
          this.initStateCloseCollection();
          this.updateTable();
          this.notificationService.showSuccess('Operación realizada con éxito', 'Operacion realizada');
        } else {
          this.notificationService.showError(result.error.generalMessage, 'Error');
        }
      });
    };
    this.confirmModal.openModalStatic();
  }

  goToConfirm() {
    this.callbackConfirm();
  }
  GoToCashFlow() {
    this.router.navigate(['/session-cashflow', this.session.idTurn]);
  }

  conciliateAll() {}

  onSearch(status: tableStatus) {
    // this.parameter.status = status;
    this.totalMovements = this.table.intData;
    this.SetCanReconciliate();
    this.listSearch = this.table.listIntData;
    // this.machineQuantity = this.table.intData;
  }
}
