<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange != null"> - {{ dateRange }} </span>
              </h3>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [controlFrom]="formSearch.get('from')"
                    [controlTo]="formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm-4">
                  <common-input-select
                    [allowClear]="true"
                    class="form-control-sm"
                    [list]="this.issuedBy"
                    label="Originado por"
                    formControlName="issuedBy"
                    (valueChanged)="changedIssuedBy()">
                  </common-input-select>
                </div>
                <div class="col-12 col-sm-4" [style.display]="showCR ? 'block' : 'none'">
                  <cash-register-combo
                    [multiselect]="true"
                    [control]="formSearch.get('cashRegisterIds')"></cash-register-combo>
                </div>
                <div class="col-12 col-sm-4" [style.display]="showEgm ? 'block' : 'none'">
                  <egm-combo [multiselect]="true" [control]="formSearch.get('egmUid')"></egm-combo>
                </div>
              </div>

              <div class="form-row"></div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- /.row -->
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              #table
              [model]="data"
              [excel]="true"
              [title]="title"
              (onCustomButton)="onCustomButton($event)"
              (onSearch)="onSearch($event)"></app-common-table>
          </div>

          <div class="card-footer" *ngIf="ticketQuantity > 0">
            <div class="row">
              <div class="col-2 d-flex">
                <span class="resalt">Importe total:</span
                ><span class="resalt">
                  {{ currencyPipe.transform(totalAmount, currencyCode, currencySym) }}
                </span>
              </div>

              <div class="col-2 d-flex">
                <span class="resalt">Cantidad de máquinas:</span><span class="resalt">{{ ticketQuantity }}</span>
              </div>
              <div class="col-8 d-flex"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>
