<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body" *ngIf="casinoData != null">
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display [label]="'Nombre de la empresa'" [model]="casinoData.companyName"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display [label]="'Nombre del establecimiento'" [model]="casinoData.facilityName">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display [label]="'Identificador fiscal'" [model]="casinoData.cuit"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Inicio del establecimiento'"
                  [model]="moment(casinoData.facilityStart).format('DD/MM/yyyy, HH:mm:ss')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Inicio del sistema'"
                  [model]="moment(casinoData.systemStart).format('DD/MM/yyyy, HH:mm:ss')">
                </app-info-display>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Horario de cierre diario'"
                  [model]="moment(casinoData.dayStart).format('kk:00')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display [label]="'Dirección'" [model]="casinoData.address"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display [label]="'Teléfono'" [model]="casinoData.phoneNumber"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Símbolo Monetario del sistema'"
                  [model]="casinoData.currencyCode + ', ' + casinoData.currencySymbol">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Período de lectura de contadores (seg.)'"
                  [model]="casinoData.insertCountersSpanSecond">
                </app-info-display>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Período de cálculo de ganancia (seg.)'"
                  [model]="casinoData.profitSpanSecond">
                </app-info-display>
              </div>

              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Time Out EGM disconnected (seg.)'"
                  [model]="casinoData.egmSecondsHostTimeout">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display [label]="'Time Zone '" [model]="casinoData.localTimeZone"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl"></div>
              <div class="form-group col-12 col-md-6 col-xl"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Parámetros individuales sin autorización</h3>
          </div>
          <div class="card-body" *ngIf="casinoData != null">
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Datáfono'"
                  [model]="getValue(casinoData.formattedDataphoneMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Factura'"
                  [model]="getValue(casinoData.formattedBillMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Apuestas'"
                  [model]="getValue(casinoData.formattedBetMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Gastos Varios'"
                  [model]="getValue(casinoData.formattedExpensesMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Jackpot Externo'"
                  [model]="getValue(casinoData.formattedExternalJackpotMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Manual Sin Alerta'"
                  [model]="getValue(casinoData.formattedManualCodeMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Manual (Ticket no reportado)'"
                  [model]="getValue(casinoData.formattedManualTicketMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Incidencia'"
                  [model]="getValue(casinoData.formattedImpactCodeMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl">
                <app-info-display
                  [label]="'Pago Incidencia Con Ticket'"
                  [model]="getValue(casinoData.formattedImpactTicketMaxUnauthorizedAmount)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl"></div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-end">
              <button type="button" *ngIf="showEdit | async" (click)="editParameters()" class="btn btn-primary btn-sm">
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
