import { Moment } from 'moment';
import { EGM } from './EGM';
import { HandpayState } from './Enums';
import { Ticket } from './Ticket';
import { TicketTracker } from './TicketTracker';

export class Handpay {
  id: number;
  ticket: Ticket;
  codeTicket: string;
  amount: number;
  formattedAmount: number;
  eventDate: moment.Moment;
  state: HandpayState;
  supervisorDate?: moment.Moment;
  egm: EGM;

  public constructor(init?: Partial<Handpay>) {
    Object.assign(this, init);
  }
}
