import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';

import { ParametersService } from 'src/app/services/parameters/parameters.service';
import {
  commonTableData,
  commonTableCustomButton,
  tableStatus,
  CommonTableComponent,
} from '../../shared/common-table/common-table.component';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { NotificationService } from '../../shared/notification/notification.service';
import { ApiResult, DataTableResult } from '../../viewmodels/apiResult';
import { DailyBalance } from '../../viewmodels/DailyBalance';
import { DatatableMultiFilters } from '../../viewmodels/DatatableFilters';

import { EGM } from '../../viewmodels/EGM';
import { ProfitEvent } from '../../viewmodels/Enums';
import { MultiselectFilters } from '../../viewmodels/Filters';
import { RoomComboComponent } from '../room-combo/room-combo.component';
declare var $: any;

@Component({
  selector: 'egm-profit',
  templateUrl: './egm-profit.component.html',
  styleUrls: ['./egm-profit.component.css'],
})
export class EgmProfitComponent implements OnInit {
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<DailyBalance>;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(RoomComboComponent) roomCombo: RoomComboComponent;

  @Input() isReportCurrentProfit: boolean = false; // Ganancia dia abierto
  @Input() isPeriodReport: boolean = false; // Ganancia dia cerrado
  @Input() isDailyClosing: boolean = false; // Ganancia diaria
  @Input() showTotalProfit: boolean = true;
  @Input() title: string = '';

  regex: RegExp = new RegExp('_', 'g');

  totalProfit: number = 0;
  machineQuantity: number = 0;
  periodAction: string;
  body: string;
  currencyCode: string;
  currencySym: string;
  isTogglePeriod: boolean = false;
  date: moment.Moment = moment(new Date());
  public data: commonTableData<DailyBalance>;
  formSearch: FormGroup;
  parameter: EgmProfitParameters = new EgmProfitParameters();
  dateRange: string;

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public DailyBalanceService: DailyBalanceService,
    private activatedRoute: ActivatedRoute,
    private paramService: ParametersService<EgmProfitParameters>,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe
  ) {}

  ngOnInit(): void {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.initForm();
    this.initGrid();
    this.setPeriodAction();
    if (this.isReportCurrentProfit || this.isPeriodReport) {
      if (this.isPeriodReport) this.formSearch.get('dayStatus').setValue('closed');
      this.formSearch.get('dayStatus').disable();
    }

    if (!this.isReportCurrentProfit) {
      let paramEgmProfit = this.paramService.getAndDeleteParameters();

      if (paramEgmProfit != null) {
        this.data.searchText = paramEgmProfit.status.searchString;
        this.data.pageLength = paramEgmProfit.status.pageLength;
        this.data.currentPage = paramEgmProfit.status.currentPage;
        this.formSearch.get('egmUid').setValue(paramEgmProfit.uids);
        this.formSearch.get('from').setValue(paramEgmProfit.from);
        this.formSearch.get('to').setValue(paramEgmProfit.to);
        this.formSearch.get('rooms').setValue(paramEgmProfit.rooms);
        this.formSearch.get('dayStatus').setValue(paramEgmProfit.dayStatus);
      }

      if (!this.isPeriodReport) {
        let button: commonTableCustomButton = new commonTableCustomButton();
        button.icon = 'fa-file-alt';
        button.columntitle = 'Detalles';
        button.tooltip = 'Ver vista detallada';
        button.order = 0;
        this.data.commonTableCustomButton.push(button);
      }
    }
  }

  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = true;
    this.data.showAll = true;

    const that = this;

    if (this.isPeriodReport) {
      this.data.columns = [
        {
          data: 'event',
          title: 'Evento Ganancia',
          render: function (data: number, type, row) {
            return data != null ? ProfitEvent[data].replace(that.regex, ' ') : '-';
          },
        },
        {
          data: 'closed',
          title: 'Estado',
          render: function (data, type, row) {
            return data == true ? 'Cerrado' : 'Abierto';
          },
        },
        { data: 'egm.uid', title: 'UID' },
        { data: 'egm.nickName', title: 'EGM Nombre' },

        { data: 'egm.egmConfig.formattedDenomination', title: 'Denominación' },
        {
          data: 'formattedStartDate',
          title: 'Fecha',
          render: function (data, type, row) {
            return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
          },
        },
        {
          data: 'formattedEndDate',
          title: 'Fecha Fin',
          render: function (data, type, row) {
            return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
          },
        },
        {
          data: 'formattedLastCalculateProfitDate',
          title: 'Fecha de Cálculo',
          render: function (data, type, row) {
            return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
          },
        },
        {
          //data: 'formattedProfit',
          data: 'formattedBenefit',
          title: 'Ganancia',
          render: function (data: number, type, row) {
            return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
          },
        },
      ];
    } else {
      this.data.columns = [
        {
          data: 'event',
          title: 'Evento',
          render: function (data: number, type, row) {
            return data != null ? ProfitEvent[data].replace(that.regex, ' ') : '-';
          },
        },
        {
          data: 'closed',
          title: 'Estado',
          render: function (data, type, row) {
            return data == true ? 'Cerrado' : 'Abierto';
          },
        },
        { data: 'egm.uid', title: 'UID' },
        { data: 'egm.nickName', title: 'EGM Nombre' },
        { data: 'egm.egmConfig.formattedDenomination', title: 'Denominación' },
        {
          data: 'formattedStartDate',
          title: 'Fecha',
          render: function (data, type, row) {
            return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
          },
        },
        {
          data: 'formattedEndDate',
          title: 'Fecha Fin',
          render: function (data, type, row) {
            return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
          },
        },
        {
          data: 'formattedLastCalculateProfitDate',
          title: 'Fecha de Cálculo',
          render: function (data, type, row) {
            return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
          },
        },
        {
          data: 'formattedBenefit',
          title: 'Ganancia',
          render: function (data: number, type, row) {
            return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
          },
        },
        // { data: 'initialCoinIn', title: 'Coin In Inicial' },
        // { data: 'finalCoinIn', title: 'Coin In Final' },
        // { data: 'initialCoinOut', title: 'Coin Out Inicial' },
        // { data: 'finalCoinOut', title: 'Coin Out Final' },
        // { data: 'initialJackpot', title: 'Jackpot Inicial' },
        // { data: 'finalJackpot', title: 'Jackpot Final' },

        // {
        //   data: 'egm.egmConfig.formattedPaymentPercentageProb',
        //   title: '% Theo Pay',
        //   render: function (data: number, type, row) {
        //     return that.percentPipe.transform(data, '2.2');
        //   },
        // },
        // {
        //   data: 'devPay',
        //   title: '% Dev Pay',
        //   render: function (data: number, type, row) {
        //     return that.percentPipe.transform(data, '2.2');
        //   },
        // },
        // {
        //   data: 'retHold',
        //   title: '% Ret Hold',
        //   render: function (data: number, type, row) {
        //     return that.percentPipe.transform(data, '2.2');
        //   },
        // },
      ];
    }

    this.data.rowCallback = function (row: Node, data: EGM | Object, index: number): void {
      if (data['formattedBenefit'] < 0) {
        that.redFont(row);
      } else {
        if (data['formattedBenefit'] > 0) {
          that.greenFont(row);
        }
      }
      //  that.totalProfit += data["formattedProfit"];
      //that.machineQuantity++;
    };
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
      egmUid: new FormControl([]),
      rooms: new FormControl([]),
      dayStatus: new FormControl('opened'),
    });
  }

  onSubmit() {
    this.parameter.from = this.formSearch.get('from').value;
    this.parameter.to = this.formSearch.get('to').value;
    if (this.formSearch.get('rooms').value != null) {
      this.parameter.rooms = [];
      this.formSearch.get('rooms').value.forEach((element: number) => {
        this.parameter.rooms.push(element);
      });
    }
    if (this.formSearch.get('egmUid').value != null) {
      this.parameter.uids = [];
      this.formSearch.get('egmUid').value.forEach((element: number) => {
        this.parameter.uids.push(element);
      });
    }
    this.parameter.dayStatus = this.formSearch.get('dayStatus').value;
    this.setPeriodAction();
    this.updateTable();
  }

  togglePeriod() {
    this.DailyBalanceService.togglePeriod(this.getFilters()).subscribe((result) => {
      this.updateTable();
    });
  }

  updateTable() {
    this.totalProfit = 0;
    this.machineQuantity = 0;
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();

    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    if (this.formSearch.get('rooms').value.length > 0) {
      aux += ' Salas: ' + this.roomFilters + ' - ';
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters + ' - ';
    }
    aux += this.formSearch.get('dayStatus').value == 'opened' ? 'Día abierto' : 'Día cerrado';

    if (this.table != undefined) {
      this.table.setFilterString(aux);
    }
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get roomFilters(): string {
    let combo = this.roomCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('rooms').value.length; index++) {
      const element = this.formSearch.get('rooms').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('rooms').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  openModal(n: boolean = false) {
    this.isTogglePeriod = n;
    if (n) {
      //Modal de togglePeriod
      this.body = '¿Seguro que desea ' + this.periodAction.toLowerCase() + ' el día o los días seleccionados?';
    } else {
      //Modal de recalculateProfit
      this.body = '¿Seguro que desea recalcular ganancias?';
    }
    this.confirmModal.openModalStatic();
  }

  getFilters(): MultiselectFilters {
    var filters = new MultiselectFilters();
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;

    if (this.isDailyClosing) {
      filters.closedPeriod = this.formSearch.get('dayStatus').value == 'closed' ? true : false;
    }
    if (this.isPeriodReport) {
      filters.closedPeriod = true;
    }
    if (this.isReportCurrentProfit) {
      filters.closedPeriod = false;
    }

    if (this.formSearch.get('egmUid').value != null) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      if (this.formSearch.get('rooms').value != null) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }

    return filters;
  }

  recalculateProfits() {
    this.DailyBalanceService.recalculateProfits(this.getFilters()).subscribe((result) => {
      this.updateTable();
    });
  }

  updateEgms() {
    this.formSearch.get('egmUid').setValue([]);
    if (this.formSearch.get('rooms').value == null) {
      this.egmsCombo.fillCombo();
    } else {
      var filters: MultiselectFilters = new MultiselectFilters();
      filters.rooms = this.formSearch.get('rooms').value;
      filters.egms = this.formSearch.get('egmUid').value;
      this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
    }
  }

  setGridData(filters: MultiselectFilters) {
    let service = this.DailyBalanceService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<DailyBalance>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getDailys(DataTableFilters);
    };
  }

  public onCustomButton(data: any): void {
    this.parameter.idEgm = data.row.egmId;
    this.parameter.idModel = data.row.egm.modelId;
    this.parameter.idManufacturer = data.row.egm.manufacturerId;
    this.parameter.idGameType = data.row.egm.gameTypeId;
    //me voy al otro formulario con los datos de la fecha seleccionada
    this.parameter.from = data.row.startDate;
    this.parameter.to = data.row.endDate;
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['/reports/current-counters']);
    //tengo que pasar parametros x url
    //window.open('/reports/current-counters', '_blank');
  }

  getProfitIndex(): number {
    //8 es el indice de la columna profit sin contar los custom buttons.
    return 10 + this.data.commonTableCustomButton.length;
  }

  redFont(row: Node) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[this.getProfitIndex()]).css('color', '#dc3545'); //Danger
      $($('td', row)[this.getProfitIndex()]).css('font-weight', 'bold');
    });
  }

  greenFont(row: Node) {
    this.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $($('td', row)[this.getProfitIndex()]).css('color', '#28a745'); //Success
      $($('td', row)[this.getProfitIndex()]).css('font-weight', 'bold');
    });
  }

  onSearch(status: tableStatus) {
    this.parameter.status = status;
    this.totalProfit = this.table.decimalData;
    this.machineQuantity = this.table.intData;
  }

  setPeriodAction() {
    this.formSearch.get('dayStatus').value == 'closed' ? (this.periodAction = 'Abrir') : (this.periodAction = 'Cerrar');
  }
}

export class EgmProfitParameters {
  from: string;
  to: string;
  uids: number[];
  cuids: number[];
  rooms: number[];
  dayStatus: string;
  status: tableStatus;
  idEgm: number;
  idModel: number;
  idManufacturer: number;
  idGameType: number;
  idUser: number;
  creationDate: number;

  constructor() {
    this.status = new tableStatus();
    this.uids = [];
    this.rooms = [];
  }
}
