import moment from 'moment-timezone';
import { CashMachine } from './cashMachine';
import { CashRegister } from './CashRegister';
import { EGMMock } from './EGMMock';
import { CancellationReasons, PreInserted, TicketState, TicketType } from './Enums';
import { SessionOldReport } from './SessionOldReport';

import { TicketMock } from './TicketMock';

export class TicketTracker {
  id: number;
  ticket: TicketMock;
  ticketId: number;
  eventEgm?: EGMMock;
  eventCashMachine?: CashMachine;
  //eventSession?: SessionOldReport;
  EventTurn?: Turn;
  userId?: string;
  eventSupervisorUserId: string;
  userIdName: string;
  type: TicketType;
  systemDate: moment.Moment;
  eventDate: moment.Moment;
  preInserted: PreInserted;
  preInsertedError: string;
  cancellationReason?: CancellationReasons;

  state: TicketState;
  slotResponseMessage: string;
  hostResponseMessage: string;

  cashRegisterName?: string;
}

export class Turn {
  id: number;

  session: Session;
  openUserId: string;
  closeUserId: string;
  formattedOpenDate: moment.Moment;
  formattedCloseDate: moment.Moment;
  turnUserName: string;
}

export class Session {
  cashRegister: CashRegister;
  openUserId: string;
  closeUserId: string;
  formattedOpenDate: moment.Moment;
  formattedCloseDate: moment.Moment;
  isSessionStarted: boolean;
}
