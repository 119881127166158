<div class="content">
  <div class="container-fluid">
    <div class="row" *ngIf="loadData">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              <span> {{ headerTitle }}</span>
            </h3>
            <div class="card-tools">
              <button #myCollapse type="button" class="btn btn-tool" data-card-widget="collapse">
                <i id="toolBtn" class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row d-flex justify-content-between">
              <div class="col-4">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <form role="form" [formGroup]="formSearch" #formObject="ngForm">
                        <div class="card-body">
                          <div class="form-row">
                            <validation-code
                              [control]="formSearch.get('ticketCode')"
                              [formSubmitted]="formObject.submitted"
                              class="w-100"
                              (inputFull)="onFullInput($event)"
                              [focus]="true"
                              [cleanAfterCompleted]="true">
                            </validation-code>
                          </div>
                        </div>
                        <div class="card-footer">
                          <div class="d-flex justify-content-end">
                            <button class="btn btn-info" [disabled]="!sessionStatus" (click)="search()">Buscar</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card h-100 card-primary">
                      <div class="card-header">
                        <h3 class="card-title">Total a pagar</h3>
                      </div>
                      <div class="card-body mb-2">
                        <div class="form-row d-flex flex-grow-1 h-100">
                          <div class="col-12 text-center d-flex flex-self-stretch h-100">
                            <span class="text-center mx-auto bigFont d-flex align-items-center"
                              >{{ currencyPipe.transform(total, currencyCode, currencySym) }}
                            </span>
                          </div>
                          <div class="col-12 h-100">
                            <span>Cantidad de tickets a pagar: {{ tickets.length }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="col d-flex justify-content-end">
                          <button
                            class="btn btn-primary btn-lg"
                            (click)="payTickets()"
                            [disabled]="tickets.length === 0">
                            Pagar
                          </button>

                          <button type="button" class="btn btn-secondary ml-2" (click)="cleanTickets()">
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-12" *ngIf="tickets.length > 0">
                    <div class="card card-primary">
                      <div class="card-header">
                        <h3 class="card-title">
                          {{ lastTicket.validatorCode }} -
                          {{ currencyPipe.transform(lastTicket.formattedAmount, currencyCode, currencySym) }}
                        </h3>
                      </div>
                      <div class="card-body">
                        <div class="form-row">
                          <div class="col-12 col-md-3 col-lg-4">
                            <app-info-display
                              [label]="'Monto'"
                              [model]="currencyPipe.transform(lastTicket.formattedAmount, currencyCode, currencySym)">
                            </app-info-display>
                          </div>
                          <div class="col-12 col-md-3 col-lg-4">
                            <app-info-display
                              [label]="'Estado'"
                              [model]="stateEnum[lastTicket.state].replace(regex, ' ')">
                            </app-info-display>
                          </div>
                          <div class="col-12 col-md-3 col-lg-4">
                            <app-info-display [label]="'Número de ticket'" [model]="lastTicket.ticketNumber">
                            </app-info-display>
                          </div>
                          <div class="col-12 col-md-3 col-lg-4" *ngIf="lastTicket.creationEgm !== null">
                            <app-info-display [label]="'UID'" [model]="lastTicket.creationEgm.uid"> </app-info-display>
                          </div>
                          <div class="col-12 col-md-3 col-lg-4" *ngIf="lastTicket.creationTurn !== null">
                            <app-info-display
                              [label]="'CUID'"
                              [model]="lastTicket.creationTurn.session.cashRegister.cuid">
                            </app-info-display>
                          </div>
                          <div class="col-12 col-md-3 col-lg-4">
                            <app-info-display
                              [label]="'Fecha de emisión'"
                              [model]="moment(lastTicket.creationDate).format('DD/MM/yyyy, HH:mm:ss')">
                            </app-info-display>
                          </div>
                          <div class="col-12 col-md-3 col-lg-4">
                            <app-info-display
                              [label]="'Fecha de expiración'"
                              [model]="moment(lastTicket.expirationDate).format('DD/MM/yyyy, HH:mm:ss')">
                            </app-info-display>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12" *ngIf="tickets.length > 1">
                    <div class="row">
                      <div class="col-6" *ngFor="let ticket of tickets; let i = index">
                        <ticket *ngIf="i < tickets.length - 1" [ticket]="tickets[i + 1]" [collapsed]="true"> </ticket>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button type="button" class="btn btn-secondary ml-2" (click)="GoToCashFlow(); $event.preventDefault()">
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal
  #invalidModal
  yes="Entendido"
  (onConfirm)="formSearch.get('ticketCode').enable()"
  [okOnly]="true"
  [body]="body">
</app-confirm-modal>
<app-receipt-modal [receipt]="receipt" (onConfirm)="confirmedPayment()" [isCashout]="true"></app-receipt-modal>
