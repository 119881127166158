import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { param } from 'jquery';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { StateEgm } from 'src/app/common/components/viewmodels/Enums';
import { UsersViewModel } from 'src/app/common/components/viewmodels/User';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css'],
})
export class ListUserComponent implements OnInit {
  public data: commonTableData<UsersViewModel>;

  title: string = 'Usuarios';
  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<UsersViewModel>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  user: UsersViewModel = new UsersViewModel();
  parameter: tableStatus = null;

  public static getName() {
    return 'ListUserComponent';
  }
  constructor(
    public egmService: UsersService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private casinoService: CasinoService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Seguridad'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.data = new commonTableData();
    let paramEGM = this.paramService.getAndDeleteParameters();

    if (paramEGM != null) {
      this.data.searchText = paramEGM.searchString;
      this.data.pageLength = paramEGM.pageLength;
      this.data.currentPage = paramEGM.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;

    this.setGridData(true);
  }

  setGridData(param1: boolean): void {
    let service = this.egmService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<UsersViewModel>>> {
      return service.GetUsersListDataTable(param);
    };

    this.data.columns = [
      { data: 'userName', title: 'Usuario' },
      { data: 'rol', title: 'Rol' },
      { data: 'fullName', title: 'Nombre' },

      // { data: 'active', title: 'isRemoved' },
      {
        data: 'isRemoved',
        title: 'Estado',
        render: function (data, type, row) {
          return data ? 'Baja' : `Activo`;
        },
      },
    ];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['users/edit', row.id]);
  }

  public onDeleteButton(row): void {
    this.user = row;

    this.body = '¿Está seguro que desea dar de baja el usuario ' + this.user.userName + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['/users/create']);
    // this.router.navigate(['/establishment/cash-registers']);
  }

  confirmDelete() {
    this.egmService.DeleteUser(this.user.id).subscribe((result) => {
      //this.notificationService.showSuccess(`Se borro la EGM ${this.egm.uid}`, "Atención!");
      this.updateTable();
    });
  }
}
