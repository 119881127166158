import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TurnComboComponent } from 'src/app/common/business/turn-combo/turn-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import {
  ButtonMovementTypeEnum,
  EgmStockTypeEnum,
  SessionManagmentViewModel,
  SessionStokEgmViewModel,
  SettlmentTypeEnum,
} from 'src/app/common/components/viewmodels/SessioModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { PrinterService } from 'src/app/services/printer/printer.service';

@Component({
  selector: 'app-session-stockegm-in',
  templateUrl: './session-stockegm-in.component.html',
  styleUrls: ['./session-stockegm-in.component.css'],
})
export class SessionStockegmInComponent implements OnInit {
  public static getName() {
    return 'SessionStockegmInComponent';
  }

  sessionMovementType: ButtonMovementTypeEnum;
  formEdit: FormGroup;
  items: Array<BreadcrumbItem> = [];
  title: string = '';
  visibleTito: boolean;

  loadPage: boolean = false;
  egmId: number;
  egmUid: number;

  turnId: number;
  EgmStockType: EgmStockTypeEnum;
  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  loadData: boolean = false;
  headerTitle: string = '';
  InputType = InputTypeEnum;

  currencyCode: string;
  currencySym: string;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(TurnComboComponent) turnCombo: TurnComboComponent;
  body: string;
  importeLabel: string = 'Importe ';

  constructor(
    private router: Router,
    public printerService: PrinterService,
    public sessionService: SessionService,
    public notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    public egmService: EgmServiceService,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {
    this.items.push(new BreadcrumbItem('/session-egm-action', 'Operaciones egm'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  async ngOnInit(): Promise<void> {
    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramEgmId = this.activatedRoute.snapshot.params.id;

      this.egmId = Number(paramEgmId);
      if (isNaN(this.egmId)) {
        this.GoToBack();
      } else {
        this.initForm();
        this.title = 'Reposición Máquina';
        this.visibleTito = true;
        this.formEdit.get('showTito').setValue('emitirTito');
        this.EgmStockType = EgmStockTypeEnum.Reponer;
      }
      this.body = `¿Está seguro que desea realizar la operación de ${this.title}?`;
      this.headerTitle = 'Seleccione un turno';
      this.fillCombos();

      this.casinoService.getData().subscribe((result) => {
        this.currencyCode = result.data.currencyCode;
        this.currencySym = result.data.currencySymbol;
        this.importeLabel += this.currencySym;
      });

      this.egmService.getEGM2(this.egmId).subscribe((result) => {
        this.egmUid = result.data.uid;
        this.title += ' - EGM: ' + this.egmUid + ' - ' + result.data.nickName;
      });
    }
  }

  GoToBack() {
    this.router.navigate(['/session-egm-action']);
  }

  private initForm() {
    this.formEdit = new FormGroup({
      amount: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      observations: new FormControl(null),
      turnId: new FormControl(null, [Validators.required]),
      showTito: new FormControl(null, [Validators.required]),
    });
  }

  finishLoad() {
    // var memory = localStorage.getItem('bcm.turnIdGestion');

    // let combo = this.turnCombo.getCombo();

    // //si la opcion seleccionada esta en el combo de cajas:
    // if (combo.find((e) => e.id == memory) != null) {
    //   if (memory != null) {
    //     this.formEdit.get('turnId').setValue(Number(memory));
    //     this.changeTurn();
    //   }
    // }
    if (this.turnCombo.getCombo().length == 1) {
      this.formEdit.get('turnId').setValue(Number(this.turnCombo.getCombo()[0].id));
      this.changeTurn();
    }
  }

  async onSubmit(myForm: any) {
    if (this.formEdit.valid) {
      this.confirmModal.openModalStatic();
    }
  }
  private fillCombos() {}
  changeTurn() {
    this.turnId = this.formEdit.get('turnId').value;
    //localStorage.setItem('bcm.turnIdGestion', this.turnId.toString());

    this.sessionService.GetSessionByTurnId(this.turnId).subscribe((result) => {
      this.session = result.data;
      this.loadData = true;
      if (this.session === null) {
        this.GoToBack();
      }
      this.headerTitle = this.sessionService.getPageTitle(this.session);
    });
  }

  async ConfirmAction() {
    if (this.formEdit.valid) {
      if (this.formEdit.get('amount').value <= 0) {
        this.notificationService.showError('El monto no puede ser menor o igual a cero', 'Operación rechazada');
      } else {
        let model: SessionStokEgmViewModel = new SessionStokEgmViewModel();
        model.egmId = this.egmId;
        model.turnId = this.turnId;
        model.egmStockType = this.EgmStockType;

        model.formattedAmount = Number(this.formEdit.get('amount').value);
        model.observation = this.formEdit.get('observations').value;
        model.turnId = this.turnId;

        var showTito = this.formEdit.get('showTito').value == 'emitirTito' ? true : false;
        model.printTito = showTito;

        if (showTito) {
          this.stockEgmAndGenerateTicket(model);
        } else {
          this.sessionService.StockEgmReponer(model).subscribe((result) => {
            if (result.error == null) {
              this.GoToBack();
            }
          });
        }
      }
    }
  }

  async stockEgmAndGenerateTicket(model: SessionStokEgmViewModel) {
    if (await this.printerService.isPrinterReady()) {
      var ticketQuantity = 1;

      var error = false;

      for (let index = 1; index <= ticketQuantity && !error; index++) {
        if (await this.printerService.isPrinterReady()) {
          var result = await this.sessionService.StockEgmReponer(model).toPromise();
          if (result?.data?.tickets.length > 0) {
            await this.printerService.print(result?.data?.tickets[0], index - 1);
          } else {
            error = true;
          }
        } else {
          error = true;
        }
      }

      if (!error) {
        //this.myForm.resetForm();
        this.GoToBack();
      }
    }
  }
}
