import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CombinationProfitViewModel } from 'src/app/common/components/viewmodels/CombinationProfitViewModel';
import { DailyBalance } from 'src/app/common/components/viewmodels/DailyBalance';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { LocationService } from 'src/app/services/location/location.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';

@Component({
  selector: 'app-combination-profit',
  templateUrl: './combination-profit.component.html',
  styleUrls: ['./combination-profit.component.css'],
})
export class CombinationProfitComponent implements OnInit {
  InputType = InputTypeEnum;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CombinationProfitViewModel>;

  egmCombo: Array<IdTextModel> = [];
  creditValueCombo: Array<IdTextModel> = [];
  items: Array<BreadcrumbItem> = [];
  public data: commonTableData<CombinationProfitViewModel>;
  formSearch: FormGroup;
  searchMade: boolean;
  currencyCode: string;
  currencySym: string;
  title: string = 'Ganancia por Grupo EGMs';
  dateRange: string;
  totalProfit: number = 0;
  machineQuantity: number = 0;

  public static getName() {
    return 'CombinationProfitComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public EgmService: EgmServiceService,
    public DailyBalanceService: DailyBalanceService,
    public CasinoService: CasinoService,
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
    this.fillCombos();
    this.initForm();
    this.initGrid();
  }

  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.showAll = true;

    const that = this;
    this.data.columns = [
      { data: 'egm.uid', title: 'UID' },
      { data: 'egm.nickName', title: 'EGM Nombre' },

      { data: 'egm.creditValue', title: 'Grupo EGMs' },
      // {
      //   data: 'betAverage',
      //   title: 'Prom. Apuestas',
      //   render: function (data, type, row) {
      //     return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
      //   },
      // },
      {
        data: 'formattedBetCombination',
        title: 'Apuestas',
        render: function (data, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedPayedCombination',
        title: 'Pagos',
        render: function (data, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'daysRange',
        title: 'Días',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'formattedBenefitCombination',
        title: 'Beneficio',
        render: function (data, type, row: EGM) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedDailyAverage',
        title: 'Prom. diario',
        render: function (data, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'realProfitPercentage',
        title: '% Real',
        render: function (data: number, type, row) {
          return that.percentPipe.transform(data, '2.2');
        },
      },
      {
        data: 'egm.egmConfig.formattedPaymentPercentageProb',
        title: '% Teórico',
        render: function (data: number, type, row) {
          return that.percentPipe.transform(data, '2.2');
        },
      },
    ];
  }

  onSearch(status: tableStatus) {
    //  this.parameter.status = status;
    this.totalProfit = this.table.decimalData;
    this.machineQuantity = this.table.intData;
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.DailyBalanceService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CombinationProfitViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getCombinationProfitReport(DataTableFilters);
    };
  }

  updateTable(filters: MultiselectFilters) {
    this.totalProfit = 0;
    this.machineQuantity = 0;
    this.setGridData(filters);
    this.filterString();
    this.table.reload();
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
      creditValue: new FormControl([]),
    });
  }

  onSubmit() {
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();
    this.searchMade = true;

    this.updateTable(this.getFilters());
  }
  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    if (this.formSearch.get('creditValue').value != null) {
      filters.creditValue = this.formSearch.get('creditValue').value;
    }
    if (this.formSearch.get('egmUid').value != null) {
      filters.egms = this.formSearch.get('egmUid').value;
    }

    return filters;
  }
  private fillCombos() {
    this.EgmService.getCreditValueCombo().subscribe((result) => {
      this.creditValueCombo = result.data;
    });
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters + ' - ';
    }
    if (this.formSearch.get('creditValue').value.length > 0) {
      aux += ' Salas: ' + this.creditValueFilters + ' - ';
    }

    if (this.table != undefined) {
      this.table.setFilterString(aux);
    }
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get creditValueFilters(): string {
    let combo = this.creditValueCombo;
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('creditValue').value.length; index++) {
      const element = this.formSearch.get('creditValue').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('creditValue').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }
}
