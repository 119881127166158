<label [class.disabled]="ngControl?.disabled" *ngIf="ShowLabelUp()">{{ label }}</label>
<ng-select
  #mySelect
  [items]="list"
  multiple="true"
  [(ngModel)]="value"
  [ngClass]="{ 'is-invalid': (ngControl.touched || ngControl.dirty || formSubmitted) && ngControl.invalid }"
  class="common-input-select"
  [placeholder]="placeHolder"
  [disabled]="disabled"
  (change)="emitChange($event)"
  [clearable]="allowClear"
  bindValue="id"
  bindLabel="text"
  (blur)="onTouched($event)">
</ng-select>
<div
  *ngIf="(ngControl?.dirty || ngControl?.touched || formSubmitted) && ngControl?.errors?.required"
  class="text-danger">
  {{ label }} es un campo obligatorio.
</div>
