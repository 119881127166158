import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';

import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { CollectionFooterViewModel, CollectionViewModel } from 'src/app/common/components/viewmodels/SessioModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-session-cash-count-report',
  templateUrl: './session-cash-count-report.component.html',
  styleUrls: ['./session-cash-count-report.component.css'],
})
export class SessionCashCountReportComponent {
  public static getName() {
    return 'SessionCashCountReportComponent';
  }

  items: Array<BreadcrumbItem> = [];
  title: string = 'Reporte de Arqueo';

  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<
    CollectionViewModel,
    CollectionFooterViewModel
  >;

  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;

  currencyCode: string;
  currencySym: string;
  public data: commonTableData<CollectionViewModel>;
  formSearch: FormGroup;
  dateRange: string;
  filterParamString: string;
  footerIsLoaded: boolean = false;
  constructor(
    public sessionService: SessionService,
    public notificationService: NotificationService,

    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Gestión'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.initForm();
    this.initGrid();

    this.formSearch.get('from').disable();
  }

  changePendingButton($event) {
    if ($event.target.value === 'toCollect') {
      this.formSearch.get('from').disable();
    } else {
      this.formSearch.get('from').enable();
    }
  }

  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;

    const that = this;
    this.data.columns = [
      { data: 'egm.uidFormatted', title: 'UID' },
      { data: 'egm.nickName', title: 'EGM Nombre' },
      {
        data: 'collectionDate',
        title: 'Fecha',
        render: function (data, type, row) {
          if (data == null) {
            return '-';
          }
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'formattedTotalMoviments',
        title: 'Total Movimientos',
        className: 'colorAgrupador',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedReposicionMaquinaAmount',
        title: 'Reposición Máquina (+)',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedRetiradaMaquinaAmount',
        title: 'Retirada Máquina (-)',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },

      {
        data: 'formattedPagoManualAmount',
        title: 'Pago Manual (+)',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedPagoManualSinCodigoAmount',
        title: 'Pago Manual Sin Código (+)',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedTheoricToTake',
        title: 'Efectivo a Retirar EGM',
        className: 'colorAgrupador',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedSettlementTehoric',
        title: 'Arqueo Teórico EGM (+)',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'formattedDepositInitialBefore',
        title: 'Depósito Inicial Anterior (-)',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
    ];
    // this.data.rowCallback = function (row: Node, data: any | Object, index: number): void {
    //   //saco las clases que puse  en las cabeceras para q solo quede en las cabecera

    //   that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     $($('td', row)).removeClass('colorfloralwhite colorlavenderblush colorAgrupador');
    //   });
    // };
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
      egmUid: new FormControl([]),
      toCollect: new FormControl('toCollect'),
    });
  }

  onSubmit() {
    this.updateTable();
  }

  updateTable() {
    this.dateRange = null;
    if (this.formSearch.get('from').value != null && this.formSearch.get('to').value != null) {
      this.dateRange =
        moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
        ' - ' +
        moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();
    }
    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  filterString() {
    let aux: string = '';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }

    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters + ' - ';
    }

    if (this.table != undefined) {
      this.table.setFilterString(' Filtros: - ' + aux);
    }

    aux += this.formSearch.get('toCollect').value == 'toCollect' ? 'Pendiente Recaudar' : 'Recaudado';

    this.filterParamString = aux;
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  getFilters(): MultiselectFilters {
    var filters = new MultiselectFilters();

    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;

    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
    }

    filters.ToCollect = this.formSearch.get('toCollect').value == 'toCollect' ? true : false;

    return filters;
  }

  updateEgms() {
    this.formSearch.get('egmUid').setValue([]);

    var filters: MultiselectFilters = new MultiselectFilters();

    filters.egms = this.formSearch.get('egmUid').value;
    filters.cashRegisters = this.formSearch.get('cashRegisters').value;
    this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
  }

  setGridData(filters: MultiselectFilters) {
    let service = this.sessionService;

    this.data.getData = function (
      param: any
    ): Observable<ApiResult<DataTableResult<CollectionViewModel, CollectionFooterViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.GetCashCountReport(DataTableFilters);
    };
  }

  onSearch(status: tableStatus) {
    this.footerIsLoaded = true;
  }
}
