<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                <span> {{ headerTitle }}</span>
              </h3>
              <div class="card-tools">
                <button #myCollapse type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i id="toolBtn" class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <form
              role="form"
              [formGroup]="formEdit"
              (ngSubmit)="onSubmit(formObject)"
              #formObject="ngForm"
              autocomplete="off">
              <div class="card-body">
                <div class="form-row">
                  <div class="col-md-4 col-sm">
                    <!-- <common-input-select
                      class="form-control-sm"
                      [label]="'Turnos'"
                      [list]="this.turnsCombo"
                      (valueChanged)="changeTurn()"
                      formControlName="turnoId"></common-input-select> -->

                    <app-turn-combo
                      [multiselect]="false"
                      [allowClear]="true"
                      [onlyOpen]="true"
                      [formSubmitted]="formObject.submitted"
                      (valueChanged)="changeTurn()"
                      (finishLoad)="finishLoad()"
                      [control]="formEdit.get('turnId')"></app-turn-combo>
                  </div>
                  <div class="form-group col-12 col-md-2">
                    <app-common-input
                      [control]="this.formEdit.get('amount')"
                      [label]="importeLabel"
                      [formSubmitted]="formObject.submitted"
                      [type]="InputType.numeric">
                    </app-common-input>
                  </div>

                  <div class="d-flex align-items-center ml-4" *ngIf="visibleTito">
                    <div class="col-12">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          id="emitirTito"
                          type="radio"
                          value="emitirTito"
                          name="showTito"
                          formControlName="showTito" />
                        <label class="form-check-label" for="true">Emitir TITO</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          id="notito"
                          type="radio"
                          value="notito"
                          name="showTito"
                          formControlName="showTito" />
                        <label class="form-check-label" for="notito">NO Emitir TITO</label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-12">
                    <app-common-input
                      [control]="formEdit.get('observations')"
                      [label]="'Observaciones'"
                      [type]="InputType.textarea">
                    </app-common-input>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->

              <div class="card-footer text-right">
                <button type="submit" class="btn btn-primary">Aceptar</button>

                <button type="button" class="btn btn-secondary ml-2" (click)="GoToBack()">Volver</button>
              </div>
            </form>
          </div>
          <!-- /.card -->
        </div>
      </div>
    </div>

    <div class="" *ngIf="visibleTito">
      <app-ticket-printer-status></app-ticket-printer-status>
    </div>
  </div>
</div>
<app-confirm-modal yes="Continuar" (onConfirm)="ConfirmAction()" [body]="body"> </app-confirm-modal>
