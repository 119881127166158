<common-header [title]="title" [items]="items"></common-header>

<div class="content" *ngIf="loadedForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="card-tools">
              <button type="button" class="btn btn-tool mr-2" (click)="updateTable()">
                Refrescar
                <i class="fas fa-redo"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <app-common-table [model]="data" (onSearch)="onSearch($event)"> </app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
