import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { CashRegisterTypeEnum } from 'src/app/common/components/viewmodels/SessioModel';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-cash-registers',
  templateUrl: './edit-cash-registers.component.html',
  styleUrls: ['./edit-cash-registers.component.css'],
})
export class EditCashRegistersComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Nueva caja';
  cashRegisterType: Array<IdTextModel> = [];
  items: Array<BreadcrumbItem> = [];

  InputType = InputTypeEnum;

  cashRegister: CashRegister = new CashRegister();
  isEdit: boolean;
  showCM: boolean = false;
  parameter: tableStatus = null;
  cashRegisterId: number;

  public static getName() {
    return 'EditCashRegistersComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private CashRegisterService: CashRegisterService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.parameter = this.paramService.getAndDeleteParameters();

    this.CashRegisterService.getCashRegistersTypeCombo().subscribe((result) => {
      this.cashRegisterType = result.data;

      if (this.activatedRoute.snapshot.params.id != null) {
        this.cashRegisterId = parseInt(this.activatedRoute.snapshot.params.id);
        console.log(this.cashRegisterId);
        this.CashRegisterService.getCashRegister(parseInt(this.activatedRoute.snapshot.params.id)).subscribe(
          (result) => {
            this.cashRegister = result.data;
            this.formEdit.patchValue(this.cashRegister);
            this.setCMCombo(this.cashRegister.cashRegisterTypeId);
          }
        );

        this.title = 'Editar caja';
        this.isEdit = true;
      }
    });

    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('/establishment/cash-registers', 'Cajas'));
    this.items.push(new BreadcrumbItem('', this.title));
    if (this.isEdit) {
      this.formEdit.get('cuid').clearValidators();
      this.formEdit.get('cuid').disable();
    }
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('establishment/cash-registers');
  }

  onSubmit() {
    if (this.formEdit.valid) {
      var cashRegister = new CashRegister(this.formEdit.value);
      cashRegister.id = this.formEdit.get('id').value;
      if (this.isEdit) {
        cashRegister.cuid = this.cashRegister.cuid;
      } else {
        cashRegister.cuid = Number(this.formEdit.get('cuid').value);
      }
      this.paramService.saveParameters(this.parameter);
      this.CashRegisterService.createOrEdit(cashRegister).subscribe((result) => {
        if (result.error == null) {
          this.router.navigate(['/establishment/cash-registers']);
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  changeCashRegisterType($event) {
    this.setCMCombo($event);
  }

  setCMCombo(cashMachineType) {
    if (cashMachineType == CashRegisterTypeEnum.MaquinaDeCambio) {
      this.showCM = true;
      this.formEdit.get('cashMachineId').setValidators(Validators.required);
    } else {
      this.showCM = false;
      this.formEdit.get('cashMachineId').setValue(null);
      this.formEdit.get('cashMachineId').setValidators(null);
    }
    this.formEdit.updateValueAndValidity();
    this.formEdit.get('cashMachineId').enable();
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(0),
      cuid: new FormControl(null, [
        Validators.pattern('[0-9]*'),
        Validators.max(65535),
        Validators.min(1),
        Validators.required,
      ]),
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      cashRegisterTypeId: new FormControl(null, [Validators.required]),
      secondaryName: new FormControl(null, Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())),
      observations: new FormControl(null),
      cashMachineId: new FormControl(null),
    });
  }
}
