import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantService } from './tenant.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private tenantService: TenantService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var tenantId = this.tenantService.getCurrentTenantId();

    var currentComponent = this.tenantService.getCurrentComponentName();

    var newHeaders = httpRequest.headers;
    if (tenantId) newHeaders = newHeaders.set('X-Tenant-Id', tenantId);
    if (currentComponent) newHeaders = newHeaders.set('X-ViewComponent', currentComponent);

    const modifiedReq = httpRequest.clone({
      headers: newHeaders,
    });
    return next.handle(modifiedReq);
  }
}
