import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { SessionManagmentViewModel } from 'src/app/common/components/viewmodels/SessioModel';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-session-management',
  templateUrl: './session-management.component.html',
  styleUrls: ['./session-management.component.css'],
})
export class SessionManagementComponent implements OnInit {
  public static getName() {
    return 'SessionManagementComponent';
  }

  eventsChangeSessionSettlement: Subject<SessionManagmentViewModel> = new Subject<SessionManagmentViewModel>();
  eventsChangeSessionManagement: Subject<SessionManagmentViewModel> = new Subject<SessionManagmentViewModel>();
  title: string = 'Gestión de Sesión';
  items: Array<BreadcrumbItem> = [];
  session: SessionManagmentViewModel = new SessionManagmentViewModel();

  constructor(private router: Router) {
    this.items.push(new BreadcrumbItem('', 'Gestión'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  ngOnInit(): void {}

  ChangeSessionManagment($event: SessionManagmentViewModel): void {
    this.router.navigate(['/session-settlement', $event.cashRegisterId]);
  }
}
