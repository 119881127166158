import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Room } from 'src/app/common/components/viewmodels/Room';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class RoomServiceService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getRoomsCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Rooms/GetRoomsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getRooms(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<Room>>> {
    return this.http.post<ApiResult<DataTableResult<Room>>>(`${this.baseUrl}Rooms/GetRooms`, dataTablesParameters).pipe(
      map((x) => {
        var result = this.checkResult<DataTableResult<Room>>(x);
        return result;
      }),
      catchError((error) => this.handleError<DataTableResult<Room>>(error))
    );
  }

  public deleteRoom(id: number): Observable<ApiResult<Room>> {
    return this.http.delete<ApiResult<Room>>(`${this.baseUrl}Rooms/DeleteRoom?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<Room>(x);
        return result;
      }),
      catchError((error) => this.handleError<Room>(error))
    );
  }

  public createOrEdit(room: Room): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Rooms/CreateOrEdit`, room).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getRoomById(id: number): Observable<ApiResult<Room>> {
    return this.http.get<ApiResult<Room>>(`${this.baseUrl}Rooms/GetRoomById?id=${id}`).pipe(
      map((x) => this.checkResult<Room>(x)),
      catchError((error) => this.handleError<Room>(error))
    );
  }
}
