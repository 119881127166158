import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CountersEditLog } from 'src/app/common/components/viewmodels/CountersEditLog';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { OperationType } from 'src/app/common/components/viewmodels/Enums';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { LogsService } from 'src/app/services/logs/logs.service';

@Component({
  selector: 'app-counters-edit-log',
  templateUrl: './counters-edit-log.component.html',
  styleUrls: ['./counters-edit-log.component.css'],
})
export class CountersEditLogComponent implements OnInit {
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CountersEditLog>;

  title: string = 'Log Modificación Contadores';
  regex: RegExp = new RegExp('_', 'g');
  items: Array<BreadcrumbItem> = [];

  public data: commonTableData<CountersEditLog>;

  currencyCode: string;
  currencySymbol: string;

  formSearch: FormGroup;
  dateRange: string;

  public static getName() {
    return 'CountersEditLogComponent';
  }
  constructor(
    private router: Router,
    private LogService: LogsService // private CountersService: CountersService, // private CasinoService: CasinoService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Seguridad'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.initForm();
    // this.CasinoService.getData().subscribe((result) => {
    //   this.currencyCode = result.data.currencyCode;
    //   this.currencySymbol = result.data.currencySymbol;
    // });
    this.initGrid();
  }

  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    const that = this;

    this.data.columns = [
      {
        data: 'username',
        title: 'Usuario',
        render: function (data: string, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'formattedDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'uid',
        title: 'UID',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'operationType',
        title: 'Tipo de Operación',
        render: function (data: string, type, row) {
          return data != null ? OperationType[data].replace(that.regex, ' ') : '-';
        },
      },
      {
        data: 'changes',
        title: 'Cambios',
        render: function (data: string, type, row) {
          return data != null ? data : '-';
        },
      },
    ];

    this.data.rowCallback = function (row: Node, data: CountersEditLog | Object, index: number): void {
      that.table?.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        $($('td', row)[4]).addClass('text-left');
      });
    };
  }

  updateTable(filters: MultiselectFilters) {
    this.setGridData(filters);
    this.table.reload();
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.LogService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CountersEditLog>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getCountersEditLogs(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy').toString() + ' - ' + moment(filters.to).format('DD/MM/yyyy').toString();
  }

  initForm() {
    this.formSearch = new FormGroup({
      users: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    filters.users = this.formSearch.get('users').value;
    return filters;
  }

  onSubmit() {
    this.updateTable(this.getFilters());
  }
}
