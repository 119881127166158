import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ButtonMovementTypeEnum } from 'src/app/common/components/viewmodels/SessioModel';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-session-movement-cashflow',
  templateUrl: './session-movement-cashflow.component.html',
  styleUrls: ['./session-movement-cashflow.component.css'],
})
export class SessionMovementCashflowComponent implements OnInit {
  public static getName() {
    return 'SessionMovementCashFlowComponent';
  }

  sessionMovementType: ButtonMovementTypeEnum;
  idTurn: number = 0;
  items: Array<BreadcrumbItem> = [];
  title: string = '';
  loadPage: boolean = false;
  showprintstatus: boolean = false;

  constructor(
    private router: Router,

    public sessionService: SessionService,
    public notificationService: NotificationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.idTurn = Number(paramTurnsId);
      if (isNaN(this.idTurn)) {
        this.goToErrorPage();
      } else {
        this.sessionMovementType = this.sessionService.CurrentMovement;

        switch (this.sessionMovementType) {
          case ButtonMovementTypeEnum.IncreaseFunds:
            this.title = 'Aumento de Fondos';
            break;
          case ButtonMovementTypeEnum.ReduceFunds:
            this.title = 'Disminución Fondo';
            break;

          default:
            this.goToBack();
        }
        this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
        this.items.push(new BreadcrumbItem('', this.title));

        this.sessionService.CleanCurrentMovement();
        this.loadPage = true;
      }
    } else {
      this.goToErrorPage();
    }
  }
  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }
  goToBack() {
    if (this.idTurn != 0) {
      this.router.navigate(['/session-cashflow', this.idTurn]);
    }
  }
}
