<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange != null"> - {{ dateRange }} </span>
              </h3>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [showTime]="true"
                    [controlFrom]="this.formSearch.get('from')"
                    [controlTo]="this.formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              #table
              [model]="data"
              (onCustomButton)="onCustomButton($event)"
              [excel]="true"
              [title]="this.title">
            </app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #detailModal let-modal class="modal">
  <div class="modal-header">
    <h3 class="modal-title">Detalle de poder público:</h3>
  </div>
  <div class="modal-body">
    <div class="row px-3">
      <table class="table table-hover w-100">
        <thead class="text-center">
          <tr>
            <th>Fecha</th>
            <th>Concepto</th>
            <th>Cant. tickets</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody *ngIf="publicHoldingDetail != null">
          <tr>
            <td>{{ moment(publicHoldingDetail.day).format('DD/MM/yyyy') }}</td>
            <td>(+) PP anterior</td>
            <td>{{ publicHoldingDetail.prevPublicHoldingQuantity }}</td>
            <td>
              {{
                currencyPipe.transform(publicHoldingDetail.formattedPrevPublicHoldingAmount, currencyCode, currencySym)
              }}
            </td>
          </tr>
          <tr>
            <td>{{ moment(publicHoldingDetail.day).format('DD/MM/yyyy') }}</td>
            <td>(-) Tickets expirados y cancelados en el día de días anteriores</td>
            <td>{{ publicHoldingDetail.expiredQuantity }}</td>

            <td>{{ currencyPipe.transform(publicHoldingDetail.formattedExpiredAmount, currencyCode, currencySym) }}</td>
          </tr>
          <tr>
            <td>{{ moment(publicHoldingDetail.day).format('DD/MM/yyyy') }}</td>
            <td>(-) Tickets pagados en el día de días anteriores</td>
            <td>{{ publicHoldingDetail.prevCashedQuantity }}</td>
            <td>
              {{ currencyPipe.transform(publicHoldingDetail.formattedPrevCashedAmount, currencyCode, currencySym) }}
            </td>
          </tr>
          <tr>
            <td>{{ moment(publicHoldingDetail.day).format('DD/MM/yyyy') }}</td>
            <td>(+) PP del día</td>
            <td>{{ publicHoldingDetail.dailyQuantity }}</td>

            <td>{{ currencyPipe.transform(publicHoldingDetail.formattedDailyAmount, currencyCode, currencySym) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" (click)="dismissModal()">Continuar</button>
    </div>
  </div>
</ng-template>
