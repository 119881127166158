import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { CashRegisterComboComponent } from 'src/app/common/components/business/cash-register-combo/cash-register-combo.component';
import { UsersComboComponent } from 'src/app/common/components/business/users-combo/users-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { TicketFamily, TicketFamilyGroup } from 'src/app/common/components/viewmodels/Enums';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { TicketTracker } from 'src/app/common/components/viewmodels/TicketTracker';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-cashed-tickets',
  templateUrl: './cashed-tickets.component.html',
  styleUrls: ['./cashed-tickets.component.css'],
})
export class CashedTicketsComponent implements OnInit {
  @ViewChild(UsersComboComponent) usersCombo: UsersComboComponent;
  @ViewChild(CashRegisterComboComponent) cashCombo: CashRegisterComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<TicketTracker>;
  currencySym: string;
  currencyCode: string;
  items: Array<BreadcrumbItem> = [];
  title: string = 'Tickets Pagados por Caja';
  public data: commonTableData<TicketTracker>;
  formSearch: FormGroup;
  dateRange: string;

  public static getName() {
    return 'CashedTicketsComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public TicketService: TicketService,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.initForm();
    this.initGrid();
  }
  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    const that = this;

    this.data.columns = [
      { data: 'ticket.validatorCode', title: 'Código' },
      {
        data: 'ticket.formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'ticket.family.familyGroupId',
        title: 'Familia',
        render: function (data: number, type, row) {
          return data != null ? TicketFamilyGroup[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'cashRegisterName',
        title: 'Caja',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },

      {
        data: 'systemDate',
        title: 'Fecha de Sistema',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
    ];
  }

  setGridData(filters: MultiselectFilters) {
    let service = this.TicketService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<TicketTracker>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getCashedTickets(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
      cashRegisters: new FormControl([]),
      users: new FormControl([]),
    });
  }

  updateTable() {
    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }

    if (this.formSearch.get('users').value.length > 0) {
      let combo = this.usersCombo.getCombo();
      var user = combo.find((e) => e.id == this.formSearch.get('users').value).text;
      aux += ' Usuario: ' + user + ' - ';
    }

    if (this.formSearch.get('cashRegisters').value.length > 0) {
      let combo = this.cashCombo.getCombo();

      if (combo.find((e) => e.id == this.formSearch.get('cashRegisters').value) != null) {
        var caja = combo.find((e) => e.id == this.formSearch.get('cashRegisters').value).text;
        aux += ' Caja: ' + caja + ' - ';
      }
    }

    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  getFilters(): MultiselectFilters {
    var filters = new MultiselectFilters();
    filters.cashRegisters = this.formSearch.get('cashRegisters').value;
    filters.users = this.formSearch.get('users').value;
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;
    return filters;
  }

  onSubmit() {
    console.log(this.formSearch.valid);
    if (this.formSearch.valid) {
      this.updateTable();
    }
  }
}
