import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CoinInChartViewModel } from 'src/app/common/components/viewmodels/BarChartViewModel';
import { BillsQuantityViewModel } from 'src/app/common/components/viewmodels/BillsQuantityViewModel';
import { CombinationProfitViewModel } from 'src/app/common/components/viewmodels/CombinationProfitViewModel';
import {
  DailyAccountingReport,
  DailyAccountingReportFooterViewModel,
  DailyBalance,
} from 'src/app/common/components/viewmodels/DailyBalance';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MinimalFilters, MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { InsertDailyBalanceViewModel } from 'src/app/common/components/viewmodels/InsertDailyBalanceViewModel';
import {
  DailyProfitChartViewModel,
  HourlyCoinInChartViewModel,
  HourlyProfitChartViewModel,
  LineChartViewModel,
} from 'src/app/common/components/viewmodels/LineChartViewModel';
import {
  MoneyDropsFooterViewModel,
  MoneyDropsViewModel,
} from 'src/app/common/components/viewmodels/MoneyDropsViewModel';
import { HourlyProfitComponent } from 'src/app/pages/hourly-profit/hourly-profit.component';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class DailyBalanceService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public insertDailyBalance(insertBalance: InsertDailyBalanceViewModel): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}DailyBalances/InsertDailyBalance`, insertBalance).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getDailys(param: DatatableMultiFilters): Observable<ApiResult<DataTableResult<DailyBalance>>> {
    return this.http
      .post<ApiResult<DataTableResult<DailyBalance>>>(`${this.baseUrl}DailyBalances/GetDailyBalances`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<DailyBalance>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<DailyBalance>>(error))
      );
  }
  public getDailyAccountReport(
    param: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<DailyAccountingReport, DailyAccountingReportFooterViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<DailyAccountingReport, DailyAccountingReportFooterViewModel>>>(
        `${this.baseUrl}DailyBalances/GetDailyAccountReport`,
        param
      )
      .pipe(
        map((x) => {
          var result =
            this.checkResult<DataTableResult<DailyAccountingReport, DailyAccountingReportFooterViewModel>>(x);
          return result;
        }),
        catchError((error) =>
          this.handleError<DataTableResult<DailyAccountingReport, DailyAccountingReportFooterViewModel>>(error)
        )
      );
  }

  // public getDailyAccountingReportFooter(
  //   param: DatatableMultiFilters
  // ): Observable<ApiResult<DailyAccountingReportFooterViewModel>> {
  //   return this.http
  //     .post<ApiResult<DailyAccountingReportFooterViewModel>>(
  //       `${this.baseUrl}DailyBalances/GetDailyAccountingReportFooter`,
  //       param
  //     )
  //     .pipe(
  //       map((x) => {
  //         var result = this.checkResult<DailyAccountingReportFooterViewModel>(x);
  //         return result;
  //       }),
  //       catchError((error) => this.handleError<DailyAccountingReportFooterViewModel>(error))
  //     );
  // }

  public getQuantityReport(
    param: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<BillsQuantityViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<BillsQuantityViewModel>>>(
        `${this.baseUrl}DailyBalances/GetBillsQuantityReport`,
        param
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<BillsQuantityViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<BillsQuantityViewModel>>(error))
      );
  }
  public getMoneyDropsReport(
    param: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<MoneyDropsViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<MoneyDropsViewModel>>>(`${this.baseUrl}DailyBalances/GetMoneyDropsReport`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<MoneyDropsViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<MoneyDropsViewModel>>(error))
      );
  }

  public getMoneyDropsFooterReport(param: MultiselectFilters): Observable<ApiResult<MoneyDropsFooterViewModel>> {
    return this.http
      .post<ApiResult<MoneyDropsFooterViewModel>>(`${this.baseUrl}DailyBalances/GetMoneyDropsFooterReport`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<MoneyDropsFooterViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<MoneyDropsFooterViewModel>(error))
      );
  }

  public recalculateProfits(param: MultiselectFilters): Observable<ApiResult<DataTableResult<DailyBalance>>> {
    return this.http
      .post<ApiResult<DataTableResult<DailyBalance>>>(`${this.baseUrl}DailyBalances/RecalculateProfits`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<DailyBalance>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<DailyBalance>>(error))
      );
  }

  public togglePeriod(param: MultiselectFilters): Observable<ApiResult<DataTableResult<DailyBalance>>> {
    return this.http
      .post<ApiResult<DataTableResult<DailyBalance>>>(`${this.baseUrl}DailyBalances/TogglePeriod`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<DailyBalance>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<DailyBalance>>(error))
      );
  }

  public calculateProfit(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}DailyBalances/CalculateProfits`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public updateCounters(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}DailyBalances/UpdateCounters`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public checkEgmStatus(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}DailyBalances/CheckEgmStatus`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public checkCashMachineStatus(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}DailyBalances/checkCashMachineStatus`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public parseMessage(message: string): Observable<ApiResult<string>> {
    var messageEncode = encodeURIComponent(message);
    return this.http
      .get<ApiResult<string>>(`${this.baseUrl}DailyBalances/ParseMessage?message=${messageEncode}`)
      .pipe(catchError((error) => this.handleError<string>(error)));
  }

  public getCombinationProfitReport(
    param: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<CombinationProfitViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<CombinationProfitViewModel>>>(
        `${this.baseUrl}DailyBalances/GetCombinationProfitReport`,
        param
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CombinationProfitViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CombinationProfitViewModel>>(error))
      );
  }
  public getCurrentProfitGraphicReport(filters: MultiselectFilters): Observable<ApiResult<LineChartViewModel>> {
    return this.http
      .post<ApiResult<LineChartViewModel>>(`${this.baseUrl}DailyBalances/GetCurrentProfitGraphicReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<LineChartViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<LineChartViewModel>(error))
      );
  }
  public getCoinInGraphicReport(filters: MinimalFilters): Observable<ApiResult<CoinInChartViewModel>> {
    return this.http
      .post<ApiResult<CoinInChartViewModel>>(`${this.baseUrl}DailyBalances/GetCoinInGraphicReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<CoinInChartViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<CoinInChartViewModel>(error))
      );
  }
  public getDailyProfitGraphicReport(filters: MinimalFilters): Observable<ApiResult<DailyProfitChartViewModel>> {
    return this.http
      .post<ApiResult<DailyProfitChartViewModel>>(`${this.baseUrl}DailyBalances/GetDailyProfitGraphicReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DailyProfitChartViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<DailyProfitChartViewModel>(error))
      );
  }
  public getCoinInAverageGraphicReport(filters: MinimalFilters): Observable<ApiResult<CoinInChartViewModel>> {
    return this.http
      .post<ApiResult<CoinInChartViewModel>>(`${this.baseUrl}DailyBalances/GetCoinInAverageGraphicReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<CoinInChartViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<CoinInChartViewModel>(error))
      );
  }
  public getDailyProfitAverageReport(filters: MinimalFilters): Observable<ApiResult<DailyProfitChartViewModel>> {
    return this.http
      .post<ApiResult<DailyProfitChartViewModel>>(`${this.baseUrl}DailyBalances/GetDailyProfitAverageReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DailyProfitChartViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<DailyProfitChartViewModel>(error))
      );
  }
  public getHourlyProfitGraphicReport(filters: MinimalFilters): Observable<ApiResult<HourlyProfitChartViewModel>> {
    return this.http
      .post<ApiResult<HourlyProfitChartViewModel>>(`${this.baseUrl}DailyBalances/GetHourlyProfitGraphicReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<HourlyProfitChartViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<HourlyProfitChartViewModel>(error))
      );
  }
  public getHourlyCoinInGraphicReport(filters: MinimalFilters): Observable<ApiResult<HourlyCoinInChartViewModel>> {
    return this.http
      .post<ApiResult<HourlyCoinInChartViewModel>>(`${this.baseUrl}DailyBalances/GetHourlyCoinInGraphicReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<HourlyCoinInChartViewModel>(x);
          return result;
        }),
        catchError((error) => this.handleError<HourlyCoinInChartViewModel>(error))
      );
  }
}
