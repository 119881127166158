<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="card">
          <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
            <div class="card-body">
              <div class="form-row">
                <validation-code
                  [control]="formSearch.get('ticketCode')"
                  [cleanAfterCompleted]="true"
                  (inputFull)="onFullInput($event)"
                  [formSubmitted]="formObject.submitted"
                  [focus]="true"
                  class="w-100">
                </validation-code>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="text-info font-size-14 col-12 col-sm-6" style="font-size: 14px">
                  <span *ngIf="lastSerch !== ''">*Último buscado: {{ lastSerch }}</span>
                </div>
                <div class="d-flex justify-content-end col-12 col-sm-6">
                  <button [disabled]="formSearch.get('ticketCode').value == null" type="submit" class="btn btn-info">
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /.row -->
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table [model]="data"></app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>
