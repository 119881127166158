<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              [excel]="true"
              (onSearch)="onSearch($event)"
              [title]="this.title"></app-common-table>
          </div>
          <div class="card-footer bg-dark">
            <div class="row">
              <div class="col-6 d-flex ml-4">
                <span>Importe total:</span>
              </div>
              <div class="col-5 d-flex justify-content-end">
                <div class="d-flex">
                  <span>{{ currencyPipe.transform(this.total, currencyCode, currencySym) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
