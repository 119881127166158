<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">{{ title }}</h1>
      </div>
      <div class="col-sm-6">
        <common-breadcrumb [items]="items"></common-breadcrumb>
      </div>
    </div>
  </div>
</div>
