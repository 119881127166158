import { CountersBase } from './CountersBase';
import { CountersStatus } from './Enums';

export class CountersMock extends CountersBase {
  countersDate: moment.Moment;
  countersStatus: CountersStatus;
  subperiodStart: boolean;
  period: number;
  periodCreation: moment.Moment;
  periodCreationUserId: string;
  isInCollection: boolean;

  public constructor(init?: Partial<CountersMock>) {
    super();
    Object.assign(this, init);
  }
}

export class CountersCurrent extends CountersBase {
  hostDate: moment.Moment;
  lastAddedToCounters: moment.Moment;
  eventDate: moment.Moment;

  public constructor(init?: Partial<CountersCurrent>) {
    super();
    Object.assign(this, init);
  }
}
