import * as moment from 'moment-timezone';

export class Casino {
  id: number;
  companyName: string;
  facilityName: string;
  facilityStart: moment.Moment;
  formattedFacilityStart: string;
  systemStart: moment.Moment;
  formattedSystemStart: string;
  address: string;
  phoneNumber: string;
  cuit: string;
  currencyCode: string;
  currencySymbol: string;
  dayStart: moment.Moment;
  formattedDayStart: string;
  localTimeZone: string;

  insertCountersSpanSecond: number;
  profitSpanSecond: number;
  egmSecondsHostTimeout: number;
  apikey: string;
  url: string;
  spanSyncSeconds: number;

  formattedDataphoneMaxUnauthorizedAmount?: number;
  formattedBillMaxUnauthorizedAmount?: number;
  formattedBetMaxUnauthorizedAmount?: number;
  formattedExpensesMaxUnauthorizedAmount?: number;
  formattedExternalJackpotMaxUnauthorizedAmount?: number;
  formattedManualCodeMaxUnauthorizedAmount?: number;
  formattedManualTicketMaxUnauthorizedAmount?: number;
  formattedImpactCodeMaxUnauthorizedAmount?: number;
  formattedImpactTicketMaxUnauthorizedAmount?: number;

  dataphoneMaxAmountWithPrecisionView?: number;
  billMaxAmountWithPrecisionView?: number;
  betMaxAmountWithPrecisionView?: number;
  expensesMaxAmountWithPrecisionView?: number;
  externalJackpotMaxAmountWithPrecisionView?: number;
  manualCodeMaxAmountWithPrecisionView?: number;
  manualTicketMaxAmountWithPrecisionView?: number;
  impactCodeMaxAmountWithPrecisionView?: number;
  impactTicketMaxAmountWithPrecisionView?: number;

  public constructor(init?: Partial<Casino>) {
    Object.assign(this, init);
  }
}
export class CurrencyData {
  currencyCode: string;
  currencySymbol: string;
}

export const DataConsts = {
  precisionFactor: 100,
};

export class CasinoTest {
  stringHourUtc: string;
  stringHourMappedDatimeUtc: string;
  momentHourUtc: moment.Moment;

  stringHourLocal: string;
  stringHourMappedDatimeLocal: string;
  momentHourLocal: moment.Moment;

  testHourLocaleServer: string;
}

export class Tenant {
  id: number;
  tenantId: number;
  description: string;
  apikey: string;
  lastUpdateDate: moment.Moment;
  lastSyncId: number;
  serverTablesVersion: moment.Moment;
  isDeleted: boolean;
  info: string;
  tenantVersion: string;
}
