import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { BarChartComponent } from 'src/app/common/components/shared/bar-chart/bar-chart.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { CommonTableComponent } from 'src/app/common/components/shared/common-table/common-table.component';
import { LineChartComponent } from 'src/app/common/components/shared/line-chart/line-chart.component';
import { CoinInChartViewModel } from 'src/app/common/components/viewmodels/BarChartViewModel';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import {
  DailyProfitChartViewModel,
  HourlyProfitChartViewModel,
} from 'src/app/common/components/viewmodels/LineChartViewModel';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';

@Component({
  selector: 'app-hourly-profit',
  templateUrl: './hourly-profit.component.html',
  styleUrls: ['./hourly-profit.component.css'],
})
export class HourlyProfitComponent implements OnInit {
  @ViewChild(LineChartComponent, { static: false }) chart: LineChartComponent;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;

  formSearch: FormGroup;
  items: Array<BreadcrumbItem> = [];
  title: string = 'Reporte Ganancia por Hora por día cerrado';
  public data: HourlyProfitChartViewModel;
  dataStatus: boolean = false;

  public static getName() {
    return 'HourlyProfitComponent';
  }
  constructor(private router: Router, private DailyBalanceService: DailyBalanceService) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Estadísticas'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Ganancia por Hora'));
    this.initForm();
  }
  async setChartData(filters: MinimalFilters): Promise<void> {
    let service = this.DailyBalanceService;
    var result = await service.getHourlyProfitGraphicReport(filters).toPromise();
    this.data = result.data;
  }

  async updateChart() {
    await this.setChartData(this.getFilters());
    this.chart.reload(this.data);
  }
  onSubmit() {
    this.dataStatus = true;
    this.updateChart();
  }

  getFilters() {
    var filters: MinimalFilters = new MinimalFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    if (this.formSearch.get('egmUid').value != null) {
      filters.egm = this.formSearch.get('egmUid').value;
    }
    return filters;
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl(null),
      from: new FormControl(),
      to: new FormControl(),
    });
  }
}
