import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css'],
})
export class ConfirmModalComponent implements OnInit {
  @Input() yes: string = 'Guardar';
  @Input() no: string = 'Cancelar';
  @Input() title: string = 'Atención';
  @Input() body: string = '¿Está seguro que desea continuar?';
  @Input() label: string = 'confirmModal';
  @Input() okOnly: boolean = false;

  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  @ViewChild('confirmModal') confirmModal: ElementRef;

  constructor(public modalService: NgbModal) {}

  ngOnInit(): void {}

  openModal() {
    this.modalService.open(this.confirmModal, { ariaLabelledBy: 'confirmModal' });
  }

  confirm() {
    this.modalService.dismissAll();
    this.onConfirm.emit();
  }

  cancel() {
    this.modalService.dismissAll();
    this.onCancel.emit();
  }

  openModalStatic() {
    this.modalService.open(this.confirmModal, { ariaLabelledBy: 'confirmModal', backdrop: 'static', keyboard: false });
  }
}
