import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from 'inspector';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { SessionOldDetail } from 'src/app/common/components/viewmodels/SessionOldDetail';
import { SessionOldReport } from 'src/app/common/components/viewmodels/SessionOldReport';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class SessionOldReportService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getSession(): Observable<ApiResult<SessionOldReport>> {
    return this.http.get<ApiResult<SessionOldReport>>(`${this.baseUrl}SessionsOld/GetSession`).pipe(
      map((x) => this.checkResult<SessionOldReport>(x)),
      catchError((error) => this.handleError<SessionOldReport>(error))
    );
  }

  public startSession(cashRegister: CashRegister): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}SessionsOld/StartSession`, cashRegister).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public endSession(session: SessionOldReport): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}SessionsOld/EndSession`, session).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public editSession(amount: Number): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}SessionsOld/EditSession`, amount).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getSessionsCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}SessionsOld/GetSessionsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getSessionsComboByUser(userId: string): Observable<ApiResult<IdTextModel[]>> {
    return this.http
      .get<ApiResult<IdTextModel[]>>(`${this.baseUrl}SessionsOld/GetSessionsComboByUserId?userId=${userId}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<IdTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<IdTextModel[]>(error))
      );
  }

  public getSessionsComboByUsersId(userIds: Array<string>): Observable<ApiResult<IdTextModel[]>> {
    return this.http.post<ApiResult<IdTextModel[]>>(`${this.baseUrl}SessionsOld/GetSessionsComboByIds`, userIds).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }
  public getSessionById(id: number): Observable<ApiResult<SessionOldReport>> {
    return this.http.get<ApiResult<SessionOldReport>>(`${this.baseUrl}SessionsOld/GetSessionById?id=${id}`).pipe(
      map((x) => this.checkResult<SessionOldReport>(x)),
      catchError((error) => this.handleError<SessionOldReport>(error))
    );
  }

  public getActiveSessionsCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}SessionsOld/GetActiveSessionsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getUsersComboToSessionDetail(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}SessionsOld/GetUsersComboToSessionDetail`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }
}
