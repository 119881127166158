import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { Location } from 'src/app/common/components/viewmodels/Location';
import { LocationService } from 'src/app/services/location/location.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.css'],
})
export class EditLocationComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Nueva ubicación';
  items: Array<BreadcrumbItem> = [];
  InputType = InputTypeEnum;
  location: Location = new Location();
  isEdit: boolean;
  parameter: tableStatus = null;

  public static getName() {
    return 'EditLocationComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private LocationService: LocationService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.parameter = this.paramService.getAndDeleteParameters();
    if (this.activatedRoute.snapshot.params.id != null) {
      this.LocationService.getLocation(parseInt(this.activatedRoute.snapshot.params.id)).subscribe((result) => {
        this.formEdit.patchValue(result.data);
      });
      this.title = 'Editar ubicación';
      this.isEdit = true;
    }
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('/establishment/locations', 'Ubicaciones'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('establishment/locations');
  }

  onSubmit() {
    if (this.formEdit.valid) {
      this.location = new Location(this.formEdit.value);
      this.location.id = Number(this.location.id);
      this.paramService.saveParameters(this.parameter);
      this.LocationService.createOrEdit(this.location).subscribe((result) => {
        this.router.navigate(['/establishment/locations']);
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(0),
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
    });
  }
}
