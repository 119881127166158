import { Injectable } from '@angular/core';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FakePrinter } from './fake-printer';
import { PrinterOptions } from './printer-options';
import { PrinterStatus } from './printer-status';
import { SerialOptions, SerialPort } from './serial';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  public isBrowserSupported: Boolean = false;
  public processing: Boolean = false;
  public printerOptions: PrinterOptions = new PrinterOptions();
  public currentPort: SerialPort;
  public status: PrinterStatus;

  public ports: SerialPort[];
  reader: ReadableStreamDefaultReader<any>;
  writer: WritableStreamDefaultWriter<any>;
  decoder = new TextDecoder();
  encoder = new TextEncoder();

  constructor(private toastr: ToastrService) {
    this.getFromStorage();
    this.setPrintingInternalProcessing(false);

    if (!this.printerOptions.isFake) {
      this.isBrowserSupported = 'serial' in navigator;
      if (this.isBrowserSupported) {
        this.getFromStorage();
        this.getPorts().then((x) => {
          this.findPort(false, true).then((y) => {});
        });
      }
    } else {
      this.isBrowserSupported = true;
      this.currentPort = new FakePrinter();
    }
  }

  public async getPorts() {
    this.ports = Array.from(await navigator.serial.getPorts());
  }

  getFromStorage() {
    var options = window.localStorage.getItem('printerOptions');
    try {
      if (options) this.printerOptions = JSON.parse(options);
      else {
        this.printerOptions = new PrinterOptions();
        this.printerOptions.baudRate = 38400;
        this.printerOptions.dataBits = 8;
        this.printerOptions.stopBits = 1;
        this.printerOptions.parity = 'none';
        this.printerOptions.xonXoff = true;
        this.printerOptions.isFake = false;
        this.saveToStorage();
      }
    } catch (error) {
      this.printerOptions = new PrinterOptions();
      this.printerOptions.baudRate = 38400;
      this.printerOptions.dataBits = 8;
      this.printerOptions.stopBits = 1;
      this.printerOptions.parity = 'none';
      this.printerOptions.xonXoff = true;
      this.printerOptions.isFake = false;
      this.saveToStorage();
    }
  }

  saveToStorage() {
    try {
      window.localStorage.setItem('printerOptions', JSON.stringify(this.printerOptions));
    } catch (error) {}
  }

  getOptions(): SerialOptions {
    var options: SerialOptions = {};
    options.baudRate = this.printerOptions.baudRate;
    options.dataBits = this.printerOptions.dataBits;
    options.stopBits = this.printerOptions.stopBits;
    options.parity = this.printerOptions.parity;
    //options.bufferSize;
    // options.rtscts;
    options.xon = this.printerOptions.xonXoff;
    options.xoff = this.printerOptions.xonXoff;
    return options;
  }

  public async findPort(askUser: boolean, showProcessing: boolean) {
    var finalPort: SerialPort;
    var finalStatus: PrinterStatus;

    if (await this.isPrinterProcessing('findPort')) {
      return;
    }

    this.setPrintingInternalProcessing(true);

    if (!this.printerOptions.isFake) {
      if (showProcessing) this.processing = true;

      try {
        finalPort = null;

        var newPort: SerialPort = null;
        if (askUser ?? false) {
          console.debug('Pidiendo com al usuario');
          try {
            newPort = await navigator.serial.requestPort(this.getOptions());
          } catch (error) {}
        }

        if (newPort) if (await this.tryConnect(newPort)) finalPort = newPort;

        if (!finalPort && this.currentPort) {
          console.debug('Reconectando con ya encontrada');
          if (await this.tryConnect(this.currentPort)) finalPort = this.currentPort;
        }

        if (!finalPort) {
          var i = 0;
          for (const port of this.ports) {
            i++;
            if (await this.tryConnect(port)) {
              finalPort = port;
              break;
            }
          }
        }

        if (!finalPort) {
          this.status = null;
          console.debug('Impresora no encontrada');
        }

        await this.getPorts();
      } catch (error) {
        console.error(error);
      }

      if (showProcessing) this.processing = false;
    } else {
      finalPort = new FakePrinter();
      this.status = null;
    }

    this.currentPort = finalPort;
    this.setPrintingInternalProcessing(false);
  }

  async tryConnect(port: SerialPort, markBusy: boolean = false): Promise<boolean> {
    var result = false;

    if (markBusy) {
      if (await this.isPrinterProcessing('tryConnect')) return result;

      this.setPrintingInternalProcessing(true);
    }

    try {
      await this.WaitTimeout(this.reader.cancel(), 5);
    } catch (error) {}
    try {
      this.reader.releaseLock();
    } catch (error) {}
    try {
      this.writer.releaseLock();
    } catch (error) {}
    try {
      await this.WaitTimeout(this.writer.close(), 5);
    } catch (error) {}
    try {
      await this.WaitTimeout(port.close(), 5);
    } catch (error) {}
    try {
      await this.WaitTimeout(port.open(this.getOptions()), 5);
    } catch (error) {}

    try {
      var send = '^Se|^';
      var received = '';
      var timeOut = false;

      if (port.readable != null && port.readable) this.reader = port.readable.getReader();

      await this.printerClear(port);

      while (port.readable && !timeOut) {
        var reading = this.reader.read();
        var readed = false;
        reading.then((x) => (readed = true));
        if (send) {
          this.writer = port.writable.getWriter();
          //var arr = new Uint8Array([0x05]);
          var encoded = this.encoder.encode(send);
          await this.writer.write(encoded);
          this.writer.releaseLock();
          send = null;
        }

        const to = new Promise((res) => setTimeout(() => res('to'), 3000));
        const race = await Promise.race([reading, to]);
        if (readed) {
          const { value, done } = await reading;

          if (value) {
            var valueEncoded = this.decoder.decode(value);
            received += valueEncoded;

            var start = received.indexOf('*S');
            if (start >= 0) {
              var end = received.indexOf('|*', start);
              if (end > 0) {
                console.debug('Impresora encontrada');
                this.status = PrinterStatus.parse(received.substring(start, end + 2));
                result = true;
                break;
              }
            }
          }

          if (done) {
            // |reader| has been canceled.
            break;
          }
        } else {
          timeOut = true;
        }
      }
      if (received.length > 0) console.debug(received);
    } catch (error) {
      console.error(error);
    }

    try {
      await this.WaitTimeout(this.reader.cancel(), 5);
    } catch (error) {}
    try {
      this.reader.releaseLock();
    } catch (error) {}
    try {
      this.writer.releaseLock();
    } catch (error) {}
    try {
      await this.WaitTimeout(this.writer.close(), 5);
    } catch (error) {}
    try {
      await this.WaitTimeout(port.close(), 5);
    } catch (error) {}

    if (markBusy) this.setPrintingInternalProcessing(false);

    return result;
  }

  private async printerClear(port: SerialPort) {
    var send = '^C|^';
    if (!port?.writable) return;

    this.writer = port.writable.getWriter();
    var encoded = this.encoder.encode(send);
    await this.writer.write(encoded);
    this.writer.releaseLock();
    await this.delay(500);
  }

  async WaitTimeout(p: any, seconds: number) {
    const to = new Promise((res) => setTimeout(() => res('to'), seconds * 1000));
    await Promise.race([p, to]);
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getErrorDesc(): string {
    if (this.printerOptions.isFake) return 'SIMULACIÓN';

    if (!this.status) return '';

    var result: string[] = [];
    if (this.status.isPlatenOpen) result.push('Cobertor abierto');

    if (this.status.isPaperOut) result.push('Sin papel');

    if (this.status.isHeadError) result.push('Error cabezal');

    if (this.status.isVoltageError) result.push('Error voltage');

    if (this.status.isTemperatureError) result.push('Error temperatura');

    if (this.status.isPrinterOffline) result.push('Fuera de linea');

    if (this.status.isMissingSupplyIndex) result.push('Marca de papel no encontrada');

    if (this.status.isCutterError) result.push('Error cortador');

    if (this.status.isPaperJam) result.push('Atasco de papel');

    if (this.status.isPaperLow) result.push('Poco papel');

    if (this.status.isCommandError) result.push('Error en comando');

    if (this.status.isLibraryLoadError) result.push('Error en template');

    if (this.status.isPaperInChute) result.push('Ticket pendiente de ser retirado');

    if (result.length == 0 && this.status.isError)
      result.push(
        'Error desconocido: ' +
          this.status.flag1 +
          this.status.flag2 +
          this.status.flag3 +
          this.status.flag4 +
          this.status.flag5
      );

    return result.join(' - ');
  }

  getStatusDesc(): string {
    if (this.printerOptions.isFake) return 'SIMULACIÓN';

    if (!this.status) return 'No conectado';

    if (this.status.isError) return 'Error';

    if (this.status.isBusy) return 'Procesando';

    return 'Ok';
  }

  public async isPrinterReady(trys: number = 0): Promise<boolean> {
    var popUpTimeout = 15000;

    if (this.printerOptions.isFake) {
      this.toastr.warning('Impresora simulada', 'Impresora', {
        timeOut: popUpTimeout,
        enableHtml: true,
        progressBar: true,
      });
      return true;
    }

    if (!this.isBrowserSupported) {
      this.toastr.error('El navegador no permite impresiones', 'Impresora', {
        timeOut: popUpTimeout,
        enableHtml: true,
        progressBar: true,
      });
      return false;
    }

    if (!this.currentPort) {
      this.toastr.error('Impresora no detectada', 'Impresora', {
        timeOut: popUpTimeout,
        enableHtml: true,
        progressBar: true,
      });
      return false;
    }

    var isActive = await this.tryConnect(this.currentPort, true);
    if (!isActive) {
      this.toastr.error('Error conectando a impresora', 'Impresora', {
        timeOut: popUpTimeout,
        enableHtml: true,
        progressBar: true,
      });
      return false;
    }

    if (!this.status) {
      this.toastr.error('Error, Status es nulo', 'Impresora', {
        timeOut: popUpTimeout,
        enableHtml: true,
        progressBar: true,
      });
      return false;
    }

    if (this.status.isBusy) {
      if (trys < 6) {
        await new Promise((res) => setTimeout(() => res('to'), 1000));
        return this.isPrinterReady(trys + 1);
      } else {
        this.toastr.error('Impresora ocupada', 'Impresora', {
          timeOut: popUpTimeout,
          enableHtml: true,
          progressBar: true,
        });
        return false;
      }
    }

    if (this.status.isError && this.status.isRealError()) {
      var error = this.getErrorDesc();
      this.toastr.error(error, 'Impresora', { timeOut: popUpTimeout, enableHtml: true, progressBar: true });
      console.error(error);
      return false;
    }

    return true;
  }

  public async print(text: string, index: number) {
    if (this.printerOptions.isFake) {
      this.toastr.success(index.toString() + ': ' + text, 'Impresora simulada', {
        timeOut: 5000,
        enableHtml: true,
        progressBar: true,
      });

      await new Promise((res) => setTimeout(() => res('to'), 1000));
    } else if (await this.isPrinterReady()) {
      await this.realPrint(text);
    }
  }

  public async hardReset() {
    try {
      if (!this.printerOptions.isFake) {
        this.print('^r', 0);
        await this.delay(5000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async realPrint(text: string) {
    if (await this.isPrinterProcessing('realPrint')) {
      console.error('Error al imprimir: \n' + 'Impresora ocupada');
      this.toastr.error('Impresora ocupada', 'Error al imprimir', {
        timeOut: 30000,
        enableHtml: true,
        progressBar: true,
      });
      return;
    }

    this.setPrintingInternalProcessing(true);

    try {
      await this.WaitTimeout(this.reader.cancel(), 5);
    } catch (error) {}
    try {
      this.reader.releaseLock();
    } catch (error) {}
    try {
      this.writer.releaseLock();
    } catch (error) {}
    try {
      await this.WaitTimeout(this.writer.close(), 5);
    } catch (error) {}
    try {
      await this.WaitTimeout(this.currentPort.close(), 5);
    } catch (error) {}
    try {
      await this.WaitTimeout(this.currentPort.open(this.getOptions()), 5);
    } catch (error) {}
    try {
      if (this.currentPort.readable != null && this.currentPort.readable)
        this.reader = this.currentPort.readable.getReader();

      this.writer = this.currentPort.writable.getWriter();
      var encoded = this.encoder.encode(text);

      // // We reset the printer before printing
      // await this.writer.write(this.encoder.encode('\u001b@'));
      // await this.delay(2000);

      await this.writer.write(encoded);
      await this.writer.close();
      this.toastr.success('Ticket impreso correctamente', 'Impresora', {
        timeOut: 2000,
        enableHtml: true,
        progressBar: true,
      });
      await this.delay(3000);
    } catch (error) {
      console.error('Error al imprimir: \n' + error);
      this.toastr.error(error, 'Error al imprimir', { timeOut: 30000, enableHtml: true, progressBar: true });
    }

    try {
      await this.WaitTimeout(this.reader.cancel(), 5);
    } catch (error) {}
    try {
      this.reader.releaseLock();
    } catch (error) {}
    try {
      this.writer.releaseLock();
    } catch (error) {}
    try {
      await this.WaitTimeout(this.writer.close(), 5);
    } catch (error) {}
    try {
      await this.WaitTimeout(this.currentPort.close(), 10);
    } catch (error) {}

    this.setPrintingInternalProcessing(false);
  }

  private async isPrinterProcessing(method: string): Promise<boolean> {
    for (var i = 0; i < 6; i++) {
      if (this.getPrintingInternalProcessing()) {
        console.debug(method + ': Ocupada');
        await this.delay(1000);
      } else {
        return false;
      }
    }

    return true;
  }

  private setPrintingInternalProcessing(busy: boolean) {
    window.localStorage.setItem('printerBusy', busy ? 'true' : 'false');
  }

  public getPrintingInternalProcessing(): boolean {
    var result = window.localStorage.getItem('printerBusy');
    return result == 'true';
  }
}
