import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { MoneyDropsViewModel } from 'src/app/common/components/viewmodels/MoneyDropsViewModel';
import { PublicHolding } from 'src/app/common/components/viewmodels/PublicHolding';
import { PublicHoldingViewModel } from 'src/app/common/components/viewmodels/PublicHoldingViewModel';
import { AverageReport, PublicAccountingPower } from 'src/app/common/components/viewmodels/secondaryClasses';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-public-accounting-power',
  templateUrl: './public-accounting-power.component.html',
  styleUrls: ['./public-accounting-power.component.css'],
})
export class PublicAccountingPowerComponent implements OnInit {
  @ViewChild('detailModal', { static: false }) detailModal;
  @ViewChild('table') table: CommonTableComponent<PublicHoldingViewModel>;

  items: Array<BreadcrumbItem> = [];
  title: string = 'Tickets Poder Público';
  date: moment.Moment;
  public data: commonTableData<PublicHoldingViewModel>;
  formSearch: FormGroup;
  searchMade: boolean;
  currencySym: string;
  currencyCode: string;
  moment = moment;
  publicHoldingDetail: PublicHolding;
  dateRange: string;

  public static getName() {
    return 'PublicAccountingPowerComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public TicketService: TicketService,
    private activatedRoute: ActivatedRoute,
    public currencyPipe: CurrencyPipe,
    public CasinoService: CasinoService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.initForm();
    this.initGrid();
  }

  setGridData(filters: MinimalFilters) {
    let service = this.TicketService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<PublicHoldingViewModel>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getPublicHoldingReport(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy').toString() + ' - ' + moment(filters.to).format('DD/MM/yyyy').toString();
  }

  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    let button: commonTableCustomButton = new commonTableCustomButton();
    button.icon = 'fa-file-alt';
    button.columntitle = 'Detalles';
    button.tooltip = 'Ver detalles';
    button.order = 0;
    this.data.commonTableCustomButton.push(button);

    const that = this;
    this.data.columns = [
      {
        data: 'date',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy');
        },
      },
      { data: 'ticketsQuantity', title: 'Tickets' },
      {
        data: 'amount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
    ];
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
    });
  }
  updateTable(filters: MinimalFilters) {
    this.setGridData(filters);
    this.table.reload();
  }
  onSubmit() {
    this.searchMade = true;
    this.updateTable(this.getFilters());
  }

  getFilters() {
    var filters: MinimalFilters = new MinimalFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    return filters;
  }
  dismissModal() {
    this.modalService.dismissAll();
  }

  public onCustomButton(data): void {
    this.date = moment(data.row.date);
    this.TicketService.getPublicHolding(this.date).subscribe((result) => {
      this.publicHoldingDetail = result.data;
    });
    this.modalService.open(this.detailModal, { ariaLabelledBy: 'detailModal', size: 'lg' });
  }
}
