import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { TicketFamilyGroup } from 'src/app/common/components/viewmodels/Enums';
import { SystemParameters } from 'src/app/common/components/viewmodels/SystemParameters';
import { PrintConfigViewModel, TicketConfig } from 'src/app/common/components/viewmodels/TicketConfig';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { TicketConfigService } from 'src/app/services/ticket-config/ticket-config.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-ticket-config',
  templateUrl: './ticket-config.component.html',
  styleUrls: ['./ticket-config.component.css'],
})
export class TicketConfigComponent implements OnInit {
  ticketConfig: TicketConfig;
  printConfig: PrintConfigViewModel;
  formCash: FormGroup;
  formProm: FormGroup;
  formHp: FormGroup;
  formTemplateVoucher: FormGroup;
  titleCash: string = 'Editar Emisión Ticket Cash (Reemplazo Ticket)';
  titleProm: string = 'Editar Emisión Ticket Promocional No Restrictivo Y Restrictivo';
  titleHp: string = 'Editar HandPay Voucher';
  titleTemplateVoucher: string = 'Editar Template Recibo Voucher';
  items: Array<BreadcrumbItem> = [];
  InputType = InputTypeEnum;
  showSave: Observable<boolean>;
  canSave: boolean;
  systemParameters: SystemParameters;
  originCashTicketPrint: boolean;
  originPromTicketPrint: boolean;
  title: string = 'Formato Ticket';

  public static getName() {
    return 'TicketConfigComponent';
  }

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private ValidationService: ValidationService,
    private TicketConfigService: TicketConfigService,
    private casinoService: CasinoService,
    public printerService: PrinterService
  ) {}

  async ngOnInit(): Promise<void> {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.showSave = this.casinoService
      .isComponentAuthorized('TicketConfigComponent', 'Save')
      .pipe(map((u) => u && u.data));

    this.canSave = (await this.casinoService.isComponentAuthorized('TicketConfigComponent', 'Save').toPromise()).data;

    this.loadData();
  }

  loadData() {
    this.TicketConfigService.getTicketConfigs().subscribe((result) => {
      var cash = result.data.find((i) => i.familyGroupId === TicketFamilyGroup.Cash);
      var restpromocional = result.data.find((i) => i.familyGroupId === TicketFamilyGroup.Restricted_promotional);
      var hpConfig = result.data.find((i) => i.familyGroupId === TicketFamilyGroup.Handpay);

      this.TicketConfigService.getParameters().subscribe((param) => {
        this.systemParameters = param.data;
        //inicio el formulario cuando ya recupere los parametros:
        this.initForm();

        this.TicketConfigService.getPrintConfig().subscribe((result) => {
          if (result.error == null) {
            this.printConfig = result.data;
            this.formTemplateVoucher.get('voucherRecipitTemplate').setValue(this.printConfig.voucherRecipitTemplate);
          }
        });

        this.formCash.patchValue(cash);
        this.formProm.patchValue(restpromocional);
        this.formHp.patchValue(hpConfig);

        this.originCashTicketPrint = cash.ticketPrint;
        this.originPromTicketPrint = restpromocional.ticketPrint;

        this.formCash.markAsPristine();
        this.formProm.markAsPristine();
        this.formHp.markAsPristine();
        this.formHp.get('defaultExpiration').disable();

        if (!this.canSave) {
          this.formCash.disable();
          this.formProm.disable();
          this.formHp.disable();
        }

        if (!this.systemParameters.saleTicketCash) {
          this.formCash.get('ticketPrint').disable();
          this.formCash.get('ticketPrint').setValue(false);
        }
        if (!this.systemParameters.saleTicketPromocional) {
          this.formProm.get('ticketPrint').disable();
          this.formProm.get('ticketPrint').setValue(false);
        }
      });
    });
  }

  async testPrinter() {
    if (await this.printerService.isPrinterReady()) {
      var ticketQuantity = 1;

      var error = false;

      for (let index = 1; index <= ticketQuantity && !error; index++) {
        if (await this.printerService.isPrinterReady()) {
          var result = await this.TicketConfigService.GenerateTestTicket().toPromise();
          if (result?.data?.tickets.length > 0) {
            await this.printerService.print(result?.data?.tickets[0], index - 1);
          } else {
            error = true;
          }
        } else {
          error = true;
        }
      }
    }
  }

  onSubmitCash() {
    if (this.formCash.valid) {
      this.ticketConfig = new TicketConfig(this.formCash.value);

      this.ticketConfig.defaultExpiration = Number(this.ticketConfig.defaultExpiration);
      this.ticketConfig.maximumTicketSale = Number(this.ticketConfig.maximumTicketSale);
      this.ticketConfig.template = this.ticketConfig.template;
      this.TicketConfigService.edit(this.ticketConfig).subscribe((result) => {
        if (result != null) {
          this.loadData();

          if (this.originCashTicketPrint != this.ticketConfig.ticketPrint) {
            window.location.reload();
          }
        }
      });
    } else {
      this.notificationService.showError(
        'Por favor complete todos los campos de ticket promocional correctamente.',
        'Error'
      );
    }
  }

  onSubmitProm() {
    if (this.formProm.valid) {
      this.ticketConfig = new TicketConfig(this.formProm.value);

      this.ticketConfig.defaultExpiration = Number(this.ticketConfig.defaultExpiration);
      this.ticketConfig.maximumTicketSale = Number(this.ticketConfig.maximumTicketSale);
      this.ticketConfig.template = this.ticketConfig.template;
      this.TicketConfigService.edit(this.ticketConfig).subscribe((result) => {
        if (result != null) {
          this.loadData();

          if (this.originPromTicketPrint != this.ticketConfig.ticketPrint) {
            window.location.reload();
          }
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  onSubmitTemplateVoucher() {
    if (this.formTemplateVoucher.valid) {
      this.printConfig = new PrintConfigViewModel();
      this.printConfig.voucherRecipitTemplate = this.formTemplateVoucher.get('voucherRecipitTemplate').value;

      this.TicketConfigService.editPrintConfig(this.printConfig).subscribe((result) => {
        if (result != null) this.loadData();
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }
  onSubmitHp() {
    if (this.formHp.valid) {
      this.ticketConfig = new TicketConfig(this.formHp.value);

      //al guardar el handpay siempre le guardo el cash. Hago que vieje el mismo valor, ademas de controlarlo ya que el campo es olbigatorio
      this.ticketConfig.defaultExpiration = this.formCash.get('defaultExpiration').value;

      this.ticketConfig.defaultExpiration = Number(this.ticketConfig.defaultExpiration);
      // this.ticketConfig.maximumTicketSale = Number(this.ticketConfig.maximumTicketSale);
      this.ticketConfig.template = this.ticketConfig.template;
      this.TicketConfigService.edit(this.ticketConfig).subscribe((result) => {
        if (result != null) this.loadData();
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formCash = new FormGroup({
      id: new FormControl(2),
      location: new FormControl(null, [Validators.maxLength(40)]),
      address1: new FormControl(null, [Validators.maxLength(40)]),
      address2: new FormControl(null, [Validators.maxLength(40)]),
      defaultExpiration: new FormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(this.systemParameters.expirateTicketCashInHandPay),
      ]),
      maximumTicketSale: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.max(this.systemParameters.maxTicketIn),
      ]),
      ticketPrint: new FormControl([{ value: false, disabled: true }]),
      template: new FormControl(null, [Validators.required, Validators.maxLength(2000)]),
    });

    this.formProm = new FormGroup({
      id: new FormControl(1),
      location: new FormControl(null, [Validators.maxLength(16)]),
      address1: new FormControl(null, [Validators.maxLength(40)]),
      address2: new FormControl(null, [Validators.maxLength(40)]),
      defaultExpiration: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(90)]),
      maximumTicketSale: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.max(99999999),
      ]),
      ticketPrint: new FormControl([{ value: false }]),
      template: new FormControl(null, [Validators.required, Validators.maxLength(2000)]),
    });

    this.formTemplateVoucher = new FormGroup({
      voucherRecipitTemplate: new FormControl(null, [Validators.required, Validators.maxLength(2000)]),
    });
    //!this.systemParameters.saleTicketPromocional
    this.formHp = new FormGroup({
      id: new FormControl(3),
      location: new FormControl(null, [Validators.maxLength(16)]),
      address1: new FormControl(null, [Validators.maxLength(40)]),
      address2: new FormControl(null, [Validators.maxLength(40)]),
      defaultExpiration: new FormControl(
        [{ value: 0 }],
        [Validators.min(0), Validators.max(this.systemParameters.expirateTicketCashInHandPay)]
      ),
      //  'maximumTicketSale': new FormControl(0, [Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),Validators.maxLength(10)]),
      template: new FormControl(null, [Validators.required, Validators.maxLength(2000)]),
    });
  }
}

//uso this.systemParameters.maxTicketIn para el maximo valor de emision del cash
