import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { IdTextModel } from '../../shared/IdTextModel';
import { CashRegisterTypeEnum } from '../../viewmodels/SessioModel';

@Component({
  selector: 'cash-register-combo',
  templateUrl: './cash-register-combo.component.html',
  styleUrls: ['./cash-register-combo.component.css'],
})
export class CashRegisterComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  @Input() allowClear: boolean = false;
  @Input() cashRegisterType?: CashRegisterTypeEnum;
  @Output() valueChanged = new EventEmitter();
  @Output() loadFinish = new EventEmitter();

  combo: Array<IdTextModel> = [];
  label: string = 'Caja';

  constructor(private CashRegisterService: CashRegisterService) {}

  ngOnInit(): void {
    this.fillCombos();
  }

  fillCombos() {
    this.CashRegisterService.getCashRegistersCombo(this.cashRegisterType).subscribe((result) => {
      this.combo = result.data;
      this.loadFinish.emit();
    });
  }
  public getCombo() {
    return this.combo;
  }

  public getCashRegistersComboAsIdArray(): number[] {
    var arr: number[] = [];
    this.combo.forEach((element) => {
      arr.push(parseInt(element.id));
    });
    return arr;
  }
}
