<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div *ngIf="loadPage">
      <app-session-movement-control
        [turnId]="idTurn"
        [sessionMovementType]="this.sessionMovementType"
        [operationType]="this.operationType"
        [title]="title"
        [nonAuthorizedMaximum]="nonAuthorizedMaximum"
        (needsAuthorization)="needsAuthorization($event)"></app-session-movement-control>

      <div class="" *ngIf="showprintstatus">
        <app-ticket-printer-status></app-ticket-printer-status>
      </div>
    </div>
  </div>
</div>
