import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { AuthorizationOperationType } from 'src/app/common/components/viewmodels/Enums';
import { ButtonMovementTypeEnum } from 'src/app/common/components/viewmodels/SessioModel';
import { SessionService } from 'src/app/services/pos/pos.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-session-movement',
  templateUrl: './session-movement.component.html',
  styleUrls: ['./session-movement.component.css'],
})
export class SessionMovementComponent implements OnInit {
  public static getName() {
    return 'SessionMovementComponent';
  }

  sessionMovementType: ButtonMovementTypeEnum;
  idTurn: number = 0;
  items: Array<BreadcrumbItem> = [];
  title: string = '';
  loadPage: boolean = false;
  showprintstatus: boolean = false;
  operationType: AuthorizationOperationType;
  nonAuthorizedMaximum?: number;
  constructor(
    private router: Router,
    public sessionService: SessionService,
    public notificationService: NotificationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.idTurn = Number(paramTurnsId);
      if (isNaN(this.idTurn)) {
        this.goToErrorPage();
      } else {
        this.sessionMovementType = this.sessionService.CurrentMovement;

        switch (this.sessionMovementType) {
          case ButtonMovementTypeEnum.PayDatafono:
            this.operationType = AuthorizationOperationType.Pago_Datafono;
            this.title = 'Pago Datáfono';
            this.showprintstatus = true;
            break;

          case ButtonMovementTypeEnum.PayBets:
            this.operationType = AuthorizationOperationType.Pago_Apuestas;
            this.title = 'Pago Apuestas';
            this.showprintstatus = true;
            break;

          case ButtonMovementTypeEnum.ExpensesMiscellaneous:
            this.operationType = AuthorizationOperationType.Pago_Gastos_Varios;
            this.title = 'Pago Gastos Varios';
            this.showprintstatus = true;
            break;

          case ButtonMovementTypeEnum.PayInvoice:
            this.operationType = AuthorizationOperationType.Pago_Factura;
            this.title = 'Pago Factura';
            this.showprintstatus = true;
            break;

          case ButtonMovementTypeEnum.PayJackPot:
            this.operationType = AuthorizationOperationType.Pago_Jackpot_Externo;
            this.title = 'Pago JackPot Externo';
            this.showprintstatus = true;
            break;

          // case ButtonMovementTypeEnum.IncreaseFunds:
          //   this.title = 'Aumento de fondos';
          //   break;

          // case ButtonMovementTypeEnum.ReduceFunds:
          //   this.title = 'Disminución Fondo';
          //   break;
          case ButtonMovementTypeEnum.IncreaseFundsCashier:
            this.title = 'Reposición Efectivo';
            break;

          case ButtonMovementTypeEnum.ReduceFundsCashier:
            this.title = 'Retirada Efectivo';
            break;

          default:
            this.goToBack();
        }
        this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
        this.items.push(new BreadcrumbItem('', this.title));

        this.sessionService.CleanCurrentMovement();
        this.loadPage = true;
      }
    } else {
      this.goToErrorPage();
    }
  }

  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }
  goToBack() {
    if (this.idTurn != 0) {
      this.router.navigate(['/session-cashflow', this.idTurn]);
    }
  }
}
