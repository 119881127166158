<div class="wrapper">
  <app-header (TenantLoaded)="tenantLoaded()"></app-header>

  <app-main-sidebar #sidebar></app-main-sidebar>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper pt-2" *ngIf="showcontent">
    <router-outlet (activate)="sidebar.updateMenu()"></router-outlet>
  </div>

  <app-control-sidebar></app-control-sidebar>

  <app-footer *ngIf="showcontent"></app-footer>
</div>
