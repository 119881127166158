import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { EditSpiderViewModel } from 'src/app/common/components/viewmodels/EditSpiderViewModel';
import { Spider } from 'src/app/common/components/viewmodels/Spider';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class SpiderService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getSpiders(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<Spider>>> {
    return this.http
      .post<ApiResult<DataTableResult<Spider>>>(`${this.baseUrl}Spiders/GetSpiders`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Spider>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Spider>>(error))
      );
  }

  public getAllSpiders(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<Spider>>> {
    return this.http
      .post<ApiResult<DataTableResult<Spider>>>(`${this.baseUrl}Spiders/GetAllSpiders`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Spider>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Spider>>(error))
      );
  }

  public getSpidersCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Spiders/GetSpidersCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getUnusedSpidersCombo(egmId?: number): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Spiders/GetUnusedSpidersCombo?egmId=${egmId}`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getSpider(id: number): Observable<ApiResult<EditSpiderViewModel>> {
    return this.http.get<ApiResult<EditSpiderViewModel>>(`${this.baseUrl}Spiders/GetSpider?id=${id}`).pipe(
      map((x) => this.checkResult<EditSpiderViewModel>(x)),
      catchError((error) => this.handleError<EditSpiderViewModel>(error))
    );
  }
  public createOrEdit(spider: Spider): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Spiders/CreateOrEdit`, spider).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public deleteSpider(id: number): Observable<ApiResult<Spider>> {
    return this.http.delete<ApiResult<Spider>>(`${this.baseUrl}Spiders/DeleteSpider?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<Spider>(x);
        return result;
      }),
      catchError((error) => this.handleError<Spider>(error))
    );
  }
}
