import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { Cabinet } from 'src/app/common/components/viewmodels/Cabinet';
import { CabinetService } from 'src/app/services/cabinet/cabinet.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-cabinet',
  templateUrl: './edit-cabinet.component.html',
  styleUrls: ['./edit-cabinet.component.css'],
})
export class EditCabinetComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Nuevo gabinete';

  items: Array<BreadcrumbItem> = [];

  InputType = InputTypeEnum;

  cabinet: Cabinet = new Cabinet();
  isEdit: boolean;

  parameter: tableStatus = null;

  public static getName() {
    return 'EditCabinetComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private CabinetService: CabinetService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.parameter = this.paramService.getAndDeleteParameters();

    if (this.activatedRoute.snapshot.params.id != null) {
      this.CabinetService.getCabinet(this.activatedRoute.snapshot.params.id).subscribe((result) => {
        this.formEdit.patchValue(result.data);
      });
      this.title = 'Editar gabinete';
      this.isEdit = true;
    }

    this.items.push(new BreadcrumbItem('/machines/cabinets', 'Máquinas'));
    this.items.push(new BreadcrumbItem('/machines/cabinets', 'Gabinetes'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('machines/cabinets');
  }

  onSubmit() {
    if (this.formEdit.valid) {
      this.cabinet = new Cabinet(this.formEdit.value);

      if (this.cabinet.id == null) {
        this.cabinet.id = '00000000-0000-0000-0000-000000000000';
      }

      this.paramService.saveParameters(this.parameter);
      this.CabinetService.createOrEdit(this.cabinet).subscribe((result) => {
        this.router.navigate(['/machines/cabinets']);
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
    });
  }
}
