import { CurrencyPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { TicketFamilyGroupsComboComponent } from 'src/app/common/components/business/ticket-family-groups-combo/ticket-family-groups-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  commonTableData,
  commonTableCustomButton,
  CommonTableComponent,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import {
  CancellationReasons,
  CashableType,
  PreInserted,
  TicketFamily,
  TicketFamilyGroup,
  TicketState,
  TicketType,
} from 'src/app/common/components/viewmodels/Enums';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { TicketTracker } from 'src/app/common/components/viewmodels/TicketTracker';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-general-tickets',
  templateUrl: './general-tickets.component.html',
  styleUrls: ['./general-tickets.component.css'],
})
export class GeneralTicketsComponent implements OnInit {
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<TicketTracker>;

  @ViewChild(TicketFamilyGroupsComboComponent) familyCombo: TicketFamilyGroupsComboComponent;

  public data: commonTableData<TicketTracker>;
  title: string = 'Seguimiento Tickets';

  date: moment.Moment = moment(new Date());
  formSearch: FormGroup;
  currencySym: string;
  currencyCode: string;
  ticketFamily: Array<IdTextModel> = [];
  ticketType: Array<IdTextModel> = [];
  ticketState: Array<IdTextModel> = [];
  cancellationReason: Array<IdTextModel> = [];
  generationReasonArray: Array<IdTextModel> = [];
  issuedBy: Array<IdTextModel> = [];
  regex: RegExp = new RegExp('_', 'g');
  items: Array<BreadcrumbItem> = [];
  firstTime: boolean = true;
  dateRange: string;

  public static getName() {
    return 'GeneralTicketsComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    public TicketService: TicketService,
    public CasinoService: CasinoService,
    public CashRegisterService: CashRegisterService,
    private cdref: ChangeDetectorRef,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.initForm();
    this.initGrid();
    this.fillCombos();

    this.formSearch.get('ticketTrackerStatus').setValue('1');
  }

  ngAfterViewChecked(): void {
    if (this.firstTime) {
      this.formSearch.get('cancelReason').disable();
      this.firstTime = false;
    }
    this.cdref.detectChanges();
  }

  GoToTicketData(data) {
    alert(data);
  }

  public onCustomButton(data: any): void {
    this.router.navigate(['/tito/ticket-history/' + data.row.ticket.validatorCode]);
  }

  initGrid() {
    this.data = new commonTableData();

    let button: commonTableCustomButton = new commonTableCustomButton();
    button.icon = 'fa-file-alt';
    button.columntitle = 'Ticket';
    button.tooltip = 'Ir a historial';
    button.order = 0;
    // this.data.commonTableCustomButton.push(button);

    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;

    const that = this;

    (this.data.columns = [
      {
        data: 'ticket.validatorCode',
        title: 'Ticket',
        render: function (data, type, row, meta) {
          return data != null ? data : '-';
        },
      },
      { data: 'ticket.ticketNumber', title: 'N° Ticket' },
      {
        data: 'ticket.formattedAmount',
        title: 'Importe',
        render: function (data: number, type, ro1w) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'ticket.creationDate',
        title: 'Fecha Ticket',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'ticket.expirationDate',
        title: 'Fecha Vencimiento',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },

      {
        data: 'eventDate',
        title: 'Fecha Evento',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'state',
        title: 'Estado Buscado',
        render: function (data: number, type, row) {
          return data != null ? TicketState[data].replace('_', ' ') : '-';
        },
      },
      {
        data: 'ticket.state',
        title: 'Último Estado',
        render: function (data: number, type, row) {
          return data != null ? TicketState[data].replace(that.regex, ' ') : '-';
        },
      },
      {
        data: 'cancellationReason',
        title: 'Motivo Anulación',
        render: function (data: number, type, row) {
          return data != null ? CancellationReasons[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'type',
        title: 'Tipo',
        render: function (data: number, type, row) {
          return data != null ? TicketType[data].replace(that.regex, ' ') : '-';
        },
      },
      {
        data: 'ticket.family.familyGroup.familyDescription',
        title: 'Familia',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
          // TicketFamilyGroup[data].replace(that.regex, " ")
        },
      },
      {
        data: 'ticket.creationEgm.uid',
        title: 'EGM',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'ticket.cashRegisterName',
        title: 'Caja',
        render: function (data: string, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'userIdName',
        title: 'Usuario',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'systemDate',
        title: 'Fecha Sistema',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },

      {
        data: 'ticket.cashableBy',
        title: 'Canjeable Por',
        render: function (data: number, type, row) {
          return data != null ? CashableType[data].replace(new RegExp('_', 'g'), '/') : '-';
        },
      },
      {
        data: 'preInserted',
        title: 'Pre-insertado',
        render: function (data: number, type, row) {
          return data != null ? PreInserted[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'preInsertedError',
        title: 'Error Pre-insertado',
        render: function (data: string, type, row) {
          return data != null ? data : '-';
        },
      },

      {
        data: 'ticket.ticketReplacementCodeValidator',
        title: 'Reemplazado Por',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },

      {
        data: 'ticket.generationReason.generationReasonDescription',
        title: 'Razón de Generación',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'ticket.observations',
        title: 'Observaciones',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
    ]),
      (this.data.rowCallback = function (row: Node, data: TicketTracker | Object, index: number): void {
        $($('td', row)[0]).html(
          `<a target="_blank" href='${window.location.origin}/egms/tito/ticket-history/${data['ticket'].validatorCode}' style='text-decoration: underline;' data-toggle='tooltip' data-placement='right' title='' data-original-title='Ir a historial'>${data['ticket'].validatorCode}</a>`
        );
      });
  }
  initForm() {
    this.formSearch = new FormGroup({
      cancelReason: new FormControl(),
      to: new FormControl(),
      from: new FormControl(),
      ticketType: new FormControl(),
      ticketFamily: new FormControl([]),
      ticketStatus: new FormControl(),
      ticketTrackerStatus: new FormControl(),
      issuedBy: new FormControl(),
      generationReason: new FormControl([]),
    });
  }

  onSubmit() {
    this.updateTable();
  }

  getFilters(): MinimalFilters {
    var filters = new MinimalFilters();

    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;
    filters.ticketFamilies = this.formSearch.get('ticketFamily').value;
    filters.ticketStatus = this.formSearch.get('ticketStatus').value;
    filters.ticketTrackerStatus = this.formSearch.get('ticketTrackerStatus').value;
    filters.ticketType = this.formSearch.get('ticketType').value;
    filters.issuedBy = this.formSearch.get('issuedBy').value;
    filters.generationReason = this.formSearch.get('generationReason').value;
    if (this.formSearch.get('cancelReason').value != null) {
      filters.cancellationReason = this.formSearch.get('cancelReason').value;
    }
    return filters;
  }

  updateTable() {
    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }

    if (this.formSearch.get('ticketFamily').value.length > 0) {
      aux += ' Familia: ' + this.ticketFamilyFilters + ' - ';
    }
    if (this.formSearch.get('ticketType').value != null) {
      aux += ' Tipo ticket: ' + this.ticketType.find((e) => e.id == this.formSearch.get('ticketType').value).text;
    }

    if (this.formSearch.get('issuedBy').value != null) {
      aux += ' Originado Por: ' + this.issuedBy.find((e) => e.id == this.formSearch.get('issuedBy').value).text;
    }

    if (this.formSearch.get('generationReason').value.length > 0) {
      aux += ' Razón de Generación: ' + this.generationReasonFilters + ' - ';
    }

    if (this.formSearch.get('ticketTrackerStatus').value != null) {
      aux += 'Estado: ' + this.ticketState.find((e) => e.id == this.formSearch.get('ticketTrackerStatus').value).text;

      if (this.formSearch.get('ticketTrackerStatus').value == TicketState.Anulado) {
        if (this.formSearch.get('cancelReason').value != null) {
          aux +=
            ' Motivo Cancelación: ' +
            this.cancellationReason.find((e) => e.id == this.formSearch.get('cancelReason').value).text;
        }
      }
    }

    if (this.formSearch.get('ticketStatus').value != null) {
      aux += 'Estado Actual: ' + this.ticketState.find((e) => e.id == this.formSearch.get('ticketStatus').value).text;
    }

    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  get ticketFamilyFilters(): string {
    let combo = this.familyCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('ticketFamily').value.length; index++) {
      const element = this.formSearch.get('ticketFamily').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('ticketFamily').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }
  get generationReasonFilters(): string {
    let combo = this.generationReasonArray;
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('generationReason').value.length; index++) {
      const element = this.formSearch.get('generationReason').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('generationReason').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  setGridData(filters: MinimalFilters) {
    let service = this.TicketService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<TicketTracker>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getTicketsTrackersGeneralReport(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }
  private async fillCombos() {
    this.ticketType = this.TicketService.getTicketType(this.ticketFamily);
    this.ticketState = this.TicketService.getTicketState(this.ticketState);
    this.cancellationReason = this.TicketService.getCancellationReason(this.cancellationReason);
    this.TicketService.getGenerationReasonCombo().subscribe((result) => {
      this.generationReasonArray = result.data;
    });
    this.issuedBy = this.TicketService.getTicketIssuedBy(this.issuedBy);
  }

  updateFilters() {
    this.formSearch.get('ticketTrackerStatus').value == TicketState.Anulado
      ? this.formSearch.get('cancelReason').enable()
      : this.formSearch.get('cancelReason').disable();
  }
}
