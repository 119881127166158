<form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Filtros de búsqueda
            <span *ngIf="dateRange !== null"> - {{ dateRange }} </span>
          </h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool mr-2" (click)="onSubmit()"><i class="fas fa-redo"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
          </div>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 col-sm-3">
              <app-common-daterangepicker
                [controlFrom]="formSearch.get('from')"
                [controlTo]="formSearch.get('to')"
                [label]="'Rango'">
              </app-common-daterangepicker>
            </div>
            <div class="col-12 col-sm-3">
              <room-combo [control]="formSearch.get('rooms')" [multiselect]="true" (valueChanged)="updateEgms()">
              </room-combo>
            </div>
            <div class="col-12 col-sm-3">
              <egm-combo [includeDeleted]="true" [multiselect]="true" [control]="formSearch.get('egmUid')"></egm-combo>
            </div>
            <div class="d-flex align-items-center ml-4" *ngIf="isDailyClosing">
              <div class="col-12" [class.disabled]="isReportCurrentProfit || isPeriodReport">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="opened"
                    type="radio"
                    value="opened"
                    name="dayStatus"
                    formControlName="dayStatus" />
                  <label class="form-check-label" for="opened">Día abierto</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="closed"
                    type="radio"
                    value="closed"
                    name="dayStatus"
                    formControlName="dayStatus" />
                  <label class="form-check-label" for="closed">Día cerrado</label>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center ml-4"
              *ngIf="isPeriodReport || (isReportCurrentProfit && !isDailyClosing)">
              <div class="col-12" [class.disabled]="isReportCurrentProfit || isPeriodReport">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="opened"
                    type="radio"
                    value="opened"
                    name="dayStatus"
                    formControlName="dayStatus" />
                  <label class="form-check-label" for="opened">Día abierto</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="closed"
                    type="radio"
                    value="closed"
                    name="dayStatus"
                    formControlName="dayStatus" />
                  <label class="form-check-label" for="closed">Día cerrado</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              (click)="openModal(true)"
              *ngIf="!isReportCurrentProfit && !isPeriodReport"
              class="btn btn-primary btn-sm">
              {{ periodAction }} Día(s)
            </button>
            <button
              type="button"
              (click)="openModal(false)"
              *ngIf="!isReportCurrentProfit && !isPeriodReport"
              class="btn btn-primary btn-sm ml-2">
              Actualizar Ganancias
            </button>
            <button
              (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
              data-card-widget="collapse"
              class="btn btn-info ml-2">
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <app-common-table
          [model]="data"
          (onCustomButton)="onCustomButton($event)"
          [title]="title"
          [excel]="true"
          (onSearch)="onSearch($event)"></app-common-table>
      </div>
      <!-- <div class="card-footer bg-dark" *ngIf="showTotalProfit"> -->
      <div class="card-footer" *ngIf="showTotalProfit">
        <div class="row" *ngIf="totalProfit != null">
          <div class="col-2 d-flex">
            <span class="resalt">Ganancia total: </span
            ><span class="resalt" [class.text-success]="totalProfit > 0" [class.text-danger]="totalProfit < 0">
              {{ currencyPipe.transform(totalProfit, currencyCode, currencySym) }}
            </span>
          </div>

          <div class="col-2 d-flex">
            <span class="resalt">Cantidad de máquinas:</span
            ><span class="resalt text-success">{{ machineQuantity }}</span>
          </div>
          <div class="col-8 d-flex"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal yes="Continuar" (onConfirm)="isTogglePeriod ? togglePeriod() : recalculateProfits()" [body]="body">
</app-confirm-modal>
