import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonInputComponent,
  InputTypeEnum,
} from 'src/app/common/components/shared/common-input/common-input.component';
import { TicketValuesService } from 'src/app/services/ticket-values/ticket-values.service';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { TicketConfigService } from 'src/app/services/ticket-config/ticket-config.service';
import { TicketFamily, TicketFamilyGroup } from 'src/app/common/components/viewmodels/Enums';
import { TicketsSaleViewModel } from 'src/app/common/components/viewmodels/TicketsSaleViewModel';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { SessionOldReportService } from 'src/app/services/session-report/session-report.service';
import { PrinterService } from 'src/app/services/printer/printer.service';

@Component({
  selector: 'app-promotional-ticket',
  templateUrl: './promotional-ticket.component.html',
  styleUrls: ['./promotional-ticket.component.css'],
})
export class PromotionalTicketComponent implements OnInit {
  @ViewChild('ticketValue', { static: false }) TicketValue: CommonInputComponent;
  formEdit: FormGroup;

  valuesArray: Array<IdTextModel>;

  InputType = InputTypeEnum;
  hidePromotional: boolean = false;
  maximumTicketValue: number;
  ticket: TicketsSaleViewModel;
  sessionStatus: boolean = false;
  currentCashRegister: CashRegister = new CashRegister();

  items: Array<BreadcrumbItem> = [];

  title: string = 'Emisión de Ticket Promocional';

  public static getName() {
    return 'PromotionalTicketComponent';
  }
  constructor(
    private router: Router,
    public ValuesService: TicketValuesService,
    public NotificationService: NotificationService,
    private ticketService: TicketService,
    public SessionService: SessionOldReportService,
    private TicketConfigService: TicketConfigService,
    public PrinterService: PrinterService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.formEdit.get('ticketValue').setValue('');
    this.formEdit.get('quantity').setValue(1);
    this.valuesArray = this.ValuesService.getValues();

    this.items.push(new BreadcrumbItem(this.router.url, 'Cajero'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.TicketConfigService.getTicketConfigs().subscribe((result) => {
      //levanto la de promocional restricted  (sea o no restricted pq se usa la misma)
      var restpromocional = result.data.find((i) => i.familyGroupId === TicketFamilyGroup.Restricted_promotional);
      this.hidePromotional = restpromocional.ticketPrint;

      this.maximumTicketValue = restpromocional.maximumTicketSale;
      this.TicketValue.focusField();

      this.formEdit.controls['ticketValue'].setValidators([
        Validators.required,
        Validators.max(this.maximumTicketValue),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]);
      this.formEdit.controls['ticketValue'].updateValueAndValidity();

      this.formEdit.controls['quantity'].setValidators([Validators.pattern('[0-9]*'), Validators.required]);
      this.formEdit.controls['quantity'].updateValueAndValidity();
    });
  }

  async onSubmit(myForm: any) {
    if (this.formEdit.valid) {
      if (this.formEdit.get('ticketValue').value <= 0) {
        this.NotificationService.showError('Valor de ticket menor o igual a cero', 'Operación rechazada');
      } else {
        if (await this.PrinterService.isPrinterReady()) {
          this.ticket = new TicketsSaleViewModel(this.formEdit.value);
          this.ticket.formattedAmount = Number(this.formEdit.get('ticketValue').value);
          this.ticket.quantity = Number(this.formEdit.get('quantity').value);
          this.ticket.restrictive = this.formEdit.get('restrictive').value;

          if (this.ticket.restrictive) {
            this.ticket.familyId = TicketFamily.Restricted_Promotional;
          } else {
            this.ticket.familyId = TicketFamily.No_Restricted_Promotional;
          }

          this.hidePromotional = false;
          this.ticketService.generatePromotionalTicket(this.ticket).subscribe(async (result) => {
            if (result?.data?.tickets) {
              //  var restval = this.formEdit.get('restrictive').value;
              myForm.resetForm();
              this.formEdit.get('quantity').setValue(1);
              //le dejo el que tenia
              this.formEdit.get('restrictive').setValue(this.ticket.restrictive);

              for (let index = 0; index < result?.data?.tickets.length; index++) {
                await this.PrinterService.print(result?.data?.tickets[index], index);
              }
              this.TicketValue.focusField();
            }
            this.hidePromotional = true;
          });
        }
      }
    } else {
      this.NotificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      ticketValue: new FormControl(
        [Validators.maxLength(10), Validators.required],
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/)
      ),
      quantity: new FormControl(null, [Validators.pattern('[0-9]*'), Validators.required]),
      restrictive: new FormControl(true),
    });
  }
}
