import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BillCodeService } from 'src/app/services/billcode/billcode.service';
import { IdTextModel } from '../../shared/IdTextModel';

@Component({
  selector: 'billcodes-combo',
  templateUrl: './billcode-combo.component.html',
  styleUrls: ['./billcode-combo.component.css'],
})
export class BillcodeComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() multiselect: boolean = false;
  combo: Array<IdTextModel> = [];
  @Output() valueChanged = new EventEmitter();

  constructor(private BillCodeService: BillCodeService) {}

  ngOnInit(): void {
    this.fillCombo();
  }

  private fillCombo() {
    this.BillCodeService.getBillCodesCombo().subscribe((result) => {
      this.combo = result.data;
    });
  }
}
