import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css'],
})
export class GlossaryComponent implements OnInit {
  glossaryItems: Array<GlossaryItem> = new Array<GlossaryItem>();

  public static getName() {
    return 'GlossaryComponent';
  }
  constructor() {}

  ngOnInit(): void {
    this.glossaryItems.push(new GlossaryItem('A', ''));
    this.glossaryItems.push(
      new GlossaryItem('AFT IN', 'Ingreso de Transferencia de una Cuenta Sin Dinero en efectivo')
    );
    this.glossaryItems.push(new GlossaryItem('AFT OUT', 'Transfer de Entrega a una Cuenta Sin Dinero en Efectivo'));
    this.glossaryItems.push(
      new GlossaryItem(
        'APPP',
        'Attendant Paid Progressive Payout. Es una parte del Total Jackpot. El total de los valores de todos los creditos registrados por el Total Jackpot, donde este monto esta determinado por un controlador progresivo.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'APEBP',
        'Attendant Pay External Bonus Payout. Es una parte del del Total Jackpot. El valor total de todos los creditos ganadores registrados en Total Jackpot .Donde los créditos ganados se deben a un premio de bonificación o un multiplicador de premio mayor del controlador de bonus externo (en creditos).'
      )
    );
    this.glossaryItems.push(new GlossaryItem('B', ''));
    this.glossaryItems.push(new GlossaryItem('BILL IN', 'Total de creditos por billetes aceptados'));
    this.glossaryItems.push(new GlossaryItem('BILL M1 Q', 'Cantidad de billetes aceptados en posición 1'));
    this.glossaryItems.push(new GlossaryItem('BILL M2 Q', 'Cantidad de billetes aceptados en posición 2'));
    this.glossaryItems.push(new GlossaryItem('BILL M3 Q', 'Cantidad de billetes aceptados en posición 3'));
    this.glossaryItems.push(new GlossaryItem('BILL M4 Q', 'Cantidad de billetes aceptados en posición 4'));
    this.glossaryItems.push(new GlossaryItem('BILL M5 Q', 'Cantidad de billetes aceptados en posición 5'));
    this.glossaryItems.push(new GlossaryItem('BILL M6 Q', 'Cantidad de billetes aceptados en posición 6'));
    this.glossaryItems.push(new GlossaryItem('BILL M7 Q', 'Cantidad de billetes aceptados en posición 7'));
    this.glossaryItems.push(new GlossaryItem('BILL M8 Q', 'Cantidad de billetes aceptados en posición 8'));
    this.glossaryItems.push(
      new GlossaryItem('BILL OUT', 'Total de creditos de Billetes dispensados por el reciclador')
    );
    this.glossaryItems.push(
      new GlossaryItem('BILL TOTAL Q', 'Cantidad de billetes aceptados por la maquina en todas las posiciones')
    );
    this.glossaryItems.push(new GlossaryItem('BILL TOTAL $', 'Monto Total de los Billetes'));
    this.glossaryItems.push(
      new GlossaryItem(
        'BILL TO DROP',
        'Total de creditos recibidos desde el aceptador de billetes que van al drop (acumulador o stacker)'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'BILL TO RECYCLE',
        'Total de creditos recibidos desde el aceptador de billetes que son desviados al reciclador.'
      )
    );
    this.glossaryItems.push(new GlossaryItem('C', ''));
    this.glossaryItems.push(
      new GlossaryItem(
        'CAJA',
        'Se refiere a la parte de la cuenta donde se registran las entradas de dinero en efectivo o por tickets o en valores representativos de sumas dinerarias, y los egresos'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('CAJERO', 'Persona responsable de sumar la cantidad debida por una compra,')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'CANCELLED CREDITS',
        'Son todos los creditos removidos o salidos de la maquina de juego (EGM), excepto los pagados por Hopper y por Jackpot'
      )
    );
    this.glossaryItems.push(new GlossaryItem('CLONAR EGM', 'Copiar de forma identica una maquina de juego (EGM)'));
    this.glossaryItems.push(
      new GlossaryItem(
        'CODIGO SPIDER',
        'Es el nombre o codigo de la interfaz (Spider) entre la maquina de juego (EGM) y el sistema.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('CODIGO EVENTO', 'Es el codigo SAS de un evento que reporta la maquina de juego (EGM)')
    );
    this.glossaryItems.push(
      new GlossaryItem('CODIGO VALIDADOR', 'Identificador o nro del codigo de barras del ticket')
    );
    this.glossaryItems.push(new GlossaryItem('COIN DIFF', 'Coin in - Coin out'));
    this.glossaryItems.push(
      new GlossaryItem(
        'COIN DROP',
        'Total de creditos por Monedas fisicas ingresadas en la maquina de juego (EGM) que caen al cajon'
      )
    );
    this.glossaryItems.push(new GlossaryItem('COIN IN', 'Total de creditos apostado por el jugador'));
    this.glossaryItems.push(
      new GlossaryItem('COIN OUT', 'Total de creditos ganados por el jugador. No incluye lo ganado por Jackpot')
    );
    this.glossaryItems.push(new GlossaryItem('CUID', 'Identificador Unico de la Caja en la SALA'));
    this.glossaryItems.push(new GlossaryItem('D', ''));
    this.glossaryItems.push(
      new GlossaryItem(
        'DENOMINACION SISTEMA',
        'Es la unidad de medida usada para multiplicar todos los valores de los contadores reportados al Host.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('DEV PAY', 'Devolucion Payout. Porcentaje de pago al jugador. Formula : 100 - Ret.Hold (%)')
    );
    this.glossaryItems.push(
      new GlossaryItem('DIRECCION 1', 'Primera direccion del establecimiento. Por ejemplo la calle y numero.')
    );
    this.glossaryItems.push(
      new GlossaryItem('DIRECCION 2', 'Segunda direccion del establecimiento. Por ejemplo Provincia y Ciudad')
    );
    this.glossaryItems.push(
      new GlossaryItem('DIRECCION MAC', 'Es el identificador único asignado por el fabricante al hardware de la Spider')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'DIRECCION IP',
        'Conjunto de números que identifica, de manera lógica y jerárquica, a una interfaz Spider en la red'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('DOOR Q', 'Cantidad de aperturas de puertas de la maquina de juego (EGM)')
    );
    this.glossaryItems.push(new GlossaryItem('E', ''));
    this.glossaryItems.push(new GlossaryItem('EFT IN', 'Ingreso de Fondos por Transferencia Electronica'));
    this.glossaryItems.push(new GlossaryItem('EGM', 'Maquina Electronica de Juego'));
    this.glossaryItems.push(
      new GlossaryItem('ERROR PRE INSERTADO', 'Indica un error cuando el ticket es insertado manualmente en el sistema')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'ESTADO DEL DIA',
        'Significa si los contadores fueron cerrados o siguen abiertos. Afecta al cálculo de ganancia.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'ESTADO',
        'Indica si los contadores de una maquina determinada estan habilitados o no para cargar la informacion en la aplicación.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'ESTADO',
        'Es el estado que se encuentra un ticket. Los diferentes estados son los siguientes Operable, Canjeable, Anulado y Pendiente'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('EXPIRACION POR DEFECTO', 'Indicador de la fecha de expiracion de un ticket')
    );
    this.glossaryItems.push(new GlossaryItem('F', ''));
    this.glossaryItems.push(new GlossaryItem('FABRICANTE', 'Nombre del Fabricante de la maquina de juego (EGM)'));
    this.glossaryItems.push(
      new GlossaryItem(
        'FAMILIA',
        'Hace referencia a las diferentes familias dentro de los tickets. las familias son Cash Out, Han Pay, Jackpot, Restricted Promocional y Non Restricted Promocional.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('FECHA DE BAJA SPIDER', 'Fecha en la que se da de baja o se saca del sistema una placa Spider.')
    );
    this.glossaryItems.push(
      new GlossaryItem('FECHA EVENTO', 'fecah en la que sucede un evento en la maquina de juego (EGM)')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'FECHA RECEPCION HOST',
        'Es la fecha y hora en la que el Host recibe un evento originado en la EGM.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'PRE INSERTADO',
        'Es un ticket “pre-insertado” manualmente al sistema por algun problema de comunicacion y para luego concretar el pago al cliente.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('FECHA SISTEMA', 'Es la fecha y hora sincronizada entre la aplicacion, spider y host.')
    );
    this.glossaryItems.push(new GlossaryItem('G', ''));
    this.glossaryItems.push(new GlossaryItem('GABINETE', 'Formato del Gabinete de la maquina de juego (EGM)'));
    this.glossaryItems.push(
      new GlossaryItem('GANANCIA DIA ABIERTO', 'Es la ganancia cuando en el momento que se solicita la consulta.')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'GANACIA DIA CERRADO',
        'Es la ganancia cuando el usuario ha cerrado los contadores del dia contable del establecimiento'
      )
    );
    this.glossaryItems.push(new GlossaryItem('Grupo EGMs', 'Agrupar EGMs por características en parriculares.'));
    this.glossaryItems.push(new GlossaryItem('H', ''));
    this.glossaryItems.push(
      new GlossaryItem('HABILITADA (EN SERVICIO)', 'Estado en el cual una EGM se encuentra conectada con el sistema')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'HANDPAY CC',
        'HandPay Cancelled Credits. Suma de creditos acumulados de todos los pagos manuales que resulten de la presion del boton "Cash Out" pot parte del jugador. No incluye creditos provenientes de Jackpot.'
      )
    );
    this.glossaryItems.push(new GlossaryItem('I', ''));
    this.glossaryItems.push(
      new GlossaryItem(
        'IMPORTE',
        'Cantidad como un valor numerico para el pago de tickets de Cash Out y Hand Pay expresada con el signo monetario.'
      )
    );
    this.glossaryItems.push(new GlossaryItem('J', ''));
    this.glossaryItems.push(
      new GlossaryItem('JACKPOT', 'Llamado tambien Total Jackpot. Total de creditos pagados por Jackpot')
    );
    this.glossaryItems.push(new GlossaryItem('JACKPOT Q', 'Cantidad de Jackpot Premiados'));
    this.glossaryItems.push(new GlossaryItem('JUEGO', 'Tipo de Juego de la maquina de juego (EGM)'));
    this.glossaryItems.push(new GlossaryItem('JUEGOS JUGADOS', 'Cantidad de Juegos Jugados'));
    this.glossaryItems.push(new GlossaryItem('JUEGOS GANADOS', 'Cantidad de Juegos Ganados'));
    this.glossaryItems.push(new GlossaryItem('JUEGOS PERDIDOS', 'Cantidad de Juegos Perdidos'));
    this.glossaryItems.push(new GlossaryItem('M', ''));
    this.glossaryItems.push(
      new GlossaryItem(
        'MODO SUPERVISOR',
        'Permite pagar o solucionar problemas con tickets que requieran la autorización excepcional del supervisor'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'MOTIVO ANULACION',
        'Diferentes motivos para la anulacion de un ticket. Son los siguientes, Expirados, Anulados, Error de Fecha, No Impreso, Ticket Pendiente y Duplicado.'
      )
    );
    this.glossaryItems.push(new GlossaryItem('MODELO', 'Nombre del Modelo de la maquina de juego (EGM)'));
    this.glossaryItems.push(
      new GlossaryItem(
        'MPPP',
        'MPPP es Machine Paid Progressive Payout. El total de los valores de todos los creditos registrados por el Total Coin Out, donde este monto esta determinado por un controlador progresivo. Es una parte del Coin out. Machine Pay Progressive Payout.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'MPEBP',
        'Machine Paid Externa Bonus Payout. Es una parte del Coin Out. El valor total de todos los creditos ganadores registrados en Total Coin Out. Donde los créditos ganados se deben a un premio de bonificación o un multiplicador de premio mayor del controlador de bonus externo. '
      )
    );
    this.glossaryItems.push(new GlossaryItem('N', ''));
    this.glossaryItems.push(new GlossaryItem('NOMBRE DEL ESTABLECIMIENTO', 'Nombre de la sala o casino'));
    this.glossaryItems.push(
      new GlossaryItem('NRO. TICKET', 'Numero en forma secuecial del ticket impreso por la maquina de juego (EGM)')
    );
    this.glossaryItems.push(new GlossaryItem('P', ''));
    this.glossaryItems.push(
      new GlossaryItem('PARAMETROS AVANZADOS', 'Se ingresa a la edicion de parametros avanzados del HOST / EGM')
    );
    this.glossaryItems.push(
      new GlossaryItem('POWER RESET', 'Cantidad de veces de reinicio de energia en la maquina de juego (EGM)')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'PODER PÚBLICO',
        'Son los tickets que se encuentran en poder del público en un día a determinar, incluyendo los tickets promocionales no restrictivos o cobrables.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'PROMEDIO DIARIO',
        'Es el promedio diario de tickets impresos para poder determinar el consumo de papel'
      )
    );
    this.glossaryItems.push(new GlossaryItem('R', ''));
    this.glossaryItems.push(
      new GlossaryItem(
        'RET HOLD',
        'Retencion %. Porcentaje de retencion para la sala o casino. (Coin Diff / Coin In) x 100 (%)'
      )
    );
    this.glossaryItems.push(new GlossaryItem('S', ''));
    this.glossaryItems.push(new GlossaryItem('SPIDER+', 'Modulo de conexion entre la EGM y el sistema'));
    this.glossaryItems.push(
      new GlossaryItem(
        'SUBPERIODO',
        'El subperíodo se utiliza para no tomar en cuenta la diferencia entre el contador con subperíodo y el contador anterior'
      )
    );
    this.glossaryItems.push(new GlossaryItem('T', ''));
    this.glossaryItems.push(new GlossaryItem('THEO PAY', 'Porcentaje teorico de Pago en (%)'));
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET CASH IN',
        'Valor de todos los Tickets Casheables ingresados en la maquina de juego (EGM)'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('TICKET CASH IN Q', 'Cantidad de Tickets Casheables ingresados en la maquina de juego (EGM)')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET CASH OUT',
        'Valor de todos los Tickets Casheables entregados por la maquina de juego (EGM).Los mismos podran ser cobrados en Caja o ser ingresados en otra maquina de juego (EGM)'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('TICKET CASH OUT Q', 'Cantidad de Tickets Casheables entregados por la maquina de juego (EGM)')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET DIFF',
        'Es la diferencia entre Total Ticket in y Total Ticket Out (Total Ticket In - Total Ticket Out)'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET EXPIRADO',
        'Ticket que se ha vencido la fecha colocada por el establecimiento para que el mismo sea cobrado en caja.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('TICKET IMPRESO', 'Ticket impreso por el sistema. Puede ser Cash out, Hand Pay o Jackpot.')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET REST. PROMO IN',
        'Valor de todos los tickets restrictivos promocionales ingresados en la maquina de juego (EGM). Es solo Jugable.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('TICKET REST. PROMO IN Q', 'Cantidad de Tickets de Restrictivos Promocionales de entrada..')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET REST. PROMO OUT',
        'Valor de todos los Ticket Restrictivos promocionales entregado por una maquina de juego (EGM) y que el mismo no puede ser cobrado en caja. Es solo jugable'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET REST. PROMO OUT Q',
        'Cantidad de Tickets de Restrictivos promocionales entregados por una maquina de juego (EGM).'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TICKET NO REST.PROMO IN',
        'Valor de todos los Ticket No restrictivos promocionales de entrada. Tickets que al jugarlos se convierten en Tickets Casheables.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('TICKET NO REST. PROMO IN Q', 'Cantidad de Tickets No Restrictivos Promocionales de entrada.')
    );
    this.glossaryItems.push(
      new GlossaryItem('TIPO', 'Hace referencia los dos tipos de tickets que se utilizan. Cash In y Cash Out')
    );
    this.glossaryItems.push(
      new GlossaryItem('TITO', 'Significa Ticket In / Ticket Out. Ticket de Salida y Ticket de Entrada')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TOTAL TICKET IN',
        'Es el valor total de Tickets Casheable Ticket In + Restricted Promotional Ticket in + Non Restricted Promotional Ticket In'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TOTAL TICKET IN Q',
        'Es la suma de Casheable Ticket In Q + Restricted Promotional Ticket in Q + Non Restricted Promotional Ticket In Q'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TOTAL TICKET OUT',
        'Es el valor total de Tickets Casheable Ticket Out + Restricted Promotional Ticket Out'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TOTAL TICKET OUT Q',
        'Es la suma de Tickets Casheable Ticket Out Q + Restricted Promotional Ticket Out Q'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem('TOTAL DIFF', 'Es la diferencia ente el Total In y el Tot Out. (Tot In - Tot Out)')
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TOTAL DROP',
        'Todos los creditos ingresados a la maquina desde una fuente externa que no son enviados al hopper ni al reciclador (Incluye: Coin Drop, bills to drop, tickets redimidos, AFT in)'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TOTAL IN',
        'Total Drop + True Coin In. Valor total de creditos de todo el dinero fisico ingresado a la maquina.'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TOTAL OUT',
        'Cancel Credits + True Coin Out + Total Jackpot. Valor total de creditos pagados por la maquina de juego en todo concepto. ( hopper, printer, transferencia electronica etc)'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TRUE COIN IN',
        'Total de creditos por Monedas fisicas ingresadas en la maquina de juego (EGM) que caen al hopper'
      )
    );
    this.glossaryItems.push(
      new GlossaryItem(
        'TRUE COIN OUT',
        'Total de creditos por Monedas fisicas entregadas por la maquina de juego (EGM)'
      )
    );
    this.glossaryItems.push(new GlossaryItem('U', ''));
    this.glossaryItems.push(new GlossaryItem('UID', 'Identificador Unico de la Maquina en la SALA'));
    this.glossaryItems.push(new GlossaryItem('V', ''));
  }
}

export class GlossaryItem {
  concept: string;
  description: string;
  observations: string;

  constructor(c: string, d: string, o?: string) {
    this.concept = c;
    this.description = d;
    this.observations = o;
  }
}
