<div *ngIf="loadPage">
  <common-header [title]="title" [items]="items"></common-header>

  <div *ngIf="notSpider" class="d-flex">
    <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Volver</button>
  </div>

  <div *ngIf="!notSpider" style="padding-left: 0.5rem; margin-bottom: 2rem">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-8">
          <h5 class="m-0 text-dark" style="font-weight: italic">
            Spider: {{ this.model.spiderModel }} - Mac: {{ this.model.spiderMac }}
          </h5>
        </div>
      </div>
    </div>
  </div>

  <div class="content" *ngIf="!model.isCurrentUpdating && !notSpider">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="card-title">Seleccione firmware</h3>
                </div>
              </div>
            </div>

            <div class="card-body">
              <form>
                <div class="modal-body">
                  <div class="form-row">
                    <div class="form-group col-12 m-0">
                      <label class="col-form-label" for="fileName"> Archivo: </label>
                      <div class="input-group mb-3">
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            name="fileName"
                            (change)="handleFileInput($event.target.files)"
                            ngbAutofocus />
                          <label class="custom-file-label text-truncate" for="fileName"> {{ this.fileName }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-primary" (click)="refreshFirmware()">Actualizar firmware</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Volver</button>
              </div>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>

  <diV *ngFor="let modelitem of model.firmwareUpdateProcessViewModels">
    <app-state-firmware [model]="modelitem"></app-state-firmware>
  </diV>

  <app-confirm-modal [label]="'statusModal'" [yes]="'Continuar'" (onConfirm)="confirmYesModal()" [body]="body">
  </app-confirm-modal>
</div>
