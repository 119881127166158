<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12">
        <form
          role="form"
          class="w-100"
          style="display: contents"
          [formGroup]="formEdit"
          (ngSubmit)="onSubmit()"
          #formObject="ngForm">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('userName')"
                    [label]="'Usuario'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('name')"
                    [label]="'Nombre'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('lastName')"
                    [label]="'Apellido'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('dni')"
                    [label]="'DNI'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-4">
                  <label class="form-control-sm mb-05">{{ labelPassword }}</label>
                  <div class="input-group">
                    <input
                      class="form-control form-control-sm min-height-36"
                      [formControl]="this.formEdit.get('password')"
                      id="Passworduser"
                      [maxlength]="20"
                      placeholder="Password"
                      [type]="showPassword ? 'text' : 'password'"
                      autocomplete="new-password" />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          alt="show"
                          class="far fa-eye eye-show"
                          (click)="showPassword = !showPassword"
                          [class.hide]="showPassword"></i>
                        <i
                          alt="hide"
                          class="far fa-eye-slash eye-hide"
                          (click)="showPassword = !showPassword"
                          [class.hide]="!showPassword"></i>
                      </span>
                    </div>
                  </div>

                  <div
                    class="text-danger font-size-14"
                    *ngIf="
                      (this.formEdit.get('password').touched || formObject.submitted) &&
                      this.formEdit.get('password').invalid
                    ">
                    <!-- *ngIf="this.formEdit.get('password').errors.required" -->

                    <div *ngIf="this.formEdit.get('password').errors.required">
                      {{ labelPassword }} es un campo obligatorio.
                    </div>
                    <div *ngIf="formEdit.get('password').errors.minlength">
                      {{ labelPassword }} debe tener
                      {{ formEdit.get('password').errors.minlength.requiredLength }} caracteres como mínimo.
                    </div>
                  </div>
                </div>

                <div class="form-group col-12 col-sm-4">
                  <label class="form-control-sm mb-05">{{ labelConfirmPassword }}</label>
                  <div class="input-group">
                    <input
                      [type]="showConfirmPassword ? 'text' : 'password'"
                      class="form-control form-control-sm min-height-36"
                      [formControl]="this.formEdit.get('confirmpassword')"
                      id="confirmpassword"
                      [maxlength]="20"
                      placeholder="Confirmar Password" />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          alt="show"
                          class="far fa-eye eye-show"
                          (click)="showConfirmPassword = !showConfirmPassword"
                          [class.hide]="showConfirmPassword"></i>
                        <i
                          alt="hide"
                          class="far fa-eye-slash eye-hide"
                          (click)="showConfirmPassword = !showConfirmPassword"
                          [class.hide]="!showConfirmPassword"></i>
                      </span>
                    </div>
                  </div>

                  <div
                    class="text-danger font-size-14"
                    *ngIf="
                      (this.formEdit.get('confirmpassword').touched ||
                        this.formEdit.get('confirmpassword').dirty ||
                        formObject.submitted) &&
                      this.formEdit.get('confirmpassword').invalid
                    ">
                    <div *ngIf="this.formEdit.get('confirmpassword').errors.required">
                      {{ labelConfirmPassword }} es un campo obligatorio.
                    </div>

                    <div *ngIf="formEdit.get('confirmpassword').errors.notSame">
                      El Password y su confirmación son diferentes.
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group col-12 col-sm-4 ">

                                    <label class="form-control-sm mb-05">{{labelConfirmPassword}}</label>
                                    <input type="password" class="form-control form-control-sm min-height-36"
                                        [formControl]="this.formEdit.get('confirmpassword')" id="confirmpassword"
                                        [maxlength]="20" placeholder="Confirmar Password">


                                    <div class="text-danger font-size-14" *ngIf="(this.formEdit.get('confirmpassword').touched || this.formEdit.get('confirmpassword').dirty || formObject.submitted)
                                        && this.formEdit.get('confirmpassword').invalid">

                                        <div *ngIf="this.formEdit.get('confirmpassword').errors.required">
                                            {{labelConfirmPassword}} es un campo obligatorio.
                                        </div>

                                        <div *ngIf="formEdit.get('confirmpassword').errors.notSame">
                                            El Password y su confirmación son diferentes.
                                        </div>

                                    </div>

                                </div> -->
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('phone')"
                    [label]="'Teléfono'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('email')"
                    [label]="'Email'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-4">
                  <common-input-multiselect
                    [list]="rolesList"
                    class="form-control-sm"
                    label="Roles"
                    *ngIf="canEdit || !this.isEdit"
                    [formControl]="formEdit.get('roles')"
                    [formSubmitted]="formObject.submitted">
                  </common-input-multiselect>

                  <label *ngIf="!canEdit && this.isEdit" class="form-control-sm mb-05">Roles</label>
                  <div class="input-group" *ngIf="!canEdit && this.isEdit">
                    <input class="form-control form-control-sm min-height-36" value="Admin" [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer text-right">
              <button type="submit" [disabled]="!canEdit && this.isEdit" class="btn btn-primary">{{ title }}</button>
              <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- /.row
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content -->
</div>
