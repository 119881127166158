<common-header title="Glosario" [items]=""></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <table class="table table-hover w-100">
          <thead class="text-center">
            <tr class="row">
              <th class="col-2">Concepto</th>
              <th class="col-6">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row" *ngFor="let item of glossaryItems">
              <td class="col-2 text-center">{{ item.concept }}</td>
              <td class="col-10">{{ item.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
