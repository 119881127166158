import { DecimalPipe, PercentPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { GametypesComboComponent } from 'src/app/common/components/business/gametypes-combo/gametypes-combo.component';
import { ModelsComboComponent } from 'src/app/common/components/business/models-combo/models-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { CabinetService } from 'src/app/services/cabinet/cabinet.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { LocationService } from 'src/app/services/location/location.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SpiderService } from 'src/app/services/spider/spider.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-egm',
  templateUrl: './edit-egm.component.html',
  styleUrls: ['./edit-egm.component.css'],
})
export class EditEgmComponent implements OnInit {
  @ViewChild(GametypesComboComponent) gametypesCombo: GametypesComboComponent;
  @ViewChild(ModelsComboComponent) modelsCombo: ModelsComboComponent;

  formEdit: FormGroup;
  cabinetList: Array<IdTextModel> = [];
  spiderList: Array<IdTextModel> = [];
  locationList: Array<IdTextModel> = [];

  items: Array<BreadcrumbItem> = [];

  title: string = 'Nueva EGM';

  InputType = InputTypeEnum;

  egm: EGMMock = new EGMMock();
  isEdit: boolean = false;
  isCreateEgm: boolean = false;
  parameter: tableStatus = null;
  idClone: number;

  public static getName() {
    return 'EditEgmComponent';
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cabinetService: CabinetService,
    private SpiderService: SpiderService,
    private LocationService: LocationService,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private percentPipe: PercentPipe,
    private EgmService: EgmServiceService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.activatedRoute.snapshot.params.id != null || this.activatedRoute.snapshot.params.idClone != null) {
      if (this.activatedRoute.snapshot.params.id != null) {
        this.title = 'Editar EGM';
        this.isCreateEgm = false;
      } else {
        if (this.activatedRoute.snapshot.params.idClone != null) {
          this.idClone = this.activatedRoute.snapshot.params.idClone;
          this.title = 'Nueva EGM';
          this.isCreateEgm = true;
        }
      }
    } else {
      this.isCreateEgm = true;
    }

    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('/machines/egms', 'EGMs'));
    this.items.push(new BreadcrumbItem('', this.title));
  }
}
