import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccessLog } from 'src/app/common/components/viewmodels/AccessLog';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { ClosedDaysLog } from 'src/app/common/components/viewmodels/ClosedDaysLog';
import { CountersEditLog } from 'src/app/common/components/viewmodels/CountersEditLog';
import { DatatableMinimalFilters, DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { ParametersEditLog } from 'src/app/common/components/viewmodels/ParametersEditLog';
import { UsersLog } from 'src/app/common/components/viewmodels/UsersLog';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class LogsService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getAccessLogs(param: DatatableMinimalFilters): Observable<ApiResult<DataTableResult<AccessLog>>> {
    return this.http.post<ApiResult<DataTableResult<AccessLog>>>(`${this.baseUrl}Logs/GetAccessLogs`, param).pipe(
      map((x) => {
        var result = this.checkResult<DataTableResult<AccessLog>>(x);
        return result;
      }),
      catchError((error) => this.handleError<DataTableResult<AccessLog>>(error))
    );
  }

  public getUsersLogs(param: DatatableMultiFilters): Observable<ApiResult<DataTableResult<UsersLog>>> {
    return this.http.post<ApiResult<DataTableResult<UsersLog>>>(`${this.baseUrl}Logs/GetUsersLogs`, param).pipe(
      map((x) => {
        var result = this.checkResult<DataTableResult<UsersLog>>(x);
        return result;
      }),
      catchError((error) => this.handleError<DataTableResult<UsersLog>>(error))
    );
  }
  public getClosedDaysLogs(param: DatatableMultiFilters): Observable<ApiResult<DataTableResult<ClosedDaysLog>>> {
    return this.http
      .post<ApiResult<DataTableResult<ClosedDaysLog>>>(`${this.baseUrl}Logs/GetClosedDaysLogs`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<ClosedDaysLog>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<ClosedDaysLog>>(error))
      );
  }

  public getCountersEditLogs(param: DatatableMultiFilters): Observable<ApiResult<DataTableResult<CountersEditLog>>> {
    return this.http
      .post<ApiResult<DataTableResult<CountersEditLog>>>(`${this.baseUrl}Logs/GetCountersEditLogs`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CountersEditLog>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CountersEditLog>>(error))
      );
  }

  public getParametersEditLogs(
    param: DatatableMinimalFilters
  ): Observable<ApiResult<DataTableResult<ParametersEditLog>>> {
    return this.http
      .post<ApiResult<DataTableResult<ParametersEditLog>>>(`${this.baseUrl}Logs/GetParametersEditLogs`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<ParametersEditLog>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<ParametersEditLog>>(error))
      );
  }
}
