import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { VoucherModalComponent } from 'src/app/common/components/business/voucher-modal/voucher-modal.component';
import { AuthorizationModalComponent } from 'src/app/common/components/shared/authorization-modal/authorization-modal.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { DataConsts } from 'src/app/common/components/viewmodels/Casino';
import {
  AuthorizationOperationType,
  CashableType,
  GenerationReasons,
  TicketFamily,
  TicketFamilyGroup,
  TicketState,
} from 'src/app/common/components/viewmodels/Enums';
import { Receipt, Voucher } from 'src/app/common/components/viewmodels/Receipt';
import { SessionManagmentViewModel } from 'src/app/common/components/viewmodels/SessioModel';
import { TicketMock } from 'src/app/common/components/viewmodels/TicketMock';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { SessionOldReportService } from 'src/app/services/session-report/session-report.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-ticket-generation',
  templateUrl: './ticket-generation.component.html',
  styleUrls: ['./ticket-generation.component.css'],
})
export class TicketGenerationComponent implements OnInit {
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  // @ViewChild(VoucherModalComponent) voucherModal: VoucherModalComponent;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(AuthorizationModalComponent, { static: false }) authorizationModal: AuthorizationModalComponent;

  // voucher: Voucher = new Voucher();
  formEdit: FormGroup;
  title: string = '';

  items: Array<BreadcrumbItem> = [];

  helpText: string = '';
  InputType = InputTypeEnum;
  body: string = '¿Está seguro que desea generar el ticket?';
  authorizationBody: string;

  manufacturer: CashRegister = new CashRegister();
  isEdit: boolean;
  parameter: tableStatus = null;
  egmCombo: Array<IdTextModel> = [];
  generationReasonCombo: Array<IdTextModel> = [];
  familyCombo: Array<IdTextModel> = [];
  ticket: TicketMock;
  loadpage: boolean = false;
  headerTitle: string = '';
  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  idTurn: number = 0;
  isIncidentPaymentForm = false;
  currencyCode: string;
  currencySym: string;
  importeLabel: string = 'Importe ';
  @ViewChild('myForm') myForm: NgForm;
  isManualPay = false;
  isinitForm = false;

  operationType: AuthorizationOperationType;
  nonAuthorizedMaximum?: number;
  requiresAuthorization: boolean;
  authorizationId?: number;

  submiting = false;
  public static getName() {
    return 'TicketGenerationComponent';
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private ValidationService: ValidationService,
    public ticketService: TicketService,
    public sessionService: SessionService,
    private modalService: NgbModal,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    public printerService: PrinterService
  ) {}

  ngOnInit(): void {
    if (this.router.url.toString().includes('incidencepayment')) {
      //pago incidencia
      this.title = 'Pago Incidencia';
      this.isIncidentPaymentForm = true;
    } else {
      this.title = 'Pago Manual';
      this.isManualPay = true;
    }
    this.isinitForm = true;

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
      this.importeLabel += this.currencySym;
    });

    this.initForm();
    this.fillCombos();
    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', this.title));

    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.idTurn = Number(paramTurnsId);
      this.loadpage = true;
      if (isNaN(this.idTurn)) {
        this.goToErrorPage();
      }

      this.sessionService.GetSessionByTurnId(this.idTurn).subscribe((result) => {
        this.session = result.data;
        if (this.session?.idTurn === null) {
          this.router.navigate(['/session-management']);
        }
        this.headerTitle = this.sessionService.getPageTitle(this.session);
      });
    } else {
      this.goToErrorPage();
    }
  }

  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }

  GoToCashFlowOrHandPayVoucher() {
    if (this.isManualPay) {
      this.router.navigate(['/session-handpayvoucher', this.session.idTurn]);
    } else {
      this.router.navigate(['/session-cashflow', this.session.idTurn]);
    }
  }

  async GeneratTicket() {
    this.myForm.control.markAllAsTouched();

    if (this.formEdit.valid) {
      if (this.formEdit.get('formattedAmount').value <= 0) {
        this.notificationService.showError('El monto no puede ser menor o igual a cero', 'Operación rechazada');
      } else {
        if (this.formEdit.get('generationReason').value == GenerationReasons.Pago_Incidente_Sin_Codigo) {
          this.ticket = new TicketMock();
          this.ticket.familyId = TicketFamilyGroup.Cash;
          this.ticket.validatorCode = '100000000000000000';
        } else if (this.formEdit.get('generationReason').value == GenerationReasons.Pago_Incidencia_Con_Codigo) {
          //Pago incidencia SAS
          this.ticket = new TicketMock();
          this.ticket.validatorCode = this.formEdit.get('validatorCode').value;
          this.ticket.id = 0;
          this.ticket.creationEgmId = this.formEdit.get('egmId').value;

          this.ticket.ticketNumber = this.formEdit.get('ticketNumber').value;
          //  this.ticket.creationDate = moment();
          this.ticket.familyId = this.formEdit.get('family').value;
          //this.ticket.cashableBy = CashableType.UID;
        } else {
          this.ticket = new TicketMock();
          this.ticket.creationEgmId = this.formEdit.get('egmId').value;
          this.ticket.familyId = TicketFamilyGroup.Jackpot;
          this.ticket.validatorCode = '100000000000000000';
        }

        this.ticket.creationTurnId = this.idTurn;

        this.ticket.amountWithPrecisionView =
          Number(this.formEdit.get('formattedAmount').value) * DataConsts.precisionFactor;
        this.ticket.generationReasonId = parseInt(this.formEdit.get('generationReason').value);
        this.ticket.observations = this.formEdit.get('observations').value;

        if (await this.printerService.isPrinterReady()) {
          if (this.requiresAuthorization) {
            this.ticket.authorizationCode = this.authorizationModal.code.value;
            this.ticket.authorizationId = this.authorizationId;
          }

          this.ticketService.generate(this.ticket).subscribe(async (result) => {
            var success = true;
            if (result?.data?.tickets) {
              for (let index = 0; index < result?.data?.tickets.length; index++) {
                if (result?.data?.tickets.length > 0) {
                  if (await this.printerService.isPrinterReady()) {
                    await this.printerService.print(result?.data?.tickets[index], index);
                    //despues del primer ticket espero 3 segundos, pq son de distinto tipo, para q se acomode la
                    //la impresora
                    if (index === 0 && result?.data?.tickets.length > 1) {
                      await new Promise((resolve) => setTimeout(resolve, 3000));
                    }
                  } else {
                    success = false;
                    break;
                  }
                }
              }

              if (success) {
                this.notificationService.showSuccess('Voucher generado con éxito', 'Atención');
              } else {
                this.notificationService.showError(
                  'Voucher generado pero no se pudo imprimir. La impresora no está lista.',
                  'Error'
                );
              }

              this.myForm.resetForm();
              this.submiting = false;
            }
          });
        } else {
          this.notificationService.showError('No se pudo generar el ticket. La impresora no está lista.', 'Error');
        }
      }
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  async onSubmit(form: NgForm) {
    if (this.formEdit.valid) {
      if (this.nonAuthorizedMaximum != null) {
        this.submiting = true;
        if (this.formEdit.get('formattedAmount').value > this.nonAuthorizedMaximum) {
          this.requiresAuthorization = true;
          this.needsAuthorization(this.formEdit.get('formattedAmount').value);
        } else {
          this.confirmModal.openModalStatic();
        }
      } else {
        this.confirmModal.openModalStatic();
      }
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  async getNonAuthorizedMaximum() {
    if (this.formEdit.get('generationReason').value == GenerationReasons.Pago_Incidente_Sin_Codigo) {
      this.operationType = AuthorizationOperationType.Pago_Incidencia;
    } else if (this.formEdit.get('generationReason').value == GenerationReasons.Pago_Incidencia_Con_Codigo) {
      this.operationType = AuthorizationOperationType.Pago_Incidencia_Con_Ticket;
    } else if (this.formEdit.get('generationReason').value == GenerationReasons.Pago_Manual_Sin_Codigo) {
      this.operationType = AuthorizationOperationType.Pago_Manual_Sin_Alerta;
    }

    if (this.operationType != null) {
      this.ticketService.getNonAuthorizedMaximum(this.operationType).subscribe((result) => {
        if (result.error == null) {
          this.nonAuthorizedMaximum = result.data;
        }
      });
    }
  }

  needsAuthorization(amount: number) {
    this.ticketService
      .createAuthorization(this.operationType, amount * DataConsts.precisionFactor)
      .subscribe((result) => {
        if (result.data != null && result.data != 0) {
          this.authorizationId = result.data;
          this.authorizationBody = `La operación ID ${this.authorizationId} de ${this.title} requiere autorización, por favor ingresar el código a continuación`;
          this.authorizationModal.openModalStatic();
        } else {
          this.submiting = false;
        }
      });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  fillCombos() {
    this.ticketService.getGenerationReasonCombo().subscribe((result) => {
      if (this.isIncidentPaymentForm) {
        let indexPagoManual = result.data.findIndex(
          (x) => x.id == Number(GenerationReasons.Pago_Manual_Sin_Codigo).toString()
        );

        result.data.splice(indexPagoManual, 1);
      } else {
        let indexPagoNotRegister = result.data.findIndex(
          (x) => x.id == Number(GenerationReasons.Pago_Incidente_Sin_Codigo).toString()
        );
        result.data.splice(indexPagoNotRegister, 1);

        let indexPagoRegister = result.data.findIndex(
          (x) => x.id == Number(GenerationReasons.Pago_Incidencia_Con_Codigo).toString()
        );
        result.data.splice(indexPagoRegister, 1);

        this.formEdit.get('generationReason').setValue(GenerationReasons.Pago_Manual_Sin_Codigo);
        this.setFromPagoManualNr();
      }

      this.generationReasonCombo = result.data;
    });

    this.ticketService.getTicketFamilyCombo().subscribe((result) => {
      this.familyCombo = result.data;
    });
  }

  updateForm() {
    if (this.formEdit.get('generationReason').value == GenerationReasons.Pago_Incidente_Sin_Codigo) {
      this.setFormPagoIncidenciaNoRegistrado();
    } else if (this.formEdit.get('generationReason').value == GenerationReasons.Pago_Incidencia_Con_Codigo) {
      this.setFormPagoIncedenciaRegistrado();
    } else {
      this.setFromPagoManualNr();
    }
  }

  setFromPagoManualNr() {
    this.helpText = '* Para EGMs que no informan su Pago Manual.';

    //guardo el reason antes de resetear
    var reason = this.formEdit.get('generationReason').value;

    this.myForm.resetForm();

    // this.ticket.familyId = TicketFamilyGroup.Cash;
    this.formEdit.get('family').setValue(Number(TicketFamilyGroup.Handpay));
    this.formEdit.get('family').clearValidators();
    this.formEdit.get('family').disable();

    this.formEdit
      .get('egmId')
      .setValidators([Validators.required, Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())]);
    this.formEdit;

    this.formEdit.get('validatorCode').setValue(null);

    this.formEdit.get('ticketNumber').setValue(null);
    //this.formEdit.get('observations').setValue(null);

    this.formEdit.get('validatorCode').clearValidators();

    this.formEdit.get('ticketNumber').clearValidators();
    //this.formEdit.get('family').clearValidators();

    this.formEdit.get('validatorCode').disable();

    this.formEdit.get('ticketNumber').disable();
    this.formEdit.get('family').setValidators(Validators.required);

    this.formEdit.get('observations').setValidators(Validators.required);

    this.formEdit.get('egmId').enable();
    this.formEdit.get('generationReason').setValue(reason);
  }

  setFormPagoIncedenciaRegistrado() {
    this.helpText = '* La EGM ha creado o impreso un ticket que todavía no se encuentra en el historial.';

    //guardo el reason antes de resetear
    var reason = this.formEdit.get('generationReason').value;

    this.myForm.resetForm();

    this.formEdit
      .get('validatorCode')
      .setValidators([
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.maxLength(18),
        Validators.minLength(18),
      ]);
    this.formEdit
      .get('egmId')
      .setValidators([Validators.required, Validators.maxLength(this.ValidationService.getMaxLenghtAllowed())]);
    this.formEdit
      .get('ticketNumber')
      .setValidators([
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]);

    this.formEdit.get('family').setValidators([Validators.required]);
    this.formEdit.get('observations').setValidators(Validators.required);
    this.formEdit.get('family').setValue(null);
    // this.formEdit.get('observations').setValidators(null);
    // this.formEdit.get('observations').setValue(null);

    this.formEdit.updateValueAndValidity(); //Hay que llamarlo luego de usar validaciones sincronicas. ?¿

    this.formEdit.get('validatorCode').enable();
    this.formEdit.get('egmId').enable();
    this.formEdit.get('ticketNumber').enable();
    this.formEdit.get('family').enable();

    this.formEdit.get('generationReason').setValue(reason);
  }

  setFormPagoIncidenciaNoRegistrado() {
    this.helpText = '* Por errores de la EGM. Casos en que la máquina no ha creado un ticket';

    //guardo el reason antes de resetear
    var reason = this.formEdit.get('generationReason').value;

    this.myForm.resetForm();

    // this.ticket.familyId = TicketFamilyGroup.Cash;
    this.formEdit.get('family').setValue(Number(TicketFamilyGroup.Cash));
    this.formEdit.get('family').clearValidators();
    this.formEdit.get('family').disable();

    this.formEdit.get('validatorCode').setValue(null);
    this.formEdit.get('egmId').setValue(null);
    this.formEdit.get('ticketNumber').setValue(null);
    this.formEdit.get('observations').setValidators(Validators.required);

    this.formEdit.get('validatorCode').clearValidators();
    this.formEdit.get('egmId').clearValidators();
    this.formEdit.get('ticketNumber').clearValidators();

    this.formEdit.get('validatorCode').disable();
    this.formEdit.get('egmId').disable();
    this.formEdit.get('ticketNumber').disable();
    this.formEdit.get('family').setValidators(Validators.required);

    // this.formEdit.get('observations').setValidators(Validators.required);
    // this.formEdit.get('observations').enable();

    this.formEdit.get('generationReason').setValue(reason);
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(0),
      ticketNumber: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      validatorCode: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.maxLength(18),
        Validators.minLength(18),
      ]),
      egmId: new FormControl(null, [Validators.maxLength(18)]),
      formattedAmount: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),

      //si voy a buscar el maximo que puedo hacer de ticket lo seteo asi:
      //this.formEdit.controls['formattedAmount'].setValidators([Validators.required, Validators.max(this.maximumTicketValue), Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/)]);
      observations: new FormControl(null, Validators.required),
      generationReason: new FormControl(null, Validators.required),
      family: new FormControl(null, Validators.required),
    });

    this.formEdit.get('generationReason').valueChanges.subscribe(async (value) => {
      await this.getNonAuthorizedMaximum();
    });
  }
}
