import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbItem } from '../common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.css'],
})
export class CommonHeaderComponent implements OnInit {
  @Input() items: Array<BreadcrumbItem> = [];
  @Input() title: string = '';

  constructor() {}

  ngOnInit(): void {}
}
