import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { AuthorizationMock } from 'src/app/common/components/viewmodels/AuthorizationMock';
import { AuthorizationOperationType } from 'src/app/common/components/viewmodels/Enums';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-list-authorizations',
  templateUrl: './list-authorizations.component.html',
  styleUrls: ['./list-authorizations.component.css'],
})
export class ListAuthorizationsComponent implements OnInit {
  public data: commonTableData<AuthorizationMock>;

  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<AuthorizationMock>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  authorization: AuthorizationMock = new AuthorizationMock();
  parameter: tableStatus = null;
  showUpdateFir: boolean;
  loadedForm: boolean;
  title = 'Autorizaciones';
  currencyCode: string;
  currencySym: string;

  public static getName() {
    return 'ListAuthorizationsComponent';
  }
  constructor(
    public ticketService: TicketService,
    public currencyPipe: CurrencyPipe,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private casinoService: CasinoService
  ) {}

  async ngOnInit(): Promise<void> {
    // ngOnInit() {
    this.items.push(new BreadcrumbItem('', 'Seguridad'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.showUpdateFir = (
      await this.casinoService.isComponentAuthorized('EditFirmwareComponent', 'Page').toPromise()
    ).data;

    this.data = new commonTableData();
    let paramEGM = this.paramService.getAndDeleteParameters();

    if (paramEGM != null) {
      this.data.searchText = paramEGM.searchString;
      this.data.pageLength = paramEGM.pageLength;
      this.data.currentPage = paramEGM.currentPage;
    }
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    await this.setGridData(true);
    this.loadedForm = true;
  }

  async setGridData(param1: boolean): Promise<void> {
    let service = this.ticketService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<AuthorizationMock>>> {
      return service.getPendingAuthorizations(param);
    };

    let that = this;

    this.data.columns = [
      { data: 'id', title: 'ID' },
      {
        data: 'creationDate',
        title: 'Fecha y Hora',
        render: function (data, type, row: AuthorizationMock) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : `-`;
        },
      },
      { data: 'userName', title: 'Usuario' },
      {
        data: 'type',
        title: 'Tipo Operación',
        render: function (data, type, row) {
          return data != null ? AuthorizationOperationType[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'formattedAmount',
        title: 'Monto',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      { data: 'code', title: 'Código' },
    ];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }
}
