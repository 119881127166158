import { TicketFamily } from './Enums';

export class TicketsSaleViewModel {
  quantity: number;
  formattedAmount: number;
  familyId: TicketFamily;
  restrictive: boolean;
  turnId: number;

  public constructor(init?: Partial<TicketsSaleViewModel>) {
    Object.assign(this, init);
  }
}
