<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <form role="form" [formGroup]="formEdit" (ngSubmit)="onSubmit()" #formObject="ngForm">
            <div class="card-body">
              <div class="form-row">
                <!-- <div class="form-group col-12 col-sm-6 col-xl-4">
                                    <app-common-input [control]="this.formEdit.get('code')" [label]="'Código'"
                                        [formSubmitted]="formObject.submitted" [type]="InputType.text">
                                    </app-common-input>
                                </div> -->
                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('model')"
                    [label]="'Modelo'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('ipSuggested')"
                    [label]="'Dirección IP SUGERIDA'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.IP">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="isEdit">
                  <app-common-input
                    [control]="this.formEdit.get('ip')"
                    [label]="'Dirección IP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.IP">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="isEdit">
                  <app-common-input
                    [control]="this.formEdit.get('firmware')"
                    [label]="'Firmware'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('mac')"
                    [label]="'Dirección MAC'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.MAC">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('manufacturingDate')"
                    [label]="'Fecha de alta'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.date">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="isEdit && showextended | async">
                  <app-common-input
                    [control]="this.formEdit.get('extendedVersion')"
                    [label]="'Versión extendida'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="isEdit && showextended | async">
                  <app-common-input
                    [control]="this.formEdit.get('checkSum')"
                    [label]="'CheckSum'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="this.formEdit.get('comments')"
                    [label]="'Observaciones'"
                    [type]="InputType.textarea">
                  </app-common-input>
                </div>
              </div>
            </div>
            <!-- /.card-body -->

            <div class="card-footer">
              <app-confirm-buttons (onCancel)="cancel()"></app-confirm-buttons>
            </div>
          </form>
        </div>
        <!-- /.card -->
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
