import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  commonTableData,
  commonTableCustomButton,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Ticket } from 'src/app/common/components/viewmodels/Ticket';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-ticket-info',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.css'],
})
export class TicketInfoComponent {
  public static getName() {
    return 'TicketInfoComponent';
  }
}

// export class TicketInfoComponent implements OnInit {

//   ticketFamily: Array<IdTextModel> = [];
//   ticketType: Array<IdTextModel> = [];

//   currencyCode: string;
//   currencySym: string;

//   egmCombo: Array<IdTextModel> = [];

//   items: Array<BreadcrumbItem> = [];

//   title: string = 'Información de tickets'

//   date: moment.Moment = moment(new Date());

//   public data: commonTableData<Ticket>;

//   formSearch: FormGroup;
//   searchMade: boolean;

//   constructor(private router: Router,
//     public notificationService: NotificationService,
//     public RoomService: RoomServiceService,
//     public EgmService: EgmServiceService,
//     public TicketService: TicketService,
//     public CasinoService: CasinoService,
//     public currencyPipe: CurrencyPipe,
//     private activatedRoute: ActivatedRoute) { }

//   ngOnInit(): void {

//     this.items.push(new BreadcrumbItem(this.router.url, 'TITO'));
//     this.items.push(new BreadcrumbItem(this.router.url, this.title));

//     //Armo los combos de busqueda

//     this.fillCombos();

//     this.initForm();

//     let service = this.TicketService;

//     this.data = new commonTableData();
//     this.data.showEditButton = false;
//     this.data.showDeleteButton = false;
//     this.data.showCreateButton = false;
//     // this.data.mainTitle = 'Parámetros avanzados de EGMs'
//     this.data.searching = false;
//     this.data.paging = true;
//     let button: commonTableCustomButton = new commonTableCustomButton();
//     button.icon = "fa-history";
//     button.tooltip = "Ver historial";
//     button.columntitle = "Historial";
//     this.data.commonTableCustomButton.push(button);

//     this.data.getData = function (
//       param: any
//     ): Observable<ApiResult<DataTableResult<Ticket>>> {
//       return service.getTickets(param);
//     };

//     this.CasinoService.getData().subscribe((result) => {
//       this.currencyCode = result.data.currencyCode;
//       this.currencySym = result.data.currencySymbol;
//     })

//     const that = this;

//     this.data.columns = [
//       { data: 'id', title: 'Nº Ticket' },
//       {
//         data: 'uid', title: 'EGM',
//         "render": function (data, type, row) {
//           return data == null ? '' : data;
//         }
//       },
//       {
//         data: 'cashMachine.cuid', title: 'Caja',
//         "render": function (data, type, row) {
//           return data == null ? '' : data;
//         }
//       },
//       { data: 'code', title: 'Código' },
//       {
//         data: 'formattedAmount', title: 'Importe',
//         "render": function (data: number, type, row) {
//           return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
//         }
//       },
//       { data: 'creationDate', title: 'Fecha' },
//       { data: 'type', title: 'Ticket' },
//       { data: 'family', title: 'Familia' },
//     ];

//   }

//   initForm() {
//     this.formSearch = new FormGroup({
//       'to': new FormControl(),
//       'egmUid': new FormControl(),
//       'from': new FormControl(),
//       'ticketType': new FormControl(),
//       'ticketFamily': new FormControl(),
//       'ticketStatus': new FormControl(),
//     });
//   }

//   onSubmit() {
//     this.searchMade = true;
//   }

//   private fillCombos() {

//     var egmList = this.EgmService.getEGMs(null);
//     egmList.forEach(observableEgm => {
//       observableEgm.data.dataResults.forEach(egm => {
//         this.egmCombo.push(new IdTextModel(egm.uid, egm.uid.toString()));
//       })
//     })

//     this.TicketService.getTicketFamilyCombo().subscribe((result) => {
//       this.ticketFamily = result.data;
//     });
//     this.ticketType = this.TicketService.getTicketType(this.ticketFamily);

//   }

//   public onCustomButton(data): void {
//     this.router.navigate(['/tito/ticket-history', data.row.code]);
//   }

// }
