import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GuidTextModel, IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Cabinet } from 'src/app/common/components/viewmodels/Cabinet';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CabinetService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getCabinetsCombo(): Observable<ApiResult<GuidTextModel[]>> {
    return this.http.get<ApiResult<GuidTextModel[]>>(`${this.baseUrl}Cabinets/GetCabinetsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<GuidTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<GuidTextModel[]>(error))
    );
  }
  public getCabinetsCombo2(idModel: string): Observable<ApiResult<GuidTextModel[]>> {
    return this.http
      .get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Cabinets/GetCabinetsComboById?idModel=${idModel}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<GuidTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<GuidTextModel[]>(error))
      );
  }
  public getCabinets(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<Cabinet>>> {
    return this.http
      .post<ApiResult<DataTableResult<Cabinet>>>(`${this.baseUrl}Cabinets/GetCabinets`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Cabinet>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Cabinet>>(error))
      );
  }
  public getCabinet(id: string): Observable<ApiResult<Cabinet>> {
    return this.http.get<ApiResult<Cabinet>>(`${this.baseUrl}Cabinets/GetCabinet?id=${id}`).pipe(
      map((x) => this.checkResult<Cabinet>(x)),
      catchError((error) => this.handleError<Cabinet>(error))
    );
  }
  public createOrEdit(cabinet: Cabinet): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Cabinets/CreateOrEdit`, cabinet).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public deleteCabinet(id: string): Observable<ApiResult<string>> {
    return this.http.delete<ApiResult<string>>(`${this.baseUrl}Cabinets/DeleteCabinet?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }
}
