import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters, DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { Events } from 'src/app/common/components/viewmodels/Events';

import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class SystemEventService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  // public getSystemEvents(dataTablesParameters: any): Observable<ApiResult<DataTableResult<SystemEvent>>> {

  //   var listSystemEvent: SystemEvent[] = [];
  //   for (let i = 0; i < 1; i++) {

  //     var objRet0: SystemEvent = new SystemEvent();
  //     objRet0.id = 1;
  //     objRet0.egm.uid = 1003
  //     objRet0.egm.manufacturer = 'Fabricante A';
  //     objRet0.egm.model = 'TU1';
  //     objRet0.egm.denomination = 0.01;
  //     objRet0.egm.SASversion = '1.2';
  //     objRet0.description = 'Volvió conexión'
  //     objRet0.systemeventDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
  //     objRet0.onlineStatus = true;

  //     listSystemEvent.push(objRet0);

  //     var objRet: SystemEvent = new SystemEvent();
  //     objRet.id = 2
  //     objRet.egm.uid = 1002
  //     objRet.egm.manufacturer = 'Fabricante B';
  //     objRet.egm.model = 'TU2';
  //     objRet.egm.denomination = 0.01;
  //     objRet.egm.SASversion = '1.2';
  //     objRet.description = 'Cerro puerta'
  //     objRet.systemeventDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
  //     objRet.onlineStatus = false;

  //     listSystemEvent.push(objRet);

  //     var objRet2: SystemEvent = new SystemEvent();
  //     objRet2.id = 3;
  //     objRet2.egm.uid = 1001
  //     objRet2.egm.manufacturer = 'Fabricante C';
  //     objRet2.egm.model = 'TU3';
  //     objRet2.egm.denomination = 0.01;
  //     objRet2.egm.SASversion = '1.2';
  //     objRet2.description = 'Abrio puerta'
  //     objRet2.systemeventDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
  //     objRet2.onlineStatus = true;

  //     listSystemEvent.push(objRet2);

  //     var objRet3: SystemEvent = new SystemEvent();
  //     objRet3.id = 4
  //     objRet3.egm.uid = 1000
  //     objRet3.egm.manufacturer = 'Fabricante D';
  //     objRet3.egm.model = 'TU4';
  //     objRet3.egm.denomination = 0.01;
  //     objRet3.egm.SASversion = '1.2';
  //     objRet3.description = 'Perdió conexión'
  //     objRet3.systemeventDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
  //     objRet3.onlineStatus = false;

  //     listSystemEvent.push(objRet3);

  //   }

  //   var ret = new ApiResult<DataTableResult<SystemEvent>>()
  //   ret.data.dataResults = listSystemEvent;
  //   ret.data.recordsTotal = 8;
  //   ret.data.recordsFiltered = 8;

  //   return new Observable((o) => {
  //     o.next(ret)
  //   });
  // }
  public getSystemEventsCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}SystemEvents/GetSystemEventsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getSystemEvents(param: DatatableMinimalFilters): Observable<ApiResult<DataTableResult<Events>>> {
    return this.http
      .post<ApiResult<DataTableResult<Events>>>(`${this.baseUrl}SystemEvents/GetSystemEventLogs`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Events>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Events>>(error))
      );
  }
}
