import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { ApiResult, ApiError } from 'src/app/common/components/viewmodels/apiResult';

@Injectable({
  providedIn: 'root',
})
export abstract class baseService {
  protected constructor(private toastr: ToastrService, private router: Router) {}

  protected showError(error: string) {
    if (!error) return;

    var max = 500;
    error = error.length > max ? error.substr(0, max - 1) + ' &hellip;' : error;

    this.toastr.error(error, '¡Advertencia!', {
      timeOut: 30000,
      enableHtml: true,
    });
  }

  protected checkResult<T>(x: ApiResult<T>, hideSuccess?: boolean) {
    //this.disableUI = false;

    if (x?.error?.generalMessage) {
      console.error(x.error.generalMessage);
      this.showError(x.error.generalMessage);
    } else if (x?.error?.fieldErrors?.length > 0) {
      x.error.fieldErrors.forEach((fieldErr) => {
        console.error(fieldErr.field + ': ' + fieldErr.error);
        this.showError('<b>' + fieldErr.field + ':<br> ' + fieldErr.error);
      });
    } else if (x.data && typeof x.data === 'string') {
      if (!hideSuccess) this.toastr.success(x.data, 'Operación correcta');
    }

    return x;
  }

  protected handleError<T>(error: HttpErrorResponse, hideError?: boolean) {
    //this.disableUI = false;
    var inner: string;

    if (error.url)
      if (error.url.includes('/Account/Login') || error.url.includes('/Account/AccessDenied')) {
        var url = error.url;
        if (url.indexOf('?') > 0 && error.url.includes('/Account/Login')) url = url.substring(0, url.indexOf('?'));

        window.location.href = url;
        return;
      }

    try {
      if (error.error) inner = JSON.stringify(error.error);
    } catch (er) {
      inner = error.error;
    }

    var errorDesc =
      `<b>URL:</b> ${error.url} ` +
      `<b>Http Code:</b> ${error.status}<br/>` +
      `<b>Mensaje:</b> ${error.message}<br/>` +
      `<b>Contenido:</b> ${inner}`;
    try {
      console.error('Custom Error: ' + JSON.stringify(error));
    } catch (er) {
      console.error('Custom Error: ' + errorDesc);
    }
    if (!hideError) this.showError(errorDesc);
    var result = new ApiResult<T>();
    result.error = new ApiError();
    result.error.generalMessage = errorDesc;

    return of(result);
  }
}
