import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CalculatedCountersViewModel } from 'src/app/common/components/viewmodels/CalculatedCountersViewModel';
import { CountersCurrent, CountersMock } from 'src/app/common/components/viewmodels/CountersMock';
import { DatatableMinimalFilters, DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { EditCountersViewModel } from 'src/app/common/components/viewmodels/editCountersViewModel';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CountersService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getCountersCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Counters/GetCountersCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getCountersWithMinimalFilters(
    filters: DatatableMinimalFilters
  ): Observable<ApiResult<DataTableResult<CountersMock>>> {
    return this.http
      .post<ApiResult<DataTableResult<CountersMock>>>(`${this.baseUrl}Counters/GetCountersWithMinimalFilters`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CountersMock>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CountersMock>>(error))
      );
  }

  public GetInstantCounters(filters: DatatableMinimalFilters): Observable<ApiResult<DataTableResult<CountersCurrent>>> {
    return this.http
      .post<ApiResult<DataTableResult<CountersCurrent>>>(`${this.baseUrl}Counters/GetInstantCounters`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CountersCurrent>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CountersCurrent>>(error))
      );
  }

  public getEmptyDataTable(filters: DatatableMinimalFilters): Observable<ApiResult<DataTableResult<CountersMock>>> {
    return this.http
      .post<ApiResult<DataTableResult<CountersMock>>>(`${this.baseUrl}Counters/GetEmptyDataTable`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CountersMock>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CountersMock>>(error))
      );
  }

  public getEmptyDataTableCountersCurrent(
    filters: DatatableMinimalFilters
  ): Observable<ApiResult<DataTableResult<CountersCurrent>>> {
    return this.http
      .post<ApiResult<DataTableResult<CountersCurrent>>>(
        `${this.baseUrl}Counters/getEmptyDataTableCountersCurrent`,
        filters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CountersCurrent>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CountersCurrent>>(error))
      );
  }

  public getCounterssWithMultiFilters(
    filters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<CalculatedCountersViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<CalculatedCountersViewModel>>>(
        `${this.baseUrl}Counters/GetCountersWithMultiFilters`,
        filters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CalculatedCountersViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CalculatedCountersViewModel>>(error))
      );
  }

  public setCounterStatus(counter: CountersMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Counters/SetCounterStatus`, counter).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getEditCounters(id: number): Observable<ApiResult<EditCountersViewModel>> {
    return this.http.get<ApiResult<EditCountersViewModel>>(`${this.baseUrl}Counters/GetEditCounters?id=${id}`).pipe(
      map((x) => this.checkResult<EditCountersViewModel>(x)),
      catchError((error) => this.handleError<EditCountersViewModel>(error))
    );
  }

  public editCounters(countersMock: CountersMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Counters/EditCounters`, countersMock).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public setSubperiod(counter: CountersMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Counters/SetSubperiod`, counter).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public resetSubperiods(filters: MinimalFilters): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Counters/ResetSubperiods`, filters).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
}
