<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-header" *ngIf="!PrinterService.isBrowserSupported">
        <h3 class="card-title">Su navegador no permite usar la impresora</h3>
      </div>
      <div class="card-body" *ngIf="PrinterService.isBrowserSupported">
        <div class="form-row">
          <div class="form-group col-12 col-sm-6 col-xl-2">
            <app-info-display
              [label]="'Impresora'"
              [model]="
                PrinterService.processing ? 'Procesando' : PrinterService.currentPort ? 'Encontrada' : 'No encontrada'
              ">
            </app-info-display>
          </div>
          <div class="form-group col-12 col-sm-6 col-xl-2" *ngIf="showFirmware && PrinterService.status?.softVersion">
            <app-info-display [label]="'Firmware'" [model]="PrinterService.status.softVersion"> </app-info-display>
          </div>
          <div class="form-group col-12 col-sm-6 col-xl-2">
            <app-info-display [label]="'Estado'" [model]="PrinterService.getStatusDesc()"> </app-info-display>
          </div>
          <div class="form-group col-12 col-sm-6 col-xl-2">
            <app-info-display
              [label]="'Error'"
              *ngIf="PrinterService.status?.isError"
              [model]="PrinterService.getErrorDesc()">
            </app-info-display>
          </div>
        </div>
      </div>

      <div
        class="card-footer text-right"
        *ngIf="PrinterService.isBrowserSupported && PrinterService.currentPort == null">
        <button
          class="btn btn-primary"
          (click)="connectCOM()"
          [class.disabled]="PrinterService.processing || PrinterService.currentPort != null">
          <i class="fas fa-plus"></i> Conectar impresora
        </button>
      </div>
    </div>
  </div>
</div>
