import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import {
  commonTableData,
  commonTableCustomButton,
  tableStatus,
  CommonTableComponent,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Spider } from 'src/app/common/components/viewmodels/Spider';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { SpiderService } from 'src/app/services/spider/spider.service';

@Component({
  selector: 'app-list-spiders',
  templateUrl: './list-spiders.component.html',
  styleUrls: ['./list-spiders.component.css'],
})
export class ListSpidersComponent implements OnInit {
  public data: commonTableData<Spider>;

  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Spider>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  spider: Spider = new Spider();
  parameter: tableStatus = null;
  canDelete: boolean;
  title: string = 'Spider Plus';
  public static getName() {
    return 'ListSpidersComponent';
  }
  constructor(
    public spiderService: SpiderService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.data = new commonTableData();
    let paramSpider = this.paramService.getAndDeleteParameters();

    if (paramSpider != null) {
      this.data.searchText = paramSpider.searchString;
      this.data.pageLength = paramSpider.pageLength;
      this.data.currentPage = paramSpider.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }
  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/spiders/edit-spider', row.id]);
  }

  public async onDeleteButton(row): Promise<void> {
    this.spider = row;
    await this.getStatus();
    this.body = '¿Está seguro que desea eliminar la spider ' + this.spider.code + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/spiders/create-spider']);
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  confirmDelete() {
    if (!this.canDelete) {
      this.notificationService.showWarning('No es posible borrar, pertenece a una máquina activa', 'Atención!');
    }
    if (this.canDelete) {
      this.spiderService.deleteSpider(this.spider.id).subscribe((result) => {
        this.updateTable();
      });
    }
  }
  getStatus() {
    this.spiderService.getSpider(this.spider.id).subscribe((result) => {
      this.canDelete = result.data.canEdit;
    });
  }
  setGridData(param1: boolean): void {
    let service = this.spiderService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Spider>>> {
      return service.getSpiders(param);
    };

    this.data.columns = [
      // { data: 'code', title: 'Código' },
      { data: 'model', title: 'Modelo' },
      {
        data: 'ip',
        title: 'Dirección IP',
      },

      {
        data: 'mac',
        title: 'Dirección MAC',
      },
      {
        data: 'uidEgm',
        title: 'EGM Uid',
        render: function (data, type, row: Spider) {
          return data != null ? data : `-`;
        },
      },
      {
        data: 'checkSum',
        title: 'CheckSum',
      },
      { data: 'firmware', title: 'Firmware' },
      // {
      //   data: 'comments',
      //   title: 'Observaciones',
      //   render: function (data, type, row) {
      //     return data != null ? data : '-';
      //   },
      // },
      {
        data: 'manufacturingDate',
        title: 'Fecha de alta',
        render: function (data: moment.Moment, type, row) {
          // return moment(data).format('DD/MM/yyyy, HH:mm:ss');
          return moment(data).format('DD/MM/yyyy');
        },
      },
    ];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }
}
