import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
//const moment = require('moment')
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import {
  Firmwarefile,
  FirmwareListProcessViewModel,
  FirmwareUpdateProcessViewModel,
} from 'src/app/common/components/viewmodels/EGM';

import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-edit-firmware',
  templateUrl: './edit-firmware.component.html',
  styleUrls: ['./edit-firmware.component.css'],
})
export class EditFirmwareComponent implements OnInit {
  model: FirmwareListProcessViewModel = null;

  moment = moment;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string = '';
  //  modelFile: Firmwarefile = new Firmwarefile();
  fileData: File;
  fileName: string = 'Elegir archivo';

  items: Array<BreadcrumbItem> = [];
  title: string = 'Firmware EGM';
  loadPage: boolean = false;
  notSpider = false;

  public static getName() {
    return 'EditFirmwareComponent';
  }
  parameter: tableStatus = null;
  constructor(
    private router: Router,
    private paramService: ParametersService<tableStatus>,
    public egmService: EgmServiceService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute
  ) {}
  performRefreshFirmware() {
    if (this.fileData == null) {
      this.notificationService.showError('Debe seleccionar un archivo', 'Error');
      return;
    }

    this.egmService
      .updateFirmware(Number(this.activatedRoute.snapshot.params.id), this.fileData)
      .subscribe((result) => {
        if (result.error == null) {
          window.location.reload();
        }
      });
  }

  refreshFirmware() {
    this.confirmModal.openModal();
    this.body = `¿Está seguro que desea comenzar la actualización de firmware  de la máquina ${this.model.egmUid} ?`;
  }

  handleFileInput(files: FileList) {
    //aca podría controlar tamaño de archivo
    this.fileData = files.item(0);
    this.fileName = this.fileData.name;
  }

  confirmYesModal() {
    this.performRefreshFirmware();
  }

  cancel() {
    this.router.navigateByUrl('/machines/egms');
  }

  async ngOnInit(): Promise<void> {
    this.items.push(new BreadcrumbItem('/machines/egms', 'Máquinas'));
    this.items.push(new BreadcrumbItem('/machines/egms', 'EGMs'));
    this.items.push(new BreadcrumbItem('', this.title));

    if (this.activatedRoute.snapshot.params.id == null) {
      this.paramService.saveParameters(this.parameter);
      this.router.navigateByUrl('/machines/egms');
    } else {
      this.egmService.getFirmwareUpdateProcess(Number(this.activatedRoute.snapshot.params.id)).subscribe((result) => {
        if (result.error == null) {
          this.model = result.data;

          if (this.model.spiderId == 0) {
            this.title = `Firmware EGM ${this.model.egmUid} sin Spider asociado`;
            this.notSpider = true;
          } else {
            this.title = `Firmware EGM ${this.model.egmUid}`;
            if (this.model.currentFirmware != null || this.model.currentExtendedVersion != null)
              this.title += `(${this.model.currentFirmware}-${this.model.currentExtendedVersion})`;
          }
          this.loadPage = true;
        }
      });
    }
  }
}
