export class IdTextModel {
  public id: string;
  public text: string;

  constructor(id: any, description: string) {
    this.id = id.toString();
    this.text = description;
  }
}

export class GuidTextModel {
  public id: string;
  public text: string;
  constructor(id: number, description: string) {
    this.id = id.toString();
    this.text = description;
  }
}
