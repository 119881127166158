import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { IdTextModel } from '../../shared/IdTextModel';

@Component({
  selector: 'ticket-family-groups-combo',
  templateUrl: './ticket-family-groups-combo.component.html',
  styleUrls: ['./ticket-family-groups-combo.component.css'],
})
export class TicketFamilyGroupsComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  combo: Array<IdTextModel> = [];
  label: string = 'Familia';
  @Output() valueChanged = new EventEmitter();

  constructor(private TicketsService: TicketService) {}

  ngOnInit(): void {
    this.fillCombo();
    if (this.multiselect) this.label = 'Familias';
  }

  private fillCombo() {
    this.TicketsService.getTicketFamilyCombo().subscribe((result) => {
      this.combo = result.data;
    });
  }
  public getCombo() {
    return this.combo;
  }
}
