import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { GameType } from 'src/app/common/components/viewmodels/GameType';
import { GametypesService } from 'src/app/services/gametypes/gametypes.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-list-gametypes',
  templateUrl: './list-gametypes.component.html',
  styleUrls: ['./list-gametypes.component.css'],
})
export class ListGametypesComponent implements OnInit {
  public data: commonTableData<GameType>;
  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<GameType>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  gametype: GameType = new GameType();
  parameter: tableStatus = null;

  public static getName() {
    return 'ListGametypesComponent';
  }
  constructor(
    public GameTypeService: GametypesService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/gametypes/edit-gametype', row.id]);
  }

  public onDeleteButton(row): void {
    this.gametype = row;
    this.body = '¿Está seguro que desea eliminar el juego ' + this.gametype.name + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/gametypes/create-gametype']);
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  confirmDelete() {
    this.GameTypeService.deleteGameType(this.gametype.id).subscribe((result) => {
      this.updateTable();
    });
  }

  setGridData(param1: boolean): void {
    let service = this.GameTypeService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<GameType>>> {
      return service.getGameTypes(param);
    };
    this.data.columns = [
      { data: 'name', title: 'Juegos' },
      { data: 'model.name', title: 'Modelo' },
    ];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Máquinas'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Juegos'));
    this.data = new commonTableData();
    let paramGame = this.paramService.getAndDeleteParameters();

    if (paramGame != null) {
      this.data.searchText = paramGame.searchString;
      this.data.pageLength = paramGame.pageLength;
      this.data.currentPage = paramGame.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }
}
