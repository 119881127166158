import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DailyTicketsAverageViewModel } from 'src/app/common/components/viewmodels/DailyTicketsAverageViewModel';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { AverageReport } from 'src/app/common/components/viewmodels/secondaryClasses';
import { SummarizedTicketsAverageViewModel } from 'src/app/common/components/viewmodels/SummarizedTicketsAverageViewModel';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-tickets-average',
  templateUrl: './tickets-average.component.html',
  styleUrls: ['./tickets-average.component.css'],
})
export class TicketsAverageComponent implements OnInit {
  @ViewChild('tableDaily') tableDaily: CommonTableComponent<DailyTicketsAverageViewModel>;
  @ViewChild('tableSummarized') tableSummarized: CommonTableComponent<SummarizedTicketsAverageViewModel>;

  items: Array<BreadcrumbItem> = [];

  title: string = 'Tickets Promedio';

  date: moment.Moment = moment(new Date());

  public dataDaily: commonTableData<DailyTicketsAverageViewModel>;
  public dataSummarized: commonTableData<SummarizedTicketsAverageViewModel>;

  formSearch: FormGroup;
  searchMade: boolean;
  dateRange: string;

  public static getName() {
    return 'TicketsAverageComponent';
  }

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public TicketService: TicketService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();
    this.initGrid();
  }

  initGrid() {
    this.dataDaily = new commonTableData();
    this.dataDaily.showEditButton = false;
    this.dataDaily.showDeleteButton = false;
    this.dataDaily.showCreateButton = false;
    this.dataDaily.searching = false;
    this.dataDaily.paging = true;
    this.dataDaily.loadFirstTime = false;
    this.dataDaily.showAll = true;
    this.dataDaily.columns = [
      {
        data: 'uidType',
        title: 'Tipo UID',
        width: '150px',
      },
      {
        data: 'day',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy');
        },
        width: '127px',
      },
      {
        data: 'uidQuantity',
        title: 'Cantidad de UIDs',
        width: '324px',
      },
      {
        data: 'ticketQuantity',
        title: 'Tickets Impresos',
        width: '324px',
      },
      {
        data: 'ticketAverage',
        title: 'Promedio diario',
        render: function (data, type, row) {
          return data.toFixed(2);
        },
        width: '324px',
      },
    ];

    this.dataSummarized = new commonTableData();
    this.dataSummarized.showEditButton = false;
    this.dataSummarized.showDeleteButton = false;
    this.dataSummarized.showCreateButton = false;
    this.dataSummarized.searching = false;
    this.dataSummarized.paging = false;
    this.dataSummarized.loadFirstTime = false;
    this.dataSummarized.showAll = true;

    this.dataSummarized.columns = [
      {
        data: 'uidType',
        title: 'Tipo UID',
        width: this.dataDaily.columns[0].width,
      },
      {
        data: 'daysQuantity',
        title: 'Días',
        width: this.dataDaily.columns[1].width,
      },
      {
        data: 'daysQuantity',
        title: ' ',
        render: function (data, type, row) {
          return ' ';
        },
        width: this.dataDaily.columns[2].width,
      },
      {
        data: 'ticketsQuantity',
        title: 'Tickets Impresos',
        width: this.dataDaily.columns[3].width,
      },
      {
        data: 'ticketAverage',
        title: 'Promedio diario',
        render: function (data, type, row) {
          return data.toFixed(2);
        },
        width: this.dataDaily.columns[4].width,
      },
    ];
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
    });
  }

  updateTable(filters: MultiselectFilters) {
    this.setGridData(filters);
    // this.reloadTables();
  }

  reloadTables() {
    this.tableSummarized.reload();
    this.tableDaily.reload();
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.TicketService;

    this.dataDaily.getData = function (
      param: any
    ): Observable<ApiResult<DataTableResult<DailyTicketsAverageViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getDailyTicketsAverageReport(DataTableFilters);
    };
    this.dataSummarized.getData = function (
      param: any
    ): Observable<ApiResult<DataTableResult<SummarizedTicketsAverageViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getSummarizedTicketsAverageReport(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }
  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();

    var endDate = moment(this.formSearch.get('to').value).endOf('day');

    filters.to = endDate;
    filters.from = this.formSearch.get('from').value;

    return filters;
  }

  onSubmit() {
    this.searchMade = true;
    this.updateTable(this.getFilters());
    this.reloadTables();
  }
}
