<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row" *ngIf="loadpage">
      <div class="col">
        <form
          role="form"
          [formGroup]="formEdit"
          (ngSubmit)="onSubmit(formObject)"
          #formObject="ngForm"
          autocomplete="off">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                <span> {{ headerTitle }}</span>
              </h3>
            </div>

            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm">
                  <app-common-input
                    [control]="this.formEdit.get('quantity')"
                    [label]="'Cantidad de tickets'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6">
                  <app-common-input
                    [control]="this.formEdit.get('ticketValue')"
                    [label]="'Valor del ticket'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric"
                    #ticketValue>
                  </app-common-input>
                </div>
              </div>
            </div>

            <div class="card-footer text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!hideCashable">Imprimir</button>
              <button type="button" class="btn btn-secondary ml-2" (click)="GoToCashFlow()">Volver</button>
            </div>
          </div>
          <!-- /.card -->
        </form>
      </div>
    </div>
    <app-ticket-printer-status></app-ticket-printer-status>
  </div>
</div>
