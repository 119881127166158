<div class="content">
  <div class="container-fluid">
    <div class="row" *ngIf="!isReport">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              <span> {{ headerTitle }}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm" *ngIf="formSearch != null">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange != null"> - {{ dateRange }} </span>
              </h3>
              <div class="card-tools">
                <button
                  [class.activeButton]="refreshPage"
                  type="button"
                  class="btn btn-tool mr-2"
                  (click)="onRefresh()">
                  <i class="fas fa-redo"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [controlFrom]="formSearch.get('from')"
                    [controlTo]="formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm-4">
                  <egm-combo [multiselect]="true" [control]="formSearch.get('uid')"></egm-combo>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <span style="color: red" *ngIf="this.refreshPage">búsqueda automática activada</span>

                <button
                  [disabled]="this.refreshPage"
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info ml-2">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <app-common-table
                [model]="data"
                (onCustomButton)="onCustomButton($event)"
                [excel]="isReport"
                [title]="this.title"></app-common-table>
            </div>
            <div>
              <div class="card-footer text-right" *ngIf="!isReport">
                <button type="button" class="btn btn-primary ml-2" (click)="GoToManualPayment()">
                  Forzar Pago Manual S/Alerta
                </button>

                <button type="button" class="btn btn-secondary ml-2" (click)="GoToCashFlow()">Volver</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <app-ticket-printer-status *ngIf="!isReport"></app-ticket-printer-status>
  </div>
</div>
<app-confirm-modal yes="Continuar" (onConfirm)="onConfirm()" [body]="this.body"> </app-confirm-modal>

<app-authorization-modal
  yes="Continuar"
  (onConfirm)="onConfirm()"
  (onCancel)="submiting = false"
  [body]="authorizationBody">
</app-authorization-modal>
