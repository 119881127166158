import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  commonTableData,
  CommonTableComponent,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import {
  CancellationReasons,
  PreInserted,
  TicketFamily,
  TicketFamilyGroup,
  TicketState,
  TicketType,
} from 'src/app/common/components/viewmodels/Enums';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { TicketMock } from 'src/app/common/components/viewmodels/TicketMock';
import { TicketTracker } from 'src/app/common/components/viewmodels/TicketTracker';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-ticket-history',
  templateUrl: './ticket-history.component.html',
  styleUrls: ['./ticket-history.component.css'],
})
export class TicketHistoryComponent implements OnInit, AfterViewInit {
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<TicketTracker>;
  title: string = 'Historial de tickets';
  items: Array<BreadcrumbItem> = [];
  currencyCode: string;
  currencySym: string;
  public data: commonTableData<TicketTracker>;
  formSearch: FormGroup;
  paramStatus: boolean;
  lastSerch: string = '';
  public static getName() {
    return 'TicketHistoryComponent';
  }

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    public TicketService: TicketService,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'TITO'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Historial'));
    this.initForm();

    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    const that = this;

    this.data.columns = [
      // { data: 'ticket.validatorCode', title: 'Código' },
      {
        data: 'ticket.formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'ticket.creationDate',
        title: 'Fecha Ticket',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'ticket.expirationDate',
        title: 'Fecha Vencimiento',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },

      {
        data: 'eventDate',
        title: 'Fecha Evento',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'state',
        title: 'Estado',
        render: function (data: number, type, row) {
          return data != null ? TicketState[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'slotResponseMessage',
        title: 'Respuesta Slot',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'hostResponseMessage',
        title: 'Respuesta Host',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'cancellationReason',
        title: 'Motivo Cancelación',
        render: function (data: number, type, row) {
          return data != null ? CancellationReasons[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'type',
        title: 'Tipo',
        render: function (data: number, type, row) {
          return data != null ? TicketType[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'ticket.family.familyGroupId',
        title: 'Familia',
        render: function (data: number, type, row) {
          return data != null ? TicketFamilyGroup[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'eventEgm.uid',
        title: 'EGM',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'cashRegisterName',
        title: 'Caja',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      // {
      //   data: 'eventCashMachine.muid',
      //   title: 'MC',
      //   render: function (data, type, row) {
      //     return data != null ? data : '-';
      //   },
      // },
      {
        data: 'userIdName',
        title: 'Usuario',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'ticket.ticketReplacementCodeValidator',
        title: 'Reemplazado Por',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'preInserted',
        title: 'Pre-insertado',
        render: function (data: number, type, row) {
          return data != null ? PreInserted[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'preInsertedError',
        title: 'Error Pre-insertado',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      { data: 'ticket.ticketNumber', title: 'Nro. Ticket' },
      {
        data: 'systemDate',
        title: 'Fecha Sistema',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'ticket.creationDate',
        title: 'Fecha de Emisión',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
    ];

    if (this.activatedRoute.snapshot.params.code != null) {
      this.formSearch.get('ticketCode').setValue(this.activatedRoute.snapshot.params.code);
      // this.onSubmit();
      this.paramStatus = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.paramStatus) {
      this.onSubmit();
    }
  }

  setGridData(validationCode: string) {
    let service = this.TicketService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<TicketTracker>>> {
      var filters = new MinimalFilters();
      filters.validatorCode = validationCode;
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getTicketHistory(DataTableFilters);
    };
  }

  initForm() {
    this.formSearch = new FormGroup({
      ticketCode: new FormControl(null, [Validators.pattern('[0-9]*'), Validators.maxLength(18), Validators.required]),
    });
  }

  updateTable() {
    this.setGridData(this.formSearch.get('ticketCode').value);
    this.table.reload();
  }

  onSubmit() {
    if (this.formSearch.valid) {
      this.lastSerch = this.formSearch.get('ticketCode').value;
      this.updateTable();
    }
  }

  async onFullInput(codeTikcet) {
    if (this.formSearch.get('ticketCode').value != null) {
      var text = this.formSearch.get('ticketCode').value;
      if (text.length == 18) {
        this.onSubmit();
      }
    }
  }
}
