import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { commonTableData, tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-egm-report',
  templateUrl: './egm-report.component.html',
  styleUrls: ['./egm-report.component.css'],
})
export class EgmReportComponent implements OnInit {
  public data: commonTableData<EGMMock>;
  items: Array<BreadcrumbItem> = [];
  body: string;
  egm: EGM = new EGM();
  parameter: tableStatus = null;
  title: string = 'EGMs';

  public static getName() {
    return 'EgmReportComponent';
  }
  constructor(
    public egmService: EgmServiceService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));

    let service = this.egmService;

    this.data = new commonTableData();
    let paramEGM = this.paramService.getAndDeleteParameters();

    if (paramEGM != null) {
      this.data.searchText = paramEGM.searchString;
      this.data.pageLength = paramEGM.pageLength;
      this.data.currentPage = paramEGM.currentPage;
    }
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.data.showAll = true;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<EGMMock>>> {
      return service.getAllEGMs(param);
    };

    this.data.columns = [
      { data: 'uid', title: 'EGM' },
      { data: 'manufacturer.name', title: 'Fabricante' },
      { data: 'model.name', title: 'Modelo' },

      {
        data: 'gameType.name',
        title: 'Juego',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'alias',
        title: 'Alias',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'date',
        title: 'Fecha Alta',
        render: function (data, type, row: EGMMock) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : `-`;
        },
      },
      {
        data: 'eliminationDate',
        title: 'Fecha Baja',
        render: function (data, type, row: EGMMock) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : `-`;
        },
      },
      // {
      //   data: 'spider.code', title: 'Codigo Spider+',
      //   "render": function (data, type, row) {
      //     return data != null ? data : '-';
      //   }
      // },

      {
        data: 'spider.ip',
        title: 'IP',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'spider.mac',
        title: 'MAC',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'spider.model',
        title: 'Modelo Spider',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'observations',
        title: 'Observaciones',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
    ];
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }
}
