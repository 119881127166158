<label [class.disabled]="ngControl?.disabled" *ngIf="ShowLabelUp()">{{ label }}</label>
<ng-select
  #mySelect
  [items]="list"
  multiple="false"
  [(ngModel)]="value"
  class="common-input-select"
  [placeholder]="placeHolder"
  (change)="emitChange($event)"
  [clearable]="allowClear"
  bindValue="id"
  bindLabel="text"
  [multiple]="false"
  (blur)="onTouched($event)">
</ng-select>

<!-- es submiteado
{{ formSubmitted }}
es ngControl? es null?
{{ ngControl }}
.es ngControl?.errors?.required es required?
{{ ngControl?.errors?.required }} -->

<div
  *ngIf="(ngControl?.dirty || ngControl?.touched || formSubmitted) && ngControl?.errors?.required"
  class="text-danger">
  {{ label }} es un campo obligatorio.
</div>

<div *ngIf="help !== ''" class="form-text">{{ help }}</div>
