import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { filter } from 'jszip';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { AccessLog } from 'src/app/common/components/viewmodels/AccessLog';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { OperationType } from 'src/app/common/components/viewmodels/Enums';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { LogsService } from 'src/app/services/logs/logs.service';

@Component({
  selector: 'app-access-log',
  templateUrl: './access-log.component.html',
  styleUrls: ['./access-log.component.css'],
})
export class AccessLogComponent implements OnInit {
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<AccessLog>;

  title: string = 'Log Accesos';

  items: Array<BreadcrumbItem> = [];

  public data: commonTableData<AccessLog>;

  currencyCode: string;
  currencySymbol: string;

  formSearch: FormGroup;
  dateRange: string;

  public static getName() {
    return 'AccessLogComponent';
  }
  constructor(
    private router: Router,
    private LogService: LogsService // private CountersService: CountersService, // private CasinoService: CasinoService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Seguridad'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();
    // this.CasinoService.getData().subscribe((result) => {
    //   this.currencyCode = result.data.currencyCode;
    //   this.currencySymbol = result.data.currencySymbol;
    // });
    this.initGrid();
  }

  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    const that = this;

    this.data.columns = [
      {
        data: 'username',
        title: 'Usuario',
        render: function (data: string, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'formattedDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'ip',
        title: 'IP Acceso',
        render: function (data: string, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'action',
        title: 'Acción',
        render: function (data: string, type, row) {
          return data != null ? OperationType[data] : '-';
        },
      },
    ];
  }

  updateTable(filters: MinimalFilters) {
    this.setGridData(filters);
    this.table.reload();
  }

  setGridData(filters: MinimalFilters): void {
    let service = this.LogService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<AccessLog>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getAccessLogs(DataTableFilters);
    };

    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }

  initForm() {
    this.formSearch = new FormGroup({
      user: new FormControl(),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  getFilters() {
    var filters: MinimalFilters = new MinimalFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    filters.user = this.formSearch.get('user').value;
    return filters;
  }

  onSubmit() {
    this.updateTable(this.getFilters());
  }

  get formSearchNull(): boolean {
    return this.formSearch.get('user').value.length == null;
  }
}
