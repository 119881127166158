import { ValidationStyles } from './Enums';

export class EGM {
  id: number;
  uid: number;
  uidFormatted: string;
  manufacturer: string; //Lista
  model: string; //Lista
  gameType: string; //Lista
  theoricalPaymentPercentage: number;
  serial: string;
  company: string;
  roomId: number;
  location: number;
  SMIB: number;
  acceptsCoins: boolean;
  currency: string; //lista
  denomination: number;
  SASversion: string;
  status: boolean;
  advancedConfig: AdvancedConfig;
  counters: Counters;
  date: string;
  subperiod: number;
  nickName: string;
  observations: string;

  constructor() {
    this.advancedConfig = new AdvancedConfig(true);
    this.counters = new Counters();
  }
}

export class FirmwareUpdateProcessViewModel {
  id?: number;

  isUpdating: boolean;
  canCancel: boolean;

  fileName: string;
  userUpdate: string;
  formattedUpdateQueued: moment.Moment;
  formattedUpdateUnqueued: moment.Moment;
  formattedUpdateRecivied: moment.Moment;
  formattedUpdatePostedOk: moment.Moment;
  formattedUpdatePostedError: moment.Moment;
  formattedUpdateNotPost: moment.Moment;
  formattedUpdateFinishOk: moment.Moment;
  formattedUpdateFinishNotOk: moment.Moment;
  formattedUpdateUpdateCancel: moment.Moment;
  currentProgress: string;

  isFinalizedOk: boolean;
  isFinalizedError: boolean;
  postError: string;
}

export class FirmwareListProcessViewModel {
  isCurrentUpdating: boolean;
  egmUid: number;
  spiderId: number = 0;
  currentFirmware: string = '';
  currentExtendedVersion: string = '';
  spiderMac: string = '';
  spiderModel: string = '';
  firmwareUpdateProcessViewModels: FirmwareUpdateProcessViewModel[];
}

export class Firmwarefile {
  fileData: File;
  fileName: string = '';
}

export class AdvancedConfig {
  //Editables

  egmId: number;
  ticketIn: boolean;
  ticketOut: boolean;
  promotionalTickets: boolean;
  AFTin: boolean;
  AFTout: boolean;
  combinationProfit: number;
  acceptsCoins: boolean;
  acceptsHopper: boolean;

  //No editables

  readonly printerCashoutDevice: boolean;
  readonly printerHandpayReceiptDevice: boolean;
  readonly printerRestrictedTicket: boolean;
  readonly jackpot: boolean;
  readonly AFT: boolean;
  readonly AFTBonusAwards: boolean;
  readonly tournament: boolean;
  readonly validationStyle: ValidationStyles;
  readonly ticketRedemption: boolean;
  readonly extendedCounters: boolean;
  readonly cashoutLimit: boolean;

  constructor(
    ticketRedemption?: boolean,
    AFT?: boolean,
    validationStyle: ValidationStyles = ValidationStyles.Enhanced,
    printerCashoutDevice?: boolean,
    printerHandpayReceiptDevice?: boolean,
    printerRestrictedTicket?: boolean,
    jackpot?: boolean,
    AFTBonusAwards?: boolean,
    tournament?: boolean,
    extendedCounters?: boolean,
    cashoutLimit?: boolean
  ) {
    this.printerCashoutDevice = printerCashoutDevice;
    this.printerHandpayReceiptDevice = printerHandpayReceiptDevice;
    this.printerRestrictedTicket = printerRestrictedTicket;
    this.jackpot = jackpot;
    this.AFT = AFT;
    this.AFTBonusAwards = AFTBonusAwards;
    if (!this.AFT) {
      this.AFTBonusAwards = false;
      this.AFTin = false;
      this.AFTout = false;
    }
    this.tournament = tournament;
    this.validationStyle = validationStyle;
    this.ticketRedemption = ticketRedemption;
    if (!this.ticketRedemption) {
      this.ticketIn = false;
      this.ticketOut = false;
      this.promotionalTickets = false;
    }
    this.extendedCounters = extendedCounters;
    this.cashoutLimit = cashoutLimit;
    this.acceptsCoins = false;
    this.acceptsHopper = false;
  }
}

export class Counters {
  isSubPeriod: boolean;
  date: string;
  status: boolean;

  //Revisar si esto va para una clase período

  coinIn: number;
  initialCoinIn: number;
  finalCoinIn: number;
  coinOut: number;
  initialCoinOut: number;
  finalCoinOut: number;
  coinDiff: number;
  totalIn: number;
  totalOut: number;
  totalDiff: number;
  jackpot: number;
  initialJackpot: number;
  finalJackpot: number;
  totalJackpot: number;
  handPay: number;
  ticketIn: number;
  ticketOut: number;
  ticketDiff: number;
  ticketProm: number;
  ticketPromIn: number;
  ticketPromOut: number;
  mppp: number;
  apcc: number;
  appp: number;
  mpbp: number;
  apj: number;
  apebp: number;
  bet: number;
  wonBet: number;
  payPercentage: string;
  theoPay: string; //Usar percent pipe en html
  devPay: string; //Usar percent pipe en html
  retHold: string; //Usar percent pipe en html
  bills: number;
  trueCoinIn: number;
  trueCoinOut: number;
  coins: number;
  coinDrop: number;
  eftIn: number;
  watIn: number;
  watOut: number;
  hopper: number;
  gamesPlayed: number;
  gamesWon: number;
  aftIn: number;
  aftOut: number;
  jackpotQ: number;
  doorQ: number;
  billM1Q: number;
  billM2Q: number;
  billM3Q: number;
  billM4Q: number;
  billM5Q: number;
  billM6Q: number;
  billM7Q: number;
  billM8Q: number;
  billTotalQ: number;
  billTotalMoney: number;
  profit: number;
  benefit: number;
  betAvg: number;
  dailyAvg: number;

  constructor() {
    this.isSubPeriod = false;
  }
}
