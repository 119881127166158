<!-- Navbar -->
<nav class="main-header navbar navbar-custom navbar-expand customnvar sticky-top">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <!-- <a role="button" class="nav-link" (click)="toggle()" [routerLink]="" ><em class="fa fa-bars"></em></a> -->

      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
    <li
      class="nav-item d-none d-sm-inline-block"
      [routerLinkActive]="['link-active']"
      [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="establishment/about">{{ facilityName | async }}</a>
    </li>
    <li
      class="nav-item d-none d-sm-inline-block"
      [routerLinkActive]="['link-active']"
      [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/dashboard/visualize">Monitoreo</a>
    </li>
  </ul>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <div
        class="nav-link"
        role="button"
        style="font-weight: bold"
        *ngIf="tenantList.length > 0 && tenantList[0].id != '0'">
        Establecimiento:
      </div>
    </li>

    <li class="nav-item">
      <div style="min-width: 160px"></div>
      <common-input-select
        *ngIf="tenantList.length > 0 && tenantList[0].id != '0'"
        class=""
        (valueChanged)="changeTenant()"
        [list]="tenantList"
        [formControl]="formSearch.get('tenantId')">
      </common-input-select>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="glossary" role="button"> <i class="fas fa-info-circle mr-2"></i>Glosario </a>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="" aria-expanded="false">
        <i class="far fa-user mr-2"></i>{{ userName | async }}
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <div class="dropdown-divider"></div>
        <div class="dropdown-divider"></div>
        <a [href]="logoutUrl" class="dropdown-item"> <i class="fas fa-sign-out-alt mr-2"></i> Cerrar sesión </a>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="toggleFullscreen()" [routerLink]="" role="button">
        <i *ngIf="!this.isFullScreen" class="fas fa-expand-arrows-alt"></i>
        <i *ngIf="this.isFullScreen" class="fas fa-compress-arrows-alt"></i>
      </a>
    </li>
  </ul>
</nav>
<!-- /.navbar -->
