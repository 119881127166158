<common-header title="{{ title }}" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="card">
          <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
            <div class="card-body">
              <div class="form-row">
                <validation-code
                  [control]="this.formSearch.get('ticketCode')"
                  [cleanAfterCompleted]="true"
                  (inputFull)="onFullInput($event)"
                  [formSubmitted]="formObject.submitted"
                  [focus]="true"
                  class="w-100">
                </validation-code>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="text-info font-size-14 col-12 col-sm-6" style="font-size: 14px">
                  <span *ngIf="lastSerch !== ''">*Último buscado: {{ lastSerch }}</span>
                </div>
                <div class="d-flex justify-content-end col-12 col-sm-6">
                  <button type="submit" class="btn btn-info" [disabled]="!sessionStatus">Buscar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /.row -->
    <div class="row" *ngIf="cardRead">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  [label]="'Fecha de emisión'"
                  [model]="moment(ticket.creationDate).format('DD/MM/yyyy, HH:mm:ss')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  [label]="'Monto'"
                  [model]="currencyPipe.transform(ticket.formattedAmount, currencyCode, currencySym)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display [label]="'Estado'" [model]="TicketState[ticket.state]"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display [label]="'Número de ticket'" [model]="ticket.ticketNumber"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticket.creationEgm !== null">
                <app-info-display [label]="'EGM'" [model]="ticket.creationEgm.uid"></app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticket.creationTurn !== null">
                <app-info-display [label]="'Caja'" [model]="ticket.creationTurn.session.cashRegister.cuid">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticket.creationTurn !== null">
                <app-info-display [label]="'Usuario'" [model]="ticket.creationTurn.session.UserIdName">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  [label]="'Fecha de expiración'"
                  [model]="moment(ticket.expirationDate).format('DD/MM/yyyy, HH:mm:ss')">
                </app-info-display>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button type="submit" class="btn btn-primary" (click)="cancelTicket()">Cancelar ticket</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>

<app-confirm-modal
  #invalidModal
  [okOnly]="true"
  yes="Entendido"
  (onConfirm)="formSearch.get('ticketCode').enable()"
  [body]="body"></app-confirm-modal>
