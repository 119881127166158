import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters, DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import {
  CancellationReasons,
  GenerationReasons,
  TicketIssuedBy,
  TicketFamily,
  TicketState,
  TicketType,
  TicketIssedMachine,
  cancellationReasonMap,
  AuthorizationOperationType,
} from 'src/app/common/components/viewmodels/Enums';
import { AverageReport, PublicAccountingPower } from 'src/app/common/components/viewmodels/secondaryClasses';
import { Ticket } from 'src/app/common/components/viewmodels/Ticket';
import { TicketMock } from 'src/app/common/components/viewmodels/TicketMock';
import { TicketTracker } from 'src/app/common/components/viewmodels/TicketTracker';
import { TicketsSaleViewModel } from 'src/app/common/components/viewmodels/TicketsSaleViewModel';
import { baseService } from '../base/base.service';
import { PromotionalTicketMock } from 'src/app/common/components/viewmodels/PromotionalTicketMock';
import {
  TicketCancelViewModel,
  TicketReplacementViewModel,
  TicketViewModel,
} from 'src/app/common/components/viewmodels/TicketReplacementViewModel';
import { SummarizedTicket } from 'src/app/common/components/viewmodels/SummarizedTicket';
import { Handpay } from 'src/app/common/components/viewmodels/HandpayPending';
import { DailyTicketsAverageViewModel } from 'src/app/common/components/viewmodels/DailyTicketsAverageViewModel';
import { SummarizedTicketsAverageViewModel } from 'src/app/common/components/viewmodels/SummarizedTicketsAverageViewModel';
import { PublicHoldingViewModel } from 'src/app/common/components/viewmodels/PublicHoldingViewModel';
import { PublicHolding } from 'src/app/common/components/viewmodels/PublicHolding';
import { Router } from '@angular/router';
import { PrintableTickets } from 'src/app/common/components/viewmodels/PrintableTickets';
import { AuthorizationMock } from 'src/app/common/components/viewmodels/AuthorizationMock';

@Injectable({
  providedIn: 'root',
})
export class TicketService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public pocessHoldingPublic(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Tickets/PocessHoldingPublic`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public checkVto(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Tickets/CheckVto`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getGenerationReasonCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Tickets/GetTicketGenerationReasonCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getTicketsWithError(dataTablesParameters: any): Observable<ApiResult<DataTableResult<Ticket>>> {
    var listTicket: Ticket[] = [];
    for (let i = 0; i < 1; i++) {
      var objRet0: Ticket = new Ticket();
      objRet0.id = 777;
      objRet0.uid = 3510;
      objRet0.amount = 45.4;
      objRet0.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.family = TicketFamily.Cash;
      objRet0.type = TicketType.OUT;
      objRet0.status = 'Expirado';
      objRet0.code = 4568785432;
      objRet0.userIdName = 'EGM';
      objRet0.isValid = false;
      objRet0.error = 'C150';

      listTicket.push(objRet0);

      var objRet2: Ticket = new Ticket();
      objRet2.id = 780;
      objRet2.uid = 3510;
      objRet2.amount = 10.5;
      objRet2.creationDate = moment(new Date()).add(20, 'minutes').format('DD/MM/yyyy, kk: mm: ss');
      objRet2.validationDate = moment(new Date()).add(20, 'minutes').format('DD/MM/yyyy, kk: mm: ss');
      objRet2.expirationDate = moment(new Date()).add(20, 'minutes').format('DD/MM / yyyy, kk: mm: ss');
      objRet2.family = TicketFamily.Restricted_Promotional;
      objRet2.type = TicketType.OUT;
      objRet2.status = 'Anterior';
      objRet2.code = 4568785434;
      objRet2.userIdName = 'Carlos Gonzalez';
      objRet2.isValid = false;
      objRet2.error = 'C150';

      listTicket.push(objRet2);
    }

    var ret = new ApiResult<DataTableResult<Ticket>>();
    ret.data.dataResults = listTicket;
    ret.data.recordsTotal = 8;
    ret.data.recordsFiltered = 8;

    return new Observable((o) => {
      o.next(ret);
    });
  }

  public getAuthorizedtickets(dataTablesParameters: any): Observable<ApiResult<DataTableResult<Ticket>>> {
    var listTicket: Ticket[] = [];
    for (let i = 0; i < 1; i++) {
      var objRet0: Ticket = new Ticket();
      objRet0.id = 1115556;
      objRet0.uid = 1003;
      objRet0.cashMachine.cuid = 150;
      objRet0.amount = 80000;
      objRet0.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.family = TicketFamily.Handpay;
      objRet0.status = 'No impreso';
      objRet0.code = 4568785432;
      objRet0.userIdName = 'Jorge Benitez';
      objRet0.isValid = false;
      objRet0.error = 'C150';

      listTicket.push(objRet0);

      var objRet2: Ticket = new Ticket();
      objRet2.id = 1115580;
      objRet2.uid = 1002;
      objRet2.cashMachine.cuid = 151;
      objRet2.amount = 75000;
      objRet2.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet2.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet2.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet2.family = TicketFamily.Jackpot;
      objRet2.status = 'No impreso';
      objRet2.code = 4568785432;
      objRet2.userIdName = 'Jorge Benitez';
      objRet2.isValid = false;
      objRet2.error = 'C150';

      listTicket.push(objRet2);

      var objRet: Ticket = new Ticket();
      objRet.id = 1115590;
      objRet.uid = 1001;
      objRet.cashMachine.cuid = 152;
      objRet.amount = 8.9;
      objRet.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet.family = TicketFamily.Cash;
      objRet.status = 'No impreso';
      objRet.code = 4568785432;
      objRet.userIdName = 'Jorge Benitez';
      objRet.isValid = false;
      objRet.error = 'C150';

      listTicket.push(objRet);
    }

    var ret = new ApiResult<DataTableResult<Ticket>>();
    ret.data.dataResults = listTicket;
    ret.data.recordsTotal = 8;
    ret.data.recordsFiltered = 8;

    return new Observable((o) => {
      o.next(ret);
    });
  }

  public getTicketTrackers(param: DatatableMinimalFilters): Observable<ApiResult<DataTableResult<TicketTracker>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketTracker>>>(
        `${this.baseUrl}Tickets/GetTicketTrackersWithMinimalFilters`,
        param
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketTracker>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketTracker>>(error))
      );
  }

  public getTicketsTrackersGeneralReport(
    param: DatatableMinimalFilters
  ): Observable<ApiResult<DataTableResult<TicketTracker>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketTracker>>>(`${this.baseUrl}Tickets/GetTicketTrackersGeneralReport`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketTracker>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketTracker>>(error))
      );
  }

  public getIssuedTicketsReport(
    filters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<TicketViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketViewModel>>>(`${this.baseUrl}Tickets/GetIssuedTicketsReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketViewModel>>(error))
      );
  }
  public getAcceptedTicketReport(
    filters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<TicketViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketViewModel>>>(`${this.baseUrl}Tickets/GetAcceptedTicketsReport`, filters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketViewModel>>(error))
      );
  }

  public getTicketsPerUser(param: DatatableMultiFilters): Observable<ApiResult<DataTableResult<SummarizedTicket>>> {
    return this.http
      .post<ApiResult<DataTableResult<SummarizedTicket>>>(`${this.baseUrl}Tickets/GetTicketsPerUser`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<SummarizedTicket>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<SummarizedTicket>>(error))
      );
  }

  public getAliveTickets(
    dataTablesParameters: DataTableParameters
  ): Observable<ApiResult<DataTableResult<TicketMock>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketMock>>>(`${this.baseUrl}Tickets/GetAliveTickets`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketMock>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketMock>>(error))
      );
  }

  public getTicketsReportsWithTotals(dataTablesParameters: any): Observable<ApiResult<DataTableResult<Ticket>>> {
    var listTicket: Ticket[] = [];
    for (let i = 0; i < 1; i++) {
      var total: number = 0;
      var totalTickets: number = 0;

      var objRet0: Ticket = new Ticket();
      objRet0.id = 1115556;
      objRet0.uid = 1003;
      objRet0.cashMachine.cuid = 150;
      objRet0.amount = 80000;
      objRet0.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet0.family = TicketFamily.Handpay;
      objRet0.status = 'Pagado';
      objRet0.code = 4568785432;
      objRet0.userIdName = 'Jorge Benitez';
      objRet0.isValid = false;
      objRet0.error = 'C150';
      objRet0.quantity = 15;
      objRet0.cashMachine.name = 'C1000';
      totalTickets += objRet0.quantity;
      total += objRet0.amount;

      listTicket.push(objRet0);

      var objRet2: Ticket = new Ticket();
      objRet2.id = 1115580;
      objRet2.uid = 1002;
      objRet2.cashMachine.cuid = 151;
      objRet2.amount = 75000;
      objRet2.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet2.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet2.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet2.family = TicketFamily.Jackpot;
      objRet2.status = 'Anulado';
      objRet2.code = 4568785432;
      objRet2.userIdName = 'Jorge Benitez';
      objRet2.isValid = false;
      objRet2.quantity = 45;
      objRet2.error = 'C150';
      objRet2.cashMachine.name = 'C1001';
      totalTickets += objRet2.quantity;
      total += objRet2.amount;

      listTicket.push(objRet2);

      var objRet: Ticket = new Ticket();
      objRet.id = 1115590;
      objRet.uid = 1001;
      objRet.cashMachine.cuid = 152;
      objRet.amount = 35000;
      objRet.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet.family = TicketFamily.Cash;
      objRet.status = 'Aceptado';
      objRet.code = 4568785432;
      objRet.userIdName = 'Jorge Benitez';
      objRet.isValid = false;
      objRet.quantity = 30;
      objRet.error = 'C150';
      objRet.cashMachine.name = 'C1002';
      totalTickets += objRet.quantity;
      total += objRet.amount;

      listTicket.push(objRet);

      var objRet3: Ticket = new Ticket();
      objRet3.id = 1115595;
      objRet3.uid = 1000;
      objRet3.cashMachine.cuid = 153;
      objRet3.amount = 150000;
      objRet3.creationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet3.validationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet3.expirationDate = moment(new Date()).format('DD/MM/yyyy, HH:mm:ss');
      objRet3.family = TicketFamily.Restricted_Promotional;
      objRet3.status = 'No impreso';
      objRet3.code = 4568785432;
      objRet3.userIdName = 'Jorge Benitez';
      objRet3.isValid = false;
      objRet3.quantity = 70;
      objRet3.error = 'C150';
      objRet3.cashMachine.name = 'C1003';
      totalTickets += objRet3.quantity;
      total += objRet3.amount;

      listTicket.push(objRet3);

      var totals: Ticket = new Ticket();
      totals.quantity = totalTickets;
      totals.validationDate = '';
      totals.id = 0;
      totals.amount = total;
      totals.uid = 0;

      listTicket.push(totals);
    }

    var ret = new ApiResult<DataTableResult<Ticket>>();
    ret.data.dataResults = listTicket;
    ret.data.recordsTotal = 8;
    ret.data.recordsFiltered = 8;

    return new Observable((o) => {
      o.next(ret);
    });
  }

  public getTicketFamilyCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Tickets/GetTicketFamilyCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getTicketType(arr: Array<IdTextModel>): Array<IdTextModel> {
    arr = new Array<IdTextModel>();

    // arr.push(new IdTextModel(1, 'IN'));
    // arr.push(new IdTextModel(2, 'OUT'));

    var i: number = 1;
    for (let item in TicketType) {
      if (isNaN(Number(item))) {
        arr.push(new IdTextModel(i, item));
        i++;
      }
    }

    return arr;
  }

  public getTicketState(arr: Array<IdTextModel>): Array<IdTextModel> {
    arr = new Array<IdTextModel>();
    // arr.push(new IdTextModel(1, 'No impreso'));
    // arr.push(new IdTextModel(2, 'Impreso'));
    // arr.push(new IdTextModel(3, 'Pagado'));
    // arr.push(new IdTextModel(4, 'Aceptado'));

    var i: number = 1;
    for (let item in TicketState) {
      if (isNaN(Number(item))) {
        arr.push(new IdTextModel(i, item));
        i++;
      }
    }

    return arr;
  }
  public getCancellationReason(arr: Array<IdTextModel>): Array<IdTextModel> {
    arr = new Array<IdTextModel>();

    for (let item in CancellationReasons) {
      if (!isNaN(Number(item))) {
        // Convertir el item a número y luego buscar en el mapa
        const enumValue = Number(item);
        const mappedValue = cancellationReasonMap[enumValue];
        if (mappedValue) {
          arr.push(new IdTextModel(enumValue, mappedValue));
        }
      }
    }

    return arr;
  }

  public generate(tickets: TicketMock): Observable<ApiResult<PrintableTickets>> {
    return this.http.post<ApiResult<PrintableTickets>>(`${this.baseUrl}Tickets/GenerateTicket`, tickets).pipe(
      map((x) => this.checkResult<PrintableTickets>(x)),
      catchError((error) => this.handleError<PrintableTickets>(error))
    );
  }

  public generateCashTicket(ticket: TicketsSaleViewModel): Observable<ApiResult<PrintableTickets>> {
    return this.http.post<ApiResult<PrintableTickets>>(`${this.baseUrl}Tickets/GenerateCashTicket`, ticket).pipe(
      map((x) => this.checkResult<PrintableTickets>(x)),
      catchError((error) => this.handleError<PrintableTickets>(error))
    );
  }

  public getTicketHistory(
    dataTablesParameters: DatatableMinimalFilters
  ): Observable<ApiResult<DataTableResult<TicketTracker>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketTracker>>>(`${this.baseUrl}Tickets/GetTicketHistory`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketTracker>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketTracker>>(error))
      );
  }

  public getExpiredTickets(
    dataTablesParameters: DatatableMinimalFilters
  ): Observable<ApiResult<DataTableResult<TicketMock>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketMock>>>(`${this.baseUrl}Tickets/GetExpiredTickets`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketMock>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketMock>>(error))
      );
  }

  public getCashedTickets(
    dataTablesParameters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<TicketTracker>>> {
    return this.http
      .post<ApiResult<DataTableResult<TicketTracker>>>(`${this.baseUrl}Tickets/GetCashedTickets`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<TicketTracker>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<TicketTracker>>(error))
      );
  }

  public getLastTicketTracker(validatorCode: string): Observable<ApiResult<TicketTracker>> {
    return this.http
      .get<ApiResult<TicketTracker>>(`${this.baseUrl}Tickets/GetLastTicketTracker?validatorCode=${validatorCode}`)
      .pipe(
        map((x) => this.checkResult<TicketTracker>(x)),
        catchError((error) => this.handleError<TicketTracker>(error))
      );
  }

  public getTicketByValidatorCode(validatorCode: string): Observable<ApiResult<TicketMock>> {
    return this.http
      .get<ApiResult<TicketMock>>(`${this.baseUrl}Tickets/GetTicketByValidatorCode?validatorCode=${validatorCode}`)
      .pipe();
  }

  public payTickets(tickets: TicketMock[], turnId: number): Observable<ApiResult<string>> {
    var payTicketData = {
      tickets: tickets,
      turnId: turnId,
    };

    return this.http.post<ApiResult<string>>(`${this.baseUrl}Tickets/PayTickets`, payTicketData).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public generatePromotionalTicket(tickets: TicketsSaleViewModel): Observable<ApiResult<PrintableTickets>> {
    return this.http
      .post<ApiResult<PrintableTickets>>(`${this.baseUrl}Tickets/GeneratePromotionalTicket`, tickets)
      .pipe(
        map((x) => this.checkResult<PrintableTickets>(x)),
        catchError((error) => this.handleError<PrintableTickets>(error))
      );
  }

  public cancelTicket(code: string): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Tickets/CancelTicket?code=${code}`).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public enablePromotionalTicket(code: string): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Tickets/EnablePromotionalTicket?code=${code}`).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getPromoTicketByValidatorCode(validatorCode: string): Observable<ApiResult<PromotionalTicketMock>> {
    return this.http
      .get<ApiResult<PromotionalTicketMock>>(
        `${this.baseUrl}Tickets/GetPromoTicketByValidatorCode?validatorCode=${validatorCode}`
      )
      .pipe
      // map(x => this.checkResult<PromotionalTicketMock>(x)),
      // catchError(error => this.handleError<PromotionalTicketMock>(error))
      ();
  }

  public cancelTicketBySupervisor(replacementViewModel: TicketCancelViewModel): Observable<ApiResult<string>> {
    return this.http
      .post<ApiResult<string>>(`${this.baseUrl}Tickets/CancelTicketBySupervisor`, replacementViewModel)
      .pipe(
        map((x) => this.checkResult<string>(x)),
        catchError((error) => this.handleError<string>(error))
      );
  }

  public replacementTicket(replacementViewModel: TicketReplacementViewModel): Observable<ApiResult<PrintableTickets>> {
    return this.http
      .post<ApiResult<PrintableTickets>>(`${this.baseUrl}Tickets/ReplacementTicket`, replacementViewModel)
      .pipe(
        map((x) => this.checkResult<PrintableTickets>(x)),
        catchError((error) => this.handleError<PrintableTickets>(error))
      );
  }

  public cancelHandpay(handpay: Handpay): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Tickets/CancelHandpay`, handpay).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public printHandpayVoucher(handpay: Handpay): Observable<ApiResult<PrintableTickets>> {
    return this.http.post<ApiResult<PrintableTickets>>(`${this.baseUrl}Tickets/PrintHandpayVoucher`, handpay).pipe(
      map((x) => this.checkResult<PrintableTickets>(x)),
      catchError((error) => this.handleError<PrintableTickets>(error))
    );
  }

  public getHandpayPendingsDatatable(param: DatatableMinimalFilters): Observable<ApiResult<DataTableResult<Handpay>>> {
    return this.http
      .post<ApiResult<DataTableResult<Handpay>>>(`${this.baseUrl}Tickets/GetHandpayPendingsDatatable`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Handpay>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Handpay>>(error))
      );
  }

  public getTicketIssuedBy(arr: Array<IdTextModel>): Array<IdTextModel> {
    arr = new Array<IdTextModel>();

    var i: number = 1;
    for (let item in TicketIssuedBy) {
      if (isNaN(Number(item))) {
        arr.push(new IdTextModel(i, item));
        i++;
      }
    }

    return arr;
  }

  public getTicketIssuedMachineBy(): Array<IdTextModel> {
    var arr = new Array<IdTextModel>();
    var re = new RegExp('_', 'g');

    var i: number = 1;
    for (let item in TicketIssedMachine) {
      if (isNaN(Number(item))) {
        arr.push(new IdTextModel(i, item.replace(re, ' ')));
        i++;
      }
    }

    return arr;
  }

  public getAverageTicketsReport(
    param: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<SummarizedTicket>>> {
    return this.http
      .post<ApiResult<DataTableResult<SummarizedTicket>>>(`${this.baseUrl}Tickets/GetTicketsPerUser`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<SummarizedTicket>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<SummarizedTicket>>(error))
      );
  }
  public getDailyTicketsAverageReport(
    dataTablesParameters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<DailyTicketsAverageViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<DailyTicketsAverageViewModel>>>(
        `${this.baseUrl}Tickets/GetDailyTicketsAverageReport`,
        dataTablesParameters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<DailyTicketsAverageViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<DailyTicketsAverageViewModel>>(error))
      );
  }
  public getSummarizedTicketsAverageReport(
    dataTablesParameters: DatatableMultiFilters
  ): Observable<ApiResult<DataTableResult<SummarizedTicketsAverageViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<SummarizedTicketsAverageViewModel>>>(
        `${this.baseUrl}Tickets/GetSummarizedTicketsAverageReport`,
        dataTablesParameters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<SummarizedTicketsAverageViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<SummarizedTicketsAverageViewModel>>(error))
      );
  }

  public getPublicHoldingReport(
    param: DatatableMinimalFilters
  ): Observable<ApiResult<DataTableResult<PublicHoldingViewModel>>> {
    return this.http
      .post<ApiResult<DataTableResult<PublicHoldingViewModel>>>(`${this.baseUrl}Tickets/GetPublicHoldingReport`, param)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<PublicHoldingViewModel>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<PublicHoldingViewModel>>(error))
      );
  }

  public getPublicHolding(date: moment.Moment): Observable<ApiResult<PublicHolding>> {
    return this.http.post<ApiResult<PublicHolding>>(`${this.baseUrl}Tickets/GetPublicHolding`, date).pipe(
      map((x) => this.checkResult<PublicHolding>(x)),
      catchError((error) => this.handleError<PublicHolding>(error))
    );
  }

  getPendingAuthorizations(
    dataTablesParameters: DataTableParameters
  ): Observable<ApiResult<DataTableResult<AuthorizationMock>>> {
    return this.http
      .post<ApiResult<DataTableResult<AuthorizationMock>>>(
        `${this.baseUrl}Tickets/GetAuthorizations`,
        dataTablesParameters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<AuthorizationMock>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<AuthorizationMock>>(error))
      );
  }

  public createAuthorization(type: AuthorizationOperationType, amount: number): Observable<ApiResult<number>> {
    var authorizationData = {
      operationType: type,
      amount: amount,
    };

    return this.http.post<ApiResult<number>>(`${this.baseUrl}Tickets/CreateAuthorization`, authorizationData).pipe(
      map((x) => this.checkResult<number>(x)),
      catchError((error) => this.handleError<number>(error))
    );
  }

  public getNonAuthorizedMaximum(type: AuthorizationOperationType): Observable<ApiResult<number>> {
    return this.http.post<ApiResult<number>>(`${this.baseUrl}Tickets/GetNonAuthorizedMaximum`, type).pipe(
      map((x) => this.checkResult<number>(x)),
      catchError((error) => this.handleError<number>(error, true))
    );
  }
}
