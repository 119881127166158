import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-rooms-report',
  templateUrl: './rooms-report.component.html',
  styleUrls: ['./rooms-report.component.css'],
})
export class RoomsReportComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];
  title: string = 'Reporte de Salas';

  public static getName() {
    return 'RoomsReportComponent';
  }
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }
}
