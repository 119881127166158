import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  // userName: string;
  currentTenant: string;

  constructor(private authorizeService: AuthorizeService, public router: Router, public route: ActivatedRoute) {
    // this.authorizeService.getUser().pipe(map(u => u && u.name)).subscribe(u => this.userName = u);
  }

  getCurrentTenantId(): string {
    return this.currentTenant;
  }

  setCurrentTenantId(tenantId: string) {
    this.currentTenant = tenantId;
  }

  getCurrentComponentName(): string {
    var currentRoute = this.router.url.split('?')[0];

    return this.findComponentByRoutes(this.router.url, this.router.config);
  }

  public findComponentNameByRoute(route: string) {
    return this.findComponentByRoutes(route, this.router.config);
  }

  public findComponentByRoutes(route: string, routes: Route[]): string {
    for (let index = 0; index < routes.length; index++) {
      const element = routes[index];
      if (this.removeSlashs(element?.path) == this.removeSlashs(route))
        if ((element.component as any)?.getName) {
          return (element.component as any)?.getName();
        } else {
          return null;
        }

      if (this.router.parseUrl(route)?.root?.children?.primary?.segments)
        if (
          this.defaultUrlMatcher(
            this.router.parseUrl(route).root.children.primary.segments,
            this.router.parseUrl(route).root,
            element
          )
        )
          if ((element.component as any)?.getName) {
            return (element.component as any)?.getName();
          } else {
            return null;
          }

      if (element.children != null) {
        var child = this.findComponentByRoutes(route, element.children);
        if (child != null) return child;
      }
    }

    return null;
  }

  public removeSlashs(text: string): string {
    if (!text) return text;

    while (text.charAt(0) === '/') {
      text = text.substring(1);
    }

    while (text.charAt(text.length - 1) == '/') {
      text = text.substring(0, text.length - 1);
    }

    return text;
  }

  public defaultUrlMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
    const path = route.path;
    const parts = path.split('/');
    const posParams: { [key: string]: UrlSegment } = {};
    const consumed: UrlSegment[] = [];

    let currentIndex = 0;

    for (let i = 0; i < parts.length; ++i) {
      if (currentIndex >= segments.length) return null;
      const current = segments[currentIndex];

      const p = parts[i];
      const isPosParam = p.startsWith(':');

      if (!isPosParam && p !== current.path) return null;
      if (isPosParam) {
        posParams[p.substring(1)] = current;
      }
      consumed.push(current);
      currentIndex++;
    }

    if (route.pathMatch === 'full' && (segmentGroup.hasChildren() || currentIndex < segments.length)) {
      return null;
    } else {
      return { consumed, posParams };
    }
  }
}
