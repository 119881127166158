import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';

import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import {
  AuthorizationOperationType,
  GenerationReasons,
  HandpayState,
  TicketFamily,
  TicketState,
} from 'src/app/common/components/viewmodels/Enums';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { Handpay } from 'src/app/common/components/viewmodels/HandpayPending';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { SessionManagmentViewModel } from '../viewmodels/SessioModel';
import { SessionService } from 'src/app/services/pos/pos.service';
import { TicketMock } from '../viewmodels/TicketMock';
import { EgmComboComponent } from '../../business/egm-combo/egm-combo.component';
import { AuthorizationModalComponent } from '../shared/authorization-modal/authorization-modal.component';
import { DataConsts } from '../viewmodels/Casino';
import { CommonDaterangepickerMaterialComponent } from '../shared/common-daterangepicker-material/common-daterangepicker-material.component';

@Component({
  selector: 'app-handpay-voucher-list',
  templateUrl: './handpay-voucher-list.component.html',
  styleUrls: ['./handpay-voucher-list.component.css'],
})
export class HandpayVoucherListComponent implements OnInit {
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Handpay>;
  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(AuthorizationModalComponent, { static: false }) authorizationModal: AuthorizationModalComponent;
  @ViewChild(CommonDaterangepickerMaterialComponent)
  commonDaterangepickerComponent: CommonDaterangepickerMaterialComponent;

  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @Input() isReport: boolean = true; // Es editar EGM
  @Input() idTurn: number = 0; // Es editar EGM

  currencySym: string;
  currencyCode: string;
  parameter: tableStatus = null;
  body: string;
  authorizationBody: string;
  isPrint: boolean = false;
  isCancel: boolean = false;
  isPrintTicketReported: boolean = false;
  handpay: Handpay;
  public data: commonTableData<Handpay>;
  footerIsLoaded: boolean = false;
  searchLive: boolean = false;
  refreshPage: boolean = false;
  formSearch: FormGroup;
  title: string = 'Handpay';
  headerTitle: string = '';
  dateRange: string;
  showCancel: boolean;
  showPrint: boolean;
  autoRefreshFunc: any = null;
  operationType: AuthorizationOperationType = AuthorizationOperationType.Pago_Manual_Ticket_No_Reportado;
  nonAuthorizedMaximum?: number;
  requiresAuthorization: boolean;
  authorizationId?: number;
  submiting = false;

  constructor(
    private router: Router,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    public ticketService: TicketService,
    public notificationService: NotificationService,
    public casinoService: CasinoService,
    public printerService: PrinterService,
    private paramService: ParametersService<tableStatus>,
    private sessionService: SessionService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.isReport) {
    } else {
      this.sessionService.GetSessionByTurnId(this.idTurn).subscribe((result) => {
        this.session = result.data;

        if (this.session === null) {
          this.router.navigate(['/session-management']);
          return;
        }
        this.headerTitle = this.sessionService.getPageTitle(this.session);
      });
    }
    await this.FillData();
  }

  async FillData() {
    this.showCancel =
      (await this.casinoService.isComponentAuthorized('HandpayVoucherComponent', 'Cancel').toPromise()).data &&
      !this.isReport;
    this.showPrint =
      (await this.casinoService.isComponentAuthorized('HandpayVoucherComponent', 'Print').toPromise()).data &&
      !this.isReport;

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.getNonAuthorizedMaximum();

    this.initForm();
    await this.initGrid();
  }

  async initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = true;

    if (!this.isReport) {
      let printButton: commonTableCustomButton = new commonTableCustomButton();
      printButton.icon = 'fa-print';
      printButton.columntitle = 'Imprimir';
      printButton.tooltip = 'Imprimir Voucher HP';
      printButton.order = 0;
      this.data.commonTableCustomButton.push(printButton);

      let cancelButton: commonTableCustomButton = new commonTableCustomButton();
      cancelButton.icon = 'fa-ban';
      cancelButton.columntitle = 'Cancelar';
      cancelButton.tooltip = 'Cancelar';
      cancelButton.order = 1;
      this.data.commonTableCustomButton.push(cancelButton);

      let printSinTO: commonTableCustomButton = new commonTableCustomButton();
      printSinTO.icon = 'fa-ticket-alt';
      printSinTO.columntitle = 'Ticket No Reportado';
      printSinTO.tooltip = 'Ticket No Reportado';
      printSinTO.order = 2;
      this.data.commonTableCustomButton.push(printSinTO);
    }

    let that = this;

    this.data.rowCallback = function (row: Node, data: Handpay | Object, index: number): void {
      //saco el boton imprimer de los que no van

      if (data['ticket'] != null) {
        $($('td', row)[7]).html(
          `<a target="_blank" href='${window.location.origin}/egms/tito/ticket-history/${data['ticket'].validatorCode}' style='text-decoration: underline;' data-toggle='tooltip' data-placement='right' title='' data-original-title='Ir a historial'>${data['ticket'].validatorCode}</a>`
        );
      }

      if (!this.isReport) {
        const shouldHideButton = !that.showPrint || data['state'] !== HandpayState.Handpay_Generado;

        if (shouldHideButton) {
          that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            $($('td a', row)[0]).addClass('hideVisivilityButton'); //Danger
          });
        }

        //solo puedo generar ticket cuando estoy a la espera de un ticket out (se usa en casos que no llegue)
        if (
          !that.showPrint ||
          data['state'] == HandpayState.Pendiente_Auto_Acreditacion ||
          data['state'] == HandpayState.Pendiente_Sin_Ticket_Out ||
          data['state'] == HandpayState.Handpay_Recibo ||
          data['state'] == HandpayState.Handpay_Generado ||
          data['state'] == HandpayState.Pagado ||
          data['state'] == HandpayState.Cancelado
        ) {
          that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            $($('td a', row)[2]).addClass('hideVisivilityButton');
            //saco el buton cancelar en todos menos donde esta el boton de ricket no reportado
            $($('td a', row)[1]).addClass('hideVisivilityButton');
          });
        }

        //saco el boton cancelar de los que no van
        // if (
        //   !that.showCancel ||
        //   data['state'] == HandpayState.Pendiente_Auto_Acreditacion ||
        //   data['state'] == HandpayState.Pendiente_Ticket_Out ||
        //   data['state'] == HandpayState.Pendiente_Sin_Ticket_Out ||
        //   data['state'] == HandpayState.Handpay_Recibo ||
        //   data['state'] == HandpayState.Handpay_Generado ||
        //   data['state'] == HandpayState.Pagado ||
        //   data['state'] == HandpayState.Cancelado
        // ) {
        //   that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //     $($('td a', row)[1]).addClass('hideVisivilityButton'); //Danger
        //   });
        // }
      }
    };

    var re = new RegExp('_', 'g');

    (this.data.columns = [
      {
        data: 'egm.uid',
        title: 'EGM',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'eventDate',
        title: 'Fecha Evento',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },

      {
        data: 'state',
        title: 'Estado',
        render: function (data, type, row) {
          return data != null ? HandpayState[data].replace(re, ' ') : '-';
        },
      },
      {
        data: 'codeTicket',
        title: 'Ticket',
      },

      {
        data: 'family.description',
        title: 'Familia',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },

      {
        data: 'supervisorName',
        title: 'Usuario',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'supervisorDate',
        title: 'Fecha Supervision',
        render: function (data, type, row) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : '-';
        },
      },
    ]),
      this.performSearch();
  }

  //presiono boton de la interfaz
  async onRefresh() {
    this.refreshPage = !this.refreshPage;

    await this.refreshData();
  }

  async autoRefresh() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    const timeIntevalSeconds = 30 * 1000; // 30 segundos

    this.autoRefreshFunc = setTimeout(async () => {
      await this.refreshData();
    }, timeIntevalSeconds); //
  }

  async refreshData() {
    if (this.refreshPage) {
      await this.commonDaterangepickerComponent.refreshData();
      this.performSearch();
      this.autoRefresh();
    }
  }

  GoToManualPayment() {
    this.router.navigate(['session-manualpayment', this.session.idTurn]);
  }

  onSubmit() {
    // if (this.formSearch.valid) {
    //   this.updateTable();
    // } else {
    //   this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    // }

    //guardo si estoy buscando en el to hasta ahora, para que el refresh actualice la hora:

    var now = moment();
    var to = this.formSearch.get('to').value;

    if (to >= now) {
      this.searchLive = true;
    }

    this.performSearch();
  }

  performSearch() {
    //aca deberia guardar parametros para irme y volver bien
    this.updateTable();
  }

  updateTable() {
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();

    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  getFilters(): MinimalFilters {
    var filters = new MinimalFilters();
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;
    filters.egms = this.formSearch.get('uid').value;
    return filters;
  }
  setGridData(filters: MinimalFilters) {
    let that = this;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Handpay>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return that.ticketService.getHandpayPendingsDatatable(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }
  public onCustomButton(data): void {
    this.handpay = new Handpay(data.row);
    this.isPrintTicketReported = false;
    this.isPrint = false;
    this.isCancel = false;
    switch (parseInt(data.indexButton)) {
      case 0:
        this.body = '¿Esta seguro que desea imprimir?';
        this.isPrint = true;
        this.confirmModal.openModal();
        break;
      case 1:
        this.isCancel = true;
        this.body = '¿Esta seguro que desea cancelar?';
        this.confirmModal.openModal();
        break;
      case 2:
        if (this.submiting) return;
        this.body = '¿Esta seguro que desea generar el ticket?';

        this.isPrintTicketReported = true;
        this.submiting = true;
        if (this.nonAuthorizedMaximum != null) {
          if (this.handpay.formattedAmount > this.nonAuthorizedMaximum) {
            this.requiresAuthorization = true;
            this.needsAuthorization(this.handpay.formattedAmount);
          } else {
            this.confirmModal.openModal();
          }
        } else {
          this.confirmModal.openModal();
        }

        break;
      default:
        console.log('Algo falló con los botones');
        break;
    }
  }

  async getNonAuthorizedMaximum() {
    this.ticketService.getNonAuthorizedMaximum(this.operationType).subscribe((result) => {
      if (result.error == null) {
        this.nonAuthorizedMaximum = result.data;
      }
    });
  }

  needsAuthorization(amount: number) {
    this.ticketService
      .createAuthorization(this.operationType, amount * DataConsts.precisionFactor)
      .subscribe((result) => {
        if (result.data != null && result.data != 0) {
          this.authorizationId = result.data;
          var re = new RegExp('_', 'g');
          this.authorizationBody = `La operación ID ${this.authorizationId} de Pago ${AuthorizationOperationType[
            this.operationType
          ].replace(re, ' ')} requiere autorización, por favor ingresar el código a continuación`;
          this.authorizationModal.openModalStatic();
        } else {
          this.submiting = false;
        }
      });
  }

  async onConfirm() {
    if (this.isPrint) {
      if (await this.printerService.isPrinterReady()) {
        this.ticketService.printHandpayVoucher(this.handpay).subscribe(async (result) => {
          if (result?.data?.tickets) {
            for (let index = 0; index < result?.data?.tickets.length; index++) {
              await this.printerService.print(result?.data?.tickets[index], index);
            }
          }
          this.performSearch();
        });
      }
    } else if (this.isPrintTicketReported) {
      await this.perfomCreateTicket(this.handpay);
    } else {
      this.ticketService.cancelHandpay(this.handpay).subscribe((result) => {
        this.performSearch();
      });
    }
  }

  async perfomCreateTicket(handpay: Handpay) {
    const ticket = new TicketMock();
    //el backend la setea
    ticket.familyId = 1;
    ticket.validatorCode = '100000000000000000';
    ticket.generationReasonId = GenerationReasons.Alerta_Ticket_Out_No_Generado;
    ticket.creationEgmId = handpay.egm.id;
    ticket.amountWithPrecisionView = handpay.amount;
    ticket.creationTurnId = this.session.idTurn;

    if (this.requiresAuthorization) {
      ticket.authorizationCode = this.authorizationModal.code.value;
      ticket.authorizationId = this.authorizationId;
    }

    this.ticketService.generate(ticket).subscribe(async (result) => {
      this.submiting = false;
      if (result.error != null) {
        this.notificationService.showError('Error al generar el ticket.', 'Error');
      }
      this.performSearch();
    });
  }

  initForm() {
    this.formSearch = new FormGroup({
      uid: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }
  GoToCashFlow() {
    this.router.navigate(['/session-cashflow', this.session.idTurn]);
  }
  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }

    if (this.formSearch.get('uid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters;
    }
    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('uid').value.length; index++) {
      const element = this.formSearch.get('uid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('uid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }
}
