<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange != null"> - {{ dateRange }} </span>
              </h3>
              <div class="card-tools">
                <button type="button" class="btn btn-tool mr-2" (click)="onSubmit()">
                  <i class="fas fa-redo"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm">
                  <app-common-daterangepicker
                    [controlFrom]="this.formSearch.get('from')"
                    [controlTo]="formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm">
                  <egm-combo [includeDeleted]="true" [control]="formSearch.get('egmUid')"></egm-combo>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  [disabled]="formSearch.get('egmUid').value == null"
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table
              [model]="data"
              [title]="this.title"
              (onCustomButton)="onCustomButton($event)"
              (onSearch)="onSearch($event)"></app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-modal
  [label]="'statusModal'"
  [yes]="'Continuar'"
  (onConfirm)="toggleStatus()"
  [body]="'¿Está seguro que desea ' + this.messageStatus + ' el contador de la máquina ' + this.uid + '?'">
</app-confirm-modal>
