import { Point } from '@angular/cdk/drag-drop';
import { DecimalPipe } from '@angular/common';
import { CashMachine } from './cashMachine';
import { DashboardItemEvent } from './DashboardItemEvent';
import { EGMMock } from './EGMMock';
import { StateEgm } from './Enums';
import { Events } from './Events';

export class Position implements Point {
  x: number;
  y: number;

  constructor(x: number = 0, y: number = 0) {
    this.x = x;
    this.y = y;
  }
}

export class DashboardItem {
  id: number;
  egm?: EGMMock;
  egmId?: number;
  cashMachine?: CashMachine;
  cashMachineId?: number;
  position: Position;
  x: number;
  y: number;
  prevPosition: Position;
  prevX: number;
  prevY: number;
  innerHTML: string;
  isSelected: boolean;
  currentCredit: number;
  currentCreditMoney: number;

  result: number;
  event: Events;
  eventsBuffer: DashboardItemEvent[];
  stateCashMachine: number;

  constructor() {
    this.id = 0;
    this.position = new Position();
    this.prevPosition = new Position();
    this.innerHTML = 'No se encontró información';
    this.isSelected = false;
    this.x = 0;
    this.y = 0;
    this.prevY = 0;
    this.prevX = 0;
  }
}
