import { CancellationReasons } from './Enums';

export class TicketReplacementViewModel {
  validatorCode: string;
  replacementReason: CancellationReasons;
  turnId: number;
  ticketAmount: number[];
}
export class TicketCancelViewModel {
  validatorCode: string;
  replacementReason: CancellationReasons;
  turnId: number;
}

export class TicketViewModel {
  validatorCode: string;
  ticketNumber: number;
  creationDateFormattedDate: string;
  familyDescription: string;
  formattedAmount: number;
  eventDateFormattedDate: string;
  uid: number;
  mUid: number;
  manufacturer: number;
  model: number;
  cashRegisterName: string;

  uidTarget;
  manufacturerTarget: number;
  modelTarget: number;
  cashRegisterNameTarget: string;
}
