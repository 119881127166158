import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { param } from 'jquery';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { StateEgm } from 'src/app/common/components/viewmodels/Enums';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-list-egms',
  templateUrl: './list-egms.component.html',
  styleUrls: ['./list-egms.component.css'],
})
export class ListEgmsComponent implements OnInit {
  public data: commonTableData<EGMMock>;

  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<EGMMock>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  egm: EGMMock = new EGMMock();
  parameter: tableStatus = null;
  showUpdateFir: boolean;
  loadedForm: boolean;
  title = 'EGMs';

  public static getName() {
    return 'ListEgmsComponent';
  }
  constructor(
    public egmService: EgmServiceService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private casinoService: CasinoService
  ) {}

  async ngOnInit(): Promise<void> {
    // ngOnInit() {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.showUpdateFir = (
      await this.casinoService.isComponentAuthorized('EditFirmwareComponent', 'Page').toPromise()
    ).data;

    this.data = new commonTableData();
    let paramEGM = this.paramService.getAndDeleteParameters();

    if (paramEGM != null) {
      this.data.searchText = paramEGM.searchString;
      this.data.pageLength = paramEGM.pageLength;
      this.data.currentPage = paramEGM.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    let button: commonTableCustomButton = new commonTableCustomButton();
    button.icon = 'fa-clone';
    button.columntitle = 'Clonar EGM';
    button.tooltip = 'Clonar EGM';
    this.data.commonTableCustomButton.push(button);
    button = new commonTableCustomButton();
    button.icon = 'fa-cogs';
    button.columntitle = 'Parámetros avanzados';
    button.tooltip = 'Parámetros avanzados';
    this.data.commonTableCustomButton.push(button);

    // button = new commonTableCustomButton();
    // button.icon = 'fa-sync-alt';
    // button.columntitle = 'Actualizar Firmware';
    // button.tooltip = 'Actualizar Firmware';
    // this.data.commonTableCustomButton.push(button);

    await this.setGridData(true);
    this.loadedForm = true;
  }

  async setGridData(param1: boolean): Promise<void> {
    let service = this.egmService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<EGMMock>>> {
      return service.getEGMs2(param);
    };

    let that = this;

    // this.data.rowCallback = function (row: Node, data: any | Object, index: number): void {
    //   //saco el boton imprimer de los que no van
    //   if (!that.showUpdateFir) {
    //     that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //       $($('td a', row)[9]).addClass('hideButton'); //Danger
    //     });
    //   }
    // };

    this.data.columns = [
      { data: 'uidFormatted', title: 'UID' },
      { data: 'manufacturer.name', title: 'Fabricante' },
      { data: 'model.name', title: 'Modelo' },
      {
        data: 'gameType.name',
        title: 'Juego',
        render: function (data, type, row) {
          return data != null ? data : `-`;
        },
      },
      {
        data: 'room.name',
        title: 'Sala',
        render: function (data, type, row) {
          return data != null ? data : `-`;
        },
      },
      {
        data: 'date',
        title: 'Fecha Alta',
        render: function (data, type, row: EGMMock) {
          return data != null ? moment(data).format('DD/MM/yyyy, HH:mm:ss') : `-`;
        },
      },
      {
        data: 'statusEgm',
        title: 'Últ. Estado',
        render: function (data, type, row) {
          return data != null ? StateEgm[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },
      {
        data: 'lastEvent',
        title: 'Evento',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
    ];
    this.data.rowCallback = function (row: Node, data: EGMMock | Object, index: number): void {
      //saco el boton imprimer de los que no van

      if (data['eliminationDate'] != null) {
        that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          $($('td a', row)[3]).addClass('hideButton');
          $($('td a', row)[2]).addClass('hideButton');
          $($('td a', row)[1]).addClass('hideButton');
          $($('td a', row)[0]).addClass('hideButton');
        });
      }
    };
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/egms/edit-egm', row.id]);
  }

  public onDeleteButton(row): void {
    this.egm = row;
    this.body =
      '¿Está seguro que desea dar de baja la EGM ' +
      this.egm.uid.toString() +
      '?. Luego de esta acción usted no tendrá mas acceso a su información Histórica';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/egms/create-egm']);
  }

  confirmDelete() {
    this.egmService.deleteEGM(this.egm.id).subscribe((result) => {
      //this.notificationService.showSuccess(`Se borro la EGM ${this.egm.uid}`, "Atención!");
      this.updateTable();
    });
  }

  public onCustomButton(data): void {
    switch (parseInt(data.indexButton)) {
      case 0:
        this.paramService.saveParameters(this.parameter);
        this.router.navigate(['machines/egms/clone-egm', data.row.id]);
        break;
      case 1:
        this.paramService.saveParameters(this.parameter);
        this.router.navigate(['machines/egms/parameters/edit', data.row.id]);
        break;
      case 2:
        this.paramService.saveParameters(this.parameter);
        this.router.navigate(['machines/egms/firmware', data.row.id]);
        break;
      default:
        console.log('Algo falló con los botones');
        break;
    }
  }
}
