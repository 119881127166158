import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { Manufacturer } from 'src/app/common/components/viewmodels/Manufacturer';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-manufacturers',
  templateUrl: './edit-manufacturer.component.html',
  styleUrls: ['./edit-manufacturer.component.css'],
})
export class EditManufacturerComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Nuevo fabricante';

  items: Array<BreadcrumbItem> = [];

  InputType = InputTypeEnum;

  isEdit: boolean;

  manufacturer: Manufacturer = new Manufacturer();

  parameter: tableStatus = null;

  public static getName() {
    return 'EditManufacturerComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private ManufacturerService: ManufacturerService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.parameter = this.paramService.getAndDeleteParameters();

    if (this.activatedRoute.snapshot.params.id != null) {
      this.ManufacturerService.getManufacturer(this.activatedRoute.snapshot.params.id).subscribe((result) => {
        this.formEdit.patchValue(result.data);
      });

      this.title = 'Editar fabricante';
      this.isEdit = true;
    }

    this.items.push(new BreadcrumbItem('/machines/manufacturers', 'Máquinas'));
    this.items.push(new BreadcrumbItem('/machines/manufacturers', 'Fabricantes'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('machines/manufacturers');
  }

  onSubmit() {
    this.formEdit.markAllAsTouched();
    if (this.formEdit.valid) {
      this.manufacturer = new Manufacturer(this.formEdit.value);

      if (this.manufacturer.id == null) {
        this.manufacturer.id = '00000000-0000-0000-0000-000000000000';
      }

      this.paramService.saveParameters(this.parameter);
      this.ManufacturerService.createOrEdit(this.manufacturer).subscribe((result) => {
        this.router.navigate(['/machines/manufacturers']);
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
    });
  }
}
