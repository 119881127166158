<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                <span> {{ headerTitle }}</span>
              </h3>
            </div>
            <div class="card-body">
              <div class="form-row">
                <validation-code
                  [control]="this.formSearch.get('ticketCode')"
                  [formSubmitted]="formObject.submitted"
                  class="w-100"></validation-code>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-info">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- /.row -->
    <div class="row">
      <div class="col">
        <div class="card" *ngIf="cardRead">
          <div class="card-header">
            <h3 class="card-title">Información de ticket leído</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display [label]="'Código'" [model]="ticketTracker.ticket.validatorCode"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display [label]="'Nro. de ticket'" [model]="ticketTracker.ticket.ticketNumber">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  [label]="'Importe'"
                  [model]="currencyPipe.transform(ticketTracker.ticket.formattedAmount, currencyCode, currencySym)">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  label="Fecha VTO"
                  [model]="moment(ticketTracker.ticket.expirationDate).format('DD/MM/yyyy, HH:mm:ss')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  label="Fecha evento"
                  [model]="moment(ticketTracker.eventDate).format('DD/MM/yyyy, HH:mm:ss')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.state !== null">
                <app-info-display label="Estado" [model]="TicketState[ticketTracker.state].replace(regex, ' ')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.cancellationReason !== null">
                <app-info-display
                  label="Motivo Anulación"
                  [model]="CancellationReasons[ticketTracker.cancellationReason].replace(regex, ' ')">
                </app-info-display>
              </div>
              <div
                class="form-group col-12 col-md-6 col-xl-4"
                *ngIf="ticketTracker.ticket.ticketReplacement.length > 0">
                <app-info-display label="Reemplazado Por" [model]="ticketTracker.ticket.ticketReplacementCodeValidator">
                </app-info-display>
              </div>

              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.type !== null">
                <app-info-display label="Tipo" [model]="TicketType[ticketTracker.type].replace(regex, ' ')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.ticket.family !== null">
                <app-info-display
                  label="Familia"
                  [model]="TicketFamily[ticketTracker.ticket.family.familyGroupId].replace(regex, ' ')">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.eventEgm !== null">
                <app-info-display [label]="'EGM'" [model]="ticketTracker.eventEgm.uid"> </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.eventTurn !== null">
                <app-info-display [label]="'Caja'" [model]="ticketTracker.eventTurn.session.cashRegister.cuid">
                </app-info-display>
              </div>
              <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.eventTurn !== null">
                <app-info-display [label]="'Cajero'" [model]="ticketTracker.userIdName"> </app-info-display>
              </div>
              <!-- <div class="form-group col-12 col-md-6 col-xl-4" *ngIf="ticketTracker.userIdName != null">
                <app-info-display [label]="'Supervisor'" [model]="ticketTracker.userIdName"> </app-info-display>
              </div> -->
              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  label="Fecha emisión"
                  [model]="moment(ticketTracker.ticket.creationDate).format('DD/MM/yyyy, HH:mm:ss')">
                </app-info-display>
              </div>

              <div class="form-group col-12 col-md-6 col-xl-4">
                <app-info-display
                  label="Preinsertado"
                  [model]="PreInserted[ticketTracker.preInserted].replace(regex, ' ')">
                </app-info-display>
              </div>

              <div
                class="form-group col-12 col-md-6 col-xl-4"
                *ngIf="ticketTracker.preInserted != PreInserted.No && ticketTracker.preInsertedError != null">
                <app-info-display label="Error preinsertado" [model]="ticketTracker.preInsertedError">
                </app-info-display>
              </div>

              <div *ngIf="showDivision" class="col-12">
                <hr />
                <div class="form-group col-12">
                  <div class="d-flex align-items-center">
                    <span>Tickets de reemplazo</span>
                    <div class="ml-2">
                      <button (click)="addInput()" class="btn btn-primary">
                        <i class="fas fa-solid fa-plus-circle"></i>
                      </button>

                      <button
                        (click)="removeInput()"
                        [disabled]="divisionForm.get('dynamicInputs').controls.length <= 1"
                        class="btn btn-danger ml-2">
                        <i class="fas fa-solid fa-minus-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <form [formGroup]="divisionForm">
                  <div
                    class="text-danger font-size-14"
                    *ngIf="
                      divisionForm.get('dynamicInputs').hasError('totalAmountMismatch') &&
                      (divisionForm.touched || divisionForm.dirty || divisionForm.submitted)
                    ">
                    La suma total de los tickets {{ currencySym }}{{ allSum }} no coincide con el monto original
                    {{ currencySym }}{{ this.ticketTracker.ticket.formattedAmount }}.
                  </div>
                  <div formArrayName="dynamicInputs">
                    <div
                      *ngFor="let valueCtrl of divisionForm.get('dynamicInputs').controls; let i = index"
                      class="d-flex align-items-center"
                      style="padding-top: 25px">
                      <label class="mr-2">{{ i + 1 }}) Ticket {{ currencySym }}</label>
                      <input
                        class="form-control form-control-sm min-height-36"
                        [class.is-invalid]="
                          (valueCtrl.touched || valueCtrl.dirty || formObject.submitted) && valueCtrl.invalid
                        "
                        [formControl]="valueCtrl"
                        (keyup)="changeTotal(i)"
                        style="width: 150px" />
                      <div
                        class="text-danger font-size-14"
                        *ngIf="(valueCtrl.touched || valueCtrl.dirty || formObject.submitted) && valueCtrl.invalid">
                        <div *ngIf="valueCtrl.errors.required">Campo obligatorio.</div>
                        <div *ngIf="valueCtrl.errors.maxlength">
                          Campo de
                          {{ valueCtrl.errors.maxlength.requiredLength }}
                          caracteres como máximo.
                        </div>
                        <div *ngIf="valueCtrl.errors?.min || valueCtrl.errors?.max">
                          Campo debe ser un valor entre
                          {{ valueCtrl.errors?.min ? valueCtrl.errors.min.min : minValue }}
                          y
                          {{ valueCtrl.errors?.max ? valueCtrl.errors.max.max : maxValue }}.
                        </div>
                        <div *ngIf="valueCtrl.errors.pattern">Número no válido.</div>

                        <div *ngIf="valueCtrl.errors.totalAmountExceeded">
                          La suma total excede el valor máximo permitido.
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- fin body -->
          </div>

          <div class="card-footer text-right">
            <button
              type="button"
              [disabled]="divisionForm.invalid || isUsed"
              class="btn btn-primary ml-2"
              style="min-width: 100px"
              (click)="replaceTicket()">
              Reemplazar
            </button>
            <button
              type="button"
              class="btn btn-primary ml-2"
              (click)="anularTicket()"
              style="min-width: 100px"
              [disabled]="!canCancel">
              Anular
            </button>
            <button type="button" class="btn btn-secondary ml-2" (click)="GoToCashFlow()">Volver</button>
          </div>
        </div>

        <div class="card" *ngIf="!cardRead">
          <div class="card-body"><h3 class="card-title">No hay datos para mostrar</h3></div>
          <div class="card-footer text-right">
            <button type="button" class="btn btn-secondary ml-2" (click)="GoToCashFlow()">Volver</button>
          </div>
        </div>
      </div>
    </div>

    <app-ticket-printer-status></app-ticket-printer-status>
  </div>
  <app-confirm-modal yes="Continuar" (onConfirm)="confirm()" [body]="this.body"> </app-confirm-modal>

  <ng-template #replacementModal let-modal class="modal">
    <div class="modal-header">
      <h3 class="modal-title">{{ titleModal }}</h3>
    </div>
    <form role="form" [formGroup]="formReplacementReason" (ngSubmit)="onContinueButton()" #formObj="ngForm">
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="col-12 col-4">
              <common-input-select
                class="form-control-sm"
                [list]="this.cancellationReason"
                label="{{ labelModal }}"
                formControlName="replacementReason">
              </common-input-select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-primary">Continuar</button>
          <button type="button" class="btn btn-secondary ml-2" (click)="dismissModal()">Cancelar</button>
        </div>
      </div>
    </form>
  </ng-template>
</div>
