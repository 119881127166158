<div id="floatingCirclesG" class="mt-4" *ngIf="isLoading">
  <div class="f_circleG" id="frotateG_01"></div>
  <div class="f_circleG" id="frotateG_02"></div>
  <div class="f_circleG" id="frotateG_03"></div>
  <div class="f_circleG" id="frotateG_04"></div>
  <div class="f_circleG" id="frotateG_05"></div>
  <div class="f_circleG" id="frotateG_06"></div>
  <div class="f_circleG" id="frotateG_07"></div>
  <div class="f_circleG" id="frotateG_08"></div>
</div>

<div *ngIf="!isLoading">
  <common-header [title]="title" [items]="items"></common-header>

  <div class="content">
    <div class="container-fluid">
      <div class="row" *ngIf="loadData">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                <span> {{ headerTitle }}</span>
                <div>Recaudación Salón: {{ data.collectionCasinoDesc }}</div>
              </h3>
              <div class="card-tools">
                <button #myCollapse type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i id="toolBtn" class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <form
              role="form"
              [formGroup]="formEdit"
              (ngSubmit)="onSubmit(formObject)"
              #formObject="ngForm"
              autocomplete="off">
              <div class="card-body row">
                <div class="col-12 col-md-5">
                  <div class="row card col-lg-10 col-md-12">
                    <div class="card-body">
                      <div class="col-12">
                        <p class="lead">Contadores</p>
                        <table class="table datatable table-sm">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Diferencia</th>
                              <!-- <th>Final</th>
                            <th>Diferencia</th> -->
                              <th>Importe</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Jugado</td>
                              <!-- <td>{{ data.playedInitial }}</td>
                            <td>{{ data.playedEnd }}</td> -->
                              <td>{{ data.playedCounter }}</td>
                              <td>
                                {{ currencyPipe.transform(data.formattedPlayedMoney, currencyCode, currencySym) }}
                              </td>
                            </tr>
                            <tr>
                              <td>Premiado</td>
                              <!-- <td>{{ data.wonInitial }}</td>
                            <td>{{ data.wonEnd }}</td> -->
                              <td>{{ data.wonCounter }}</td>
                              <td>
                                {{ currencyPipe.transform(data.formattedWonMoney, currencyCode, currencySym) }}
                              </td>
                            </tr>
                          </tbody>

                          <tfoot>
                            <tr>
                              <!-- <td colspan="3"></td> -->
                              <td></td>
                              <th>Ganancia</th>
                              <th>{{ currencyPipe.transform(data.formattedBenefit, currencyCode, currencySym) }}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="row card col-lg-10 col-md-12">
                    <div class="card-body">
                      <div class="col-12">
                        <p class="lead">Tickets</p>

                        <table class="table table-sm datatable">
                          <tbody>
                            <tr>
                              <td style="width: 50%">Ticket Salida</td>
                              <td>
                                {{ currencyPipe.transform(data.formattedTicketOut, currencyCode, currencySym) }}
                              </td>
                            </tr>
                            <tr>
                              <td>Ticket Entrada</td>
                              <td>{{ currencyPipe.transform(data.formattedTicketIn, currencyCode, currencySym) }}</td>
                            </tr>

                            <tr>
                              <th>Total</th>
                              <th>
                                {{ currencyPipe.transform(data.formattedTotalTickets, currencyCode, currencySym) }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="row card col-lg-10 col-md-12">
                    <div class="card-body">
                      <div class="col-12">
                        <p class="lead">Movimientos</p>

                        <table class="table table-sm datatable">
                          <tbody>
                            <tr *ngFor="let mov of data.movementsSummary">
                              <td style="width: 50%">{{ mov.description }}</td>
                              <td>{{ currencyPipe.transform(mov.formattedAmount, currencyCode, currencySym) }}</td>
                            </tr>
                            <tr>
                              <th>Total Movimientos</th>
                              <th>
                                {{ currencyPipe.transform(data.formattedTotalMoviments, currencyCode, currencySym) }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-7">
                  <div class="row card col-lg-8 col-md-10">
                    <div class="card-body">
                      <div class="col-12">
                        <p class="lead">Recaudación</p>

                        <table class="table table-sm datatable" style="table-layout: fixed">
                          <tbody>
                            <tr>
                              <td style="width: 70%">Depósito Inicial Anterior</td>
                              <td style="width: 30%">
                                {{
                                  currencyPipe.transform(data.formattedDepositInitialBefore, currencyCode, currencySym)
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>Ganancia</td>
                              <td>
                                {{ currencyPipe.transform(data.formattedBenefit, currencyCode, currencySym) }}
                              </td>
                            </tr>
                            <tr>
                              <td>Tickets</td>
                              <td>
                                {{ currencyPipe.transform(data.formattedTotalTickets, currencyCode, currencySym) }}
                              </td>
                            </tr>
                            <tr class="bordertop">
                              <td>Total Movimientos</td>
                              <td>
                                {{ currencyPipe.transform(data.formattedTotalMoviments, currencyCode, currencySym) }}
                              </td>
                            </tr>

                            <tr>
                              <td>Arqueo teórico EGM</td>
                              <td>
                                {{ currencyPipe.transform(data.formattedSettlementTehoric, currencyCode, currencySym) }}
                              </td>
                            </tr>
                            <tr>
                              <td>Arqueo real EGM {{ currencySym }}</td>
                              <td>
                                <div class="col-12 col-sm-6 col-xl" style="padding-right: 0; width: 100%">
                                  <input
                                    #standard
                                    [type]="type"
                                    class="form-control form-control-sm min-height-36"
                                    [class.is-invalid]="
                                      (formEdit.get('formattedSettlementReal').touched ||
                                        formEdit.get('formattedSettlementReal').dirty ||
                                        formObject.submitted) &&
                                      formEdit.get('formattedSettlementReal').invalid
                                    "
                                    [formControl]="formEdit.get('formattedSettlementReal')"
                                    [id]="formattedSettlementReal"
                                    (keyup)="changeTotalToTake($event)"
                                    [maxLength]="13" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" style="border: none">
                                <div
                                  class="text-danger font-size-14"
                                  *ngIf="
                                    (formEdit.get('formattedSettlementReal').touched ||
                                      formEdit.get('formattedSettlementReal').dirty ||
                                      formObject.submitted) &&
                                    formEdit.get('formattedSettlementReal').invalid
                                  ">
                                  <div *ngIf="formEdit.get('formattedSettlementReal').errors.required">
                                    Campo obligatorio.
                                  </div>
                                  <!-- <div *ngIf="formEdit.get('formattedSettlementReal').errors.maxlength">
                                  Campo de
                                  {{ formEdit.get('formattedSettlementReal').errors.maxlength.requiredLength }}
                                  caracteres como máximo.
                                </div> -->
                                  <div *ngIf="formEdit.get('formattedSettlementReal').errors.max">
                                    Campo no puede ser mayor a
                                    {{ formEdit.get('formattedSettlementReal').errors.max.max }}.
                                  </div>
                                  <div *ngIf="formEdit.get('formattedSettlementReal').errors.pattern">
                                    Número no válido.
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr class="bordertop">
                              <td>Deposito Inicial {{ currencySym }}</td>
                              <td>
                                <div class="col-12 col-sm-6 col-xl" style="padding-right: 0; width: 100%">
                                  <input
                                    #standard
                                    [type]="type"
                                    class="form-control form-control-sm min-height-36"
                                    [class.is-invalid]="
                                      (formEdit.get('formattedDepositCurrent').touched ||
                                        formEdit.get('formattedDepositCurrent').dirty ||
                                        formObject.submitted) &&
                                      formEdit.get('formattedDepositCurrent').invalid
                                    "
                                    [formControl]="formEdit.get('formattedDepositCurrent')"
                                    [id]="formattedDepositCurrent"
                                    (keyup)="changeTotalToTake($event)"
                                    [maxLength]="13" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" style="border: none">
                                <div
                                  class="text-danger font-size-14"
                                  *ngIf="
                                    (formEdit.get('formattedDepositCurrent').touched ||
                                      formEdit.get('formattedDepositCurrent').dirty ||
                                      formObject.submitted) &&
                                    formEdit.get('formattedDepositCurrent').invalid
                                  ">
                                  <div *ngIf="formEdit.get('formattedDepositCurrent').errors.required">
                                    Campo obligatorio.
                                  </div>
                                  <div *ngIf="formEdit.get('formattedDepositCurrent').errors.maxlength">
                                    Campo de
                                    {{ formEdit.get('formattedDepositCurrent').errors.maxlength.requiredLength }}
                                    caracteres como máximo.
                                  </div>
                                  <div *ngIf="formEdit.get('formattedDepositCurrent').errors.max">
                                    Campo no puede ser mayor a
                                    {{ formEdit.get('formattedDepositCurrent').errors.max.max }}.
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr class="footerrow">
                              <th>{{ getLabelCashResult() }}</th>
                              <th>
                                {{
                                  currencyPipe.transform(
                                    formEdit.get('formattedTheoricToTake').value,
                                    currencyCode,
                                    currencySym
                                  )
                                }}
                              </th>
                            </tr>
                            <tr class="footerrow">
                              <th>Recaudación</th>
                              <th>
                                {{
                                  currencyPipe.transform(
                                    data.formattedTheoricPendingToReConcil,
                                    currencyCode,
                                    currencySym
                                  )
                                }}
                              </th>
                            </tr>
                            <tr *ngIf="data.ticketPendingCount > 0">
                              <td style="text-align: left; font-style: italic">
                                *Se van anular {{ data.ticketPendingCount }} tickets por un total de
                                {{
                                  currencyPipe.transform(data.formattedTicketPendingAmount, currencyCode, currencySym)
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="row card col-lg-8 col-md-10">
                    <div class="form-group col-12">
                      <app-common-input
                        [control]="formEdit.get('observations')"
                        [label]="'Observaciones'"
                        [type]="InputType.textarea">
                      </app-common-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer text-right">
                <button type="button" class="btn btn-primary" (click)="goToPerfomModal()" [disabled]="isLoading">
                  Aceptar
                </button>

                <button type="button" class="btn btn-secondary ml-2" (click)="GoToBack()" [disabled]="isLoading">
                  Volver
                </button>
              </div>
            </form>
          </div>
          <!-- /.card -->
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-modal yes="Continuar" (onConfirm)="onConfirm()" [body]="body"> </app-confirm-modal>
