<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ title }}</h3>
  </div>
  <div class="modal-body" style="display: grid">
    <div [innerHTML]="body"></div>
    <div class="form-group col-12 col-md-6 pt-3" style="justify-self: center">
      <app-common-input [control]="code" [placeholder]="'Código'" [type]="InputType.numeric"> </app-common-input>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button type="button" (click)="confirm()" class="btn btn-primary">{{ yes }}</button>
      <button type="button" *ngIf="!okOnly" class="btn btn-secondary ml-2" (click)="cancel()">{{ no }}</button>
    </div>
  </div>
</ng-template>
