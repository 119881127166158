import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralEditEgmComponent } from 'src/app/common/components/business/general-edit-egm/general-edit-egm.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { commonTableData } from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';

@Component({
  selector: 'app-list-egm-parameters',
  templateUrl: './list-egm-parameters.component.html',
  styleUrls: ['./list-egm-parameters.component.css'],
})
export class ListEgmParametersComponent implements OnInit {
  @ViewChild(GeneralEditEgmComponent) report: GeneralEditEgmComponent;
  roomCombo: Array<IdTextModel> = [];
  egmCombo: Array<IdTextModel> = [];

  public data: commonTableData<EGM>;

  items: Array<BreadcrumbItem> = [];

  formSearch: FormGroup;
  searchMade: boolean;
  idReportEgm: number;

  public static getName() {
    return 'ListEgmParametersComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Parámetros EGMs/Host'));

    this.formSearch = new FormGroup({
      egmUid: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    this.searchMade = true;
    this.idReportEgm = this.formSearch.get('egmUid').value;
    if (this.report != null) this.report.reportReload(this.idReportEgm);
  }
}
