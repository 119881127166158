<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <form role="form" [formGroup]="formEdit" (ngSubmit)="onSubmit()" #formObject="ngForm" *ngIf="showForm">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('muid')"
                    [label]="'MCUID'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-4 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('name')"
                    [label]="'Nombre'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('mac')"
                    [label]="'Dirección MAC'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.MAC">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <room-combo [control]="this.formEdit.get('roomId')"> </room-combo>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <common-input-select
                    [label]="'Ubicación'"
                    [list]="locationList"
                    formControlName="locationId"
                    class="form-control-sm"
                    [formSubmitted]="formObject.submitted">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('cashinLimit')"
                    [label]="'Cashin Limit (sin céntimos)'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('timeOut')"
                    [label]="'Time Out (seg.)'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4">
                  <app-common-input
                    [control]="this.formEdit.get('heartBeat')"
                    [label]="'Heart Beat (seg.)'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-4 col-xl-4">
                  <common-input-select
                    label="Tipo de Dispositivo"
                    (valueChanged)="changeBrand($event)"
                    [list]="brandList"
                    formControlName="brand"
                    class="form-control-sm">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="showIp">
                  <app-common-input
                    [control]="this.formEdit.get('ip')"
                    [label]="'Dirección IP'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.IP">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="showDb">
                  <app-common-input
                    [control]="this.formEdit.get('db')"
                    [label]="'Base de Datos'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="showDb">
                  <app-common-input
                    [control]="this.formEdit.get('user')"
                    [label]="'Usuario'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="showDb">
                  <app-common-input
                    [control]="this.formEdit.get('psw')"
                    [label]="'Password'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.password">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="showDb">
                  <app-common-input
                    [control]="this.formEdit.get('port')"
                    [label]="'Puerto'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4 d-flex align-items-center" *ngIf="showDb">
                  <button
                    [disabled]="isTestConnection"
                    type="button"
                    class="btn btn-primary"
                    style="margin: 1rem"
                    (click)="onTestConnection()">
                    Probar conexión
                  </button>
                  <span *ngIf="isTestConnection">Conectando...</span>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="isEdit">
                  <app-common-input
                    [control]="this.formEdit.get('machineHostSerialNumber')"
                    [label]="'MachineHost Serial Number'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="isEdit">
                  <app-common-input
                    [control]="this.formEdit.get('systemHostKey')"
                    [label]="'System HostKey'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>

                <div class="form-group col-12 col-sm-6 col-xl-4" *ngIf="isEdit">
                  <app-common-input
                    [control]="this.formEdit.get('eliminationDate')"
                    [label]="'Fecha de eliminación'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.date">
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="this.formEdit.get('description')"
                    [label]="'Observaciones'"
                    [type]="InputType.textarea">
                  </app-common-input>
                </div>
              </div>

              <div class="card collapsed-card">
                <div class="card-header">
                  <h3 class="card-title">Mapeo Stock</h3>

                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>

                <!-- <div class="form-row">
                  <div class="form-group col-12 col-sm-2 col-xl-2">
                    <span class="title">Mapeos</span>

                    <button
                      class="btn btn-sm btn-primary"
                      (click)="addmapping(); $event.preventDefault()"
                      style="margin-left: 15px">
                      <i class="fas fa-plus"></i> Agregar Mapeo
                    </button>
                  </div>
                </div> -->
                <div class="card-body">
                  <ng-container
                    *ngFor="
                      let cashMachineCurrencyMapping of formEdit.get('cashMachineCurrencyMapping')['controls'];
                      let i = index
                    ">
                    <div class="" [formGroup]="cashMachineCurrencyMapping">
                      <div class="form-row">
                        <div class="form-group col-12 col-sm-1 col-xl-1"></div>
                        <div class="form-group col-12 col-sm-2 col-xl-2">
                          <app-currency-type-combo
                            [allowClear]="true"
                            [control]="cashMachineCurrencyMapping.get('currencyTypeId')"
                            [formSubmitted]="formObject.submitted"></app-currency-type-combo>
                        </div>
                        <div class="form-group col-12 col-sm-2 col-xl-2">
                          <app-common-input
                            [control]="cashMachineCurrencyMapping.get('trayIdentifier')"
                            [label]="'Tray Identifier'"
                            [formSubmitted]="formObject.submitted"
                            [type]="InputType.text">
                          </app-common-input>
                        </div>
                        <div class="form-group col-12 col-sm-2 col-xl-2">
                          <app-common-input
                            [control]="cashMachineCurrencyMapping.get('denomination')"
                            [label]="'Denominación'"
                            [formSubmitted]="formObject.submitted"
                            [type]="InputType.text">
                          </app-common-input>
                        </div>
                        <div class="form-group col-12 col-sm-1 col-xl-1" style="padding-top: 35px">
                          <a
                            class="btn deleteButton"
                            (click)="deleteMapping(i)"
                            data-row="0"
                            data-toggle="tooltip"
                            data-placement="right"
                            title=""
                            data-original-title="Eliminar"
                            ><i class="fas fa-trash-alt"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-sm btn-primary" (click)="addmapping(); $event.preventDefault()">
                      <i class="fas fa-plus"></i> Agregar Mapeo
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <app-confirm-buttons (onCancel)="cancel()"></app-confirm-buttons>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
