import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { InputTypeEnum } from '../../../shared/common-input/common-input.component';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { NotificationService } from '../../../shared/notification/notification.service';
import {
  SessionManagmentViewModel,
  ButtonMovementTypeEnum,
  SessionMovementViewModel,
} from '../../../viewmodels/SessioModel';
import { AuthorizationModalComponent } from '../../../shared/authorization-modal/authorization-modal.component';
import { AuthorizationOperationType } from '../../../viewmodels/Enums';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { DataConsts } from '../../../viewmodels/Casino';

@Component({
  selector: 'app-session-movement-control',
  templateUrl: './session-movement-control.component.html',
  styleUrls: ['./session-movement-control.component.css'],
})
export class SessionMovementControlComponent implements OnInit {
  formEdit: FormGroup;
  @Input() turnId: number;
  @Input() nonAuthorizedMaximum: number;
  @Input() title: string;
  @Input() cashflow: boolean = false;
  @Input() sessionMovementType: ButtonMovementTypeEnum;
  @Input() operationType: AuthorizationOperationType;

  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  loadData: boolean = false;
  headerTitle: string = '';
  InputType = InputTypeEnum;
  currencyCode: string;
  currencySym: string;
  importeLabel: string = 'Importe ';
  body: string;
  authorizationBody: string;
  @ViewChild('myForm') myForm: NgForm;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  @ViewChild(AuthorizationModalComponent, { static: false }) authorizationModal: AuthorizationModalComponent;
  requiresAuthorization: boolean;
  authorizationId?: number;
  submiting = false;

  constructor(
    private router: Router,
    public printerService: PrinterService,
    public sessionService: SessionService,
    public notificationService: NotificationService,
    private ticketService: TicketService,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.body = `¿Está seguro que desea realizar la operación de ${this.title}?`;
    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
      this.importeLabel += this.currencySym;
    });

    this.sessionService.GetSessionByTurnId(this.turnId).subscribe((result) => {
      this.session = result.data;
      this.getNonAuthorizedMaximum();
      this.loadData = true;

      if (this.session === null) {
        this.router.navigate(['/session-management']);
      }
      this.headerTitle = this.sessionService.getPageTitle(this.session);
    });
  }
  goToErrorPage(): void {
    this.notificationService.showError('No es posible cargar la página solicitada', 'error');
    this.router.navigate(['/session-management']);
  }

  private initForm() {
    this.formEdit = new FormGroup({
      amount: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
      ]),
      observations: new FormControl(null, Validators.required),
    });
  }
  GoToCashFlow() {
    this.router.navigate(['/session-cashflow', this.session.idTurn]);
  }

  async onSubmit() {
    if (this.formEdit.valid) {
      if (this.nonAuthorizedMaximum != null) {
        this.submiting = true;
        if (this.formEdit.get('amount').value > this.nonAuthorizedMaximum) {
          this.requiresAuthorization = true;
          this.needsAuthorization(this.formEdit.get('amount').value);
        } else {
          this.confirmModal.openModalStatic();
        }
      } else {
        this.confirmModal.openModalStatic();
      }
    }
  }

  getNonAuthorizedMaximum() {
    if (this.operationType != null) {
      this.ticketService.getNonAuthorizedMaximum(this.operationType).subscribe((result) => {
        if (result.error == null) {
          this.nonAuthorizedMaximum = result.data;
        }
      });
    }
  }

  needsAuthorization(amount: number) {
    this.ticketService
      .createAuthorization(this.operationType, amount * DataConsts.precisionFactor)
      .subscribe((result) => {
        if (result.data != null && result.data != 0) {
          this.authorizationId = result.data;
          this.authorizationBody = `La operación ID ${this.authorizationId} de ${this.title} requiere autorización, por favor ingresar el código a continuación`;
          this.authorizationModal.openModalStatic();
        } else {
          this.submiting = false;
        }
      });
  }

  async SubmitSessionMovementGenerateTicket(model: SessionMovementViewModel) {
    if (await this.printerService.isPrinterReady()) {
      var ticketQuantity = 1;

      var error = false;

      for (let index = 1; index <= ticketQuantity && !error; index++) {
        if (await this.printerService.isPrinterReady()) {
          if (this.requiresAuthorization) {
            model.authorizationCode = this.authorizationModal.code.value;
            model.authorizationId = this.authorizationId;
          }
          var result = await this.sessionService.SessionMovementGenerateTicket(model).toPromise();
          if (result?.data?.tickets.length > 0) {
            await this.printerService.print(result?.data?.tickets[0], index - 1);
          } else {
            error = true;
          }
        } else {
          error = true;
        }
      }
      this.submiting = false;

      if (!error) {
        //this.myForm.resetForm();
        this.GoToCashFlow();
      }
    }
  }

  async SubmitSessionMovement(model: SessionMovementViewModel) {
    if (this.cashflow) {
      this.sessionService.SessionMovement(model).subscribe((result) => {
        if (result != null) {
          this.GoToCashFlow();
        }
      });
    } else {
      this.sessionService.SessionMovemenCashflow(model).subscribe((result) => {
        if (result != null) {
          this.GoToCashFlow();
        }
      });
    }
  }
  async ConfirmAction() {
    if (this.formEdit.valid) {
      if (this.formEdit.get('amount').value <= 0) {
        this.notificationService.showError('El monto no puede ser menor o igual a cero', 'Operación rechazada');
      } else {
        let model: SessionMovementViewModel = new SessionMovementViewModel();
        model.movementType = this.sessionMovementType;
        model.formattedAmount = Number(this.formEdit.get('amount').value);
        model.observation = this.formEdit.get('observations').value;
        model.turnId = this.turnId;

        switch (this.sessionMovementType) {
          case ButtonMovementTypeEnum.PayDatafono:
          case ButtonMovementTypeEnum.PayInvoice:
          case ButtonMovementTypeEnum.PayJackPot:
          case ButtonMovementTypeEnum.PayBets:
          case ButtonMovementTypeEnum.ExpensesMiscellaneous:
            await this.SubmitSessionMovementGenerateTicket(model);
            break;

          case ButtonMovementTypeEnum.IncreaseFunds:
          case ButtonMovementTypeEnum.ReduceFunds:
          case ButtonMovementTypeEnum.IncreaseFundsCashier:
          case ButtonMovementTypeEnum.ReduceFundsCashier:
            await this.SubmitSessionMovement(model);
            break;
          default:
            break;
        }
      }
    }
  }
}
