<div class="w-100 text-nowrap text-center" *ngIf="show">
  <table
    datatable
    [dtOptions]="dtOptions"
    [dtTrigger]="dtTrigger"
    (page)="onPageChange($event)"
    class="row-border hover w-100 table table-hover"
    dt-column-defs="dtColumnDefs"
    dt-instance="dtInstanceCallback"
    style="min-height: 90px">
    <tbody></tbody>
  </table>
</div>
