import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { NotificationService } from '../../../shared/notification/notification.service';
import { CashRegister } from '../../../viewmodels/CashRegister';
import { TicketState, TicketFamilyGroup } from '../../../viewmodels/Enums';
import { Receipt } from '../../../viewmodels/Receipt';
import { SessionManagmentViewModel } from '../../../viewmodels/SessioModel';
import { TicketMock } from '../../../viewmodels/TicketMock';
import { ReceiptModalComponent } from '../../receipt-modal/receipt-modal.component';
import { ValidationCodeComponent } from '../../validation-code/validation-code.component';

@Component({
  selector: 'app-session-cashout',
  templateUrl: './session-cashout.component.html',
  styleUrls: ['./session-cashout.component.css'],
})
export class SessionCashoutComponent implements OnInit {
  @ViewChild('invalidModal', { static: false }) invalidModal: ConfirmModalComponent;
  @ViewChild('paymentModal', { static: false }) paymentModal: ReceiptModalComponent;
  @ViewChild(ReceiptModalComponent) receiptModal: ReceiptModalComponent;
  @ViewChild(ValidationCodeComponent, { static: false }) validationInput: ValidationCodeComponent;
  @Input() turnId: number;
  loadData: boolean = false;
  currencySym: string;
  currencyCode: string;
  session: SessionManagmentViewModel = new SessionManagmentViewModel();

  headerTitle: string = '';
  body: string;

  paymentBody: string;

  total: number = 0;

  cardRead: boolean = false;

  formSearch: FormGroup;

  currentCashRegister: CashRegister = new CashRegister();

  lastTicket: TicketMock = null;
  ticket: TicketMock = new TicketMock();
  cuit: string;

  receipt: Receipt = new Receipt();

  tickets: TicketMock[] = [];
  stateEnum = TicketState;
  moment = moment;
  regex: RegExp = new RegExp('_', 'g');

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    public ticketService: TicketService,
    private modalService: NgbModal,
    private casinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    public sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.casinoService.getCasinoConfigData().subscribe((result) => {
      this.receipt.casino = result.data;
    });

    this.sessionService.GetSessionByTurnId(this.turnId).subscribe((result) => {
      this.session = result.data;
      this.loadData = true;
      if (this.session === null) {
        this.router.navigate(['/session-management']);
      }
      this.headerTitle = this.sessionService.getPageTitle(this.session);
      this.loadData = true;
    });
  }

  search() {
    if (this.formSearch.valid) {
      this.addTicket(this.formSearch.get('ticketCode').value);
    }
  }

  addTicket(codeTikcet) {
    if (this.formSearch.valid) {
      let isRepeated: boolean = false;
      this.ticketService.getTicketByValidatorCode(codeTikcet).subscribe((result) => {
        if (result.data != null) {
          this.ticket = result.data;
          this.tickets.forEach((element) => {
            if (element.validatorCode == this.ticket.validatorCode) {
              this.notificationService.showError(
                'El ticket con código ' + codeTikcet + ' ya se encuentra en la lista',
                'Error'
              );
              isRepeated = true;
            }
          });
          if (!isRepeated) {
            if (this.ticket.state == TicketState.Operable) {
              if (this.ticket.family.familyGroup.id == TicketFamilyGroup.Restricted_promotional) {
                this.body = 'El ticket con código ' + this.ticket.validatorCode + ' es promocional restrictivo.';
                this.invalidModal.openModalStatic();
                this.formSearch.get('ticketCode').disable();
              } else {
                this.tickets.splice(0, 0, this.ticket);
                this.lastTicket = this.ticket;
                this.total += this.lastTicket.formattedAmount;
                this.validationInput.commonInput.focusField();
              }
            } else {
              switch (this.ticket.state) {
                case TicketState.Anulado:
                  this.body = 'El ticket con código ' + this.ticket.validatorCode + ' está anulado.';
                  break;
                case TicketState.Canjeado:
                  this.body = 'El ticket con código ' + this.ticket.validatorCode + ' ya fue canjeado.';
                  break;
                case TicketState.Pendiente:
                  this.body = 'El ticket con código ' + this.ticket.validatorCode + ' esta pendiente.';
                  break;
                default:
                  this.body = 'El ticket esta repetido.';
                  break;
              }
              this.invalidModal.openModalStatic();
              this.formSearch.get('ticketCode').disable();
            }
          }
        } else {
          this.notificationService.showError('No se encontró el ticket de código ' + codeTikcet, 'Error');
        }
      });
    }
  }

  cleanTickets() {
    this.clean();
  }

  async payTickets() {
    if (this.tickets.length > 0) {
      this.receipt.cuid = Number(this.session.cashRegisterCuid);
      this.receipt.user = this.session.turnStartUser;
      this.paymentBody = 'Está a punto de pagar $' + this.total.toString() + '.';
      this.receipt.date = moment().format('DD/MM/yyyy, HH:mm:ss');
      this.receipt.tickets = this.tickets;
      this.receiptModal.openModalStatic();
    } else {
      this.notificationService.showError('Ingrese un ticket a pagar', 'Error');
    }
  }

  clean() {
    this.modalService.dismissAll();
    this.tickets = [];
    this.total = 0;
    this.formSearch.get('ticketCode').setValue('');
    this.formSearch.reset();
  }

  confirmedPayment() {
    this.ticketService.payTickets(this.tickets, this.turnId).subscribe((result) => {
      this.clean();
    });
  }

  cancel() {
    this.modalService.dismissAll();
  }

  async onFullInput(codeTikcet) {
    if (codeTikcet != null) {
      if (codeTikcet.length == 18) {
        this.addTicket(codeTikcet);
      }
    }
  }

  initForm() {
    this.formSearch = new FormGroup({
      ticketCode: new FormControl(null, [
        Validators.pattern('[0-9]*'),
        Validators.maxLength(18),
        Validators.minLength(18),
        Validators.required,
      ]),
    });
  }
  GoToCashFlow() {
    this.router.navigate(['/session-cashflow', this.turnId]);
  }
}
