import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { VoucherModalComponent } from 'src/app/common/components/business/voucher-modal/voucher-modal.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { Casino, DataConsts } from 'src/app/common/components/viewmodels/Casino';
import {
  CashableType,
  GenerationReasons,
  TicketFamily,
  TicketFamilyGroup,
  TicketState,
} from 'src/app/common/components/viewmodels/Enums';
import { Receipt, Voucher } from 'src/app/common/components/viewmodels/Receipt';
import { SessionManagmentViewModel } from 'src/app/common/components/viewmodels/SessioModel';
import { TicketMock } from 'src/app/common/components/viewmodels/TicketMock';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { SessionOldReportService } from 'src/app/services/session-report/session-report.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-casino-data-parameters',
  templateUrl: './edit-casino-parameters.component.html',
  styleUrls: ['./edit-casino-parameters.component.css'],
})
export class EditCasinoDataParametersComponent implements OnInit {
  // @ViewChild(VoucherModalComponent) voucherModal: VoucherModalComponent;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;

  casinoData: Casino = new Casino();
  formEdit: FormGroup;
  title: string = '';

  items: Array<BreadcrumbItem> = [];

  InputType = InputTypeEnum;
  body: string = '¿Está seguro que desea guardar esta configuración?';

  currencyCode: string;
  currencySym: string;
  importeLabel: string = 'Importe ';

  @ViewChild('myForm') myForm: NgForm;
  isinitForm = false;

  public static getName() {
    return 'CasinoDataEditComponent';
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private validationService: ValidationService,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.title = 'Editar Parámetros';

    this.isinitForm = true;

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
      this.importeLabel += this.currencySym;
    });

    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('establishment/about', 'Acerca de'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.casinoService.getCasinoConfigData().subscribe((result) => {
      if (result.data == null) {
        this.goToErrorPage();
        return;
      }
      this.casinoData = result.data;
      this.initForm();
    });
  }

  goToErrorPage(): void {
    this.router.navigate(['establishment/about']);
  }

  async onSubmit(form: NgForm) {
    if (this.formEdit.valid) {
      this.confirmModal.openModalStatic();
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  editConfiguration() {
    this.myForm.control.markAllAsTouched();

    if (this.formEdit.valid) {
      if (this.formEdit.controls['allowDataphone'].value) {
        this.casinoData.dataphoneMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['dataphoneAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.dataphoneMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowBill'].value) {
        this.casinoData.billMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['billAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.billMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowBet'].value) {
        this.casinoData.betMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['betAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.betMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowExpenses'].value) {
        this.casinoData.expensesMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['expensesAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.expensesMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowExternalJackpot'].value) {
        this.casinoData.externalJackpotMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['externalJackpotAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.externalJackpotMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowManualCode'].value) {
        this.casinoData.manualCodeMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['manualCodeAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.manualCodeMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowManualTicket'].value) {
        this.casinoData.manualTicketMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['manualTicketAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.manualTicketMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowImpactCode'].value) {
        this.casinoData.impactCodeMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['impactCodeAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.impactCodeMaxAmountWithPrecisionView = null;
      }
      if (this.formEdit.controls['allowImpactTicket'].value) {
        this.casinoData.impactTicketMaxAmountWithPrecisionView =
          Number(this.formEdit.controls['impactTicketAmount'].value) * DataConsts.precisionFactor;
      } else {
        this.casinoData.impactTicketMaxAmountWithPrecisionView = null;
      }

      this.casinoService.editCasinoConfigurationParameters(this.casinoData).subscribe((result) => {
        if (result.data != null) {
          // this.notificationService.showSuccess('Configuración guardada correctamente.', 'Éxito');
          this.router.navigate(['establishment/about']);
        } else {
          this.notificationService.showError('No se pudo guardar la configuración.', 'Error');
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private initForm() {
    this.formEdit = new FormGroup({
      dataphoneAmount: new FormControl(this.casinoData.formattedDataphoneMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      billAmount: new FormControl(this.casinoData.formattedBillMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      betAmount: new FormControl(this.casinoData.formattedBetMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      expensesAmount: new FormControl(this.casinoData.formattedExpensesMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      externalJackpotAmount: new FormControl(this.casinoData.formattedExternalJackpotMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      manualCodeAmount: new FormControl(this.casinoData.formattedManualCodeMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      manualTicketAmount: new FormControl(this.casinoData.formattedManualTicketMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      impactCodeAmount: new FormControl(this.casinoData.formattedImpactCodeMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      impactTicketAmount: new FormControl(this.casinoData.formattedImpactTicketMaxUnauthorizedAmount, [
        Validators.required,
        Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
        Validators.max(99999999),
      ]),
      allowDataphone: new FormControl(false),
      allowBill: new FormControl(false),
      allowBet: new FormControl(false),
      allowExpenses: new FormControl(false),
      allowExternalJackpot: new FormControl(false),
      allowManualCode: new FormControl(false),
      allowManualTicket: new FormControl(false),
      allowImpactCode: new FormControl(false),
      allowImpactTicket: new FormControl(false),
    });

    this.formEdit.controls['allowDataphone'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['dataphoneAmount'].enable();
      } else {
        this.formEdit.controls['dataphoneAmount'].setValue(null);
        this.formEdit.controls['dataphoneAmount'].disable();
      }
    });
    this.formEdit.controls['allowBill'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['billAmount'].enable();
      } else {
        this.formEdit.controls['billAmount'].setValue(null);
        this.formEdit.controls['billAmount'].disable();
      }
    });
    this.formEdit.controls['allowBet'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['betAmount'].enable();
      } else {
        this.formEdit.controls['betAmount'].setValue(null);
        this.formEdit.controls['betAmount'].disable();
      }
    });
    this.formEdit.controls['allowExpenses'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['expensesAmount'].enable();
      } else {
        this.formEdit.controls['expensesAmount'].setValue(null);
        this.formEdit.controls['expensesAmount'].disable();
      }
    });
    this.formEdit.controls['allowExternalJackpot'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['externalJackpotAmount'].enable();
      } else {
        this.formEdit.controls['externalJackpotAmount'].setValue(null);
        this.formEdit.controls['externalJackpotAmount'].disable();
      }
    });
    this.formEdit.controls['allowManualCode'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['manualCodeAmount'].enable();
      } else {
        this.formEdit.controls['manualCodeAmount'].setValue(null);
        this.formEdit.controls['manualCodeAmount'].disable();
      }
    });
    this.formEdit.controls['allowManualTicket'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['manualTicketAmount'].enable();
      } else {
        this.formEdit.controls['manualTicketAmount'].setValue(null);
        this.formEdit.controls['manualTicketAmount'].disable();
      }
    });
    this.formEdit.controls['allowImpactCode'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['impactCodeAmount'].enable();
      } else {
        this.formEdit.controls['impactCodeAmount'].setValue(null);
        this.formEdit.controls['impactCodeAmount'].disable();
      }
    });
    this.formEdit.controls['allowImpactTicket'].valueChanges.subscribe((value) => {
      if (value) {
        this.formEdit.controls['impactTicketAmount'].enable();
      } else {
        this.formEdit.controls['impactTicketAmount'].setValue(null);
        this.formEdit.controls['impactTicketAmount'].disable();
      }
    });
    this.formEdit.controls['allowDataphone'].setValue(this.casinoData.formattedDataphoneMaxUnauthorizedAmount != null);
    this.formEdit.controls['allowBill'].setValue(this.casinoData.formattedBillMaxUnauthorizedAmount != null);
    this.formEdit.controls['allowBet'].setValue(this.casinoData.formattedBetMaxUnauthorizedAmount != null);
    this.formEdit.controls['allowExpenses'].setValue(this.casinoData.formattedExpensesMaxUnauthorizedAmount != null);
    this.formEdit.controls['allowExternalJackpot'].setValue(
      this.casinoData.formattedExternalJackpotMaxUnauthorizedAmount != null
    );
    this.formEdit.controls['allowManualCode'].setValue(
      this.casinoData.formattedManualCodeMaxUnauthorizedAmount != null
    );
    this.formEdit.controls['allowManualTicket'].setValue(
      this.casinoData.formattedManualTicketMaxUnauthorizedAmount != null
    );
    this.formEdit.controls['allowImpactCode'].setValue(
      this.casinoData.formattedImpactCodeMaxUnauthorizedAmount != null
    );
    this.formEdit.controls['allowImpactTicket'].setValue(
      this.casinoData.formattedImpactTicketMaxUnauthorizedAmount != null
    );
  }
}
