import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CashRegisterService extends baseService {
  currentCashregister: CashRegister = new CashRegister();

  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getCashRegisters(
    dataTablesParameters: DataTableParameters
  ): Observable<ApiResult<DataTableResult<CashRegister>>> {
    return this.http
      .post<ApiResult<DataTableResult<CashRegister>>>(
        `${this.baseUrl}CashRegisters/GetCashRegisters`,
        dataTablesParameters
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CashRegister>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CashRegister>>(error))
      );
  }
  public getCashRegister(id: number): Observable<ApiResult<CashRegister>> {
    return this.http.get<ApiResult<CashRegister>>(`${this.baseUrl}CashRegisters/GetCashRegister?id=${id}`).pipe(
      map((x) => this.checkResult<CashRegister>(x)),
      catchError((error) => this.handleError<CashRegister>(error))
    );
  }

  public getCashRegistersCombo(cashRegisterTypeId?: number): Observable<ApiResult<IdTextModel[]>> {
    return this.http
      .get<ApiResult<IdTextModel[]>>(
        `${this.baseUrl}CashRegisters/GetCashRegistersCombo?cashRegisterTypeId=${cashRegisterTypeId}`
      )
      .pipe(
        map((x) => {
          var result = this.checkResult<IdTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<IdTextModel[]>(error))
      );
  }
  public getCashRegistersTypeCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}CashRegisters/GetCashRegistersTypeCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }
  // public getEgmCashRegistersCombo(): Observable<ApiResult<IdTextModel[]>> {
  //   return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}CashRegisters/GetEgmCashRegistersCombo`).pipe(
  //     map((x) => {
  //       var result = this.checkResult<IdTextModel[]>(x);
  //       return result;
  //     }),
  //     catchError((error) => this.handleError<IdTextModel[]>(error))
  //   );
  // }
  public getCurrentCashRegister(): CashRegister {
    var cashMachine = new CashRegister();
    cashMachine.name = 'Caja A';
    cashMachine.cuid = 1;
    cashMachine.secondaryName = 'Caja Blue';
    return cashMachine;
  }

  public setCurrentCashRegister(cashRegister: CashRegister): void {
    this.currentCashregister = cashRegister;
  }

  public createOrEdit(cashRegister: CashRegister): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}CashRegisters/CreateOrEdit`, cashRegister).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public deleteCashRegister(id: number): Observable<ApiResult<CashRegister>> {
    return this.http.delete<ApiResult<CashRegister>>(`${this.baseUrl}CashRegisters/DeleteCashRegister?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<CashRegister>(x);
        return result;
      }),
      catchError((error) => this.handleError<CashRegister>(error))
    );
  }
}
