import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CasinoService } from 'src/app/services/casino/casino.service';

@Injectable({
  providedIn: 'root',
})
export class ServerAuthorizeGuard implements CanActivate {
  constructor(public casinoService: CasinoService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ((route.component as any)?.getName) {
      var componentName = (route.component as any)?.getName();
      return this.casinoService.isComponentAuthorized(componentName).pipe(map((x) => x.data));
    } else {
      alert('error de configuracion de permisos');
    }
  }
}
