import { Casino } from './Casino';
import { TicketMock } from './TicketMock';

export class Receipt {
  tickets: TicketMock[];
  user: string;
  date: string;
  cuid: number;
  casino: Casino;

  constructor() {
    this.tickets = new Array<TicketMock>();
    this.casino = new Casino();
  }
}

export class Voucher {
  tickets: TicketMock[];
  user: string;
  date: string;
  casino: Casino;
  motivGeneracion: string;
  observaciones: string;

  family: string;
  egm: string;
  nTicket: string;

  constructor() {
    this.tickets = new Array<TicketMock>();
    this.casino = new Casino();
  }
}
