export class PrinterOptions {
  portName: string;
  baudRate: number;
  dataBits: number;
  stopBits: number;
  parity: string;
  xonXoff: boolean;
  isFake: Boolean;

  hasConnected: Boolean;
}
