import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiResult } from 'src/app/common/components/viewmodels/apiResult';
import { Dashboard } from 'src/app/common/components/viewmodels/Dashboard';
import { DashboardItem } from 'src/app/common/components/viewmodels/DashboardItem';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { EventsClasses, StateEgm } from 'src/app/common/components/viewmodels/Enums';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends baseService {
  graphicItems: Array<DashboardItem> = null;
  events = EventsClasses;

  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    public CurrencyPipe: CurrencyPipe,
    router: Router
  ) {
    super(toastr, router);
  }

  private initDashboard(): void {
    this.graphicItems = new Array<DashboardItem>();
    var offsetx: number = 350;
    for (let i = 0; i < 4; i++) {
      var item: DashboardItem = new DashboardItem();
      item.egm = new EGMMock();
      item.egm.uid = 1000 + i;
      item.position.x = i * 70 + offsetx;
      this.graphicItems.push(item);
    }

    offsetx = 430;
    var offsety: number = 70;
    var auxUid: number = 50;
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 2; j++) {
        var item: DashboardItem = new DashboardItem();
        item.egm = new EGMMock();
        item.egm.uid = auxUid;
        item.position.x = j * 50 + offsetx;
        item.position.y = offsety;
        this.graphicItems.push(item);
        auxUid++;
      }
      offsety += 50;
    }

    offsetx = 350;
    offsety += 20;
    for (let i = 0; i < 4; i++) {
      var item: DashboardItem = new DashboardItem();
      item.egm = new EGMMock();
      item.egm.uid = auxUid;
      item.position.x = i * 70 + offsetx;
      item.position.y = offsety;
      this.graphicItems.push(item);
      auxUid++;
    }

    // this.graphicItems[2].color = StateEgm.Alarma;
    // this.graphicItems[3].color = StateEgm.Libre;
    // this.graphicItems[9].color = StateEgm.Fuera_de_servicio;
    // this.graphicItems[10].color = StateEgm.Asistente;
    // this.graphicItems[14].color = StateEgm.Desconectado;

    this.graphicItems.forEach((element) => {
      element.innerHTML =
        +element.egm.uid +
        ' - Double Diamond, Ruleta <br>' +
        'Detalle: Stacker próximo a lleno (full) <br>' +
        'VC: ' +
        this.CurrencyPipe.transform(element.egm.creditValue, 'ARS', '$') +
        ' ' +
        'CA: ' +
        this.CurrencyPipe.transform(48.3, 'ARS', '$') +
        ' ' +
        'R: ' +
        this.CurrencyPipe.transform(166.6, 'ARS', '$');
    });
  }

  getCurrentDashboardItemsStatus(): Array<DashboardItem> {
    if (this.graphicItems == null || this.graphicItems.length == 0) {
      this.initDashboard();
    }
    return this.graphicItems;
  }

  public getDashboard(roomId: number): Observable<ApiResult<Dashboard>> {
    return this.http.get<ApiResult<Dashboard>>(`${this.baseUrl}Dashboards/GetDashboard?roomId=${roomId}`).pipe(
      map((x) => this.checkResult<Dashboard>(x)),
      catchError((error) => this.handleError<Dashboard>(error))
    );
  }

  public saveDashboard(dashboard: Dashboard): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Dashboards/SetDashboard`, dashboard).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public deleteDashboardItem(id: number): Observable<ApiResult<DashboardItem>> {
    return this.http.delete<ApiResult<DashboardItem>>(`${this.baseUrl}Dashboards/DeleteDashboardItem?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<DashboardItem>(x);
        return result;
      }),
      catchError((error) => this.handleError<DashboardItem>(error))
    );
  }

  saveCurrentDashboardItemsStatus(arr: Array<DashboardItem>): void {
    if (this.graphicItems == null || this.graphicItems.length == 0) {
      this.graphicItems = new Array<DashboardItem>();
    }
    this.graphicItems = arr;
  }

  public updateBackground(roomId: number, file: File): Observable<ApiResult<string>> {
    let formToPost = new FormData();

    if (file) formToPost.append('uploadedFile', file, file.name);

    return this.http
      .post<ApiResult<string>>(`${this.baseUrl}Dashboards/SetDashboardBackground?id=${roomId}`, formToPost)
      .pipe(
        map((x) => this.checkResult<string>(x)),
        catchError((error) => this.handleError<string>(error))
      );
  }
}
