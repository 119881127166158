<div class="row" [hidden]="this.currentTurn === 0">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <app-common-table [model]="data" [title]="this.title" (onSearch)="onSearch($event)"></app-common-table>
      </div>
      <div class="card-footer text-right">
        <button type="button" class="btn btn-primary mr-2" (click)="testPrinter()" style="min-width: 100px">
          Imprimir Test
        </button>
       
      </div>
    </div>
  </div>
</div>
