import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-list-cash-registers',
  templateUrl: './list-cash-registers.component.html',
  styleUrls: ['./list-cash-registers.component.css'],
})
export class ListCashRegistersComponent implements OnInit {
  public data: commonTableData<CashRegister>;

  @Input() isABMReport: boolean = true;
  items: Array<BreadcrumbItem> = [];
  title: string = 'Cajas';
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CashRegister>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  cashRegister: CashRegister = new CashRegister();

  parameter: tableStatus = null;

  public static getName() {
    return 'ListCashRegistersComponent';
  }
  constructor(
    public CashRegisterService: CashRegisterService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Configuración de Salón'));
    this.items.push(new BreadcrumbItem('', this.title));

    let service = this.CashRegisterService;

    this.data = new commonTableData();
    let paramCashMachine = this.paramService.getAndDeleteParameters();

    if (paramCashMachine != null) {
      this.data.searchText = paramCashMachine.searchString;
      this.data.pageLength = paramCashMachine.pageLength;
      this.data.currentPage = paramCashMachine.currentPage;
    }
    this.data.showEditButton = this.isABMReport;
    this.data.showDeleteButton = false; //this.isABMReport;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }

  setGridData(param1: boolean): void {
    let service = this.CashRegisterService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CashRegister>>> {
      return service.getCashRegisters(param);
    };

    this.data.columns = [
      { data: 'cuid', title: 'CUID' },
      { data: 'name', title: 'Nombre' },
      { data: 'secondaryName', title: 'Nombre Secundario' },
      { data: 'friendlyCashReigsterType', title: 'Tipo de Caja' },

      {
        data: 'cashMachine.muid',
        title: 'MCUID',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
    ];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['establishment/cash-registers/edit', row.id]);
  }

  public onDeleteButton(row): void {
    this.cashRegister = row;
    this.body = '¿Está seguro que desea eliminar la caja ' + this.cashRegister.name + '?';
    this.confirmModal.openModal();
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['establishment/cash-registers/create-cash-register']);
  }

  confirmDelete() {
    this.CashRegisterService.deleteCashRegister(this.cashRegister.id).subscribe((result) => {
      this.updateTable();
      this.notificationService.showSuccess(`Se borro la caja ${result.data.name}`, 'Atención!');
    });
  }
}
