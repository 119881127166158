<div
  *ngIf="type != InputType.checkbox && type != InputType.textarea && type != InputType.MAC && type != InputType.IP"
  [class.disabled]="control.disabled">
  <label *ngIf="label" class="form-control-sm mb-05">{{ label }}</label>

  <input
    #standard
    [type]="type"
    class="form-control form-control-sm min-height-36 commoninput"
    [class.is-invalid]="(control.touched || control.dirty || formSubmitted) && control.invalid"
    [placeholder]="placeholder ? placeholder : label"
    [formControl]="control"
    [id]="label"
    [maxlength]="maxlength"
    (keyup)="keyup($event)"
    (input)="emitChange($event)" />
  <div class="text-danger font-size-14" *ngIf="(control.touched || control.dirty || formSubmitted) && control.invalid">
    <div *ngIf="control.errors.required && label">{{ label }} es un campo obligatorio.</div>
    <div *ngIf="control.errors.required && !label">Es un campo obligatorio.</div>
    <div *ngIf="control.errors.maxlength && label">
      {{ label }} debe tener {{ control.errors.maxlength.requiredLength }} caracteres como máximo.
    </div>
    <div *ngIf="control.errors.maxlength && !label">
      Debe tener {{ control.errors.maxlength.requiredLength }} caracteres como máximo.
    </div>
    <div *ngIf="control.errors.minlength && label">
      {{ label }} debe tener {{ control.errors.minlength.requiredLength }} caracteres como mínimo.
    </div>
    <div *ngIf="control.errors.minlength && !label">
      Debe tener {{ control.errors.minlength.requiredLength }} caracteres como mínimo.
    </div>
    <div *ngIf="control.errors.email">Formato de email incorrecto.</div>
    <div *ngIf="control.errors.pattern && label">{{ label }} debe ser numérico.</div>
    <div *ngIf="control.errors.pattern && !label">Debe ser numérico.</div>
    <div *ngIf="control.errors.max">{{ label }} no puede ser mayor a {{ control.errors.max.max }}.</div>
    <div *ngIf="control.errors.min">{{ label }} no puede ser menor a {{ control.errors.min.min }}.</div>
  </div>
</div>

<div *ngIf="type == InputType.textarea" [class.disabled]="control.disabled">
  <label class="form-control-sm mb-05">{{ label }}</label>
  <textarea
    #textarea
    type="text"
    class="form-control form-control-sm min-height-36 commoninput"
    [class.is-invalid]="(control.touched || control.dirty || formSubmitted) && control.invalid"
    [placeholder]="label"
    [formControl]="control"
    [id]="label"
    rows="3"></textarea>
  <div class="text-danger font-size-14" *ngIf="(control.touched || control.dirty || formSubmitted) && control.invalid">
    <div *ngIf="control.errors.required">{{ label }} es un campo obligatorio.</div>
    <div *ngIf="control.errors.maxlength">
      {{ label }} debe tener {{ control.errors.maxlength.requiredLength }} caracteres como máximo.
    </div>
    <div *ngIf="control.errors.minlength">
      {{ label }} debe tener {{ control.errors.minlength.requiredLength }} caracteres como mínimo.
    </div>
    <div *ngIf="control.errors.email">Formato de email incorrecto.</div>
    <div *ngIf="control.errors.pattern">{{ label }} debe ser numérico.</div>
    <div *ngIf="control.errors.max">{{ label }} no puede ser mayor a {{ control.errors.max.max }}.</div>
    <div *ngIf="control.errors.min">{{ label }} no puede ser menor a {{ control.errors.min.min }}.</div>
  </div>
</div>

<div *ngIf="type == InputType.checkbox">
  <div class="form-check">
    <input
      #checkbox
      [type]="type"
      [attr.disabled]="control.disabled == true ? true : null"
      [class]="
        (control.touched || control.dirty || formSubmitted) && control.invalid
          ? 'form-check-input is-invalid commoninput'
          : 'form-check-input commoninput'
      "
      [id]="id"
      [formControl]="control"
      [name]="name"
      [checked]="control.value ? true : false"
      (change)="control.setValue($event.target.checked)" />
    <label class="form-check-label" [for]="label">{{ label }}</label>
  </div>
  <div class="text-danger font-size-14" *ngIf="(control.touched || control.dirty || formSubmitted) && control.invalid">
    <div *ngIf="control.errors.required">{{ label }} es un campo obligatorio.</div>
  </div>
</div>

<div *ngIf="type == InputType.MAC" [class.disabled]="control.disabled">
  <label class="form-control-sm mb-05">{{ label }}</label>
  <input
    [dropSpecialCharacters]="false"
    #mac
    type="text"
    class="form-control form-control-sm min-height-36 commoninput"
    [class.is-invalid]="(control.touched || control.dirty || formSubmitted) && control.invalid"
    [formControl]="control"
    [id]="label"
    placeholder="00:00:00:00:00:00"
    mask="AA:AA:AA:AA:AA:AA" />
  <div class="text-danger font-size-14" *ngIf="(control.touched || control.dirty || formSubmitted) && control.invalid">
    <div *ngIf="control.errors.required">{{ label }} es un campo obligatorio.</div>
    <div *ngIf="control.errors.maxlength">
      {{ label }} debe tener {{ control.errors.maxlength.requiredLength }} caracteres como máximo.
    </div>
    <div *ngIf="control.errors.minlength">
      {{ label }} debe tener {{ control.errors.minlength.requiredLength }} caracteres como mínimo.
    </div>
    <div *ngIf="control.errors.mask">{{ label }} debe tener 12 valores hexadecimales.</div>
    <div *ngIf="control.errors.pattern">{{ label }} debe ser hexadecimal.</div>
  </div>
</div>

<div *ngIf="type == InputType.IP" [class.disabled]="control.disabled">
  <label class="form-control-sm mb-05">{{ label }}</label>
  <input
    [dropSpecialCharacters]="false"
    #ip
    type="text"
    class="form-control form-control-sm min-height-36 commoninput"
    [class.is-invalid]="(control.touched || control.dirty || formSubmitted) && control.invalid"
    [formControl]="control"
    [id]="label"
    placeholder="192.168.0.1"
    mask="IP" />
  <div class="text-danger font-size-14" *ngIf="(control.touched || control.dirty || formSubmitted) && control.invalid">
    <div *ngIf="control.errors.required">{{ label }} es un campo obligatorio.</div>
    <div *ngIf="control.errors.maxlength">
      {{ label }} debe tener {{ control.errors.maxlength.requiredLength }} caracteres como máximo.
    </div>
    <div *ngIf="control.errors.minlength">
      {{ label }} debe tener {{ control.errors.minlength.requiredLength }} caracteres como mínimo.
    </div>
    <div *ngIf="control.errors.mask">{{ label }} debe tener formato de dirección IP.</div>
    <div *ngIf="control.errors.pattern">{{ label }} debe ser numérico.</div>
  </div>
</div>
