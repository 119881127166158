import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { CMComboComponent } from 'src/app/common/business/cm-combo/cm-combo.component';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { CashRegisterComboComponent } from 'src/app/common/components/business/cash-register-combo/cash-register-combo.component';
import { EgmProfitParameters } from 'src/app/common/components/business/egm-profit/egm-profit.component';
import { GametypesComboComponent } from 'src/app/common/components/business/gametypes-combo/gametypes-combo.component';
import { ManufacturersComboComponent } from 'src/app/common/components/business/manufacturers-combo/manufacturers-combo.component';
import { ModelsComboComponent } from 'src/app/common/components/business/models-combo/models-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CalculatedCountersViewModel } from 'src/app/common/components/viewmodels/CalculatedCountersViewModel';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { CountersStatus, TicketIssedMachine } from 'src/app/common/components/viewmodels/Enums';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { TicketViewModel } from 'src/app/common/components/viewmodels/TicketReplacementViewModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { CountersService } from 'src/app/services/counters/counters.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-accepted-tickets',
  templateUrl: './accepted-tickets.component.html',
  styleUrls: ['./accepted-tickets.component.css'],
})
export class AcceptedTicketsComponent implements OnInit {
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(CashRegisterComboComponent) cashRegisterCombo: CashRegisterComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CalculatedCountersViewModel>;

  totalAmount: number = 0;
  ticketQuantity: number = -1;

  viewCombo: Array<IdTextModel> = [];
  locationCombo: Array<IdTextModel> = [];

  title: string = 'Tickets Aceptados';

  items: Array<BreadcrumbItem> = [];
  issuedBy: Array<IdTextModel> = [];

  public data: commonTableData<TicketViewModel>;

  formSearch: FormGroup;
  paramStatus: boolean;
  dateRange: string;
  showEgm: boolean;
  showCR: boolean;
  currencySym: string;
  currencyCode: string;

  public static getName() {
    return 'AcceptedTicketsComponent';
  }
  constructor(
    private router: Router,
    private paramService: ParametersService<EgmProfitParameters>,
    private percentPipe: PercentPipe,
    public ticketService: TicketService,
    public currencyPipe: CurrencyPipe,
    public casinoService: CasinoService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.initForm();

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.issuedBy = this.ticketService.getTicketIssuedMachineBy();

    let param = this.paramService.getAndDeleteParameters();

    this.initGrid();

    if (param != null) {
      // this.formSearch.get('cmMuid').setValue([param.idGameType]);
      this.formSearch.get('egmUid').setValue([param.idEgm]);

      this.formSearch.get('from').setValue(param.from);
      this.formSearch.get('to').setValue(param.to);
      // this.onSubmit();
      this.paramStatus = true;
    }
  }

  changedIssuedBy() {
    console.log(TicketIssedMachine[TicketIssedMachine.EGM]);
    if (this.formSearch.get('issuedBy').value == TicketIssedMachine.EGM) {
      this.showCR = false;
      this.showEgm = true;
      this.formSearch.get('cashRegisterIds').setValue([]);
    } else {
      this.showCR = true;
      this.showEgm = false;
      this.formSearch.get('egmUid').setValue([]);
    }
  }

  ngAfterViewInit(): void {
    if (this.paramStatus) {
      this.onSubmit();
    }
  }

  public onCustomButton(data: any): void {
    console.log('test');
    this.router.navigate(['/tito/ticket-history/' + data.row.validatorCode]);
  }

  initGrid(): void {
    this.data = new commonTableData();

    let button: commonTableCustomButton = new commonTableCustomButton();
    button.icon = 'fa-file-alt';
    button.columntitle = 'Ticket';
    button.tooltip = 'Ir a historial';
    button.order = 0;

    this.data.commonTableCustomButton.push(button);
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    const that = this;

    this.data.columns = [
      { data: 'validatorCode', title: 'Código' },
      { data: 'ticketNumber', title: 'N° de ticket' },
      {
        data: 'creationDateFormattedDate',
        title: 'Fecha ticket',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },

      { data: 'familyDescription', title: 'Familia' },

      {
        data: 'formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },

      {
        data: 'eventDateFormattedDate',
        title: 'Fecha Canjeado',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'uidTarget',
        title: 'EGM Destino',
        render: function (data, type, row) {
          return data != 0 ? data : '';
        },
      },
      {
        data: 'cashRegisterNameTarget',
        title: 'Caja Destino',
        render: function (data, type, row) {
          return data != 0 ? data : '';
        },
      },

      {
        data: 'uid',
        title: 'EGM Origen',
        render: function (data, type, row) {
          return data != 0 ? data : '';
        },
      },
      {
        data: 'cashRegisterName',
        title: 'Caja Origen',
        render: function (data, type, row) {
          return data != 0 ? data : '';
        },
      },

      { data: 'manufacturer', title: 'Fabricante Origen' },
      { data: 'model', title: 'Modelo Origen' },
    ];
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.ticketService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<TicketViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getAcceptedTicketReport(DataTableFilters);
    };
  }

  onSearch(status: tableStatus) {
    //  this.parameter.status = status;
    this.totalAmount = this.table.decimalData;
    this.ticketQuantity = this.table.intData;
  }

  updateTable(filters: MultiselectFilters) {
    this.totalAmount = 0;
    this.ticketQuantity = 0;

    this.setGridData(filters);
    this.filterString();
    this.table.reload();
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl([]),
      issuedBy: new FormControl(),
      //cmMuid: new FormControl([]),
      cashRegisterIds: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;

    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      //sino hay egm seleccionada pero el combo dice egm pongo todas

      if (
        this.formSearch.get('issuedBy').value == null ||
        this.formSearch.get('issuedBy').value == TicketIssedMachine.EGM
      ) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }

    if (this.formSearch.get('cashRegisterIds').value.length > 0) {
      filters.cashRegisters = this.formSearch.get('cashRegisterIds').value;
    } else {
      //sino hay egm seleccionada pero el combo dice egm pongo todas

      if (
        this.formSearch.get('issuedBy').value == null ||
        this.formSearch.get('issuedBy').value == TicketIssedMachine.Caja
      ) {
        filters.cashRegisters = this.cashRegisterCombo.getCashRegistersComboAsIdArray();
      }
    }

    //else {
    // if (!this.formSearchNull) {
    //   filters.egms = this.egmsCombo.getEgmComboAsIdArray();
    // }
    //}
    return filters;
  }

  onSubmit() {
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();

    this.updateTable(this.getFilters());
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }

    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters;
    }

    if (this.formSearch.get('cashRegisterIds').value.length > 0) {
      aux += ' CashRegisters: ' + this.cashRegistersFilters;
    }

    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get cashRegistersFilters(): string {
    let combo = this.cashRegisterCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('cashRegisterIds').value.length; index++) {
      const element = this.formSearch.get('cashRegisterIds').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('cashRegisterIds').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }
}
