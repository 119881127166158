import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { hasRequiredField } from '../hasRequiredField';

@Component({
  selector: 'app-common-input',
  templateUrl: './common-input.component.html',
  styleUrls: ['./common-input.component.css'],
})
export class CommonInputComponent implements OnInit {
  @Input() control: FormControl;
  @Input() maxlength: number = 100;
  @Input() otro: string;
  @Input() label: string;
  @Input() placeholder?: string | null = null;
  @Input() name: string;
  @Input() formSubmitted: boolean;
  @Input() type: InputTypeEnum = InputTypeEnum.text;
  @ViewChild('standard') standard: ElementRef;
  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild('checkbox') checkbox: ElementRef;
  // @Input('autocomplete') autocomplete: string = "on";
  @Output() keyUpEvent = new EventEmitter();
  @Output() inputFull = new EventEmitter();

  InputType = InputTypeEnum;
  onChange = (_: any) => {};
  id: string = 'checkId';

  private disabledtest: boolean = true;
  constructor() {}

  ngOnInit(): void {
    if (this.type == this.InputType.checkbox) {
      if (this.name == null || this.name == '') {
        this.name = this.label;
        this.id = this.label;
      } else {
        this.id = this.name;
      }
    }

    if (!Object.values(this.InputType).includes(this.type)) {
      this.type = this.InputType.text;
    }

    // if (this.maxLength != null) {
    //   this.maxL = this.maxLength;
    // }

    if (this.type == this.InputType.MAC) {
      this.control.valueChanges.subscribe((x) => {
        this.control.patchValue(this.control.value.toUpperCase(), { emitEvent: false });
      });
    }
  }

  public focusField() {
    if (this.type == this.InputType.checkbox) {
      this.checkbox.nativeElement.focus();
    }
    if (this.type == this.InputType.textarea) {
      this.textarea.nativeElement.focus();
    } else {
      this.standard.nativeElement.focus();
    }
  }

  keyup(event: any) {
    if (this.control.value != null) {
      var x = this.control.value;
      if (x.length == 18) {
        this.inputFull.emit();
      } else {
        this.keyUpEvent.emit(event);
      }
    } else {
      this.keyUpEvent.emit(event);
    }
  }

  emitChange(selection: any) {
    // this.inputFull.emit(selection);
  }
}

export enum InputTypeEnum {
  text = 'text',
  numeric = 'tel',
  datetime_local = 'datetime-local', //No soportado por firefox
  date = 'date',
  time = 'time',
  checkbox = 'checkbox',
  textarea = 'textarea',
  number = 'number',
  MAC = 'MAC',
  IP = 'IP',
  password = 'password',
}
