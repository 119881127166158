import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { commonTableData } from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Ticket } from 'src/app/common/components/viewmodels/Ticket';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-authorized-tickets',
  templateUrl: './authorized-tickets.component.html',
  styleUrls: ['./authorized-tickets.component.css'],
})
export class AuthorizedTicketsComponent implements OnInit {
  usersCombo: Array<IdTextModel> = [];

  items: Array<BreadcrumbItem> = [];

  title: string = 'Reporte de Tickets Autorizados';

  date: moment.Moment = moment(new Date());
  currencySym: string;
  currencyCode: string;

  public data: commonTableData<Ticket>;

  formSearch: FormGroup;
  searchMade: boolean;

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    private TicketService: TicketService,
    private UsersService: UsersService,
    private activatedRoute: ActivatedRoute,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    const that = this;

    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));

    //Armo los combos de busqueda

    this.fillCombos();

    this.initForm();

    let service = this.TicketService;

    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Ticket>>> {
      return service.getAuthorizedtickets(param);
    };

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.data.columns = [
      { data: 'validationDate', title: 'Fecha Autorización' },
      { data: 'user', title: 'Usuario' },
      { data: 'id', title: 'Nº Ticket' },
      { data: 'uid', title: 'EGM' },
      { data: 'cashMachine.cuid', title: 'Caja' },
      { data: 'family', title: 'Familia' },
      { data: 'status', title: 'Estado' },
      {
        data: 'formattedAmount',
        title: 'Monto',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
    ];
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      users: new FormControl(),
      from: new FormControl(),
    });
  }

  onSubmit() {
    console.log(this.formSearch);
    this.searchMade = true;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.formSearch.get('egmUid').value,
        from: this.formSearch.get('from').value,
        to: this.formSearch.get('to').value,
        dayStatus: this.formSearch.get('dayStatus').value,
        rooms: this.formSearch.get('rooms').value,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
      skipLocationChange: false,
    };

    this.router.navigate([], navigationExtras);
  }

  private fillCombos() {
    this.UsersService.getUsersCombo().subscribe((result) => {
      this.usersCombo = result.data;
    });
  }
}
