import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';
import { GuidTextModel } from '../../shared/IdTextModel';

@Component({
  selector: 'manufacturers-combo',
  templateUrl: './manufacturers-combo.component.html',
  styleUrls: ['./manufacturers-combo.component.css'],
})
export class ManufacturersComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  combo: Array<GuidTextModel> = [];
  label: string = 'Fabricante';
  @Output() valueChanged = new EventEmitter();

  constructor(private ManufacturersService: ManufacturerService) {}

  ngOnInit(): void {
    this.fillCombo();
    if (this.multiselect) this.label = 'Fabricantes';
  }

  public async fillCombo() {
    var result = await this.ManufacturersService.getManufacturersCombo().toPromise();
    this.combo = result.data;
  }

  public getCombo() {
    return this.combo;
  }
}
