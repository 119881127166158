import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-daily-analysis',
  templateUrl: './daily-analysis.component.html',
  styleUrls: ['./daily-analysis.component.css'],
})
export class DailyAnalysisComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];

  title: string = 'Ganancia Diaria';

  public static getName() {
    return 'DailyAnalysisComponent';
  }
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Contadores'));
    this.items.push(new BreadcrumbItem('', this.title));
  }
}
