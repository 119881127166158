import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { CashRegisterComboComponent } from 'src/app/common/components/business/cash-register-combo/cash-register-combo.component';
import { UsersComboComponent } from 'src/app/common/components/business/users-combo/users-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { TicketFamily, TicketFamilyGroup } from 'src/app/common/components/viewmodels/Enums';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { TicketMock } from 'src/app/common/components/viewmodels/TicketMock';
import { TicketTracker } from 'src/app/common/components/viewmodels/TicketTracker';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-expired-tickets',
  templateUrl: './expired-tickets.component.html',
  styleUrls: ['./expired-tickets.component.css'],
})
export class ExpiredTicketsComponent implements OnInit {
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(UsersComboComponent) usersCombo: UsersComboComponent;
  @ViewChild(CashRegisterComboComponent) cashCombo: CashRegisterComboComponent;

  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<TicketTracker>;
  items: Array<BreadcrumbItem> = [];
  currencySym: string;
  currencyCode: string;
  title: string = 'Tickets Expirados por Origen';
  public data: commonTableData<TicketMock>;
  formSearch: FormGroup;
  TicketFamily = TicketFamily;
  dateRange: string;

  public static getName() {
    return 'ExpiredTicketsComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    public TicketService: TicketService,
    public CashMachinesService: CashRegisterService,
    private activatedRoute: ActivatedRoute,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.initForm();
    this.initGrid();
  }

  initGrid() {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;

    const that = this;

    this.data.columns = [
      { data: 'validatorCode', title: 'Código' },
      {
        data: 'formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
      {
        data: 'expirationDate',
        title: 'Fecha VTO',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'family.familyGroupId',
        title: 'Familia',
        render: function (data: number, type, row) {
          return data != null ? TicketFamilyGroup[data].replace(new RegExp('_', 'g'), ' ') : '-';
        },
      },

      {
        data: 'creationEgm.uid',
        title: 'EGM',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'cashRegisterName',
        title: 'Caja',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'userIdName',
        title: 'Usuario',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'creationDate',
        title: 'Fecha de Emisión',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
    ];
  }
  setGridData(filters: MinimalFilters) {
    let service = this.TicketService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<TicketMock>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getExpiredTickets(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }

  initForm() {
    this.formSearch = new FormGroup({
      to: new FormControl(),
      from: new FormControl(),
      uid: new FormControl(),
      cuid: new FormControl(),
      user: new FormControl(),
    });
  }

  onSubmit() {
    if (this.formSearch.valid) {
      this.updateTable();
    }
  }

  updateTable() {
    this.setGridData(this.getFilters());
    this.filterString();
    this.table.reload();
  }

  getFilters(): MinimalFilters {
    var filters = new MinimalFilters();
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;
    filters.egm = this.formSearch.get('uid').value;
    filters.cuid = this.formSearch.get('cuid').value;
    filters.user = this.formSearch.get('user').value;
    return filters;
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }

    if (this.formSearch.get('uid').value != null) {
      let combo = this.egmsCombo.getCombo();
      var egm = combo.find((e) => e.id == this.formSearch.get('uid').value).text;
      aux += ' Egms: ' + egm + ' - ';
    }
    if (this.formSearch.get('user').value != null) {
      let combo = this.usersCombo.getCombo();
      var user = combo.find((e) => e.id == this.formSearch.get('user').value).text;
      aux += ' Usuario: ' + user + ' - ';
    }

    if (this.formSearch.get('cuid').value != null) {
      let combo = this.cashCombo.getCombo();
      var caja = combo.find((e) => e.id == this.formSearch.get('cuid').value).text;
      aux += ' Caja: ' + caja + ' - ';
    }

    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }
}
