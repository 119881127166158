import { AfterViewChecked, Component, Injectable, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment-timezone';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';

import { CasinoService } from './services/casino/casino.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
@Injectable()
export class AppComponent implements OnInit {
  title = 'app';

  constructor(public casinoService: CasinoService) {}

  ngOnInit() {
    this.casinoService.getTimeZone().subscribe((result) => {
      if (result?.data?.val) {
        moment.tz.setDefault(result.data.val);
        dayjs.extend(timezone);
        dayjs.tz.setDefault(result.data.val);
      }
    });
  }
}
