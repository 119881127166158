import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResult } from 'src/app/common/components/viewmodels/apiResult';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class MocksService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public createData(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Mocks/AddMocks`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public deleteData(): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>(`${this.baseUrl}Mocks/DeleteMocks`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }
}
