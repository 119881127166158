<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange != null"> - {{ dateRange }} </span>
              </h3>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [controlFrom]="formSearch.get('from')"
                    [controlTo]="formSearch.get('to')"
                    [label]="'Rango Fecha de Expiración'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm">
                  <egm-combo [allowClear]="true" [control]="formSearch.get('uid')"></egm-combo>
                </div>
                <div class="col-12 col-sm">
                  <users-combo [allowClear]="true" [control]="formSearch.get('user')"></users-combo>
                </div>
                <div class="col-12 col-sm">
                  <cash-register-combo [allowClear]="true" [control]="formSearch.get('cuid')"> </cash-register-combo>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table [model]="data" [excel]="true" [title]="this.title"></app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
