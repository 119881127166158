import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  commonTableData,
  commonTableCustomButton,
  tableStatus,
  CommonTableComponent,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Manufacturer } from 'src/app/common/components/viewmodels/Manufacturer';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-list-manufacturers',
  templateUrl: './list-manufacturers.component.html',
  styleUrls: ['./list-manufacturers.component.css'],
})
export class ListManufacturersComponent implements OnInit {
  public data: commonTableData<Manufacturer>;

  items: Array<BreadcrumbItem> = [];
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<Manufacturer>;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  body: string;
  manufacturer: Manufacturer = new Manufacturer();

  parameter: tableStatus = null;

  public static getName() {
    return 'ListManufacturersComponent';
  }
  constructor(
    public manufacturerService: ManufacturerService,
    private router: Router,
    public notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>
  ) {}

  public onEditButton(row): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/manufacturers/edit-manufacturer', row.id]);
  }

  public onCreateButton(): void {
    this.paramService.saveParameters(this.parameter);
    this.router.navigate(['machines/manufacturers/create-manufacturer']);
  }

  public onDeleteButton(row): void {
    this.manufacturer = row;
    this.body = '¿Está seguro que desea eliminar el fabricante ' + this.manufacturer.name + '?';
    this.confirmModal.openModal();
  }

  confirmDelete() {
    this.manufacturerService.deleteManufacturer(this.manufacturer.id).subscribe((result) => {
      this.updateTable();
    });
  }

  setGridData(param1: boolean): void {
    let service = this.manufacturerService;
    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<Manufacturer>>> {
      return service.getManufacturers(param);
    };

    this.data.columns = [{ data: 'name', title: 'Fabricante' }];
  }

  updateTable() {
    this.setGridData(true);
    this.table.reload();
  }

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Máquinas'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Fabricantes'));

    let paramManu = this.paramService.getAndDeleteParameters();

    this.data = new commonTableData();
    if (paramManu != null) {
      this.data.searchText = paramManu.searchString;
      this.data.pageLength = paramManu.pageLength;
      this.data.currentPage = paramManu.currentPage;
    }
    this.data.showEditButton = true;
    this.data.showDeleteButton = true;
    this.data.showCreateButton = false;
    this.data.searching = true;
    this.data.paging = true;
    this.setGridData(true);
  }

  onSearch(status: tableStatus) {
    this.parameter = status;
  }
}
