import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-cashRegister-report',
  templateUrl: './cashRegister-report.component.html',
  styleUrls: ['./cashRegister-report.component.css'],
})
export class CashRegisterReportComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];
  title: string = 'Reporte de Cajas';

  public static getName() {
    return 'CashRegisterReportComponent';
  }
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }
}
