import { DateRange } from './DateRange';

export class MultiselectFilters extends DateRange {
  manufacturers: number[];
  models: number[];
  gametypes: number[];
  rooms: number[];
  egms: number[];
  cashMachines: number[];
  Turns: number[];
  cashRegisters: number[];
  movements: number[];
  users: string[];
  modifiedUsers: string[];
  closedPeriod: boolean;
  includeDeleted: boolean;
  date: moment.Moment;
  creditValue: string[];

  toConciliate: boolean;
  ToCollect: boolean;

  //turno que sucede la busqueda
  currentTurn: number;
  movementTypes: number[];
  movementsId: number[];

  constructor() {
    super();
    this.gametypes = [];
    this.manufacturers = [];
    this.models = [];
    this.rooms = [];
    this.egms = [];
    this.cashMachines = [];
    this.Turns = [];
    this.cashRegisters = [];
    this.users = [];
    this.modifiedUsers = [];
    this.creditValue = [];
    this.movementTypes = [];
    this.movementsId = [];
  }
}

export class MinimalFilters extends DateRange {
  manufacturer?: number;
  model?: number;
  gametype?: number;
  room?: number;
  egm?: number;
  validatorCode?: string;
  cuid?: number;
  user: string;
  egms: number[];
  cashMachines: number[];
  events: number[];
  egmOnline: boolean;
  priorityOnly: boolean;
  closedPeriod: boolean;
  session?: number;
  cashRegister?: number;
  ticketFamily?: number;
  ticketType?: number;
  ticketStatus?: number;
  ticketTrackerStatus?: number;
  cancellationReason?: number;
  ticketFamilies?: number[];
  issuedBy?: number;
  generationReason?: number[];
  date: moment.Moment;
  includeDeleted: boolean;
}
