import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { EGM } from 'src/app/common/components/viewmodels/EGM';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { GametypesService } from 'src/app/services/gametypes/gametypes.service';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { ModelService } from 'src/app/services/model/model.service';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { EgmProfitParameters } from 'src/app/common/components/business/egm-profit/egm-profit.component';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { GametypesComboComponent } from 'src/app/common/components/business/gametypes-combo/gametypes-combo.component';
import { ModelsComboComponent } from 'src/app/common/components/business/models-combo/models-combo.component';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-detailed-counters-report',
  templateUrl: './detailed-counters-report.component.html',
  styleUrls: ['./detailed-counters-report.component.css'],
})
export class DetailedCountersReportComponent {
  public static getName() {
    return 'DetailedCountersReportComponent';
  }
}

// export class DetailedCountersReportComponent implements OnInit {

//   @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<EGM>;
//   @ViewChild(ModelsComboComponent) modelsCombo: ModelsComboComponent;
//   @ViewChild(GametypesComboComponent) gametypesCombo: GametypesComboComponent;
//   @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;

//   egmCombo: Array<IdTextModel> = [];

//   items: Array<BreadcrumbItem> = [];

//   public data: commonTableData<EGM>;

//   formSearch: FormGroup;

//   title: string = "Reporte de Contadores Detallados";

//   parameter: EgmProfitParameters = null;
//   dateRange: string;

//   constructor(private router: Router,
//     public notificationService: NotificationService,
//     public RoomService: RoomServiceService,
//     public EgmService: EgmServiceService,
//     public ManufacturersService: ManufacturerService,
//     public ModelService: ModelService,
//     public gameTypesService: GametypesService,
//     private activatedRoute: ActivatedRoute,
//     private paramService: ParametersService<EgmProfitParameters>
//   ) { }

//   ngOnInit(): void {

//     this.items.push(new BreadcrumbItem('', 'Listados'));
//     this.items.push(new BreadcrumbItem(this.router.url, 'Contadores'));
//     //Armo los combos de busqueda
//     this.fillCombos();
//     this.initForm();
//     this.initGrid();
//   }

//   initGrid(): void {

//     this.data = new commonTableData();

//     let paramDetailedCountersReport = this.paramService.getAndDeleteParameters();

//     if (paramDetailedCountersReport != null) {
//       this.formSearch.get('egmUid').setValue(paramDetailedCountersReport.uids);
//       this.formSearch.get('from').setValue(paramDetailedCountersReport.from);
//       this.formSearch.get('to').setValue(paramDetailedCountersReport.to);
//       this.parameter = new EgmProfitParameters();
//       this.parameter = paramDetailedCountersReport;
//     }

//     this.data.showEditButton = false;
//     this.data.showDeleteButton = false;
//     this.data.showCreateButton = false;
//     this.data.searching = false;
//     this.data.paging = true;
//     this.data.loadFirstTime = false;

//     this.data.columns = [
//       {
//         data: 'counters.status', title: 'Habilitado',
//         "render": function (data, type, row) {
//           return (data == true) ? 'Si' : 'No';
//         }
//       },
//       { data: 'date', title: 'Fecha' },
//       { data: 'uid', title: 'EGM' },
//       { data: 'manufacturer', title: 'Fabricante' },
//       { data: 'model', title: 'Modelo' },
//       { data: 'egmConfig.formattedDenomination', title: 'Denominación' },
//       { data: 'counters.coinIn', title: 'Coin In' },
//       { data: 'counters.coinOut', title: 'Coin Out' },
//       { data: 'counters.coinDiff', title: 'Coin Diff' },
//       { data: 'counters.totalDiff', title: 'Total Diff' },
//       { data: 'counters.mppp', title: 'APCC' },
//       { data: 'counters.mppp', title: 'MPPP' },
//       { data: 'counters.mpbp', title: 'MPBP' },
//       { data: 'counters.mpbp', title: 'APJ' },
//       { data: 'counters.appp', title: 'APPP' },
//       { data: 'counters.apebp', title: 'APEBP' },
//       { data: 'counters.apebp', title: 'Total Jackpot' },
//       { data: 'counters.ticketIn', title: 'Ticket In' },
//       { data: 'counters.ticketOut', title: 'Ticket Out' },
//       { data: 'counters.ticketDiff', title: 'Ticket Diff' },
//       { data: 'counters.bills', title: 'Bills' },
//       { data: 'counters.mppp', title: 'True Coin In' },
//       { data: 'counters.mppp', title: 'True Coin Out' },
//       { data: 'counters.mppp', title: 'Coin Drop' },
//       { data: 'counters.aftIn', title: 'EFT In' },
//       { data: 'counters.aftIn', title: 'WAT In' },
//       { data: 'counters.aftOut', title: 'WAT Out' },
//       { data: 'counters.gamesPlayed', title: 'Juegos Jugados' },
//       { data: 'counters.jackpotQ', title: 'Jackpot Q' },
//       { data: 'counters.doorQ', title: 'Door Q' },
//       { data: 'counters.billM1Q', title: 'Bill M1 Q' },
//       { data: 'counters.billM2Q', title: 'Bill M2 Q' },
//       { data: 'counters.billM3Q', title: 'Bill M3 Q' },
//       { data: 'counters.billM4Q', title: 'Bill M4 Q' },
//       { data: 'counters.billM5Q', title: 'Bill M5 Q' },
//       { data: 'counters.billM6Q', title: 'Bill M6 Q' },
//       { data: 'counters.billM7Q', title: 'Bill M7 Q' },
//       { data: 'counters.billM8Q', title: 'Bill M8 Q' },
//       { data: 'counters.billTotalQ', title: 'Bill Total Q' },
//       { data: 'counters.billTotalMoney', title: 'Bill Total Money' },
//       { data: 'counters.theoPay', title: 'Theo Pay' },
//       { data: 'counters.devPay', title: 'Dev Pay' },
//       { data: 'counters.retHold', title: 'Ret Hold' },
//     ];
//   }
//   getModels() {
//     this.formSearch.get('models').setValue([]);
//     this.modelsCombo.fillComboWithManufacturersId(this.formSearch.get('manufacturers').value);
//     this.getGameTypes();
//     this.updateEgms()
//   }

//   getGameTypes() {
//     this.formSearch.get('gameTypes').setValue([]);
//     this.gametypesCombo.fillComboWithModelsId(this.formSearch.get('models').value);
//     this.updateEgms();
//   }

//   updateEgms() {
//     this.formSearch.get('egmUid').setValue([]);
//     if (this.formSearchNull) {
//       this.egmsCombo.fillCombo();
//     }
//     else {
//       var filters: MultiselectFilters = new MultiselectFilters();
//       filters.manufacturers = this.formSearch.get('manufacturers').value;
//       filters.models = this.formSearch.get('models').value;
//       filters.gametypes = this.formSearch.get('gameTypes').value;
//       filters.egms = this.formSearch.get('egmUid').value;
//       this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
//     }
//   }
//   get formSearchNull(): boolean {
//     return this.formSearch.get('gameTypes').value.length == 0 &&
//       this.formSearch.get('models').value.length == 0 &&
//       this.formSearch.get('manufacturers').value.length == 0
//   }

//   goToDailyAnalysis() {
//     this.paramService.saveParameters(this.parameter);
//     this.router.navigateByUrl('audit/daily-profit');
//   }

//   onSearch(status: tableStatus) {
//     if (this.parameter != null) {
//       this.parameter.status = status;
//     }
//   }

//   setGridData(filters: MultiselectFilters): void {

//     let service = this.EgmService;

//     this.data.getData = function (
//       param: any
//     ): Observable<ApiResult<DataTableResult<EGM>>> {

//       return service.getEGMs(param, true);
//     };
//     this.dateRange = moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() + ' - ' + moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
//   }

//   updateTable(filters: MultiselectFilters) {
//     this.setGridData(filters);
//     this.table.reload();
//   }

//   initForm() {
//     this.formSearch = new FormGroup({
//       'egmUid': new FormControl([]),
//       'manufacturers': new FormControl([]),
//       'models': new FormControl([]),
//       'gameTypes': new FormControl([]),
//       'from': new FormControl(),
//       'to': new FormControl(),
//     });
//   }

//   onSubmit() {
//     if (this.formSearch.valid) {
//       if (this.formSearch.get('egmUid').value.length > 0) {
//         //Mando las egm seleccionadas
//         var filters: MultiselectFilters = new MultiselectFilters();
//         filters.egms = this.formSearch.get('egmUid').value;
//         this.updateTable(filters);
//       }
//       else {
//         if (this.formSearchNull) {
//           //Mando todo nulo y traigo todas las egm del back
//           this.updateTable(null);
//         }
//         else {
//           //Mando el combo de egm
//           var filters: MultiselectFilters = new MultiselectFilters();
//           filters.egms = this.egmsCombo.getEgmComboAsIdArray();
//           this.updateTable(filters);
//         }
//       }
//     }
//   }

//   private fillCombos() {
//     this.egmCombo.push(new IdTextModel(0, 'Todos'));

//     var egmList = this.EgmService.getEGMs(null);
//     egmList.forEach(observableEgm => {
//       observableEgm.data.dataResults.forEach(egm => {
//         this.egmCombo.push(new IdTextModel(egm.uid, egm.uid.toString()));
//       })
//     })

//   }

// }
