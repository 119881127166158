import { CashRegister } from './CashRegister';
import { TicketFamily, TicketType } from './Enums';
import { TicketMock, TicketReplacement } from './TicketMock';
import { Turn } from './TicketTracker';

export class Ticket {
  id: number;
  uid?: number;
  code: number;
  creationDate: string;
  validationDate: string;
  expirationDate: string;
  amount: number;
  family: TicketFamily;
  type: TicketType;
  status: string;
  userIdName: string;
  isValid: boolean;
  error: string;
  quantity: number;
  cashMachine: CashRegister;
  observations: string;
  replacementTicket: TicketMock;
  replacementTicketId?: number;
  creationTurn: Turn;
  ticketReplacement: TicketReplacement[];

  constructor() {
    this.family = TicketFamily.Cash;
    this.type = TicketType.OUT;
    this.cashMachine = new CashRegister();
  }
}
