import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';

@Component({
  selector: 'app-locations-report',
  templateUrl: './locations-report.component.html',
  styleUrls: ['./locations-report.component.css'],
})
export class LocationsReportComponent implements OnInit {
  items: Array<BreadcrumbItem> = [];

  title: string = 'Reporte de Ubicaciones';

  public static getName() {
    return 'LocationsReportComponent';
  }
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }
}
