<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- ticket cash -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">{{ titleCash }}</h3>
          </div>
          <form
            role="form"
            [formGroup]="formCash"
            (ngSubmit)="onSubmitCash()"
            #formObject="ngForm"
            *ngIf="formCash != null">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formCash.get('location')"
                    [label]="'Nombre del establecimiento'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formCash.get('address1')"
                    [label]="'Dirección 1'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formCash.get('address2')"
                    [label]="'Dirección 2'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formCash.get('defaultExpiration')"
                    [label]="'Expiración por defecto'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formCash.get('maximumTicketSale')"
                    [label]="'Máximo valor Emisión (sin céntimos)'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formCash.get('template')"
                    [label]="'Plantilla de impresora'"
                    [type]="InputType.textarea">
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formCash.get('ticketPrint')"
                    [label]="'Permitir impresión de ticket cash'"
                    [type]="InputType.checkbox"
                    [name]="'ticketcashcheck'">
                  </app-common-input>
                </div>
              </div>
            </div>

            <div class="card-footer text-right">
              <button type="button" class="btn btn-primary mr-2" (click)="testPrinter()" style="min-width: 100px">
                Imprimir Test
              </button>

              <button
                type="submit"
                class="btn btn-primary"
                *ngIf="showSave | async"
                [class.disabled]="!this.formCash.dirty">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- ticket prom -->
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">{{ titleProm }}</h3>
          </div>
          <form
            role="form"
            [formGroup]="formProm"
            (ngSubmit)="onSubmitProm()"
            #formObject="ngForm"
            *ngIf="formProm != null">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formProm.get('location')"
                    [label]="'Nombre del establecimiento'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formProm.get('address1')"
                    [label]="'Dirección 1'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formProm.get('address2')"
                    [label]="'Dirección 2'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formProm.get('defaultExpiration')"
                    [label]="'Expiración por defecto'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formProm.get('maximumTicketSale')"
                    [label]="'Máximo valor emisión (sin céntimos)'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formProm.get('template')"
                    [label]="'Plantilla de impresora'"
                    [type]="InputType.textarea">
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formProm.get('ticketPrint')"
                    [label]="'Permitir impresión de ticket promocional'"
                    [name]="'ticketpromcheck'"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button
                type="submit"
                class="btn btn-primary"
                *ngIf="showSave | async"
                [class.disabled]="!this.formProm.dirty">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- ticket handpauy -->
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">{{ titleHp }}</h3>
          </div>
          <form role="form" [formGroup]="formHp" (ngSubmit)="onSubmitHp()" #formObject="ngForm" *ngIf="formHp != null">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formHp.get('location')"
                    [label]="'Nombre del establecimiento'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formHp.get('address1')"
                    [label]="'Dirección 1'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formHp.get('address2')"
                    [label]="'Dirección 2'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl">
                  <app-common-input
                    [control]="formHp.get('defaultExpiration')"
                    [label]="'Expiración por defecto'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <!-- <div class="form-group col-12 col-sm-6 col-xl">
                                    <app-common-input [control]="formHp.get('maximumTicketSale')"
                                        [label]="'Máximo valor permitido'"
                                        [formSubmitted]="formObject.submitted" [type]="InputType.numeric">
                                    </app-common-input>
                                </div> -->
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formHp.get('template')"
                    [label]="'Plantilla de impresora'"
                    [type]="InputType.textarea">
                  </app-common-input>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button
                type="submit"
                class="btn btn-primary"
                *ngIf="showSave | async"
                [class.disabled]="!this.formHp.dirty">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- ticket handpauy -->
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">{{ titleTemplateVoucher }}</h3>
          </div>
          <form
            role="form"
            [formGroup]="formTemplateVoucher"
            (ngSubmit)="onSubmitTemplateVoucher()"
            #formObject="ngForm"
            *ngIf="formTemplateVoucher != null">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formTemplateVoucher.get('voucherRecipitTemplate')"
                    [label]="'Recibo Voucher'"
                    [type]="InputType.textarea">
                  </app-common-input>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="btn btn-primary" [class.disabled]="!this.formTemplateVoucher.dirty">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Opciones de plantilla de impresora</h3>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-12">
                <ul>
                  <li>&lt;PrintDate&gt;</li>
                  <li>&lt;Location&gt;</li>
                  <li>&lt;Address1&gt;</li>
                  <li>&lt;Address2&gt;</li>
                  <li>&lt;Family&gt;</li>
                  <li>&lt;ValidatorCode&gt;</li>
                  <li>&lt;ValidatorCodeFormatted&gt;</li>
                  <li>&lt;TicketDate&gt;</li>
                  <li>&lt;Number&gt;</li>
                  <li>&lt;AmountWordsIntegral&gt;</li>
                  <li>&lt;AmountWordsDecimal&gt;</li>
                  <li>&lt;CashIn&gt;</li>
                  <li>&lt;Amount&gt;</li>
                  <li>&lt;ExpirationDate&gt;</li>
                  <li>&lt;ExpirationDays&gt;</li>
                  <li>&lt;MachineId&gt;</li>
                  <li>--------</li>
                  <li>&lt;CompanyName&gt;</li>
                  <li>&lt;FacilityName&gt;</li>
                  <li>&lt;ValidatorCode&gt;</li>
                  <li>&lt;GenerateReason&gt;</li>
                  <li>&lt;Egm&gt;</li>
                  <li>&lt;Observations&gt;</li>
                  <li>&lt;ESC&gt;</li>
                  <li>&lt;CR&gt;</li>
                  <li>&lt;LF&gt;</li>
                  <li>&lt;FF&gt;</li>
                  <li>&lt;ENQ&gt;</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
