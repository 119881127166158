<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4 vh-100" *ngIf="loading">
  <div id="floatingCirclesG" class="mt-4">
    <div class="f_circleG" id="frotateG_01"></div>
    <div class="f_circleG" id="frotateG_02"></div>
    <div class="f_circleG" id="frotateG_03"></div>
    <div class="f_circleG" id="frotateG_04"></div>
    <div class="f_circleG" id="frotateG_05"></div>
    <div class="f_circleG" id="frotateG_06"></div>
    <div class="f_circleG" id="frotateG_07"></div>
    <div class="f_circleG" id="frotateG_08"></div>
  </div>
</aside>
<aside class="main-sidebar sidebar-dark-primary elevation-4 invisible" [class.invisible]="loading">
  <!-- Brand Logo -->
  <a routerLink="/" class="brand-link text-center">
    <span class="tenattile">{{ tentantTitle }}</span>
  </a>
  <div class="sidebar h-100 overflow-scroll hide">
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="dashboard/visualize"
            routerLinkActive="active"
            #menuItem
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="clearInternalStates()">
            <i class="nav-icon fas fa-desktop"></i>
            <p>Monitoreo</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon fas fa-building"></i>
            <p>
              Configuración Salón
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="establishment/tenant/list"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tenants</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="establishment/about"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Acerca De</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="establishment/config/edit"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Sincronismo</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="establishment/rooms"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Salas (Áreas)</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="establishment/locations"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ubicaciones</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="machines/cash-machine"
                class="nav-link itemmenu"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Máquinas de Cambio / ATM</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="establishment/cash-registers"
                class="nav-link itemmenu"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Cajas</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="machines/spiders"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Spiders Plus</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="machines/egms"
                class="nav-link itemmenu"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>EGMs</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="machines/manufacturers"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Fabricantes</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="machines/models"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Modelos</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="machines/gametypes"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Juegos</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="machines/cabinets"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Gabinetes</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/tito/ticket-config"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Formato Ticket</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/instant-counters"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Contadores Instántaneos</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/tito/printer-config"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Conexión Impresora Tickets</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="establishment/services"
                class="nav-link itemmenu"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Servicios</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon fas fa-search-dollar"></i>
            <p>
              Contadores
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/edit"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Modificación</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/periods"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Modificación x Período</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/daily-profit"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ganancia Diaria</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon fas fa-ticket-alt"></i>
            <p>
              TITO
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/general-tickets"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Seguimiento Tickets</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/tito/ticket-history"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Historial por Ticket</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/ticket-counters"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Contadores Tickets</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/redeemed-tickets-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tickets Aceptados</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/issued-ticket-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tickets Emitidos por Origen</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/expired-tickets"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tickets Expirados por Origen</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/detailed-unpaid-tickets"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tickets Impagos</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/cashed-tickets"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tickets Pagados por Caja</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/tickets-average"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tickets Promedio</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/public-accounting-power"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Tickets Poder Público</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon fas fa-bullhorn"></i>
            <p>Eventos<i class="fas fa-angle-left right"></i></p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="events/system-events"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Eventos del Sistema</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon fas fa-dollar-sign"></i>
            <p>
              Gestión
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/session-management"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Sesiones y Turnos</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/session-egm-action"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Operaciones EGMs</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="reports/session-movements-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Movimientos del Turno</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="report/session-collection-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Reporte de Recaudación</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="report/session-CashCount-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Reporte de Arqueo</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="reports/turn-detail-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Reporte Cierre de Turno</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon fas fa-file-invoice-dollar"></i>
            <p>
              Listados
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu itemmenu"
                routerLink="/reports/egm-parameters"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Parámetros EGMs/Servidor</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/egm-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>EGMs</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/spider-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Spiders Plus</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/current-counters"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Contadores</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/current-profit"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ganancia Día Abierto</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/period-profit"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ganancia Día Cerrado</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/combination-profit"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ganancia por Grupo EGMs</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/bills-quantity"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Billetes</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/money-drops"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Conciliación Drop</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/daily-accounting"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Contable</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/rooms-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Salas</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/locations-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ubicaciones</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/cash-register-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Cajas</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/reports/handpay-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Pagos Manuales</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon far fa-chart-bar"></i>
            <p>
              Estadísticas
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/statistics/played-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Apostado x Día Cerrado</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/statistics/played-average-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Apostado x Promedio</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/statistics/hourly-played"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Apostado x Hora</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/statistics/daily-profit-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ganancia x Día Cerrado</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/statistics/profit-average-report"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ganancia x Promedio</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/statistics/hourly-profit"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Ganancia x Hora</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" href="" #menuGroup>
            <i class="nav-icon fas fa-users"></i>
            <p>
              Seguridad
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/authorizations"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Autorizaciones</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="users/list"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Usuarios</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/access-log"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Log Accesos</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/users-log"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Log Usuarios</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/closed-days-log"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Log Apertura/Cierre Día</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/counters-edit-log"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Log Mod. Contadores</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link itemmenu"
                routerLink="/audit/parameters-edit-log"
                routerLinkActive="active"
                #menuItem
                (click)="clearInternalStates()">
                <p>Log Mod. Parámetros EGMs</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</aside>
