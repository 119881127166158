import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { EditEgmComponent } from './pages/edit-egm/edit-egm.component';
import { ListEgmsComponent } from './pages/list-egms/list-egms.component';
import { CasinoDataComponent } from './pages/casino-data/casino-data.component';
import { EditRoomComponent } from './pages/edit-room/edit-room.component';
import { EditModelComponent } from './pages/edit-model/edit-model.component';
import { ListModelsComponent } from './pages/list-models/list-models.component';
import { ListRoomsComponent } from './pages/list-rooms/list-rooms.component';
import { EditManufacturerComponent } from './pages/edit-manufacturer/edit-manufacturer.component';
import { ListManufacturersComponent } from './pages/list-manufacturers/list-manufacturers.component';
import { EditSpiderComponent } from './pages/edit-spider/edit-spider.component';
import { ListSpidersComponent } from './pages/list-spiders/list-spiders.component';
import { EditCabinetComponent } from './pages/edit-cabinet/edit-cabinet.component';
import { ListCabinetsComponent } from './pages/list-cabinets/list-cabinets.component';
import { ListEgmParametersComponent } from './pages/list-egm-parameters/list-egm-parameters.component';
import { EditEgmParametersComponent } from './pages/edit-egm-parameters/edit-egm-parameters.component';
import { CountersInfoComponent } from './pages/counters-info/counters-info.component';
import { DetailedCountersInfoComponent } from './pages/detailed-counters-info/detailed-counters-info.component';
import { TicketConfigComponent } from './pages/ticket-config/ticket-config.component';
import { EditCountersComponent } from './pages/edit-counters/edit-counters.component';
import { SystemEventsComponent } from './pages/system-events/system-events.component';
import { ListGametypesComponent } from './pages/list-gametypes/list-gametypes.component';
import { EditGametypeComponent } from './pages/edit-gametype/edit-gametype.component';
import { TicketInfoComponent } from './pages/ticket-info/ticket-info.component';
import { TicketHistoryComponent } from './pages/ticket-history/ticket-history.component';
import { TicketSupervisorComponent } from './pages/ticket-supervisor/ticket-supervisor.component';
import { CountersPeriodComponent } from './pages/counters-period/counters-period.component';
import { DetailedCountersReportComponent } from './pages/detailed-counters-report/detailed-counters-report.component';
import { ListCashRegistersComponent } from './pages/list-cash-registers/list-cash-registers.component';
import { EditCashRegistersComponent } from './pages/edit-cash-registers/edit-cash-registers.component';
import { CashableTicketComponent } from './pages/cashable-ticket/cashable-ticket.component';
import { PromotionalTicketComponent } from './pages/promotional-ticket/promotional-ticket.component';
import { DailyAnalysisComponent } from './pages/daily-analysis/daily-analysis.component';
import { EnableTicketComponent } from './pages/enable-ticket/enable-ticket.component';
import { CancelTicketComponent } from './pages/cancel-ticket/cancel-ticket.component';

import { CashoutComponent } from './pages/cashout/cashout.component';

import { ReportCurrentProfitComponent } from './pages/report-current-profit/report-current-profit.component';
import { PeriodProfitComponent } from './pages/period-profit/period-profit.component';
import { AuthorizedTicketsComponent } from './pages/authorized-tickets/authorized-tickets.component';
import { GeneralTicketsComponent } from './pages/general-tickets/general-tickets.component';
import { UnpaidTicketsComponent } from './pages/unpaid-tickets/unpaid-tickets.component';
import { DetailedUnpaidTicketsComponent } from './pages/detailed-unpaid-tickets/detailed-unpaid-tickets.component';
import { CashedTicketsComponent } from './pages/cashed-tickets/cashed-tickets.component';
import { ExpiredTicketsComponent } from './pages/expired-tickets/expired-tickets.component';

import { TicketsAverageComponent } from './pages/tickets-average/tickets-average.component';
import { PublicAccountingPowerComponent } from './pages/public-accounting-power/public-accounting-power.component';

import { VisualizeDashboardComponent } from './pages/visualize-dashboard/visualize-dashboard.component';
import { EditDashboardComponent } from './pages/edit-dashboard/edit-dashboard.component';
import { ListLocationsComponent } from './pages/list-locations/list-locations.component';
import { EditLocationComponent } from './pages/edit-location/edit-location.component';
import { EgmReportComponent } from './pages/egm-report/egm-report.component';
import { SpiderReportComponent } from './pages/spider-report/spider-report.component';
import { CombinationProfitComponent } from './pages/combination-profit/combination-profit.component';
import { GlossaryComponent } from './pages/glossary/glossary.component';
import { TicketGenerationComponent } from './pages/ticket-generation/ticket-generation.component';
import { MockButtonsComponent } from './pages/mock-buttons/mock-buttons.component';
import { ServicesComponent } from './pages/services/services.component';
import { RealTimeProfitComponent } from './pages/real-time-profit/real-time-profit.component';
import { HandpayVoucherComponent } from './pages/handpay-voucher/handpay-voucher.component';
import { MoneyDropsComponent } from './pages/money-drops/money-drops.component';
import { BillsQuantityComponent } from './pages/bills-quantity/bills-quantity.component';

import { CurrentProfitGraphicComponent } from './pages/current-profit-graphic/current-profit-graphic.component';
import { CoinInReportComponent } from './pages/coin-in-report/coin-in-report.component';
import { CoinInAverageReportComponent } from './pages/coin-in-average-report/coin-in-average-report.component';
import { ProfitAverageReportComponent } from './pages/profit-average-report/profit-average-report.component';
import { DailyProfitReportComponent } from './pages/daily-profit-report/daily-profit-report.component';
import { HourlyProfitComponent } from './pages/hourly-profit/hourly-profit.component';
import { HourlyCoinInComponent } from './pages/hourly-coin-in/hourly-coin-in.component';
import { AccessLogComponent } from './pages/access-log/access-log.component';
import { UsersLogComponent } from './pages/users-log/users-log.component';
import { ClosedDaysLogComponent } from './pages/closed-days-log/closed-days-log.component';
import { CountersEditLogComponent } from './pages/counters-edit-log/counters-edit-log.component';
import { ParametersEditLogComponent } from './pages/parameters-edit-log/parameters-edit-log.component';
import { TicketCountersReportComponent } from './pages/ticket-counters-report/ticket-counters-report.component';
import { ServerAuthorizeGuard } from 'src/api-authorization/server-authorize.guard';
import { ListUserComponent } from './pages/list-user/list-user.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';
import { PrinterConfigComponent } from './pages/printer-config/printer-config.component';
import { ListTenantComponent } from './pages/list-tenant/list-tenant.component';
import { EditTenantComponent } from './pages/edit-tenant/edit-tenant.component';
import { EditCasinodataComponent } from './pages/edit-casinodata/edit-casinodata.component';
import { LocationsReportComponent } from './pages/locations-report/locations-report.component';
import { CashRegisterReportComponent } from './pages/cashRegister-report/cashRegister-report.component';
import { RoomsReportComponent } from './pages/rooms-report/rooms-report.component';
import { ListCashMachineComponent } from './pages/list-cash-machine/list-cash-machine.component';
import { EditCashMachineComponent } from './pages/edit-cash-machine/edit-cash-machine.component';
import { HandpayVoucherReportComponent } from './pages/handpay-voucher-report/handpay-voucher-report.component';
import { IssuedTicketsComponent } from './pages/issued-tickets/issued-tickets.component';
import { AcceptedTicketsComponent } from './pages/accepted-tickets/accepted-tickets.component';
import { AccountingReportComponent } from './pages/accounting-report/accounting-report.component';
import { EditFirmwareComponent } from './pages/edit-firmware/edit-firmware.component';
import { SessionCashFlowComponent } from './pages/session-cash-flow/session-cash-flow.component';
import { SessionManagementComponent } from './pages/session-management/session-management.component';
import { SessionSettlementComponent } from './pages/session-settlement/session-settlement.component';
import { SessionMovementComponent } from './pages/session-movement/session-movement.component';
import { SessionCollectionComponent } from './pages/session-collection/session-collection.component';
import { SessionEgmActionComponent } from './pages/session-egm-action/session-egm-action.component';

import { SessionReconciliationComponent } from './pages/session-reconciliation/session-reconciliation.component';
import { SessionMovementsReportComponent } from './pages/session-movements-report/session-movements-report.component';
import { SessionCollectionReportComponent } from './pages/session-collection-report/session-collection-report.component';
import { SessionMovementCashflowComponent } from './pages/session-movement-cashflow/session-movement-cashflow.component';
import { SessionCashCountReportComponent } from './pages/session-cash-count-report/session-cash-count-report.component';
import { SessionStockegmInComponent } from './pages/session-stockegm-in/session-stockegm-in.component';
import { SessionStockegmOutComponent } from './pages/session-stockegm-in-out/session-stockegm-out.component';
import { DetailedCountersinstantComponent } from './pages/detailed-counters-instant/detailed-counters-instant.component';
import { EditCasinoDataParametersComponent } from './pages/edit-casino-parameters/edit-casino-parameters.component';
import { ListAuthorizationsComponent } from './pages/list-authorizations/list-authorizations.component';
import { TurnDetailsComponent } from './pages/turn-details/turn-details.component';

const routes: Routes = [
  // {
  //   path: 'login',
  //   component: LoginComponent,
  // },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard/visualize',
        pathMatch: 'full',
      },
      {
        path: 'glossary',
        component: GlossaryComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'mocks',
        component: MockButtonsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'dashboard/visualize',
        component: VisualizeDashboardComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'dashboard/edit/:id',
        component: EditDashboardComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/egms/firmware/:id',
        component: EditFirmwareComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/egms/edit-egm/:id',
        component: EditEgmComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/egms/clone-egm/:idClone',
        component: EditEgmComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/egms/create-egm',
        component: EditEgmComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/egms',
        component: ListEgmsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/cash-machine/edit/:id',
        component: EditCashMachineComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/cash-machine/create-cash-machine',
        component: EditCashMachineComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/cash-machine',
        component: ListCashMachineComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/rooms/edit-room/:id',
        component: EditRoomComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/rooms/create-room',
        component: EditRoomComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/rooms',
        component: ListRoomsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/rooms/:searchString',
        component: ListRoomsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/locations',
        component: ListLocationsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/locations/edit-location/:id',
        component: EditLocationComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/locations/create-location',
        component: EditLocationComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/about',
        component: CasinoDataComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/models',
        component: ListModelsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/models/edit-model/:id',
        component: EditModelComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/models/create-model',
        component: EditModelComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/manufacturers',
        component: ListManufacturersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/manufacturers/edit-manufacturer/:id',
        component: EditManufacturerComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/manufacturers/create-manufacturer',
        component: EditManufacturerComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/spiders',
        component: ListSpidersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'machines/spiders/edit-spider/:id',
        component: EditSpiderComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/spiders/create-spider',
        component: EditSpiderComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/cabinets',
        component: ListCabinetsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/cabinets/edit-cabinet/:id',
        component: EditCabinetComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/cabinets/create-cabinet',
        component: EditCabinetComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/gametypes',
        component: ListGametypesComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/gametypes/edit-gametype/:id',
        component: EditGametypeComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/gametypes/create-gametype',
        component: EditGametypeComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/cash-registers',
        component: ListCashRegistersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/cash-registers/edit/:id',
        component: EditCashRegistersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/cash-registers/create-cash-register',
        component: EditCashRegistersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'machines/egms/parameters/edit/:id',
        component: EditEgmParametersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/services',
        component: ServicesComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'tito/ticket-config',
        component: TicketConfigComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'tito/printer-config',
        component: PrinterConfigComponent,
        canActivate: [AuthorizeGuard],
      },
      {
        path: 'tito/ticket-info',
        component: TicketInfoComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      // {
      //   path: 'tito/authorize-ticket',
      //   component: AuthorizeTicketsComponent,
      //   canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      // },
      {
        path: 'tito/ticket-history',
        component: TicketHistoryComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'tito/ticket-history/:code',
        component: TicketHistoryComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/edit/edit-counters/:id/:uid',
        component: EditCountersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/edit',
        component: DetailedCountersInfoComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/instant-counters',
        component: DetailedCountersinstantComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/periods',
        component: CountersPeriodComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/daily-profit',
        component: DailyAnalysisComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/access-log',
        component: AccessLogComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/users-log',
        component: UsersLogComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/closed-days-log',
        component: ClosedDaysLogComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/counters-edit-log',
        component: CountersEditLogComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'audit/parameters-edit-log',
        component: ParametersEditLogComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'events/system-events',
        component: SystemEventsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'cashier/promotional-ticket',
        component: PromotionalTicketComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'cashier/enable-ticket',
        component: EnableTicketComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'cashier/cancel-ticket',
        component: CancelTicketComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-management',
        component: SessionManagementComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-pay-ticket/:id',
        component: CashoutComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-cashable-ticket/:id',
        component: CashableTicketComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-cashflow/:id',
        component: SessionCashFlowComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'session-movement/:id',
        component: SessionMovementComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-movement-cashflow/:id',
        component: SessionMovementCashflowComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'session-settlement/:id',
        component: SessionSettlementComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-settlement',
        component: SessionSettlementComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-handpayvoucher/:id',
        component: HandpayVoucherComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'session-ticketreplacement/:id',
        component: TicketSupervisorComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-incidencepayment/:id',
        component: TicketGenerationComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-manualpayment/:id',
        component: TicketGenerationComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'session-collection/:id',
        component: SessionCollectionComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-reconciliation/:id',
        component: SessionReconciliationComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-egm-action',
        component: SessionEgmActionComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-stock-egm-in/:id',
        component: SessionStockegmInComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'session-stock-egm-out/:id',
        component: SessionStockegmOutComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'reports/session-movements-report',
        component: SessionMovementsReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/turn-detail-report',
        component: TurnDetailsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'report/session-collection-report',
        component: SessionCollectionReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'report/session-CashCount-report',
        component: SessionCashCountReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'reports/daily-accounting',
        component: AccountingReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/egm-parameters',
        component: ListEgmParametersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/current-counters',
        component: CountersInfoComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/ticket-counters',
        component: TicketCountersReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/money-drops',
        component: MoneyDropsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/bills-quantity',
        component: BillsQuantityComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/detailed-counters',
        component: DetailedCountersReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/real-time-profit',
        component: RealTimeProfitComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/current-profit',
        component: ReportCurrentProfitComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      // {
      //   path: "reports/current-profit-graphic", component: CurrentProfitGraphicComponent, canActivate: [AuthorizeGuard, ServerAuthorizeGuard]
      // },
      {
        path: 'statistics/played-report',
        component: CoinInReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'statistics/daily-profit-report',
        component: DailyProfitReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'statistics/played-average-report',
        component: CoinInAverageReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'statistics/profit-average-report',
        component: ProfitAverageReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'statistics/hourly-profit',
        component: HourlyProfitComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'statistics/hourly-played',
        component: HourlyCoinInComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/period-profit',
        component: PeriodProfitComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/combination-profit',
        component: CombinationProfitComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/authorized-tickets',
        component: AuthorizedTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/general-tickets',
        component: GeneralTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/unpaid-tickets',
        component: UnpaidTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/detailed-unpaid-tickets',
        component: DetailedUnpaidTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/cashed-tickets',
        component: CashedTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/expired-tickets',
        component: ExpiredTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      // {
      //   path: 'reports/summarized-sessions',
      //   component: TicketsPerUserComponent,
      //   canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      // },
      {
        path: 'reports/tickets-average',
        component: TicketsAverageComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/public-accounting-power',
        component: PublicAccountingPowerComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'reports/egm-report',
        component: EgmReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/locations-report',
        component: LocationsReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/cash-register-report',
        component: CashRegisterReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'reports/rooms-report',
        component: RoomsReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'reports/spider-report',
        component: SpiderReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'reports/handpay-report',
        component: HandpayVoucherReportComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'reports/issued-ticket-report',
        component: IssuedTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'reports/redeemed-tickets-report',
        component: AcceptedTicketsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      // { //viejo finalizar sessiones
      //   path: 'supervisor/supervisor-sessions',
      //   component: SupervisorSessionsComponent,
      //   canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      // },

      {
        path: 'users/list',
        component: ListUserComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'users/edit/:id',
        component: EditUserComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'users/create',
        component: EditUserComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'establishment/tenant/list',
        component: ListTenantComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/tenant/:id',
        component: EditTenantComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },
      {
        path: 'establishment/tenant/create',
        component: EditTenantComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'establishment/config/edit',
        component: EditCasinodataComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'establishment/about/edit-parameters',
        component: EditCasinoDataParametersComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: 'authorizations',
        component: ListAuthorizationsComponent,
        canActivate: [AuthorizeGuard, ServerAuthorizeGuard],
      },

      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' }),
    ApiAuthorizationModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
