<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ title }}</h3>
  </div>
  <div class="modal-body" [innerHTML]="body"></div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button type="submit" class="btn btn-primary" (click)="confirm()">{{ yes }}</button>
      <button type="button" *ngIf="!okOnly" class="btn btn-secondary ml-2" (click)="cancel()">{{ no }}</button>
    </div>
  </div>
</ng-template>
