import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { GametypesComboComponent } from 'src/app/common/components/business/gametypes-combo/gametypes-combo.component';
import { ManufacturersComboComponent } from 'src/app/common/components/business/manufacturers-combo/manufacturers-combo.component';
import { ModelsComboComponent } from 'src/app/common/components/business/models-combo/models-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { BillsQuantityViewModel } from 'src/app/common/components/viewmodels/BillsQuantityViewModel';
import { CountersMock } from 'src/app/common/components/viewmodels/CountersMock';
import { DailyBalance } from 'src/app/common/components/viewmodels/DailyBalance';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { CountersService } from 'src/app/services/counters/counters.service';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';

@Component({
  selector: 'app-bills-quantity',
  templateUrl: './bills-quantity.component.html',
  styleUrls: ['./bills-quantity.component.css'],
})
export class BillsQuantityComponent implements OnInit {
  @ViewChild(ModelsComboComponent) modelsCombo: ModelsComboComponent;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(ManufacturersComboComponent) manufacturersCombo: ManufacturersComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<BillsQuantityViewModel>;

  viewCombo: Array<IdTextModel> = [];

  title: string = 'Billetes';

  items: Array<BreadcrumbItem> = [];

  public data: commonTableData<BillsQuantityViewModel>;

  currencyCode: string;
  currencySymbol: string;

  formSearch: FormGroup;
  dateRange: string;

  public static getName() {
    return 'BillsQuantityComponent';
  }
  constructor(
    private router: Router,
    private DailyBalanceService: DailyBalanceService,
    private CountersService: CountersService,
    public currencyPipe: CurrencyPipe,
    private CasinoService: CasinoService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Billetes'));

    this.initForm();
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySymbol = result.data.currencySymbol;
    });
    this.initGrid();
  }

  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    const that = this;

    this.data.columns = [
      {
        data: 'dailyBalance.formattedStartDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'dailyBalance.egm.uid', title: 'EGM' },
      { data: 'dailyBalance.egm.manufacturer.name', title: 'Fabricante' },
      { data: 'dailyBalance.egm.model.name', title: 'Modelo' },
      { data: 'dailyBalance.egm.egmConfig.currency', title: 'Moneda' },

      {
        data: 'm1Bill',
        title: 'M1 Bill',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },

      {
        data: 'dailyBalance.difBillM1Q',
        title: 'Bill M1 Q',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },

      {
        data: 'm2Bill',
        title: 'M2 Bill',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },
      {
        data: 'dailyBalance.difBillM2Q',
        title: 'Bill M2 Q',
        render: function (data: number, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'm3Bill',
        title: 'M1 B3ll',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },
      {
        data: 'dailyBalance.difBillM3Q',
        title: 'Bill M3 Q',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'm4Bill',
        title: 'M4 Bill',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },
      {
        data: 'dailyBalance.difBillM4Q',
        title: 'Bill M4 Q',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'm5Bill',
        title: 'M5 Bill',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },
      {
        data: 'dailyBalance.difBillM5Q',
        title: 'Bill M5 Q',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'm6Bill',
        title: 'M6 Bill',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },
      {
        data: 'dailyBalance.difBillM6Q',
        title: 'Bill M6 Q',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'm7Bill',
        title: 'M7 Bill',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },
      {
        data: 'dailyBalance.difBillM7Q',
        title: 'Bill M7 Q',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'm8Bill',
        title: 'M8 Bill',
        render: function (data: number, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },
      {
        data: 'dailyBalance.difBillM8Q',
        title: 'Bill M8 Q',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },

      {
        data: 'total',
        title: 'Total Bill',
        render: function (data, type, row) {
          return data == 0 ? '-' : that.currencyPipe.transform(data, that.currencyCode, that.currencySymbol);
        },
      },

      {
        data: 'difBillTotalQ',
        title: 'Total Q',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'dailyBalance.difBillIn',
        title: 'Bill In',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
      {
        data: 'dailyBalance.egm.egmConfig.formattedDenomination',
        title: 'Denomin. Sist',
        render: function (data, type, row) {
          return data != null ? data : '-';
        },
      },
    ];
  }

  updateTable(filters: MultiselectFilters) {
    this.setGridData(filters);
    this.filterString();
    this.table.reload();
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.DailyBalanceService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<BillsQuantityViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getQuantityReport(DataTableFilters);
    };
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl([]),
      manufacturers: new FormControl([]),
      models: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();

    var endDate = moment(this.formSearch.get('to').value).endOf('day');
    filters.to = endDate;

    filters.to = endDate;
    filters.from = this.formSearch.get('from').value;
    if (this.formSearch.get('manufacturers').value > 0) {
      filters.models = this.formSearch.get('manufacturers').value;
    }
    if (this.formSearch.get('models').value > 0) {
      filters.models = this.formSearch.get('models').value;
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      if (!this.formSearchNull) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }
    return filters;
  }

  onSubmit() {
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy').toString();
    this.updateTable(this.getFilters());
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    if (this.formSearch.get('manufacturers').value.length > 0) {
      aux += ' Fabricante: ' + this.manufacturerFilters + ' - ';
    }
    if (this.formSearch.get('models').value.length > 0) {
      aux += ' Modelo: ' + this.modelFilters + ' - ';
    }

    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters;
    }
    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  get manufacturerFilters(): string {
    let combo = this.manufacturersCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('manufacturers').value.length; index++) {
      const element = this.formSearch.get('manufacturers').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('manufacturers').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get modelFilters(): string {
    let combo = this.modelsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('models').value.length; index++) {
      const element = this.formSearch.get('models').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('models').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  getModels() {
    this.modelsCombo.fillComboWithManufacturersId(this.formSearch.get('manufacturers').value);
    this.updateEgms();
  }

  updateEgms() {
    if (this.formSearchNull) {
      this.egmsCombo.fillCombo();
    } else {
      var filters: MultiselectFilters = new MultiselectFilters();
      filters.manufacturers = this.formSearch.get('manufacturers').value;
      filters.models = this.formSearch.get('models').value;
      filters.egms = this.formSearch.get('egmUid').value;
      this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
    }
  }

  get formSearchNull(): boolean {
    return this.formSearch.get('models').value.length == 0 && this.formSearch.get('manufacturers').value.length == 0;
  }
}
