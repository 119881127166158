import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EGM,
  Firmwarefile,
  FirmwareListProcessViewModel,
  FirmwareUpdateProcessViewModel,
} from '../../common/components/viewmodels/EGM';
import * as moment from 'moment-timezone';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { ToastrService } from 'ngx-toastr';
import { baseService } from '../base/base.service';
import { catchError, map } from 'rxjs/operators';
import { EGMMock } from 'src/app/common/components/viewmodels/EGMMock';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { MinimalFilters, MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { TicketConfig } from 'src/app/common/components/viewmodels/TicketConfig';
import { Router } from '@angular/router';
import { CashMachine, CashMachineConnectionViewModel } from 'src/app/common/components/viewmodels/cashMachine';

@Injectable({
  providedIn: 'root',
})
export class EgmServiceService extends baseService {
  date: moment.Moment = moment(new Date());

  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getEGMs2(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<EGMMock>>> {
    return this.http
      .post<ApiResult<DataTableResult<EGMMock>>>(`${this.baseUrl}EGMs/GetEGMs`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<EGMMock>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<EGMMock>>(error))
      );
  }

  public getAllEGMs(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<EGMMock>>> {
    return this.http
      .post<ApiResult<DataTableResult<EGMMock>>>(`${this.baseUrl}EGMs/GetAllEGMs`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<EGMMock>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<EGMMock>>(error))
      );
  }

  public getTicketCashConfig(): Observable<ApiResult<TicketConfig>> {
    return this.http.get<ApiResult<TicketConfig>>(`${this.baseUrl}EGMs/GetTicketCashConfig`).pipe(
      map((x) => {
        var result = this.checkResult<TicketConfig>(x);
        return result;
      }),
      catchError((error) => this.handleError<TicketConfig>(error))
    );
  }

  public getBillDescriptionList(): Observable<ApiResult<string[]>> {
    return this.http.get<ApiResult<string[]>>(`${this.baseUrl}EGMs/GetBillDescriptionList`).pipe(
      map((x) => {
        var result = this.checkResult<string[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<string[]>(error))
    );
  }

  // public getTicketConfigs(): Observable<ApiResult<TicketConfig[]>> {
  //   return this.http.get<ApiResult<TicketConfig[]>>(
  //     `${this.baseUrl}TicketConfigs/GetTicketConfigs`
  //   ).pipe(
  //     map(x => {
  //       var result = this.checkResult<TicketConfig[]>(x);
  //       return result;
  //     }
  //     ),
  //     catchError(error => this.handleError<TicketConfig[]>(error))
  //   );
  // }

  public getEGM2(id: number): Observable<ApiResult<EGMMock>> {
    return this.http.get<ApiResult<EGMMock>>(`${this.baseUrl}EGMs/GetEGM?id=${id}`).pipe(
      map((x) => this.checkResult<EGMMock>(x)),
      catchError((error) => this.handleError<EGMMock>(error))
    );
  }
  public getFirmwareUpdateProcess(egmId: number): Observable<ApiResult<FirmwareListProcessViewModel>> {
    return this.http
      .get<ApiResult<FirmwareListProcessViewModel>>(`${this.baseUrl}EGMs/GetFirmwareUpdateProcess?egmId=${egmId}`)
      .pipe(
        map((x) => this.checkResult<FirmwareListProcessViewModel>(x)),
        catchError((error) => this.handleError<FirmwareListProcessViewModel>(error))
      );
  }
  public createOrEdit(egm: EGMMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/CreateOrEdit`, egm).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public deleteEGM(id: number): Observable<ApiResult<string>> {
    return this.http.delete<ApiResult<string>>(`${this.baseUrl}EGMs/DeleteEgm?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public cancelUpdateFirmware(id: number): Observable<ApiResult<string>> {
    return this.http.delete<ApiResult<string>>(`${this.baseUrl}EGMs/CancelUpdateFirmware?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public updateFirmware(egmId: number, file: File): Observable<ApiResult<string>> {
    let formToPost = new FormData();
    formToPost.append('egmId', egmId.toString());
    formToPost.append('uploadedFile', file, file.name);

    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/UpdateFirmware`, formToPost).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public disabledAllEgm(): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/CommnadQueueDisabledAllEgm`, null).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public disabledEgm(egm: EGMMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/CommnadQueueDisabledEgm`, egm).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public startUpEGM(egm: EGMMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/CommnadQueueStartUpEGM`, egm).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public logSpiderOff(egm: EGMMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/CommnadQueueLogSpiderOff`, egm).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public logSpiderOn(egm: EGMMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/CommnadQueueLogSpiderOn`, egm).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getEGMsCombo(includeDeleted: boolean = false): Observable<ApiResult<IdTextModel[]>> {
    return this.http
      .get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetEGMsCombo?includeDeleted=${includeDeleted}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<IdTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<IdTextModel[]>(error))
      );
  }

  public getEGMsComboManualPay(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/getEGMsComboManualPay`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getMicsCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetMicsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  // public getAgrupadorJugadoCombo(): Observable<ApiResult<IdTextModel[]>> {
  //   return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetAgrupadorJugadoCombo`).pipe(
  //     map((x) => {
  //       var result = this.checkResult<IdTextModel[]>(x);
  //       return result;
  //     }),
  //     catchError((error) => this.handleError<IdTextModel[]>(error))
  //   );
  // }

  // public getAgrupadorPremiadoFichasCombo(): Observable<ApiResult<IdTextModel[]>> {
  //   return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetAgrupadorPremiadoFichasCombo`).pipe(
  //     map((x) => {
  //       var result = this.checkResult<IdTextModel[]>(x);
  //       return result;
  //     }),
  //     catchError((error) => this.handleError<IdTextModel[]>(error))
  //   );
  // }
  // public getAgrupadorJugadoFichasCombo(): Observable<ApiResult<IdTextModel[]>> {
  //   return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetAgrupadorJugadoFichasCombo`).pipe(
  //     map((x) => {
  //       var result = this.checkResult<IdTextModel[]>(x);
  //       return result;
  //     }),
  //     catchError((error) => this.handleError<IdTextModel[]>(error))
  //   );
  // }
  // public getAgrupadorPremiadoCombo(): Observable<ApiResult<IdTextModel[]>> {
  //   return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetAgrupadorPremiadoCombo`).pipe(
  //     map((x) => {
  //       var result = this.checkResult<IdTextModel[]>(x);
  //       return result;
  //     }),
  //     catchError((error) => this.handleError<IdTextModel[]>(error))
  //   );
  // }
  // public getAgrupadorPagoManualCombo(): Observable<ApiResult<IdTextModel[]>> {
  //   return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetAgrupadorPagoManualCombo`).pipe(
  //     map((x) => {
  //       var result = this.checkResult<IdTextModel[]>(x);
  //       return result;
  //     }),
  //     catchError((error) => this.handleError<IdTextModel[]>(error))
  //   );
  // }
  public getFreeEGMsCombo(roomId: number): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetFreeEGMsCombo?roomId=${roomId}`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public fillEgmsWithMultiselectFilters(filters: MultiselectFilters): Observable<ApiResult<IdTextModel[]>> {
    return this.http.post<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetEGMsComboByIds`, filters).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public fillEgmsWithMinimalFilters(filters: MinimalFilters): Observable<ApiResult<IdTextModel[]>> {
    return this.http.post<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetEGMsComboByMinimalIds`, filters).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public editEgmParams(egm: EGMMock): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/EditEgmParams`, egm).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getCreditValueCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetCreditValueCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  //cash machine que no estan usadas en dasboard
  public getFreeCashMachineCombo(roomId: number): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetFreeCashMachineCombo?roomId=${roomId}`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public deleteCashMachine(id: number): Observable<ApiResult<string>> {
    return this.http.delete<ApiResult<string>>(`${this.baseUrl}EGMs/DeleteCashMachine?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<string>(x);
        return result;
      }),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public createOrEditCashMachine(egm: CashMachine): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/CashMachineCreateOrEdit`, egm).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public testConnection(testConnection: CashMachineConnectionViewModel): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}EGMs/TestConnection`, testConnection).pipe(
      map((x) => this.checkResult<string>(x, true)),
      catchError((error) => this.handleError<string>(error))
    );
  }

  public getCashMachine(id: number): Observable<ApiResult<CashMachine>> {
    return this.http.get<ApiResult<CashMachine>>(`${this.baseUrl}EGMs/GetCashMachine?id=${id}`).pipe(
      map((x) => this.checkResult<CashMachine>(x)),
      catchError((error) => this.handleError<CashMachine>(error))
    );
  }

  //trae todas las cashmachie

  public getAllCashMachine(
    dataTablesParameters: DataTableParameters
  ): Observable<ApiResult<DataTableResult<CashMachine>>> {
    return this.http
      .post<ApiResult<DataTableResult<CashMachine>>>(`${this.baseUrl}EGMs/GetAllCashMachine`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<CashMachine>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<CashMachine>>(error))
      );
  }

  public getCashMachinesCombo(): Observable<ApiResult<IdTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/GetCashMachineCombo`).pipe(
      map((x) => {
        var result = this.checkResult<IdTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<IdTextModel[]>(error))
    );
  }

  public getCashMachinesNotUsedCombo(cashRegisterId: number): Observable<ApiResult<IdTextModel[]>> {
    return this.http
      .get<ApiResult<IdTextModel[]>>(`${this.baseUrl}EGMs/getCashMachinesNotUsedCombo?cashRegisterId=${cashRegisterId}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<IdTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<IdTextModel[]>(error))
      );
  }
}
