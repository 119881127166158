import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { FullUserViewModel } from 'src/app/common/components/viewmodels/User';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { NgForm } from '@angular/forms';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { Tenant } from 'src/app/common/components/viewmodels/Casino';
import { CasinoService } from 'src/app/services/casino/casino.service';

declare var $: any;

@Component({
  selector: 'app-edit-tenant',
  templateUrl: './edit-tenant.component.html',
  styleUrls: ['./edit-tenant.component.css'],
})
export class EditTenantComponent implements OnInit {
  formEdit: FormGroup;
  title: string = 'Nuevo Tenant';

  InputType = InputTypeEnum;

  isEdit: boolean;
  items: Array<BreadcrumbItem> = [];
  tenant: Tenant = new Tenant();

  parameter: tableStatus = null;

  public static getName() {
    return 'EditTenantComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private casinoService: CasinoService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();

    if (!isNaN(Number(this.activatedRoute.snapshot.params.id))) {
      this.casinoService.GetTenantById(this.activatedRoute.snapshot.params.id).subscribe((result) => {
        if (result.error == null) {
          this.formEdit.patchValue(result.data);
        } else {
          this.notificationService.showError('Error al editar el tenant.', 'Error');
        }
      });

      this.title = 'Editar Tenant';

      this.isEdit = true;
    } else {
      this.title = 'Nuevo Tenant';
      this.isEdit = false;
    }

    this.items.push(new BreadcrumbItem(this.router.url, 'Tenants'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('establishment/tenant/list');
  }

  onSubmit() {
    this.formEdit.markAllAsTouched();
    if (this.formEdit.valid) {
      this.tenant.description = this.formEdit.get('description').value;
      this.tenant.id = 0;

      if (this.isEdit) {
        this.tenant.id = this.formEdit.get('id').value;
      }

      this.tenant.apikey = this.formEdit.get('apikey').value;

      this.paramService.saveParameters(this.parameter);
      this.casinoService.createOrEditTenant(this.tenant).subscribe((result) => {
        if (result.error == null) {
          this.router.navigate(['/establishment/tenant/list']);
        }
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(),
      description: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      apikey: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      //'description': new FormControl(),
      //'apikey': new FormControl(),
    });
  }
}
