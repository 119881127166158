export class PrinterStatus {
  // *S|0|GRUSA4100|T|@|@|@|A|P |*
  unitAddress: string;
  softVersion: string;
  flag1: string;
  flag2: string;
  flag3: string;
  flag4: string;
  flag5: string;
  template: string;

  unitAddressNum: number;
  templateNum: number;

  isBusy: boolean;
  isError: boolean;
  isPlatenOpen: boolean;
  isPaperOut: boolean;
  isHeadError: boolean;
  isVoltageError: boolean;

  isTemperatureError: boolean;
  isLibraryRefError: boolean;
  isPRDataError: boolean;
  isLibraryLoadError: boolean;
  isBufferOverflow: boolean;
  isJobMemoryOverflow: boolean;

  isCommandError: boolean;
  isPrintLibrariesCorrupted: boolean;
  isPaperInChute: boolean;
  isFlashProgError: boolean;
  isPrinterOffline: boolean;
  isMissingSupplyIndex: boolean;

  isJournalPrinting: boolean;
  isCutterError: boolean;
  isPaperJam: boolean;
  isPaperLow: boolean;

  isValidationDone: boolean;
  isAtTopForm: boolean;
  isXoff: boolean;
  isPrinterOpen: boolean;
  isBarcodeDataAccessed: boolean;
  isReset: boolean;

  protected punitAddress() {
    try {
      this.unitAddressNum = Number(this.unitAddress);
    } catch (error) {}
  }

  protected ptemplate() {
    try {
      this.templateNum = Number(this.unitAddress.replace('P', '').trim());
    } catch (error) {}
  }

  protected pflag1() {
    var encoder = new TextEncoder();
    var bytes = encoder.encode(this.flag1);
    var first = bytes[0];
    if (this.getBit(first, 7) || !this.getBit(first, 6)) {
      throw new Error('Error leyendo flag1: ' + this.flag1);
    }

    this.isBusy = this.getBit(first, 5);
    this.isError = this.getBit(first, 4);
    this.isPlatenOpen = this.getBit(first, 3);
    this.isPaperOut = this.getBit(first, 2);
    this.isHeadError = this.getBit(first, 1);
    this.isVoltageError = this.getBit(first, 0);
  }

  protected pflag2() {
    var encoder = new TextEncoder();
    var bytes = encoder.encode(this.flag2);
    var first = bytes[0];
    if (this.getBit(first, 7) || !this.getBit(first, 6)) {
      throw new Error('Error leyendo flag2: ' + this.flag2);
    }

    this.isTemperatureError = this.getBit(first, 5);
    this.isLibraryRefError = this.getBit(first, 4);
    this.isPRDataError = this.getBit(first, 3);
    this.isLibraryLoadError = this.getBit(first, 2);
    this.isBufferOverflow = this.getBit(first, 1);
    this.isJobMemoryOverflow = this.getBit(first, 0);
  }

  protected pflag3() {
    var encoder = new TextEncoder();
    var bytes = encoder.encode(this.flag3);
    var first = bytes[0];
    if (this.getBit(first, 7) || !this.getBit(first, 6)) {
      throw new Error('Error leyendo flag3: ' + this.flag3);
    }

    this.isCommandError = this.getBit(first, 5);
    this.isPrintLibrariesCorrupted = this.getBit(first, 4);
    this.isPaperInChute = this.getBit(first, 3);
    this.isFlashProgError = this.getBit(first, 2);
    this.isPrinterOffline = this.getBit(first, 1);
    this.isMissingSupplyIndex = this.getBit(first, 0);
  }

  protected pflag4() {
    var encoder = new TextEncoder();
    var bytes = encoder.encode(this.flag4);
    var first = bytes[0];
    if (this.getBit(first, 7) || !this.getBit(first, 6)) {
      throw new Error('Error leyendo flag4: ' + this.flag4);
    }

    this.isJournalPrinting = this.getBit(first, 3);
    this.isCutterError = this.getBit(first, 2);
    this.isPaperJam = this.getBit(first, 1);
    this.isPaperLow = this.getBit(first, 0);
  }

  protected pflag5() {
    var encoder = new TextEncoder();
    var bytes = encoder.encode(this.flag5);
    var first = bytes[0];
    if (this.getBit(first, 7) || !this.getBit(first, 6)) {
      throw new Error('Error leyendo flag4: ' + this.flag5);
    }

    this.isValidationDone = this.getBit(first, 5);
    this.isAtTopForm = this.getBit(first, 4);
    this.isXoff = this.getBit(first, 3);
    this.isPrinterOpen = this.getBit(first, 2);
    this.isBarcodeDataAccessed = this.getBit(first, 1);
    this.isReset = this.getBit(first, 0);
  }

  public isRealError(): boolean {
    return (
      this.isPlatenOpen &&
      this.isPaperOut &&
      this.isHeadError &&
      this.isVoltageError &&
      this.isTemperatureError &&
      this.isLibraryRefError &&
      this.isPRDataError &&
      this.isLibraryLoadError &&
      this.isBufferOverflow &&
      this.isJobMemoryOverflow &&
      this.isCommandError &&
      this.isPrintLibrariesCorrupted &&
      this.isFlashProgError &&
      this.isPrinterOffline &&
      this.isMissingSupplyIndex &&
      this.isCutterError &&
      this.isPaperJam
    );
  }

  public static parse(value: string): PrinterStatus {
    if (!value) return null;

    var result = new PrinterStatus();
    var parts = value.split('|');
    if (parts.length > 1) result.unitAddress = parts[1];
    if (parts.length > 2) result.softVersion = parts[2];
    if (parts.length > 3) result.flag1 = parts[3];
    if (parts.length > 4) result.flag2 = parts[4];
    if (parts.length > 5) result.flag3 = parts[5];
    if (parts.length > 6) result.flag4 = parts[6];
    if (parts.length > 7) result.flag5 = parts[7];
    if (parts.length > 8) result.template = parts[8];

    result.punitAddress();
    result.ptemplate();
    result.pflag1();
    result.pflag2();
    result.pflag3();
    result.pflag4();
    result.pflag5();

    return result;
  }

  getBit(number: number, bitPosition: number): boolean {
    return (number & (1 << bitPosition)) > 0 ? true : false;
  }
}
