import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { CashRegister } from 'src/app/common/components/viewmodels/CashRegister';
import { PromoTicketState, TicketState } from 'src/app/common/components/viewmodels/Enums';
import { PromotionalTicketMock } from 'src/app/common/components/viewmodels/PromotionalTicketMock';
import { Ticket } from 'src/app/common/components/viewmodels/Ticket';
import { TicketMock } from 'src/app/common/components/viewmodels/TicketMock';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { SessionOldReportService } from 'src/app/services/session-report/session-report.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-enable-ticket',
  templateUrl: './enable-ticket.component.html',
  styleUrls: ['./enable-ticket.component.css'],
})
export class EnableTicketComponent implements OnInit {
  @ViewChild('invalidModal', { static: false }) invalidModal: ConfirmModalComponent;

  body: string;
  ticketCode: string;
  cardRead: boolean = false;
  formSearch: FormGroup;
  ticket: PromotionalTicketMock = new PromotionalTicketMock();
  TicketState = TicketState;
  regex: RegExp = new RegExp('_', 'g');
  moment = moment;

  currencySym: string;
  currencyCode: string;
  lastSerch: string = '';
  sessionStatus: boolean = false;
  currentCashRegister: CashRegister = new CashRegister();

  title: string = 'Habilitación de Tickets';

  items: Array<BreadcrumbItem> = [];

  date: moment.Moment = moment(new Date());

  public static getName() {
    return 'EnableTicketComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    public TicketService: TicketService,
    private CasinoService: CasinoService,
    private SessionReportService: SessionOldReportService,
    private CashRegisterService: CashRegisterService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });
    this.items.push(new BreadcrumbItem(this.router.url, 'Cajero'));
    this.items.push(new BreadcrumbItem(this.router.url, this.title));

    this.initForm();
  }

  onSubmit() {
    if (this.formSearch.valid) {
      this.ticketCode = this.formSearch.get('ticketCode').value;
      this.lastSerch = this.formSearch.get('ticketCode').value;
      this.addTicket();
    }
  }

  addTicket() {
    this.TicketService.getPromoTicketByValidatorCode(this.ticketCode).subscribe((result) => {
      if (result.data != null) {
        if (result.data.state == PromoTicketState.Pendiente) {
          this.cardRead = true;
          this.ticket = result.data;
        } else {
          this.cardRead = false;
          switch (result.data.state) {
            case PromoTicketState.Anulado:
              this.body = 'El ticket con código ' + result.data.validatorCode + ' está anulado';
              break;
            case PromoTicketState.Habilitado:
              this.body = 'El ticket con código ' + result.data.validatorCode + ' ya está habilitado';
              break;
            default:
              this.body = 'El ticket es inválido';
              break;
          }
          this.invalidModal.openModalStatic();
          this.formSearch.get('ticketCode').disable();
        }
      } else {
        this.notificationService.showError(
          'No se encontró el ticket promocional de código ' + this.ticketCode,
          'Error'
        );
      }
    });
  }

  confirmAction() {}

  enableTicket(myForm: any) {
    this.TicketService.enablePromotionalTicket(this.ticket.validatorCode).subscribe((result) => {
      this.ticket = new PromotionalTicketMock();
      this.cardRead = false;
      this.formSearch.get('ticketCode').setValue('');
      this.formSearch.reset();
      myForm.resetForm();
    });
  }

  initForm() {
    this.formSearch = new FormGroup({
      ticketCode: new FormControl(null, [Validators.pattern('[0-9]*'), Validators.maxLength(18), Validators.required]),
    });
  }

  async onFullInput(codeTikcet) {
    if (this.formSearch.get('ticketCode').value != null) {
      var text = this.formSearch.get('ticketCode').value;
      if (text.length == 18) {
        this.onSubmit();
      }
    }
  }
}
