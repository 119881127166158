<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <div class="card">
          <div class="card-header">Calcular Ganancia</div>
          <div class="card-footer text-right">
            <button class="btn btn-primary ml-sm-4" (click)="calculateProfit()">Calcular</button>
          </div>
        </div>
        <div class="card">
          <div class="card-header">Deshabilitar todas las EGMs</div>
          <div class="card-footer text-right">
            <button class="btn btn-primary ml-sm-4" (click)="disabledAllEgms()">Deshabilitar</button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="card">
          <div class="card-header">Verificar EGMs desconectadas</div>
          <div class="card-footer text-right">
            <button class="btn btn-primary ml-sm-4" (click)="checkEgmStatus()">Calcular</button>
          </div>
        </div>
        <div class="card">
          <div class="card-header">Chequear Expiración</div>
          <div class="card-footer text-right">
            <button class="btn btn-primary ml-sm-4" (click)="checkVto()">Procesar</button>
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="card">
          <div class="card-header">Refrescar Contadores</div>
          <div class="card-footer text-right">
            <button class="btn btn-primary ml-sm-4" (click)="updateCounters()">
              Actualizar de currentCounter a counter
            </button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="card">
          <div class="card-header">Verificar MUID desconectadas</div>
          <div class="card-footer text-right">
            <button class="btn btn-primary ml-sm-4" (click)="checkCashMachineStatus()">Calcular</button>
          </div>
        </div>
        <!-- <div class="card">
                    <div class="card-header">
                      Chequear Expiración
                    </div>
                    <div class="card-footer text-right">
                        <button class="btn btn-primary ml-sm-4" (click)="checkVto()">
                                Procesar
                        </button>
                    </div>
                </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form role="form" class="w-100" [formGroup]="formEdit2" #formObject2="ngForm" id="form2">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formEdit2.get('message')"
                    [label]="'Mensaje'"
                    [type]="InputType.textarea"
                    [formSubmitted]="formObject2.submitted">
                  </app-common-input>
                </div>
                <div class="form-group d-flex col-12 justify-content-end">
                  <button class="btn btn-primary" (click)="onParse()">Parsear</button>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="form-group col-12">
                <!-- <app-common-input [control]="formEdit2.get('parsedMessage')" [label]="'Mensaje Parseado'"
                                    [type]="InputType.textarea" [formSubmitted]="formObject2.submitted" [rowsTextArea]="40">
                                </app-common-input> -->
                <label class="form-control-sm mb-05">{{ parsedLabel }}</label>
                <textarea
                  type="text"
                  class="form-control form-control-sm min-height-36"
                  [placeholder]="parsedLabel"
                  [formControl]="formEdit2.get('parsedMessage')"
                  rows="40">
                </textarea>
              </div>
              <app-ticket-printer-status></app-ticket-printer-status>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
