<!-- <div style="display: block;">
    <canvas baseChart width="100" height="20" [datasets]="lineChartData" [labels]="lineChartLabels"
        [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
    </canvas>
</div> -->
<div class="text-center">
  <div *ngIf="this.dataStatus">
    <canvas id="myChart" width="100" height="30"></canvas>
  </div>
  <div *ngIf="!this.dataStatus">No hay datos para mostrar</div>
</div>
