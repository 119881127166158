import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { InputTypeEnum } from '../../../shared/common-input/common-input.component';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { SessionManagmentViewModel, SettlementViewModel, SettlmentTypeEnum } from '../../../viewmodels/SessioModel';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-session-settlement-control',
  templateUrl: './session-settlement-control.component.html',
  styleUrls: ['./session-settlement-control.component.css'],
})
export class SessionSettlementControlComponent implements OnInit, OnDestroy {
  moment = moment;

  session: SessionManagmentViewModel = null;
  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;
  isLoeded: boolean = false;
  isAutomtic: boolean = false;
  settlementModel: SettlementViewModel = null;
  body: string;
  currencyCode: string;
  currencySym: string;
  formEdit: FormGroup;
  InputType = InputTypeEnum;
  @Input() cashRegisterId: number;
  @Input() specifTurnId: number;
  @Input() settlmentType: SettlmentTypeEnum = SettlmentTypeEnum.ReadOnly;

  callbackConfirm: () => void;
  autoRefreshFunc: any = null;
  canedit: boolean;
  dataDate: string = ' - ';
  showSettlement: boolean = false;
  title: string = '';
  @Output() sessionLoaded = new EventEmitter();
  previousUrl: string;
  readonly: boolean = false;

  constructor(
    public sessionService: SessionService,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private percentPipe: PercentPipe,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initForm();

    if (
      this.settlmentType == SettlmentTypeEnum.ReadOnly ||
      this.settlmentType == SettlmentTypeEnum.ReadOnlySpecifTurn
    ) {
      this.formEdit.get('observations').disable();
      this.readonly = true;
    }

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;

      if (this.settlmentType == SettlmentTypeEnum.ReadOnlySpecifTurn) {
        this.getSettlementCloseByTurnId(this.specifTurnId);
        console.log('model filled');
      } else {
        this.sessionService.GetSessionByCashRegisterId(this.cashRegisterId).subscribe((result) => {
          this.session = result.data;

          if (this.session == null) {
            this.router.navigate(['/session-management']);
          }

          if (!this.session?.canStartTurn && this.settlmentType == SettlmentTypeEnum.OpenTurn) {
            this.router.navigate(['/session-management']);
          }

          if (!this.session?.canEndTurn && this.settlmentType == SettlmentTypeEnum.EndTurn) {
            this.router.navigate(['/session-management']);
          }

          this.sessionLoaded.emit(this.session);
          this.title = this.sessionService.getPageTitle(this.session);
          this.getSettlementModel();
        });
      }
    });
    console.log('finish ');
  }

  public get SettlmentTypeEnumLocal(): typeof SettlmentTypeEnum {
    return SettlmentTypeEnum;
  }

  refreshShowSettlement() {
    this.showSettlement = this.settlementModel != null && this.settlementModel.bills.length > 0;
  }

  getSettlementCloseByTurnId(turnId: number) {
    //busco datos del arqueo
    this.sessionService.GetSettlementCloseByTurnId(turnId).subscribe((result) => {
      this.settlementModel = result.data;

      //this.title = 'a'; // this.sessionService.getPageTitle(this.session);

      this.bindSettlement();
    });
  }

  getSettlementModel() {
    //busco datos del arqueo
    this.sessionService.GetSessionSettlement(this.session.cashRegisterId).subscribe((result) => {
      this.settlementModel = result.data;

      if (this.settlementModel.errorReadingStock) {
        this.toastr.error('Error al consultar el stock de billetes y monedas en la máquina de cambio', 'Atención', {
          timeOut: 15000,
          enableHtml: true,
          progressBar: true,
        });
      }

      this.bindSettlement();
    });
  }

  bindSettlement() {
    this.formEdit.patchValue(this.settlementModel);

    this.settlementModel.bills.forEach((element) => {
      const billsFrom = this.formBuilder.group({
        count: [element.count, [Validators.pattern('[0-9]*'), Validators.required]],
        val: [element.val, Validators.required],
        total: [element.total, Validators.required],
        currencyVal: [element.currencyVal, Validators.required],
        currencyTypeId: [element.currencyTypeId, Validators.required],
      });

      if (this.isAutomtic) {
        billsFrom.disable();
      }

      this.bills.push(billsFrom);
    });

    this.settlementModel.coins.forEach((element) => {
      const coinsFrom = this.formBuilder.group({
        count: [element.count, [Validators.pattern('[0-9]*'), Validators.required]],
        val: [element.val, Validators.required],
        total: [element.total, Validators.required],
        currencyVal: [element.currencyVal, Validators.required],
        currencyTypeId: [element.currencyTypeId, Validators.required],
      });

      if (this.isAutomtic) {
        coinsFrom.disable();
      }

      this.coins.push(coinsFrom);
    });

    if (this.settlementModel.dataDate != null) {
      this.dataDate = moment(this.settlementModel.dataDate).format('DD/MM/yyyy, HH:mm:ss');
    }

    this.isLoeded = true;
    this.refreshShowSettlement();
  }

  ngOnDestroy() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);
  }
  private initForm() {
    this.formEdit = this.formBuilder.group({
      observations: new FormControl(null, Validators.maxLength(255)),
      idTurn: new FormControl(null),
      total: new FormControl(null),
      currentTotalTheoretical: new FormControl(null),
      difftotal: new FormControl(null),
      cashRegisterId: new FormControl(null),
      totalsBills: new FormControl(null),
      totalsCoins: new FormControl(null),

      coins: this.formBuilder.array([]),
      bills: this.formBuilder.array([]),
    });
  }

  get coins() {
    return this.formEdit.controls['coins'] as FormArray;
  }

  get bills() {
    return this.formEdit.controls['bills'] as FormArray;
  }

  onCoinChange(event, index) {
    if (
      event.target.value == null ||
      event.target.value == undefined ||
      event.target.value == '' ||
      isNaN(Number(event.target.value))
    ) {
      this.coins.controls[index].patchValue({
        total: 0,
      });
    } else {
      this.coins.controls[index].patchValue({
        total: event.target.value * this.coins.controls[index].value.currencyVal,
      });
    }
    this.changeTotals();
  }

  onBillChange(event, index) {
    if (
      event.target.value == null ||
      event.target.value == undefined ||
      event.target.value == '' ||
      isNaN(Number(event.target.value))
    ) {
      this.bills.controls[index].patchValue({
        total: 0,
      });
    } else {
      this.bills.controls[index].patchValue({
        total: event.target.value * this.bills.controls[index].value.currencyVal,
      });
    }
    this.changeTotals();
  }

  changeTotals() {
    this.settlementModel.totalsCoins = 0;
    this.settlementModel.totalsBills = 0;

    this.bills.controls.forEach((element) => {
      this.settlementModel.totalsBills += element.value.total;
    });

    this.coins.controls.forEach((element) => {
      this.settlementModel.totalsCoins += element.value.total;
    });

    this.settlementModel.total = this.settlementModel.totalsBills + this.settlementModel.totalsCoins;
    this.settlementModel.difftotal = this.settlementModel.total - this.settlementModel.currentTotalTheoretical;
  }

  goToConfirm() {
    this.callbackConfirm();
  }

  onCancelModal() {
    if (this.settlmentType == SettlmentTypeEnum.ReadOnly) {
      this.router.navigate(['/session-cashflow', this.session.idTurn]);
    } else if (this.settlmentType == SettlmentTypeEnum.OpenTurn) {
      this.body = `¿Está seguro que desea cancelar el arqueo y el inicio del turno?`;
      this.callbackConfirm = () => {
        this.router.navigate(['/session-management']);
      };
    } else {
      this.body = `¿Está seguro que desea cancelar el arqueo y el cierre del turno?`;
      this.callbackConfirm = () => {
        this.router.navigate(['/session-management']);
      };
    }
    this.confirmModal.openModalStatic();
  }

  onConfirmTurnSettlementModal() {
    if (this.settlmentType == SettlmentTypeEnum.OpenTurn) {
      this.body = `¿Está seguro que desea confirmar el arqueo e iniciar el turno?`;
      this.callbackConfirm = () => {
        //    this.settlementModel.observations = this.formEdit.get('observations').value;

        console.log('total antes', this.settlementModel.total);
        this.settlementModel = new SettlementViewModel(this.formEdit.value);

        console.log('total despues', this.settlementModel.total);

        this.changeTotals();
        console.log('total despues pach', this.settlementModel.total);

        this.sessionService.StartTurn(this.settlementModel).subscribe((result) => {
          if (result.error == null) {
            this.session = result.data;
            this.router.navigate(['/session-cashflow', this.session.idTurn]);
          }
        });
      };
    } else {
      this.body = `¿Está seguro que desea confirmar el arqueo y cerrar el turno?`;
      this.callbackConfirm = () => {
        //   this.settlementModel.observations = this.formEdit.get('observations').value;

        this.settlementModel = new SettlementViewModel(this.formEdit.value);

        this.sessionService.EndTurn(this.settlementModel).subscribe((result) => {
          if (result.error == null) {
            //  this.session = result.data;
            this.router.navigate(['/session-management']);
            return;
          }
        });
      };
    }
    this.confirmModal.openModalStatic();
  }

  autoRefresh() {
    if (this.autoRefreshFunc) clearTimeout(this.autoRefreshFunc);

    const timeIntevalSeconds = 90; //90 segundos

    this.autoRefreshFunc = setTimeout(async () => {
      await this.getSettlementModel();
    }, timeIntevalSeconds * 1000);
  }

  goToBack() {
    if (this.settlmentType == SettlmentTypeEnum.ReadOnlySpecifTurn) {
      this.router.navigate(['/reports/turn-detail-report']);
    } else {
      this.router.navigate(['/session-cashflow', this.session.idTurn]);
    }
  }
}
