import { Component, OnInit, ViewChild } from '@angular/core';
import { MainSidebarComponent } from './main-sidebar/main-sidebar.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  @ViewChild('sidebar') sidebar: MainSidebarComponent;
  showcontent: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  tenantLoaded() {
    this.showcontent = true;
  }
}
