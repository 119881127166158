<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12">
        <form
          role="form"
          class="w-100"
          style="display: contents"
          [formGroup]="formEdit"
          (ngSubmit)="onSubmit()"
          #formObject="ngForm">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('description')"
                    [label]="'Descripción'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-4">
                  <app-common-input
                    [control]="this.formEdit.get('apikey')"
                    [label]="'Api Key'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-4"></div>
              </div>
            </div>

            <div class="card-footer text-right">
              <button type="submit" class="btn btn-primary">{{ title }}</button>
              <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- /.row
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content -->
</div>
