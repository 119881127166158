import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { BarChartComponent } from 'src/app/common/components/shared/bar-chart/bar-chart.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { CoinInChartViewModel } from 'src/app/common/components/viewmodels/BarChartViewModel';
import { MinimalFilters, MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
import { DailyProfitChartViewModel } from 'src/app/common/components/viewmodels/LineChartViewModel';
import { DailyBalanceService } from 'src/app/services/daily-balance/daily-balance.service';

@Component({
  selector: 'app-daily-profit-report',
  templateUrl: './daily-profit-report.component.html',
  styleUrls: ['./daily-profit-report.component.css'],
})
export class DailyProfitReportComponent implements OnInit {
  @ViewChild(BarChartComponent, { static: false }) chart: BarChartComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<DailyProfitChartViewModel>;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;

  formSearch: FormGroup;
  items: Array<BreadcrumbItem> = [];
  dataStatus: boolean = false;
  title: string = 'Reporte de Ganancia Diaria por Día Cerrado';
  public data: DailyProfitChartViewModel;

  public static getName() {
    return 'DailyProfitReportComponent';
  }
  constructor(private router: Router, private DailyBalanceService: DailyBalanceService) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem(this.router.url, 'Estadísticas'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Ganancia Diaria por Día Cerrado'));
    this.initForm();
  }

  async setChartData(filters: MinimalFilters): Promise<void> {
    let service = this.DailyBalanceService;

    var result = await service.getDailyProfitGraphicReport(filters).toPromise();
    this.data = result.data;
  }

  async updateChart() {
    await this.setChartData(this.getFilters());
    this.chart.reload(this.data);
  }
  onSubmit() {
    this.dataStatus = true;
    this.updateChart();
  }

  getFilters() {
    var filters: MinimalFilters = new MinimalFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    if (this.formSearch.get('egmUid').value != null) {
      filters.egm = this.formSearch.get('egmUid').value;
    }
    return filters;
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl(null),
      from: new FormControl(),
      to: new FormControl(),
    });
  }
}
