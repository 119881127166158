import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { DataConsts } from 'src/app/common/components/viewmodels/Casino';
import {
  CancellationReasons,
  CashableType,
  PreInserted,
  TicketFamily,
  TicketState,
  TicketType,
} from 'src/app/common/components/viewmodels/Enums';
import { SessionManagmentViewModel } from 'src/app/common/components/viewmodels/SessioModel';
import { TicketMock, TicketReplacement } from 'src/app/common/components/viewmodels/TicketMock';
import {
  TicketCancelViewModel,
  TicketReplacementViewModel,
} from 'src/app/common/components/viewmodels/TicketReplacementViewModel';
import { TicketTracker } from 'src/app/common/components/viewmodels/TicketTracker';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { SessionOldReportService } from 'src/app/services/session-report/session-report.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-ticket-supervisor',
  templateUrl: './ticket-supervisor.component.html',
  styleUrls: ['./ticket-supervisor.component.css'],
})
export class TicketSupervisorComponent implements OnInit {
  @ViewChild('replacementModal') replacementModal: any;
  @ViewChild(ConfirmModalComponent) confirmModal: ConfirmModalComponent;
  ticketCode: number = 0;
  allSum: number = 0;
  body: string = '¿Está seguro que desea reemplazar el ticket?';
  cardRead: boolean = false;
  formSearch: FormGroup;
  divisionForm: FormGroup;
  formDuplicate: FormGroup;
  ticketTracker: TicketTracker = new TicketTracker();

  title: string = 'Reemplazo Ticket TITO - Voucher';
  items: Array<BreadcrumbItem> = [];
  date: moment.Moment = moment(new Date());
  isDuplicated: boolean = false;
  isUsed: boolean = false;
  InputType = InputTypeEnum;
  CashableType = CashableType;
  TicketState = TicketState;
  TicketFamily = TicketFamily;
  TicketType = TicketType;
  PreInserted = PreInserted;
  CancellationReasons = CancellationReasons;

  formReplacementReason: FormGroup;
  replacementViewModel: TicketReplacementViewModel;
  ticketcancelViewModel: TicketCancelViewModel;
  cancellationReason: Array<IdTextModel> = [];

  regex: RegExp = new RegExp('_', 'g');
  currencyCode: string;
  currencySym: string;
  moment = moment;

  estoyAnulando: boolean = false;
  paymentBody: string;
  titleModal: string;
  labelModal: string;
  replacementReason: number;
  canCancel: boolean = false;
  loadpage: boolean = false;
  headerTitle: string = '';
  session: SessionManagmentViewModel = new SessionManagmentViewModel();
  maxValue: number = 0;
  showDivision: boolean = false;
  turnId: number = 0;
  public static getName() {
    return 'TicketSupervisorComponent';
  }

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public TicketService: TicketService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public SessionService: SessionOldReportService,
    public currencyPipe: CurrencyPipe,
    public CasinoService: CasinoService,
    public printerService: PrinterService,
    public sessionService: SessionService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    if (this.activatedRoute.snapshot.params.id != null && this.activatedRoute.snapshot.params.id != 0) {
      let paramTurnsId = this.activatedRoute.snapshot.params.id;

      this.turnId = Number(paramTurnsId);
      this.loadpage = true;
      if (isNaN(this.turnId)) {
        this.goToErrorPage();
      }

      this.sessionService.GetSessionByTurnId(this.turnId).subscribe((result) => {
        this.session = result.data;
        this.maxValue = this.session.cashinLimit;

        if (this.session?.idTurn === null) {
          this.router.navigate(['/session-management']);
        }
        this.headerTitle = this.sessionService.getPageTitle(this.session);
      });
    } else {
      this.goToErrorPage();
    }

    //this.headerTitle = this.sessionService.getPageTitle(this.session);
    this.cancellationReason = this.TicketService.getCancellationReason(this.cancellationReason);
  }

  onSubmit() {
    if (this.formSearch.valid) {
      this.getTicket();
    }
  }

  goToErrorPage(): void {
    this.router.navigate(['/session-management']);
  }

  GoToCashFlow() {
    this.router.navigate(['/session-cashflow', this.session.idTurn]);
  }

  getAllTicketReplacement() {
    if (!this.ticketTracker.ticket.ticketReplacement || this.ticketTracker.ticket.ticketReplacement.length === 0) {
      return '';
    }

    return this.ticketTracker.ticket.ticketReplacement
      .map((replacement: TicketReplacement) => replacement.replacementTicket.validatorCode)
      .join(' - ');
  }

  async getTicket() {
    var result = await this.TicketService.getLastTicketTracker(this.formSearch.get('ticketCode').value).toPromise();

    if (result.data != null) {
      this.ticketTracker = result.data;

      this.initFormDivision();

      this.cardRead = true;

      //no puedo reemplazar tickets usados o que ya fueron reemplazados por otro o que estan anulados
      if (
        this.ticketTracker.state == TicketState.Canjeado ||
        this.ticketTracker.ticket.ticketReplacement.length > 0 ||
        this.ticketTracker.state == TicketState.Anulado
      ) {
        this.isUsed = true;
        this.showDivision = false;
      } else {
        this.isUsed = false;
      }

      if (this.ticketTracker.state == TicketState.Canjeado || this.ticketTracker.state == TicketState.Anulado) {
        this.canCancel = false;
      } else {
        this.canCancel = true;
      }
    } else this.notificationService.showError('No se encontró un ticket con ese código', 'Error');
  }

  onContinueButton() {
    this.replacementReason = parseInt(this.formReplacementReason.get('replacementReason').value);

    switch (this.replacementReason) {
      case CancellationReasons.Expiracion:
        this.body += ' expiración?';
        break;
      case CancellationReasons.Anulacion:
        this.body += ' anulación?';
        break;
      case CancellationReasons.Error_de_fecha_Egm:
        this.body += ' error de fecha EGM?';
        break;
      case CancellationReasons.No_impreso:
        this.body += ' no impresión?';
        break;
      case CancellationReasons.Ticket_pending:
        this.body += ' ticket pendiente?';
        break;
      case CancellationReasons.Duplicado:
        this.body += ' duplicado?';
        break;
      case CancellationReasons.HandPay_reemplazado:
        this.body += ' handPay reemplazado?';
        break;
      case CancellationReasons.Error_en_familia_de_ticket:
        this.body += ' Error en familia ticket?';
        break;
      case CancellationReasons.Error_en_Codigo:
        this.body += ' Error en Cdigo?';
        break;

      case CancellationReasons.Canje:
        this.body += ' Canje?';
        break;
    }

    this.confirmModal.openModal();
  }

  async confirm() {
    if (!this.estoyAnulando) {
      if (await this.printerService.isPrinterReady()) {
        this.replacementViewModel = new TicketReplacementViewModel();
        this.replacementViewModel.turnId = this.turnId;
        this.replacementViewModel.validatorCode = this.formSearch.get('ticketCode').value;
        this.replacementViewModel.replacementReason = this.formReplacementReason.get('replacementReason').value;

        //si hubo fraccionamiento, completo:
        if (this.showDivision) {
          const dynamicInputs = this.divisionForm.get('dynamicInputs') as FormArray;
          this.replacementViewModel.ticketAmount = dynamicInputs.controls.map((control) => {
            return Number((control.value * DataConsts.precisionFactor).toFixed(2));
          });
        } else {
          this.replacementViewModel.ticketAmount = [
            Number((this.ticketTracker.ticket.formattedAmount * DataConsts.precisionFactor).toFixed(2)),
          ];
        }

        this.TicketService.replacementTicket(this.replacementViewModel).subscribe(async (result) => {
          if (result?.data?.tickets) {
            for (let index = 0; index < result?.data?.tickets.length; index++) {
              await this.printerService.print(result?.data?.tickets[index], index);

              // Add a 3-second delay using setTimeout
              await new Promise((resolve) => setTimeout(resolve, 3000));
            }
          }
          this.cardRead = false;
          this.formSearch.reset();
          this.formSearch.markAsUntouched();
          this.GoToCashFlow();
        });
      }
    } else {
      this.cardRead = false;

      this.ticketcancelViewModel = new TicketCancelViewModel();
      this.ticketcancelViewModel.turnId = this.turnId;
      this.ticketcancelViewModel.validatorCode = this.formSearch.get('ticketCode').value;
      this.ticketcancelViewModel.replacementReason = this.formReplacementReason.get('replacementReason').value;
      this.TicketService.cancelTicketBySupervisor(this.ticketcancelViewModel).subscribe();
      this.GoToCashFlow();
    }
  }

  reload() {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/tito/ticket-supervisor']);
    });
    // this.cardRead = false;
    // this.formSearch.reset();
  }

  anularTicket() {
    if (this.ticketTracker.state == TicketState.Anulado) {
      this.notificationService.showError('El ticket ya está anulado', 'Error');
    } else {
      this.titleModal = 'Anulación de Ticket';
      this.labelModal = 'Motivo de la anulación';
      this.body = '¿Está seguro que desea anular el ticket por ';
      this.estoyAnulando = true;
      this.openReplacementReason();
    }
  }

  replaceTicket() {
    this.body = '¿Está seguro que desea reemplazar el ticket por ';
    this.titleModal = 'Reemplazo Ticket';
    this.labelModal = 'Motivo del reemplazo';
    this.estoyAnulando = false;
    this.openReplacementReason();
  }

  openReplacementReason() {
    // if (this.ticketTracker.state == TicketState.Anulado) {
    //   this.notificationService.showError('El ticket está anulado', 'Error');
    // } else {
    this.modalService.open(this.replacementModal, { ariaLabelledBy: 'replacementModal' });
    //}
  }

  dismissModal() {
    this.formReplacementReason.reset();
    this.modalService.dismissAll();
  }

  initForm() {
    this.formSearch = new FormGroup({
      ticketCode: new FormControl(null, [
        Validators.pattern('[0-9]*'),
        Validators.minLength(18),
        Validators.maxLength(18),
        Validators.required,
      ]),
    });

    this.formDuplicate = new FormGroup({
      uid: new FormControl(null, [Validators.pattern('[0-9]*'), Validators.required]),
      formattedAmount: new FormControl(null, [Validators.required, Validators.pattern('^d + (.|,) d{ 2}$')]),
      ticketNumber: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
    });
    this.formReplacementReason = new FormGroup({
      replacementReason: new FormControl(null, Validators.required),
    });
  }

  initFormDivision() {
    this.divisionForm = this.fb.group({
      dynamicInputs: this.fb.array([this.fb.control('', this.getValidators())], {
        validators: [this.totalAmountValidator()],
      }),
    });

    this.initDivision();
  }

  getValidators() {
    return [
      Validators.required,
      Validators.min(0.01),
      Validators.max(this.maxValue),
      Validators.pattern(/^-?[0-9]+(\.[0-9]{1,2})?$/),
    ];
  }

  totalAmountValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dynamicInputs = control as FormArray;
      if (dynamicInputs != null && dynamicInputs.controls.length > 0) {
        var totalAmount = dynamicInputs.controls.reduce((sum, currentControl) => {
          this.allSum = Number((sum + (parseFloat(currentControl.value) || 0)).toFixed(2));
          return this.allSum;
        }, 0);

        totalAmount = Number(totalAmount.toFixed(2));
        const originalAmount = this.ticketTracker.ticket.formattedAmount;

        if (totalAmount !== originalAmount) {
          return { totalAmountMismatch: true };
        }
      }
      return null;
    };
  }

  initDivision() {
    const amount = this.ticketTracker.ticket.formattedAmount;

    const dynamicInputs = this.divisionForm.get('dynamicInputs') as FormArray;
    dynamicInputs.clear();

    let remaining = amount;

    while (remaining > 0) {
      if (remaining >= this.maxValue) {
        dynamicInputs.push(this.fb.control(Number(this.maxValue.toFixed(2)), this.getValidators()));
        remaining -= this.maxValue;
      } else {
        dynamicInputs.push(this.fb.control(Number(remaining.toFixed(2)), this.getValidators()));
        remaining = 0;
      }
    }
    this.showDivision = true;
  }

  addInput() {
    const dynamicInputs = this.divisionForm.get('dynamicInputs') as FormArray;

    var defaultValue = 0;
    if (this.ticketTracker.ticket.formattedAmount >= this.allSum) {
      defaultValue = this.ticketTracker.ticket.formattedAmount - this.allSum;
    }

    dynamicInputs.push(this.fb.control(defaultValue.toFixed(2), this.getValidators()));
    this.divisionForm.markAsTouched();
  }

  removeInput() {
    const dynamicInputs = this.divisionForm.get('dynamicInputs') as FormArray;
    if (dynamicInputs.controls.length > 1) {
      dynamicInputs.removeAt(dynamicInputs.controls.length - 1);
    }
  }

  changeTotal(index: number) {
    const dynamicInputs = this.divisionForm.get('dynamicInputs') as FormArray;
    const curremt = dynamicInputs.at(index).value;

    // const amount = this.divisionForm.get('amount').value;
    // this.divisionForm.get('amount').setValue(amount);
    // this.initDivision();
  }
}
