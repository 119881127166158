import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import { InputTypeEnum } from 'src/app/common/components/shared/common-input/common-input.component';
import { tableStatus } from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { Model } from 'src/app/common/components/viewmodels/Model';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';
import { ModelService } from 'src/app/services/model/model.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-edit-model',
  templateUrl: './edit-model.component.html',
  styleUrls: ['./edit-model.component.css'],
})
export class EditModelComponent implements OnInit {
  formEdit: FormGroup;
  items: Array<BreadcrumbItem> = [];
  title: string = 'Crear modelo';
  model: Model = new Model();
  InputType = InputTypeEnum;
  isEdit: boolean;
  parameter: tableStatus = null;

  public static getName() {
    return 'EditModelComponent';
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private paramService: ParametersService<tableStatus>,
    private ValidationService: ValidationService,
    private ModelService: ModelService,
    private ManufacturerService: ManufacturerService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.parameter = this.paramService.getAndDeleteParameters();

    if (this.activatedRoute.snapshot.params.id != null) {
      this.ModelService.getModel(this.activatedRoute.snapshot.params.id).subscribe((result) => {
        this.formEdit.patchValue(result.data);
        this.formEdit.get('manufacturer').setValue(result.data.manufacturer.id);
      });
      this.title = 'Editar modelo';
      this.isEdit = true;
    }

    this.items.push(new BreadcrumbItem('/machines/models', 'Máquinas'));
    this.items.push(new BreadcrumbItem('/machines/models', 'Modelos'));
    this.items.push(new BreadcrumbItem('', this.title));
  }

  cancel() {
    this.paramService.saveParameters(this.parameter);
    this.router.navigateByUrl('machines/models');
  }

  async onSubmit() {
    if (this.formEdit.valid) {
      this.model = new Model(this.formEdit.value);

      if (this.model.id == null) {
        this.model.id = '00000000-0000-0000-0000-000000000000';
      }

      await this.getManufacturer();
      this.paramService.saveParameters(this.parameter);
      this.ModelService.createOrEdit(this.model).subscribe((result) => {
        this.router.navigate(['/machines/models']);
      });
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  async getManufacturer() {
    var result = await this.ManufacturerService.getManufacturer(this.formEdit.get('manufacturer').value).toPromise();
    this.model.manufacturer = result.data;
  }

  private initForm() {
    this.formEdit = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.ValidationService.getMaxLenghtAllowed()),
      ]),
      manufacturer: new FormControl(null, Validators.required),
    });
  }
}
