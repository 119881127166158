import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { EgmComboComponent } from 'src/app/common/business/egm-combo/egm-combo.component';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { MultiselectFilters } from 'src/app/common/components/viewmodels/Filters';
//import * as moment from 'moment-timezone';
import { CountersStatus } from 'src/app/common/components/viewmodels/Enums';
import { PercentPipe } from '@angular/common';
import { GametypesComboComponent } from 'src/app/common/components/business/gametypes-combo/gametypes-combo.component';
import { ModelsComboComponent } from 'src/app/common/components/business/models-combo/models-combo.component';
import { CountersMock } from 'src/app/common/components/viewmodels/CountersMock';
import { DatatableMultiFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { CountersService } from 'src/app/services/counters/counters.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import {
  EgmProfitComponent,
  EgmProfitParameters,
} from 'src/app/common/components/business/egm-profit/egm-profit.component';
import { CalculatedCountersViewModel } from 'src/app/common/components/viewmodels/CalculatedCountersViewModel';
import { ManufacturersComboComponent } from 'src/app/common/components/business/manufacturers-combo/manufacturers-combo.component';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
const moment = require('moment');

@Component({
  selector: 'app-counters-info',
  templateUrl: './counters-info.component.html',
  styleUrls: ['./counters-info.component.css'],
})
export class CountersInfoComponent implements OnInit, AfterViewInit {
  @ViewChild(ModelsComboComponent) modelsCombo: ModelsComboComponent;
  @ViewChild(GametypesComboComponent) gametypesCombo: GametypesComboComponent;
  @ViewChild(EgmComboComponent) egmsCombo: EgmComboComponent;
  @ViewChild(ManufacturersComboComponent) manufacturersCombo: ManufacturersComboComponent;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CalculatedCountersViewModel>;

  viewCombo: Array<IdTextModel> = [];
  locationCombo: Array<IdTextModel> = [];

  title: string = 'Contadores';

  items: Array<BreadcrumbItem> = [];

  public data: commonTableData<CalculatedCountersViewModel>;
  param: EgmProfitParameters;
  formSearch: FormGroup;
  paramStatus: boolean;
  dateRange: string = '-';
  billDescriptionList: string[] = ['', '', '', '', '', '', '', ''];
  private subscription: Subscription;
  public static getName() {
    return 'CountersInfoComponent';
  }
  constructor(
    private router: Router,
    private CountersService: CountersService,
    private paramService: ParametersService<EgmProfitParameters>,
    private percentPipe: PercentPipe,
    private egmSerivce: EgmServiceService
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Listados'));
    this.items.push(new BreadcrumbItem(this.router.url, 'Contadores'));

    this.initForm();

    this.param = this.paramService.getAndDeleteParameters();

    this.initGrid();

    if (this.param != null) {
      this.formSearch.get('manufacturers').setValue([this.param.idManufacturer]);
      this.formSearch.get('models').setValue([this.param.idModel]);
      this.formSearch.get('gameTypes').setValue([this.param.idGameType]);
      this.formSearch.get('egmUid').setValue([this.param.idEgm]);

      this.formSearch.get('from').setValue(this.param.from);
      this.formSearch.get('to').setValue(this.param.to);
      // this.onSubmit();
      this.paramStatus = true;

      //  this.data.loadFirstTime = false;
    }

    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          // Capturar el evento "go back" del navegador

          this.paramService.saveParameters(this.param);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.paramStatus) {
      this.onSubmit();
    }
  }
  initGrid(): void {
    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.loadFirstTime = false;
    this.data.showAll = true;
    const that = this;

    this.data.columns = [
      {
        data: 'counters.countersStatus',
        title: 'Estado',
        render: function (data: number, type, row) {
          return CountersStatus[data];
        },
      },
      {
        data: 'counters.countersDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'counters.period', title: 'Período' },

      {
        data: 'counters.isInCollection',
        title: 'Recaudación',
        render: function (data, type, row) {
          return data == true ? 'Sí' : 'No';
        },
      },

      { data: 'counters.egm.uid', title: 'EGM' },
      { data: 'counters.egm.manufacturer.name', title: 'Fabricante' },
      { data: 'counters.egm.model.name', title: 'Modelo' },
      { data: 'counters.egm.egmConfig.formattedDenomination', title: 'Denominación' },
      { data: 'counters.coinIn', title: 'Coin_IN' },
      { data: 'counters.coinOut', title: 'Coin_OUT' },
      { data: 'counters.jackpot', title: 'Total Jackpot' },
      { data: 'counters.totalDrop', title: 'Total Drop' },
      { data: 'counters.cancelledCredits', title: 'Cancelled Credits' },
      { data: 'counters.handpayCC', title: 'Handpay CC' },
      { data: 'counters.billIn', title: 'Bill_IN' },
      { data: 'counters.billOut', title: 'Bill_OUT' },
      { data: 'counters.totalTicketIn', title: 'Total TCK_IN' },
      { data: 'counters.totalTicketOut', title: 'Total TCK_OUT' },
      { data: 'counters.coinDrop', title: 'Coin Drop' },
      { data: 'counters.trueCoinIn', title: 'True Coin In' },
      { data: 'counters.trueCoinOut', title: 'True Coin Out' },
      { data: 'counters.eftIn', title: 'EFT In' },
      { data: 'counters.aftIn', title: 'AFT In' },
      { data: 'counters.aftOut', title: 'AFT Out' },
      { data: 'counters.gamesPlayed', title: 'J. Jugados' },
      { data: 'counters.gamesWon', title: 'J. Ganados' },
      { data: 'counters.gamesLost', title: 'J. Perdidos' },
      { data: 'counters.billM1Q', title: 'Bill M1 Q' },
      { data: 'counters.billM2Q', title: 'Bill M2 Q' },
      { data: 'counters.billM3Q', title: 'Bill M3 Q' },
      { data: 'counters.billM4Q', title: 'Bill M4 Q' },
      { data: 'counters.billM5Q', title: 'Bill M5 Q' },
      { data: 'counters.billM6Q', title: 'Bill M6 Q' },
      { data: 'counters.billM7Q', title: 'Bill M7 Q' },
      { data: 'counters.billM8Q', title: 'Bill M8 Q' },
      { data: 'counters.billToDrop', title: 'Bill To Drop' },
      { data: 'counters.billToRecycle', title: 'Bill To Recycle' },
      { data: 'counters.mptp', title: 'MPTP' },
      { data: 'counters.mppp', title: 'MPPP' },
      { data: 'counters.mpebp', title: 'MPEBP' },
      { data: 'counters.aptp', title: 'APTP' },
      { data: 'counters.appp', title: 'APPP' },
      { data: 'counters.apebp', title: 'APEBP' },
      { data: 'counters.doorQ', title: 'Door Q' },
      { data: 'counters.powerReset', title: 'Power Reset' },

      { data: 'counters.gamesSincePowerUp', title: 'Games Since PowerUp' },
      { data: 'counters.gamesSinceDoorClosed', title: 'Games Since Door Closed' },
      { data: 'counters.currentHopperLevel', title: 'Current Hopper Level' },
      { data: 'counters.totalHandPaidCredits', title: 'Total HandPaid Credits' },
      { data: 'counters.reserved1', title: 'Reserved1' },
      { data: 'counters.reserved2', title: 'Reserved2' },
      { data: 'counters.reserved3', title: 'Reserved3' },
      { data: 'counters.reserved4', title: 'Reserved4' },
      { data: 'counters.reserved5', title: 'Reserved5' },
      { data: 'counters.reserved6', title: 'Reserved6' },
      { data: 'counters.reserved7', title: 'Reserved7' },
      { data: 'counters.reserved8', title: 'Reserved8' },
      { data: 'counters.reserved9', title: 'Reserved9' },
      { data: 'counters.reserved10', title: 'Reserved10' },
      { data: 'counters.reserved11', title: 'Reserved11' },
      { data: 'counters.reserved12', title: 'Reserved12' },
      { data: 'counters.reserved13', title: 'Reserved13' },
      { data: 'counters.reserved14', title: 'Reserved14' },
      { data: 'counters.reserved15', title: 'Reserved15' },
      { data: 'counters.reserved16', title: 'Reserved16' },
    ];
  }

  setGridData(filters: MultiselectFilters): void {
    let service = this.CountersService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CalculatedCountersViewModel>>> {
      var DataTableFilters = new DatatableMultiFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getCounterssWithMultiFilters(DataTableFilters);
    };
  }

  updateTable(filters: MultiselectFilters) {
    this.setGridData(filters);
    this.filterString();
    this.table.reload();
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl([]),
      manufacturers: new FormControl([]),
      models: new FormControl([]),
      gameTypes: new FormControl([]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  getFilters() {
    var filters: MultiselectFilters = new MultiselectFilters();
    filters.to = this.formSearch.get('to').value;
    filters.from = this.formSearch.get('from').value;
    if (this.formSearch.get('egmUid').value.length > 0) {
      filters.egms = this.formSearch.get('egmUid').value;
    } else {
      if (!this.formSearchNull) {
        filters.egms = this.egmsCombo.getEgmComboAsIdArray();
      }
    }
    return filters;
  }
  onSubmit() {
    this.dateRange =
      moment(this.formSearch.get('from').value).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(this.formSearch.get('to').value).format('DD/MM/yyyy, HH:mm:ss').toString();

    this.updateTable(this.getFilters());
  }

  async getModels() {
    //me guardo los que ya tenia seleccioando para recuperar:
    let currentModel = this.formSearch.get('models').value;
    this.formSearch.get('models').setValue([]);

    await this.modelsCombo.fillComboWithManufacturersId(this.formSearch.get('manufacturers').value);

    //si lo que habia seleccionado no esta en la lista de modelos actualizada, lo borro:
    //intento recuperar lo que habia seleccioando:
    this.refreshModels(currentModel);
    this.getGameTypes();
  }

  async getGameTypes() {
    //me guardo los que ya tenia seleccioando para recuperar:
    let currentModel = this.formSearch.get('gameTypes').value;
    this.formSearch.get('gameTypes').setValue([]);

    //sino tengo modelo, le paso todos los del combo para filtrar solo esos:
    let modelSelected = this.formSearch.get('models').value;
    if (modelSelected.length == 0) {
      modelSelected = this.modelsCombo.getModelComboAsIdArray();
    }

    await this.gametypesCombo.fillComboWithModelsId(modelSelected);

    this.refreshGames(currentModel);
    this.updateEgms();
  }

  refreshModels(currentModel: any) {
    //si lo que habia seleccionado no esta en la lista de modelos actualizada, lo borro:
    //intento recuperar lo que habia seleccioando:
    if (currentModel.length > 0) {
      let comboArray = this.modelsCombo.getModelComboAsIdArray();
      let filterModel = comboArray.filter((item) => currentModel.includes(item));
      this.formSearch.get('models').setValue(filterModel);
    }
  }

  refreshGames(currentModel: any) {
    //si lo que habia seleccionado no esta en la lista de modelos actualizada, lo borro:
    //intento recuperar lo que habia seleccioando:
    if (currentModel.length > 0) {
      let comboArray = this.gametypesCombo.getGameComboAsIdArray();
      let filterModel = comboArray.filter((item) => currentModel.includes(item));
      this.formSearch.get('gameTypes').setValue(filterModel);
    }
  }

  updateEgms() {
    // this.formSearch.get('egmUid').setValue([]);
    if (this.formSearchNull) {
      this.egmsCombo.fillCombo();
    } else {
      var filters: MultiselectFilters = new MultiselectFilters();
      filters.manufacturers = this.formSearch.get('manufacturers').value;
      filters.models = this.formSearch.get('models').value;
      filters.gametypes = this.formSearch.get('gameTypes').value;
      filters.egms = this.formSearch.get('egmUid').value;
      this.egmsCombo.fillEgmsWithMultiselectFilters(filters);
    }
  }

  get formSearchNull(): boolean {
    return (
      this.formSearch.get('gameTypes').value.length == 0 &&
      this.formSearch.get('models').value.length == 0 &&
      this.formSearch.get('manufacturers').value.length == 0
    );
  }

  filterString() {
    let aux: string = ' Filtros: - ';
    if (this.dateRange != null) {
      aux += ' Rango: ' + this.dateRange + ' - ';
    }
    if (this.formSearch.get('manufacturers').value.length > 0) {
      aux += ' Fabricante: ' + this.manufacturerFilters + ' - ';
    }
    if (this.formSearch.get('models').value.length > 0) {
      aux += ' Modelo: ' + this.modelFilters + ' - ';
    }
    if (this.formSearch.get('gameTypes').value.length > 0) {
      aux += ' Juegos: ' + this.gamesFilters + ' - ';
    }
    if (this.formSearch.get('egmUid').value.length > 0) {
      aux += ' Egms: ' + this.egmFilters;
    }
    // aux += "-";
    if (this.table != undefined) this.table.setFilterString(aux);
  }

  get manufacturerFilters(): string {
    let combo = this.manufacturersCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('manufacturers').value.length; index++) {
      const element = this.formSearch.get('manufacturers').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('manufacturers').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get modelFilters(): string {
    let combo = this.modelsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('models').value.length; index++) {
      const element = this.formSearch.get('models').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('models').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get gamesFilters(): string {
    let combo = this.gametypesCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('gameTypes').value.length; index++) {
      const element = this.formSearch.get('gameTypes').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('gameTypes').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }

  get egmFilters(): string {
    let combo = this.egmsCombo.getCombo();
    let aux: string = '';
    for (let index = 0; index < this.formSearch.get('egmUid').value.length; index++) {
      const element = this.formSearch.get('egmUid').value[index];
      let name = combo.find((e) => e.id == element).text;
      aux += name;
      if (index < this.formSearch.get('egmUid').value.length - 1) {
        aux += ',';
      }
    }
    return aux;
  }
}
