<common-header [title]="title" [items]="items"></common-header>
<!-- Main content -->
<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <form role="form" [formGroup]="roomForm" #formObject="ngForm" (ngSubmit)="onSubmit()">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6">
                  <app-common-input
                    [control]="this.roomForm.get('name')"
                    [label]="'Nombre de la sala'"
                    [formSubmitted]="formObject.submitted"
                    [type]="inputType.text">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6">
                  <app-common-input
                    [control]="this.roomForm.get('roomStart')"
                    [label]="'Inicio de la sala'"
                    [formSubmitted]="formObject.submitted"
                    [type]="inputType.date">
                  </app-common-input>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer text-right">
              <app-confirm-buttons (onCancel)="cancel()"></app-confirm-buttons>
            </div>
          </form>
        </div>
        <!-- /.card -->
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content -->
