<common-header title="Conexión Impresora Tickets" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <!-- <div class="card-header">
                        <h3 class="card-title" *ngIf="!PrinterService.isBrowserSupported">
                            Su navegador no permite usar la impresora
                        </h3>
                        <h3 class="card-title" *ngIf="PrinterService.isBrowserSupported">
                            <p>Su navegador soporta impresora</p>
                        </h3>
                        <div class="card-tools" *ngIf="PrinterService.isBrowserSupported">
                            <button class="btn btn-primary" (click)="connectCOM()"
                                [class.disabled]="PrinterService.processing || PrinterService.currentPort != null"><i class="fas fa-plus"></i>
                                Conectar</button>
                        </div>
                    </div> -->
          <div class="card-body" *ngIf="printerService.isBrowserSupported">
            <div class="form-row">
              <form role="form" class="w-100" style="display: contents" [formGroup]="formEdit" #formObject="ngForm">
                <div class="form-group col-12 col-sm-6 col-xl-2">
                  <common-input-select
                    label="Bauds Rate"
                    [list]="baudsList"
                    formControlName="baudRate"
                    (valueChanged)="updateSettings()"
                    class="form-control-sm"
                    [allowClear]="false"
                    [model]="printerService.printerOptions.baudRate">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-1">
                  <common-input-select
                    label="Data bits"
                    [list]="dataBitsList"
                    formControlName="dataBits"
                    (valueChanged)="updateSettings()"
                    class="form-control-sm"
                    [allowClear]="false">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-2">
                  <common-input-select
                    label="Paridad"
                    [list]="parityList"
                    formControlName="parity"
                    (valueChanged)="updateSettings()"
                    class="form-control-sm"
                    [allowClear]="false">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-1">
                  <common-input-select
                    label="Stop bits"
                    [list]="stopBitsList"
                    formControlName="stopBits"
                    (valueChanged)="updateSettings()"
                    class="form-control-sm"
                    [allowClear]="false">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-2">
                  <common-input-select
                    label="Flow control"
                    [list]="flowControlList"
                    formControlName="flowControl"
                    (valueChanged)="updateSettings()"
                    class="form-control-sm"
                    [allowClear]="false">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-2">
                  <common-input-select
                    label="Usar impresora simulada"
                    [list]="isFakeList"
                    formControlName="isFake"
                    (valueChanged)="updateSettings()"
                    class="form-control-sm"
                    [allowClear]="false">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6 col-xl-2 d-flex align-items-center">
                  <button type="button" class="btn btn-primary mr-2" (click)="testPrinter()" style="min-width: 100px">
                    Imprimir Test
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="card-body" *ngIf="!printerService.isBrowserSupported">
            <div class="form-row">
              <form role="form" class="w-100" style="display: contents" [formGroup]="formEdit" #formObject="ngForm">
                <div class="form-group col-12 col-sm-6 col-xl-2">
                  <common-input-select
                    label="Usar impresora simulada"
                    [list]="isFakeList"
                    formControlName="isFake"
                    (valueChanged)="updateSettings()"
                    class="form-control-sm"
                    [allowClear]="false">
                  </common-input-select>
                </div>
              </form>
            </div>
            <div class="form-row">
              <div class="form-group col-12 col-sm-6 col-xl-2">
                <app-info-display [label]="'Estado'" [model]="printerService.getStatusDesc()"> </app-info-display>
              </div>
              <div class="form-group col-12 col-sm-6 col-xl-2">
                <app-info-display
                  [label]="'Error'"
                  *ngIf="printerService.status?.isError"
                  [model]="printerService.getErrorDesc()">
                </app-info-display>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-ticket-printer-status [showFirmware]="true"></app-ticket-printer-status>
  </div>
</div>
