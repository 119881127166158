import { SerialOptions, SerialPort, SerialPortInfo } from './serial';

export class FakePrinter implements SerialPort {
  open(options: SerialOptions): Promise<void> {
    throw new Error('Method not implemented.');
  }
  close(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  readable: ReadableStream<any>;
  writable: WritableStream<any>;
  in: ReadableStream<any>;
  out: WritableStream<any>;
  getInfo(): SerialPortInfo {
    var result = new FakeSerialPortInfo();
    result.serialNumber = 'Simulation Printer';
    result.manufacturer = 'Simulation Printer';
    result.locationId = 'Simulation Printer';
    result.vendorId = 'Simulation Printer';
    result.vendor = 'Simulation Printer';
    result.productId = 'Simulation Printer';
    result.product = 'Simulation Printer';

    return result;
  }
}

class FakeSerialPortInfo implements SerialPortInfo {
  serialNumber: string;
  manufacturer: string;
  locationId: string;
  vendorId: string;
  vendor: string;
  productId: string;
  product: string;
}
