<common-header [title]="this.title" [items]="items"></common-header>

<div class="content" *ngIf="loadedForm">
  <div class="container-fluid">
    <div class="form-group row"></div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table [model]="data" (onCustomButton)="onCustomButton($event)" (onSearch)="onSearch($event)">
            </app-common-table>
          </div>
        </div>
      </div>
    </div>
    <app-confirm-modal yes="Continuar" #confirmModalDisabledEgm (onConfirm)="disabledEgm()" [body]="this.body">
    </app-confirm-modal>
    <app-confirm-modal yes="Continuar" #confirmModalStartupEgm (onConfirm)="startupEgm()" [body]="this.body">
    </app-confirm-modal>
  </div>
</div>
