import { Manufacturer } from './Manufacturer';

export class Model {
  id: string;
  name: string;
  manufacturer: Manufacturer;

  public constructor(init?: Partial<Model>) {
    Object.assign(this, init);
  }
}
