<common-header [title]="title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <form
          role="form"
          [formGroup]="formEdit"
          (ngSubmit)="onSubmit(formObject)"
          #formObject="ngForm"
          #myForm="ngForm">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                <span> {{ headerTitle }}</span>
              </h3>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6">
                  <common-input-select
                    [list]="generationReasonCombo"
                    class="form-control-sm"
                    label="Motivo de Generación"
                    [help]="helpText"
                    [formControl]="formEdit.get('generationReason')"
                    (valueChanged)="updateForm()">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6">
                  <common-input-select
                    [list]="familyCombo"
                    class="form-control-sm"
                    label="Familia"
                    [formControl]="formEdit.get('family')"
                    [formSubmitted]="formObject.submitted">
                  </common-input-select>
                </div>
                <div class="form-group col-12 col-sm-6">
                  <app-common-input
                    [control]="formEdit.get('validatorCode')"
                    [label]="'Código Validador'"
                    [type]="InputType.numeric"
                    [maxlength]="18"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6" *ngIf="isinitForm">
                  <egm-combo
                    [isManualPay]="isManualPay"
                    [class.disabled]="formEdit.get('egmId').disabled"
                    [control]="formEdit.get('egmId')">
                  </egm-combo>
                </div>
                <div class="form-group col-12 col-sm-6">
                  <app-common-input
                    [control]="formEdit.get('ticketNumber')"
                    [label]="'Número de ticket'"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm-6">
                  <app-common-input
                    [control]="formEdit.get('formattedAmount')"
                    [label]="importeLabel"
                    [type]="InputType.numeric"
                    [formSubmitted]="formObject.submitted">
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="formEdit.get('observations')"
                    [label]="'Observaciones'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.textarea">
                  </app-common-input>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="btn btn-primary" [disabled]="submiting">Generar</button>
              <button type="button" class="btn btn-secondary ml-2" (click)="GoToCashFlowOrHandPayVoucher()">
                Volver
              </button>
            </div>
          </div>
          <!-- /.card -->
        </form>
      </div>
    </div>
    <!-- /.row -->
    <app-ticket-printer-status></app-ticket-printer-status>
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content -->

<app-confirm-modal yes="Continuar" (onConfirm)="GeneratTicket()" [body]="body"> </app-confirm-modal>
<!-- <app-voucher-modal [voucher]="voucher" (onPrint)="GoToCashFlow()" (onConfirm)="GoToCashFlow()"></app-voucher-modal> -->
<app-authorization-modal
  yes="Continuar"
  (onConfirm)="GeneratTicket()"
  (onCancel)="submiting = false"
  [body]="authorizationBody">
</app-authorization-modal>
