export class ApiResult<T> {
  data: T;
  error: ApiError;
  timeOut: boolean;

  constructor() {
    this.data = {} as T;
  }
}

export class ApiError {
  generalMessage: string;
  fieldErrors: FieldError[];
}

export class FieldError {
  field: string;
  error: string;
}

export class DataTableResult<T, U = any> {
  dataResults: T[];
  recordsTotal: number;
  recordsFiltered: number;
  decimalData: number;
  intData: number;
  listIntData: number[];
  footer: U;
}

export class DataTableParameters {
  columns: Columns[];
  length: number;
  search: Search;
  draw: number;
  start: number;
}

export class Columns {
  data: string;
  ordenable: boolean;
  search: Search;
  searchable: boolean;
}

export class Search {
  value: string;
  regex: boolean;
}
