import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CashRegisterService } from 'src/app/services/cash-register/cash-register.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { CommonInputSelectComponent } from '../../components/shared/common-input-select/common-input-select.component';
import { IdTextModel } from '../../components/shared/IdTextModel';
import { MinimalFilters, MultiselectFilters } from '../../components/viewmodels/Filters';

@Component({
  selector: 'app-cm-combo',
  templateUrl: './cm-combo.component.html',
  styleUrls: ['./cm-combo.component.css'],
})
export class CMComboComponent implements OnInit {
  @Input() control: FormControl;
  @Input() multiselect: boolean = false;
  @Input() roomId: number = null;
  @Input() cashRegisterId?: number = null;
  @Input() onlyNotUsed: boolean = false;

  @Input() allowClear: boolean = false;

  combo: Array<IdTextModel> = [];
  label: string = 'Máquna de Cambio';
  @Output() valueChanged = new EventEmitter();

  @ViewChild(CommonInputSelectComponent) selectComponent: CommonInputSelectComponent;

  constructor(private EgmService: EgmServiceService) {}

  ngOnInit(): void {
    this.roomId != null ? this.fillComboWithFreeCashMachine(this.roomId) : this.fillCombo();
  }

  public fillCombo() {
    if (this.onlyNotUsed) {
      this.EgmService.getCashMachinesNotUsedCombo(this.cashRegisterId).subscribe((result) => {
        this.combo = result.data;
      });
    } else {
      this.EgmService.getCashMachinesCombo().subscribe((result) => {
        this.combo = result.data;
      });
    }
  }

  public fillComboWithFreeCashMachine(roomId: number) {
    this.EgmService.getFreeCashMachineCombo(roomId).subscribe((result) => {
      this.combo = result.data;
    });
  }

  // public fillEgmsWithMultiselectFilters(ids: MultiselectFilters) {
  //   this.EgmService.fillEgmsWithMultiselectFilters(ids).subscribe((result) => {
  //     this.combo = result.data;
  //   })
  // }

  // public fillEgmsWithMinimalFilters(ids: MinimalFilters) {
  //   this.EgmService.fillEgmsWithMinimalFilters(ids).subscribe((result) => {
  //     this.combo = result.data;
  //   })
  // }

  public getCashMachineComboAsIdArray(): number[] {
    var arr: number[] = [];
    this.combo.forEach((element) => {
      arr.push(parseInt(element.id));
    });
    return arr;
  }

  public getCombo() {
    return this.combo;
  }
}
