import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableCustomButton,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { ConfirmModalComponent } from 'src/app/common/components/shared/confirm-modal/confirm-modal.component';
import { IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { CalculatedCountersViewModel } from 'src/app/common/components/viewmodels/CalculatedCountersViewModel';
import { CountersMock } from 'src/app/common/components/viewmodels/CountersMock';
import { DatatableMinimalFilters } from 'src/app/common/components/viewmodels/DatatableFilters';
import { CountersStatus } from 'src/app/common/components/viewmodels/Enums';
import { MinimalFilters } from 'src/app/common/components/viewmodels/Filters';
import { CountersService } from 'src/app/services/counters/counters.service';
import { EgmServiceService } from 'src/app/services/egm/egm-service.service';
import { ManufacturerService } from 'src/app/services/manufacturer/manufacturer.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { RoomServiceService } from 'src/app/services/room/room-service.service';
import { runInThisContext } from 'vm';
import { EditCountersParameters } from '../detailed-counters-info/detailed-counters-info.component';
declare var $: any;

@Component({
  selector: 'app-counters-period',
  templateUrl: './counters-period.component.html',
  styleUrls: ['./counters-period.component.css'],
})
export class CountersPeriodComponent implements OnInit {
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<CountersMock>;

  @ViewChild(ConfirmModalComponent, { static: false }) confirmModal: ConfirmModalComponent;

  rowData: any;

  body: string;

  title: string = 'Modificación Por Período';
  dateRange: string;

  items: Array<BreadcrumbItem> = [];
  isReset: boolean = false;
  public data: commonTableData<CountersMock>;
  showReset: boolean = false;
  formSearch: FormGroup;
  searchMade: boolean;
  counterObject: CountersMock;
  moment = moment;
  rowsCount: number = 0;
  parameter: EditCountersParameters = new EditCountersParameters();

  public static getName() {
    return 'CountersPeriodComponent';
  }

  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public RoomService: RoomServiceService,
    public EgmService: EgmServiceService,
    public ManufacturersService: ManufacturerService,
    public counterService: CountersService,
    private paramService: ParametersService<EditCountersParameters>
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Contadores'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.initForm();

    this.initGrid();
  }

  initGrid(): void {
    this.data = new commonTableData();

    this.data.loadFirstTime = false;

    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;

    let paramEditCounters = this.paramService.getAndDeleteParameters();

    if (paramEditCounters != null) {
      this.data.searchText = paramEditCounters.status.searchString;
      this.data.pageLength = paramEditCounters.status.pageLength;
      this.data.currentPage = paramEditCounters.status.currentPage;
      this.formSearch.get('egmUid').setValue(paramEditCounters.uid);
      this.formSearch.get('from').setValue(paramEditCounters.from);
      this.formSearch.get('to').setValue(paramEditCounters.to);

      this.data.loadFirstTime = true;
    }

    let button: commonTableCustomButton = new commonTableCustomButton();
    button.icon = 'fa-arrow-right';
    // button.tooltip = "Iniciar subperíodo";
    button.columntitle = 'Iniciar Subperíodo';
    button.order = 0;
    this.data.commonTableCustomButton.push(button);
    this.data.columns = [
      {
        data: 'dailyBalance.closed',
        title: 'Estado del día',
        render: function (data: boolean, type, row) {
          return data == true ? 'Cerrado' : 'Abierto';
        },
      },
      {
        data: 'countersStatus',
        title: 'Estado',
        render: function (data, type, row) {
          return CountersStatus[data];
        },
      },
      {
        data: 'countersDate',
        title: 'Fecha',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      { data: 'period', title: 'Período' },
      {
        data: 'isInCollection',
        title: 'Recaudación',
        render: function (data, type, row) {
          return data == true ? 'Sí' : 'No';
        },
      },
      { data: 'egm.uid', title: 'EGM' },
      { data: 'egm.manufacturer.name', title: 'Fabricante' },
      { data: 'egm.model.name', title: 'Modelo' },
      { data: 'egm.egmConfig.formattedDenomination', title: 'Denominación' },
      { data: 'coinIn', title: 'Coin_IN' },
      { data: 'coinOut', title: 'Coin_OUT' },
      { data: 'jackpot', title: 'Total Jackpot' },
      { data: 'totalDrop', title: 'Total Drop' },
      { data: 'cancelledCredits', title: 'Cancelled Credits' },
      { data: 'handpayCC', title: 'Handpay CC' },
      { data: 'billIn', title: 'Bill_IN' },
      { data: 'billOut', title: 'Bill_OUT' },
      { data: 'totalTicketIn', title: 'Total TCK_IN' },
      { data: 'totalTicketOut', title: 'Total TCK_OUT' },
      { data: 'coinDrop', title: 'Coin Drop' },
      { data: 'trueCoinIn', title: 'True Coin In' },
      { data: 'trueCoinOut', title: 'True Coin Out' },
      { data: 'eftIn', title: 'EFT In' },
      { data: 'aftIn', title: 'AFT In' },
      { data: 'aftOut', title: 'AFT Out' },
      { data: 'gamesPlayed', title: 'J. Jugados' },
      { data: 'gamesWon', title: 'J. Ganados' },
      { data: 'gamesLost', title: 'J. Perdidos' },
      { data: 'billM1Q', title: 'Bill M1 Q' },
      { data: 'billM2Q', title: 'Bill M2 Q' },
      { data: 'billM3Q', title: 'Bill M3 Q' },
      { data: 'billM4Q', title: 'Bill M4 Q' },
      { data: 'billM5Q', title: 'Bill M5 Q' },
      { data: 'billM6Q', title: 'Bill M6 Q' },
      { data: 'billM7Q', title: 'Bill M7 Q' },
      { data: 'billM8Q', title: 'Bill M8 Q' },
      { data: 'billToDrop', title: 'Bill To Drop' },
      { data: 'billToRecycle', title: 'Bill To Recycle' },

      { data: 'ticketCashIn', title: 'TCK_IN Cashable' },
      { data: 'ticketCashInQ', title: 'TCK_IN Cashable Q' },
      { data: 'ticketRPIn', title: 'TCK_IN Restr' },
      { data: 'ticketRPromInQ', title: 'TCK_IN Restr Q' },
      { data: 'ticketNoRestPromIn', title: 'TCK_IN No Restr' },
      { data: 'ticketNoRestPromInQ', title: 'TCK_IN No Restr Q' },
      { data: 'ticketCashOut', title: 'TCK_OUT Cashable' },
      { data: 'ticketCashOutQ', title: 'TCK_OUT Cashable Q' },
      { data: 'ticketRestPromOut', title: 'TCK_OUT Restr' },
      { data: 'ticketRestPromOutQ', title: 'TCK_OUT Restr Q' },
      { data: 'mptp', title: 'MPTP' },
      { data: 'mppp', title: 'MPPP' },
      { data: 'mpebp', title: 'MPEBP' },
      { data: 'aptp', title: 'APTP' },
      { data: 'appp', title: 'APPP' },
      { data: 'apebp', title: 'APEBP' },
      { data: 'doorQ', title: 'Door Q' },
      { data: 'powerReset', title: 'Power Reset' },

      { data: 'gamesSincePowerUp', title: 'Games Since PowerUp' },
      { data: 'gamesSinceDoorClosed', title: 'Games Since Door Closed' },
      { data: 'currentHopperLevel', title: 'Current Hopper Level' },
      { data: 'totalHandPaidCredits', title: 'Total HandPaid Credits' },
      { data: 'reserved1', title: 'Reserved1' },
      { data: 'reserved2', title: 'Reserved2' },
      { data: 'reserved3', title: 'Reserved3' },
      { data: 'reserved4', title: 'Reserved4' },
      { data: 'reserved5', title: 'Reserved5' },
      { data: 'reserved6', title: 'Reserved6' },
      { data: 'reserved7', title: 'Reserved7' },
      { data: 'reserved8', title: 'Reserved8' },
      { data: 'reserved9', title: 'Reserved9' },
      { data: 'reserved10', title: 'Reserved10' },
      { data: 'reserved11', title: 'Reserved11' },
      { data: 'reserved12', title: 'Reserved12' },
      { data: 'reserved13', title: 'Reserved13' },
      { data: 'reserved14', title: 'Reserved14' },
      { data: 'reserved15', title: 'Reserved15' },
      { data: 'reserved16', title: 'Reserved16' },
    ];

    const that = this;

    this.data.rowCallback = function (row: Node, data: CountersMock | Object, index: number): void {
      // if (data["subperiodStart"] == false && data["period"] == 1) {
      //   that.table.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //     $($('td', row)[0]).addClass("disabled");
      //   });
      // }
    };
  }

  setGridData(filters: MinimalFilters): void {
    let service = this.counterService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<CountersMock>>> {
      var DataTableFilters = new DatatableMinimalFilters();
      DataTableFilters.filters = filters;
      DataTableFilters.dataTableParameters = param;
      return service.getCountersWithMinimalFilters(DataTableFilters);
    };
    this.dateRange =
      moment(filters.from).format('DD/MM/yyyy, HH:mm:ss').toString() +
      ' - ' +
      moment(filters.to).format('DD/MM/yyyy, HH:mm:ss').toString();
  }

  //con solo cargar el status alcanza pq no me estoy yendo de la pagina, pero dejo todo el metodo x si me voy a otra pagina
  // refreshAndUpdateTable() {
  //   let paramEditCounters = this.paramService.getAndDeleteParameters();

  //   if (paramEditCounters != null) {
  //     this.data.searchText = paramEditCounters.status.searchString;
  //     this.data.pageLength = paramEditCounters.status.pageLength;
  //     this.data.currentPage = paramEditCounters.status.currentPage;
  //     this.formSearch.get('egmUid').setValue(paramEditCounters.uid);
  //     this.formSearch.get('from').setValue(paramEditCounters.from);
  //     this.formSearch.get('to').setValue(paramEditCounters.to);
  //   }
  //   this.updateTable();
  // }

  updateTable() {
    // this.rowsCount = 0;
    this.setGridData(this.getFilters());
    this.table.reload();
  }

  initForm() {
    this.formSearch = new FormGroup({
      egmUid: new FormControl(null, [Validators.required]),
      from: new FormControl(),
      to: new FormControl(),
    });
  }

  onSubmit() {
    if (this.formSearch.valid) {
      var from = moment(this.formSearch.get('from').value);
      var to = moment(this.formSearch.get('to').value);
      var daysDiff = from.diff(to, 'days');
      var days = moment.duration(from.diff(to)).asDays();
      var daysTo = moment(this.formSearch.get('to').value).date();
      var daysFrom = moment(this.formSearch.get('from').value).date();
      var monthTo = moment(this.formSearch.get('to').value).month();
      var monthFrom = moment(this.formSearch.get('from').value).month();
      var yearTo = moment(this.formSearch.get('to').value).year();
      var yearFrom = moment(this.formSearch.get('from').value).year();
      // El rango de fechas tiene que ser 1 (para dias). Por ende debe ser del mismo mes (y año)
      if (daysTo - daysFrom == 1 && monthTo - monthFrom == 0 && yearTo - yearFrom == 0) {
        this.showReset = true;
      } else {
        this.showReset = false;
      }
      // if (daysDiff == 1) {
      //   this.showReset = true;
      // } else {
      //   this.showReset = false;
      // }

      this.updateTable();
    } else {
      this.notificationService.showError('Por favor complete todos los campos correctamente.', 'Error');
    }
  }

  onSearch(status: tableStatus) {
    this.parameter.status = status;
  }

  getTotalRows(event) {
    this.rowsCount = event;
  }
  onCustomButton(data) {
    this.rowData = data;
    this.isReset = false;
    this.counterObject = data.row;
    this.body = '¿Está seguro que desea iniciar el subperíodo?';
    this.confirmModal.openModal();
  }

  onResetButton() {
    this.body =
      '¿Está seguro que desea resetear los subperíodos de ' +
      this.rowsCount +
      ' contadores del rango ' +
      this.dateRange +
      ' ?';
    this.isReset = true;
    this.confirmModal.openModal();
  }

  togglePeriod() {
    this.parameter.uid = this.formSearch.get('egmUid').value;
    this.parameter.from = this.formSearch.get('from').value;
    this.parameter.to = this.formSearch.get('to').value;

    this.paramService.saveParameters(this.parameter);
    if (this.isReset) {
      this.counterService.resetSubperiods(this.getFilters()).subscribe((result) => {
        this.initGrid();
        this.updateTable();
      });
    } else {
      this.counterService.setSubperiod(this.counterObject).subscribe((result) => {
        this.initGrid();
        this.updateTable();
      });
    }
  }

  getFilters() {
    var filters = new MinimalFilters();
    filters.egm = this.formSearch.get('egmUid').value;
    filters.from = this.formSearch.get('from').value;
    filters.to = this.formSearch.get('to').value;

    return filters;
  }
}
