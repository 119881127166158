<common-header [title]="this.title" [items]="items"></common-header>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <form
            role="form"
            [formGroup]="formEdit"
            (ngSubmit)="onSubmit(formObject)"
            #formObject="ngForm"
            autocomplete="off">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-12 col-sm">
                  <app-common-input
                    [control]="this.formEdit.get('quantity')"
                    [label]="'Cantidad de tickets'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric">
                  </app-common-input>
                </div>
                <div class="form-group col-12 col-sm">
                  <app-common-input
                    [control]="this.formEdit.get('ticketValue')"
                    [label]="'Valor del ticket'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.numeric"
                    #ticketValue>
                  </app-common-input>
                </div>
                <div class="form-group col-12">
                  <app-common-input
                    [control]="this.formEdit.get('restrictive')"
                    [label]="'¿Es restrictivo?'"
                    [formSubmitted]="formObject.submitted"
                    [type]="InputType.checkbox">
                  </app-common-input>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!hidePromotional || !sessionStatus">
                Imprimir
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <app-ticket-printer-status></app-ticket-printer-status>
  </div>
</div>
