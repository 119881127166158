import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  SessionManagmentViewModel,
  ButtonMovementTypeEnum,
  CashRegisterTypeEnum,
} from 'src/app/common/components/viewmodels/SessioModel';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { SessionService } from 'src/app/services/pos/pos.service';

@Component({
  selector: 'app-session-cash-flow',
  templateUrl: './session-cash-flow.component.html',
  styleUrls: ['./session-cash-flow.component.css'],
})
export class SessionCashFlowComponent implements OnInit {
  public static getName() {
    return 'SessionCashFlowComponent';
  }

  title: string = 'Operaciones de Caja';
  headerTitle: string = '';
  items: Array<BreadcrumbItem> = [];
  currencyCode: string;
  currencySym: string;
  isCashSalon: boolean;
  session: SessionManagmentViewModel = new SessionManagmentViewModel();

  loadData: boolean = false;
  showcashflowButtons: boolean = false;
  showconciliate: boolean = false;

  formEdit: FormGroup;
  showDetails: boolean = true;
  eventsChangeTurn: Subject<number> = new Subject<number>();

  constructor(
    private router: Router,

    public sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    public casinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe((params) => {
      const idParam = params['id']; // Asume que 'id' es el nombre del parámetro
      this.InitPage(idParam);
    });

    this.items.push(new BreadcrumbItem('', 'Gestión'));
    this.items.push(new BreadcrumbItem('/session-management', 'Sesiones y turnos'));
    this.items.push(new BreadcrumbItem('', 'Operaciones de caja'));

    this.showcashflowButtons = (
      await this.casinoService.isComponentAuthorized('SessionMovementCashFlowComponent', 'Page').toPromise()
    ).data;

    this.showconciliate = (
      await this.casinoService.isComponentAuthorized('SessionReconciliationComponent', 'Page').toPromise()
    ).data;

    this.casinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    // this.items.push(new BreadcrumbItem(this.router.url, 'Gestión'));
    // this.items.push(new BreadcrumbItem(this.router.url, this.title));
  }

  InitPage(paramTurnsId: string) {
    this.loadData = false;
    //miro el q viene en la url
    if (paramTurnsId != null && Number(paramTurnsId) != 0) {
      if (isNaN(Number(paramTurnsId))) {
        this.goToErrorPage();
      } else {
        this.ChangeTurn(Number(paramTurnsId));
      }
    } else {
      this.goToErrorPage();
    }
  }

  goToErrorPage() {
    this.router.navigate(['/session-management']);
  }

  changeTurnEvent() {
    this.ChangeTurn(Number(this.formEdit.get('turnoId').value));
  }

  ChangeTurn(turnId: number): void {
    //voy a buscar el detalle del turno:

    this.sessionService.GetSessionByTurnId(turnId).subscribe((result) => {
      this.session = result.data;
      this.title = this.session.cashRegisterName;
      this.isCashSalon = this.session.cashRegisterType == CashRegisterTypeEnum.Salon;

      if (this.session === null) {
        this.router.navigate(['/session-management']);
      } else {
        this.headerTitle = this.sessionService.getPageTitle(this.session);
        this.loadData = true;
        this.eventsChangeTurn.next(turnId);
      }
    });
  }

  GoToSettlement() {
    this.router.navigate(['/session-settlement', this.session.cashRegisterId], { queryParams: { m: '0' } });
  }
  GoToPayDatafono() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.PayDatafono;
    this.router.navigate(['/session-movement', this.session.idTurn]);
  }
  GoToPayBets() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.PayBets;
    this.router.navigate(['/session-movement', this.session.idTurn]);
  }

  GoToExpensesMiscellaneous() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.ExpensesMiscellaneous;
    this.router.navigate(['/session-movement', this.session.idTurn]);
  }

  GoToPayInvoice() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.PayInvoice;
    this.router.navigate(['/session-movement', this.session.idTurn]);
  }

  GoToPayJackPot() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.PayJackPot;
    this.router.navigate(['/session-movement', this.session.idTurn]);
  }

  GoToIncreaseFunds() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.IncreaseFunds;
    this.router.navigate(['/session-movement-cashflow', this.session.idTurn]);
  }

  GoToReduceFunds() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.ReduceFunds;
    this.router.navigate(['/session-movement-cashflow', this.session.idTurn]);
  }

  GoToIncreaseFundsCashier() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.IncreaseFundsCashier;
    this.router.navigate(['/session-movement', this.session.idTurn]);
  }

  GoToReduceFundsCashier() {
    this.sessionService.CurrentMovement = ButtonMovementTypeEnum.ReduceFundsCashier;
    this.router.navigate(['/session-movement', this.session.idTurn]);
  }

  GoToReplacementTicket() {
    this.router.navigate(['session-ticketreplacement', this.session.idTurn]);
  }

  goToNexCashRegister() {
    this.router.navigate(['session-cashflow', this.session.nextTurnId]);
  }

  // GoToManualPayment() {
  //   this.router.navigate(['session-manualpayment', this.session.idTurn]);
  // }

  GoToManualIncidencePayment() {
    this.router.navigate(['session-incidencepayment', this.session.idTurn]);
  }

  GoToHandypayPayment() {
    this.router.navigate(['session-handpayvoucher', this.session.idTurn]);
  }

  GoToConciliation() {
    this.router.navigate(['session-reconciliation', this.session.idTurn]);
  }
  GoToPayTito() {
    this.router.navigate(['session-pay-ticket', this.session.idTurn]);
  }

  GoToGeneratTito() {
    this.router.navigate(['session-cashable-ticket', this.session.idTurn]);
  }
}
