import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from 'src/app/common/components/shared/common-breadcrumb/common-breadcrumb.component';
import {
  CommonTableComponent,
  commonTableData,
  tableStatus,
} from 'src/app/common/components/shared/common-table/common-table.component';
import { NotificationService } from 'src/app/common/components/shared/notification/notification.service';
import { ApiResult, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { TicketFamily, TicketFamilyGroup } from 'src/app/common/components/viewmodels/Enums';
import { Ticket } from 'src/app/common/components/viewmodels/Ticket';
import { TicketMock } from 'src/app/common/components/viewmodels/TicketMock';
import { CasinoService } from 'src/app/services/casino/casino.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';

@Component({
  selector: 'app-detailed-unpaid-tickets',
  templateUrl: './detailed-unpaid-tickets.component.html',
  styleUrls: ['./detailed-unpaid-tickets.component.css'],
})
export class DetailedUnpaidTicketsComponent implements OnInit {
  total: number = 0;
  items: Array<BreadcrumbItem> = [];
  title: string = 'Tickets Impagos';
  public data: commonTableData<TicketMock>;
  currencySym: string;
  currencyCode: string;
  regex: RegExp = new RegExp('_', 'g');
  searchMade: boolean;
  @ViewChild(CommonTableComponent, { static: false }) table: CommonTableComponent<TicketMock>;

  public static getName() {
    return 'DetailedUnpaidTicketsComponent';
  }
  constructor(
    private router: Router,
    public notificationService: NotificationService,
    public TicketService: TicketService,
    private activatedRoute: ActivatedRoute,
    public CasinoService: CasinoService,
    public currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.items.push(new BreadcrumbItem('', 'Tito'));
    this.items.push(new BreadcrumbItem('', this.title));

    this.data = new commonTableData();
    this.data.showEditButton = false;
    this.data.showDeleteButton = false;
    this.data.showCreateButton = false;
    this.data.searching = false;
    this.data.paging = true;
    this.data.showAll = true;

    this.CasinoService.getData().subscribe((result) => {
      this.currencyCode = result.data.currencyCode;
      this.currencySym = result.data.currencySymbol;
    });

    this.setGridData();
  }

  onSearch(status: tableStatus) {
    this.total = this.table.decimalData;
  }

  setGridData() {
    const that = this;
    let service = this.TicketService;

    this.data.getData = function (param: any): Observable<ApiResult<DataTableResult<TicketMock>>> {
      return service.getAliveTickets(param);
    };

    (this.data.columns = [
      {
        data: 'validatorCode',
        title: 'Código',
        render: function (data: number, type, row) {
          return data == 0 ? '' : data;
        },
      },
      {
        data: 'creationDate',
        title: 'Emisión',
        render: function (data, type, row) {
          return moment(data).format('DD/MM/yyyy, HH:mm:ss');
        },
      },
      {
        data: 'family.familyGroupId',
        title: 'Familia',
        render: function (data: number, type, row) {
          return data != null ? TicketFamilyGroup[data].replace(that.regex, ' ') : '-';
        },
      },
      {
        data: 'formattedAmount',
        title: 'Importe',
        render: function (data: number, type, row) {
          return that.currencyPipe.transform(data, that.currencyCode, that.currencySym);
        },
      },
    ]),
      (this.data.rowCallback = function (row: Node, data: TicketMock, index: number): void {
        $($('td', row)[0]).html(
          `<a target="_blank" href='${window.location.origin}/egms/tito/ticket-history/${data.validatorCode}' style='text-decoration: underline;' data-toggle='tooltip' data-placement='right' title='' data-original-title='Ir a historial'>${data.validatorCode}</a>`
        );
      });

    // this.data.rowCallback = function (row: Node, data: Ticket | Object, index: number): void {
    //   that.total += data["formattedAmount"];
    // };
  }
}
