import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GuidTextModel, IdTextModel } from 'src/app/common/components/shared/IdTextModel';
import { ApiResult, DataTableParameters, DataTableResult } from 'src/app/common/components/viewmodels/apiResult';
import { Model } from 'src/app/common/components/viewmodels/Model';
import { baseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class ModelService extends baseService {
  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string,
    toastr: ToastrService,
    router: Router
  ) {
    super(toastr, router);
  }

  public getModelsCombo2(idManufacturer: number): Observable<ApiResult<GuidTextModel[]>> {
    return this.http
      .get<ApiResult<GuidTextModel[]>>(`${this.baseUrl}Models/GetModelsComboById?idManufacturer=${idManufacturer}`)
      .pipe(
        map((x) => {
          var result = this.checkResult<GuidTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<GuidTextModel[]>(error))
      );
  }

  public getModelsComboByManufacturersId(idManufacturers: Array<string>): Observable<ApiResult<GuidTextModel[]>> {
    return this.http
      .post<ApiResult<GuidTextModel[]>>(`${this.baseUrl}Models/GetModelsComboByIds`, idManufacturers)
      .pipe(
        map((x) => {
          var result = this.checkResult<GuidTextModel[]>(x);
          return result;
        }),
        catchError((error) => this.handleError<GuidTextModel[]>(error))
      );
  }
  public getModelsCombo(): Observable<ApiResult<GuidTextModel[]>> {
    return this.http.get<ApiResult<IdTextModel[]>>(`${this.baseUrl}Models/GetModelsCombo`).pipe(
      map((x) => {
        var result = this.checkResult<GuidTextModel[]>(x);
        return result;
      }),
      catchError((error) => this.handleError<GuidTextModel[]>(error))
    );
  }
  public getModels(dataTablesParameters: DataTableParameters): Observable<ApiResult<DataTableResult<Model>>> {
    return this.http
      .post<ApiResult<DataTableResult<Model>>>(`${this.baseUrl}Models/GetModels`, dataTablesParameters)
      .pipe(
        map((x) => {
          var result = this.checkResult<DataTableResult<Model>>(x);
          return result;
        }),
        catchError((error) => this.handleError<DataTableResult<Model>>(error))
      );
  }
  public getModel(id: string): Observable<ApiResult<Model>> {
    return this.http.get<ApiResult<Model>>(`${this.baseUrl}Models/GetModel?id=${id}`).pipe(
      map((x) => this.checkResult<Model>(x)),
      catchError((error) => this.handleError<Model>(error))
    );
  }
  public createOrEdit(model: Model): Observable<ApiResult<string>> {
    return this.http.post<ApiResult<string>>(`${this.baseUrl}Models/CreateOrEdit`, model).pipe(
      map((x) => this.checkResult<string>(x)),
      catchError((error) => this.handleError<string>(error))
    );
  }
  public deleteModel(id: string): Observable<ApiResult<Model>> {
    return this.http.delete<ApiResult<Model>>(`${this.baseUrl}Models/DeleteModel?id=${id}`).pipe(
      map((x) => {
        var result = this.checkResult<Model>(x);
        return result;
      }),
      catchError((error) => this.handleError<Model>(error))
    );
  }
}
