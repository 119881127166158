<common-header title="Eventos del Sistema" [items]="items"></common-header>
<div class="content">
  <div class="container-fluid">
    <form role="form" [formGroup]="formSearch" (ngSubmit)="onSubmit()" #formObject="ngForm">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Filtros de búsqueda
                <span *ngIf="dateRange != null"> - {{ dateRange }} </span>
              </h3>
              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-12 col-sm-4">
                  <app-common-daterangepicker
                    [controlFrom]="this.formSearch.get('from')"
                    [controlTo]="this.formSearch.get('to')"
                    [label]="'Rango'">
                  </app-common-daterangepicker>
                </div>
                <div class="col-12 col-sm">
                  <manufacturers-combo [control]="this.formSearch.get('manufacturers')" (valueChanged)="getModels()">
                  </manufacturers-combo>
                </div>
                <div class="col-12 col-sm">
                  <models-combo [control]="this.formSearch.get('models')" (valueChanged)="getGameTypes()">
                  </models-combo>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 col-sm">
                  <gametypes-combo [control]="this.formSearch.get('gameTypes')" (valueChanged)="updateEgms()">
                  </gametypes-combo>
                </div>
                <div class="col-12 col-sm">
                  <egm-combo [multiselect]="true" [control]="this.formSearch.get('egmUid')"> </egm-combo>
                </div>
                <div class="col-12 col-sm">
                  <common-input-multiselect
                    class="form-control-sm"
                    [label]="'Evento'"
                    [list]="this.eventsCombo"
                    formControlName="events"></common-input-multiselect>
                </div>
              </div>
              <!-- <div class="form-row">
                                <div class="col-6">
                                    <app-common-input [control]="this.formSearch.get('egmOnline')"
                                        [label]="'Habilitada (en servicio)'" [type]="InputType.checkbox">
                                    </app-common-input>
                                </div>
                                <div class="col-6">
                                    <app-common-input [control]="this.formSearch.get('priority')"
                                        [label]="'Solo alertas'" [type]="InputType.checkbox">
                                    </app-common-input>
                                </div>
                            </div> -->
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-end">
                <button
                  (click)="formObject.submitted ? formObject.ngSubmit.emit() : onSubmit()"
                  data-card-widget="collapse"
                  class="btn btn-info">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <app-common-table [model]="data" [excel]="true" [title]="'Eventos'"></app-common-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
